import React from 'react';
import styled from '@emotion/styled';
import { FormattedMessage, useIntl } from 'react-intl';
import TallyMarkdown from 'src/components/shared/ui/TallyMarkdown';
import { BingoProperties } from 'src/util/TallyFirestore';
import { EventModel } from 'src/util/TallyFirestore';
import Button from '@material-ui/core/Button';
import { NetworkError } from 'src/util/errors/NetworkError';
import {
    LoadingIndicatorContainer,
    HeaderText,
    WidthRestrictor,
} from './shared';
import SquareLoading from 'src/components/shared/animations/SquareLoading';
import theme from 'src/util/Theme';

type Props = {
    bingo: BingoProperties;
    event: EventModel;
    onGenerateScoreCard: () => void;
    loading: boolean;
    error?: Error;
};

const Welcome = ({
    bingo: { scorecardSize },
    event,
    onGenerateScoreCard,
    loading,
    error,
}: Props) => {
    const intl = useIntl();

    return (
        <WidthRestrictor>
            <HeaderText>
                <FormattedMessage
                    id="bingoGame.welcome.header"
                    values={{ eventName: event.name }}
                />
            </HeaderText>
            <ErrorMessage error={error} />
            <TallyMarkdown
                source={intl.formatMessage(
                    { id: 'bingoGame.welcome.description' },
                    {
                        scorecardSize,
                    },
                )}
            />
            <Button onClick={onGenerateScoreCard} disabled={loading}>
                <FormattedMessage id="bingoGame.welcome.button.generate" />
            </Button>
            <LoadingIndicatorContainer>
                {loading && (
                    <SquareLoading color={theme.themeHighlightFallbackColor} />
                )}
            </LoadingIndicatorContainer>
        </WidthRestrictor>
    );
};

export default Welcome;

const ErrorMessage = ({ error }: { error?: Error }) =>
    error ? (
        <ErrorStyled>
            {error instanceof NetworkError ? (
                <FormattedMessage id="error.failedToFetch" />
            ) : (
                error.message
            )}
        </ErrorStyled>
    ) : null;

const ErrorStyled = styled.div`
    font-size: 14px;
    color: red;
    margin-bottom: 8px;
`;
