import styled from '@emotion/styled';
import { Color, TextStyles, TextStyleTypes } from 'src/styles/Constants';

export const TileTitle = styled.div`
    ${TextStyles[TextStyleTypes.H3]}
    border-bottom: 2px solid ${Color.G8_CONCRETE};
    text-align: left;
    width: 100%;
`;

export const ButtonContainer = styled.div`
    padding: 25px 0 15px 0;
`;

export const DescriptionText = styled.div`
    margin: 15px 0 30px 0;
    ${TextStyles[TextStyleTypes.P1]}
    text-align: left;
    width: 100%;
`;

export const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 20px;
    margin: 20px 0;
`;

export const DescriptionImg = styled.img`
    margin: 15px 0 30px 0;
    width: 100%;
`;
