import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import DotsDefault from 'src/images/backgrounds/DotsDefault.svg';
import Prediction, {
    OnAnswerCallback,
} from 'src/components/shared/predictions/Prediction';
import PredictionIframeAd from 'src/components/shared/predictions/PredictionIframeAd';
import { Color, TextStyles, TextStyleTypes } from 'src/styles/Constants';
import {
    Claim,
    PredictionAdPlacement,
    PredictionAdRenderTime,
    PredictionModel,
    SponsorModel,
    UserAnswer,
} from 'src/util/TallyFirestore';

import { getContainerBorderColor, getPillDetails } from './utils';
import PowerUpAnimation from '../../PowerUpAnimation';
import Pill from 'src/components/pistachioGame/Pill';
import useVisibilityChanged from 'src/hooks/useVisibilityChanged';
import { PredictionSponsorshipUnitTemplateId } from 'src/util/SponsorshipTypes';
import { useLocale } from 'src/hooks/useLocale';

type Props = {
    answer?: UserAnswer;
    oddClaim?: Claim;
    prediction: PredictionModel;
    projectedSelectedOptionId?: string;
    sponsor?: SponsorModel;
    onAnswer?: OnAnswerCallback;
    onCardIconVisibilityChanged: (id: string, visible: boolean) => void;
};

const LivePredictionCard = ({
    prediction,
    answer,
    oddClaim,
    projectedSelectedOptionId,
    sponsor,
    onAnswer,
    onCardIconVisibilityChanged,
}: Props) => {
    const { localeId } = useLocale();
    const { id } = prediction;

    const iconRef = useRef<HTMLDivElement>(null);
    const [showSplash, setShowSplash] = useState(false);
    const [showPill, setShowPill] = useState(!!prediction.sponsorship);

    const onVisibilityChange = (visible: boolean) => {
        if (visible) {
            if (!showSplash && sponsor) {
                setShowSplash(true);
            }

            if (prediction.sponsorship) {
                setShowPill(true);
            }
            onCardIconVisibilityChanged(id, true);
        } else {
            if (prediction.sponsorship) {
                setShowPill(false);
            }
            onCardIconVisibilityChanged(id, false);
        }
    };

    useVisibilityChanged(iconRef, onVisibilityChange);

    const shouldDisplayAd: boolean = !!(
        prediction.adEnabled &&
        (prediction.adRenderTime === PredictionAdRenderTime.rightAway ||
            (prediction.adRenderTime ===
                PredictionAdRenderTime.afterPrediction &&
                (projectedSelectedOptionId || answer)))
    );

    const answered = !!answer;

    const borderColor = getContainerBorderColor(prediction.type, answered);

    const pillDetails = getPillDetails(
        prediction,
        answered || projectedSelectedOptionId === prediction.id,
        sponsor,
    );
    const pointValue = prediction.pointValue.toLocaleString(localeId);
    const isPoll = prediction.type === 'POLL';

    return (
        <Container>
            {shouldDisplayAd &&
                prediction.adPlacement === PredictionAdPlacement.above && (
                    <AdCardContentContainer adPlacment={prediction.adPlacement}>
                        <PredictionIframeAd
                            predictionId={prediction.id}
                            predictionAd={{
                                iframeHtml: prediction.adIframeHtml,
                                headline: prediction.adHeadline,
                                disclaimer: prediction.adDisclaimer,
                                sizingPolicy: prediction.adSizingPolicy,
                                cutOffHeight: prediction.adCutOffHeight,
                            }}
                        />
                    </AdCardContentContainer>
                )}

            <Card borderColor={borderColor} extraTopPadding={!sponsor}>
                <IconContainer ref={iconRef}>
                    <Pill details={pillDetails} expand={showPill} />
                </IconContainer>
                {sponsor && (
                    <SponsorshipBox center={isPoll}>
                        <SponsorshipLogoContainer center={isPoll}>
                            <SponsorshipLogo src={sponsor.wideLogoUrl} />
                        </SponsorshipLogoContainer>
                        {!isPoll && (
                            <SponsorshipTag>
                                <FormattedMessage id="newPredictionCard.sponsorshipTag" />
                            </SponsorshipTag>
                        )}
                    </SponsorshipBox>
                )}
                <CardContent>
                    {isPoll && (
                        <PollPoints>
                            <PollHeading>
                                <FormattedMessage id="newPredictionCard.pollHeading" />
                            </PollHeading>
                            {pointValue && pointValue != '0' && pointValue}{' '}
                            {pointValue && pointValue != '0' && (
                                <FormattedMessage id="newPredictionCard.pollPoints" />
                            )}
                        </PollPoints>
                    )}
                    <TitleLabel big={!isPoll} centered={isPoll}>
                        {prediction.text}
                    </TitleLabel>
                    <Prediction
                        answer={answer}
                        oddClaim={oddClaim}
                        projectedSelectedOptionId={projectedSelectedOptionId}
                        prediction={prediction}
                        // ATODO: do we even need this?
                        isLive={true}
                        showText={false}
                        onAnswer={onAnswer}
                    />
                    {shouldDisplayAd &&
                        prediction.adPlacement ===
                            PredictionAdPlacement.inline && (
                            <PredictionIframeAd
                                predictionId={prediction.id}
                                predictionAd={{
                                    iframeHtml: prediction.adIframeHtml,
                                    sizingPolicy: prediction.adSizingPolicy,
                                    cutOffHeight: prediction.adCutOffHeight,
                                    headline: prediction.adHeadline,
                                    disclaimer: prediction.adDisclaimer,
                                }}
                            />
                        )}
                </CardContent>
                {showSplash && sponsor && prediction.sponsorship && (
                    <AnimationContainer>
                        <PowerUpAnimation
                            sponsor={sponsor}
                            onAnimationEnd={() => {
                                setShowSplash(false);
                            }}
                            sponsorshipType={prediction.sponsorship.templateId}
                        />
                    </AnimationContainer>
                )}
                {prediction.sponsorship &&
                    prediction.sponsorship.templateId ===
                        PredictionSponsorshipUnitTemplateId.PRIZE_REDEMPTION && (
                        <>
                            <PrizeAssetContainer>
                                <PrizeAsset
                                    src={
                                        prediction.sponsorship.properties.asset
                                    }
                                />
                            </PrizeAssetContainer>
                            <PrizeAssetBackgroundContainer>
                                <PrizeAssetBackground src={DotsDefault} />
                            </PrizeAssetBackgroundContainer>
                            {prediction.sponsorship.bonusDescription && (
                                <BonusDescriptionText>
                                    {prediction.sponsorship.bonusDescription}
                                </BonusDescriptionText>
                            )}
                        </>
                    )}
            </Card>
            {shouldDisplayAd &&
                prediction.adPlacement === PredictionAdPlacement.below && (
                    <AdCardContentContainer adPlacment={prediction.adPlacement}>
                        <PredictionIframeAd
                            predictionId={prediction.id}
                            predictionAd={{
                                iframeHtml: prediction.adIframeHtml,
                                sizingPolicy: prediction.adSizingPolicy,
                                cutOffHeight: prediction.adCutOffHeight,
                                headline: prediction.adHeadline,
                                disclaimer: prediction.adDisclaimer,
                            }}
                        />
                    </AdCardContentContainer>
                )}
        </Container>
    );
};

export default LivePredictionCard;

const Container = styled.div`
    padding-bottom: 60px;
`;

const AdCardContentContainer = styled.div<{
    adPlacment: PredictionAdPlacement;
}>`
    margin: ${(props) =>
            props.adPlacment === PredictionAdPlacement.below ? 20 : 0}px
        0
        ${(props) =>
            props.adPlacment === PredictionAdPlacement.above ? 20 : 0}px
        0;
`;

const Card = styled.div<{
    borderColor: string;
    extraTopPadding?: boolean;
}>`
    position: relative;
    top: 0;
    border: 1px solid ${(props) => props.borderColor};
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.08);
    background-color: ${Color.P1_WHITE};
    transition: border 0.4s;
    width: 100%;
    padding: ${(props) => (props.extraTopPadding ? 40 : 20)}px 20px 20px 20px;
    box-sizing: border-box;
`;

const CardContent = styled.div`
    position: relative;
    z-index: 1;
`;

const IconContainer = styled.div`
    position: absolute;
    top: -15px;
    left: 20px;
    z-index: 10;
`;

const TitleLabel = styled.div<{ big: boolean; centered: boolean }>`
    display: inline-block;
    width: 100%;
    ${(props) =>
        props.big
            ? TextStyles[TextStyleTypes.S1]
            : TextStyles[TextStyleTypes.P3]}
    text-align: ${(props) => (props.centered ? 'center' : 'left')};
    transition:
        font-weight 0.3s,
        font-size 0.3s;
`;

const PollHeading = styled.span`
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.13px;
    line-height: 28px;
    margin-right: 5px;
`;

const PollPoints = styled.div`
    text-align: center;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: -0.13px;
    line-height: 28px;
    margin-bottom: 6px;
`;

const AnimationContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    pointer: none;
    width: 100%;
    height: 100%;
    z-index: 9;
`;

// Sponsor stuff

const SponsorshipLogo = styled.img`
    height: 32px;
`;

const SponsorshipLogoContainer = styled.div<{ center: boolean }>`
    padding: 5px 0;
    width: 100%;
    border-bottom: 2px dotted ${Color.G7_FOG};
    display: flex;
    justify-content: ${(props) => (props.center ? 'center' : 'flex-start')};
    align-items: center;
    height: 25px;
`;

const SponsorshipTag = styled.div`
    color: ${Color.G4_CHARCOAL};
    margin: 5px 0 0 0;
    ${TextStyles[TextStyleTypes.B2]}
`;

const SponsorshipBox = styled.div<{ center: boolean }>`
    padding: 15px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: ${(props) => (props.center ? 'center' : 'flex-start')};
`;

const PrizeAssetContainer = styled.div`
    margin-top: 20px;
    position: relative;
    z-index: 8;
`;

const PrizeAsset = styled.img`
    width: 100%;
`;

const PrizeAssetBackground = styled.img`
    width: 100%;
`;

const PrizeAssetBackgroundContainer = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
`;

const BonusDescriptionText = styled.div`
    color: ${Color.T31_BLACK};
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 18px;
    margin: 18px auto 0 8px;
    max-width: 325px;
`;
