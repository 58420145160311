import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';

import FirebaseAuth from 'src/services/FirebaseAuth';
import 'src/styles/global.css';
import config, {
    InitialEventData,
    convertInitialEventData,
    NoCurentIteration,
} from 'src/util/Config';
import * as FirestoreDao from 'src/util/FirestoreDao';
import theme, { GlobalCss, muiTheme } from 'src/util/Theme';
import TallyLoadingFullScreen from 'src/components/shared/animations/TallyLoadingFullScreen';
import log from 'src/util/Logger';
import { getIterationPathFromEventPath } from 'src/util/EventHelpers';

import { UserStorageConsentProvider } from './hooks/useUserStorageConsent';
import { LocaleProvider } from './hooks/useLocale';
import { PartnerProvider } from './hooks/usePartner';
import Iteration from './Iteration';
import convertArrayToOrderedMap from './util/convertArrayToOrderedMap';
import { BrowserRouter } from 'react-router-dom';
import { UserAuthData, UserStateProvider } from './contexts/UserStateContext';
import { GroupProvider } from './contexts/GroupProvider';
import { InitialEventDataProvider } from './contexts/InitialEventDataContext';
import { UserAgreementProvider } from './hooks/useUserAgreement';
import {
    AnalyticsProvider,
    initialize as initializeAnalytics,
} from './contexts/AnalyticsContext';
import UserStorageConsentModal from './components/shared/modals/UserStorageConsentModal';
import { TranslatableEventModel, EventState } from './util/TallyFirestore';
import { authenticationMethods } from './util/ConfigTypes';

type State =
    | DataLoading
    | DataLoadingSuccess
    | DataLoadingFailure
    | DataLoadingSuccessAndUiReady;

type DataLoading = {
    id: 'dataLoading';
};

type DataLoadingFailure = {
    id: 'dataLoadingFailure';
    error: Error;
};

type DataLoadingSuccess = {
    id: 'dataLoadingSuccess';
    initialEventData: InitialEventData;
    userAuthData: UserAuthData;
};

type DataLoadingSuccessAndUiReady = {
    id: 'dataLoadingSuccessAndUiReady';
    initialEventData: InitialEventData;
    userAuthData: UserAuthData;
};

// Live events that started earlier go first. final events go last
const eventRelevanceComparator = (
    a: TranslatableEventModel,
    b: TranslatableEventModel,
): number => {
    if (a.state === EventState.final && b.state !== EventState.final) {
        return 1;
    }
    if (a.state !== EventState.final && b.state === EventState.final) {
        return -1;
    }

    if (!a.startDate) {
        return 1;
    }
    if (!b.startDate) {
        return -1;
    }

    return a.startDate.seconds - b.startDate.seconds;
};

const App = () => {
    const [state, setState] = useState<State>({
        id: 'dataLoading',
    });

    useEffect(() => {
        const initializeEventData = async (): Promise<
            InitialEventData | NoCurentIteration
        > => {
            // Now that we're signed into firebase, grab the category for the partner.
            const category = await FirestoreDao.getCategory(
                config.partnerData.categoryId,
            );

            if (!category) {
                throw new Error(
                    `Couldn\'t find category by id: ${config.partnerData.categoryId}`,
                );
            }

            // not local, single event
            if (window.tally && window.tally.eventData) {
                // When deployed, we grab the initial event data from the edge api.
                // We have to make sure to convert date fields into timestamp objects.
                const convertedInitialEventData =
                    window.tally.eventData &&
                    convertInitialEventData(window.tally.eventData);

                const eventsArray =
                    await FirestoreDao.getAllEventsForIterationByPath(
                        convertedInitialEventData.iteration.path,
                    );

                return {
                    ...convertedInitialEventData,
                    events: convertArrayToOrderedMap(
                        eventsArray,
                        'slug',
                        eventRelevanceComparator,
                    ),
                };
            }

            // this is local single event
            if (category.primaryEvent !== null) {
                const eventPath = category.primaryEvent;
                const iterationPath = getIterationPathFromEventPath(eventPath);
                const [event, iteration, eventsArray] = await Promise.all([
                    FirestoreDao.getEventByPath(eventPath),
                    FirestoreDao.getIterationByPath(iterationPath),
                    FirestoreDao.getAllEventsForIterationByPath(iterationPath),
                ]);

                const initialEventData = {
                    category,
                    iteration,
                    event,
                    events: convertArrayToOrderedMap(
                        eventsArray,
                        'slug',
                        eventRelevanceComparator,
                    ),
                };

                console.log(
                    'Found event data from firestore',
                    initialEventData,
                );
                return initialEventData;
            }

            // this is mutli event no matter if local or PWE
            const iteration = await FirestoreDao.getCurrentIteration(
                config.partnerData.categoryId,
            );
            if (!iteration) {
                return {
                    category,
                    iteration: null,
                };
            }

            const eventsArray =
                await FirestoreDao.getAllEventsForIterationByPath(
                    iteration.path,
                );

            const events = convertArrayToOrderedMap(
                eventsArray,
                'slug',
                eventRelevanceComparator,
            );

            const initialEventData = { category, iteration, events };

            if (!window.tally) {
                console.log(
                    'Found event data from firestore',
                    initialEventData,
                );
            }

            return initialEventData;
        };

        const initializeUser = async (): Promise<UserAuthData> => {
            await initializeAnalytics(config.partnerData);

            // Check if a previous user exists.
            const firebaseUser = await FirebaseAuth.checkForCurrentUser();
            FirebaseAuth.registerListener();
            if (!firebaseUser) {
                // if we have no previous user. sign them in anonymously
                // so we can read from firestore.
                const userCredential = await FirebaseAuth.signInAnonymously();
                const uid = userCredential.user!.uid;
                return { type: 'annonymous', uid };
            }

            if (firebaseUser.isAnonymous) {
                if (
                    config.partnerData.authenticationMethod ===
                    authenticationMethods.none
                ) {
                    const tallyUser = await FirestoreDao.getUserWithPrivateData(
                        firebaseUser.uid,
                    );
                    if (tallyUser) {
                        return {
                            type: 'initialized',
                            uid: firebaseUser.uid,
                            ...tallyUser,
                        };
                    }
                }
                return { type: 'annonymous', uid: firebaseUser.uid };
            }

            const tallyUser = await FirestoreDao.getUserWithPrivateData(
                firebaseUser.uid,
            );

            if (tallyUser) {
                return {
                    type: 'initialized',
                    uid: firebaseUser.uid,
                    ...tallyUser,
                };
            }

            return { type: 'authenticated', uid: firebaseUser.uid };
        };

        initializeUser()
            .then(async (userAuthData) => {
                const initialEventData = await initializeEventData();
                return { userAuthData, initialEventData };
            })
            .then(
                ({ userAuthData, initialEventData }) => {
                    if (initialEventData.iteration) {
                        setState({
                            id: 'dataLoadingSuccess',
                            initialEventData,
                            userAuthData,
                        });
                        return;
                    }
                    const error = new Error(
                        `Could not find current iteration for category ${config.partnerData.categoryId}`,
                    );
                    console.error(error.message);
                    setState({
                        id: 'dataLoadingFailure',
                        error,
                    });
                },
                (error) => {
                    log.error('Error loading initial data', error);
                    setState({
                        id: 'dataLoadingFailure',
                        error:
                            error instanceof Error
                                ? error
                                : new Error('Unknown initial loading error'),
                    });
                },
            );
    }, [setState]);

    const onNextTallyLoadingIteration = () => {
        setState((prevState) => {
            if (prevState.id === 'dataLoadingSuccess') {
                return {
                    ...prevState,
                    id: 'dataLoadingSuccessAndUiReady',
                };
            }
            return prevState;
        });
    };

    switch (state.id) {
        case 'dataLoading':
        case 'dataLoadingFailure':
        case 'dataLoadingSuccess': {
            // If we finished initializing but don't have an event, stop animating.
            const animate = state.id !== 'dataLoadingFailure';
            return (
                <TallyLoadingFullScreen
                    animate={animate}
                    onNextIteration={onNextTallyLoadingIteration}
                />
            );
        }
        case 'dataLoadingSuccessAndUiReady':
            return (
                <ThemeProvider theme={muiTheme}>
                    <UserAgreementProvider>
                        <InitialEventDataProvider
                            value={state.initialEventData}
                        >
                            <>
                                <CssBaseline />
                                <GlobalCss />
                                <BrowserRouter>
                                    <LocaleProvider
                                        supportedLanguages={
                                            config.partnerData.properties
                                                .supportedLanguages
                                        }
                                    >
                                        <PartnerProvider>
                                            <UserStateProvider
                                                initialUserAuthData={
                                                    state.userAuthData
                                                }
                                            >
                                                <UserStorageConsentProvider>
                                                    <AnalyticsProvider
                                                        category={
                                                            state
                                                                .initialEventData
                                                                .category
                                                        }
                                                    >
                                                        <GroupProvider
                                                            iterationId={
                                                                state
                                                                    .initialEventData
                                                                    .iteration
                                                                    .id
                                                            }
                                                        >
                                                            <>
                                                                {theme.disableConsent ? null : (
                                                                    <UserStorageConsentModal />
                                                                )}

                                                                <Iteration
                                                                    initialEventData={
                                                                        state.initialEventData
                                                                    }
                                                                />
                                                            </>
                                                        </GroupProvider>
                                                    </AnalyticsProvider>
                                                </UserStorageConsentProvider>
                                            </UserStateProvider>
                                        </PartnerProvider>
                                    </LocaleProvider>
                                </BrowserRouter>
                            </>
                        </InitialEventDataProvider>
                    </UserAgreementProvider>
                </ThemeProvider>
            );
    }
};

export default App;
