import { useReducer } from 'react';

import {
    CurrentAssignedTriviaQuestion,
    PastAssignedTriviaQuestion,
    UserAnswerResponse,
    UserTriviaState,
} from 'src/services/ServerApi';
import { TriviaInterstitialAd } from 'src/util/TallyFirestore';

type InitialLoading = {
    id: 'initialLoading';
};

type Welcome = {
    id: 'welcome';
};

type AssignLoading = {
    id: 'assignLoading';
};

type AssignLoadingError = {
    id: 'assignLoadingError';
    errorMessage: string;
};

type InitialLoadingError = {
    id: 'initialLoadingError';
    errorMessage: string;
};

export type UserTriviaStatePast = {
    id: 'past';
    past: PastAssignedTriviaQuestion[];
    numberOfQuestions: number;
};

export type UserTriviaStateCurrent = {
    id: 'current';
    current: CurrentAssignedTriviaQuestion;
    past: PastAssignedTriviaQuestion[];
    numberOfQuestions: number;
};

export type AnswerLoading = {
    id: 'answerLoading';

    current: CurrentAssignedTriviaQuestion;
    selectedOptionId: string | null;

    past: PastAssignedTriviaQuestion[];
    numberOfQuestions: number;
};

type AnswerLoadingError = {
    id: 'answerLoadingError';

    current: CurrentAssignedTriviaQuestion;
    selectedOptionId: string | null;

    past: PastAssignedTriviaQuestion[];

    errorMessage: string;
};

export type AnswerSuccess = {
    id: 'answerSuccess';
    past: PastAssignedTriviaQuestion[];
    // we only show points for the latest answered question
    latestQuestionPoints: number;
    numberOfQuestions: number;
};

type PostAnswerAd = {
    id: 'postAnswerAd';
    ad: TriviaInterstitialAd;
} & Omit<AnswerSuccess, 'id'>;

export type NextLoading = {
    id: 'nextLoading';
    past: PastAssignedTriviaQuestion[];
    numberOfQuestions: number;
    latestQuestionPoints?: number;
    ad?: TriviaInterstitialAd;
};

export type NextLoadingError = {
    id: 'nextLoadingError';
    errorMessage: string;
} & Omit<NextLoading, 'id'>;

type GameOver = {
    id: 'gameOver';
    past: PastAssignedTriviaQuestion[];
};

export type TriviaGameState =
    | InitialLoading
    | InitialLoadingError
    | Welcome
    | UserTriviaStateCurrent
    | UserTriviaStatePast
    | AssignLoading
    | AssignLoadingError
    | AnswerLoading
    | AnswerLoadingError
    | AnswerSuccess
    | PostAnswerAd
    | NextLoading
    | NextLoadingError
    | GameOver;

export type TriviaGameStateWithQuestion =
    | UserTriviaStateCurrent
    | AnswerLoading
    | UserTriviaStatePast
    | NextLoadingError
    | AnswerSuccess
    | NextLoading;

type TriviaGameAction =
    | {
          type: 'initialLoadingFailure';
          data: { errorMessage: string };
      }
    | {
          type: 'initialLoadingSuccess';
          data: UserTriviaState;
      }
    | {
          type: 'assignLoadingRequest';
      }
    | {
          type: 'assignLoadingFailure';
          data: {
              errorMessage: string;
          };
      }
    | {
          type: 'assignLoadingSuccess';
          data: {
              current: CurrentAssignedTriviaQuestion;
              numberOfQuestions: number;
          };
      }
    | {
          type: 'showAd';
          data: {
              ad: TriviaInterstitialAd;
          };
      }
    | {
          type: 'nextLoadingRequest';
      }
    | {
          type: 'nextLoadingFailure';
          data: {
              errorMessage: string;
          };
      }
    | {
          type: 'nextLoadingSuccess';
          data: {
              current: CurrentAssignedTriviaQuestion;
          };
      }
    | {
          type: 'answerLoadingRequest';
          data: {
              selectedOptionId: string | null;
          };
      }
    | {
          type: 'answerLoadingFailure';
          data: {
              errorMessage: string;
          };
      }
    | {
          type: 'answerLoadingSuccess';
          data: UserAnswerResponse;
      }
    | {
          type: 'gameOver';
      };

const reducer = (
    state: TriviaGameState,
    action: TriviaGameAction,
): TriviaGameState => {
    switch (action.type) {
        case 'initialLoadingFailure':
            return {
                id: 'initialLoadingError',
                errorMessage: action.data.errorMessage,
            };
        case 'initialLoadingSuccess': {
            const { data } = action;

            if (data.current !== undefined) {
                return {
                    id: 'current',
                    current: data.current,
                    past: data.past,
                    numberOfQuestions: data.numberOfQuestions,
                };
            }
            if (data.past.length === 0) {
                return {
                    id: 'welcome',
                };
            }

            if (data.past.length === data.numberOfQuestions) {
                return {
                    id: 'gameOver',
                    past: data.past,
                };
            }
            return {
                id: 'past',
                past: data.past,
                numberOfQuestions: data.numberOfQuestions,
            };
        }
        case 'nextLoadingRequest':
            if (state.id === 'postAnswerAd') {
                return {
                    id: 'nextLoading',
                    numberOfQuestions: state.numberOfQuestions,
                    past: state.past,
                    ad: state.ad,
                };
            }

            if (state.id === 'past' || state.id === 'answerSuccess') {
                return {
                    id: 'nextLoading',
                    numberOfQuestions: state.numberOfQuestions,
                    past: state.past,
                    latestQuestionPoints:
                        state.id === 'answerSuccess'
                            ? state.latestQuestionPoints
                            : undefined,
                };
            }

            return state;

        case 'nextLoadingFailure':
            if (state.id === 'nextLoading') {
                return {
                    ...state,
                    id: 'nextLoadingError',
                    errorMessage: action.data.errorMessage,
                };
            }

            return state;
        case 'assignLoadingRequest':
            if (state.id === 'welcome') {
                return {
                    id: 'assignLoading',
                };
            }

            return state;
        case 'assignLoadingSuccess': {
            if (state.id === 'assignLoading') {
                return {
                    id: 'current',
                    current: action.data.current,
                    past: [],
                    numberOfQuestions: action.data.numberOfQuestions,
                };
            }
            return state;
        }
        case 'assignLoadingFailure': {
            if (state.id === 'assignLoading') {
                return {
                    ...state,
                    id: 'assignLoadingError',
                    errorMessage: action.data.errorMessage,
                };
            }
            return state;
        }
        case 'nextLoadingSuccess': {
            if (state.id === 'nextLoading') {
                return {
                    id: 'current',
                    current: action.data.current,
                    past: state.past,
                    numberOfQuestions: state.numberOfQuestions,
                };
            }
            return state;
        }
        case 'answerLoadingRequest': {
            if (state.id === 'current')
                return {
                    id: 'answerLoading',
                    current: state.current,
                    past: state.past,
                    selectedOptionId: action.data.selectedOptionId,
                    numberOfQuestions: state.numberOfQuestions,
                };
            return state;
        }
        case 'answerLoadingFailure': {
            if (state.id === 'answerLoading') {
                return {
                    ...state,
                    id: 'answerLoadingError',
                    errorMessage: action.data.errorMessage,
                };
            }
            return state;
        }
        case 'answerLoadingSuccess': {
            if (state.id === 'answerLoading') {
                return {
                    id: 'answerSuccess',
                    past: [
                        ...state.past,
                        {
                            ...state.current,
                            selectedOptionId: action.data.selectedOptionId,
                            correctOptionId: action.data.correctOptionId,
                            answeredDate: action.data.answeredDate,
                            options: state.current.options,
                        },
                    ],
                    latestQuestionPoints: action.data.points,
                    numberOfQuestions: state.numberOfQuestions,
                };
            }
            return state;
        }
        case 'showAd': {
            if (state.id === 'answerSuccess') {
                return {
                    ...state,
                    id: 'postAnswerAd',
                    ad: action.data.ad,
                };
            }
            return state;
        }
        case 'gameOver': {
            if (state.id === 'answerSuccess' || state.id === 'postAnswerAd') {
                return {
                    id: 'gameOver',
                    past: state.past,
                };
            }
            return state;
        }
    }
};

const useTriviaStateReducer = () => {
    return useReducer<TriviaGameState, TriviaGameAction>(reducer, {
        id: 'initialLoading',
    });
};

export default useTriviaStateReducer;
