import { IntlFormatters } from 'react-intl';
import { UserAuthData } from 'src/contexts/UserStateContext';
import { TileCheckbox } from '../../utils';

export const getMarketingCheckboxes = ({
    userData,
    smsMarketingLabel,
    emailMarketingLabel,
    optionalOptInLabel,
    tallyOptInLabel,
    emailOptInIsMandatory,
    smsMarketingEnabled,
    smsOptInIsMandatory,
    optionalOptInEnabled,
    optionalOptInIsMandatory,
    tallyOptInIsActive,
    optInDefault,
    intl,
}: {
    userData: UserAuthData;
    smsMarketingLabel?: string;
    emailMarketingLabel?: string;
    optionalOptInLabel?: string;
    tallyOptInLabel?: string;
    emailOptInIsMandatory?: boolean;
    smsMarketingEnabled?: boolean;
    smsOptInIsMandatory?: boolean;
    optionalOptInEnabled?: boolean;
    optionalOptInIsMandatory?: boolean;
    tallyOptInIsActive?: boolean;
    optInDefault?: boolean;
    intl: IntlFormatters;
}): TileCheckbox[] => {
    const smsOptInText =
        smsMarketingLabel ||
        intl.formatMessage({ id: 'optIn.marketing.phone.label' });

    const emailOptInMessage =
        emailMarketingLabel ||
        intl.formatMessage({ id: 'optIn.marketing.email.label' });

    const optionalOptInOnboardingLabel =
        optionalOptInLabel ||
        intl.formatMessage({ id: 'optIn.marketing.3p.label' });

    const tallyOptInOnboardingLabel =
        tallyOptInLabel ||
        intl.formatMessage({ id: 'optIn.marketing.tally.label' });
    const contactInfo =
        userData.type === 'initialized' ? userData.contactInfo : undefined;
    const checkboxes: TileCheckbox[] = [
        {
            field: 'emailCheckboxChecked',
            defaultValue: Boolean(
                (contactInfo && contactInfo.marketingOptIn) || optInDefault,
            ),
            label: emailOptInMessage,
            mandatory: Boolean(emailOptInIsMandatory),
        },
    ];
    smsMarketingEnabled &&
        checkboxes.push({
            field: 'smsCheckboxChecked',
            defaultValue: Boolean(contactInfo && contactInfo.smsMarketingOptIn),
            label: smsOptInText,
            mandatory: Boolean(smsOptInIsMandatory),
        });
    optionalOptInEnabled &&
        checkboxes.push({
            field: 'optionalOptIn',
            defaultValue: Boolean(
                (contactInfo && contactInfo.optionalOptIn) || optInDefault,
            ),
            label: optionalOptInOnboardingLabel,
            mandatory: Boolean(optionalOptInIsMandatory),
        });
    tallyOptInIsActive &&
        checkboxes.push({
            field: 'tallyOptIn',
            defaultValue: Boolean(
                contactInfo && contactInfo.tallyOptIn !== undefined,
            ),
            label: tallyOptInOnboardingLabel,
            mandatory: false,
        });

    return checkboxes;
};
