import styled from '@emotion/styled';
import React, { forwardRef, RefForwardingComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Color } from 'src/styles/Constants';
import theme from 'src/util/Theme';

import TouchButton from '../../../shared/controls/TouchButton';

type Props = {
    text: JSX.Element | string;
    onClick: () => void;
    onClose: () => void;
    isDuringRequest: boolean;
};

const InformationModuleButtons: RefForwardingComponent<
    HTMLDivElement,
    Props
> = ({ text, onClick, onClose, isDuringRequest }, forwardedRef) => {
    return (
        <>
            <AcceptButtonContainer ref={forwardedRef}>
                <TouchButton
                    backgroundColor={
                        theme.themeHighlightFallbackColor || Color.T31_BLACK
                    }
                    disabled={isDuringRequest}
                    textColor={Color.P1_WHITE}
                    displayText={text}
                    onClick={onClick}
                    stretchWidth={true}
                    secondaryStyle={true}
                />
                <CloseButton onClick={onClose}>
                    <FormattedMessage id="informationModule.closeButton" />
                </CloseButton>
            </AcceptButtonContainer>
        </>
    );
};

export default forwardRef(InformationModuleButtons);

export const CLOSE_BUTTON_HEIGHT = 18;
export const CLOSE_BUTTON_MARGIN_TOP = 12;

const AcceptButtonContainer = styled.div`
    margin: 25px 0px 0px;
    max-width: 190px;
`;

const CloseButton = styled.p`
    cursor: pointer;
    height: ${CLOSE_BUTTON_HEIGHT}px;
    max-width: 93px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: ${CLOSE_BUTTON_HEIGHT}px;
    text-decoration: underline;
    margin: ${CLOSE_BUTTON_MARGIN_TOP}px auto 0px;
`;
