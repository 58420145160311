import styled from '@emotion/styled';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import TimeUntilLock from 'src/components/shared/renderless/TimeUntilLock';
import { getCountdownString } from 'src/util/Dates';
import { TriviaGameProperties } from 'src/util/TallyFirestore';
import theme from 'src/util/Theme';
import {
    CountdownHeadline,
    CountdownLabel,
} from '../PredictGame/HeroicCountdown';

type Props = {
    trivia: TriviaGameProperties;
    questionIndex: number;
    lockDate: Date;
    countdownSeconds?: number;
    onCountdownUpdated: (countdownSeconds?: number) => void;
    onCountdownExpired: () => void;
    pause?: boolean;
};

const TriviaCountdown = ({
    trivia,
    questionIndex,
    lockDate,
    countdownSeconds,
    onCountdownUpdated,
    onCountdownExpired,
    pause,
}: Props) => {
    return (
        <Container>
            <CountdownHeadline>
                <FormattedMessage
                    id="triviaGame.countdown.headline"
                    values={{
                        questionNumber: questionIndex + 1,
                        numberOfQuestions: trivia.numberOfQuestions,
                    }}
                />
            </CountdownHeadline>
            <CountdownLabel color={theme.nextLockUrgencyColor}>
                {countdownSeconds !== undefined &&
                    getCountdownString(countdownSeconds)}
            </CountdownLabel>
            {!pause && (
                <TimeUntilLock
                    countdownDate={lockDate}
                    onCountdownUpdated={onCountdownUpdated}
                    onCountdownExpired={onCountdownExpired}
                />
            )}
        </Container>
    );
};

const Container = styled.div`
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export default TriviaCountdown;
