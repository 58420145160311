import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from '@emotion/styled';
import {
    Color,
    ColorMode,
    Height,
    PistachioZIndex,
} from 'src/styles/Constants';
import {
    LockInfo,
    NextPredictionToLockInfo,
} from 'src/components/shared/ui/Toast';
import AnimatedCountdownBar from 'src/components/shared/animations/AnimatedCountdownBar';
import Theme from 'src/util/Theme';
import {
    DEFAULT_HIGHLIGHT_HOURS,
    getCountdownString,
    getDateString,
    getDaysBetween,
    getHoursBetween,
    getShortWeekday,
    getTimeString,
} from 'src/util/Dates';
import { PredictionModel } from 'src/util/TallyFirestore';

interface Props {
    visible: boolean;
    nextPredictionToLockInfo: NextPredictionToLockInfo;
    nextPredictionToLock: PredictionModel;
    onClick?: () => void;
}

export default function CountdownHeader(props: Props) {
    const { visible, nextPredictionToLockInfo, nextPredictionToLock, onClick } =
        props;

    const barColor = Theme.nextLockUrgencyColor || Color.P6_ELECTRIC_BLUE;
    /** this seems to be overengineered and causes issues with customization - suddenly the bar si blue 
nextPredictionToLockInfo && nextPredictionToLockInfo.hasAnswered
    ? Color.P6_ELECTRIC_BLUE
    : Theme.nextLockUrgencyColor;*/
    const { lockInfo } = nextPredictionToLockInfo;

    return (
        <Container visible={visible} onClick={visible ? onClick : undefined}>
            {lockInfo === 'lockDescription' ? (
                <AnimatedCountdownBar
                    barBackgroundColorMode={ColorMode.DARK}
                    barColor={barColor}
                />
            ) : (
                <AnimatedCountdownBar
                    barBackgroundColorMode={ColorMode.DARK}
                    barColor={barColor}
                    timeLeftCount={lockInfo.timeLeftCount}
                />
            )}
            <Label>
                <LockText
                    lockInfo={lockInfo}
                    nextPredictionToLock={nextPredictionToLock}
                />
            </Label>
        </Container>
    );
}

const LockText = ({
    lockInfo,
    nextPredictionToLock,
}: {
    lockInfo: LockInfo;
    nextPredictionToLock: PredictionModel;
}) => {
    const intl = useIntl();

    if (lockInfo === 'lockDescription') {
        return (
            <FormattedMessage
                id="countdownHeader.label"
                values={{
                    timeLeftText: nextPredictionToLock.lockDescription,
                }}
            />
        );
    }

    const { timeLeftCount, lockDate } = lockInfo;
    const now = new Date();
    const daysUntilStart = getDaysBetween(now, lockDate);
    const timeString = getTimeString(lockDate);

    if (daysUntilStart === 1) {
        return (
            <FormattedMessage
                id="countdownHeader.label"
                values={{
                    timeLeftText: intl.formatMessage(
                        { id: 'timeUntilLock.timeUntilLockMode.medium' },
                        { timeString },
                    ),
                }}
            />
        );
    }
    if (daysUntilStart === 0) {
        const hoursUntilStart = getHoursBetween(now, lockDate);

        if (hoursUntilStart < DEFAULT_HIGHLIGHT_HOURS) {
            return (
                <FormattedMessage
                    id="countdownHeader.label"
                    values={{
                        timeLeftText: getCountdownString(timeLeftCount),
                    }}
                />
            );
        }
        return (
            <FormattedMessage
                id="countdownHeader.label"
                values={{ timeLeftText: timeString }}
            />
        );
    }

    if (daysUntilStart <= 6) {
        return (
            <>
                <FormattedMessage
                    id="countdownHeader.label"
                    values={{
                        timeLeftText: `${intl.formatMessage({
                            id: getShortWeekday(lockDate),
                        })} ${timeString}`,
                    }}
                />
            </>
        );
    }

    return <>{getDateString(lockDate)}</>;
};

const Container = styled.div<{ visible: boolean }>`
    position: sticky;
    top: ${Height.TITLEBAR}px;
    width: 100%;
    z-index: ${PistachioZIndex.STICKY_HEADER};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: ${(props) =>
        props.visible ? `${Height.COUNTDOWN_HEADER}px` : '0px'};
    opacity: ${(props) => (props.visible ? '1' : '0')};
    transition:
        height 0.3s,
        opacity 0.3s;
`;

const Label = styled.div`
    display: flex;
    position: absolute;
    color: ${Color.G11_WHITE};
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 18px;
    text-transform: upperCase;
`;
