import React from 'react';
import { useIntl } from 'react-intl';
import { getInviteLink } from 'src/contexts/GroupProvider';
import { Group } from 'src/types';
import { EventModel } from 'src/util/TallyFirestore';

import ShareLinkButton from '../shared/ui/ShareLinkButton';

type Props = {
    group: Group;
    event: EventModel | undefined;
    onCopyToClipboardFailed?: (error: unknown) => void;
};

const ShareGroupLinkButton = ({
    group,
    event,
    onCopyToClipboardFailed,
}: Props) => {
    const intl = useIntl();

    return (
        <ShareLinkButton
            buttonText={intl.formatMessage({
                id: 'group.shareLink.button',
            })}
            title={intl.formatMessage(
                {
                    id: 'group.share.title',
                },
                {
                    groupName: group.name,
                    eventName: event ? event.shortName : '',
                },
            )}
            text={
                event && event.prizes[0]
                    ? intl.formatMessage(
                          {
                              id: 'group.share.text.prize',
                          },
                          {
                              eventName: event.shortName,
                              prize: event.prizes[0],
                          },
                      )
                    : event
                    ? intl.formatMessage(
                          {
                              id: 'group.share.text.noPrize',
                          },
                          { eventName: event.shortName },
                      )
                    : intl.formatMessage(
                          {
                              id: 'group.share.text.noEvent',
                          },
                          { groupName: group.name },
                      )
            }
            url={getInviteLink(window.location, group)}
            onCopyToClipboardFailed={onCopyToClipboardFailed}
        />
    );
};

export default ShareGroupLinkButton;
