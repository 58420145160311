import React, { ReactNode, createContext, useContext } from 'react';
import ReactGA from 'react-ga4';
import { TrackJS } from 'trackjs';
import queryString from 'query-string';
import packageJson from 'src/../package.json';
import {
    getContactInfo,
    getDetails,
    useUserState,
} from 'src/contexts/UserStateContext';
import {
    CategoryModel,
    EventModel,
    TranslatableEventModel,
} from 'src/util/TallyFirestore';
import { PartnerData } from 'src/util/ConfigTypes';
import {
    AppArea,
    EventName,
    Screen,
    eventNameWithSuffix,
} from 'src/util/AnalyticsConstants';
import FacebookPixel from 'src/services/FacebookPixel';
import HelpScout from 'src/util/HelpScout';
import browserMode from 'src/util/BrowserMode';
import config from 'src/util/Config';
import log from 'src/util/Logger';
import { useUserStorageConsent } from 'src/hooks/useUserStorageConsent';

const HELPSCOUT_BEACON_ID = '8cb0022e-2918-423e-a5b2-c6447acfab5f';

type LogEventProps = {
    eventName: string;
    eventCategory?: AppArea;
    params?: any;
    gameEvent?: TranslatableEventModel | EventModel;
};

type LogModalViewProps = {
    modalName: string;
    gameEvent?: TranslatableEventModel | EventModel;
    params?: object;
};

type LogScreenViewProps = {
    screenName: string;
    screenType: Screen;
    appArea: AppArea;
    params?: object;
    gameEvent?: TranslatableEventModel | EventModel;
};

export type Analytics = {
    logEvent: (props: LogEventProps) => void;
    logModalView: (props: LogModalViewProps) => void;
    logScreenView: (props: LogScreenViewProps) => void;
};

type Props = {
    category: CategoryModel;
    children: ReactNode;
};

export const AnalyticsContext = createContext<Analytics>({
    logEvent: () => {},
    logModalView: () => {},
    logScreenView: () => {},
});

const dummyFunc = () => {};

const dummyAnalytics: Analytics = [
    'logEvent',
    'logModalView',
    'logScreenView',
].reduce(
    (acc, key): Analytics => ({ ...acc, [key]: dummyFunc }),
    {} as Analytics,
);

const getCategoryReportingName = (category?: CategoryModel): string => {
    return category ? category.reportingName : 'unknown';
};

const logUtmParameters = () => {
    const query = window.location && window.location.search;
    const parsedQuery = query && queryString.parse(query);

    // Log custom metadata to TrackJS to help us debug issues
    // by knowing where user is coming from
    if (parsedQuery) {
        if (typeof parsedQuery.utm_source === 'string') {
            TrackJS.addMetadata('utm_source', parsedQuery.utm_source);
        }

        if (typeof parsedQuery.utm_medium === 'string') {
            TrackJS.addMetadata('utm_medium', parsedQuery.utm_medium);
        }

        if (typeof parsedQuery.utm_campaign === 'string') {
            TrackJS.addMetadata('utm_campaign', parsedQuery.utm_campaign);
        }
    }
};

const logFBEvent = (eventName: string, params?: any) => {
    if (Boolean(window.fbq)) {
        window.fbq('trackCustom', eventName, params);
    }
};

const logFBPageView = () => {
    if (Boolean(window.fbq)) {
        window.fbq('track', 'PageView');
    }
};

export const initialize = async (partnerData: PartnerData) => {
    if (config.partnerData.facebookAppId) {
        try {
            FacebookPixel.initialize(config.partnerData.facebookAppId);
        } catch (error) {
            // Analytics module has safeguards around the Facebook Pixel not initializing correctly,
            // so it's safe to proceed if FacebookPixel.initialize fails.
            log.error('Failed to initialize Facebook Pixel', error);
        }
    }

    // Read all gaIDs - the analytics.google property can take a string (bakwards compatibility) or an array of strings
    const gaIDs: string[] = Array.isArray(partnerData.analyticsKeys.google)
        ? partnerData.analyticsKeys.google
        : [partnerData.analyticsKeys.google];
    const gaInits = gaIDs.map((item) => ({
        gaOptions: { name: item.replace(/-/g, '') },
        trackingId: item,
    }));

    try {
        await ReactGA.initialize(gaInits);
    } catch (error) {
        log.error('Failed to initialize Google Analytics SDK', error);
    }

    HelpScout.init(HELPSCOUT_BEACON_ID);

    if (!config.trackJs.disable) {
        const analyticsDebug =
            partnerData.environment === 'DEV' ||
            partnerData.environment === 'LOCAL';

        TrackJS.install({
            application: config.trackJs.projectName,
            console: {
                display: analyticsDebug,
                error: analyticsDebug,
            },
            onError: (payload) => {
                payload.network = payload.network.filter((item) => {
                    return (
                        item.url.indexOf('analytics.google.com') === -1 &&
                        item.url.indexOf('facebook.com') === -1
                    );
                });
                return true;
            },
            token: config.trackJs.key,
            version:
                process.env.REACT_APP_MEGAPHONE_VERSION ||
                `${packageJson.version}-unknown`,
        });

        TrackJS.addMetadata('Partner', partnerData.partnerId);
        TrackJS.addMetadata('Environment', config.environment);
        TrackJS.addMetadata('BrowserMode', browserMode);

        logUtmParameters();
    }
};

export const AnalyticsProvider = ({ category, children }: Props) => {
    const { consent } = useUserStorageConsent();
    const userAuthData = useUserState();

    const getBaseLogParams = (
        gameEvent?: TranslatableEventModel | EventModel,
    ) => {
        const userDetails = userAuthData && getDetails(userAuthData);
        const userContactInfo = userAuthData && getContactInfo(userAuthData);

        const baseLogParams = {
            browser_mode: browserMode,
            category: category ? category.name : undefined,
            category_reporting_name: getCategoryReportingName(category),
            currency: 'USD', // Required for FB analytics and FB Ads to work well
            event_name: gameEvent ? gameEvent.name : undefined,
            event_short_name: gameEvent ? gameEvent.shortName : undefined,
            event_state: gameEvent ? gameEvent.state : undefined,
            partner: config.partnerData.partnerId,
            user_games_played:
                userDetails && userDetails.gamesPlayed
                    ? userDetails.gamesPlayed
                    : undefined,
            user_sms_alerts_state:
                userContactInfo && userContactInfo.smsAlertsState
                    ? userContactInfo.smsAlertsState.toLowerCase()
                    : undefined,
        };

        return baseLogParams;
    };

    const logEvent = ({
        eventName,
        eventCategory,
        params,
        gameEvent,
    }: LogEventProps) => {
        const logParams = {
            ...getBaseLogParams(gameEvent),
            ...params,
        };

        logFBEvent(eventName, logParams);

        ReactGA.event({
            action: eventName,
            category: eventCategory || AppArea.game,
        });
    };

    const logModalView = ({
        modalName,
        gameEvent,
        params,
    }: LogModalViewProps) => {
        const modalViewParams = {
            ...getBaseLogParams(gameEvent),
            ...params,
        };

        logEvent({
            eventName: eventNameWithSuffix(EventName.modalView, modalName),
            params: modalViewParams,
            gameEvent,
        });

        ReactGA.send({ hitType: 'modalView', page: modalName });
    };

    const logScreenView = ({
        screenName,
        appArea,
        screenType,
        params,
        gameEvent,
    }: LogScreenViewProps) => {
        const screenViewParams = {
            area: appArea,
            screen: screenType,
            ...getBaseLogParams(gameEvent),
            ...params,
        };

        // Log standard event, so we can tack on the params.
        logEvent({
            eventName: eventNameWithSuffix(
                EventName.pageViewPrefix,
                screenType,
            ),
            eventCategory: appArea,
            params: screenViewParams,
            gameEvent,
        });

        // Log a single generic page view event, which allows aggregate reporting
        // on all page views regardless what they were. Can't use the .logFBPageView()
        // because it takes no params.
        logEvent({
            eventName: EventName.pageViewFB,
            eventCategory: appArea,
            params: screenViewParams,
            gameEvent,
        });

        // Log standard FB Page View. Logging this even though seems like a a dupe
        // since we fear FBA uses formal pageviews as a means to calculating
        // user engagement.
        logFBPageView();

        console.log(window.location.search);

        ReactGA.send({
            hitType: 'pageview',
            page:
                window.location.pathname +
                '/' +
                screenName +
                window.location.search,
        });
    };

    const analytics: Analytics = {
        logEvent,
        logModalView,
        logScreenView,
    };

    return (
        <AnalyticsContext.Provider
            value={consent?.analytics ? analytics : dummyAnalytics}
        >
            {children}
        </AnalyticsContext.Provider>
    );
};

export const AnalyticsConsumer = AnalyticsContext.Consumer;

export const useAnalytics = (): Analytics => useContext(AnalyticsContext);
