import React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import LeaderboardCard, {
    Props as LeaderboardCardProps,
} from './LeaderboardCard';
import routeUrls, { getEventSlugSearchParam } from 'src/routes';
import {
    Leaderboards,
    NoGroupsLeaderboards,
    TeamGroupLeaderboards,
    TeamUserGroupLeaderboards,
    UserGroupLeaderboards,
    VenueGroupLeaderboards,
} from 'src/hooks/useLiveEntityLeaderboards';
import { Grid, Typography, withStyles } from '@material-ui/core';
import {
    LeaderboardEntry,
    LeaderboardEntryWithGroupRanks,
    SummarizedLeaderboard,
} from 'src/util/TallyFirestore';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as InfoIcon } from 'src/images/icons/info-white-18dp.svg';
import styled from '@emotion/styled';
import config from 'src/util/Config';
import { Color } from 'src/styles/Constants';
import { useHistory, useLocation } from 'react-router-dom';
import { OrderedMap } from 'immutable';
import { EventModel } from 'src/util/TallyFirestore';

const StyledTypography = withStyles({
    root: {
        color:
            config.partnerData.properties.leaderboardsPage?.contentTextColor ||
            Color.G1_BLACK,
        'margin-top': '24px',
    },
})(Typography);

type Props = {
    url: string;
    leaderboards: Leaderboards;
    events: OrderedMap<string, EventModel>;
    omitCorrectAnswers?: boolean;
};

const findPlayerEntryFromSummary = (
    playerId: string,
    leaderboardSummary: SummarizedLeaderboard,
): LeaderboardEntry | undefined => {
    for (const rank of leaderboardSummary.ranks) {
        const foundPlayer = rank.players.find(
            (player) => player.uid === playerId,
        );
        if (foundPlayer) {
            return { ...foundPlayer, rank: rank.rank, points: rank.points };
        }
    }
};

const makeIntraGroupLeaderboardEntry = (
    generalLeaderboardEntry: LeaderboardEntryWithGroupRanks,
    groupId: string,
): LeaderboardEntry | undefined => {
    if (!generalLeaderboardEntry.groupRanks) {
        return;
    }
    const groupRank = generalLeaderboardEntry.groupRanks.find(
        (gr) => gr.groupId === groupId,
    );
    if (groupRank) {
        return { ...generalLeaderboardEntry, rank: groupRank.userRank };
    }
};

const getCardsTeamUserGroup = (
    leaderboards: TeamUserGroupLeaderboards,
    url: string,
    intl: IntlShape,
): LeaderboardCardProps[] => {
    const cards: LeaderboardCardProps[] = [];
    const { groupModeProperties } = leaderboards;

    const generalLeaderboardCard: LeaderboardCardProps = {
        name:
            groupModeProperties.generalLeaderboardName ||
            intl.formatMessage({
                id: 'group.generalLeaderboard',
            }),
        imageUrl: groupModeProperties.generalLeaderboardImageUrl,
        pathName: `${url}${routeUrls.generalLeaderboard}`,
        leaderboard: leaderboards.generalLeaderboard,
    };
    cards.push(generalLeaderboardCard);

    const leaderboardEntryWithRanks =
        leaderboards.generalLeaderboard.leaderboardEntry;

    {
        const interTeamCard = {
            name: groupModeProperties.interGroupLeaderboardName,
            imageUrl: groupModeProperties.interGroupLeaderboardImageUrl,
            pathName: `${url}${routeUrls.interGroupLeaderboard}/teams`,
            leaderboard: {
                leaderboardSummary:
                    leaderboards.teamGroup.interGroupLeaderboardSummary,
                leaderboardEntry:
                    leaderboards.teamGroup.group &&
                    leaderboards.teamGroup.interGroupLeaderboardSummary
                        ? findPlayerEntryFromSummary(
                              leaderboards.teamGroup.group.id,
                              leaderboards.teamGroup
                                  .interGroupLeaderboardSummary,
                          )
                        : undefined,
            },
        };
        cards.push(interTeamCard);
    }

    if (!leaderboardEntryWithRanks) {
        return cards;
    }

    if (
        leaderboards.userGroup.group &&
        leaderboards.userGroup.intraGroupLeaderboardSummary
    ) {
        cards.push({
            name: leaderboards.userGroup.group.name,
            imageUrl: groupModeProperties.userGroupImageUrl,
            pathName: `${url}${routeUrls.intraGroupLeaderboard}/usergroups`,
            leaderboard: {
                leaderboardSummary:
                    leaderboards.userGroup.intraGroupLeaderboardSummary,
                leaderboardEntry: makeIntraGroupLeaderboardEntry(
                    leaderboardEntryWithRanks,
                    leaderboards.userGroup.group.id,
                ),
            },
        });
    }

    if (
        leaderboards.teamGroup.group &&
        leaderboards.teamGroup.intraGroupLeaderboardSummary
    ) {
        cards.push({
            name: leaderboards.teamGroup.group.name,
            imageUrl: leaderboards.teamGroup.group.imageUrl,
            pathName: `${url}${routeUrls.intraGroupLeaderboard}/teams`,
            leaderboard: {
                leaderboardSummary:
                    leaderboards.teamGroup.intraGroupLeaderboardSummary,

                leaderboardEntry: makeIntraGroupLeaderboardEntry(
                    leaderboardEntryWithRanks,
                    leaderboards.teamGroup.group.id,
                ),
            },
        });
    }
    return cards;
};

const getCardsTeam = (
    leaderboards: TeamGroupLeaderboards,
    url: string,
    intl: IntlShape,
): LeaderboardCardProps[] => {
    const cards: LeaderboardCardProps[] = [];
    const { groupModeProperties } = leaderboards;

    const generalLeaderboardCard: LeaderboardCardProps = {
        name:
            groupModeProperties.generalLeaderboardName ||
            intl.formatMessage({
                id: 'group.generalLeaderboard',
            }),
        imageUrl: groupModeProperties.generalLeaderboardImageUrl,
        pathName: `${url}${routeUrls.generalLeaderboard}`,
        leaderboard: leaderboards.generalLeaderboard,
    };
    cards.push(generalLeaderboardCard);

    const leaderboardEntryWithRanks =
        leaderboards.generalLeaderboard.leaderboardEntry;

    {
        const interTeamCard = {
            name: groupModeProperties.interGroupLeaderboardName,
            imageUrl: groupModeProperties.interGroupLeaderboardImageUrl,
            pathName: `${url}${routeUrls.interGroupLeaderboard}/teams`,
            leaderboard: {
                leaderboardSummary:
                    leaderboards.teamGroup.interGroupLeaderboardSummary,
                leaderboardEntry:
                    leaderboards.teamGroup.group &&
                    leaderboards.teamGroup.interGroupLeaderboardSummary
                        ? findPlayerEntryFromSummary(
                              leaderboards.teamGroup.group.id,
                              leaderboards.teamGroup
                                  .interGroupLeaderboardSummary,
                          )
                        : undefined,
            },
        };
        cards.push(interTeamCard);
    }

    if (!leaderboardEntryWithRanks) {
        return cards;
    }

    if (
        leaderboards.teamGroup.group &&
        leaderboards.teamGroup.intraGroupLeaderboardSummary
    ) {
        cards.push({
            name: leaderboards.teamGroup.group.name,
            imageUrl: leaderboards.teamGroup.group.imageUrl,
            pathName: `${url}${routeUrls.intraGroupLeaderboard}/teams`,
            leaderboard: {
                leaderboardSummary:
                    leaderboards.teamGroup.intraGroupLeaderboardSummary,

                leaderboardEntry: makeIntraGroupLeaderboardEntry(
                    leaderboardEntryWithRanks,
                    leaderboards.teamGroup.group.id,
                ),
            },
        });
    }

    return cards;
};

const getCardsUserGroup = (
    leaderboards: UserGroupLeaderboards,
    url: string,
    intl: IntlShape,
): LeaderboardCardProps[] => {
    const cards: LeaderboardCardProps[] = [];
    const { groupModeProperties } = leaderboards;

    const generalLeaderboardCard: LeaderboardCardProps = {
        name:
            groupModeProperties.generalLeaderboardName ||
            intl.formatMessage({
                id: 'group.generalLeaderboard',
            }),
        imageUrl: groupModeProperties.generalLeaderboardImageUrl,
        pathName: `${url}${routeUrls.generalLeaderboard}`,
        leaderboard: leaderboards.generalLeaderboard,
    };
    cards.push(generalLeaderboardCard);

    const leaderboardEntryWithRanks =
        leaderboards.generalLeaderboard.leaderboardEntry;

    if (!leaderboardEntryWithRanks) {
        return cards;
    }

    if (
        leaderboards.userGroup.group &&
        leaderboards.userGroup.intraGroupLeaderboardSummary
    ) {
        cards.push({
            name: leaderboards.userGroup.group.name,
            imageUrl: groupModeProperties.userGroupImageUrl,
            pathName: `${url}${routeUrls.intraGroupLeaderboard}/usergroups`,
            leaderboard: {
                leaderboardSummary:
                    leaderboards.userGroup.intraGroupLeaderboardSummary,
                leaderboardEntry: makeIntraGroupLeaderboardEntry(
                    leaderboardEntryWithRanks,
                    leaderboards.userGroup.group.id,
                ),
            },
        });
    }

    return cards;
};

const getCardsVenue = (
    leaderboards: VenueGroupLeaderboards,
    url: string,
    intl: IntlShape,
): LeaderboardCardProps[] => {
    const cards: LeaderboardCardProps[] = [];
    const { groupModeProperties } = leaderboards;

    const generalLeaderboardCard: LeaderboardCardProps = {
        name:
            groupModeProperties.generalLeaderboardName ||
            intl.formatMessage({
                id: 'group.generalLeaderboard',
            }),
        imageUrl: groupModeProperties.generalLeaderboardImageUrl,
        pathName: `${url}${routeUrls.generalLeaderboard}`,
        leaderboard: leaderboards.generalLeaderboard,
    };
    cards.push(generalLeaderboardCard);

    const leaderboardEntryWithRanks =
        leaderboards.generalLeaderboard.leaderboardEntry;

    if (!leaderboardEntryWithRanks) {
        return cards;
    }

    if (
        leaderboards.venueGroup.group &&
        leaderboards.venueGroup.intraGroupLeaderboardSummary
    ) {
        cards.push({
            name: leaderboards.venueGroup.group.name,
            imageUrl: leaderboards.venueGroup.group.imageUrl || undefined,
            pathName: `${url}${routeUrls.intraGroupLeaderboard}/usergroups`,
            leaderboard: {
                leaderboardSummary:
                    leaderboards.venueGroup.intraGroupLeaderboardSummary,
                leaderboardEntry: makeIntraGroupLeaderboardEntry(
                    leaderboardEntryWithRanks,
                    leaderboards.venueGroup.group.id,
                ),
            },
        });
    }

    return cards;
};

const BackToGameText = ({
    onReturnToGameClick,
}: {
    onReturnToGameClick: () => void;
}) => {
    return (
        <StyledTypography>
            <InfoIcon
                style={{
                    float: 'left',
                    marginRight: '4px',
                    fill:
                        config.partnerData.properties.leaderboardsPage
                            ?.contentTextColor || Color.G1_BLACK,
                }}
            />{' '}
            <FormattedMessage
                id="leaderboardPage.backToGame"
                values={{
                    leaderboardLink: (
                        <LeaderboardLink onClick={onReturnToGameClick}>
                            <FormattedMessage id="leaderboardPage.backToGame.linkText" />
                        </LeaderboardLink>
                    ),
                }}
            />
        </StyledTypography>
    );
};

const getCardsNoGroup = (
    leaderboards: NoGroupsLeaderboards,
    url: string,
    intl: IntlShape,
): LeaderboardCardProps[] => {
    const cards: LeaderboardCardProps[] = [];
    const {
        groupModeProperties: {
            generalLeaderboardName,
            generalLeaderboardImageUrl,
        },
    } = leaderboards;

    const generalLeaderboardCard: LeaderboardCardProps = {
        name:
            generalLeaderboardName ||
            intl.formatMessage({
                id: 'group.generalLeaderboard',
            }),
        imageUrl: generalLeaderboardImageUrl,
        pathName: `${url}${routeUrls.generalLeaderboard}`,
        leaderboard: leaderboards.generalLeaderboard,
    };
    cards.push(generalLeaderboardCard);

    return cards;
};

const getCards = (
    leaderboards: Leaderboards,
    url: string,
    intl: IntlShape,
): LeaderboardCardProps[] => {
    switch (leaderboards.groupMode) {
        case 'TEAM,USER_GROUP': {
            return getCardsTeamUserGroup(leaderboards, url, intl);
        }
        case 'TEAM': {
            return getCardsTeam(leaderboards, url, intl);
        }
        case 'USER_GROUP': {
            return getCardsUserGroup(leaderboards, url, intl);
        }
        case 'VENUE': {
            return getCardsVenue(leaderboards, url, intl);
        }
        case null: {
            return getCardsNoGroup(leaderboards, url, intl);
        }
    }
};

const LeaderboardTabPanel = ({ leaderboards, url, events }: Props) => {
    const history = useHistory();
    const location = useLocation();
    const intl = useIntl();

    const eventSlugSearchParam = getEventSlugSearchParam(location.search);

    const isInMultiEventGame =
        !!eventSlugSearchParam && events.has(eventSlugSearchParam);

    const onReturnToGameClick = () => {
        history.push({
            pathname: routeUrls.play,
            search: location.search,
        });
    };

    const cards = getCards(leaderboards, url, intl);
    return (
        <>
            <Grid
                container
                spacing={4}
                justifyContent="flex-start"
                alignItems="stretch"
            >
                {cards.map((card) => (
                    <LeaderboardCard key={card.pathName} {...card} />
                ))}
            </Grid>
            {isInMultiEventGame && (
                <BackToGameText onReturnToGameClick={onReturnToGameClick} />
            )}
        </>
    );
};

export default LeaderboardTabPanel;

const LeaderboardLink = styled.a`
    cursor: pointer;
    text-decoration: underline;
`;
