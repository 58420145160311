import styled from '@emotion/styled';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';

import {
    TriviaInterstitialAd as TriviaInterstitialAdType,
    UIEventConfig,
} from 'src/util/TallyFirestore';
import useCountdown from 'src/hooks/useCountdown';
import { ButtonContainer, LoadingIndicatorContainer } from '../shared';
import SquareLoading from 'src/components/shared/animations/SquareLoading';
import AspectRatioIframeAd from 'src/components/shared/ads/AspectRatioIframeAd';
import theme from 'src/util/Theme';
import { Color } from 'src/styles/Constants';

type Props = {
    eventId: string;
    eventUI: UIEventConfig | null;
    ad: TriviaInterstitialAdType;
    buttonText: React.ReactNode;
    // if undefined, new question is loading
    onNextQuestion?: () => void;
};

const TriviaInterstitialAd = ({
    eventId,
    eventUI,
    ad,
    onNextQuestion,
    buttonText,
}: Props) => {
    const [countdownDate] = useState<Date>(() => {
        const now = new Date();
        now.setSeconds(now.getSeconds() + ad.duration);

        return now;
    });

    const [countdownSeconds, setCountdownSeconds] = useState<
        undefined | number
    >(undefined);

    useCountdown({ countdownDate, onCountdownUpdated: setCountdownSeconds });

    return (
        <Container>
            {ad.iframeHtml ? (
                <AspectRatioIframeAd
                    eventId={eventId}
                    iframeHtml={ad.iframeHtml}
                    headline={ad.headline}
                    disclaimer={ad.disclaimer}
                />
            ) : null}
            <ButtonContainer>
                <Button
                    style={{
                        position: 'relative',
                        color: eventUI?.textColor || Color.T31_BLACK,
                    }}
                    disabled={countdownSeconds !== 0 || !onNextQuestion}
                    onClick={onNextQuestion}
                >
                    {countdownSeconds !== 0 ? (
                        <FormattedMessage
                            id="triviaGame.ad.button.countdown"
                            values={{
                                countdownSeconds:
                                    countdownSeconds || ad.duration,
                            }}
                        />
                    ) : (
                        buttonText
                    )}
                </Button>
            </ButtonContainer>
            {!onNextQuestion && (
                <LoadingIndicatorContainer>
                    <SquareLoading color={theme.themeHighlightFallbackColor} />
                </LoadingIndicatorContainer>
            )}
        </Container>
    );
};

const Container = styled.div`
    margin-top: 25px;
`;

export default TriviaInterstitialAd;
