import en from 'src/locales/en';

export const LocaleId = [
    'EN', // English (the fallback for static messages)
    'DE', // German
    'PT', // Portuguese
    'ES', // Spanish, Castilian
    'IT', // Italian
    // addressing flavors that are inherently missing in ISO 639-1
    'EN-AU',
] as const;

export type LocaleId = (typeof LocaleId)[number];
export type SecondaryLocaleId = Exclude<LocaleId, 'EN'>;

export type TranslationMessageId = keyof typeof en.messages;

export type Locales = Record<TranslationMessageId, string>;

export type LanguageConfig = {
    fullLanguageName: string;
    // en determines what keys there should be in other languages
    messages: Locales;
};

export type SecondaryLanguageConfig = {
    fullLanguageName: string;
    // en determines what keys there should be in other languages
    messages: Partial<Locales>;
};

declare global {
    namespace FormatjsIntl {
        interface Message {
            ids: TranslationMessageId;
        }
    }
}
