import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import TouchButton from '../../controls/TouchButton';

import { Color } from 'src/styles/Constants';
import ReactSwitch from 'react-switch';
import { Link } from '@material-ui/core';
import { useUserStorageConsent } from 'src/hooks/useUserStorageConsent';
import { useIntl } from 'react-intl';
import { usePartner } from 'src/hooks/usePartner';
import { TranslatedConfigPartnerData } from 'src/util/ConfigTypes';
import { UserStorageConsent } from 'src/hooks/useUserStorageConsent/useUserStorageConsent';

const useStyles = makeStyles({
    drawerPaper: {
        fontFamily: `Helvetica, Arial, 'Hiragino Sans GB', STXihei,
        'Microsoft YaHei', 'WenQuanYi Micro Hei', Hind, 'MS Gothic',
        'Apple SD Gothic Neo', 'NanumBarunGothic', sans-serif`,
        fontSize: 16,
        backgroundColor: '#252e39',
        color: '#fff',
        padding: '1.5em',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    consentContent: {
        flex: 5,
    },
    dataPolicyLink: {
        color: '#92bffb',
    },
    switchesContainer: {
        display: 'flex',
        flex: 5,
        flexWrap: 'wrap',
        '&>*': {
            margin: '0.5em 1em 0.5em 0',
        },
        marginTop: '0.75em',
        marginBottom: '0.75em',
    },
    buttonsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        flex: 1,
    },
    labeledInputContainer: {
        display: 'flex',
        alignItems: 'center',
        '& *:first-child': {
            marginRight: '0.5em',
        },
    },
    button: {
        flex: '1 1 auto',
        margin: '0.25em 0.5em',
    },
});

type Props = {
    onClose: () => void;
};

const getInitialUserConsentProposal = (
    partner: TranslatedConfigPartnerData,
): UserStorageConsent => {
    const { initialUserConsent } = partner.properties;

    if (initialUserConsent) {
        return { analytics: initialUserConsent.analytics };
    }
    return { analytics: true };
};

const UserStorageConsentForm = ({ onClose }: Props) => {
    const intl = useIntl();
    const styles = useStyles();
    const partner = usePartner();

    const { consent, setConsent } = useUserStorageConsent();
    const [analyticsEnabled, setAnalyticsEnabled] = useState(
        consent
            ? consent.analytics
            : getInitialUserConsentProposal(partner).analytics,
    );

    const handleSaveClick = () => {
        setConsent({ analytics: analyticsEnabled });
        onClose();
    };

    return (
        <>
            <div className={styles.consentContent}>
                <div>
                    <span>
                        {intl.formatMessage({
                            id: 'storageConsentModal.description',
                        })}
                    </span>{' '}
                    <Link
                        href="https://s3-us-west-2.amazonaws.com/playtally.com/policies/privacy/index.html"
                        target="_blank"
                        className={styles.dataPolicyLink}
                    >
                        {intl.formatMessage({
                            id: 'storageConsentModal.storagePolicy.link.text',
                        })}
                    </Link>
                </div>
                <div className={styles.switchesContainer}>
                    <div className={styles.labeledInputContainer}>
                        <ReactSwitch
                            disabled={true}
                            onChange={() => {}}
                            checked={true}
                            checkedIcon={false}
                            offColor="#b1b1b1"
                            offHandleColor={Color.P1_WHITE}
                            onColor={Color.T25_BRIGHT_TEAL}
                            onHandleColor={Color.P1_WHITE}
                            uncheckedIcon={false}
                        />
                        <div>
                            {intl.formatMessage({
                                id: 'storageConsentModal.necessary.switch.label',
                            })}
                        </div>
                    </div>
                    <div className={styles.labeledInputContainer}>
                        <ReactSwitch
                            onChange={setAnalyticsEnabled}
                            checked={analyticsEnabled}
                            checkedIcon={false}
                            offColor="#b1b1b1"
                            offHandleColor={Color.P1_WHITE}
                            onColor={Color.T25_BRIGHT_TEAL}
                            onHandleColor={Color.P1_WHITE}
                            uncheckedIcon={false}
                        />
                        <div>
                            {intl.formatMessage({
                                id: 'storageConsentModal.analytics.switch.label',
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.buttonsContainer}>
                <TouchButton
                    className={styles.button}
                    onClick={handleSaveClick}
                    backgroundColor={Color.S15_DEEP_TEAL}
                    displayText={intl.formatMessage({
                        id: 'storageConsentModal.button.save',
                    })}
                    textColor={Color.T31_BLACK}
                    secondaryStyle={true}
                />
            </div>
        </>
    );
};
export default UserStorageConsentForm;
