import React, { useState, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from '@emotion/styled';
import CountdownTimer from 'src/components/shared/ui/CountdownTimer';
import css from '@emotion/css';
import ServerError from 'src/services/ServerError';

enum ErrorMessages {
    NOT_ALLOWED = 'NOT_ALLOWED',
    REQUEST_EXPIRED = 'NEXMO_REQUEST_EXPIRED',
}

interface Props {
    countdownSeconds?: number;
    onResend: () => void;
    currentError?: Error | ServerError;
}

export default function VerifyCodeCountdown(props: Props) {
    const intl = useIntl();
    const { countdownSeconds, currentError, onResend } = props;
    const [countdownExpired, setCountdownExpired] = useState(!countdownSeconds);
    const countdownDateRef = useRef<Date | null>(null);

    if (!countdownDateRef.current && !countdownExpired && countdownSeconds) {
        const countdownExpirationDate = new Date();
        countdownExpirationDate.setSeconds(
            countdownExpirationDate.getSeconds() + countdownSeconds,
        );
        countdownDateRef.current = countdownExpirationDate;
    }

    let showRetryButton = false;
    if (currentError && currentError.hasOwnProperty('code')) {
        const serverError = currentError as ServerError;
        if (serverError.code === ErrorMessages.REQUEST_EXPIRED) {
            showRetryButton = true;
            if (!countdownExpired) {
                setCountdownExpired(true);
            }
        }
    }

    const onCountdownExpired = () => {
        setCountdownExpired(true);
    };

    const onResendWrapper = () => {
        countdownDateRef.current = null;
        setCountdownExpired(false);
        onResend();
    };

    return (
        <>
            {!countdownExpired && countdownDateRef.current && (
                <UnderlineCountdown
                    startDate={countdownDateRef.current}
                    countdownPrefixText={intl.formatMessage({
                        id: 'verifyCodeCountdown.underlineCountdown',
                    })}
                    onCountdownExpired={onCountdownExpired}
                />
            )}
            {countdownExpired && !showRetryButton && (
                <UnderlineText>
                    <FormattedMessage id="verifyCodeCountdown.underlineText" />
                </UnderlineText>
            )}
            {showRetryButton && (
                <UnderlineButton onClick={onResendWrapper}>
                    <FormattedMessage id="verifyCodeCountdown.button.label" />
                </UnderlineButton>
            )}
        </>
    );
}

const UnderlineTextStyle = css`
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 18px;
    text-transform: upperCase;
    margin: 10px 0;
`;

const UnderlineText = styled.div`
    ${UnderlineTextStyle}
`;

const UnderlineCountdown = styled(CountdownTimer)`
    ${UnderlineTextStyle}
`;

const UnderlineButton = styled.div`
    ${UnderlineTextStyle}
    cursor: pointer;
    text-decoration: underline;
    padding: 10px;
`;
