import React, { createContext, useContext } from 'react';

import config from 'src/util/Config';
import { TranslatedConfigPartnerData } from 'src/util/ConfigTypes';
import { translatePartnerConfig } from 'src/util/i18n';
import { useLocale } from './useLocale';

export const PartnerContext = createContext<TranslatedConfigPartnerData>(
    translatePartnerConfig('EN')(config.partnerData),
);

type PartnerProviderProps = {
    children: React.ReactNode;
};

export const PartnerProvider = ({ children }: PartnerProviderProps) => {
    const { localeId } = useLocale();
    const translatedPartner = translatePartnerConfig(localeId)(
        config.partnerData,
    );

    return (
        <PartnerContext.Provider value={translatedPartner}>
            {children}
        </PartnerContext.Provider>
    );
};

export const PartnerConsumer = PartnerContext.Consumer;

export const usePartner = () => useContext(PartnerContext);
