import React from 'react';
import styled from '@emotion/styled';
import {
    FormattedMessage,
    injectIntl,
    WrappedComponentProps,
} from 'react-intl';
import { Color, StyleDefaults, Orientation } from 'src/styles/Constants';
import { TranslatedConfigPartnerData } from 'src/util/ConfigTypes';
import { EventModel, SponsorModel } from 'src/util/TallyFirestore';
import { TranslatedIteration } from 'src/util/i18n';
import Link from 'src/components/shared/ui/Link';
import PresentedBy from 'src/components/shared/ui/PresentedBy';
import AgreementTile from './tiles/AgreementTile';
import VenueTile from './tiles/VenueTile';
import TeamsTile from './tiles/TeamsTile';
import { Redirect, useLocation } from 'react-router-dom';
import routeUrls from 'src/routes';
import { openCustomerSupport } from 'src/util/GameShared';
import { useAnalytics } from 'src/contexts/AnalyticsContext';
import { EventName } from 'src/util/AnalyticsConstants';
import {
    onboardingTileTypes,
    // logOnboardingScreenView,
    OnboardingTileType,
} from './utils';
import PhoneAuthTiles from './PhoneAuthTiles';
import DisplayNameTile from './tiles/DisplayNameTile';
import useOnboardingTile from 'src/hooks/useOnboardingTile';
import LoadingTile from './tiles/LoadingTIle';
import { EmailTile, ReOptInTile } from './tiles/MarketingTiles';
import PhoneTile from './tiles/PhoneTile';
import { GameBG } from 'src/Iteration/SingleEvent/components';
import { getEventUITheme } from 'src/Iteration/SingleEvent/utils';
import { BackgroundPanel } from 'src/components/shared/ui/BackgroundPanel';
import { has } from 'lodash';

type Props = {
    partner: TranslatedConfigPartnerData;
    iteration: TranslatedIteration;
    event?: EventModel;
    gameWideSponsor?: SponsorModel;
} & WrappedComponentProps;

const Onboarding = (props: Props): any => {
    const analytics = useAnalytics();
    const { partner, iteration, event } = props;

    const {
        properties: {
            rulesUrl,
            privacyUrl,
            privacyLabel,
            rulesLabel,
            termsUrl,
            termsLabel,
            onboardingDisclaimer,
            onboardingDisclaimerHideFooterLinks,
            addDisclaimer,
        },
    } = partner;

    const onContactSupportClick = () => {
        openCustomerSupport(event ? { event } : { iteration });
        analytics.logEvent({ eventName: EventName.contactSupport });
    };

    const CAPTCHA_BUTTON_ID = 'CAPTCHA_BUTTON_ID';
    const location = useLocation<undefined | { from?: string }>();

    const currentCollectionTile = useOnboardingTile();

    document.body.style.backgroundColor = Color.P1_WHITE;

    // useEffect(() => {
    //     logOnboardingScreenView(currentCollectionTile, iteration, event);
    // }, [currentCollectionTile]);

    // const onSubmitEmail = async (data: {
    //     email?: string;
    //     emailCheckboxChecked?: boolean;
    //     smsCheckboxChecked?: boolean;
    //     optionalOptIn?: boolean;
    //     tallyOptIn?: boolean;
    // }) => {
    //     const {
    //         email,
    //         emailCheckboxChecked,
    //         smsCheckboxChecked,
    //         optionalOptIn,
    //         tallyOptIn,
    //     } = data;

    //     const iterationId = iteration.id;
    //     try {
    //         await ServerApi.updateUser({
    //             email,
    //             lastIterationOptIn: iterationId,
    //             marketingOptIn:
    //                 emailCheckboxChecked === undefined
    //                     ? undefined
    //                     : Boolean(emailCheckboxChecked),
    //             smsMarketingOptIn:
    //                 smsCheckboxChecked === undefined
    //                     ? undefined
    //                     : Boolean(smsCheckboxChecked),
    //             optionalOptIn:
    //                 optionalOptIn === undefined
    //                     ? undefined
    //                     : Boolean(optionalOptIn),
    //             tallyOptIn:
    //                 tallyOptIn === undefined ? undefined : Boolean(tallyOptIn),
    //         });

    //         Analytics.logEvent(
    //             EventName.signUpEmailComplete,
    //             {
    //                 marketing_opt_in: emailCheckboxChecked,
    //                 sms_marketing_opt_in: smsCheckboxChecked,
    //             },
    //             AppArea.signup
    //         );

    //         if (emailCheckboxChecked || smsCheckboxChecked) {
    //             Analytics.logEvent(EventName.marketingOptIn, {
    //                 emailOptedIn: emailCheckboxChecked,
    //                 screen: Screen.signupEmail,
    //                 smsOptedIn: smsCheckboxChecked,
    //             });
    //         }
    //         const userData = await updateContactInfo(FirebaseAuth.getUser()!);
    //     } catch (error) {
    //         if (!(error instanceof Error)) {
    //             Logger.error('Error submitting email.', error);
    //             setTileError(
    //                 new Error(intl.formatMessage({ id: 'error.generic' }))
    //             );
    //             return;
    //         }

    //         Analytics.logError(
    //             EventName.signUpError,
    //             ErrorCategory.signIn,
    //             ErrorSeverity.warn,
    //             error.message
    //         );

    //         if (error instanceof NetworkError) {
    //             setTileError(
    //                 new Error(intl.formatMessage({ id: 'error.failedToFetch' }))
    //             );
    //             return;
    //         }
    //         if (error instanceof ServerError && error.field) {
    //             setFieldErrors({ ...fieldErrors, [error.field]: error });
    //             setTileError(
    //                 new Error(intl.formatMessage({ id: 'error.generic' }))
    //             );
    //             return;
    //         }
    //         setTileError(error);
    //     }
    // };

    const hasBg =
        event?.ui?.textBlockBackgroundOpacity !== undefined &&
        event?.ui?.textBlockBackgroundOpacity > 0;

    const getFullDisclaimer = (): JSX.Element => {
        return (
            <>
                <DisclaimerText>
                    {onboardingDisclaimer || (
                        <FormattedMessage id="onboarding.disclaimer" />
                    )}
                    {!onboardingDisclaimerHideFooterLinks && (
                        <>
                            {rulesUrl && (
                                <DisclaimerLink
                                    displayText={
                                        rulesLabel || (
                                            <FormattedMessage id="onboarding.officialRules" />
                                        )
                                    }
                                    url={rulesUrl}
                                />
                            )}
                            {termsUrl && ', '}
                            {termsUrl && (
                                <DisclaimerLink
                                    displayText={
                                        termsLabel || (
                                            <FormattedMessage id="onboarding.termsAndConditions" />
                                        )
                                    }
                                    url={termsUrl}
                                />
                            )}
                            {privacyUrl &&
                                (addDisclaimer ? (
                                    ','
                                ) : (
                                    <FormattedMessage id="onboarding.separator" />
                                ))}
                            {privacyUrl && (
                                <DisclaimerLink
                                    displayText={
                                        privacyLabel || (
                                            <FormattedMessage id="onboarding.privacyPolicy" />
                                        )
                                    }
                                    url={privacyUrl}
                                />
                            )}{' '}
                        </>
                    )}
                    {addDisclaimer &&
                        addDisclaimer.map(({ displayText, linkUrl }, index) => {
                            return (
                                <DisclaimerLink
                                    key={index}
                                    displayText={displayText}
                                    url={linkUrl}
                                />
                            );
                        })}
                </DisclaimerText>
            </>
        );
    };

    const getTileByType = (tileType: OnboardingTileType): JSX.Element => {
        switch (tileType) {
            case onboardingTileTypes.loading:
                return <LoadingTile />;
            case onboardingTileTypes.agreementRequired:
                return <AgreementTile />;
            case onboardingTileTypes.initialize:
                return (
                    <DisplayNameTile
                        iteration={iteration}
                        onContactSupportClick={onContactSupportClick}
                        screenHasBg={hasBg}
                        disclaimer={getFullDisclaimer()}
                    />
                );
            case onboardingTileTypes.authPhone:
                return (
                    <PhoneAuthTiles
                        event={event}
                        onContactSupportClick={onContactSupportClick}
                        screenHasBg={hasBg}
                        disclaimer={getFullDisclaimer()}
                    />
                );
            case onboardingTileTypes.collectEmail:
                return (
                    <EmailTile
                        partner={partner}
                        iterationId={iteration.id}
                        onContactSupportClick={onContactSupportClick}
                        // TODO: rethink prizes messages as Onboarding can happen within iteration.
                        // Should we use iteration prizes?
                        prizes={event ? event.prizes : undefined}
                        screenHasBg={hasBg}
                    />
                );
            case onboardingTileTypes.reOptIn:
                return (
                    <ReOptInTile
                        partner={partner}
                        iterationId={iteration.id}
                        onContactSupportClick={onContactSupportClick}
                        // TODO: rethink prizes messages as Onboarding can happen within iteration.
                        // Should we use iteration prizes?
                        prizes={event ? event.prizes : undefined}
                    />
                );
            case onboardingTileTypes.collectPhone:
                return (
                    <PhoneTile
                        iterationId={iteration.id}
                        onContactSupportClick={onContactSupportClick}
                    />
                );
            case onboardingTileTypes.joinVenue:
                return (
                    <VenueTile onContactSupportClick={onContactSupportClick} />
                );
            case onboardingTileTypes.joinTeam:
                return (
                    <TeamsTile
                        iterationId={iteration.id}
                        onContactSupportClick={onContactSupportClick}
                    />
                );
        }
    };

    if (currentCollectionTile === null) {
        return (
            <Redirect
                to={
                    location.state?.from &&
                    location.state.from !== routeUrls.onboarding
                        ? location.state.from
                        : routeUrls.play
                }
            />
        );
    }

    const infoTile = getTileByType(currentCollectionTile);

    const presentedByLogo = event?.sponsorship
        ? event.sponsorship.properties.gameWideLogoUrl ||
          event.sponsorship.properties.gameLogoUrl
        : undefined;

    return (
        <BodyContainer>
            {hasBg && (
                <GameBG {...getEventUITheme(event, partner)}>&nbsp;</GameBG>
            )}

            <WidthRestrictor>
                {presentedByLogo && (
                    <BackgroundPanel screenHasBg={hasBg} btmConnect={true}>
                        <BannerContainer>
                            <PresentedBy
                                sponsorshipUnit={event?.sponsorship}
                                asset={presentedByLogo}
                                assetMaxHeight={70}
                                orientation={Orientation.VERTICAL}
                            />
                        </BannerContainer>
                    </BackgroundPanel>
                )}
                <InputCollectionContainer>{infoTile}</InputCollectionContainer>
                <CaptchaContainer id={CAPTCHA_BUTTON_ID} />
            </WidthRestrictor>
        </BodyContainer>
    );
};

export default injectIntl(Onboarding);

const BannerContainer = styled.div`
    width: 100%;
    padding: 30px 0 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${Color.P1_WHITE};
`;

const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const CaptchaContainer = styled.div`
    display: flex;
    flex: 1;
`;

const DisclaimerLink = styled(Link)`
    margin: 0 3px;
    text-decoration: underline;
`;

const DisclaimerText = styled.div`
    margin: 0;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 11px;
    line-height: 16px;
    color: ${Color.T30_CHARCOAL};
`;

const InputCollectionContainer = styled.div<{ animation?: string }>`
    animation: ${(props) => props.animation} 0.5s ease;
    font-family: Graphik Web;
    text-align: center;
    display: flex;
    width: 100%;
    flex-direction: column;
`;

const WidthRestrictor = styled.div`
    max-width: ${StyleDefaults.MAX_ELEMENT_WIDTH};
    font-family: Graphik Web;
    padding: 20px 30px ${StyleDefaults.PAGE_PADDING_BOTTOM} 30px;
    text-align: center;
`;
