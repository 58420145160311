import React, { ReactNode, createRef, Component } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import { debounce } from 'lodash';
import { RouteChildrenProps } from 'react-router-dom';
import {
    PredictionModel,
    UserAnswerSet,
    LeaderboardEntry,
    SummarizedLeaderboard,
    EventState,
    SponsorModel,
    OddClaim,
    PredictionEventModel,
} from 'src/util/TallyFirestore';
import ModalHoster, {
    ModalType,
} from 'src/components/shared/modals/ModalHoster';
import {
    Color,
    StyleDefaults,
    PistachioZIndex,
    TRANSITION_ANIMATION_TIME,
    TextStyles,
    TextStyleTypes,
    Height,
} from 'src/styles/Constants';
import { EventName, Screen, AppArea } from 'src/util/AnalyticsConstants';
import UserStats from 'src/components/shared/ui/UserStats';
import lottie, { AnimationItem } from 'lottie-web';
import lightningBolt from 'src/images/animations/LightningBolt-White.json';
// import noBrandLightningBolt from 'src/images/animations/splash-white.json';
import noBrandLightningBolt from 'src/images/animations/rams-head-oj.json';

import { keyframes } from 'emotion';
import Theme from 'src/util/Theme';
import ServerError from 'src/services/ServerError';
import ServerApi from 'src/services/ServerApi';
import InformationModule from './InformationModule';
import { OnAnswerCallback } from 'src/components/shared/predictions/Prediction';
import { OnAnswerCallback as OnPollAnswerCallback } from 'src/components/shared/progressivePoll/ProgressivePoll';
import { Analytics } from 'src/contexts/AnalyticsContext';
import Cookies from 'universal-cookie';
import PresentedBy from 'src/components/shared/ui/PresentedBy';
import BonusSponsors from 'src/components/pistachioGame/BonusSponsors';

import LivePredictionList from './LivePredictionList';
import PastPredictionList from './PastPredictionList';
import {
    GroupedPredictions,
    hasActivePredictions,
} from 'src/util/PredictionHelpers';
import {
    getPredicitonPageScrollTarget,
    PredictionPageScrollTarget,
    ProjectedAnswers,
} from './utils';
import { TranslatedIteration } from 'src/util/i18n';
import { TranslatedConfigPartnerData } from 'src/util/ConfigTypes';
import {
    ProgressivePollModel,
    ProgressivePollAnswer,
} from 'src/util/TallyFirestore';
import { getDetails, UserAuthData } from 'src/contexts/UserStateContext';
import routeUrls, { goToGeneralEventLeaderboard } from 'src/routes';
import Link from 'src/components/shared/ui/Link';
import { openCustomerSupport } from 'src/util/GameShared';

type Props = {
    analytics: Analytics;
    showPrizeDetails?: () => void;
    userData: UserAuthData;
    partner: TranslatedConfigPartnerData;
    answers: UserAnswerSet;
    answersUpdatedTimestamp: Date;
    bonusSponsors: SponsorModel[];
    event: PredictionEventModel;
    gameWideSponsor?: SponsorModel;
    hasUnansweredActivePrediction: boolean;
    groupedPredictions: GroupedPredictions;
    groupedPredictionsUpdatedTimestamp: Date;
    eventLeaderboardEntry?: LeaderboardEntry;
    iteration: TranslatedIteration;
    leaderboard?: SummarizedLeaderboard;
    prizes?: string[];
    modalHoster?: ModalHoster;
    lastBatchReleased: boolean;
    sponsors: SponsorModel[];
    oddClaims?: OddClaim;
    isAdSticky: boolean;
    progressivePolls: ProgressivePollModel[];
    progressivePollsAnswers: ProgressivePollAnswer[];
} & RouteChildrenProps;

interface State {
    // Stores answers that were successfully submitted but
    // not yet reflected in the answer counts in the prediction model counts.
    projectedAnswers: ProjectedAnswers;
    activePredictionAnimation: boolean;
}

class PredictPage extends Component<Props, State> {
    private fullScreenAnimationContainer = createRef<HTMLDivElement>();
    private animation?: AnimationItem;
    private animationContainer = createRef<HTMLDivElement>();

    private livePredictionListRef = createRef<HTMLDivElement>();
    private pastPredictionListRef = createRef<HTMLDivElement>();
    private scrollTarget: PredictionPageScrollTarget = 'livePredictionList';

    constructor(props: Props) {
        super(props);

        this.state = {
            activePredictionAnimation: false,
            projectedAnswers: {},
        };
    }

    public componentDidMount() {
        const { event, groupedPredictions, analytics } = this.props;

        if (this.fullScreenAnimationContainer.current) {
            this.fullScreenAnimationContainer.current.addEventListener(
                'animationend',
                this.onHeroicAnimationEnd,
            );
        }
        if (this.shouldShowPrizeModal()) {
            this.showPrizesModal();
        }

        analytics.logScreenView({
            screenName: `${event.shortName} - ${Screen.eventPredictions}`,
            screenType: Screen.eventPredictions,
            appArea: AppArea.game,
            params: {
                prediction_state: hasActivePredictions(groupedPredictions)
                    ? 'active'
                    : 'inactive',
            },
        });
    }

    private shouldShowPrizeModal = () => {
        const { event, showPrizeDetails } = this.props;
        const reactCookie = new Cookies();
        const cookies = reactCookie.getAll();

        // if the event isn't final and this is first time visiting this page
        // fire prize modal
        return (
            event.state !== EventState.final &&
            !cookies[event.id] &&
            event.prizes &&
            showPrizeDetails
        );
    };

    private showPrizesModal = () => {
        const { event, showPrizeDetails } = this.props;
        const reactCookie = new Cookies();

        if (showPrizeDetails) {
            reactCookie.set(event.id, true);
            setTimeout(showPrizeDetails, 500);
        }
    };

    private handleScroll = () => {
        if (this.props.location.pathname === routeUrls.play) {
            const { pastPredictionListRef } = this;

            switch (this.scrollTarget) {
                case 'livePredictionList': {
                    window.scroll({
                        top: 0,
                        behavior: 'smooth',
                    });
                    break;
                }
                case 'pastPredictionList':
                    {
                        const rect =
                            pastPredictionListRef.current!.getBoundingClientRect();
                        window.scroll({
                            top:
                                rect.top +
                                window.scrollY -
                                Height.TITLEBAR -
                                Height.COUNTDOWN_HEADER,
                            behavior: 'smooth',
                        });
                    }
                    break;
                default:
                    break;
            }
        }
    };

    private static MAX_ANIMATION_END_OFFSET = TRANSITION_ANIMATION_TIME / 3;

    private debouncedHandleScroll = debounce(
        this.handleScroll,
        PredictPage.MAX_ANIMATION_END_OFFSET,
        {
            leading: false,
            trailing: true,
        },
    );

    public componentWillUnmount() {
        if (this.fullScreenAnimationContainer.current) {
            this.fullScreenAnimationContainer.current.removeEventListener(
                'animationend',
                this.onHeroicAnimationEnd,
            );
        }
    }

    public componentDidUpdate(prevProps: Props) {
        if (this.shouldShowPrizeModal()) {
            this.showPrizesModal();
        }

        const {
            answersUpdatedTimestamp: newTimestamp,
            groupedPredictions: newGroupedPredictions,
            answers,
        } = this.props;

        const {
            answersUpdatedTimestamp: oldTimestamp,
            groupedPredictions: oldGroupedPredictions,
        } = prevProps;

        const oldActivePredictions = [
            ...oldGroupedPredictions.live.automatic.flatMap(
                (batch) => batch.entities,
            ),
            ...oldGroupedPredictions.live.manual.flatMap(
                (batch) => batch.entities,
            ),
        ];
        const newActivePredictions = [
            ...newGroupedPredictions.live.automatic.flatMap(
                (batch) => batch.entities,
            ),
            ...newGroupedPredictions.live.manual.flatMap(
                (batch) => batch.entities,
            ),
        ];
        const { activePredictionAnimation } = this.state;

        if (oldTimestamp.getTime() !== newTimestamp.getTime()) {
            // answers have been updated. wipe out the projected answers.
            this.setState({ projectedAnswers: {} });
        }

        // if there are new predictions, we should just scroll top
        if (newActivePredictions.length > oldActivePredictions.length) {
            this.scrollTarget = 'livePredictionList';
        } else if (newActivePredictions.length < oldActivePredictions.length) {
            const { livePredictionListRef, pastPredictionListRef } = this;
            this.scrollTarget = getPredicitonPageScrollTarget(
                {
                    livePredictionListRef,
                    pastPredictionListRef,
                },
                -Height.COUNTDOWN_HEADER - Height.TITLEBAR,
            );
        } else if (
            newGroupedPredictions.live.automatic.length !==
            oldGroupedPredictions.live.automatic.length
        ) {
            // if regrouping happened just within live predictions
            this.scrollTarget = 'livePredictionList';
        }

        // Play the animation whenever there is an increase in active predicitons.
        if (
            oldActivePredictions.length < newActivePredictions.length &&
            !activePredictionAnimation
        ) {
            const currentTime = new Date();
            const unAnsweredPredictions = newActivePredictions.filter(
                (p) =>
                    !answers[p.id] &&
                    p.lockDate.toDate().getTime() > currentTime.getTime(),
            );

            if (unAnsweredPredictions && unAnsweredPredictions.length > 0) {
                // Show animation;
                this.setState({
                    activePredictionAnimation: true,
                });
                if (!Theme.predictionAnimGifUrl) {
                    setTimeout(() => {
                        this.animation = lottie.loadAnimation({
                            animationData: Theme.unbrandNewPredictions
                                ? noBrandLightningBolt
                                : lightningBolt,
                            autoplay: true,
                            container: this.animationContainer.current,
                            loop: Theme.unbrandNewPredictions ? true : false,
                            renderer: 'svg',
                        });
                    }, 500);
                } else {
                    // console.log('unbranded');
                }
            }
        }
    }

    private onAnswer: OnAnswerCallback = async (params: {
        optionId: string;
        prediction: PredictionModel;
        value: boolean | string;
    }): Promise<boolean> => {
        const { optionId, prediction, value } = params;
        const { answers, analytics } = this.props;
        const { id: predictionId, type: predictionType } = prediction;
        try {
            const numAnswered = answers ? Object.keys(answers).length : 0;

            // Do some validation on the value.
            let cleanedValue = value;
            if (typeof value === 'string') {
                cleanedValue = value.trim();
                if (cleanedValue.length === 0) {
                    // Only whitespace. Don't bother submitting.
                    return false;
                }

                if (!/^\d+$/.test(cleanedValue)) {
                    // Not an integer. Don't bother submitting.
                    return false;
                }
            }
            // optimistic update
            this.setState({
                projectedAnswers: {
                    ...this.state.projectedAnswers,
                    [predictionId]: optionId,
                },
            });
            await ServerApi.submitPrediction({
                optionId,
                predictionId,
                value: cleanedValue,
            });

            // Log predictions_started. Note that there is a race-condition here where
            // this won't be 100% accurate. We depend on our local "answers" to determine if
            // we've made any predictions before. If the user makes more than 1 prediction in a row,
            // we may log multiple predictions_started, because we haven't gotten an update for
            // answers yet.
            if (numAnswered === 0) {
                analytics.logEvent({
                    eventName: EventName.predictionsStarted,
                    params: {
                        prediction_type: predictionType,
                    },
                });
            }

            this.setState({
                projectedAnswers: {
                    ...this.state.projectedAnswers,
                    [predictionId]: optionId,
                },
            });
            return true;
        } catch (error) {
            const revertedProjectedAnswers = {
                ...this.state.projectedAnswers,
            };
            delete revertedProjectedAnswers[predictionId];

            this.setState({
                projectedAnswers: revertedProjectedAnswers,
            });

            const message = ServerError.getMessage(
                error,
                'Something went wrong submitting your choice.',
            );
            alert(message);
            return false;
        }
    };
    private onProgressivePollAnswer: OnPollAnswerCallback = async (params: {
        optionId: string;
        poll: ProgressivePollModel;
    }): Promise<boolean> => {
        const { optionId, poll } = params;
        const { id: pollId } = poll;
        try {
            // optimistic update
            this.setState({
                projectedAnswers: {
                    ...this.state.projectedAnswers,
                    [pollId]: optionId,
                },
            });
            await ServerApi.submitProgressivePoll({
                optionId,
                pollId,
                eventId: this.props.event.id,
            });

            this.setState({
                projectedAnswers: {
                    ...this.state.projectedAnswers,
                    [pollId]: optionId,
                },
            });
            return true;
        } catch (error) {
            const revertedProjectedAnswers = {
                ...this.state.projectedAnswers,
            };
            delete revertedProjectedAnswers[pollId];

            this.setState({
                projectedAnswers: revertedProjectedAnswers,
            });

            const message = ServerError.getMessage(
                error,
                'Something went wrong submitting your choice.',
            );
            alert(message);
            return false;
        }
    };

    private onHeroicAnimationEnd = () => {
        this.setState({
            activePredictionAnimation: false,
        });

        if (this.animation) {
            this.animation.destroy();
        }
    };

    private goToLeaderboard = () => {
        const { history, event } = this.props;
        goToGeneralEventLeaderboard(history, location, event.slug);
    };

    private onHowToPlayClick = () => {
        const { iteration, event, modalHoster } = this.props;
        if (modalHoster) {
            modalHoster.showModal({
                iteration,
                event,
                modalType: ModalType.HOW_TO_PLAY,
            });
        }
    };

    // This need to be resilient to having no user since an
    // uninitialized user can be here if the game is final
    public render(): ReactNode {
        const {
            userData,
            partner,
            answers,
            bonusSponsors,
            event,
            eventLeaderboardEntry,
            progressivePolls,
            progressivePollsAnswers,
            sponsors,
            groupedPredictions,
            hasUnansweredActivePrediction,
            lastBatchReleased,
            oddClaims,
            isAdSticky,
        } = this.props;
        const { activePredictionAnimation, projectedAnswers } = this.state;

        const activePredictionBatches = [
            ...groupedPredictions.live.automatic,
            ...groupedPredictions.live.manual,
        ];

        const userDetails = getDetails(userData);
        const userName = userDetails ? userDetails.displayName : '';

        const sponsoredPresentedByLogo = event.sponsorship
            ? event.sponsorship.properties.gameWideLogoUrl ||
              event.sponsorship.properties.gameLogoUrl
            : undefined;

        return (
            <>
                <LivePredictionList
                    ref={this.livePredictionListRef}
                    answers={answers}
                    projectedAnswers={projectedAnswers}
                    predictionBatches={activePredictionBatches}
                    sponsors={sponsors}
                    onAnswer={this.onAnswer}
                    onEntered={this.debouncedHandleScroll}
                    onExited={this.debouncedHandleScroll}
                    oddClaims={oddClaims}
                    isAdSticky={isAdSticky}
                    onProgressivePollAnswer={this.onProgressivePollAnswer}
                    progressivePolls={progressivePolls}
                    progressivePollsAnswers={progressivePollsAnswers}
                    progressivePollConfig={event.progressivePolls}
                />
                {partner.properties.blueBoxPromoImageUrl && (
                    <AdLink
                        href={partner.properties.blueBoxPromoLink}
                        target="blank"
                    >
                        <AdContent
                            src={partner.properties.blueBoxPromoImageUrl}
                        />
                    </AdLink>
                )}
                <InformationModule
                    event={event}
                    hasUnansweredActivePrediction={
                        hasUnansweredActivePrediction
                    }
                    hasActivePredictions={hasActivePredictions(
                        groupedPredictions,
                    )}
                    lastBatchReleased={lastBatchReleased}
                />
                {event.state === EventState.final && (
                    <BonusSponsors bonusSponsors={bonusSponsors} />
                )}
                <WidthRestrictor marginBottom="22px" marginTop="22px">
                    <UserStats
                        leaderboardEntry={eventLeaderboardEntry}
                        name={userName}
                        showShadow={true}
                        titleOverride={
                            <FormattedMessage id="predictPage.userStats.titleOverride" />
                        }
                        callToActionButton={
                            partner.properties.hideLeaderboards
                                ? undefined
                                : {
                                      label: (
                                          <FormattedMessage id="predictPage.userStats.callToActionButton.label" />
                                      ),
                                      onClick: this.goToLeaderboard,
                                  }
                        }
                    />
                    <HowToPlayButton
                        onClick={this.onHowToPlayClick}
                        color={event.ui?.textColor}
                    >
                        <FormattedMessage id="predictPage.userStats.howToPlayButton.label" />
                    </HowToPlayButton>
                </WidthRestrictor>
                {event.sponsorship && event.sponsorship.properties.tileAd && (
                    <WidthRestrictor>
                        <TileAd src={event.sponsorship.properties.tileAd} />
                    </WidthRestrictor>
                )}
                <PastPredictionList
                    ref={this.pastPredictionListRef}
                    answers={answers}
                    finalPredictionBatches={groupedPredictions.final}
                    pendingPredictionsBatch={groupedPredictions.pending}
                    projectedAnswers={projectedAnswers}
                    sponsors={sponsors}
                    oddClaims={oddClaims}
                    progressivePolls={progressivePolls}
                    progressivePollsAnswers={progressivePollsAnswers}
                    progressivePollConfig={event.progressivePolls}
                />
                {event.sponsorship && event.sponsorship.properties.footerAd && (
                    <FooterAdContainer>
                        <FooterAd src={event.sponsorship.properties.footerAd} />
                    </FooterAdContainer>
                )}
                <FullScreenContainer
                    showAnimation={
                        Theme.predictionAnimGifUrl
                            ? false
                            : activePredictionAnimation
                    }
                    ref={
                        Theme.predictionAnimGifUrl
                            ? undefined
                            : this.fullScreenAnimationContainer
                    }
                >
                    {Theme.predictionAnimGifUrl ? (
                        <img src={Theme.predictionAnimGifUrl} />
                    ) : (
                        <AnimationContainer ref={this.animationContainer} />
                    )}

                    <HeroicPredictionNotice>
                        <FormattedMessage id="predictPage.heroicPredictionNotice" />
                    </HeroicPredictionNotice>
                    {sponsoredPresentedByLogo && (
                        <PresentedByBanner>
                            <PresentedBy
                                sponsorshipUnit={event.sponsorship}
                                asset={sponsoredPresentedByLogo}
                            />
                        </PresentedByBanner>
                    )}
                </FullScreenContainer>
            </>
        );
    }
}

export default PredictPage;

const VerticalSpace = styled.div<{ height: number }>`
    height: ${(props) => props.height}px;
`;

const FooterAd = styled.img`
    background-color: ${Color.P6_ELECTRIC_BLUE};
    width: 500px;
    max-width: 100%;
`;

const FooterAdContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    max-height: 312px;
`;

const TileAd = styled.img`
    width: 100%;
`;

const FADE_IN_AND_OUT_ANIMATION = keyframes`
    0%, 100% {
        opacity: 0;
    }

    5%, 95% {
        opacity: 1;
    }
`;

const PresentedByBanner = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    aligh-items: center;
    background-color: ${Color.G11_WHITE};
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.08);
    position: absolute;
    bottom: 0;
`;

const FullScreenContainer = styled.div<{ showAnimation: boolean }>`
    animation: ${(props) =>
            props.showAnimation ? FADE_IN_AND_OUT_ANIMATION : undefined}
        3s ease;
    pointer-events: none;
    background-color: ${Theme.newPredictionBackgroundColor};
    z-index: ${PistachioZIndex.NEW_PREDICTION_NOTICE};
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
`;

const AnimationContainer = styled.div`
    height: 150px;
    width: 150px;
    margin: 0 0 20px 0;
`;

const HeroicPredictionNotice = styled.div`
    color: ${Color.P1_WHITE};
    display: flex;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1.4px;
    line-height: 18px;
    text-transform: upperCase;
`;

const WidthRestrictor = styled.div<{
    marginBottom?: string;
    marginTop?: string;
}>`
    margin-bottom: ${(props) => props.marginBottom || '0'};
    margin-top: ${(props) => props.marginTop || '0'};
    max-width: ${StyleDefaults.MAX_ELEMENT_WIDTH};
    width: ${StyleDefaults.ELEMENT_WIDTH};
    font-family: Graphik Web;
`;

const HowToPlayButton = styled.button<{
    color?: string;
}>`
    &:active {
        opacity: 0.6;
    }
    background-color: transparent;
    border: none;
    color: ${(props) => props.color || Color.G1_BLACK};
    cursor: pointer;
    font-weight: 500;
    font-family: ${StyleDefaults.FONT_FAMILY};
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 18px;
    outline: none;
    padding: 30px 0 15px 0;
    text-align: center;
    text-transform: uppercase;
    text-decoration: underline;
    transition: all 0.3s;
    width: 100%;
`;

const PolicyLinksContainer = styled.div`
    border-top: 1px solid #252525;
    display: flex;
    flex-direction: row;
    margin-top: 26px;
    width: 100%;
`;
const PolicyLink = styled(Link)`
    ${TextStyles[TextStyleTypes.C3]}
    color: #9a9a9a;
    margin: 5px 0;
    underline: false;
    padding-right: 17px;
    padding-top: 4px;
    text-decoration: none;
`;

const ButtonLink = styled.a`
    ${TextStyles[TextStyleTypes.C3]}
    color: #9a9a9a;
    margin: 5px 0;
    underline: false;
    padding-right: 17px;
    padding-top: 4px;
    cursor: pointer;
`;

const AdLink = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 15px;
    height: auto;
    width: auto;
    color: ${Color.P1_WHITE};
`;

const AdContent = styled.img`
    width: 300px;
    height: auto;
`;
