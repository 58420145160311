import React from 'react';
import styled from '@emotion/styled';

import CheckMarkIcon from 'src/images/icons/Picks-Correct-S@3x.svg';
import { Color } from 'src/styles/Constants';
import { useLocale } from '../../../hooks/useLocale';

const PICKS_CORRECT_TEXT_COLOR = Color.T32_VIKING_ACCESSIBLE;

type AnswersProps = {
    correctAnswers: number;
    total?: number;
};

type TittleProps = {
    text: string;
};

type Props = AnswersProps | TittleProps;

const LeaderboardCheckMark = (props: Props) => {
    const { localeId } = useLocale();
    return (
        <CheckMark>
            <img src={CheckMarkIcon} alt="check mark icon" />
            {'text' in props ? (
                <RankStats color={PICKS_CORRECT_TEXT_COLOR}>
                    {props.text}
                </RankStats>
            ) : (
                <>
                    <RankStats color={PICKS_CORRECT_TEXT_COLOR}>
                        {props.correctAnswers.toLocaleString(localeId)}
                    </RankStats>
                    {props.total && (
                        <TotalStat>
                            /{props.total.toLocaleString(localeId)}
                        </TotalStat>
                    )}
                </>
            )}
        </CheckMark>
    );
};

export default LeaderboardCheckMark;

const CheckMark = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`;

export const RankStats = styled.div<{ color?: string }>`
    color: ${(props) => (props.color ? props.color : Color.T31_BLACK)};
    font-size: 12px;
    letter-spacing: 0.66px;
    line-height: 18px;
`;

const TotalStat = styled.div`
    color: ${Color.T31_BLACK};
    font-size: 12px;
    letter-spacing: 0.66px;
    line-height: 18px;
`;
