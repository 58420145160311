import styled from '@emotion/styled';

export const LoadingContainer = styled.div`
    height: 100px;
    padding-top: 25px;
`;

export const ButtonContainer = styled.div`
    margin: 10px 0;
    display: flex;
    justify-content: center;
`;

export const LoadingIndicatorContainer = styled.div`
    margin: 10px 0;
    display: flex;
    justify-content: center;
`;
