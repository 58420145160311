import styled from '@emotion/styled';
import { StyleDefaults } from 'src/styles/Constants';

export const LoadingContainer = styled.div`
    height: 100px;
    padding-top: 25px;
`;

export const ButtonContainer = styled.div`
    margin: 10px 0;
    display: flex;
    justify-content: center;
`;

export const LoadingIndicatorContainer = styled.div`
    margin: 10px 0;
    display: flex;
    justify-content: center;
`;

export const HeaderText = styled.div`
    text-align: center;
    font-size: 35px;
    letter-spacing: -0.6px;
    line-height: 37px;
    font-weight: 900;
    margin: 0 0 20px 0;
`;

export const WidthRestrictor = styled.div<{
    marginBottom?: string;
    marginTop?: string;
}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    max-width: ${StyleDefaults.MAX_ELEMENT_WIDTH};
    width: ${StyleDefaults.ELEMENT_WIDTH};
`;
