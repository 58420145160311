import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ServerApi from 'src/services/ServerApi';
import localeIdToMessages from 'src/locales';
import { getLanguageFromURL, getPartnerDefaultLanguage } from 'src/util/i18n';
import {
    initialPreferredLanguage,
    storePreferredLanguageToLocalStorage,
} from 'src/util/locale';
import { LocaleId } from 'src/util/LocaleHelpers';
import log from 'src/util/Logger';
import { IntlProvider } from 'react-intl';
import { getDetails, useUserState } from 'src/contexts/UserStateContext';
import { addLanguageSearchParam, getLanguageSearchParam } from 'src/routes';

type LocaleProviderProps = {
    supportedLanguages?: Array<LocaleId>;
    children: React.ReactNode;
};

function dummy() {
    throw new Error(
        'Should never be called. Is LocaleContext.Provider in the component tree?',
    );
}

export type LocaleContextType = {
    localeId: LocaleId;
    setLocaleId: (localeId: LocaleId) => void;
};

export const LocaleContext = createContext<LocaleContextType>({
    localeId: 'EN',
    setLocaleId: dummy,
});

export const LocaleProvider = ({
    children,
    supportedLanguages,
}: LocaleProviderProps) => {
    const history = useHistory();
    const { search } = useLocation();
    const userData = useUserState();
    const [localeId, setLocaleId] = useState<LocaleId>(
        initialPreferredLanguage ||
            getLanguageFromURL(getLanguageSearchParam(search)) ||
            getPartnerDefaultLanguage(supportedLanguages),
    );

    const changeLocale = async (localeId: LocaleId): Promise<void> => {
        storePreferredLanguageToLocalStorage(localeId);
        history.replace({ search: addLanguageSearchParam(search, localeId) });
        // do not await the following to keep ui locale change slick
        const userDetails = getDetails(userData);
        if (userDetails) {
            ServerApi.updateUser({
                preferredLanguage: localeId,
            }).catch((error) => {
                log.error(error);
            });
        }
        setLocaleId(localeId);
    };

    return (
        <LocaleContext.Provider
            value={{
                localeId: localeId,
                setLocaleId: changeLocale,
            }}
        >
            <IntlProvider
                locale={localeId}
                messages={localeIdToMessages.get(localeId)}
            >
                {children}
            </IntlProvider>
        </LocaleContext.Provider>
    );
};

export const LocaleConsumer = LocaleContext.Consumer;

export const useLocale = () => useContext(LocaleContext);
