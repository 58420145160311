import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import TouchButton from 'src/components/shared/controls/TouchButton';
import { Color, TextStyles, TextStyleTypes } from 'src/styles/Constants';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from '@material-ui/core';
import {
    SearchVenueByDistanceResult,
    SearchVenueByNameResult,
} from 'src/services/ServerApi';

export default ({
    venues,
    actionFunction,
    pendingGroupJoin,
}: {
    venues: (SearchVenueByDistanceResult | SearchVenueByNameResult)[];
    actionFunction: (groupId: string) => void;
    pendingGroupJoin: boolean;
}) => {
    return (
        <TableContainer style={{ paddingBottom: '30px' }}>
            <Table padding={'none'}>
                <TableBody>
                    {venues.map((row) => (
                        <TableRow
                            key={row.name}
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                borderBottom: `1px solid ${Color.G8_CONCRETE}`,
                            }}
                        >
                            <TableCell
                                style={{
                                    flexGrow: 1,
                                    padding: '10px 0 10px 0',
                                    border: 0,
                                }}
                            >
                                <VenueTitle>{row.name}</VenueTitle>
                                <VenueAddress>{row.address}</VenueAddress>
                            </TableCell>
                            <TableCell>
                                <TouchButton
                                    displayText={
                                        <FormattedMessage id="infoCollectionTitle.touchButtonJoin" />
                                    }
                                    backgroundColor={Color.T31_BLACK}
                                    textColor={Color.P1_WHITE}
                                    onClick={async () =>
                                        await actionFunction(row.id)
                                    }
                                    secondaryStyle={true}
                                    disabled={pendingGroupJoin}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const VenueTitle = styled.div`
    ${TextStyles[TextStyleTypes.P3]};
    text-align: left;
    font-weight: bold;
    width: 100%;
`;
const VenueAddress = styled.div`
    ${TextStyles[TextStyleTypes.S4]};
    text-align: left;
    width: 100%;
`;
