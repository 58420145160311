import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import { Color, TextStyles, TextStyleTypes } from 'src/styles/Constants';
import ClipLoader from 'react-spinners/ClipLoader';
import { debounce } from 'lodash';
import ServerApi, {
    SearchVenueByDistanceResult,
    SearchVenueByNameResult,
} from 'src/services/ServerApi';
import GeoSearch from './GeoSearch';
import TextSearch from './TextSearch';
import { usePartner } from 'src/hooks/usePartner';
import { useGroupProvider } from 'src/contexts/GroupProvider';

export enum SearchMode {
    manualSearch = 'manualSearch',
    geoSearch = 'geoSearch',
}

type Props = {
    // TODO: implement error message display and onContactSupportClick
    onContactSupportClick: () => void;
};

const VenueTile = ({ onContactSupportClick }: Props): JSX.Element => {
    const {
        groups,
        joinGroup: { joinGroup, error: joinGroupError },
        isLoading,
        getUserGroups: { error: getUserGroupsError },
    } = useGroupProvider();
    const partner = usePartner();

    const [isInitializing, setIsInitializing] = useState<boolean>(true);
    const [pendingTextSearch, setPendingTextSearch] = useState<boolean>(false);
    const [geoVenueList, setGeoVenueList] = useState<
        SearchVenueByDistanceResult[]
    >([]);
    const [venueList, setVenueList] = useState<
        SearchVenueByNameResult[] | null
    >(null);
    const [searchMode, setSearchMode] = useState<SearchMode>(
        SearchMode.geoSearch,
    );

    const { name, shortName, partnerId } = partner;

    const onJoinGroup = async (groupId: string) => {
        joinGroup(partnerId, groupId);
    };

    useEffect(() => {
        const position = async () => {
            setIsInitializing(true);

            if (isLoading) {
                return;
            }

            await navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const venueList = await ServerApi.searchVenues({
                        partnerId: partnerId,
                        lat: position.coords.latitude,
                        long: position.coords.longitude,
                    });
                    setGeoVenueList(
                        venueList.sort((va, vb) => va.distance - vb.distance),
                    );
                    setIsInitializing(false);
                },
                () => {
                    setSearchMode(SearchMode.manualSearch);
                    setIsInitializing(false);
                },
            );
        };
        position();
    }, [isLoading]);

    const debouncedChangeHandler = useCallback(
        debounce(
            async (value) => {
                if (value.length < 3) {
                    return;
                }
                setPendingTextSearch(true);
                const venueList = await ServerApi.searchVenues({
                    partnerId: partnerId,
                    name: value,
                });
                setVenueList(venueList);
                setPendingTextSearch(false);
            },
            400,
            {
                leading: false,
                trailing: true,
            },
        ),
        [],
    );

    const TileInitialization = () => (
        <>
            {!isLoading && (
                <DescriptionText>
                    <FormattedMessage
                        id={'infoCollectionTitle.geolocationGathering'}
                        values={{ name: shortName || name }}
                    />
                </DescriptionText>
            )}

            <SpinnerContainer>
                <ClipLoader size={20} loading={true} center={true} />
            </SpinnerContainer>
        </>
    );

    const getSearch = () => {
        switch (searchMode) {
            case SearchMode.geoSearch:
                return (
                    <GeoSearch
                        geoVenueList={geoVenueList}
                        onJoinGroup={onJoinGroup}
                        setSearchMode={setSearchMode}
                        setGeoVenueList={setGeoVenueList}
                        setVenueList={setVenueList}
                        pendingGroupJoin={isLoading}
                    />
                );
            case SearchMode.manualSearch:
                return (
                    <TextSearch
                        onChange={debouncedChangeHandler}
                        setVenueList={setVenueList}
                        pendingTextSearch={pendingTextSearch}
                        venueList={venueList}
                        onJoinGroup={onJoinGroup}
                        pendingGroupJoin={isLoading}
                    />
                );
        }
    };

    return isInitializing ? (
        TileInitialization()
    ) : (
        <>
            <TileTitle color={Color.T31_BLACK}>
                <FormattedMessage
                    id={'infoCollectionTitle.groupModeTileTitle'}
                />
            </TileTitle>
            {getSearch()}
        </>
    );
};

export default VenueTile;

const TileTitle = styled.div`
    ${TextStyles[TextStyleTypes.H3]};
    border-bottom: 2px solid ${Color.G8_CONCRETE};
    text-align: left;
    width: 100%;
`;

const DescriptionText = styled.div`
    margin: 15px 0 15px 0;
    ${TextStyles[TextStyleTypes.P1]}
    text-align: left;
    width: 100%;
`;

const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 30px 0 50px 0;
    flex-direction: row;
`;
