import React, { useState, useRef } from 'react';
import { Color, StyleDefaults, PistachioZIndex } from 'src/styles/Constants';
import styled from '@emotion/styled';

import SeesawAnimation from '../../../shared/animations/SeesawAnimation';
import infoModuleBackground from 'src/images/backgrounds/InfoModuleBG.png';
import LoadingCheckmark from '../../../shared/animations/LoadingCheckmark';
import { useAnalytics } from 'src/contexts/AnalyticsContext';
import { AppArea, EventName, Screen } from 'src/util/AnalyticsConstants';
import InformationModuleButtons, {
    CLOSE_BUTTON_HEIGHT,
    CLOSE_BUTTON_MARGIN_TOP,
} from './InformationModuleButtons';
import { FormattedMessage, useIntl } from 'react-intl';
import { EventModel } from 'src/util/TallyFirestore';
import ServerApi from 'src/services/ServerApi';
import useInformationModuleDetails from './useInformationModuleDetails';
import { NetworkError } from 'src/util/errors/NetworkError';
import { SMSAlertsState } from 'src/util/TallyFirestore';
import { useUpdateContactInfo } from 'src/contexts/UserStateContext';

interface Props {
    event: EventModel;
    hasUnansweredActivePrediction: boolean;
    hasActivePredictions: boolean;
    lastBatchReleased: boolean;
}

interface State {
    dismissedModule: boolean;
    requestInProgress: boolean;
    requestFinished: boolean;
}

const InformationModule = ({
    event,
    hasUnansweredActivePrediction,
    hasActivePredictions,
    lastBatchReleased,
}: Props) => {
    const intl = useIntl();
    const updateContactInfo = useUpdateContactInfo();
    const [moduleDetails, dismissSmsModule] = useInformationModuleDetails({
        event,
        hasActivePredictions,
        hasUnansweredActivePrediction,
        lastBatchReleased,
    });
    const analytics = useAnalytics();
    const [error, setError] = useState(undefined);

    const buttonContainerRef = useRef<HTMLDivElement | null>(null);
    const btnContainerHeight = useRef(0);
    const btnContainerWidth = useRef(0);
    const [state, setState] = useState<State>({
        dismissedModule: false,
        requestFinished: false,
        requestInProgress: false,
    });

    const onButtonClick = async () => {
        setState((prevState) => ({ ...prevState, requestInProgress: true }));

        await ServerApi.updateUser({
            smsAlertsState: SMSAlertsState.OPTED_IN,
        })
            .then((_) => {
                updateContactInfo({ smsAlertsState: SMSAlertsState.OPTED_IN });

                analytics.logEvent({
                    eventName: EventName.smsAlertsEnabled,
                    params: {
                        screen: Screen.eventPredictions,
                    },
                    eventCategory: AppArea.game,
                });

                setState((prevState) => ({
                    ...prevState,
                    requestFinished: true,
                }));
            })
            .catch((error) => {
                if (error instanceof NetworkError) {
                    alert(intl.formatMessage({ id: 'error.failedToFetch' }));
                } else {
                    alert(error.message);
                }
                setError(error);
                setState((prevState) => ({
                    ...prevState,
                    requestFinished: true,
                }));
            });
    };

    const onButtonLoadingCheckmarkAnimationComplete = () => {
        // Wait a couple seconds before informing the parent to switch to different info module details.
        setTimeout(() => {
            if (!error) {
                dismissSmsModule();
            }
        }, 2000);
    };

    const renderRequestSpinner = () => {
        const { requestFinished } = state;

        // TODO: What's that for?
        if (buttonContainerRef.current && btnContainerHeight.current === 0) {
            btnContainerHeight.current =
                buttonContainerRef.current.clientHeight -
                    (CLOSE_BUTTON_HEIGHT + CLOSE_BUTTON_MARGIN_TOP) || 40;
            btnContainerWidth.current =
                buttonContainerRef.current.clientWidth || 120;
        }
        return (
            <LoadingContainer
                height={btnContainerHeight.current}
                width={btnContainerWidth.current}
            >
                <LoadingCheckmark
                    color={Color.P1_WHITE}
                    onCheckmarkAnimationComplete={
                        onButtonLoadingCheckmarkAnimationComplete
                    }
                    showCheck={requestFinished}
                    sizePixels={18}
                />
            </LoadingContainer>
        );
    };

    const handleCloseRequest = () => {
        analytics.logEvent({
            eventName: EventName.dismissInfoModuleSms,
            params: {
                module_type: moduleDetails.title,
            },
        });

        // Fade the info module out first before informing the parent
        // to switch the module details.

        setState((prevState) => ({ ...prevState, dismissedModule: true }));

        setTimeout(() => {
            dismissSmsModule();
            setState((prevState) => ({
                ...prevState,
                dismissedModule: false,
            }));
        }, CONTAINER_FADE_DURATION_MS);
    };

    const { dismissedModule, requestInProgress } = state;
    const { body, preTitle, showSeesaw, title } = moduleDetails;
    return (
        <ModuleContainer
            backgroundImageUrl={
                moduleDetails.type === 'smsOptIn'
                    ? infoModuleBackground
                    : undefined
            }
            opacity={dismissedModule ? 0 : 1}
        >
            <PreTitle>{preTitle}</PreTitle>
            <Title>{title}</Title>
            <BodyText>{body}</BodyText>
            <BottomContent>
                {showSeesaw && <SeesawAnimation />}
                {moduleDetails.type === 'smsOptIn' &&
                    (requestInProgress ? (
                        renderRequestSpinner()
                    ) : (
                        <InformationModuleButtons
                            isDuringRequest={requestInProgress}
                            ref={buttonContainerRef}
                            onClose={handleCloseRequest}
                            onClick={onButtonClick}
                            text={
                                <FormattedMessage id="predictPage.smsOptInInfoModuleDetails.button" />
                            }
                        />
                    ))}
            </BottomContent>
        </ModuleContainer>
    );
};

export default InformationModule;

const CONTAINER_FADE_DURATION_MS = 1000;

const ModuleContainer = styled.div<{
    backgroundImageUrl?: string;
    opacity: number;
}>`
    background-image: url(${(props) =>
        props.backgroundImageUrl ? props.backgroundImageUrl : ''});
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    font-family: ${StyleDefaults.FONT_FAMILY};
    background-color: #d4f8ff;
    opacity: ${(props) => props.opacity};
    transition: opacity ${CONTAINER_FADE_DURATION_MS}ms ease-in-out;
    text-align: center;
    width: 100%;
    max-width: 500px;
    padding: 30px 0;
    z-index: ${PistachioZIndex.INFORMATION_MODULE};
`;

const PreTitle = styled.span`
    letter-spacing: 1.75px;
    line-height: 18px;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
`;
const Title = styled.h2`
    font-size: 34px;
    letter-spacing: -0.58px;
    line-height: 32px;
    font-weight: 900;
    margin: 5px auto 0 auto;
    text-transform: uppercase;
    max-width: 325px;
`;
const BodyText = styled.div`
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 18px;
    margin: 18px auto 0 auto;
    max-width: 325px;
`;

const BottomContent = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 14px;
    margin-bottom: 8px;
`;

const LoadingContainer = styled.div<{
    height: number;
    width: number;
}>`
    background-color: ${Color.T31_BLACK};
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${(props) => props.height}px;
    width: ${(props) => props.width}px;
    margin: 25px 0 ${CLOSE_BUTTON_HEIGHT + CLOSE_BUTTON_MARGIN_TOP}px 0;
`;
