import pollIcon from 'src/images/icons/Polls.svg';
import powerUpIcon from 'src/images/icons/Powerup.svg';
import bonusIcon from 'src/images/icons/Bonus.svg';
import correctIcon from 'src/images/icons/Picks-Correct-White-S@3x.svg';

import Theme from 'src/util/Theme';
import { PredictionSponsorshipUnitTemplateId } from 'src/util/SponsorshipTypes';
import {
    PredictionModel,
    PredictionType,
    SponsorModel,
    UserAnswer,
} from 'src/util/TallyFirestore';
import { AnswerCorrectness } from 'src/util/EventHelpers';
import { PillDetails } from 'src/components/pistachioGame/Pill';
import { Color } from 'src/styles/Constants';
import { predictionTypeIsMultipleChoicy } from 'src/util/PredictionHelpers';

export function predictionSelectedOptionId(
    prediction: PredictionModel,
    answer?: UserAnswer,
): string | undefined {
    if (!prediction.resolved) {
        return undefined;
    }
    if (predictionTypeIsMultipleChoicy(prediction.type)) {
        for (const id in answer) {
            if (answer[id] === true) {
                return id;
            }
        }
    }
    return undefined;
}

export const getContainerBorderColor = (
    type: PredictionType,
    correctness: AnswerCorrectness,
    answered: boolean,
    expanded: boolean,
) => {
    if (expanded && !answered && type === 'POLL') {
        return Color.T34_SQUIRTLE;
    }

    if (expanded || !answered || correctness === AnswerCorrectness.unknown) {
        return Color.T28_FOG;
    }
    return correctness === AnswerCorrectness.correct
        ? Color.PREDICTION_GREEN
        : Color.P4_CHILI_PEPPER;
};

export const getPillDetails = (
    prediction: PredictionModel,
    answered: boolean,
    sponsor?: SponsorModel,
) => {
    let pillDetails: PillDetails | undefined;

    if (prediction.type === 'POLL') {
        pillDetails = {
            asset: pollIcon,
            circleColor: Color.T34_SQUIRTLE,
            pillColor: Color.S18_BRIGHT_CYAN,
        };
    } else {
        if (answered) {
            pillDetails = {
                asset: correctIcon,
                circleColor: Theme.predictionAnsweredIconBackgroundColor, // Color.P6_ELECTRIC_BLUE,
                pillColor: Color.NAVY_BLUE,
            };
        } else {
            pillDetails = {
                asset: Theme.predictionLiveIcon,
                circleColor: Theme.predictionLiveIconBackgroundColor, //Color.P4_CHILI_PEPPER,
                pillColor: Color.S13_MAROON,
            };
        }
    }

    if (pillDetails && prediction.sponsorship && sponsor) {
        if (
            prediction.sponsorship.templateId ===
            PredictionSponsorshipUnitTemplateId.DOUBLE_POINT_POWERUP
        ) {
            pillDetails.asset = powerUpIcon;
            pillDetails.label = `${sponsor.shortName} Powerup`;
        } else if (
            prediction.sponsorship.templateId ===
            PredictionSponsorshipUnitTemplateId.PRIZE_REDEMPTION
        ) {
            pillDetails.asset = bonusIcon;
            pillDetails.label = `${sponsor.shortName} Bonus`;
        }
    }

    return pillDetails;
};
