import React, { useReducer } from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import SquareLoading from 'src/components/shared/animations/SquareLoading';
import { Color, TextStyles, TextStyleTypes } from 'src/styles/Constants';
import TallyMarkdown from 'src/components/shared/ui/TallyMarkdown';
import ServerError from 'src/services/ServerError';
import HelpLinks from 'src/components/shared/ui/HelpLinks';
import InputLabel from 'src/components/shared/ui/InputLabel';
import InputCode from 'src/components/shared/controls/InputCode';
import VerifyCodeCountdown from 'src/components/pistachioGame/PredictGame/VerifyCodeCountdown';
import { AppArea, EventName } from 'src/util/AnalyticsConstants';
import { useAnalytics } from 'src/contexts/AnalyticsContext';
import Logger from 'src/util/Logger';
import { TranslatedConfigPartnerData } from 'src/util/ConfigTypes';
import FirebaseAuth from 'src/services/FirebaseAuth';
import formatPhoneNumberLeadingPlus from 'src/util/phoneNumber';
import ServerApi from 'src/services/ServerApi';
import { NetworkError } from 'src/util/errors/NetworkError';
import {
    useInitializeUser,
    useSignInUser,
} from 'src/contexts/UserStateContext';
import { getPhoneAuthTranslatedErrorMessage } from '../utils';
import { getUserWithPrivateData } from 'src/util/FirestoreDao';
import { useGroupProvider } from 'src/contexts/GroupProvider';
import { BackgroundPanel } from 'src/components/shared/ui/BackgroundPanel';

const CODE_LENGTH = 6;

type Props = {
    partner: TranslatedConfigPartnerData;
    phoneNumber: string;
    verifyToken: string;
    onContactSupportClick: () => void;
    screenHasBg?: boolean | false;
    disclaimer?: JSX.Element;
};

type TypingState = {
    type: 'typing';
};

type SubmittingState = {
    type: 'submitting';
};

type ErrorState = {
    type: 'error';
    error: Error;
};

type TileState = TypingState | SubmittingState | ErrorState;

type TileAction =
    | { type: 'update' }
    | { type: 'submit' }
    | {
          type: 'error';
          error: Error;
      };

const reducer = (state: TileState, action: TileAction): TileState => {
    switch (action.type) {
        case 'submit': {
            if (state.type !== 'typing') {
                return state;
            }
            return {
                type: 'submitting',
            };
        }
        case 'error': {
            return {
                type: 'error',
                error: action.error,
            };
        }
        case 'update': {
            return {
                type: 'typing',
            };
        }
    }
};

const initialState = {
    type: 'typing',
} as const;

const PhoneCodeAuthTile = ({
    partner,
    phoneNumber,
    verifyToken,
    onContactSupportClick,
    screenHasBg,
    disclaimer,
}: Props): JSX.Element => {
    const signIn = useSignInUser();
    const initializeUser = useInitializeUser();
    const intl = useIntl();
    const { getUserGroups } = useGroupProvider();
    const analytics = useAnalytics();

    const [state, dispatch] = useReducer<TileState, TileAction>(
        reducer,
        initialState,
    );

    const tileTitle = intl.formatMessage({
        id: 'onboarding.verifyCode.title',
    });

    const onSubmitSmsCode = async (
        code: string,
        verifyToken: string,
        phoneNumber: string,
    ) => {
        dispatch({ type: 'submit' });
        try {
            const { token } = await ServerApi.verifySMSCode(
                partner.partnerId,
                formatPhoneNumberLeadingPlus(phoneNumber),
                verifyToken,
                code,
            );
            analytics.logEvent({
                eventName: EventName.signUpVerifySmsComplete,
                eventCategory: AppArea.signup,
            });

            const userCredential = await FirebaseAuth.signInWithCustomToken(
                token,
                phoneNumber,
            );
            const uid = userCredential.user!.uid;

            const existingUser = await getUserWithPrivateData(uid);

            getUserGroups.getUserGroups(partner.partnerId);

            if (existingUser) {
                initializeUser(uid, existingUser);
            } else {
                signIn(uid);
            }
        } catch (error) {
            const setError = (error: Error) => {
                dispatch({
                    type: 'error',
                    error,
                });
            };
            if (!(error instanceof Error)) {
                Logger.error('Unknown error submitting sms code', error);
                setError(
                    new Error(intl.formatMessage({ id: 'error.generic' })),
                );
                return;
            }

            if (error instanceof NetworkError) {
                setError(
                    new Error(
                        intl.formatMessage({
                            id: 'error.failedToFetch',
                        }),
                    ),
                );
                return;
            }
            if (error instanceof ServerError && error.type) {
                setError(
                    new Error(
                        getPhoneAuthTranslatedErrorMessage(intl, error.type),
                    ),
                );
                return;
            }

            setError(error);
        }
    };

    const fieldDescription = intl.formatMessage(
        {
            id: 'onboarding.verifyCode.description',
        },
        {
            phoneNumber: formatPhoneNumberLeadingPlus(phoneNumber),
        },
    );

    return (
        <>
            <BackgroundPanel
                screenHasBg={screenHasBg}
                topConnect={true}
                btmConnect={true}
            >
                <TileTitle color={Color.T31_BLACK}>{tileTitle}</TileTitle>
                {fieldDescription && (
                    <DescriptionText>
                        <TallyMarkdown source={fieldDescription} />
                    </DescriptionText>
                )}
                {state.type === 'error' && (
                    <>
                        <InputLabel error={state.error} />
                        <HelpLinks
                            onContactSupportClick={onContactSupportClick}
                        />
                    </>
                )}
                <InputCode
                    codeLength={CODE_LENGTH}
                    onInputUpdate={(code) => {
                        if (code.length === CODE_LENGTH) {
                            onSubmitSmsCode(code, verifyToken, phoneNumber);
                        } else {
                            dispatch({ type: 'update' });
                        }
                    }}
                    showError={state.type === 'error'}
                />
                <VerifyCodeCountdown
                    countdownSeconds={60}
                    onResend={() => {}} // resend is handled on sms provider side
                    currentError={undefined}
                />
            </BackgroundPanel>
            <BackgroundPanel screenHasBg={screenHasBg} topConnect={true}>
                {disclaimer}
            </BackgroundPanel>
            <LoadingContainer>
                {state.type === 'submitting' && (
                    <SquareLoading color={Color.P6_ELECTRIC_BLUE} />
                )}
            </LoadingContainer>
        </>
    );
};

const TileTitle = styled.div`
    ${TextStyles[TextStyleTypes.H3]}
    border-bottom: 2px solid ${Color.G8_CONCRETE};
    text-align: left;
    width: 100%;
`;

const DescriptionText = styled.div`
    margin: 15px 0 30px 0;
    ${TextStyles[TextStyleTypes.P1]}
    text-align: left;
    width: 100%;
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 20px;
    margin: 20px 0;
`;

export default PhoneCodeAuthTile;
