export default {
    fullLanguageName: 'English',
    messages: {
        'menu.play': 'Play',
        'menu.playing': 'Playing: {eventName}',
        'menu.gameLeaderboard': 'Leaderboard',
        'menu.groups.groups': 'Groups',
        'menu.tournament': 'Tournament',
        'menu.account': 'Account',

        'infoCollectionTitle.touchButtonLabel': 'NEXT',

        'onboarding.requestDisplayName.title': 'Claim a Name',
        'onboarding.requestDisplayName.description':
            'Choose a display name! This will appear on the leaderboard, so let’s keep it clean.',
        'onboarding.requestDisplayName.field.title': 'display name:',
        'onboarding.requestDisplayName.field.placeholder': 'Placeholder',

        'onboarding.okta.title': 'Getting the game ready ...',
        'onboarding.updates.title': 'Updates',

        'inputField.helpMe': 'contact us.',
        'inputField.helpQuestion':
            'Need help? Visit our {helpPage} or {contactPage}',

        'inputField.helpLinkTitle': 'Help Page',
        'inputField.contactLinkTitle': 'contact us.',
        'error.phoneAuth.invalidPhoneNumber': 'Invalid phone number',
        'error.phoneAuth.verificationCodesBlocked':
            'Submission failed. Please double check that you enter a mobile number in the fomrat xxx-xxx-xxxx',
        'error.phoneAuth.tooManyAttempts':
            'Too many attempts. Try again later.',
        'error.phoneAuth.incorrectVerificationCode':
            'Incorrect verification code.',
        'error.phoneAuth.verificationCodeExpired':
            'Code expired. Please resend and try again.',
        'error.phoneAuth.verifyRequestFailed':
            "Request to verify a user's code failed.",
        'error.failedToFetch':
            'Please check internet connection and try again.',
        'error.displayName.isTaken': 'Oops. That name is already taken.',
        'error.offensiveLanguage': 'Offensive language detected.',
        'inputField.error.charLimit': 'EXCEEDS {limit} CHARACTER LIMIT',
        'inputField.error.validationError': 'Invalid string',

        'onboarding.disclaimer': 'By continuing you agree to ',
        'onboarding.officialRules': 'Official Rules',
        'onboarding.termsAndConditions': 'Terms and Conditions',
        'onboarding.privacyPolicy': 'Privacy Policy',
        'onboarding.separator': 'and',
        'onboarding.cookies': 'Cookies',

        'onboarding.requestEmail.field.title': 'Email address:',
        'onboarding.requestEmail.bodyWithPrizes.markdown':
            'In order to redeem prizes we will need your email to contact you.',
        'onboarding.requestEmail.bodyWithoutPrizes.markdown':
            'Please provide your email for future communication.',
        'onboarding.invalidEmail': 'Please enter a valid email address',

        'optIn.marketing.phone.label': 'Get Texts',
        'optIn.marketing.email.label': 'Get Emails',
        'optIn.marketing.3p.label': 'Be contacted by our partners',

        'onboarding.requestPhone.title': 'Welcome',
        'onboarding.requestPhone.title.gameIsLive': 'Welcome',
        'onboarding.requestPhone.description.gameIsLive':
            'Predict for free as the action unfolds and compete for Exclusive Prizes. Enter your mobile phone number to get started.',
        'onboarding.requestPhone.title.gameIsFinal': 'Sign In',
        'onboarding.requestPhone.description.gameIsFinal':
            'The game is over, but you can still sign in to see your results or sign up with your mobile number to get ready for the next game.',

        'onboarding.requestPhone.field.title': 'phone number:',
        'onboarding.requestPhone.field.placeholder': 'xxx-xxx-xxxx',
        'onboarding.requestPhone.field.description':
            'We only use your phone number to contact you if you win.',

        'onboarding.verifyCode.title': 'Verify Code',
        'onboarding.verifyCode.description':
            'Please enter the verification code sent to {phoneNumber}',
        'onboarding.verifyCode.resendCode': 'Resend code',

        'onboarding.newIteration.fallbackTitle': 'Accept Game Rules',
        'onboarding.newIteration.fallbackNewOptInBody':
            'Please agree to the Official Rules and Terms & Conditions to participate.',
        'onboarding.newIteration.checkBox1FallbackLabel':
            'By checking this box and continuing I agree to the official rules and terms & conditions linked below',

        'gameFooter.contactSupport': 'Contact Support',
        'gameFooter.poweredBy': 'Powered by',

        'broadcastLeaderbord.playerLeaderBoard.title': 'Top of the leaderboard',
        'broadcastLeaderbord.playerLeaderBoard.stats.rank': 'Rank',
        'broadcastLeaderbord.playerLeaderBoard.stats.player': 'Player',
        'broadcastLeaderbord.playerLeaderBoard.stats.points': 'Points',

        'leaderBoardTab.switch.tournamentLeaderBoard': 'Tournament Leaderboard',
        'leaderBoardTab.switch.groupLeaderBoard': 'Group Leaderboard',
        'leaderBoardTab.header': 'Leaderboard',
        'leaderBoardTab.signInButton': 'Already played? Tap to sign in.',
        'leaderBoardTab.finalAfterGameDisclaimer':
            'Leaderboards will change during the game and are only final after the game is over.',

        'prosLeaderboard.header': 'How the Pros Did',

        'leaderBoard.prizeDetails': 'Prize details',
        'leaderBoard.prizeDetailsButton': 'Prize details',
        'leaderBoard.correctPicks': ' PICKS CORRECT',

        'settings.titleBar': 'PROFILE',
        'settings.welcomeText': 'Hi, {username}',
        'settings.joinedText': 'Joined: {date}',

        'account.rewards.header': 'Rewards',
        'account.stats.header': 'Stats',
        'account.stats.previous': 'Previous',
        'account.stats.next': 'Next',

        'settings.personalInfo.header': 'Personal Info',
        'settings.personalInfo.description': 'Email and phone number',
        'settings.personalInfo.email.label': 'Email',
        'settings.personalInfo.email.button': 'Save',
        'settings.personalInfo.email.placeholder': 'Your email address',
        'settings.personalInfo.phoneNumber.label': 'Phone number',

        'settings.marketing.header': 'Marketing',
        'settings.marketing.descriptionText':
            'Stay updated with {partnerName} by opting in for special promotions and marketing.',
        'settings.smsAlertHeader': 'Game Alerts',
        'settings.smsAlertLabel': 'SMS Game Alerts',
        'settings.smsAlertText':
            'Receive SMS alerts every time new predictions go live, and never miss out on the competition.',
        'settings.smsAlertUnstopText':
            'To opt back into SMS alerts, send ”UNSTOP" to',
        'settings.smsAlertUnstopLink': 'Click here to reactivate',
        'settings.subscribeButton.subscribe': 'Subscribed',
        'settings.subscribeButton.subscribed': 'Subscribe',

        'groupSettings.groupLeaderbord': 'Group Leaderboard',
        'groupSettings.groupLeaderbordDescription':
            'Create groups to compete in a private group with your own group leaderboards. Your group participation does not affect your results in the per game contests or Season Long Tournament.',
        'groupSettings.definitionList.owner': "You're the owner of",
        'groupSettings.definitionList.isLoading': 'Loading group...',
        'groupSettings.definitionList.member': "You're a member of",
        'groupSettings.definitionList.inviteLink': 'Invite link',
        'groupSettings.definitionList.deleteGroup': 'Delete group',
        'groupSettings.definitionList.leaveGroup': 'Leave group',
        'groupSettings.createGroup.inputLabel': 'Group name',
        'groupSettings.createGroup.inputPlaceholder': 'Some group name',
        'groupSettings.createGroup.buttonText': 'Create group',
        'groupSettings.createGroup.description':
            'You can also join the existing group by following the link provided by any group member.',

        'predictPage.userStats.titleOverride': 'My Game Stats',
        'predictPage.userStats.callToActionButton.label': 'LEADERBOARD',
        'predictPage.userStats.howToPlayButton.label': 'How To Play',
        'predictPage.predictionList.groupTitle': 'PENDING PREDICTIONS',
        'predictPage.predictionList.groupTitleTokenized': 'PREDICTIONS',
        'predictPage.heroicPredictionNotice': 'New Predictions',
        'predictPage.predictionList.lockLabel': 'Locks',
        'userStats.rank': 'Rank',
        'userStats.points': 'Points',
        'userStats.correct': 'Correct',

        'toast.nextLocksIn': 'Next Prediction locks: {textAddon}',
        'toast.resultsSoon': 'Final results coming soon',
        'toast.gameOver': 'Game over',

        'survey.callToAction': 'We want to hear from you',
        'survey.subText': 'Tell us what you thought of the experience.',
        'survey.subTextLink': 'Take 2-minute survey',

        'howToPlayModal.tag': 'Play for Free!',
        'howToPlayModal.header': 'How to Play',
        'howToPlayModal.button': "I'm ready, let's go",
        'howToPlayModal.defaultBody':
            'Predictions go live before tip-off, at the end of each quarter and at commercial breaks. You have a limited amount of time answer before they lock! No answer = no points.',

        'howToPlayTournamentModal.header': 'How to Play',
        'howToPlayTournamentModal.button': "I'm ready, let's go",

        'cumulativeLeaderboardTab.button': 'How to play',
        'cumulativeLeaderboardTab.myStats': 'My tournament stats',
        'cumulativeLeaderboardTab.gameLeaderboard': 'Game Leaderboard',
        'cumulativeLeaderboardTab.groupLeaderboard': 'Group Leaderboard',

        'group.isLoading': 'Loading group...',
        'group.shareLink.button': 'Copy Invite Link',
        'group.shareLink.description.groups':
            'Get with your friends to create a group and see who becomes the {partnerName} leader each game and throughout the season! Copy the link and send it to your friends via email, text, or social! Your group participation does not affect your results in the per game contests or season long tournament.',

        'groupLeaderboardTab.headerText.error': 'Error loading the group',
        'groupLeaderboardTab.pointsDescription':
            'Users and points on the group leaderboard will be updated during and after the event',
        'groupLeaderboardTab.headerText.notInGroup':
            "Your're not in a group yet!",
        'groupLeaderboardTab.headerText.notInGroup.descriptionStart':
            'Create a group to compete with your friends.',
        'groupLeaderboardTab.headerText.notInGroup.descriptionBottom':
            'You can also join an existing group. Ask your friends for the invitation link and get in on the fun!',
        'groupLeaderboardTab.button': 'How to play',
        'groupLeaderboardTab.myStats': 'My tournament stats',
        'groupLeaderboardTab.gameLeaderboard': 'Game Leaderboard',
        'groupLeaderboardTab.tournamentLeaderboard': 'Tournament Leaderboard',

        'predictPage.smsOptInInfoModuleDetails.body':
            "Get alerted when new picks go live. We'll only send up to 3 messages per game.",

        'predictPage.smsOptInInfoModuleDetails.button': 'GET SMS ALERTS',
        'predictPage.smsOptInInfoModuleDetails.preTitle': 'GET NOTIFIED',
        'predictPage.smsOptInInfoModuleDetails.title': 'MORE QUESTIONS COMING',
        'predictPage.noSmsOptInInfoModuleDetails.preTitle': 'GET NOTIFIED',
        'predictPage.noSmsOptInInfoModuleDetails.title':
            'MORE QUESTIONS COMING',
        'predictPage.deadStateAndAllAnsweredInfoModuleDetails.body':
            "Questions get released throughout the game. We'll notify you when the next set is live.",
        'predictPage.deadStateAndAllAnsweredInfoModuleDetails.title':
            'More questions coming',
        'predictPage.deadStateAndAllReleasedInfoModuleDetails.body':
            'Follow along as results are tallied live, shifting your spot on the leaderboard as the game progresses. Every point matters!',
        'predictPage.deadStateAndAllReleasedInfoModuleDetails.preTitle':
            'Stay Tuned',
        'predictPage.deadStateAndAllReleasedInfoModuleDetails.title':
            'Ranks Update in Real Time',
        'predictPage.activePredictionsInfoModuleDetails.body':
            'Points depend on how everyone predicts. Longshots are worth more than safe bets.',
        'predictPage.activePredictionsInfoModuleDetails.preTitle': 'HEADS UP!',
        'predictPage.activePredictionsInfoModuleDetails.title':
            'POINTS SHIFT UNTIL PICKS LOCK',
        'predictPage.smsOptInGameOverInfoModuleDetails.title': 'GAME OVER',
        'predictPage.basicGameOverInfoModuleDetails.bodyWithPrizes':
            'Prize winners will be contacted by email. Keep your eyes peeled for the next game!',
        'predictPage.basicGameOverInfoModuleDetails.bodyWithoutPrizes':
            'Thanks for playing. Check the leaderboard to see how you did.',

        'predictPage.basicGameOverInfoModuleDetails.preTitle':
            'CONGRATS WINNERS',
        'predictPage.basicGameOverInfoModuleDetails.title': 'GAME OVER',
        'predictPage.readMoreButton.text': 'Read More',

        'pistachioGame.stickyButton.text.single': '{count} Unmade Prediction',
        'pistachioGame.stickyButton.text.many': '{count} Unmade Predictions',
        'pistachioGame.stickyButton.gameDisclaimerFallback':
            'Please also refer to',
        'pistachioGame.toast.correct.title': 'You nailed it!',
        'pistachioGame.toast.correct.text': '+{points} pts.',
        'pistachioGame.toast.wrong.title': "You didn't get it right.",
        'pistachioGame.toast.wrong.text': 'Keep going @{name}',
        'pistachioGame.toast.noCorrect.title': 'Bummer! 0/{count} correct',
        'pistachioGame.toast.noCorrect.text': 'Keep going @{name}',
        'pistachioGame.toast.someCorrect.title': 'Nailed it!',
        // 'pistachioGame.toast.someCorrect.title': 'Nailed it! ${totalCorrect}/${totalPredictions} correct',
        'pistachioGame.toast.someCorrect.text': '+{points} pts.',
        'pistachioGame.pollToast.text': '+{points} pts.',
        // 'pistachioGame.pollToast.title': '{count} Poll results in!',
        'pistachioGame.pollToast.title': 'Poll results in!',

        'heroicCountdown.headline': 'Predictions lock:',

        'informationModule.closeButton': 'NO THANKS',

        'countdownHeader.label': 'PREDICTIONS LOCK: {timeLeftText}',

        'waitingRoom.smallPrediction.ended': 'You missed this one!',
        'waitingRoom.smallPrediction.notEnded': 'Pre-game predictions live:',
        'waitingRoom.emailNotifyTitle.ended': 'Get notified for the next game',
        'waitingRoom.emailNotifyTitle.notEnded': 'Get notified',
        'waitingRoom.emailSubmitted.smallActionText': "You're on the list",
        'waitingRoom.emailSubmitted.bigActionText': 'Boom!',
        'waitingRoom.detailsButton': 'Details',
        'waitingRoom.submitButton': 'Submit',
        'waitingRoom.continueSkipButton.continue': 'Continue',
        'waitingRoom.continueSkipButton.skip': 'Skip',
        'waitingRoom.gameOver': 'Game over',
        'waitingRoom.soon': 'Coming soon',
        'waitingRoom.tomorrowTime': 'Tomorrow {time}',

        'newPredictionCard.status.missed': 'Missed',
        'newPredictionCard.status.anytime': 'Anytime',
        'newPredictionCard.status.incorrect': 'Incorrect',
        'newPredictionCard.status.participated': 'Participated',
        'newPredictionCard.status.correct': 'Correct',
        'newPredictionCard.status.pending': 'Pending - {when}',
        'newPredictionCard.pollHeading': 'POLL',
        'newPredictionCard.pollPoints': 'PTS',
        'newPredictionCard.sponsorshipTag': 'Pick correct & win',

        'offerDetails.redeem.ButtonText': 'Redeem',
        'offerDetails.redeem.codeTitle': 'Your Code:',
        'offerDetails.expirationText.expired': 'Expired {when}',
        'offerDetails.expirationText.expires': 'Expires {when}',
        'offerDetails.redeem.howTo': 'How to claim',
        'offerDetails.redeem.disclaimer': 'Disclaimer Text',

        'leaderboardDeadstate.waitingSubHead': 'Results coming shortly ⏲',
        'leaderboardDeadstate.waitingLabel': 'Check back after game start',
        'leaderboardDeadstate.buttonText': 'Prize Details',

        'incognitoModal.tagLineText': 'Warning',
        'incognitoModal.headerText': 'Incognito Mode',
        'incognitoModal.bodyText':
            'Playing this game in incognito mode may result in predictions not being saved. Please switch over to regular browser mode to ensure your picks and username are preserved.',
        'incognitoModal.buttonText': 'I understand',

        'offerWinModal.youWon.label': 'You won',
        'offerWinModal.rewardsButton.text': 'See rewards',

        'newPredictionModal.label': 'Next Prediction Locks',
        'newPredictionModal.buttonText': 'Predict Now',

        'timeUntilLock.timeUntilLockMode.short': '1 DAY',
        'timeUntilLock.timeUntilLockMode.medium': 'TMRW {timeString}',
        'timeUntilLock.timeUntilLockMode.fallback': 'Tomorrow {timeString}',
        'timeUntilLock.dateType.actualDate': 'PREDICTIONS LOCK ON {dateString}',
        'timeUntilLock.dateType.daysUntil':
            'PREDICTIONS LOCK IN {daysUntilStart} DAYS',

        'dates.longWeekDay.sunday': 'Sunday',
        'dates.longWeekDay.monday': 'Monday',
        'dates.longWeekDay.tuesday': 'Tuesday',
        'dates.longWeekDay.wednesday': 'Wednesday',
        'dates.longWeekDay.thursday': 'Thursday',
        'dates.longWeekDay.friday': 'Friday',
        'dates.longWeekDay.saturday': 'Saturday',
        'dates.shortWeekDay.sunday': 'Sun.',
        'dates.shortWeekDay.monday': 'Mon.',
        'dates.shortWeekDay.tuesday': 'Tue.',
        'dates.shortWeekDay.wednesday': 'Wed.',
        'dates.shortWeekDay.thursday': 'Thu.',
        'dates.shortWeekDay.friday': 'Fri.',
        'dates.shortWeekDay.saturday': 'Sat.',

        'countdownPageBreak.batchLabel': 'Locks in',

        'powerUpAnimation.doublePointPowerup.label':
            '{name} wants to up your game',
        'powerUpAnimation.fallback.label': '{name} wants to give you an edge',

        'verifyCodeCountdown.button.label': 'RESEND CODE',
        'verifyCodeCountdown.underlineText': 'code resent',
        'verifyCodeCountdown.underlineCountdown': 'RESEND IN',

        'bonusSponsors.thankYouLabel': 'thank you bonus sponsors',

        'playgroundGame.decoySpacer': 'Welcome to the PLAYGROUND 🤘',

        'prizeDetailsModal.button': 'Play now',
        'prizeDetailsModal.header': 'Prizes',

        'broadcastSideboard.copyQuestionButton': 'COPY',
        'broadcastSideboard.copyOptionButton': 'COPY',
        'broadcastSideboard.percentSpan': 'Fill in the blank',
        'broadcastSideboard.clipboardText': 'copied!',
        'broadcastView.playerCount': '{count} Players',
        'broadcastView.emptyState': 'No predictions have been answered yet.',
        'tvBroadcastView.rank': 'Rank',
        'tvBroadcastView.player': 'Player',
        'tvBroadcastView.points': 'Points',
        'tvBroadcastView.correct': '# Correct',
        'groupPage.groupView.subHead.groups': 'Current Group Members:',
        'groupPage.groupView.subHead.venue': 'Playing in your bar:',
        'groupPage.groupView.owner': 'Owner',
        'groupPage.error': 'Error fetching group!',
        'groupPage.warning':
            "You can only be a member of one group, so joining agroup will make you leave the group you're currently in. Leaving the group you're the owner of will cause the deletion of the group!",
        'groupPage.playButton': 'Play the game',
        'groupPage.joinButton': 'Join group',
        'groupPage.joinedButton': 'Joined!',
        'presentedBy.label': 'Presented by',
        'createGroupForm.inputLabel': 'Group name',
        'createGroupForm.buttonText': 'Create group',
        'createGroupForm.cancel': 'Cancel',
        'createGroupForm.inputPlaceholder': 'Some group name',
        'deleteGroupForm.ownerDefinition': "You're the owner of",
        'deleteGroupForm.memberDefinition': "You're a member of",
        'deleteGroupForm.ownerButtonText': 'Delete group',
        'deleteGroupForm.memberButtonText': 'Leave group',
        'copyToClipboardButton.tooltip.copied': 'Copied',
        'socialTitle.label': 'Share the game',
        'offerCard.status.pending': 'Unlocked after the game',
        'offerCard.status.today': 'Today',
        'offerCard.status.tomorrow': 'Tomorrow',
        'offerCard.status.inDays': '{in} days',
        'offerCard.status.expd': 'Expired: {when}',
        'offerCard.status.exps': 'Expires: {when}',

        'predictions.blank.label': 'My prediction',
        'predictions.blank.average': 'AVG',
        'predictions.blank.result': 'RESULT',
        'predictions.blank.errorGreater': 'Maximum value is {value}',
        'predictions.blank.errorBelow': 'Minimum value is {value}',

        'prediction.option.odds.betToWin': 'Bet {bet} to win {win}',
        'prediction.option.odds.liveOddsBy':
            'Live Odds provided by {sportsbook}',
        'prediction.option.odds.oddsType.MONEY_LINE': 'Money Line',
        'prediction.option.odds.oddsType.OVER_UNDER': 'Over/Under',
        'prediction.option.draw': 'Draw',
        'prediction.option.nobet': 'No Bet',

        'storageConsentModal.description':
            'This website stores data such as cookies to enable important site functionality including analytics, targeting, and personalization. You may alter your preferences at any time or accept the default settings.',
        'storageConsentModal.storagePolicy.link.text': 'Data Storage Policy',
        'storageConsentModal.necessary.switch.label': 'Strictly Necessary',
        'storageConsentModal.analytics.switch.label': 'Analytics',
        'storageConsentModal.button.save': 'Save',
    },
} as const;
