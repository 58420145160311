import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';

import GroupSettings from './GroupSettings';
import MarketingSettings from './MarketingSettings';
import PersonalSettings from './PersonalSettings';
import SmsSettings from './SmsSettings';
import { TranslatedIteration } from 'src/util/i18n';
import { EventModel } from 'src/util/TallyFirestore';
import { openCustomerSupport } from 'src/util/GameShared';
import { useUserState } from 'src/contexts/UserStateContext';
import { usePartner } from 'src/hooks/usePartner';
import { useAnalytics } from 'src/contexts/AnalyticsContext';
import { EventName } from 'src/util/AnalyticsConstants';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 80,
    },
});

type Props = {
    iteration: TranslatedIteration;
    event?: EventModel;
};

const Settings = ({ iteration, event }: Props) => {
    const partner = usePartner();
    const userData = useUserState();
    const analytics = useAnalytics();

    const { smsAlertsEnabled } = partner;

    const classes = useStyles();

    return (
        <div className={classes.root}>
            {userData.type === 'initialized' && userData.contactInfo && (
                <>
                    <PersonalSettings
                        contactInfo={userData.contactInfo}
                        onContactSupportClick={() => {
                            openCustomerSupport(
                                event ? { event } : { iteration },
                            );
                            analytics.logEvent({
                                eventName: EventName.contactSupport,
                            });
                        }}
                    />
                    <MarketingSettings contactInfo={userData.contactInfo} />
                    {smsAlertsEnabled && (
                        <SmsSettings contactInfo={userData.contactInfo} />
                    )}
                </>
            )}
            {(iteration.groupMode === 'USER_GROUP' ||
                iteration.groupMode === 'TEAM,USER_GROUP') && (
                <GroupSettings event={event} />
            )}
        </div>
    );
};

export default Settings;
