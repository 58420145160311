import React from 'react';
import styled from '@emotion/styled';

import {
    LeaderboardEntry,
    SummarizedLeaderboardRankEntry,
} from 'src/util/TallyFirestore';
import LeaderboardPlayer from './LeaderboardPlayer';
import Theme from 'src/util/Theme';
import { Color, TextStyles, TextStyleTypes } from 'src/styles/Constants';
import LeaderboardCheckMark, { RankStats } from './LeaderboardCheckMark';
import { useLocale } from 'src/hooks/useLocale';

const COMPRESSED_RANK_THRESHOLD = 5;
const PLAYERS_PER_COMPRESSED_RANK = 5;

type Props = {
    rowNumber: number;
    rowData: SummarizedLeaderboardRankEntry;
    leaderboardEntry?: LeaderboardEntry;
    prizes?: string[];
    showPrizeDetails?: () => void;
    omitCorrectAnswers?: boolean;
    omitFirstPlayerHighlight?: boolean;
};

const LeaderboardRow = ({
    rowNumber,
    rowData,
    leaderboardEntry,
    prizes,
    showPrizeDetails,
    omitCorrectAnswers,
    omitFirstPlayerHighlight,
}: Props) => {
    const { localeId } = useLocale();
    let prize;
    if (prizes) {
        prize = prizes[rowData.rank - 1]; // is -1 because of  array index starts from 0
    }

    // Render all players unless this rank is >= compressed rank threshold.
    // then render a compressed version of that rank.
    let playersToRender = rowData.players.length;
    if (
        rowNumber >= COMPRESSED_RANK_THRESHOLD &&
        playersToRender > PLAYERS_PER_COMPRESSED_RANK
    ) {
        playersToRender = PLAYERS_PER_COMPRESSED_RANK;
    }

    const playerNames: JSX.Element[] = [];

    // If the user is in this rank, render them at the top.
    if (leaderboardEntry && leaderboardEntry.rank === rowData.rank) {
        const user = (
            <LeaderboardPlayer
                omitFirstPlayerHighlight={omitFirstPlayerHighlight}
                key={leaderboardEntry.uid}
                currentRank={rowData.rank}
                player={leaderboardEntry}
                currentPlayer={leaderboardEntry}
            />
        );
        playerNames.push(user);
    }

    // skip overflow if the overflow would end up as +1 but we had the player data for that overflow.
    const skipOverflow =
        rowData.players.length === rowData.totalCount &&
        rowData.players.length === playersToRender + 1;

    for (const player of rowData.players) {
        // If we have already rendered the maximum players.
        if (playerNames.length === playersToRender && !skipOverflow) {
            break;
        }

        // Ignore the current user since we rendered them first.
        if (leaderboardEntry && leaderboardEntry.rank === rowData.rank) {
            continue;
        }

        const playerName = (
            <LeaderboardPlayer
                omitFirstPlayerHighlight={omitFirstPlayerHighlight}
                key={player.uid}
                currentRank={rowData.rank}
                player={player}
            />
        );
        playerNames.push(playerName);
    }

    // If there are more players in the rank that we just don't have data for,
    // render the overflow count to represent them.
    if (playerNames.length < rowData.totalCount) {
        playerNames.push(
            <RankPlayer key={rowData.rank} color={Color.T31_BLACK}>
                + {rowData.totalCount - playerNames.length}
            </RankPlayer>,
        );
    }
    const readableNumber = rowData.points.toLocaleString(localeId);
    const { correctAnswers, totalQuestionCount } = rowData.players[0];

    return (
        <Rank key={rowData.rank}>
            <RankLabel>{rowData.rank}</RankLabel>
            <RankBody>
                {prize && (
                    <RankPrizeContainer onClick={showPrizeDetails}>
                        {prize}
                    </RankPrizeContainer>
                )}
                <RankInformation showPadding={!!prize}>
                    <RankPlayers>{playerNames}</RankPlayers>
                    <RankStatsContainer>
                        <RankStats>{readableNumber}</RankStats>
                        {!omitCorrectAnswers &&
                            correctAnswers !== undefined && (
                                <LeaderboardCheckMark
                                    correctAnswers={correctAnswers}
                                    total={totalQuestionCount}
                                />
                            )}
                    </RankStatsContainer>
                </RankInformation>
            </RankBody>
        </Rank>
    );
};

export default LeaderboardRow;

const RankPrizeContainer = styled.div`
    ${TextStyles[TextStyleTypes.S4]}
    letter-spacing: 1.5px;
    text-transform: upperCase;
    cursor: pointer;
    text-align: left;
    padding: 0 0 10px 0;
    margin: 0 0 10px 0;
    color: ${Theme.leaderboardPrizeTextColor};
    border-bottom: 1px solid ${Theme.leaderboardHorizontalRuleColor};
    transition: filter 0.3s;
`;

const RankPlayers = styled.div`
    display: flex;
    flex-direction: column;
`;

const RankPlayer = styled.div<{ color: string }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    color: ${(props) => props.color};
    text-align: left;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
`;

const Rank = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border-bottom: 1px solid ${Theme.leaderboardHorizontalRuleColor};
    padding: 10px 0;
`;

const RankInformation = styled.div<{ showPadding: boolean }>`
    padding: ${(props) => (props.showPadding ? '2px 0 6px; 0' : undefined)};
    display: flex;
    flex-direction: row;
`;

const RankStatsContainer = styled.div`
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 2;
`;

const RankLabel = styled.div`
    text-align: left;
    font-size: 18px;
    font-weight: 900;
    line-height: 18px;
    min-width: 45px;
    padding: 0 5px 0 0;
`;

const RankBody = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;
