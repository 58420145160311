import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { useHistory, useLocation } from 'react-router-dom';

import routeUrls from 'src/routes';
import { SignInButton } from 'src/Iteration/components';
import HeaderText from 'src/components/shared/ui/HeaderText';
import Leaderboard from 'src/components/pistachioGame/leaderboard/Leaderboard';
import {
    EventState,
    SponsorModel,
    FullLeaderboard,
    eventHasPrizeDetailsMarkdown,
    EventModel,
} from 'src/util/TallyFirestore';
import UserStats from 'src/components/shared/ui/UserStats';
import { AppArea, Screen } from 'src/util/AnalyticsConstants';
import {
    StyleDefaults,
    Orientation,
    Alignment,
    Color,
} from 'src/styles/Constants';
import SurveyTile from 'src/components/shared/ui/SurveyTile';
import LeaderboardDeadstate from 'src/components/pistachioGame/leaderboard/LeaderboardDeadstate';
import PresentedBy from 'src/components/shared/ui/PresentedBy';
import { usePartner } from 'src/hooks/usePartner';
import { useAnalytics } from 'src/contexts/AnalyticsContext';
import { useUserState } from 'src/contexts/UserStateContext';
import TouchButton from '../shared/controls/TouchButton';
import { useShowEventPrizeDetails } from 'src/contexts/ShowEventPrizeDetailsContext';

type Props = {
    event: EventModel;
    gameWideSponsor?: SponsorModel;
    leaderboard: FullLeaderboard;
    omitCorrectAnswers: boolean;
};

const EventGeneralLeaderboardPage = ({
    event,
    gameWideSponsor,
    leaderboard,
    omitCorrectAnswers,
}: Props) => {
    const location = useLocation();
    const history = useHistory();
    const showEventPrizeDetails = useShowEventPrizeDetails();
    const intl = useIntl();
    const partner = usePartner();
    const userState = useUserState();
    const analytics = useAnalytics();

    const showPrizeDetails =
        showEventPrizeDetails &&
        (eventHasPrizeDetailsMarkdown(event)
            ? () => showEventPrizeDetails(event)
            : undefined);

    useEffect(() => {
        analytics.logScreenView({
            screenName: `${event.shortName} - ${Screen.eventLeaderboard}`,
            screenType: Screen.eventLeaderboard,
            appArea: AppArea.game,
        });
    }, [analytics]);

    const surveyUrl =
        event.state === EventState.final && partner.properties.surveyUrl;

    const userName =
        userState.type === 'initialized' ? userState.details.displayName : '';

    const presentedByLogo = event.sponsorship
        ? event.sponsorship.properties.gameWideLogoUrl ||
          event.sponsorship.properties.gameLogoUrl
        : undefined;

    let imageUrl, bannerLink;
    if (partner.properties.leaderboardBanner) {
        imageUrl = partner.properties.leaderboardBanner.imageUrl;
        bannerLink = partner.properties.leaderboardBanner.linkUrl;
    } else if (gameWideSponsor && event.sponsorship) {
        imageUrl = event.sponsorship.properties.gameWideBanner;
        bannerLink = event.sponsorship.properties.gameWideBannerLink;
    }

    return (
        <Container>
            {bannerLink ? (
                <a href={bannerLink} target="lbBanner">
                    <Banner src={imageUrl} />
                </a>
            ) : (
                <Banner src={imageUrl} />
            )}

            <WidthRestrictor>
                <HeaderText
                    headerText={event.shortName}
                    subHeaderText={intl.formatMessage({
                        id: 'leaderBoardTab.header',
                    })}
                />
                {presentedByLogo && (
                    <PresentedBy
                        sponsorshipUnit={event.sponsorship}
                        asset={presentedByLogo}
                        orientation={Orientation.HORIZONTAL}
                        alignment={Alignment.CENTER}
                    />
                )}
                {partner.properties.showLeaderboardPrizeMarkdown &&
                    showPrizeDetails && (
                        <TouchButton
                            backgroundColor={Color.TRANSPARENT}
                            textColor={Color.P2_DARK_OLIVE}
                            displayText={
                                <FormattedMessage id="leaderboardDeadstate.buttonText" />
                            }
                            onClick={showPrizeDetails}
                            stretchWidth={true}
                            underline={true}
                        />
                    )}
                {userName ? (
                    <UserStats
                        leaderboardEntry={leaderboard.leaderboardEntry}
                        name={userName}
                        omitCorrectAnswers={omitCorrectAnswers}
                    />
                ) : (
                    <SignInButton
                        onClick={() =>
                            history.push({
                                pathname: routeUrls.onboarding,
                                state: { from: location },
                            })
                        }
                    >
                        <FormattedMessage id="leaderBoardTab.signInButton" />
                    </SignInButton>
                )}
                {surveyUrl && (
                    <SurveyTile eventSlug={event.slug} surveyUrl={surveyUrl} />
                )}

                {leaderboard.leaderboardSummary &&
                leaderboard.leaderboardSummary.ranks.length > 0 ? (
                    <Leaderboard
                        showPrizeDetails={showPrizeDetails}
                        leaderboard={leaderboard.leaderboardSummary}
                        leaderboardEntry={leaderboard.leaderboardEntry}
                        prizes={event.prizes}
                        disclaimer="leaderBoardTab.finalAfterGameDisclaimer"
                        omitCorrectAnswers={omitCorrectAnswers}
                    />
                ) : (
                    <LeaderboardDeadstate showPrizeDetails={showPrizeDetails} />
                )}
            </WidthRestrictor>
        </Container>
    );
};

export default EventGeneralLeaderboardPage;

const Banner = styled.img`
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: auto;
`;

const Container = styled.div<{
    backgroundColor?: string;
}>`
    max-width: ${StyleDefaults.MAX_ELEMENT_WIDTH};
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${(props) => props.backgroundColor || Color.G10_WHITISH};
`;

const WidthRestrictor = styled.div`
    max-width: ${StyleDefaults.MAX_ELEMENT_WIDTH};
    width: ${StyleDefaults.ELEMENT_WIDTH};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${StyleDefaults.PAGE_PADDING};
`;
