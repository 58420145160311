import React from 'react';
import { Color } from 'src/styles/Constants';

const SvgDotsPredictionIntro = (props) => (
    <svg width="1em" height="1em" viewBox="0 0 550 500" {...props}>
        <defs>
            <path id="DotsPredictionIntro_svg__a" d="M0 0h553v501H0z" />
        </defs>
        <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
            <mask id="DotsPredictionIntro_svg__b" fill="#fff">
                <use xlinkHref="#DotsPredictionIntro_svg__a" />
            </mask>
            <g mask="url(#DotsPredictionIntro_svg__b)">
                <g transform="translate(-52 -32)">
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={319}
                        cy={113}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={319}
                        cy={133}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={339}
                        cy={133}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={299}
                        cy={133}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={151.5}
                        cy={324.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={151.5}
                        cy={344.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={151.5}
                        cy={364.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={151.5}
                        cy={384.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={151.5}
                        cy={404.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={171.5}
                        cy={344.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={171.5}
                        cy={364.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={171.5}
                        cy={384.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={191.5}
                        cy={364.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={111.5}
                        cy={364.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={131.5}
                        cy={344.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={131.5}
                        cy={364.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={131.5}
                        cy={384.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={254.5}
                        cy={351.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={254.5}
                        cy={371.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={254.5}
                        cy={391.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={254.5}
                        cy={411.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={254.5}
                        cy={431.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={274.5}
                        cy={371.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={274.5}
                        cy={391.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={274.5}
                        cy={411.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={294.5}
                        cy={391.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={214.5}
                        cy={391.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={234.5}
                        cy={371.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={234.5}
                        cy={391.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={234.5}
                        cy={411.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={358.5}
                        cy={351.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={358.5}
                        cy={371.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={358.5}
                        cy={391.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={358.5}
                        cy={411.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={358.5}
                        cy={431.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={378.5}
                        cy={371.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={378.5}
                        cy={391.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={378.5}
                        cy={411.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={398.5}
                        cy={391.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={318.5}
                        cy={391.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={338.5}
                        cy={371.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={338.5}
                        cy={391.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={338.5}
                        cy={411.5}
                        r={1.5}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={204}
                        cy={268}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={204}
                        cy={288}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={204}
                        cy={308}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={204}
                        cy={328}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={204}
                        cy={348}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={224}
                        cy={288}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={224}
                        cy={308}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={224}
                        cy={328}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={244}
                        cy={308}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={164}
                        cy={308}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={184}
                        cy={288}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={184}
                        cy={308}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={184}
                        cy={328}
                        r={1}
                    />
                    <g
                        transform="translate(163 165)"
                        fill={props.color ? props.color : Color.P1_WHITE}
                    >
                        <circle cx={41} cy={1} r={1} />
                        <circle cx={41} cy={21} r={1} />
                        <circle cx={41} cy={41} r={1} />
                        <circle cx={41} cy={61} r={1} />
                        <circle cx={41} cy={81} r={1} />
                        <circle cx={61} cy={21} r={1} />
                        <circle cx={61} cy={41} r={1} />
                        <circle cx={61} cy={61} r={1} />
                        <circle cx={81} cy={41} r={1} />
                        <circle cx={1} cy={41} r={1} />
                        <circle cx={21} cy={21} r={1} />
                        <circle cx={21} cy={41} r={1} />
                        <circle cx={21} cy={61} r={1} />
                    </g>
                    <g
                        transform="translate(87 85)"
                        fill={props.color ? props.color : Color.P1_WHITE}
                    >
                        <circle cx={41} cy={1} r={1} />
                        <circle cx={41} cy={21} r={1} />
                        <circle cx={41} cy={41} r={1} />
                        <circle cx={41} cy={61} r={1} />
                        <circle cx={41} cy={81} r={1} />
                        <circle cx={61} cy={21} r={1} />
                        <circle cx={61} cy={41} r={1} />
                        <circle cx={61} cy={61} r={1} />
                        <circle cx={81} cy={41} r={1} />
                        <circle cx={1} cy={41} r={1} />
                        <circle cx={21} cy={21} r={1} />
                        <circle cx={21} cy={41} r={1} />
                        <circle cx={21} cy={61} r={1} />
                    </g>
                    <g
                        transform="translate(13 165)"
                        fill={props.color ? props.color : Color.P1_WHITE}
                    >
                        <circle cx={41} cy={1} r={1} />
                        <circle cx={41} cy={21} r={1} />
                        <circle cx={41} cy={41} r={1} />
                        <circle cx={41} cy={61} r={1} />
                        <circle cx={41} cy={81} r={1} />
                        <circle cx={61} cy={21} r={1} />
                        <circle cx={61} cy={41} r={1} />
                        <circle cx={61} cy={61} r={1} />
                        <circle cx={81} cy={41} r={1} />
                    </g>
                    <g
                        transform="translate(541 351)"
                        fill={props.color ? props.color : Color.P1_WHITE}
                    >
                        <circle cx={41} cy={1} r={1} />
                        <circle cx={41} cy={21} r={1} />
                        <circle cx={41} cy={41} r={1} />
                        <circle cx={41} cy={61} r={1} />
                        <circle cx={41} cy={81} r={1} />
                        <circle cx={61} cy={21} r={1} />
                        <circle cx={61} cy={41} r={1} />
                        <circle cx={61} cy={61} r={1} />
                        <circle cx={1} cy={41} r={1} />
                        <circle cx={21} cy={21} r={1} />
                        <circle cx={21} cy={41} r={1} />
                        <circle cx={21} cy={61} r={1} />
                    </g>
                    <g
                        transform="translate(454 371)"
                        fill={props.color ? props.color : Color.P1_WHITE}
                    >
                        <circle cx={41} cy={1} r={1} />
                        <circle cx={41} cy={21} r={1} />
                        <circle cx={41} cy={41} r={1} />
                        <circle cx={41} cy={61} r={1} />
                        <circle cx={41} cy={81} r={1} />
                        <circle cx={61} cy={21} r={1} />
                        <circle cx={61} cy={41} r={1} />
                        <circle cx={61} cy={61} r={1} />
                        <circle cx={81} cy={41} r={1} />
                        <circle cx={1} cy={41} r={1} />
                        <circle cx={21} cy={21} r={1} />
                        <circle cx={21} cy={41} r={1} />
                        <circle cx={21} cy={61} r={1} />
                    </g>
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={545}
                        cy={432}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={545}
                        cy={452}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={545}
                        cy={492}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={545}
                        cy={512}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={565}
                        cy={452}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={565}
                        cy={472}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={565}
                        cy={492}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={585}
                        cy={472}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={505}
                        cy={472}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={525}
                        cy={452}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={525}
                        cy={472}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={525}
                        cy={492}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={428}
                        cy={113}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={428}
                        cy={133}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={437}
                        cy={146}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={337}
                        cy={146}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={437}
                        cy={166}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={337}
                        cy={166}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={437}
                        cy={186}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={337}
                        cy={186}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={448}
                        cy={133}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={457}
                        cy={146}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={357}
                        cy={146}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={457}
                        cy={166}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={357}
                        cy={166}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={477}
                        cy={146}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={377}
                        cy={146}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={397}
                        cy={146}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={297}
                        cy={146}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={408}
                        cy={133}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={417}
                        cy={146}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={317}
                        cy={146}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={417}
                        cy={166}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={317}
                        cy={166}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={603}
                        cy={146}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={603}
                        cy={166}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={563}
                        cy={146}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={583}
                        cy={146}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={583}
                        cy={166}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={306}
                        cy={288}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={306}
                        cy={308}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={306}
                        cy={328}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={306}
                        cy={348}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={306}
                        cy={368}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={326}
                        cy={308}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={326}
                        cy={328}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={326}
                        cy={348}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={346}
                        cy={328}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={266}
                        cy={328}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={286}
                        cy={308}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={286}
                        cy={328}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={286}
                        cy={348}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={493}
                        cy={159}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={393}
                        cy={159}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={493}
                        cy={179}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={393}
                        cy={179}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={493}
                        cy={199}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={393}
                        cy={199}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={493}
                        cy={219}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={493}
                        cy={239}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={513}
                        cy={179}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={513}
                        cy={199}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={413}
                        cy={199}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={513}
                        cy={219}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={413}
                        cy={219}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={533}
                        cy={199}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={553}
                        cy={179}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={553}
                        cy={199}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={553}
                        cy={219}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={573}
                        cy={199}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={453}
                        cy={199}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={473}
                        cy={179}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={373}
                        cy={179}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={473}
                        cy={199}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={473}
                        cy={219}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={373}
                        cy={219}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={414}
                        cy={314}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={414}
                        cy={334}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={414}
                        cy={354}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={414}
                        cy={374}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={434}
                        cy={314}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={434}
                        cy={354}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={454}
                        cy={334}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={374}
                        cy={334}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={394}
                        cy={314}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={394}
                        cy={334}
                        r={1}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={394}
                        cy={354}
                        r={1}
                    />
                    <g
                        transform="translate(158 403)"
                        fill={props.color ? props.color : Color.P1_WHITE}
                    >
                        <circle cx={40} cy={2} r={2} />
                        <circle cx={40} cy={22} r={2} />
                        <circle cx={40} cy={42} r={2} />
                        <circle cx={40} cy={62} r={2} />
                        <circle cx={40} cy={82} r={2} />
                        <circle cx={60} cy={22} r={2} />
                        <circle cx={60} cy={42} r={2} />
                        <circle cx={60} cy={62} r={2} />
                        <circle cx={80} cy={42} r={2} />
                        <circle cx={2} cy={42} r={2} />
                        <circle cx={20} cy={22} r={2} />
                        <circle cx={20} cy={42} r={2} />
                        <circle cx={20} cy={62} r={2} />
                    </g>
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={74}
                        cy={411}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={74}
                        cy={431}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={74}
                        cy={451}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={74}
                        cy={471}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={94}
                        cy={431}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={94}
                        cy={451}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={114}
                        cy={451}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={54}
                        cy={431}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={54}
                        cy={471}
                        r={2}
                    />
                    <g
                        transform="translate(562 491)"
                        fill={props.color ? props.color : Color.P1_WHITE}
                    >
                        <circle cx={40} cy={2} r={2} />
                        <circle cx={40} cy={22} r={2} />
                        <circle cx={40} cy={42} r={2} />
                        <circle cx={2} cy={42} r={2} />
                        <circle cx={20} cy={22} r={2} />
                        <circle cx={20} cy={42} r={2} />
                    </g>
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={255}
                        cy={492}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={255}
                        cy={512}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={255}
                        cy={532}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={275}
                        cy={512}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={275}
                        cy={532}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={295}
                        cy={532}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={215}
                        cy={532}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={235}
                        cy={512}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={235}
                        cy={532}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={355}
                        cy={512}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={355}
                        cy={532}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={375}
                        cy={512}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={375}
                        cy={532}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={395}
                        cy={532}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={315}
                        cy={532}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={335}
                        cy={532}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={455}
                        cy={492}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={455}
                        cy={512}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={455}
                        cy={532}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={475}
                        cy={512}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={475}
                        cy={532}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={495}
                        cy={532}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={415}
                        cy={532}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={435}
                        cy={512}
                        r={2}
                    />
                    <circle
                        fill={props.color ? props.color : Color.P1_WHITE}
                        cx={435}
                        cy={532}
                        r={2}
                    />
                    <g
                        transform="translate(286)"
                        fill={props.color ? props.color : Color.P1_WHITE}
                    >
                        <circle cx={141} cy={34} r={1} />
                        <circle cx={41} cy={34} r={1} />
                        <circle cx={141} cy={54} r={1} />
                        <circle cx={41} cy={54} r={1} />
                        <circle cx={141} cy={74} r={1} />
                        <circle cx={41} cy={74} r={1} />
                        <circle cx={161} cy={34} r={1} />
                        <circle cx={61} cy={34} r={1} />
                        <circle cx={161} cy={54} r={1} />
                        <circle cx={61} cy={54} r={1} />
                        <circle cx={181} cy={34} r={1} />
                        <circle cx={81} cy={34} r={1} />
                        <circle cx={101} cy={34} r={1} />
                        <circle cx={1} cy={34} r={1} />
                        <circle cx={121} cy={34} r={1} />
                        <circle cx={21} cy={34} r={1} />
                        <circle cx={121} cy={54} r={1} />
                        <circle cx={21} cy={54} r={1} />
                        <circle cx={307} cy={34} r={1} />
                        <circle cx={307} cy={54} r={1} />
                        <circle cx={267} cy={34} r={1} />
                        <circle cx={287} cy={34} r={1} />
                        <circle cx={287} cy={54} r={1} />
                        <circle cx={197} cy={47} r={1} />
                        <circle cx={97} cy={47} r={1} />
                        <circle cx={197} cy={67} r={1} />
                        <circle cx={97} cy={67} r={1} />
                        <circle cx={197} cy={87} r={1} />
                        <circle cx={97} cy={87} r={1} />
                        <circle cx={197} cy={107} r={1} />
                        <circle cx={197} cy={127} r={1} />
                        <circle cx={217} cy={67} r={1} />
                        <circle cx={217} cy={87} r={1} />
                        <circle cx={117} cy={87} r={1} />
                        <circle cx={217} cy={107} r={1} />
                        <circle cx={117} cy={107} r={1} />
                        <circle cx={237} cy={87} r={1} />
                        <circle cx={257} cy={67} r={1} />
                        <circle cx={257} cy={87} r={1} />
                        <circle cx={257} cy={107} r={1} />
                        <circle cx={277} cy={87} r={1} />
                        <circle cx={157} cy={87} r={1} />
                        <circle cx={177} cy={67} r={1} />
                        <circle cx={77} cy={67} r={1} />
                        <circle cx={177} cy={87} r={1} />
                        <circle cx={177} cy={107} r={1} />
                        <circle cx={77} cy={107} r={1} />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default SvgDotsPredictionIntro;
