import firebase from 'firebase/app';
import { OrderedMap } from 'immutable';

import {
    ConfigPartnerData,
    ConfigPreset,
    PartnerDataLoadSource,
} from './ConfigTypes';
import { getPartnerDataFromHostname } from './DevConfig';
import {
    TranslatableEventModel,
    Iteration,
    MultiEventCategory,
    SingleEventCategory,
    isTranslatablePredictionEvent,
} from './TallyFirestore';
import { IsoDateString } from './SponsorshipTypes';

export type InitialSingleEventData = {
    category: SingleEventCategory;
    iteration: Iteration;
    event: TranslatableEventModel;
    events: OrderedMap<string, TranslatableEventModel>;
};

export type InitialMultiEventData = {
    category: MultiEventCategory;
    iteration: Iteration;
    events: OrderedMap<string, TranslatableEventModel>;
};

export type NoCurentIteration = {
    category: MultiEventCategory;
    iteration: null;
};

export type InitialEventData = InitialMultiEventData | InitialSingleEventData;

export function isSingleEventData(
    initialEventData: InitialEventData,
): initialEventData is InitialSingleEventData {
    return 'event' in initialEventData;
}

type EdgeInitialEventData = {
    category: SingleEventCategory<IsoDateString>;
    iteration: Iteration<IsoDateString>;
    eventPath: string;
    event: TranslatableEventModel<IsoDateString>;
};

export type ConvertedEdgeSingleEventData = {
    category: SingleEventCategory;
    iteration: Iteration;
    event: TranslatableEventModel;
};

declare global {
    interface Window {
        tally?: {
            eventData?: EdgeInitialEventData;
            partner: ConfigPartnerData;
            oktaToken?: string;
            oktaUID?: string;
            geoFenceBlock?: boolean;
        };
    }
}

function firestoreTimestampFromISODateString(
    dateString: IsoDateString,
): firebase.firestore.Timestamp {
    return firebase.firestore.Timestamp.fromDate(new Date(dateString));
}

export function convertInitialEventData(
    eventData: EdgeInitialEventData,
): ConvertedEdgeSingleEventData {
    // The edge api sends down these objects as serialized json, which has all of its dates in string form.
    // This function converts those into timestamps, which is used throughout this app.
    const {
        category: originalCategory,
        iteration: originalIteration,
        event: originalEvent,
    } = eventData;

    const category: SingleEventCategory = {
        ...originalCategory,
        endDate: firestoreTimestampFromISODateString(originalCategory.endDate),
        startDate: firestoreTimestampFromISODateString(
            originalCategory.startDate,
        ),
    };

    let event: TranslatableEventModel;
    if (isTranslatablePredictionEvent(originalEvent)) {
        event = {
            ...originalEvent,
            endDate: originalEvent.endDate
                ? firestoreTimestampFromISODateString(originalEvent.endDate)
                : null,
            startDate: originalEvent.startDate
                ? firestoreTimestampFromISODateString(originalEvent.startDate)
                : null,
            actualStartDate:
                originalEvent.actualStartDate === null
                    ? null
                    : firestoreTimestampFromISODateString(
                          originalEvent.actualStartDate,
                      ),
            lastPublishedDate: firestoreTimestampFromISODateString(
                originalEvent.lastPublishedDate,
            ),
        };
    } else {
        event = {
            ...originalEvent,
            endDate: originalEvent.endDate
                ? firestoreTimestampFromISODateString(originalEvent.endDate)
                : null,
            startDate: originalEvent.startDate
                ? firestoreTimestampFromISODateString(originalEvent.startDate)
                : null,
            lastPublishedDate: firestoreTimestampFromISODateString(
                originalEvent.lastPublishedDate,
            ),
        };
    }

    const iteration: Iteration = {
        ...originalIteration,
        endDate: originalIteration.endDate
            ? firestoreTimestampFromISODateString(originalIteration.endDate)
            : undefined,
        startDate: originalIteration.startDate
            ? firestoreTimestampFromISODateString(originalIteration.startDate)
            : undefined,
    };

    return { category, iteration, event };
}

function loadPartnerData(): {
    data: ConfigPartnerData;
    source: PartnerDataLoadSource;
    oktaToken?: string;
    oktaUID?: string;
    geoFenceBlock?: boolean;
} {
    let data: ConfigPartnerData;
    let source: PartnerDataLoadSource;
    let oktaToken: string | undefined;
    let oktaUID: string | undefined;
    let geoFenceBlock: boolean | undefined;
    if (window.tally) {
        // tslint:disable-next-line:no-console
        console.info('Reading partner from edge');
        // Read partner data from the edge api if available.
        data = window.tally.partner; // this can return legacy style config. See actualizeConfigProps method call
        oktaToken = window.tally.oktaToken;
        oktaUID = window.tally.oktaUID;
        geoFenceBlock = window.tally.geoFenceBlock;
        source = PartnerDataLoadSource.EDGE;
    } else {
        // tslint:disable-next-line:no-console
        // console.info('Reading partner from hostname map');
        // Otherwise use the hostname mapping
        data = getPartnerDataFromHostname();
        source = PartnerDataLoadSource.HOSTNAME_MAPPING;

        const urlParams = new URLSearchParams(window.location.search);
        const oktaTokenData = urlParams.get('oktaToken');
        const oktaUIDData = urlParams.get('oktaUID');
        const geoFenceBlockData = urlParams.get('geoFenceBlock');
        oktaToken = oktaTokenData || undefined;
        oktaUID = oktaUIDData || undefined;
        geoFenceBlock = geoFenceBlockData
            ? geoFenceBlockData === 'true'
            : undefined;
    }
    return { data, source, oktaToken, oktaUID, geoFenceBlock };
}

const {
    data: partnerData,
    source: partnerDataLoadSource,
    oktaToken,
    oktaUID,
    geoFenceBlock,
} = loadPartnerData();

// Disable TrackJS if we are doing local development.
const trackJsDisable =
    partnerDataLoadSource === PartnerDataLoadSource.HOSTNAME_MAPPING;

// tslint:disable-next-line:no-console
// console.info('Using partner', {
//     authenticationMethod: partnerData.authenticationMethod,
//     categoryId: partnerData.categoryId,
//     environment: partnerData.environment,
//     name: partnerData.name,
//     partnerId: partnerData.partnerId,
//     properties: partnerData.properties,
// });

let config: ConfigPreset;
if (partnerData.environment === 'PROD') {
    // tslint:disable-next-line:no-console
    // console.info('Using prod config');
    config = {
        apiBaseUrl: 'https://partner-api.pepsico.pwrbyt.com/app',
        apiFeedUrl: 'https://partner-api.pepsico.pwrbyt.com/authoring',
        adServerUrl: 'https://ad.pepsico.pwrbyt.com',
        environment: 'PROD',
        partnerData,
        supportEmail: 'support@poweredbytally.com',
        trackJs: {
            disable: trackJsDisable,
            key: '651b0e2084324fe8ad7c57cfd9b928e1',
            projectName: 'megaphone-prod',
        },
    };
} else if (partnerData.environment === 'LOCAL') {
    // tslint:disable-next-line:no-console
    // console.info('Using local config');
    config = {
        apiBaseUrl: 'http://localhost:4012',
        apiFeedUrl: 'http://localhost:4013',
        adServerUrl: 'http://localhost:4015',
        environment: 'LOCAL',
        partnerData,
        supportEmail: 'support@poweredbytally.com',
        trackJs: {
            disable: trackJsDisable,
            key: '5a3dccdf2b604c1bb47c844b89851265',
            projectName: 'megaphone-internal',
        },
    };
} else {
    // tslint:disable-next-line:no-console
    // console.info('Using dev config');
    config = {
        apiBaseUrl: 'https://partner-api.playtally.tallydevlab.com/app',
        apiFeedUrl: 'https://partner-api.playtally.tallydevlab.com/authoring',
        adServerUrl: 'https://ad.predictionsgame.tallydevlab.com',
        environment: 'DEV',
        partnerData,
        supportEmail: 'support@poweredbytally.com',
        trackJs: {
            disable: trackJsDisable,
            key: '5a3dccdf2b604c1bb47c844b89851265',
            projectName: 'megaphone-internal',
        },
    };
}

export default {
    ...config,
    partnerData,
    oktaToken,
    oktaUID,
    geoFenceBlock,
};
