import { ColorProperty } from 'csstype';
import { LocaleId } from 'src/util/LocaleHelpers';
import { Timestamp } from 'src/services/Firebase';
import { SocialShare, SocialShareConfig } from './TallyFirestore';

// TODO: implement if needed
// also consider how account page would look like
export const isSso = (authenticationMethod: AuthenticationMethod): boolean =>
    false;
// those were removed after changes for concacaf (mutli event setup):
// authenticationMethod === authenticationMethods.OKTA_UID ||
// authenticationMethod === authenticationMethods.OKTASSO ||
// authenticationMethod === authenticationMethods.GSSO;

export const authenticationMethods = {
    none: '{ANONYMOUS}',
    phone: '{PHONE}',
} as const;

export type AuthenticationMethod =
    (typeof authenticationMethods)[keyof typeof authenticationMethods];

const environments = ['LOCAL', 'DEV', 'PROD'] as const;

export type Environment = (typeof environments)[number];

export type GroupMode = 'USER_GROUP' | 'VENUE' | 'TEAM,USER_GROUP' | 'TEAM';

export interface FirebaseConfig {
    apiKey: string;
    appId?: string;
    authDomain: string;
    databaseURL: string;
    messagingSenderId: string;
    projectId: string;
    storageBucket: string;
}

export interface ConfigLink {
    displayText: string;
    linkUrl: string;
}

export interface PartnerBanner {
    imageUrl: string;
    linkUrl?: string;
}

export enum AdditionalDataFieldsTypeEnum {
    TEXT = 'TEXT',
    DATE = 'DATE',
    SELECT = 'SELECT',
}

export enum DateValidationType {
    AFTER = 'AFTER',
    BEFORE = 'BEFORE',
    BETWEEN = 'BETWEEN',
    OLDER_THAN_Y_O = 'OLDER_THAN_Y_O',
}

export type DateValidationAfter = {
    type: DateValidationType.AFTER;
    dateFrom?: Date;
};
export type DateValidationBefore = {
    type: DateValidationType.BEFORE;
    dateTo?: Date;
};
export type DateValidationBetween = {
    type: DateValidationType.BETWEEN;
    dateFrom?: Date;
    dateTo?: Date;
};
export type DateValidationOlderThan = {
    type: DateValidationType.OLDER_THAN_Y_O;
    olderThanYO?: number;
};
export type DateValidation =
    | DateValidationAfter
    | DateValidationBefore
    | DateValidationBetween
    | DateValidationOlderThan;

export interface IAdditionalDataCollectionField<T> {
    id: string;
    label: T;
    validationErrorText?: T;
    type: AdditionalDataFieldsTypeEnum;
}

export interface AdditionalDataCollectionFieldText<T>
    extends IAdditionalDataCollectionField<T> {
    type: AdditionalDataFieldsTypeEnum.TEXT;
    validationRegexp?: string;
}
export interface AdditionalDataCollectionFieldDate<T>
    extends IAdditionalDataCollectionField<T> {
    type: AdditionalDataFieldsTypeEnum.DATE;
    dateValidation?: DateValidation;
}

export type SelectOption<T> = {
    label: T;
    value: string;
};
export interface AdditionalDataCollectionFieldSelect<T>
    extends IAdditionalDataCollectionField<T> {
    type: AdditionalDataFieldsTypeEnum.SELECT;
    options: SelectOption<T>[];
    defaultValue?: string;
    validationRegexp?: string;
}

export type AdditionalDataCollectionField<T> =
    | AdditionalDataCollectionFieldDate<T>
    | AdditionalDataCollectionFieldText<T>
    | AdditionalDataCollectionFieldSelect<T>;

export type DataCollectionStrings<T> = {
    description: T;
    headline: T;
};

export type DataCollectionArray<T> = {
    fields: Array<AdditionalDataCollectionField<T>>;
};

type DataCollection<T> = DataCollectionStrings<T> & DataCollectionArray<T>;

export type TranslatableDataCollection =
    DataCollection<TranslatableConfigMessage>;

export type TranslatedDataCollection = DataCollection<string>;

export type HomePageContentStrings<T> = {
    header: T;
    subHeader: T;
    bodyMarkdown: T;

    headlineImage: T;
    subHeadLineText: T;
    eventListHeadline: T;
    funEventListHeadline?: T;

    backgroundImgUrl?: string;
    backgroundImgLock?: string;
    backgroundColor?: string;
    eventListHeadlineColor?: string;
    secondaryHeadingTextColor?: string;

    contentTextColor?: string;
};

type HomePageContent<T> = HomePageContentStrings<T>;

export type TranslatableHomePageContent =
    HomePageContent<TranslatableConfigMessage>;

export type TranslatedHomePageContent = HomePageContent<string>;

export type LeaderboardsPageStrings<T> = {
    headlineImage: T;
    backgroundImgUrl?: string;
    backgroundColor?: string;
    secondaryHeadingTextColor?: string;
    contentTextColor?: string;
};

type LeaderboardsPage<T> = LeaderboardsPageStrings<T>;

export type TranslatableLeaderboardsPage =
    LeaderboardsPage<TranslatableConfigMessage>;

export type TranslatedLeaderboardsPage = LeaderboardsPage<string>;

export type DeletedAccountLinkType<T> = {
    active?: boolean;
    label?: T;
};

type PersonalSettingsPageContentStrings<T> = {
    deleteAccountLink?: DeletedAccountLinkType<T>;
};

type PersonalSettingsPageContent<T> = PersonalSettingsPageContentStrings<T>;

export type TranslatablePersonalSettingsPageContent =
    PersonalSettingsPageContent<TranslatableConfigMessage>;

export type TranslatedPersonalSettingsPageContent =
    PersonalSettingsPageContent<string>;

export type PartnerProperties = {
    initialUserConsent?: {
        // specify false if you want analytics to appear switched off initially on the user consent modal
        analytics: boolean;
    };
    appEmbedded?: boolean; // if used in some app's webview (to avoid double bottom nav)

    // THEME
    defaultLinkColor?: string; // default color we use for links
    themeHighlightFallbackColor?: string; // color we use for all buttons, links, etc.
    leaderboardCurrentUserTextColor?: string; // highlight color for the current user in the leaderboard
    leaderboardHorizontalRuleColor?: string; // color of the lines between the LB entries
    leaderboardPrizeTextColor?: string; // color of the prize entries
    logoUrl?: string; // the URL of the main logo (waiting room, etc.)
    logoSizeLoadingScreen?: string; // in px - e.g. 80 (default)
    newPredictionBackgroundColor?: string; // bg color of the new prediction screen (lightning)
    modalBackgroundColor?: string; // bg color of every modal
    navLogoUrl?: string; // used in the nav bar (if a different logo is required)
    navBarTextColor?: string; // the color of the text in the nav bar
    navBarBackgroundColor?: string; // the color of the background of the nav bar
    onboardingFooterImageUrl?: string; // NOT USED
    onboardingToastColor?: string; //color of the header bar during onboarding?
    onboardingToastInvertTextColor?: boolean; // true uses ToastTextColor.BLACK.
    prizeDetailsModalBackgroundImageUrl?: string; // NOT USED?
    seesawLottieColor?: number[]; // [ red, green, blue, alpha ]
    stayTunedTileLoadingDotsColor?: string;
    tournamentHeaderBackgroundColor?: ColorProperty; //bg color behind the tourament title, etc.
    tournamentHeaderGraphicUrl?: string; //bg image behind the tourament title, etc.
    logoSizeWaitingRoom?: string; // in px e.g. 80
    waitingRoomHideSponsor?: boolean; /// hiding the sponsor logo in the waiting room
    waitingRoomToastBackgroundColor?: string;
    waitingRoomToastInvertTextColor?: boolean; // true uses ToastTextColor.BLACK.
    waitingRoomBackgroundColor?: string; // changes the color of the WR background
    waitingRoomCollectEmail?: boolean; // show the email collection field (default: hide)
    logoSizeGeoFence?: string; // in px e.g. 80
    geoFenceHideSponsor?: boolean; /// hiding the sponsor logo in the geofence block screen
    geoFenceBackgroundColor?: string; // changes the color of the WR background
    nextLockUrgencyColor?: string; // the color we use for all urgent messages about locks
    // TALLY BRANDING
    // likely all of these should be set
    liveIconUrl?: string; // the image that might replace the lightning on the 'get notfied' screen
    unbrandNewPredictions?: boolean; // changes the lighting on the new predictions screen to something else
    unbrandUI?: boolean; // removes all Tally branding
    // if we want to use an animated GIF instead - the presence of the URL switches off any lottie animation
    predictionAnimGifUrl?: string; // the URL
    predictionAnimGifSecs?: number; // the time in seconds we want to see the GIF for
    predictionLiveIconUrl?: string; // DEPRECATED - the lightning bolt near the prediction
    predictionLiveIconBackgroundColor?: string; // the color under the lightningbolt bolt near the prediction
    predictionAnsweredIconBackgroundColor?: string; // the color under the lightningbolt bolt near the prediction
    loadingScreenImageUrl?: string; // if you want to use an image instaed of the X screen during loading

    // we can add ONE promotional ad before the blue box

    // URLS

    // SETTINGS
    smsMarketingEnabled?: boolean;
    smsAlertsExternal?: boolean; // if this is true, we will hide the SMS Alert opt in switch and copy on setting page and replace it with copy (default or custom)
    //smsAlertExternalMsg?: string; // loclaized and markdown copy to replace SMS alert switch/language on setting page
    tallyOptInIsActive?: boolean;
    autoDismissPolls?: boolean;
    optionalOptInEnabled?: boolean; // we now have a third checkbox that allows for collectign another consent besides email/sms - e.g. 3p marketing
    optInDefault?: boolean; // opt in or opt out (no impact on SMS opt in - that's done seperately)
    optInSMSDefault?: boolean; // opt in or opt out for SMS field (not advisable)
    disableConsent?: boolean; // here we can switch off the consent management
    disableClientOptInChange?: boolean; // if this is true, all client opt in boxes on marketin settings will be hiden

    collectIterationUpdate?: boolean; // if this is true, every time a user goes to an iteratoon for the first time they will have to opt into new rules
    onboardingDisclaimerHideFooterLinks?: boolean; // if this is true, all links fro the footer will be listed during onbaording at the bottom (Byt continuing you agre to ...)
    emailOptInIsMandatory?: boolean; //  is the email opt in checkbox is mandatory. Is the first checkbox on request email and onboarding  screens
    smsOptInIsMandatory?: boolean; //  is the sms opt in checkbox is mandatory. Is the second checkbox on request email and onboarding  screens
    optionalOptInIsMandatory?: boolean; //  is the additional opt in checkbox is mandatory. Is the third checkbox on request email and onboarding

    // MISC
    lastReleaseMilestone?: string;
    embeddedUtmValues?: string[];

    // AUTH
    legacyAuth?: boolean;

    //I18N
    countriesActive?: string[]; // all countries we want to be available in the country drop down, if none provided - we will show all
    countrySelected?: string; // we can preselect a country code. TODO: manage countries that share a country code (e.g US and Canada) - currently that will only show the first alphabetially
    supportedLanguages?: LocaleId[]; // array of languages that we are supporting

    // Contact Us
    supportEmail?: string; // deprecated. if we aren't using helpscout this allows for configuring a custom recipient for support emails
    hideHelpLink?: boolean; // if this is true, we won't show the contact us link in the footer

    // LEADERBOARD
    showLeaderboardPrizeMarkdown?: boolean; //if false we won't show the prize details button above the leaderboard
    leaderboardFallbackIconUrl?: string; // the fallback image used in the LB overview in case none is configured (there is a Tally logo as final fallback)
    hideLeaderboards?: boolean; //  Hide leaderboards. Hide rank and points from user stats.

    // ACCOUNT
    hideAccountPage?: boolean; //  Hide leaderboards. Hide rank and points from user stats.

    //Opt-in milestone. Users, who opted in before that date will be forced to see optin screen
    optInValidSince?: Timestamp; //firestore.Timestamp as Date object
    emailReOptInIsMandatory?: boolean; //  is the email opt in checkbox is mandatory. Is the first checkbox on request email and re-optIn  screens
    smsReOptInIsMandatory?: boolean; //  is the sms opt in checkbox is mandatory. Is the second checkbox on request email and re-optIn  screens
    optionalReOptInIsMandatory?: boolean; //  is the additional opt in checkbox is mandatory. Is the third checkbox on request email and re-optIn  screens

    headingColor?: string;
};

export type KeysEnum<T> = { [P in keyof Required<T>]?: true };

export type TranslatableConfigMessage = { [key in LocaleId]?: string } | string;

export const isString = (
    translatableConfigMessage: TranslatableConfigMessage,
): translatableConfigMessage is string =>
    typeof translatableConfigMessage === 'string';

export type TranslatableConfigLink =
    | { [key in LocaleId]?: ConfigLink }
    | ConfigLink;

export type TranslatableLeaderboardBanner =
    | { [key in LocaleId]?: PartnerBanner }
    | PartnerBanner;

export type PropertiesMessages<T> = {
    mandate18?: boolean; // do we want to display a 'must be 18' disclaimer? DEPRECATED - will be overwritten by mandateAge
    mandateAge?: number; // if this is set, the age set here will be used as an age disclaimer

    onboardingDisclaimer?: T; // e.g. 'By continuing you confirm that you are 18 years or older and agree to '
    emailMarketingLabel?: T;
    marketingOptInTitle?: T;
    optionalOptInLabel?: T;
    smsMarketingLabel?: T;
    infoModuleStayTunedOptInPreTitle?: T;
    infoModuleStayTunedOptInTitle?: T;
    infoModuleStayTunedOptInBody?: T;
    infoModuleStayTunedPretitle?: T;
    infoModuleStayTunedTitle?: T;
    infoModuleStayTunedBody?: T;
    infoModuleStayTunedPendingPretitle?: T;
    infoModuleStayTunedPendingTitle?: T;
    infoModuleStayTunedPendingBody?: T;
    infoModuleInGameMoreQuestionsPreTitle?: T;
    infoModuleInGameMoreQuestionsTitle?: T;
    infoModuleInGameMoreQuestionsBody?: T;
    onboardingPhoneAuthMessage?: T;
    onboardingPhoneAuthBannerUrl?: T;
    smsAlertCheckboxTitle?: T;
    smsAlertCheckboxSubtitle?: T;
    smsAlertExternalMsg?: T;
    waitingRoomHeadline?: T;
    geoFenceMessageHeadline?: T;
    geoFenceMessageSubHeadline?: T;
    waitingRoomSubHeadline?: T;
    marketingOptInMessageOverride?: T;
    marketingPreferencesHowTo?: T;
    marketingReOptInMessage?: T;
    blueBoxPromoImageUrl?: T;
    blueBoxPromoLink?: T;
    privacyUrl?: T;
    privacyLabel?: T;
    rulesLabel?: T;
    termsLabel?: T;
    rulesUrl?: T;
    surveyUrl?: T;
    termsUrl?: T;
    waitingRoomDetailsUrl?: T;
    gameDisclaimer?: T;
    onboardingRulesCheckboxDescription?: T;
    onboardingRulesCheckboxField?: T;
    onboardingRulesFieldTitle?: T;
    onboardingRulesFieldDescription?: T;
    onboardingRulesFieldPlaceholder?: T;
    onboardingRulesSubmitButtonLabel?: T;
    onboardingRulesTile?: T;
    tallyOptInLabel?: T;
};

export type TranslatablePropertiesMessages =
    PropertiesMessages<TranslatableConfigMessage>;

export type TranslatedPropertiesMessages = PropertiesMessages<string>;

export type ConfigPartnerProperties = PartnerProperties &
    TranslatablePropertiesMessages & {
        addDisclaimer?: Array<TranslatableConfigLink>;
        dataCollection?: TranslatableDataCollection;
        onboardingAgreement?: OnboardingAgreement<TranslatableConfigMessage>;
        leaderboardBanner?: TranslatableLeaderboardBanner;
        // feature props concacaf:
        homePageContent?: TranslatableHomePageContent;
        leaderboardsPage?: TranslatableLeaderboardsPage;
        personalSettingsPageContent?: TranslatablePersonalSettingsPageContent;
    } & { socialShare?: SocialShareConfig };

export type TranslatedConfigPartnerProperties = PartnerProperties &
    TranslatedPropertiesMessages & {
        addDisclaimer?: Array<ConfigLink>;
        dataCollection?: DataCollection<string>;
        homePageContent?: HomePageContent<string>;
        leaderboardsPage?: LeaderboardsPage<string>;
        onboardingAgreement?: OnboardingAgreement<string>;
        leaderboardBanner?: PartnerBanner;
        personalSettingsPageContent?: PersonalSettingsPageContent<string>;
        socialShare?: SocialShare;
    };

export type OnboardingAgreement<T> = {
    text?: T;
    heading?: T;
    agreeButtonLabel?: T;
    disagreeButtonLabel?: T;
    redirectUrl: string;
    forceRecurrentAgreementDisplay?: boolean;
};

export type PartnerData = {
    analyticsKeys: {
        google: string;
    };
    authenticationMethod: AuthenticationMethod;
    categoryId: string;
    collectEmail: boolean;
    collectPhone: boolean;
    environment: Environment;
    facebookAppId: string;
    firebaseConfig: FirebaseConfig;
    name: string;
    partnerId: string;
    primarySmsPhoneNumber?: string;
    secondarySmsPhoneNumber?: string;
    shortName?: string;
    smsAlertsEnabled?: boolean;
};

/**
 * IMPORTANT: When adding new properties to the ConfigPartnerData
 * you must update tally object sanitizer in partner-web-edge-api ( partner-web-edge-api/src/lib/sanitizer.ts )
 * Otherwise the new property will not be injected in the tally object on production.
 *
 */

export type ConfigPartnerData = PartnerData & {
    properties: ConfigPartnerProperties;
};

export type TranslatedConfigPartnerData = PartnerData & {
    properties: TranslatedConfigPartnerProperties;
};

export interface Config {
    apiBaseUrl: string;
    apiFeedUrl: string;
    adServerUrl: string;
    environment: Environment;
    supportEmail: string;
    oktaToken?: string;
    oktaUID?: string;
    trackJs: {
        disable: boolean;
        key: string;
        projectName: string;
    };
}

export interface ConfigPreset extends Config {
    partnerData: ConfigPartnerData;
}

export enum PartnerDataLoadSource {
    EDGE = 'EDGE',
    HOSTNAME_MAPPING = 'HOSTNAME_MAPPING',
}
