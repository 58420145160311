import React from 'react';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { SummarizedLeaderboardProRankEntry } from 'src/util/TallyFirestore';
import LeaderboardRow from './LeaderboardRow';

type Props = {
    proRanks: SummarizedLeaderboardProRankEntry[];
};

const ProsLeaderboard = ({ proRanks }: Props) => {
    return (
        <>
            <Typography variant="h2">
                <FormattedMessage id="prosLeaderboard.header" />
            </Typography>
            {proRanks.map((rank, i) => (
                <LeaderboardRow
                    key={rank.rank}
                    rowNumber={i}
                    rowData={rank}
                    omitCorrectAnswers
                    omitFirstPlayerHighlight
                />
            ))}
        </>
    );
};

export default ProsLeaderboard;
