import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import queryString from 'query-string';
import { EventModel } from 'src/util/TallyFirestore';
import { translateFirestoreEvent } from 'src/util/i18n';
import { AppArea, Screen } from 'src/util/AnalyticsConstants';
import BroadcastLeaderboard from 'src/components/broadcast/BroadcastLeaderboard';
import ServerApi, {
    EventFeedResponse,
    FeedPrediction,
    FeedPredictionAnswerState,
} from 'src/services/ServerApi';
import Logger from 'src/util/Logger';
import { Color, StyleDefaults } from 'src/styles/Constants';
import firebase from 'src/services/Firebase';
import { getEventFromDocumentSnapshot } from 'src/util/FirestoreDao';
import BroadcastSideBoard from 'src/components/broadcast/BroadcastSideboard';
import TallyLoadingFullScreen from 'src/components/shared/animations/TallyLoadingFullScreen';
import { LocaleId } from 'src/util/LocaleHelpers';
import { Analytics } from 'src/contexts/AnalyticsContext';

type Props = {
    logoGraphicUrl?: string;
    path?: string;
    sponsorGraphicUrl?: string;
    localeId: LocaleId;
    analytics: Analytics;
};

interface State {
    loading: boolean;
    eventFeedResponse?: EventFeedResponse;
    event?: EventModel;
}

class BroadcastView extends Component<Props, State> {
    private eventPath?: string;
    constructor(props: Props) {
        super(props);

        this.state = {
            event: undefined,
            eventFeedResponse: undefined,
            loading: true,
        };

        this.eventPath = props.path;
    }

    public componentWillMount = async () => {
        document.title = 'Broadcast View';
        document.body.style.backgroundColor = '#ededed';
    };

    public async componentDidMount() {
        const { localeId, analytics } = this.props;

        if (!this.eventPath) {
            Logger.error(`Broadcast view error: No event path`);
            return;
        }
        const eventRef = firebase.firestore().doc(this.eventPath);
        const event = getEventFromDocumentSnapshot(await eventRef.get());

        document.title = `${event.name} Broadcast`;

        analytics.logScreenView({
            screenName: `${event.shortName} - ${Screen.eventBroadcastView}`,
            screenType: Screen.eventBroadcastView,
            appArea: AppArea.partnerTools,
            gameEvent: event,
        });

        this.refreshEventFeed(event.id);

        this.setState({
            event: translateFirestoreEvent(localeId)(event),
        });
        setInterval(() => {
            this.refreshEventFeed(event.id);
        }, 10000);
    }

    private refreshEventFeed = async (eventId: string) => {
        try {
            const eventFeedResponse = (await ServerApi.getFeed(
                eventId,
                this.getQueryString(),
            )) as EventFeedResponse;

            this.setState({
                eventFeedResponse,
                loading: false,
            });
        } catch (error) {
            Logger.error(`Error refreshing event feed: ${error}`);
        }
    };

    private getLatestPrediction = (): FeedPrediction[] | undefined => {
        const { eventFeedResponse } = this.state;
        if (!eventFeedResponse) {
            return undefined;
        }
        const { predictions } = eventFeedResponse;

        const unlockedPredictions = predictions.filter(
            (prediction) =>
                prediction.state === FeedPredictionAnswerState.UNLOCKED,
        );

        const sortedPredictions = unlockedPredictions.sort(
            (prev, cur): number => {
                const prevDate = new Date(prev.lockDate);
                const currDate = new Date(cur.lockDate);

                if (prevDate === currDate) {
                    return 0;
                } else {
                    return prevDate > currDate ? 1 : -1;
                }
            },
        );

        if (sortedPredictions.length > 0) {
            return sortedPredictions;
        } else {
            return undefined;
        }
    };

    private getQueryString = (): string => {
        const query = window.location.search;
        const parsedQuery = queryString.parse(query);
        return parsedQuery['display-names'] as string;
    };

    private getValidatedPredictionCount = (
        predictions: FeedPrediction[],
    ): number => {
        const answeredPredictions = predictions.filter(
            (prediction) =>
                prediction.state === FeedPredictionAnswerState.ANSWERED,
        );

        return answeredPredictions.length;
    };

    private renderHeader = () => {
        const { eventFeedResponse } = this.state;
        const { logoGraphicUrl } = this.props;
        if (!eventFeedResponse) {
            return;
        }
        const { localeId } = this.context;

        const { name: eventName, playerCount, predictions } = eventFeedResponse;

        const validatedPredictionCount =
            this.getValidatedPredictionCount(predictions);

        const playerCountString = playerCount.toLocaleString(localeId);
        const validatedString =
            validatedPredictionCount === 1
                ? '1 Prediction Validated'
                : `${validatedPredictionCount} Predictions Validated`;

        return (
            <HeaderContainer>
                <LogoTitleContaienr>
                    <LogoContainer rightMargin={'10px'} src={logoGraphicUrl} />
                    <EventNameAndPredictionCount>
                        <EventText>{eventName}</EventText>
                        <PredictionCount>{validatedString}</PredictionCount>
                    </EventNameAndPredictionCount>
                </LogoTitleContaienr>
                <PlayerCount>
                    <FormattedMessage
                        id="broadcastView.playerCount"
                        values={{ count: playerCountString }}
                    />
                </PlayerCount>
            </HeaderContainer>
        );
    };

    private renderSpecialLeaderboard = (
        eventFeedResponse: EventFeedResponse,
        sponsorLogo: string | undefined,
    ) => {
        const { playerCount, predictions, specialUsersLeaderboard } =
            eventFeedResponse;

        const validatedPredictionCount =
            this.getValidatedPredictionCount(predictions);
        return (
            <BroadcastLeaderboard
                isPlayerLeaderboard={false}
                specialBroadcastLeaderboard={specialUsersLeaderboard}
                playerCount={playerCount}
                validatedQuestionCount={validatedPredictionCount}
                sponsorLogo={sponsorLogo}
                event={this.state.event}
            />
        );
    };

    private renderLeaderboard = (
        eventFeedResponse: EventFeedResponse,
        sponsorLogo: string | undefined,
    ) => {
        const { leaderboard, playerCount, predictions } = eventFeedResponse;
        const validatedPredictionCount =
            this.getValidatedPredictionCount(predictions);
        return (
            <BroadcastLeaderboard
                isPlayerLeaderboard={true}
                normalBroadcastLeaderboard={leaderboard}
                playerCount={playerCount}
                validatedQuestionCount={validatedPredictionCount}
                sponsorLogo={sponsorLogo}
                event={this.state.event}
            />
        );
    };

    private renderEmptyState = () => {
        return (
            <EmptyStateText>
                <FormattedMessage id="broadcastView.emptyState" />
            </EmptyStateText>
        );
    };

    public render() {
        const { eventFeedResponse, loading, event } = this.state;
        const sponsorLogo =
            event &&
            event.sponsorship &&
            (event.sponsorship.properties.gameLogoUrl ||
                event.sponsorship.properties.gameWideBanner);

        let { displayOnly } = queryString.parse(location.search);
        displayOnly = displayOnly && displayOnly.toString().toUpperCase();

        let validatedPredictionCount = 0;

        if (eventFeedResponse) {
            const { predictions } = eventFeedResponse;
            validatedPredictionCount =
                this.getValidatedPredictionCount(predictions);
        }

        if (loading) {
            return <TallyLoadingFullScreen />;
        }

        const latestPredictions = this.getLatestPrediction();
        const sideboardTitle = latestPredictions
            ? 'Latest Prediction'
            : 'No Live Predictions';

        return (
            <Container>
                <LeaderboardsContainer>
                    <TopContainer>
                        <WidthContainer>
                            {this.renderHeader()}
                            {eventFeedResponse &&
                                this.renderSpecialLeaderboard(
                                    eventFeedResponse,
                                    sponsorLogo,
                                )}
                        </WidthContainer>
                    </TopContainer>
                    <BottomContainer>
                        <WidthContainer>
                            {eventFeedResponse &&
                                validatedPredictionCount > 0 &&
                                this.renderLeaderboard(
                                    eventFeedResponse,
                                    sponsorLogo,
                                )}
                            {validatedPredictionCount === 0 &&
                                this.renderEmptyState()}
                        </WidthContainer>
                    </BottomContainer>
                </LeaderboardsContainer>
                <VerticalBorder />
                <SideboardContainer>
                    <SideboardTitleContainer>
                        <SideboardTitle
                            style={
                                latestPredictions
                                    ? { opacity: 1 }
                                    : { opacity: 0.2 }
                            }
                        >
                            {sideboardTitle}
                        </SideboardTitle>
                    </SideboardTitleContainer>
                    {latestPredictions &&
                        latestPredictions.map((prediction, index) => (
                            <BroadcastSideBoard
                                key={index}
                                prediction={prediction}
                                displayOnly={
                                    !!displayOnly &&
                                    (displayOnly == 'YES' ||
                                        displayOnly == 'TRUE' ||
                                        displayOnly == '1')
                                }
                            />
                        ))}
                </SideboardContainer>
            </Container>
        );
    }
}

export default BroadcastView;

const VerticalBorder = styled.div`
    background-color: #dedede;
    width: 2px;
`;

const Container = styled.div`
    display: flex;
    font-family: ${StyleDefaults.FONT_FAMILY};
`;

const LeaderboardsContainer = styled.div`
    flex: 2;
    flex-direction: column;
    width: 100%;
`;

const TopContainer = styled.div`
    background-color: ${Color.P1_WHITE};
    display: flex;
    justify-content: center;
    padding-bottom: 25px;
`;
const BottomContainer = styled.div`
    background-color: #ededed;
    display: flex;
    justify-content: center;
    padding-bottom: 25px;
`;

const WidthContainer = styled.div`
    min-width: 800px;
    max-width: 800px;
    overflow-x: auto;
    @media (max-width: 600px) {
        min-width: 0px;
    }
`;

const HeaderContainer = styled.div`
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin: 30px 0px;
    @media (max-width: 600px) {
        flex-direction: column;
        align-items: center;
    }
`;

const LogoContainer = styled.img<{ rightMargin: string }>`
    align-self: center;
    margin: 0 ${(props) => props.rightMargin} 0 0;
    max-height: 50px;
`;

const LogoTitleContaienr = styled.div`
    display: flex;
`;

const PlayerCount = styled.span`
    color: #000000;
    font-size: 30px;
    letter-spacing: -0.5px;
    line-height: 37px;
    text-align: right;
`;

const EventNameAndPredictionCount = styled.div`
    text-align: left;
`;

const EventText = styled.h2`
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1.5px;
    line-height: 18px;
    text-transform: uppercase;
`;

const PredictionCount = styled.p`
    color: #000000;
    font-size: 30px;
    letter-spacing: -0.5px;
    line-height: 37px;
    @media (max-width: 600px) {
        display: none;
    }
`;

const EmptyStateText = styled.h3`
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 150px;
`;

const SideboardContainer = styled.div`
    background-color: #f2f2f2;
    flex: 1;
    height: 100vh;
    overflow-y: scroll;
`;

const SideboardTitleContainer = styled.div`
    align-items: flex-end;
    display: flex;
    height: 55px;
    margin: 30px 0px;
`;

const SideboardTitle = styled.p`
    color: #000000;
    font-size: 30px;
    letter-spacing: -0.5px;
    line-height: 37px;
    margin-left: 33px;
`;
