import { useState, useEffect } from 'react';
import { usePartner } from 'src/hooks/usePartner';
import ServerApi, { IGameStatus } from 'src/services/ServerApi';
import { IGameEventModel } from 'src/util/TallyFirestore';

type IGameState = {
    iGameState: IGameStatus | undefined;
    canPlay: boolean | undefined;
    error: Error | undefined;
};

const useIGameState = (event: IGameEventModel): IGameState => {
    const partner = usePartner();

    const [igamePreviousGameResult, setIgamePreviousGameResult] = useState<
        IGameStatus | undefined
    >(undefined);
    const [canPlay, setCanPlay] = useState<boolean | undefined>(undefined);
    const [error, setError] = useState<Error | undefined>(undefined);

    useEffect(() => {
        ServerApi.getIgameStatus({
            eventId: event.id,
            partnerId: partner.name,
        })
            .then((result) => {
                setIgamePreviousGameResult(result);
                setCanPlay(result.canPlay);
            })
            .catch((e) => {
                setError(e);
            });
    }, []);

    useEffect(() => {
        if (canPlay) {
            try {
                const handler = async (e: MessageEvent<any>) => {
                    if (e.data.eventName == 'GameComplete') {
                        const result = await ServerApi.submitIGameResult({
                            eventId: event.id,
                            points: e.data.score,
                            partnerId: partner.name,
                        });

                        setIgamePreviousGameResult(result);
                        if (canPlay !== result.canPlay) {
                            setCanPlay(result.canPlay);
                        }
                    }
                };
                window.addEventListener('message', handler);
                return () => {
                    window.removeEventListener('message', handler);
                };
            } catch (error) {
                setError(error as Error);
            }
        }
    }, [canPlay]);

    return {
        iGameState: igamePreviousGameResult,
        error: error,
        canPlay: canPlay,
    };
};

export default useIGameState;
