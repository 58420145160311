import { useEffect } from 'react';
import { Timestamp } from 'src/services/Firebase';
import { LocaleId } from 'src/util/LocaleHelpers';
import {
    ConfigLink,
    ConfigPartnerData,
    PartnerProperties,
    TranslatableConfigLink,
    TranslatableConfigMessage,
    TranslatableDataCollection,
    TranslatablePropertiesMessages,
    TranslatedConfigPartnerData,
    TranslatedDataCollection,
    TranslatedHomePageContent,
    AdditionalDataFieldsTypeEnum,
    TranslatableLeaderboardBanner,
    TranslatedPropertiesMessages,
    OnboardingAgreement,
    PartnerBanner,
    TranslatableHomePageContent,
    TranslatableLeaderboardsPage,
    TranslatedLeaderboardsPage,
    TranslatablePersonalSettingsPageContent,
    TranslatedPersonalSettingsPageContent,
} from './ConfigTypes';
import log from './Logger';
import {
    GroupedPredictions,
    mapGroupedPredictions,
    GroupedTranslatablePredictions,
} from './PredictionHelpers';
import {
    PredictionModel,
    TranslatableEventModel,
    Iteration,
    IterationBase,
    IterationGroupModeAndProps,
    EventModel,
    TranslatablePredictionModel,
    SocialShareConfig,
    SocialShare,
} from './TallyFirestore';
import { isLocaleId } from './locale';

export type EntityTranslation<Entity> = Entity & {
    languageCodeId: LocaleId;
};

export type TranslatableEntity<UntranslatableFields, TranslatableFields> =
    UntranslatableFields &
        TranslatableFields & {
            defaultLanguageId?: LocaleId;
            entityTranslations?: EntityTranslation<TranslatableFields>[];
        };

export type TranslatedEntity<UntranslatableFields, TranslatableFields> = Omit<
    TranslatableEntity<UntranslatableFields, TranslatableFields>,
    'defaultLanguageId' | 'entityTranslations'
>;

export type TranslatedIteration = IterationBase<Timestamp> &
    IterationGroupModeAndProps;

const translateTranslatableMessage =
    ({
        localeId,
        defaultLocaleId,
    }: {
        localeId: LocaleId;
        defaultLocaleId: LocaleId;
    }) =>
    (translatableMessage: TranslatableConfigMessage): string | undefined => {
        if (typeof translatableMessage === 'string') {
            return translatableMessage;
        } else {
            const translation = translatableMessage[localeId];
            if (typeof translation === 'string') {
                return translation;
            }
            if (defaultLocaleId === localeId) {
                log.warn(
                    `Couldn't translate ${JSON.stringify(
                        translatableMessage,
                    )} to ${localeId}.`,
                );
                return undefined;
            }

            const defaultTranslation = translatableMessage[defaultLocaleId];

            if (typeof defaultTranslation === `string`) {
                return defaultTranslation;
            }
            log.warn(
                `Couldn't translate ${JSON.stringify(
                    translatableMessage,
                )} to either ${localeId} or ${defaultLocaleId}.`,
            );

            return undefined;
        }
    };

const isConfigLink = (
    translatableConfigLink: TranslatableConfigLink,
): translatableConfigLink is ConfigLink => {
    return (
        translatableConfigLink.hasOwnProperty('displayText') &&
        translatableConfigLink.hasOwnProperty('linkUrl')
    );
};

const isBanner = (
    translatableLeaderboardBanner: TranslatableLeaderboardBanner,
): translatableLeaderboardBanner is PartnerBanner => {
    return translatableLeaderboardBanner.hasOwnProperty('imageUrl');
};

const translateConfigLink =
    ({
        localeId,
        defaultLocaleId,
    }: {
        localeId: LocaleId;
        defaultLocaleId: LocaleId;
    }) =>
    (
        translatableConfigLink: TranslatableConfigLink,
    ): ConfigLink | undefined => {
        if (isConfigLink(translatableConfigLink)) {
            return translatableConfigLink;
        }
        const translation = translatableConfigLink[localeId];
        if (translation && isConfigLink(translation)) {
            return translation;
        }
        const defaultTranslation = translatableConfigLink[defaultLocaleId];

        if (defaultTranslation && isConfigLink(defaultTranslation)) {
            return defaultTranslation;
        }
        const firstTranslation =
            translatableConfigLink[
                (Object.keys(translatableConfigLink) as Array<LocaleId>)[0]
            ];

        if (firstTranslation) {
            return firstTranslation;
        }
        log.warn(
            `Couldn't translate ${JSON.stringify(
                translatableConfigLink,
            )} to either ${localeId} or ${defaultLocaleId}`,
        );
    };

const translateAddDisclaimer =
    ({
        localeId,
        defaultLocaleId,
    }: {
        localeId: LocaleId;
        defaultLocaleId: LocaleId;
    }) =>
    (
        addDisclaimer?: Array<TranslatableConfigLink>,
    ): Array<ConfigLink> | undefined => {
        if (addDisclaimer && addDisclaimer.length > 0) {
            return addDisclaimer
                .map(translateConfigLink({ localeId, defaultLocaleId }))
                .filter(
                    (configLink): configLink is ConfigLink =>
                        configLink !== undefined,
                );
        }
    };

const translateBanner =
    ({
        localeId,
        defaultLocaleId,
    }: {
        localeId: LocaleId;
        defaultLocaleId: LocaleId;
    }) =>
    (
        translatableLeaderboardBanner: TranslatableLeaderboardBanner,
    ): PartnerBanner | undefined => {
        if (isBanner(translatableLeaderboardBanner)) {
            return translatableLeaderboardBanner;
        }
        const translation = translatableLeaderboardBanner[localeId];

        if (translation && isBanner(translation)) {
            return translation;
        }
        const defaultTranslation =
            translatableLeaderboardBanner[defaultLocaleId];

        if (defaultTranslation && isBanner(defaultTranslation)) {
            return defaultTranslation;
        }

        log.warn(
            `Couldn't translate ${JSON.stringify(
                translatableLeaderboardBanner,
            )} to either ${localeId} or ${defaultLocaleId}`,
        );
    };

function callIfDefined<T, U>(
    value: T | undefined,
    fn: (a: T) => U,
): undefined | U {
    return value === undefined ? undefined : fn(value);
}

const translateTranslatablePropertiesMessages =
    ({
        localeId,
        defaultLocaleId,
    }: {
        localeId: LocaleId;
        defaultLocaleId: LocaleId;
    }) =>
    (
        properties: TranslatablePropertiesMessages,
    ): TranslatedPropertiesMessages => {
        const translateMessage = translateTranslatableMessage({
            localeId,
            defaultLocaleId,
        });

        return {
            onboardingDisclaimer: callIfDefined(
                properties.onboardingDisclaimer,
                translateMessage,
            ),
            emailMarketingLabel: callIfDefined(
                properties.emailMarketingLabel,
                translateMessage,
            ),
            marketingOptInTitle: callIfDefined(
                properties.marketingOptInTitle,
                translateMessage,
            ),
            optionalOptInLabel: callIfDefined(
                properties.optionalOptInLabel,
                translateMessage,
            ),
            smsMarketingLabel: callIfDefined(
                properties.smsMarketingLabel,
                translateMessage,
            ),
            infoModuleStayTunedOptInPreTitle: callIfDefined(
                properties.infoModuleStayTunedOptInPreTitle,
                translateMessage,
            ),
            infoModuleStayTunedOptInTitle: callIfDefined(
                properties.infoModuleStayTunedOptInTitle,
                translateMessage,
            ),
            infoModuleStayTunedOptInBody: callIfDefined(
                properties.infoModuleStayTunedOptInBody,
                translateMessage,
            ),
            infoModuleStayTunedPretitle: callIfDefined(
                properties.infoModuleStayTunedPretitle,
                translateMessage,
            ),
            infoModuleStayTunedTitle: callIfDefined(
                properties.infoModuleStayTunedTitle,
                translateMessage,
            ),
            infoModuleStayTunedBody: callIfDefined(
                properties.infoModuleStayTunedBody,
                translateMessage,
            ),
            infoModuleStayTunedPendingPretitle: callIfDefined(
                properties.infoModuleStayTunedPendingPretitle,
                translateMessage,
            ),
            infoModuleStayTunedPendingTitle: callIfDefined(
                properties.infoModuleStayTunedPendingTitle,
                translateMessage,
            ),
            infoModuleStayTunedPendingBody: callIfDefined(
                properties.infoModuleStayTunedPendingBody,
                translateMessage,
            ),
            onboardingPhoneAuthMessage: callIfDefined(
                properties.onboardingPhoneAuthMessage,
                translateMessage,
            ),
            smsAlertCheckboxTitle: callIfDefined(
                properties.smsAlertCheckboxTitle,
                translateMessage,
            ),
            smsAlertCheckboxSubtitle: callIfDefined(
                properties.smsAlertCheckboxSubtitle,
                translateMessage,
            ),
            smsAlertExternalMsg: callIfDefined(
                properties.smsAlertExternalMsg,
                translateMessage,
            ),
            waitingRoomHeadline: callIfDefined(
                properties.waitingRoomHeadline,
                translateMessage,
            ),
            waitingRoomSubHeadline: callIfDefined(
                properties.waitingRoomSubHeadline,
                translateMessage,
            ),
            geoFenceMessageHeadline: callIfDefined(
                properties.geoFenceMessageHeadline,
                translateMessage,
            ),
            geoFenceMessageSubHeadline: callIfDefined(
                properties.geoFenceMessageSubHeadline,
                translateMessage,
            ),
            marketingOptInMessageOverride: callIfDefined(
                properties.marketingOptInMessageOverride,
                translateMessage,
            ),
            marketingPreferencesHowTo: callIfDefined(
                properties.marketingPreferencesHowTo,
                translateMessage,
            ),
            marketingReOptInMessage: callIfDefined(
                properties.marketingReOptInMessage,
                translateMessage,
            ),
            blueBoxPromoImageUrl: callIfDefined(
                properties.blueBoxPromoImageUrl,
                translateMessage,
            ),
            blueBoxPromoLink: callIfDefined(
                properties.blueBoxPromoLink,
                translateMessage,
            ),
            privacyUrl: callIfDefined(properties.privacyUrl, translateMessage),
            privacyLabel: callIfDefined(
                properties.privacyLabel,
                translateMessage,
            ),
            rulesLabel: callIfDefined(properties.rulesLabel, translateMessage),
            termsLabel: callIfDefined(properties.termsLabel, translateMessage),
            rulesUrl: callIfDefined(properties.rulesUrl, translateMessage),
            surveyUrl: callIfDefined(properties.surveyUrl, translateMessage),
            termsUrl: callIfDefined(properties.termsUrl, translateMessage),
            waitingRoomDetailsUrl: callIfDefined(
                properties.waitingRoomDetailsUrl,
                translateMessage,
            ),
            gameDisclaimer: callIfDefined(
                properties.gameDisclaimer,
                translateMessage,
            ),
            onboardingRulesCheckboxDescription: callIfDefined(
                properties.onboardingRulesCheckboxDescription,
                translateMessage,
            ),
            onboardingRulesCheckboxField: callIfDefined(
                properties.onboardingRulesCheckboxField,
                translateMessage,
            ),
            onboardingRulesFieldTitle: callIfDefined(
                properties.onboardingRulesFieldTitle,
                translateMessage,
            ),
            onboardingRulesFieldDescription: callIfDefined(
                properties.onboardingRulesFieldDescription,
                translateMessage,
            ),
            onboardingRulesFieldPlaceholder: callIfDefined(
                properties.onboardingRulesFieldPlaceholder,
                translateMessage,
            ),
            onboardingRulesSubmitButtonLabel: callIfDefined(
                properties.onboardingRulesSubmitButtonLabel,
                translateMessage,
            ),
            onboardingRulesTile: callIfDefined(
                properties.onboardingRulesTile,
                translateMessage,
            ),
            tallyOptInLabel: callIfDefined(
                properties.tallyOptInLabel,
                translateMessage,
            ),
        };
    };

const translateHomePageContent =
    ({
        localeId,
        defaultLocaleId,
    }: {
        localeId: LocaleId;
        defaultLocaleId: LocaleId;
    }) =>
    (
        homePageContent: TranslatableHomePageContent,
    ): TranslatedHomePageContent => {
        const translateMessage = translateTranslatableMessage({
            localeId,
            defaultLocaleId,
        });
        return {
            header: translateMessage(homePageContent.header) || '',
            subHeader: translateMessage(homePageContent.subHeader) || '',
            bodyMarkdown: translateMessage(homePageContent.bodyMarkdown) || '',

            headlineImage:
                translateMessage(homePageContent.headlineImage) || '',
            subHeadLineText:
                translateMessage(homePageContent.subHeadLineText) || '',
            eventListHeadline:
                translateMessage(homePageContent.eventListHeadline) || '',
            funEventListHeadline: homePageContent.funEventListHeadline
                ? translateMessage(homePageContent.funEventListHeadline)
                : undefined,

            backgroundImgUrl: homePageContent.backgroundImgUrl,
            backgroundImgLock: homePageContent.backgroundImgLock,
            backgroundColor: homePageContent.backgroundColor,
            eventListHeadlineColor: homePageContent.eventListHeadlineColor,
            secondaryHeadingTextColor:
                homePageContent.secondaryHeadingTextColor,
            contentTextColor: homePageContent.contentTextColor,
        };
    };

const translateLeaderboardsPage =
    ({
        localeId,
        defaultLocaleId,
    }: {
        localeId: LocaleId;
        defaultLocaleId: LocaleId;
    }) =>
    (
        leaderboardsPage: TranslatableLeaderboardsPage,
    ): TranslatedLeaderboardsPage => {
        const translateMessage = translateTranslatableMessage({
            localeId,
            defaultLocaleId,
        });
        return {
            headlineImage:
                translateMessage(leaderboardsPage.headlineImage) || '',
            backgroundImgUrl: leaderboardsPage.backgroundImgUrl,
            backgroundColor: leaderboardsPage.backgroundColor,
            secondaryHeadingTextColor:
                leaderboardsPage.secondaryHeadingTextColor,
            contentTextColor: leaderboardsPage.contentTextColor,
        };
    };

const translatePersonalSettingsPageContent =
    ({
        localeId,
        defaultLocaleId,
    }: {
        localeId: LocaleId;
        defaultLocaleId: LocaleId;
    }) =>
    (
        personalSettingsPageContent: TranslatablePersonalSettingsPageContent,
    ): TranslatedPersonalSettingsPageContent => {
        const translateMessage = translateTranslatableMessage({
            localeId,
            defaultLocaleId,
        });

        const { deleteAccountLink } = personalSettingsPageContent;
        return {
            deleteAccountLink: {
                ...deleteAccountLink,
                label: translateMessage(
                    (deleteAccountLink?.active && deleteAccountLink?.label) ||
                        '',
                ),
            },
        };
    };

const translateDataCollection =
    ({
        localeId,
        defaultLocaleId,
    }: {
        localeId: LocaleId;
        defaultLocaleId: LocaleId;
    }) =>
    (dataCollection: TranslatableDataCollection): TranslatedDataCollection => {
        const translateMessage = translateTranslatableMessage({
            localeId,
            defaultLocaleId,
        });
        return {
            description: translateMessage(dataCollection.description) || '',
            headline: translateMessage(dataCollection.headline) || '',
            fields: dataCollection.fields.map((field) => ({
                ...field,
                label: translateMessage(field.label) || '',
                validationErrorText: callIfDefined(
                    field.validationErrorText,
                    translateMessage,
                ),
                options:
                    field.type === AdditionalDataFieldsTypeEnum.SELECT &&
                    field.options &&
                    field.options.length
                        ? field.options.map((option) => ({
                              label: translateMessage(option.label) || '',
                              value: option.value,
                          }))
                        : [
                              {
                                  label: 'you missed dropdown options',
                                  value: 'missedOptsInPartnerCfg',
                              },
                          ],
            })),
        };
    };

const translateonboardingAgreement =
    ({
        localeId,
        defaultLocaleId,
    }: {
        localeId: LocaleId;
        defaultLocaleId: LocaleId;
    }) =>
    (
        onboardingAgreement: OnboardingAgreement<TranslatableConfigMessage>,
    ): OnboardingAgreement<string> => {
        const translateMessage = translateTranslatableMessage({
            localeId,
            defaultLocaleId,
        });
        const {
            text,
            heading,
            agreeButtonLabel,
            disagreeButtonLabel,
            redirectUrl,
            forceRecurrentAgreementDisplay,
        } = onboardingAgreement;
        return {
            text: callIfDefined(text, translateMessage),
            heading: callIfDefined(heading, translateMessage),
            agreeButtonLabel: callIfDefined(agreeButtonLabel, translateMessage),
            disagreeButtonLabel: callIfDefined(
                disagreeButtonLabel,
                translateMessage,
            ),
            redirectUrl: redirectUrl,
            forceRecurrentAgreementDisplay,
        };
    };

const translatePartnerSocialShare = (
    partnerSocialShare: SocialShareConfig,
    languageId: LocaleId,
): SocialShare => {
    const {
        title,
        description,
        imageUrl,
        languageInfo: { entityTranslations, defaultLanguageId },
    } = partnerSocialShare;
    const socialShare = { title, description, imageUrl };

    if (defaultLanguageId === languageId || !entityTranslations) {
        return socialShare;
    }

    const translatedSocialShare = entityTranslations.find(
        (entityTranslation) => entityTranslation.languageId === languageId,
    );

    return translatedSocialShare || socialShare;
};

export const getPartnerDefaultLanguage = (
    supportedLanguages?: Array<LocaleId>,
): LocaleId =>
    (supportedLanguages &&
        supportedLanguages.length > 0 &&
        supportedLanguages[0]) ||
    'EN';

export const getLanguageFromURL = (
    searchParam: string | null,
): LocaleId | null => {
    const searchParamUpperCase = searchParam && searchParam.toUpperCase();
    return isLocaleId(searchParamUpperCase) ? searchParamUpperCase : null;
};

export const translatePartnerConfig =
    (localeId: LocaleId) =>
    (config: ConfigPartnerData): TranslatedConfigPartnerData => {
        const properties = config.properties;
        const defaultLocaleId = getPartnerDefaultLanguage(
            properties.supportedLanguages,
        );
        const partnerProperties: PartnerProperties = config.properties;
        return {
            ...config,
            properties: {
                ...partnerProperties,
                ...{
                    addDisclaimer: properties.addDisclaimer
                        ? translateAddDisclaimer({
                              localeId,
                              defaultLocaleId,
                          })(properties.addDisclaimer)
                        : undefined,
                    leaderboardBanner: properties.leaderboardBanner
                        ? translateBanner({
                              localeId,
                              defaultLocaleId,
                          })(properties.leaderboardBanner)
                        : undefined,
                    dataCollection: properties.dataCollection
                        ? translateDataCollection({
                              localeId,
                              defaultLocaleId,
                          })(properties.dataCollection)
                        : undefined,
                    homePageContent: properties.homePageContent
                        ? translateHomePageContent({
                              localeId,
                              defaultLocaleId,
                          })(properties.homePageContent)
                        : undefined,
                    leaderboardsPage: properties.leaderboardsPage
                        ? translateLeaderboardsPage({
                              localeId,
                              defaultLocaleId,
                          })(properties.leaderboardsPage)
                        : undefined,
                    personalSettingsPageContent:
                        properties.personalSettingsPageContent
                            ? translatePersonalSettingsPageContent({
                                  localeId,
                                  defaultLocaleId,
                              })(properties.personalSettingsPageContent)
                            : undefined,
                    onboardingAgreement: properties.onboardingAgreement
                        ? translateonboardingAgreement({
                              localeId,
                              defaultLocaleId,
                          })(properties.onboardingAgreement)
                        : undefined,
                    socialShare: properties.socialShare
                        ? translatePartnerSocialShare(
                              properties.socialShare,
                              localeId,
                          )
                        : undefined,
                },
                ...translateTranslatablePropertiesMessages({
                    localeId,
                    defaultLocaleId,
                })(properties),
            },
        };
    };

export const translateFirestoreIteration =
    (localeId: LocaleId) =>
    (iteration: Iteration): TranslatedIteration => {
        if (
            iteration.defaultLanguageId === localeId ||
            !iteration.entityTranslations
        ) {
            return iteration;
        }

        const translation = iteration.entityTranslations.find(
            (et) => et.languageCodeId === localeId,
        );

        if (translation) {
            const base = {
                ...iteration,
                ...translation,
            };
            switch (iteration.groupMode) {
                case 'TEAM,USER_GROUP':
                    return {
                        ...base,
                        groupMode: iteration.groupMode,
                        groupModeProperties: translation.groupModeProperties
                            ? {
                                  ...iteration.groupModeProperties,
                                  ...translation.groupModeProperties,
                              }
                            : iteration.groupModeProperties,
                    };
                case 'USER_GROUP':
                    return {
                        ...base,
                        groupMode: iteration.groupMode,
                        groupModeProperties: translation.groupModeProperties
                            ? {
                                  ...iteration.groupModeProperties,
                                  ...translation.groupModeProperties,
                              }
                            : iteration.groupModeProperties,
                    };
                case 'TEAM':
                    return {
                        ...base,
                        groupMode: iteration.groupMode,
                        groupModeProperties: translation.groupModeProperties
                            ? {
                                  ...iteration.groupModeProperties,
                                  ...translation.groupModeProperties,
                              }
                            : iteration.groupModeProperties,
                    };
                case 'VENUE':
                    return {
                        ...base,
                        groupMode: iteration.groupMode,
                        groupModeProperties: translation.groupModeProperties
                            ? {
                                  ...iteration.groupModeProperties,
                                  ...translation.groupModeProperties,
                              }
                            : iteration.groupModeProperties,
                    };
                case null:
                    return {
                        ...base,
                        groupMode: iteration.groupMode,
                        groupModeProperties: translation.groupModeProperties
                            ? {
                                  ...iteration.groupModeProperties,
                                  ...translation.groupModeProperties,
                              }
                            : iteration.groupModeProperties,
                    };
            }
        }

        return iteration;
    };

export const translateFirestoreEvent =
    (localeId: LocaleId) =>
    (event: TranslatableEventModel): EventModel => {
        const { entityTranslations, defaultLanguageId, ...rest } = event;
        if (defaultLanguageId === localeId || !entityTranslations) {
            return rest;
        }

        const foundTranslatedFields = entityTranslations.find(
            (et) => et.languageCodeId === localeId,
        );

        if (foundTranslatedFields) {
            switch (rest.type) {
                case 'IGAME':
                    return {
                        ...rest,
                        ...foundTranslatedFields,
                        igame: {
                            ...rest.igame,
                            ...foundTranslatedFields.igame,
                        },
                    };
                case 'PREDICTION':
                case 'TRIVIA':
                    return {
                        ...rest,
                        ...foundTranslatedFields,
                    };
            }
        }

        return rest;
    };

export const translateFirestorePrediction =
    (localeId: LocaleId) =>
    (prediction: TranslatablePredictionModel): PredictionModel => {
        const { entityTranslations, defaultLanguageId, ...rest } = prediction;
        if (defaultLanguageId === localeId || !entityTranslations) {
            return rest;
        }

        const foundTranslatedFields = entityTranslations.find(
            (et) => et.languageCodeId === localeId,
        );
        if (foundTranslatedFields) {
            return {
                ...rest,
                ...foundTranslatedFields,
                // had to trick typescript here
                options: rest.options.map((option, i) => ({
                    ...option,
                    text: foundTranslatedFields.options[i].text,
                })) as any,
            };
        }

        return rest;
    };

export const translateGroupedPredictions =
    (localeId: LocaleId) =>
    (groupedPredictions: GroupedTranslatablePredictions): GroupedPredictions =>
        mapGroupedPredictions(
            groupedPredictions,
            translateFirestorePrediction(localeId),
        );

// used to translate Event and StatsHistory entities
export function translateFirestoreEntity<
    UntranslatableFields,
    TranslatableFields,
>(localeId: LocaleId) {
    return function (
        translatableEntity: TranslatableEntity<
            UntranslatableFields,
            TranslatableFields
        >,
    ): TranslatedEntity<UntranslatableFields, TranslatableFields> {
        const { entityTranslations, defaultLanguageId, ...rest } =
            translatableEntity;

        if (defaultLanguageId === localeId || !entityTranslations) {
            return rest;
        }

        const foundTranslatedFields = entityTranslations.find(
            (et) => et.languageCodeId === localeId,
        );

        if (foundTranslatedFields) {
            return {
                ...rest,
                ...foundTranslatedFields,
            };
        }

        return rest;
    };
}

export function translateArrayOfEntities<
    UntranslatableFields,
    TranslatableFields,
>(
    localeId: LocaleId,
    translatableEntities: Array<
        TranslatableEntity<UntranslatableFields, TranslatableFields>
    >,
): Array<TranslatedEntity<UntranslatableFields, TranslatableFields>> {
    return translatableEntities.map(translateFirestoreEntity(localeId));
}

export const useTranslatedHtmlTitle = ({
    eventSocialShare,
    iterationSocialShare,
    partnerSocialShare,
}: {
    eventSocialShare?: SocialShare;
    iterationSocialShare?: SocialShare;
    partnerSocialShare?: SocialShare;
}) => {
    useEffect(() => {
        if (eventSocialShare) {
            document.title = eventSocialShare.title;
        } else if (iterationSocialShare) {
            document.title = iterationSocialShare.title;
        } else if (partnerSocialShare) {
            document.title = partnerSocialShare.title;
        }
    }, [eventSocialShare, iterationSocialShare, partnerSocialShare]);
};
