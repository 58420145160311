import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { OmitNative } from 'react-router';

import routeUrls from 'src/routes';
import { useShouldShowOnboarding } from 'src/hooks/useOnboardingTile';

type Props<T extends {} = {}, Path extends string = string> = RouteProps<Path> &
    OmitNative<T, keyof RouteProps>;

function PrivateRoute({ children, ...rest }: Props): JSX.Element {
    const shouldShowOnboarding = useShouldShowOnboarding();
    if (typeof children === 'function') {
        return (
            <Route
                {...rest}
                children={(childenProps) => {
                    return shouldShowOnboarding &&
                        childenProps.location.pathname !==
                            routeUrls.onboarding ? (
                        <Redirect
                            to={{
                                ...childenProps.location,
                                pathname: routeUrls.onboarding,
                                state: { from: childenProps.location },
                            }}
                        />
                    ) : (
                        children(childenProps)
                    );
                }}
            />
        );
    }

    return (
        <Route
            {...rest}
            render={(renderProps) => {
                return shouldShowOnboarding &&
                    renderProps.location.pathname !== routeUrls.onboarding ? (
                    <Redirect
                        to={{
                            ...renderProps.location,
                            pathname: routeUrls.onboarding,
                            state: { from: renderProps.location },
                        }}
                    />
                ) : typeof children === 'function' ? (
                    children(renderProps)
                ) : (
                    children
                );
            }}
        />
    );
}

export default PrivateRoute;
