import styled from '@emotion/styled';
import { Color } from 'src/styles/Constants';
import { AspectRatio } from 'src/util/Iframe';

export const GradientOverlay = styled.div`
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 60px;
    background: linear-gradient(rgba(255, 255, 255, 0), ${Color.P1_WHITE} 80%);
`;

export const AdHeadline = styled.h3`
    padding: 5px 0;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 21px;
`;

export const Disclaimer = styled.p`
    padding: 5px 0;
    font-size: 12px;
    letter-spacing: 0.2px;
    line-height: 18px;
`;

export const AdContainer = styled.div<{
    centerItems?: boolean;
    textAlign?: string;
}>`
    display: flex;
    flex-direction: column;
    align-items: ${(props) => (props.centerItems ? 'center' : 'stretch ')};
    text-align: ${(props) => (props.textAlign ? props.textAlign : 'inherit')};
`;

export const AspectRatioContainer = styled.div<{
    aspectRatio: AspectRatio | null;
    borderSizePx?: number;
}>`
    padding-top: ${(props) =>
        props.aspectRatio
            ? 'calc(' +
              props.aspectRatio.height +
              '/' +
              props.aspectRatio.width +
              ' * 100%);'
            : '100%;'}

    position: relative;
    overflow: hidden;

    & iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-style: solid;
        border-width: ${(props) => props.borderSizePx || 0}px;
    }
`;
