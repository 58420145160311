declare global {
    interface Window {
        webkitRequestFileSystem: any;
        openDatabase: any;
        PointerEvent: any;
        MSPointerEvent: any;
    }
}

export function getSafariVersion() {
    const matchedUserAgent = navigator.userAgent.match(
        /Version\/([0-9\._]+).*Safari/,
    );

    if (matchedUserAgent) {
        return parseInt(matchedUserAgent[1], 10);
    }

    return null;
}
