import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from '@material-ui/core';

import { Color, TOOLTIP_VISIBLE_DURATION } from 'src/styles/Constants';
import Theme from 'src/util/Theme';
import TouchButton from '../../controls/TouchButton';

type Props = {
    buttonText: string;
    title?: string;
    text?: string;
    url: string;
    onCopyToClipboardFailed?: (error: unknown) => void;
};

const ShareLinkButton = ({
    title,
    text,
    buttonText,
    url,
    onCopyToClipboardFailed,
}: Props) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);

    const handleCloseTooltip = () => {
        setIsTooltipOpen(false);
    };

    const handleOpenTooltip = () => {
        setIsTooltipOpen(true);
    };

    const handleCopyLinkClick = () => {
        const handleCopyToClipboard = () => {
            navigator.clipboard
                .writeText(url)
                .then(() => {
                    handleOpenTooltip();
                    setTimeout(handleCloseTooltip, TOOLTIP_VISIBLE_DURATION);
                })
                .catch((error) => {
                    console.error(error);
                    if (onCopyToClipboardFailed) {
                        onCopyToClipboardFailed(error);
                    }
                });
        };

        if (!!navigator.share) {
            navigator.share({ text: `${text} - ${url}` }).catch((reason) => {
                console.error(reason);
                if (reason instanceof DOMException) {
                    // if a user cancelled no need to copy to clipboard
                    if (reason.name === 'AbortError') {
                        return;
                    }
                }
                handleCopyToClipboard();
            });
        } else {
            handleCopyToClipboard();
        }
    };

    return (
        <Tooltip
            open={isTooltipOpen}
            placement="bottom"
            title={
                <FormattedMessage id="copyToClipboardButton.tooltip.copied" />
            }
        >
            <div>
                <TouchButton
                    backgroundColor={Theme.themeHighlightFallbackColor}
                    textColor={Color.P1_WHITE}
                    displayText={buttonText}
                    onClick={handleCopyLinkClick}
                    disabled={isTooltipOpen}
                    stretchWidth={false}
                    secondaryStyle={true}
                />
            </div>
        </Tooltip>
    );
};

export default ShareLinkButton;
