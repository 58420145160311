import styled from '@emotion/styled';
import React from 'react';
import { ClipLoader } from 'react-spinners';

const LoadingTile = () => {
    return (
        <SpinnerContainer>
            <ClipLoader size={20} loading={true} />
        </SpinnerContainer>
    );
};

export default LoadingTile;

const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 30px 0 50px 0;
    flex-direction: row;
`;
