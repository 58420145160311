import styled from '@emotion/styled';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Link from 'src/components/shared/ui/Link';
import tallyLogo from 'src/images/icons/Tally_Logo_Horizontal_Black_200_t30charcoal.png';
import { Color, TextStyles, TextStyleTypes } from 'src/styles/Constants';
import Theme from 'src/util/Theme';

import { useConsentModal } from 'src/hooks/useUserStorageConsent';
import { usePartner } from 'src/hooks/usePartner';

interface Props {
    onContactSupportClick: () => void;
}

export default function GameFooter({ onContactSupportClick }: Props) {
    const partner = usePartner();

    const {
        termsLabel,
        termsUrl,
        rulesLabel,
        rulesUrl,
        privacyLabel,
        privacyUrl,
        hideHelpLink,
        unbrandUI,
    } = partner.properties;

    const { setIsOpen } = useConsentModal();
    const showConsentModal = () => {
        setIsOpen(true);
    };
    const flexDirection =
        partner.properties.addDisclaimer &&
        partner.properties.addDisclaimer.length > 1
            ? 'column'
            : 'row';

    return (
        <PageFooter>
            <PageFooterContent>
                {!hideHelpLink && (
                    <ButtonLink onClick={onContactSupportClick}>
                        <FormattedMessage id="gameFooter.contactSupport" />
                    </ButtonLink>
                )}
                <PolicyLinksContainer flexDir={flexDirection}>
                    {termsUrl && (
                        <PolicyLink
                            displayText={
                                termsLabel || (
                                    <FormattedMessage id="onboarding.termsAndConditions" />
                                )
                            }
                            url={termsUrl}
                        />
                    )}
                    {rulesUrl && (
                        <PolicyLink
                            displayText={
                                rulesLabel || (
                                    <FormattedMessage id="onboarding.officialRules" />
                                )
                            }
                            url={rulesUrl}
                        />
                    )}
                    {privacyUrl && (
                        <PolicyLink
                            displayText={
                                privacyLabel || (
                                    <FormattedMessage id="onboarding.privacyPolicy" />
                                )
                            }
                            url={privacyUrl}
                        />
                    )}
                    {partner.properties.addDisclaimer &&
                        partner.properties.addDisclaimer.map(
                            ({ displayText, linkUrl }, index) => {
                                return (
                                    <PolicyLink
                                        key={index}
                                        displayText={displayText}
                                        url={linkUrl}
                                    />
                                );
                            },
                        )}

                    {!Theme.disableConsent && (
                        <PolicyLink
                            url=""
                            onClick={showConsentModal}
                            displayText={
                                <FormattedMessage id="onboarding.cookies" />
                            }
                        />
                    )}
                </PolicyLinksContainer>
                {!unbrandUI && (
                    <PoweredByTallyContainer
                        href="https://www.playtally.com"
                        target="_blank"
                    >
                        <PoweredByText>
                            <FormattedMessage id="gameFooter.poweredBy" />
                        </PoweredByText>
                        <img src={tallyLogo} height="22px" />
                    </PoweredByTallyContainer>
                )}
            </PageFooterContent>
        </PageFooter>
    );
}

const HORIZONTAL_PADDING = '30px';
const VERTICAL_PADDING = '24px';

const PolicyLinksContainer = styled.div<{ flexDir?: string }>`
    border-top: 1px solid #252525;
    display: flex;
    flex-direction: ${(props) => props.flexDir || 'row'};
    margin-top: 26px;
    width: 100%;
`;

const PolicyLink = styled(Link)`
    ${TextStyles[TextStyleTypes.C3]}
    color: ${Color.T30_CHARCOAL};
    margin: 5px 0;
    underline: false;
    padding-right: 17px;
    padding-top: 4px;
    text-decoration: none;
`;

const PageFooterContent = styled.div`
    border-top: 1px solid #e5e5e5;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${VERTICAL_PADDING} ${HORIZONTAL_PADDING};
    position: relative;
`;

const PageFooter = styled.div`
    margin-top: auto;
    background-color: ${Color.P1_WHITE};
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const PoweredByTallyContainer = styled.a`
    &:link {
        text-decoration: none;
    }
    &:visited {
        text-decoration: none;
    }
    &:hover {
        text-decoration: none;
    }
    &:active {
        text-decoration: none;
    }
    margin: 5px 0;
    position: absolute;
    right: ${HORIZONTAL_PADDING};
    top: ${VERTICAL_PADDING};
`;

const PoweredByText = styled.div`
    text-align: left;
    color: ${Color.T30_CHARCOAL};
    ${TextStyles[TextStyleTypes.C3]}
`;

const ButtonLink = styled.a`
    color: ${Color.T30_CHARCOAL};
    cursor: pointer;
    margin: 5px 0;
    underline: false;
    font-size: 12px;
    line-height: 16px;
    text-decoration: none;
`;
