import React, { useEffect, useState } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { Color, TextStyles, TextStyleTypes } from 'src/styles/Constants';
import { usePartner } from 'src/hooks/usePartner';
import Box from '@material-ui/core/Box';
import { useGroupProvider } from 'src/contexts/GroupProvider';
import ServerApi from 'src/services/ServerApi';
import { TeamGroup } from 'src/types';
import TouchButton from 'src/components/shared/controls/TouchButton';
import LoadingTile from './LoadingTIle';
import { NetworkError } from 'src/util/errors/NetworkError';

type Props = {
    iterationId: string;
    // TODO: use onContactSupportClick
    onContactSupportClick: () => void;
};

const teamCardsContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: '15px 0 15px 0',
};

const teamCardStyle = {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '15px',
    alignContent: 'space-around',
    alignItems: 'stretch',
    maxWidth: '120px',
    minWidth: '120px',
    alignSelf: 'flex-end',
};

const teamImageStyle = {
    height: 'auto',
    width: 'auto',
    maxWidth: '120px',
    maxHeight: '80px',
};

const translateError = (intl: IntlShape, error: Error): string => {
    if (error instanceof NetworkError) {
        return intl.formatMessage({ id: 'error.failedToFetch' });
    }

    return error.message;
};

const ErrorMessage = ({
    error,
    intl,
}: {
    error: null | Error;
    intl: IntlShape;
}) => (error ? <ErrorStyled>{translateError(intl, error)}</ErrorStyled> : null);

const ErrorStyled = styled.div`
    font-size: 14px;
    color: red;
    margin-bottom: 8px;
`;

const TeamsTile = ({
    iterationId,
    onContactSupportClick,
}: Props): JSX.Element => {
    const intl = useIntl();
    const partner = usePartner();
    const {
        joinGroup: { joinGroup, error: joinGroupError },
        isLoading,
        getUserGroups: { error: getUserGroupsError },
    } = useGroupProvider();

    const [isInitializing, setIsInitializing] = useState<boolean>(true);
    const [teamsList, setTeamsList] = useState<TeamGroup[]>([]);
    const [groupToJoin, setGroupToJoin] = useState<string | undefined>(
        undefined,
    );

    const { partnerId } = partner;

    const onJoinTeam = async (groupId: string) => {
        setGroupToJoin(groupId);
        joinGroup(partnerId, groupId);
    };

    useEffect(() => {
        if (!isLoading) {
            setIsInitializing(true);
            ServerApi.getTeams(iterationId)
                .then((teamsList) => {
                    setTeamsList(teamsList);
                    setIsInitializing(false);
                })
                // TODO: should handle errors
                .catch((e) => setIsInitializing(false));
        }
    }, [isLoading]);

    const error = getUserGroupsError || joinGroupError;

    return isInitializing ? (
        <LoadingTile />
    ) : (
        <Box>
            <TileTitle color={Color.T31_BLACK}>
                <FormattedMessage id="infoCollectionTitle.groupAndTeamsModeTileTitle" />
            </TileTitle>
            <ErrorMessage intl={intl} error={error} />
            <Box sx={teamCardsContainerStyle}>
                {teamsList
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((team) => (
                        <Box key={team.id} sx={teamCardStyle}>
                            <Box maxHeight={'80px'} maxWidth={'120px'}>
                                <img
                                    src={team.imageUrl}
                                    style={teamImageStyle}
                                />
                            </Box>
                            {team.name}
                            <TouchButton
                                displayText={
                                    <FormattedMessage id="infoCollectionTitle.touchButtonJoinTeam" />
                                }
                                backgroundColor={Color.T31_BLACK}
                                textColor={Color.P1_WHITE}
                                onClick={() => onJoinTeam(team.id)}
                                secondaryStyle={true}
                                disabled={isLoading}
                                loading={isLoading && groupToJoin === team.id}
                            />
                        </Box>
                    ))}
            </Box>
        </Box>
    );
};

export default TeamsTile;

const TileTitle = styled.div`
    ${TextStyles[TextStyleTypes.H3]};
    border-bottom: 2px solid ${Color.G8_CONCRETE};
    text-align: left;
    width: 100%;
`;
