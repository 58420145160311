import React from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import styled from '@emotion/styled';
import theme from 'src/util/Theme';

function LinkRenderer(props: any) {
    // Ensures links are opened in a new tab.
    return (
        <a href={props.href} target="_blank">
            {props.children}
        </a>
    );
}

function BlockQuoteRenderer(props: any) {
    // Hijacking BlockQuote markdown. e.g: `This will look like disclaimer text, not BlockQuote`
    // Allows us to use a smaller font for disclaimers, etc.
    return <SmallMarkdownText>{props.children}</SmallMarkdownText>;
}

function ImageRenderer(props: any) {
    return <ResizedImage src={props.src} />;
}

interface Props {
    readonly paragraphPaddingBottom?: string;
    readonly linkColor?: string;
    readonly textColor?: string;
    readonly source: string;
}

/**
 * Adds markdown functionality that would be desired by all markdown users.
 *
 * @param props
 */
export default function TallyMarkdown(props: Props) {
    const { linkColor, paragraphPaddingBottom, source, textColor } = props;
    return (
        <MarkdownText
            linkColor={linkColor}
            paragraphPaddingBottom={paragraphPaddingBottom}
            textColor={textColor}
        >
            <ReactMarkdown
                renderers={{
                    blockquote: BlockQuoteRenderer,
                    image: ImageRenderer,
                    link: LinkRenderer,
                }}
                plugins={[gfm]}
            >
                {source}
            </ReactMarkdown>
        </MarkdownText>
    );
}

const MarkdownText = styled.div<{
    linkColor?: string;
    paragraphPaddingBottom?: string;
    textColor?: string;
}>`
    a {
        color: ${(props) => props.linkColor || theme.defaultLinkColor};
    }
    li {
        &:before {
            content: '▰';
            padding-right: 8px;
        }
        list-style-type: none;
    }
    p {
        padding-bottom: ${(props) => props.paragraphPaddingBottom || '10px'};
    }
    ul {
        padding: 0;
        margin: 0;
    }
    ${(props) => (props.textColor ? `color: ${props.textColor}` : '')};
`;

const SmallMarkdownText = styled.div`
    display: block;
    font-size: 10px;
    font-style: italic;
    line-height: 12px;
`;

const ResizedImage = styled.img`
    max-width: 100%;
    height: auto;
`;
