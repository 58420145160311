import { Component } from 'react';
import {
    PredictionLockType,
    UserAnswerSet,
    TranslatablePredictionModel,
} from 'src/util/TallyFirestore';
import { getPredictionState, PredictionsByState } from 'src/util/EventHelpers';
import {
    GroupedTranslatablePredictions,
    PredictionBatch,
} from 'src/util/PredictionHelpers';
import { Timestamp } from 'src/services/Firebase';
import config from 'src/util/Config';

interface Props {
    eventMilestones?: string[];
    predictionsUpdatedTimestamp: Date;
    predictions: TranslatablePredictionModel[];
    answers: UserAnswerSet;
    onPredictionsGrouped: (
        groupedPredictions: GroupedTranslatablePredictions,
    ) => void;
    onLastBatchReleased?: () => void;
}

type PredictionModelKey = keyof Pick<
    TranslatablePredictionModel,
    'lockDescription' | 'lockDate' | 'answerMilestone'
>;

export default class PistachioGrouper extends Component<Props> {
    public componentDidUpdate() {
        this.groupPredictions();
    }

    public shouldComponentUpdate(nextProps: Props) {
        const { predictionsUpdatedTimestamp: currentTimestamp } = this.props;
        const { predictionsUpdatedTimestamp: newTimestamp } = nextProps;
        return newTimestamp.getTime() !== currentTimestamp.getTime();
    }

    public forceRegroup = () => {
        this.groupPredictions();
    };

    private groupPredictions = () => {
        const {
            predictions,
            answers,
            onPredictionsGrouped,
            onLastBatchReleased,
        } = this.props;

        const predictionsByState: PredictionsByState = {
            live: [],
            pending: [],
            final: [],
        };
        const now = new Date();
        const partnerData = config.partnerData;

        predictions.forEach((prediction) => {
            if (
                partnerData &&
                prediction.releaseMilestone ===
                    partnerData.properties.lastReleaseMilestone &&
                onLastBatchReleased
            ) {
                onLastBatchReleased();
            }

            const isAnsweredPoll =
                config.partnerData.properties.autoDismissPolls &&
                prediction.type === 'POLL' &&
                answers &&
                answers[prediction.id];

            if (isAnsweredPoll) {
                predictionsByState['final'].push(prediction);
                return;
            }

            const predictionState = getPredictionState(prediction, now);

            predictionsByState[predictionState].push(prediction);
        });

        const manualPredictions = [];
        const automaticPredictions = [];
        for (const livePrediction of predictionsByState['live']) {
            if (livePrediction.lockType === PredictionLockType.autoamtic) {
                automaticPredictions.push(livePrediction);
            } else {
                manualPredictions.push(livePrediction);
            }
        }

        // sort automatic by lockdate.
        // sort predictions with same lockdate by question number
        automaticPredictions.sort(
            (
                a: TranslatablePredictionModel,
                b: TranslatablePredictionModel,
            ) => {
                const lockDateDif = a.lockDate!.seconds - b.lockDate!.seconds;
                if (lockDateDif !== 0) {
                    return lockDateDif;
                }
                return a.number - b.number;
            },
        );

        // Group automatic predictions into subarrays based on lockDate
        const groupedLiveAutoPredictions =
            PistachioGrouper.sortPredictionsIntoBatchedGroups<Timestamp>(
                automaticPredictions,
                'lockDate',
                PistachioGrouper.compareByLockDate,
            );

        // sort manual by question number.
        manualPredictions.sort(
            (a: TranslatablePredictionModel, b: TranslatablePredictionModel) =>
                a.number - b.number,
        );
        // Group Manual predictions into subarrays based on lockDescription
        const groupedLiveManualPredictions =
            PistachioGrouper.sortPredictionsIntoBatchedGroups<string>(
                manualPredictions,
                'lockDescription',
                PistachioGrouper.compareByNumber,
            );

        const pendingPredictionsGroup: PredictionBatch<'Pending'> = {
            group: 'Pending',
            entities: predictionsByState['pending'].sort(
                PistachioGrouper.compareByNumber,
            ),
        };

        const groupedPastPredictions =
            PistachioGrouper.sortPredictionsIntoBatchedGroups<string>(
                predictionsByState['final'].sort(
                    PistachioGrouper.compareByAnswerMilestone(
                        this.props.eventMilestones,
                    ),
                ),
                'answerMilestone',
                PistachioGrouper.compareByAnswerMilestone(
                    this.props.eventMilestones,
                ),
            );

        const groupedLivePredictions = {
            automatic: groupedLiveAutoPredictions,
            manual: groupedLiveManualPredictions,
        };

        const groupedPredictions: GroupedTranslatablePredictions = {
            final: groupedPastPredictions,
            pending: pendingPredictionsGroup,
            live: groupedLivePredictions,
        };

        onPredictionsGrouped(groupedPredictions);
    };

    private static compareByAnswerMilestone =
        (eventMilestones?: string[]) =>
        (
            a: TranslatablePredictionModel,
            b: TranslatablePredictionModel,
        ): number => {
            if (eventMilestones) {
                const milestoneIndexDif =
                    eventMilestones.indexOf(a.answerMilestone) -
                    eventMilestones.indexOf(b.answerMilestone);
                if (milestoneIndexDif !== 0) {
                    return milestoneIndexDif;
                }
                return PistachioGrouper.compareByNumber(a, b);
            } else {
                return PistachioGrouper.compareByNumber(a, b);
            }
        };

    private static compareByLockDate = (
        a: TranslatablePredictionModel,
        b: TranslatablePredictionModel,
    ): number => {
        const lockDateDif = b.lockDate.seconds - a.lockDate.seconds;
        if (lockDateDif !== 0) {
            return lockDateDif;
        }
        return a.number - b.number;
    };

    private static compareByNumber = (
        a: TranslatablePredictionModel,
        b: TranslatablePredictionModel,
    ): number => {
        return a.number - b.number;
    };

    // Takes array of predictions already sorted by their key
    // Groups are sorted in order that predictions come in
    private static sortPredictionsIntoBatchedGroups<TGroup>(
        predictions: TranslatablePredictionModel[],
        key: PredictionModelKey,
        compareFunction: (
            a: TranslatablePredictionModel,
            b: TranslatablePredictionModel,
        ) => number,
    ): Array<PredictionBatch<TGroup>> {
        const values = [];
        const results: TranslatablePredictionModel[][] = [];

        for (const prediction of predictions) {
            const val =
                key === 'lockDate' ? prediction[key].seconds : prediction[key];
            const index = values.indexOf(val);
            if (index > -1) {
                results[index].push(prediction);
            } else {
                values.push(val);
                results.push([prediction]);
            }
        }

        const sortedGroups: Array<PredictionBatch<TGroup>> = [];
        for (const result of results) {
            result.sort(compareFunction);
            // `as any as TGroup` is horrible. Polls can have no answerMilestone so the groupName isn't of type TGroup
            const groupName = (result[0][key] || '') as any as TGroup;
            const sortedBatch = { group: groupName, entities: result };
            sortedGroups.push(sortedBatch);
        }

        return sortedGroups;
    }

    public render() {
        return null;
    }
}
