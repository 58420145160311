import { useRef, useEffect } from 'react';

const useVisibilityChanged = (
    ref: React.RefObject<HTMLElement>,
    callback: (visible: boolean) => void,
) => {
    const callBackRef = useRef(callback);
    callBackRef.current = callback;

    useEffect(() => {
        if (ref.current) {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    if (entry.intersectionRatio >= 1) {
                        callBackRef.current(true);
                    } else {
                        callBackRef.current(false);
                    }
                },
                {
                    root: null,
                    rootMargin: '0px',
                    threshold: 1,
                },
            );
            observer.observe(ref.current);
            return () => {
                observer.disconnect();
            };
        }
    }, [callBackRef, ref]);
};

export default useVisibilityChanged;
