import firebase from 'firebase/app';
import { IntlFormatters } from 'react-intl';
import { GroupsState } from 'src/contexts/GroupProvider';
import { getContactInfo, UserAuthData } from 'src/contexts/UserStateContext';
import { ServerErrorType } from 'src/services/ServerError';
import {
    authenticationMethods,
    AuthenticationMethod,
    TranslatedConfigPartnerData,
    isSso,
} from 'src/util/ConfigTypes';
import { TranslatedIteration } from 'src/util/i18n';
import { TranslationMessageId } from 'src/util/LocaleHelpers';

export const onboardingTileTypes = {
    loading: 'spinner',
    authPhone: 'authPhone',
    initialize: 'initialize',
    collectEmail: 'collectEmail',
    reOptIn: 'reOptIn',
    collectPhone: 'collectPhone',
    agreementRequired: 'agreementRequired',
    joinVenue: `joinVenue`,
    joinTeam: 'joinTeam',
} as const;

export type OnboardingTileType =
    (typeof onboardingTileTypes)[keyof typeof onboardingTileTypes];

const authenticationMethodToTile: Record<
    AuthenticationMethod,
    OnboardingTileType
> = {
    [authenticationMethods.none]: 'initialize',
    [authenticationMethods.phone]: 'authPhone',
};

export const determineTile = ({
    partner,
    userData,
    userAgreed,
    iteration,
    groupsState: { groups, isLoading: isGroupsLoading },
}: {
    partner: TranslatedConfigPartnerData;
    userData: UserAuthData;
    userAgreed: boolean;
    iteration: TranslatedIteration;
    groupsState: { groups: GroupsState; isLoading: boolean };
}): OnboardingTileType | null => {
    const { authenticationMethod } = partner;
    // agreement goes first
    if (
        !isSso(partner.authenticationMethod) &&
        partner.properties.onboardingAgreement &&
        !userAgreed
    ) {
        return onboardingTileTypes.agreementRequired;
    }
    // authentication
    if (userData.type === 'annonymous') {
        return authenticationMethodToTile[authenticationMethod];
    }
    if (userData.type === 'authenticated') {
        return onboardingTileTypes.initialize;
    }

    // marketing
    if (
        partner.collectEmail &&
        (!userData.contactInfo || !userData.contactInfo.email)
    ) {
        return onboardingTileTypes.collectEmail;
    }

    const { optInValidSince } = partner.properties;
    if (
        optInValidSince &&
        firebase.firestore.Timestamp.now() >= optInValidSince &&
        (!userData.contactInfo ||
            !userData.contactInfo.lastOptInDate ||
            userData.contactInfo.lastOptInDate < optInValidSince)
    ) {
        return onboardingTileTypes.reOptIn;
    }

    if (
        partner.collectPhone &&
        (!userData.contactInfo || !userData.contactInfo.phoneNumber)
    ) {
        return onboardingTileTypes.collectPhone;
    }
    // joining mandatory group

    const { groupMode } = iteration;

    if (isGroupsLoading) {
        return onboardingTileTypes.loading;
    }

    if (
        (groupMode === 'TEAM' || groupMode === 'TEAM,USER_GROUP') &&
        !groups.teamGroup
    ) {
        return onboardingTileTypes.joinTeam;
    }

    if (groupMode === 'VENUE' && !groups.venueGroup) {
        return onboardingTileTypes.joinVenue;
    }

    return null;
};

export type TileCheckbox = {
    label: string;
    mandatory?: boolean;
    field: string;
    defaultValue?: boolean;
};

export const getPhoneAuthTranslatedErrorMessage = (
    intl: IntlFormatters,
    serverServerErrorType: ServerErrorType,
): string | undefined => {
    let id: TranslationMessageId | undefined;

    switch (serverServerErrorType) {
        case 'tooManyAttempts':
            id = 'error.phoneAuth.tooManyAttempts';
            break;
        case 'invalidPhoneNumber':
            id = 'error.phoneAuth.invalidPhoneNumber';
            break;
        case 'verificationCodesBlocked':
            id = 'error.phoneAuth.verificationCodesBlocked';
            break;
        case 'verificationCodeExpired':
            id = 'error.phoneAuth.verificationCodeExpired';
            break;
        case 'incorrectVerificationCode':
            id = 'error.phoneAuth.incorrectVerificationCode';
            break;
        case 'verifyRequestFailed':
            id = 'error.phoneAuth.verifyRequestFailed';
            break;
    }

    return id && intl.formatMessage({ id });
};
