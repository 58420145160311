import React from 'react';
import styled from '@emotion/styled';
import { FormattedMessage, useIntl, IntlShape } from 'react-intl';
import TallyMarkdown from 'src/components/shared/ui/TallyMarkdown';
import { TriviaGameProperties } from 'src/util/TallyFirestore';
import { EventModel } from 'src/util/TallyFirestore';
import { BackgroundPanel } from 'src/components/shared/ui/BackgroundPanel';
import { useLocale } from 'src/hooks/useLocale';

type Props = {
    trivia: TriviaGameProperties;
    event: EventModel;
    screenHasBg?: boolean | false;
};

const getDescription = (
    trivia: TriviaGameProperties,
    intl: IntlShape,
): string => {
    switch (trivia.mode) {
        case 'countdownNoPoints':
            return intl.formatMessage(
                { id: 'triviaGame.welcome.description.countdownNoPoints' },
                {
                    numberOfQuestions: trivia.numberOfQuestions,
                    secondsPerQuestion: trivia.secondsPerQuestion,
                },
            );
        case 'noCountdownNoPoints':
            return intl.formatMessage(
                { id: 'triviaGame.welcome.description.noCountdownNoPoints' },
                {
                    numberOfQuestions: trivia.numberOfQuestions,
                },
            );
        case 'countdownFullPoints':
            return intl.formatMessage(
                { id: 'triviaGame.welcome.description.countdownFullPoints' },
                {
                    numberOfQuestions: trivia.numberOfQuestions,
                    pointsPerQuestion: trivia.pointsPerQuestion,
                    secondsPerQuestion: trivia.secondsPerQuestion,
                },
            );
        case 'pointsCountdown':
            return intl.formatMessage(
                {
                    id: 'triviaGame.welcome.description.pointsCountdown',
                },
                {
                    numberOfQuestions: trivia.numberOfQuestions,
                    secondsPerQuestion: trivia.secondsPerQuestion,
                },
            );
        case 'noCountdownFullPoints':
            return intl.formatMessage(
                { id: 'triviaGame.welcome.description.noCountdownFullPoints' },
                {
                    numberOfQuestions: trivia.numberOfQuestions,
                    pointsPerQuestion: trivia.pointsPerQuestion,
                },
            );
    }
};

const Welcome = ({ trivia, event, screenHasBg = false }: Props) => {
    const intl = useIntl();
    const { localeId } = useLocale();

    const welcomeImgUrl = event?.ui?.welcomeImgUrl;
    const welcomeText =
        event && event.ui && event.ui.welcomeText
            ? JSON.parse(JSON.stringify(event.ui.welcomeText))
            : '';

    return (
        <>
            <BackgroundPanel screenHasBg={screenHasBg} btmConnect={true}>
                {!welcomeImgUrl && (
                    <HeaderText>
                        <FormattedMessage
                            id="triviaGame.welcome.header"
                            values={{ eventName: event.name }}
                        />
                    </HeaderText>
                )}
                {welcomeImgUrl && <HeaderImg src={welcomeImgUrl} />}

                {welcomeText && (
                    <TallyMarkdown source={welcomeText[localeId]} />
                )}
                {!welcomeText && (
                    <TallyMarkdown source={getDescription(trivia, intl)} />
                )}
            </BackgroundPanel>
        </>
    );
};

export default Welcome;

const HeaderText = styled.div`
    text-align: center;
    font-size: 35px;
    letter-spacing: -0.6px;
    line-height: 37px;
    font-weight: 900;
    margin: 0 0 20px 0;
    color: #000000;
`;

const HeaderImg = styled.img<{ src: string }>`
    width: 100%;
`;
