import { FormattedMessage, useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import ServerApi from 'src/services/ServerApi';
import { Group } from 'src/types';
import { Color, StyleDefaults } from 'src/styles/Constants';
import TouchButton from '../shared/controls/TouchButton';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styled from '@emotion/styled';
import GroupView from './GroupView';
import ClipLoader from 'react-spinners/ClipLoader';
import { useGroupProvider } from 'src/contexts/GroupProvider';
import { ErrorMessage } from '../AccountPage/shared';
import TextButton from 'src/components/shared/controls/TextButton';
import { ReactComponent as WarningIcon } from 'src/images/icons/error_outline-black-18dp.svg';
import { usePartner } from 'src/hooks/usePartner';
import { TranslatedIteration } from 'src/util/i18n';
import routeUrls from 'src/routes';

const useJoinGroup = (id: string) => {
    const { joinGroup, groups } = useGroupProvider();

    const [group, setGroup] = useState<undefined | Group>(undefined);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const userGroup = groups.userGroup;
    const userInThisGroup = userGroup ? userGroup.id === id : false;
    const userInGroup = !!userGroup;

    useEffect(() => {
        const userGroup = groups.userGroup;
        if (!userGroup || userGroup.id !== id) {
            setIsLoading(true);
            ServerApi.getGroup(id)
                .then((group) => {
                    setGroup(group);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setError(error);
                    setIsLoading(false);
                });
        }
    }, [groups]);

    if (userInThisGroup) {
        return {
            group: userGroup,
            error,
            isLoading,
            joinGroup,
            userInThisGroup,
            userInGroup,
        };
    }

    return { group, error, isLoading, joinGroup, userInThisGroup, userInGroup };
};

const GroupPage = ({ iteration }: { iteration: TranslatedIteration }) => {
    const match = useRouteMatch<{ groupId: string }>(routeUrls.group);
    const history = useHistory();
    const location = useLocation();
    const intl = useIntl();
    const partner = usePartner();

    const groupId = match ? match.params.groupId : '';
    const { group, error, isLoading, joinGroup, userInThisGroup, userInGroup } =
        useJoinGroup(groupId);

    document.body.style.backgroundColor = Color.P1_WHITE;

    return (
        <PageContainer>
            {group ? (
                <GroupView group={group} />
            ) : error ? (
                <>
                    <h1 style={{ textAlign: 'center' }}>
                        <FormattedMessage id="groupPage.error" />
                    </h1>
                    <ErrorMessage error={error} />
                </>
            ) : isLoading ? (
                <h1 style={{ textAlign: 'center' }}>
                    <FormattedMessage id="group.isLoading" />
                </h1>
            ) : null}

            <ErrorMessage error={joinGroup.error} />
            <SubmitGroup>
                {userInGroup && !userInThisGroup && (
                    <WarningText>
                        <WarningIcon
                            style={{ float: 'left', marginRight: '4px' }}
                        />{' '}
                        {group && group.groupType === 'VENUE' ? (
                            <FormattedMessage id="groupPage.warning.venue" />
                        ) : (
                            <FormattedMessage id="groupPage.warning.game" />
                        )}
                    </WarningText>
                )}
                <ButtonContainer>
                    <TouchButton
                        backgroundColor={Color.G1_BLACK}
                        displayText={intl.formatMessage({
                            id: userInThisGroup
                                ? 'groupPage.joinedButton'
                                : 'groupPage.joinButton',
                        })}
                        disabled={!group || isLoading}
                        onClick={() => {
                            !userInThisGroup
                                ? joinGroup.joinGroup(
                                      partner.partnerId,
                                      groupId,
                                  )
                                : history.push({
                                      pathname: routeUrls.play,
                                      search: location.search,
                                  });
                        }}
                        textColor={Color.P1_WHITE}
                    />
                    <div style={{ position: 'absolute', top: 9 }}>
                        <ClipLoader size={20} loading={isLoading} />
                    </div>
                </ButtonContainer>
                {iteration.groupMode !== 'VENUE' && (
                    <PlayButton
                        text={intl.formatMessage({
                            id: 'groupPage.playButton',
                        })}
                        onClick={() => {
                            history.push({
                                pathname: routeUrls.play,
                                search: location.search,
                            });
                        }}
                    />
                )}
                {userInGroup &&
                    !userInThisGroup &&
                    group &&
                    group.groupType === 'VENUE' && (
                        <PlayButton
                            text={intl.formatMessage({
                                id: 'groupPage.stayInFanzone',
                            })}
                            onClick={() => {
                                history.push({
                                    pathname: routeUrls.play,
                                    search: location.search,
                                });
                            }}
                        />
                    )}
            </SubmitGroup>
        </PageContainer>
    );
};

export default GroupPage;

const ButtonContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
`;

const WarningText = styled.p`
    margin-bottom: 16px;
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 18px;
    text-align: left;
`;

const PlayButton = styled(TextButton)`
    margin: 18px 0 0 0;
`;

const SubmitGroup = styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const PageContainer = styled.div`
    max-width: ${StyleDefaults.MAX_ELEMENT_WIDTH};
    width: ${StyleDefaults.ELEMENT_WIDTH};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 40px 0 80px 0;
`;
