import { makeStyles } from '@material-ui/core';
import React from 'react';
import { PredictionBatch, AnswerMilestone } from 'src/util/PredictionHelpers';
import {
    SponsorModel,
    UserAnswerSet,
    OddClaim,
    ProgressivePollModel,
    ProgressivePollAnswer,
    ProgressivePollProperties,
    PredictionModel,
} from 'src/util/TallyFirestore';
import CountdownPageBreak, { TokenPosition } from '../CountdownPageBreak';
import PastPredictionCard from './PastPredictionCard';
import { List, ListItem } from '../TransitionGroupList';
import { ANSWER_MILESTONE_TOKEN } from 'src/styles/Constants';
import { getProgressivePollToShow, ProjectedAnswers } from '../utils';
import ProgressivePollCard from './PastProgressivePollCard';

type Props = {
    finalPredictionBatches: Array<PredictionBatch<AnswerMilestone>>;
    pendingPredictionsBatch: PredictionBatch<'Pending'>;
    sponsors: SponsorModel[];
    answers: UserAnswerSet;
    oddClaims?: OddClaim;
    projectedAnswers: ProjectedAnswers;
    progressivePolls?: ProgressivePollModel[];
    progressivePollsAnswers: ProgressivePollAnswer[];
    progressivePollConfig?: ProgressivePollProperties;
};

const useStyles = makeStyles({
    listContainer: {
        maxWidth: 500,
        width: '92%',
    },
});

const getProgressivePoll = ({
    progressivePolls,
    progressivePollConfig,
    prediction,
    progressivePollsAnswers,
}: {
    prediction: PredictionModel;
    projectedAnswers: ProjectedAnswers;
    progressivePolls?: ProgressivePollModel[];
    progressivePollsAnswers?: ProgressivePollAnswer[];
    progressivePollConfig?: ProgressivePollProperties;
}): JSX.Element | null => {
    if (!progressivePollConfig) {
        return null;
    }
    const progressivePollToShow: ProgressivePollModel | null =
        getProgressivePollToShow({
            progressivePollConfig,
            prediction,
            progressivePolls,
        });
    return progressivePollToShow ? (
        <ListItem key={progressivePollToShow.id}>
            <ProgressivePollCard
                pointValue={progressivePollConfig!.pointsPerPoll}
                poll={progressivePollToShow}
                answer={
                    progressivePollsAnswers &&
                    progressivePollsAnswers.find(
                        (ppa) => ppa.pollId === progressivePollToShow.id,
                    )
                }
                isResolved={true}
            />
        </ListItem>
    ) : null;
};

const PastPredictionList: React.RefForwardingComponent<
    HTMLDivElement,
    Props
> = (
    {
        finalPredictionBatches,
        oddClaims,
        pendingPredictionsBatch,
        sponsors,
        answers,
        projectedAnswers,
        progressivePolls,
        progressivePollsAnswers,
        progressivePollConfig,
    },
    ref,
) => {
    const { listContainer } = useStyles();

    let listItems: JSX.Element[] = [];

    if (pendingPredictionsBatch.entities.length > 0) {
        const { group, entities: predictions } = pendingPredictionsBatch;
        listItems.push(
            <ListItem key={pendingPredictionsBatch.group + predictions[0].id}>
                <CountdownPageBreak
                    countdownData={group}
                    groupTitleTemplate={{
                        message: 'predictPage.predictionList.groupTitle',
                    }}
                    showTitleLine={false}
                />
            </ListItem>,
        );
        predictions.forEach((prediction) => {
            const predictionSponsor = prediction.sponsorship
                ? sponsors.find(
                      (s) => s.id === prediction.sponsorship!.sponsorId,
                  )
                : undefined;
            const projectedSelectedOptionId = projectedAnswers[prediction.id];

            listItems.push(
                <ListItem key={prediction.id}>
                    <PastPredictionCard
                        prediction={prediction}
                        sponsor={predictionSponsor}
                        answer={answers[prediction.id]}
                        oddClaim={oddClaims && oddClaims[prediction.id]}
                        projectedSelectedOptionId={projectedSelectedOptionId}
                    />
                </ListItem>,
            );
            const pPoll = getProgressivePoll({
                prediction,
                projectedAnswers,
                progressivePolls,
                progressivePollConfig,
                progressivePollsAnswers,
            });

            pPoll && listItems.push(pPoll);
        });
    }

    finalPredictionBatches.forEach(({ group, entities: predictions }) => {
        listItems.push(
            <ListItem key={group + predictions[0].id}>
                <CountdownPageBreak
                    countdownData={group}
                    groupTitleTemplate={{
                        message:
                            'predictPage.predictionList.groupTitleTokenized',
                        token: {
                            type: ANSWER_MILESTONE_TOKEN,
                            position: TokenPosition.LEADING,
                        },
                    }}
                    showTitleLine={false}
                />
            </ListItem>,
        );
        predictions.forEach((prediction) => {
            const predictionSponsor = prediction.sponsorship
                ? sponsors.find(
                      (s) => s.id === prediction.sponsorship!.sponsorId,
                  )
                : undefined;
            const projectedSelectedOptionId = projectedAnswers[prediction.id];

            listItems.push(
                <ListItem key={prediction.id}>
                    <PastPredictionCard
                        prediction={prediction}
                        sponsor={predictionSponsor}
                        answer={answers[prediction.id]}
                        oddClaim={oddClaims && oddClaims[prediction.id]}
                        projectedSelectedOptionId={projectedSelectedOptionId}
                    />
                </ListItem>,
            );
            const pPoll: JSX.Element | null = getProgressivePoll({
                prediction,
                projectedAnswers,
                progressivePolls,
                progressivePollConfig,
                progressivePollsAnswers,
            });

            pPoll && listItems.push(pPoll);
        });
    });

    return (
        <div ref={ref} className={listContainer}>
            <List>{listItems}</List>
        </div>
    );
};

export default React.forwardRef(PastPredictionList);
