import React, { useEffect, useRef, useState } from 'react';
import JsBarcode from 'jsbarcode';
import QRCode from 'qrcode';

import { BarcodeType, barcodeTypeToJsBarcodeFormat } from './util';
import { ErrorMessage } from 'src/components/AccountPage/shared';

const BAR_CODE_ELEMENT_ID = 'barcode';

type Props = {
    code: string;
    type: BarcodeType;
};

const MachineReadableOfferCode = ({ code, type }: Props) => {
    const [error, setError] = useState<Error | null>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    useEffect(() => {
        if (canvasRef.current) {
            if (type === 'QR_CODE') {
                QRCode.toCanvas(canvasRef.current, code, { scale: 6 }, (e) => {
                    if (e) {
                        setError(e);
                    } else {
                        setError(null);
                    }
                });
            } else {
                const format = barcodeTypeToJsBarcodeFormat(type);
                try {
                    JsBarcode(`#${BAR_CODE_ELEMENT_ID}`, code, {
                        width: 3,
                        displayValue: false,
                        format: format,
                    });
                    setError(null);
                } catch (errorMessage) {
                    if (typeof errorMessage === 'string') {
                        setError(new Error(errorMessage));
                    } else {
                        setError(new Error('Unknown barcode error'));
                    }
                }
            }
        }
    }, [code, type, canvasRef, setError]);
    return (
        <>
            <ErrorMessage error={error} />
            <canvas ref={canvasRef} id={BAR_CODE_ELEMENT_ID} />
        </>
    );
};

export default MachineReadableOfferCode;
