import React from 'react';
import { FormattedMessage } from 'react-intl';
import Switch from 'react-switch';
import styled from '@emotion/styled';

import ServerApi from 'src/services/ServerApi';
import { Color } from 'src/styles/Constants';
import { ContactInfo, SMSAlertsState } from 'src/util/TallyFirestore';
import { ErrorMessage, SectionHeaderText, DescriptionText } from '../../shared';
import {
    SettingContainer,
    SettingHeaderText,
    useImmediateSetting,
} from '../shared';
import { useAnalytics } from 'src/contexts/AnalyticsContext';
import { AppArea, EventName, Screen } from 'src/util/AnalyticsConstants';
import { useUpdateContactInfo } from 'src/contexts/UserStateContext';
import { usePartner } from 'src/hooks/usePartner';

const updateSmsAlertsOptIn = (smsAlertsOptIn: boolean) => {
    const smsAlertsState = smsAlertsOptIn
        ? SMSAlertsState.OPTED_IN
        : SMSAlertsState.OPTED_OUT_DIRECT;
    return ServerApi.updateUser({
        smsAlertsState,
    });
};

const useSmsAlertsOptIn = (initialValue: boolean | undefined) => {
    const updateContactInfo = useUpdateContactInfo();
    const analytics = useAnalytics();

    const onSmsAlertsOptInChanged = (value: boolean) => {
        const smsAlertsState = value
            ? SMSAlertsState.OPTED_IN
            : SMSAlertsState.OPTED_OUT_DIRECT;

        updateContactInfo({
            smsAlertsState,
        });
        analytics.logEvent({
            eventName: EventName.smsAlertsEnabled,
            params: {
                screen: Screen.userSettings,
                smsAlertsState,
            },
            eventCategory: AppArea.profile,
        });
    };

    const { value, error, update, isUpdating } = useImmediateSetting(
        !!initialValue,
        updateSmsAlertsOptIn,
        onSmsAlertsOptInChanged,
    );

    return {
        smsAlertsOptIn: value,
        setSmsAlertsOptIn: update,
        smsAlertsError: error,
        isSmsAlertsUpdating: isUpdating,
    };
};

const SmsSettingsForm = ({ contactInfo }: { contactInfo: ContactInfo }) => {
    const partner = usePartner();
    const {
        smsAlertsOptIn,
        setSmsAlertsOptIn,
        smsAlertsError,
        isSmsAlertsUpdating,
    } = useSmsAlertsOptIn(
        contactInfo && contactInfo.smsAlertsState === SMSAlertsState.OPTED_IN,
    );

    return (
        <>
            <SettingHeaderText>
                <FormattedMessage id="settings.smsAlertLabel" />
            </SettingHeaderText>
            <ErrorMessage error={smsAlertsError} />
            {!partner.properties.smsAlertsExternal ? (
                <SettingContainer>
                    {partner.properties.smsAlertCheckboxSubtitle || (
                        <FormattedMessage id="settings.smsAlertText" />
                    )}
                    <Switch
                        disabled={isSmsAlertsUpdating}
                        onChange={setSmsAlertsOptIn}
                        checked={smsAlertsOptIn}
                        checkedIcon={false}
                        offColor="#b1b1b1"
                        offHandleColor={Color.P1_WHITE}
                        onColor={Color.T25_BRIGHT_TEAL}
                        onHandleColor={Color.P1_WHITE}
                        uncheckedIcon={false}
                    />
                </SettingContainer>
            ) : (
                partner.properties.smsAlertExternalMsg || (
                    <FormattedMessage
                        id="settings.smsAlertExternalText"
                        values={{
                            partnerSMSNumber: partner.primarySmsPhoneNumber,
                        }}
                    />
                )
            )}
        </>
    );
};

type Props = {
    contactInfo: ContactInfo;
};

const SmsSettings = ({ contactInfo }: Props) => {
    const partner = usePartner();
    const phoneNumber =
        partner.primarySmsPhoneNumber || partner.secondarySmsPhoneNumber;

    return (
        <>
            <SectionHeaderText marginTop="30px">
                <FormattedMessage id="settings.smsAlertHeader" />
            </SectionHeaderText>
            {contactInfo.smsAlertsState === SMSAlertsState.OPTED_OUT_CARRIER ? (
                <>
                    <SettingHeaderText>
                        <FormattedMessage id="settings.smsAlertLabel" />
                    </SettingHeaderText>
                    <DescriptionText marginTop="4px">
                        <FormattedMessage id="settings.smsAlertUnstopText" />{' '}
                        {phoneNumber}.
                    </DescriptionText>
                    <ReactivateLink href={`sms://${phoneNumber};?&body=UNSTOP`}>
                        <FormattedMessage id="settings.smsAlertUnstopLink" />
                    </ReactivateLink>
                </>
            ) : (
                <SmsSettingsForm contactInfo={contactInfo} />
            )}
        </>
    );
};

export default SmsSettings;

const ReactivateLink = styled.a`
    color: ${Color.T31_BLACK};
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 18px;
    margin-top: 6px;
    text-decoration: underline;
    text-transform: uppercase;
`;
