import styled from '@emotion/styled';

import blackCloseIcon from 'src/images/icons/Close-Black@3x.svg';
import grayCloseIcon from 'src/images/icons/Close-Gray@3x.svg';
import whiteCloseIcon from 'src/images/icons/Close-White@3x.svg';

export enum CloseButtonColor {
    BLACK = 'BLACK',
    GRAY = 'GRAY',
    WHITE = 'WHITE',
}

function getCloseButtonIconUrl(iconType?: CloseButtonColor) {
    if (iconType === CloseButtonColor.BLACK) {
        return blackCloseIcon;
    }
    if (iconType === CloseButtonColor.WHITE) {
        return whiteCloseIcon;
    }
    return grayCloseIcon;
}

const CloseButton = styled.div<{
    color?: CloseButtonColor;
}>`
    height: 20px;
    width: 20px;
    padding: 2px 0;
    background-image: url(${(props) => getCloseButtonIconUrl(props.color)});
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
`;

export default CloseButton;
