import styled from '@emotion/styled';
import React, { useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import { useSpring, animated } from 'react-spring';
import SvgPowerup from 'src/images/icons/PowerUp';
import SvgBonus from 'src/images/icons/Bonus';
import { FormattedMessage } from 'react-intl';

import Prediction from 'src/components/shared/predictions/Prediction';
import chevron from 'src/images/icons/Chevron-Expand@3x.svg';
import DotsDefault from 'src/images/backgrounds/DotsDefault.svg';

import PredictionIframeAd from 'src/components/shared/predictions/PredictionIframeAd';
import { useLocale } from 'src/hooks/useLocale';
import { Color, TextStyles, TextStyleTypes } from 'src/styles/Constants';
import { AnswerCorrectness } from 'src/util/EventHelpers';
import {
    PredictionAdPlacement,
    PredictionAdRenderTime,
    SponsorModel,
    UserAnswer,
    Claim,
    PredictionModel,
} from 'src/util/TallyFirestore';

import { predictionIsCorrect } from 'src/util/PredictionHelpers';
import { getContainerBorderColor, predictionSelectedOptionId } from './utils';
import { PredictionSponsorshipUnitTemplateId } from 'src/util/SponsorshipTypes';
import { getOddsWin } from 'src/util/bettingOdds';

function getStatusText(
    prediction: PredictionModel,
    correctness: AnswerCorrectness,
    answered: boolean,
): JSX.Element | string {
    if (!answered) {
        return <FormattedMessage id="newPredictionCard.status.missed" />;
    }
    if (correctness === AnswerCorrectness.incorrect) {
        return <FormattedMessage id="newPredictionCard.status.incorrect" />;
    }
    if (correctness === AnswerCorrectness.correct) {
        if (prediction.type === 'POLL') {
            return (
                <FormattedMessage id="newPredictionCard.status.participated" />
            );
        } else {
            return <FormattedMessage id="newPredictionCard.status.correct" />;
        }
    }
    return prediction.answerMilestone ? (
        <FormattedMessage id="newPredictionCard.status.anytime">
            {(msg) => {
                return (
                    <FormattedMessage
                        id="newPredictionCard.status.pending"
                        values={{ when: msg }}
                    />
                );
            }}
        </FormattedMessage>
    ) : (
        <FormattedMessage
            id="newPredictionCard.status.pending"
            values={{ when: prediction.answerMilestone }}
        />
    );
}

function getSponsorIcon(
    sponsor: SponsorModel | undefined,
    correctness: AnswerCorrectness,
    prediction: PredictionModel,
) {
    let sponsorIcon: JSX.Element | undefined;
    let iconColor: Color | string = Color.G6_STONE;
    if (correctness === AnswerCorrectness.correct) {
        iconColor =
            sponsor && sponsor.primaryColor
                ? sponsor.primaryColor
                : Color.P4_CHILI_PEPPER;
    }
    if (
        prediction.sponsorship &&
        prediction.sponsorship.templateId ===
            PredictionSponsorshipUnitTemplateId.DOUBLE_POINT_POWERUP
    ) {
        sponsorIcon = (
            <SvgPowerup
                color={iconColor}
                style={{
                    height: '25px',
                    width: '25px',
                }}
            />
        );
    } else {
        sponsorIcon = (
            <SvgBonus
                color={iconColor}
                style={{
                    height: '25px',
                    width: '25px',
                }}
            />
        );
    }

    return sponsorIcon;
}

function getCollapsedHeaderTextColor(
    correctness: AnswerCorrectness,
    answered: boolean,
) {
    if (answered) {
        if (correctness === AnswerCorrectness.incorrect) {
            return Color.P4_CHILI_PEPPER;
        }

        if (correctness === AnswerCorrectness.correct) {
            return Color.PREDICTION_GREEN_DARK;
        }

        return Color.T31_BLACK;
    }
    return Color.PREDICTION_GREY_93;
}

type Props = {
    answer?: UserAnswer;
    oddClaim?: Claim;
    prediction: PredictionModel;
    projectedSelectedOptionId?: string;
    sponsor?: SponsorModel;
};

const PastPredictionCard = ({
    prediction,
    answer,
    oddClaim,
    projectedSelectedOptionId,
    sponsor,
}: Props) => {
    const { localeId } = useLocale();
    const [expanded, setExpanded] = useState(false);
    const rotatingChevronSpring = useSpring({
        transform: expanded ? 'rotateZ(180deg)' : 'rotateZ(0deg)',
    });

    const shouldDisplayAd: boolean = !!(
        prediction.adEnabled &&
        (prediction.adRenderTime === PredictionAdRenderTime.rightAway ||
            (prediction.adRenderTime ===
                PredictionAdRenderTime.afterPrediction &&
                (projectedSelectedOptionId || answer)))
    );

    const answered = !!answer;

    const correctness = predictionIsCorrect(prediction, answer);

    const borderColor = getContainerBorderColor(
        prediction.type,
        correctness,
        answered,
        expanded,
    );

    const status = getStatusText(prediction, correctness, answered);
    const sponsorIcon = getSponsorIcon(sponsor, correctness, prediction);
    let pointValue;

    switch (prediction.type) {
        case 'POLL':
        case 'BET_SIM':
        case 'MULTIPLE_CHOICE': {
            // ATODO: how does it work?
            const selectedOptionId = predictionSelectedOptionId(
                prediction,
                answer,
            );
            pointValue =
                (oddClaim &&
                    selectedOptionId &&
                    (
                        prediction.pointValue +
                        getOddsWin(
                            prediction.pointValue,
                            oddClaim[selectedOptionId],
                        )
                    ).toLocaleString(localeId)) ||
                0;
            break;
        }
        case 'FILL_IN_THE_BLANK':
            pointValue =
                (prediction.pointValue &&
                    prediction.pointValue.toLocaleString(localeId)) ||
                0;
            break;
        default:
    }

    const isPoll = prediction.type === 'POLL';
    const showPoints =
        correctness === AnswerCorrectness.correct && (!isPoll || !expanded);

    const collapsedHeaderTextColor = getCollapsedHeaderTextColor(
        correctness,
        answered,
    );

    const multipleChoiceOptionProps =
        prediction.type === 'MULTIPLE_CHOICE'
            ? {
                  allOrNothing: prediction.allOrNothing,
                  hideOptionMetrics: prediction.hideOptionMetrics,
              }
            : {};

    return (
        <Container>
            {shouldDisplayAd &&
                prediction.adPlacement === PredictionAdPlacement.above && (
                    <AdCardContentContainer adPlacment={prediction.adPlacement}>
                        <PredictionIframeAd
                            predictionId={prediction.id}
                            predictionAd={{
                                iframeHtml: prediction.adIframeHtml,
                                sizingPolicy: prediction.adSizingPolicy,
                                cutOffHeight: prediction.adCutOffHeight,
                                headline: prediction.adHeadline,
                                disclaimer: prediction.adDisclaimer,
                            }}
                        />
                    </AdCardContentContainer>
                )}

            <Card
                borderColor={borderColor}
                onClick={() => setExpanded(!expanded)}
            >
                <CollapsedHeaderBar color={collapsedHeaderTextColor}>
                    <PredictionStatusLabel>{status}</PredictionStatusLabel>
                    {showPoints && <Points>+{pointValue} pts</Points>}
                    <Chevron style={rotatingChevronSpring} />
                </CollapsedHeaderBar>
                {sponsor && (
                    <SponsorshipBox center={isPoll && expanded}>
                        <SponsorshipLogoContainer center={isPoll && expanded}>
                            <SponsorshipLogo src={sponsor.wideLogoUrl} />
                            {sponsorIcon && (
                                <SponsorIconContainer>
                                    {sponsorIcon}
                                </SponsorIconContainer>
                            )}
                        </SponsorshipLogoContainer>
                    </SponsorshipBox>
                )}
                <TitleLabel big={false} centered={isPoll && expanded}>
                    {prediction.text}
                </TitleLabel>
                <Collapse in={expanded} timeout="auto">
                    {isPoll && (
                        <PollPoints>
                            <PollHeading>
                                <FormattedMessage id="newPredictionCard.pollHeading" />
                            </PollHeading>
                            {pointValue}{' '}
                            <FormattedMessage id="newPredictionCard.pollPoints" />
                        </PollPoints>
                    )}
                    <Prediction
                        answer={answer}
                        oddClaim={oddClaim}
                        prediction={prediction}
                        isLive={false}
                        projectedSelectedOptionId={projectedSelectedOptionId}
                        showText={false}
                        {...multipleChoiceOptionProps}
                    />
                    {shouldDisplayAd &&
                        prediction.adPlacement ===
                            PredictionAdPlacement.inline && (
                            <PredictionIframeAd
                                predictionId={prediction.id}
                                predictionAd={{
                                    iframeHtml: prediction.adIframeHtml,
                                    sizingPolicy: prediction.adSizingPolicy,
                                    cutOffHeight: prediction.adCutOffHeight,
                                    headline: prediction.adHeadline,
                                    disclaimer: prediction.adDisclaimer,
                                }}
                            />
                        )}
                    {prediction.sponsorship &&
                        prediction.sponsorship.templateId ===
                            PredictionSponsorshipUnitTemplateId.PRIZE_REDEMPTION && (
                            <>
                                <PrizeAssetContainer>
                                    <PrizeAsset
                                        src={
                                            prediction.sponsorship.properties
                                                .asset
                                        }
                                    />
                                </PrizeAssetContainer>
                                <PrizeAssetBackgroundContainer>
                                    <PrizeAssetBackground src={DotsDefault} />
                                </PrizeAssetBackgroundContainer>
                            </>
                        )}
                </Collapse>
            </Card>
            {shouldDisplayAd &&
                prediction.adPlacement === PredictionAdPlacement.below && (
                    <AdCardContentContainer adPlacment={prediction.adPlacement}>
                        <PredictionIframeAd
                            predictionId={prediction.id}
                            predictionAd={{
                                iframeHtml: prediction.adIframeHtml,
                                sizingPolicy: prediction.adSizingPolicy,
                                cutOffHeight: prediction.adCutOffHeight,
                                headline: prediction.adHeadline,
                                disclaimer: prediction.adDisclaimer,
                            }}
                        />
                    </AdCardContentContainer>
                )}
        </Container>
    );
};

export default PastPredictionCard;

const Container = styled.div`
    padding-bottom: 24px;
`;

const AdCardContentContainer = styled.div<{
    adPlacment: PredictionAdPlacement;
}>`
    margin: ${(props) =>
            props.adPlacment === PredictionAdPlacement.below ? 20 : 0}px
        0
        ${(props) =>
            props.adPlacment === PredictionAdPlacement.above ? 20 : 0}px
        0;
`;

const Card = styled.div<{
    borderColor: string;
    extraTopPadding?: boolean;
}>`
    position: relative;
    top: 0;
    border: 1px solid ${(props) => props.borderColor};
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.08);
    background-color: ${Color.P1_WHITE};
    transition: border 0.4s;
    width: 100%;
    padding: ${(props) => (props.extraTopPadding ? 40 : 20)}px 20px 20px 20px;
    box-sizing: border-box;
    margin-bottom: 20px;
`;

const TitleLabel = styled.div<{ big: boolean; centered: boolean }>`
    display: inline-block;
    width: 100%;
    ${(props) =>
        props.big
            ? TextStyles[TextStyleTypes.S1]
            : TextStyles[TextStyleTypes.P3]}
    text-align: ${(props) => (props.centered ? 'center' : 'left')};
    transition:
        font-weight 0.3s,
        font-size 0.3s;
`;

const CollapsedHeaderBar = styled.div<{
    color: string;
}>`
    display: flex;
    width: 100%;
    color: ${(props) => props.color};
    flex-direction: row;
    justify-content: flex-start;
    margin: 0 0 4px 0;
`;

const PredictionStatusLabel = styled.div`
    font-size: 12px;
    letter-spacing: 0.34px;
    line-height: 16px;
    text-transform: uppercase;
`;

const Points = styled(PredictionStatusLabel)`
    font-weight: 600;
    margin-left: 4px;
`;

const Chevron = styled(animated.div)`
    background-image: url(${chevron});
    background-position: center;
    background-repeat: no-repeat;
    margin-left: auto;
    width: 18px;
    height: 18px;
`;
const PollHeading = styled.span`
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.13px;
    line-height: 28px;
    margin-right: 5px;
`;

const PollPoints = styled.div`
    text-align: center;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: -0.13px;
    line-height: 28px;
    margin-bottom: 6px;
`;

// Sponsor stuff

const SponsorshipLogo = styled.img`
    height: 32px;
`;

const SponsorshipLogoContainer = styled.div<{ center: boolean }>`
    padding: 5px 0;
    width: 100%;
    border-bottom: 2px dotted ${Color.G7_FOG};
    display: flex;
    justify-content: ${(props) => (props.center ? 'center' : 'flex-start')};
    align-items: center;
    height: 25px;
`;

const SponsorshipTag = styled.div`
    color: ${Color.G4_CHARCOAL};
    margin: 5px 0 0 0;
    ${TextStyles[TextStyleTypes.B2]}
`;

const SponsorshipBox = styled.div<{ center: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: ${(props) => (props.center ? 'center' : 'flex-start')};
`;

const SponsorIconContainer = styled.div`
    display: flex;
    padding: 0 0 0 10px;
`;

const PrizeAssetContainer = styled.div`
    margin-top: 20px;
    position: relative;
    z-index: 8;
`;

const PrizeAsset = styled.img`
    width: 100%;
`;

const PrizeAssetBackground = styled.img`
    width: 100%;
`;

const PrizeAssetBackgroundContainer = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
`;
