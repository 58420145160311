import 'src/styles/loadspinner.css';

import styled from '@emotion/styled';
import React from 'react';

import TallyLogo from './TallyLogo';
import { Color } from 'src/styles/Constants';
import Theme from 'src/util/Theme';
import PartnerLogo from '../ui/PartnerLogo';

const crossStrokeSize = 40;
const crossHeight = Math.ceil(
    Math.sqrt((crossStrokeSize * crossStrokeSize) / 2),
); // Pythagorean theorem

const CrossContainer = styled.div`
    height: ${crossHeight}px;
    padding: 40px 0 60px 0;
`;

const CrossStrokeOne = styled.div`
    background-color: ${Color.P1_WHITE};
    height: ${crossStrokeSize}px;
    position: absolute;
    transform: rotate(45deg);
    width: 1px;
`;

const CrossStrokeTwo = styled.div`
    background-color: white;
    height: ${crossStrokeSize}px;
    position: absolute;
    transform: rotate(-45deg);
    width: 1px;
`;

function Cross() {
    return (
        <CrossContainer>
            <CrossStrokeOne />
            <CrossStrokeTwo />
        </CrossContainer>
    );
}

export default function TallyLoadingFullScreen(props: {
    animate?: boolean;
    onNextIteration?: () => void;
}) {
    const { animate = true, onNextIteration } = props;
    Theme.tallyUnbranded && onNextIteration && onNextIteration();
    return (
        <LoadingContainer>
            {Theme.loadingScreenImageUrl && (
                <LoadingImage src={Theme.loadingScreenImageUrl} />
            )}
            {!!Theme.partnerLogoUrl && !Theme.loadingScreenImageUrl && (
                <PartnerLogo
                    logoUrl={Theme.partnerLogoUrl}
                    maxHeight={Theme.loadingScreenLogoSize}
                />
            )}
            {!Theme.tallyUnbranded &&
                !!Theme.partnerLogoUrl &&
                !Theme.loadingScreenImageUrl && <Cross />}
            {false && Theme.tallyUnbranded && (
                <>
                    <VerticalSpace height={40} />
                    <div className={'lds-ring'}>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </>
            )}
            {false && Theme.tallyUnbranded && (
                <>
                    <VerticalSpace height={40} />
                    <div className={'lds-ripple'}>
                        <div></div>
                        <div></div>
                    </div>
                </>
            )}
            {Theme.tallyUnbranded && (
                <>
                    <VerticalSpace height={40} />
                    <div className="lb-container">
                        <div className="lb-loading"></div>
                    </div>
                </>
            )}

            {!Theme.tallyUnbranded && (
                <TallyLogo
                    animate={animate}
                    backgroundColor={Theme.waitingRoomBackgroundColor}
                    color={Color.P1_WHITE}
                    onNextIteration={onNextIteration}
                />
            )}
        </LoadingContainer>
    );
}

const LoadingContainer = styled.div`
    align-items: center;
    background-color: ${Theme.waitingRoomBackgroundColor};
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    position: absolute;
    text-align: 'center';
    width: 100%;
`;

const LoadingImage = styled.img`
    align-items: center;
    max-width: 100%;
    max-height: 100%;
    margin-bottom: 20px;
`;

const VerticalSpace = styled.div<{ height: number }>`
    height: ${(props) => props.height}px;
`;
