import React from 'react';
import Switch from 'react-switch';
import { ClipLoader } from 'react-spinners';
import { useIntl, FormattedMessage } from 'react-intl';

import { DescriptionText, SectionHeaderText, ErrorMessage } from '../../shared';
import { SettingContainer } from '../shared';
import config from 'src/util/Config';
import { Color } from 'src/styles/Constants';
import {
    useEmailMarketingOptIn,
    useOptionalMarketingOptIn,
    useSmsMarketingOptIn,
    useTallyMarketingOptIn,
} from './hooks';
import { ContactInfo } from 'src/util/TallyFirestore';
import TallyMarkdown from 'src/components/shared/ui/TallyMarkdown';
import { usePartner } from 'src/hooks/usePartner';

const clipLoaderCss = 'margin-right: 12px;';

type Props = {
    contactInfo: ContactInfo;
};

const MarketingSettings = ({ contactInfo }: Props) => {
    const intl = useIntl();
    const partner = usePartner();

    const {
        smsMarketingEnabled,
        optionalOptInEnabled,
        emailOptInIsMandatory,
        smsOptInIsMandatory,
        optionalOptInIsMandatory,
        tallyOptInIsActive,
        disableClientOptInChange,
    } = config.partnerData.properties;

    const {
        smsMarketingOptIn,
        setSmsMarketingOptIn,
        smsMarketingError,
        isSmsMarketingUpdating,
    } = useSmsMarketingOptIn(contactInfo && contactInfo.smsMarketingOptIn);

    const {
        isTallyMarketingUpdating,
        setTallyMarketingOptIn,
        tallyMarketingError,
        tallyMarketingOptIn,
    } = useTallyMarketingOptIn(contactInfo && contactInfo.tallyOptIn);

    const {
        emailMarketingOptIn,
        setEmailMarketingOptIn,
        emailMarketingError,
        isEmailMarketingUpdating,
    } = useEmailMarketingOptIn(contactInfo && contactInfo.marketingOptIn);

    const {
        optionalMarketingOptIn,
        updateOptionalMarketingOptIn,
        optionalMarketingError,
        isOptionalMarketingUpdating,
    } = useOptionalMarketingOptIn(contactInfo && contactInfo.optionalOptIn);

    return (
        <>
            <SectionHeaderText marginTop="30px">
                <FormattedMessage id="settings.marketing.header" />
            </SectionHeaderText>
            <DescriptionText>
                {!disableClientOptInChange && (
                    <TallyMarkdown
                        source={
                            partner.properties.marketingOptInMessageOverride ||
                            intl.formatMessage(
                                {
                                    id: 'settings.marketing.descriptionText',
                                },
                                { partnerName: partner.name },
                            )
                        }
                    />
                )}
                {partner.properties.marketingPreferencesHowTo &&
                    disableClientOptInChange && (
                        <TallyMarkdown
                            source={
                                partner.properties.marketingPreferencesHowTo ||
                                ''
                            }
                        />
                    )}
            </DescriptionText>
            <ErrorMessage
                error={
                    emailMarketingError ||
                    smsMarketingError ||
                    optionalMarketingError ||
                    tallyMarketingError
                }
            />
            {!emailOptInIsMandatory && !disableClientOptInChange && (
                <SettingContainer marginTop="16px">
                    <TallyMarkdown
                        source={
                            partner.properties.emailMarketingLabel ||
                            intl.formatMessage({
                                id: 'optIn.marketing.email.label',
                            })
                        }
                        paragraphPaddingBottom="0"
                    />
                    <ClipLoader
                        size={20}
                        loading={isEmailMarketingUpdating}
                        css={clipLoaderCss}
                    />
                    <Switch
                        disabled={isEmailMarketingUpdating}
                        onChange={setEmailMarketingOptIn}
                        checked={emailMarketingOptIn}
                        checkedIcon={false}
                        offColor="#b1b1b1"
                        offHandleColor={Color.P1_WHITE}
                        onColor={Color.T25_BRIGHT_TEAL}
                        onHandleColor={Color.P1_WHITE}
                        uncheckedIcon={false}
                    />
                </SettingContainer>
            )}
            {smsMarketingEnabled &&
                !smsOptInIsMandatory &&
                !disableClientOptInChange && (
                    <SettingContainer marginTop="16px">
                        <TallyMarkdown
                            source={
                                partner.properties.smsMarketingLabel ||
                                intl.formatMessage({
                                    id: 'optIn.marketing.phone.label',
                                })
                            }
                            paragraphPaddingBottom="0"
                        />

                        <ClipLoader
                            size={20}
                            loading={isSmsMarketingUpdating}
                            css={clipLoaderCss}
                        />
                        <Switch
                            disabled={isSmsMarketingUpdating}
                            onChange={setSmsMarketingOptIn}
                            checked={smsMarketingOptIn}
                            checkedIcon={false}
                            offColor="#b1b1b1"
                            offHandleColor={Color.P1_WHITE}
                            onColor={Color.T25_BRIGHT_TEAL}
                            onHandleColor={Color.P1_WHITE}
                            uncheckedIcon={false}
                        />
                    </SettingContainer>
                )}
            {optionalOptInEnabled &&
                !optionalOptInIsMandatory &&
                !disableClientOptInChange && (
                    <SettingContainer marginTop="16px">
                        <TallyMarkdown
                            source={
                                partner.properties.optionalOptInLabel ||
                                intl.formatMessage({
                                    id: 'optIn.marketing.3p.label',
                                })
                            }
                            paragraphPaddingBottom="0"
                        />
                        <ClipLoader
                            size={20}
                            loading={isOptionalMarketingUpdating}
                            css={clipLoaderCss}
                        />
                        <Switch
                            disabled={isOptionalMarketingUpdating}
                            onChange={updateOptionalMarketingOptIn}
                            checked={optionalMarketingOptIn}
                            checkedIcon={false}
                            offColor="#b1b1b1"
                            offHandleColor={Color.P1_WHITE}
                            onColor={Color.T25_BRIGHT_TEAL}
                            onHandleColor={Color.P1_WHITE}
                            uncheckedIcon={false}
                        />
                    </SettingContainer>
                )}
            {tallyOptInIsActive && (
                <SettingContainer marginTop="16px">
                    <TallyMarkdown
                        source={
                            partner.properties.tallyOptInLabel ||
                            intl.formatMessage({
                                id: 'optIn.marketing.tally.label',
                            })
                        }
                        paragraphPaddingBottom="0"
                    />

                    <ClipLoader
                        size={20}
                        loading={isTallyMarketingUpdating}
                        css={clipLoaderCss}
                    />
                    <Switch
                        disabled={isTallyMarketingUpdating}
                        onChange={setTallyMarketingOptIn}
                        checked={tallyMarketingOptIn}
                        checkedIcon={false}
                        offColor="#b1b1b1"
                        offHandleColor={Color.P1_WHITE}
                        onColor={Color.T25_BRIGHT_TEAL}
                        onHandleColor={Color.P1_WHITE}
                        uncheckedIcon={false}
                    />
                </SettingContainer>
            )}
        </>
    );
};

export default MarketingSettings;
