import { TranslatedConfigPartnerData } from 'src/util/ConfigTypes';
import { EventModel } from 'src/util/TallyFirestore';

type EventTheme = {
    backgroundColor?: string;
    backgroundImgUrl?: string;
    backgroundDesktopImgUrl?: string;
    textBlockBackgroundOpacity?: number;
    backgroundBtmAlign?: boolean;
    color?: string;
};

export const getEventUITheme = (
    event?: EventModel,
    partner?: TranslatedConfigPartnerData,
): EventTheme => ({
    backgroundColor: event?.ui?.backgroundColor,
    backgroundImgUrl: event?.ui?.backgroundImgUrl,
    backgroundDesktopImgUrl: event?.ui?.backgroundDesktopImgUrl,
    textBlockBackgroundOpacity: event?.ui?.textBlockBackgroundOpacity,
    backgroundBtmAlign: event?.ui?.backgroundBtmAlign,
    color: event?.ui?.textColor,
});
