import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import Tile from './Tile';
import css from '@emotion/css';
import Link from './Link';
import { Color } from 'src/styles/Constants';

interface Props {
    eventSlug: string;
    surveyUrl: string;
}

export default class SurveyTile extends Component<Props> {
    public render() {
        const { eventSlug, surveyUrl } = this.props;

        return (
            <Tile backgroundColor={Color.T31_BLACK}>
                <Container>
                    <CallToActionTitle>
                        <FormattedMessage id="survey.callToAction" />
                    </CallToActionTitle>
                    <SubText>
                        <FormattedMessage id="survey.subText" />
                    </SubText>
                    <SubTextLink
                        displayText={
                            <FormattedMessage id="survey.subTextLink" />
                        }
                        url={`${surveyUrl}?event=${eventSlug}`}
                    />
                </Container>
            </Tile>
        );
    }
}

const Container = styled.div`
    align-items: center;
    font-family: Graphik Web;
    color: ${Color.P1_WHITE};
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    padding: 4px 0;
`;

const CallToActionTitle = styled.div`
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 18px;
    text-transform: upperCase;
    margin: 0 0 8px 0;
`;

const SubTextStyle = css`
    font-size: 12px;
    line-height: 17px;
`;

const SubText = styled.div`
    ${SubTextStyle}
`;

const SubTextLink = styled(Link)`
    ${SubTextStyle}
    text-decoration: underline;
`;
