import React, { useState } from 'react';

import TimeUntilLock from '../renderless/TimeUntilLock';
import LockText, { TimeUntilLockMode } from '../predictions/LockText';

interface Props {
    startDate: Date;
    countdownPrefixText?: string;
    onCountdownExpired?: () => void;
    className?: string;
}

const CountdownTimer = ({
    startDate,
    countdownPrefixText,
    onCountdownExpired,
    className,
}: Props) => {
    const [countdownSeconds, setCountdownSeconds] = useState<
        undefined | number
    >(undefined);

    const displayText = (
        <>
            {countdownPrefixText && countdownPrefixText + ' '}
            {countdownSeconds && (
                <LockText
                    mode={TimeUntilLockMode.long}
                    countdownDate={startDate}
                    countdownSeconds={countdownSeconds}
                />
            )}
        </>
    );

    return (
        <div className={className}>
            {displayText}
            <TimeUntilLock
                countdownDate={startDate}
                onCountdownUpdated={setCountdownSeconds}
                onCountdownExpired={onCountdownExpired}
            />
        </div>
    );
};

export default CountdownTimer;
