import React, { useEffect, useState } from 'react';

import TimeUntilLock from 'src/components/shared/renderless/TimeUntilLock';
import { GroupedPredictions } from 'src/util/PredictionHelpers';
import { usePrevious } from 'src/util/CustomHooks';
import { PredictionModel } from 'src/util/TallyFirestore';

interface Props {
    groupedPredictions: GroupedPredictions;
    groupedPredictionsUpdatedTimestamp: Date;

    predictions: PredictionModel[];
    predictionsUpdatedTimestamp: Date;

    onCountdownUpdated: (
        prediction: PredictionModel,
        countdown?: number,
    ) => void;
    onPredictionLocked: (prediction: PredictionModel) => void;
    onPredictionsResolved: (predictions: PredictionModel[]) => void;
    onNextPredictionToLock: (nextPredictionToLock?: PredictionModel) => void;
}

function didNextPredictionToLockChange(
    previous: PredictionModel | undefined,
    current: PredictionModel | undefined,
) {
    if (!previous && !current) {
        // both are undefined
        return false;
    }

    if (!previous || !current) {
        // one of them is undefined, the other is not
        return true;
    }

    // either the previous 'next prediction to lock' has a different id than the current 'next prediction to lock',
    // or the lock date has changed or the lock description changed.
    // in either case, the 'next prediction to lock' has changed.
    return (
        previous.id !== current.id ||
        !previous.lockDate.isEqual(current.lockDate) ||
        previous.lockDescription !== current.lockDescription
    );
}

export default function PistachioPredictionChangeDetector(props: Props) {
    const {
        groupedPredictions,
        groupedPredictionsUpdatedTimestamp,
        predictions,
        predictionsUpdatedTimestamp,
        onPredictionsResolved,
        onPredictionLocked,
        onCountdownUpdated,
        onNextPredictionToLock,
    } = props;

    const previousPredictions = usePrevious<PredictionModel[]>(predictions);

    useEffect(() => {
        if (previousPredictions !== null) {
            const resolvedPredictions = checkForResolvedPredictions(
                previousPredictions,
                predictions,
            );
            if (resolvedPredictions.length) {
                onPredictionsResolved(resolvedPredictions);
            }
        }
    }, [predictionsUpdatedTimestamp]);

    const [nextPredictionToLock, setNextPredictionToLock] = useState<
        PredictionModel | undefined
    >(undefined);

    useEffect(() => {
        const newNextPredictionToLock =
            determineNextPredictionToLock(groupedPredictions);
        // We have to be careful when we call setNextPredictionToLock,
        // as we should only call onNextPredictionToLock when the next prediction
        // to lock actually changes - we can't spam that call.
        if (
            didNextPredictionToLockChange(
                nextPredictionToLock,
                newNextPredictionToLock,
            )
        ) {
            setNextPredictionToLock(newNextPredictionToLock);
            // When the next prediction to lock changes, call onCountdownUpdate to
            // clear any old countdown info from the old 'next prediction to lock'.
            if (newNextPredictionToLock) {
                onCountdownUpdated(newNextPredictionToLock);
            }
        }
    }, [groupedPredictionsUpdatedTimestamp]);

    useEffect(() => {
        onNextPredictionToLock(nextPredictionToLock);
    }, [nextPredictionToLock]);

    const onCountdownUpdatedWrapper = (countdown?: number) => {
        if (nextPredictionToLock) {
            onCountdownUpdated(nextPredictionToLock, countdown);
        }
    };

    const onCountdownExpired = () => {
        if (!nextPredictionToLock) {
            // This would be unexpected
            return;
        }

        onCountdownUpdatedWrapper();

        onPredictionLocked(nextPredictionToLock);
    };
    //TODO - URGENT: Needs to be fixed
    // return <></>;
    return nextPredictionToLock ? (
        <TimeUntilLock
            countdownDate={nextPredictionToLock.lockDate.toDate()}
            onCountdownExpired={onCountdownExpired}
            onCountdownUpdated={onCountdownUpdatedWrapper}
        />
    ) : (
        <></>
    );
}

function determineNextPredictionToLock(groupedPredictions: GroupedPredictions) {
    const activePredictions = [
        ...groupedPredictions.live.automatic,
        ...groupedPredictions.live.manual,
    ];

    let nextPredictionToLock;
    if (activePredictions[0] && activePredictions[0].entities[0]) {
        nextPredictionToLock = activePredictions[0].entities[0];
    }

    return nextPredictionToLock;
}

function checkForResolvedPredictions(
    previousPredictions: PredictionModel[],
    currentPredictions: PredictionModel[],
) {
    return currentPredictions.filter((updatedPrediction) => {
        const oldPrediction = previousPredictions.find(
            (p) => p.id === updatedPrediction.id,
        );
        return (
            oldPrediction &&
            !oldPrediction.resolved &&
            updatedPrediction.resolved
        );
    });
}
