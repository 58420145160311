import React from 'react';
import { OrderedMap } from 'immutable';
import { Redirect, Route, Switch } from 'react-router-dom';
import AccountPage from 'src/components/AccountPage';
import routeUrls from 'src/routes';
import HomePage from './HomePage';
import LeaderboardSummaryPage from './LeaderboardSummaryPage';
import Onboarding from 'src/components/pistachioGame/onboarding/Onboarding';
import PrivateRoute from 'src/components/shared/PrivateRoute';
import PistachioTitlebar from 'src/components/pistachioGame/PistachioTitlebar';
import { TranslatedIteration, useTranslatedHtmlTitle } from 'src/util/i18n';
import { EventModel } from 'src/util/TallyFirestore';
import { Leaderboards } from '../../hooks/useLiveEntityLeaderboards';
import GroupPage from 'src/components/GroupPage';
import { StickyContainer } from '../components';
import { usePartner } from 'src/hooks/usePartner';
import { Color } from '../../styles/Constants';

type Props = {
    iteration: TranslatedIteration;
    events: OrderedMap<string, EventModel>;
    iterationLeaderboards?: Leaderboards;
};

const MultiEvent = ({ iteration, events, iterationLeaderboards }: Props) => {
    const partner = usePartner();

    useTranslatedHtmlTitle({
        iterationSocialShare: iteration.socialShare,
        partnerSocialShare: partner.properties.socialShare,
    });

    const eventBackgroundColor =
        partner.properties.homePageContent?.backgroundColor;

    document.body.style.backgroundColor =
        eventBackgroundColor || Color.G10_WHITISH;

    return (
        <>
            <StickyContainer>
                <PistachioTitlebar iteration={iteration} />
            </StickyContainer>
            <Switch>
                <Route path={routeUrls.play}>
                    <HomePage iteration={iteration} events={events} />
                </Route>
                <Route path={routeUrls.onboarding}>
                    <Onboarding partner={partner} iteration={iteration} />
                </Route>
                {!partner.properties.hideLeaderboards && (
                    <Route path={`${routeUrls.leaderboardSummary}/:tabPanelId`}>
                        <LeaderboardSummaryPage
                            iterationLeaderboards={iterationLeaderboards}
                            iteration={iteration}
                            events={events}
                        />
                    </Route>
                )}
                <PrivateRoute path={routeUrls.group}>
                    <GroupPage iteration={iteration} />
                </PrivateRoute>
                <PrivateRoute path={routeUrls.account}>
                    <AccountPage iteration={iteration} />
                </PrivateRoute>
                <Route>
                    <Redirect to={routeUrls.play} />
                </Route>
            </Switch>
        </>
    );
};

export default MultiEvent;
