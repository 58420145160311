import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import css from '@emotion/css';
import { Color, StyleDefaults } from 'src/styles/Constants';
import TouchButton from 'src/components/shared/controls/TouchButton';
import { ModalPresentationDetails } from './ModalHoster';
import { CloseButtonColor } from '../controls/CloseButton';

interface Props {
    requestClose: () => void;
}

export default class IncognitoModal extends PureComponent<Props> {
    public static getPresentationDetails = (): ModalPresentationDetails => {
        return {
            backgroundColor: Color.P1_WHITE,
            closeIconColor: CloseButtonColor.BLACK,
        };
    };

    public render() {
        const { requestClose } = this.props;

        return (
            <Container>
                <TagLineText>
                    <FormattedMessage id="incognitoModal.tagLineText" />
                </TagLineText>
                <HeaderText>
                    <FormattedMessage id="incognitoModal.headerText" />
                </HeaderText>
                <BodyText>
                    <FormattedMessage id="incognitoModal.bodyText" />
                </BodyText>
                <CloseButtonContainer>
                    <TouchButton
                        backgroundColor={Color.T31_BLACK}
                        textColor={Color.P1_WHITE}
                        displayText={
                            <FormattedMessage id="incognitoModal.buttonText" />
                        }
                        onClick={requestClose}
                        stretchWidth={true}
                    />
                </CloseButtonContainer>
            </Container>
        );
    }
}

const Container = styled.div`
    color: ${Color.T31_BLACK};
    display: flex;
    text-align: left;
    flex-direction: column;
    padding: ${StyleDefaults.MODAL_BODY_PADDING};
`;

const TagLineText = styled.div`
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 18px;
    text-transform: upperCase;
`;

const HeaderText = styled.div`
    font-size: 35px;
    letter-spacing: -0.6px;
    line-height: 37px;
    font-weight: 900;
    margin: 0 0 20px 0;
`;

const BodyTextStyling = css`
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 18px;
`;

const BodyText = styled.div`
    ${BodyTextStyling}
`;

const CloseButtonContainer = styled.div`
    width: 100%;
    margin: 40px 0 20px 0;
`;
