import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import ServerApi, {
    EventFeedResponse,
    FeedPrediction,
    FeedPredictionAnswerState,
    FeedLeaderboardEntry,
} from 'src/services/ServerApi';
import Logger from 'src/util/Logger';
import { Color } from 'src/styles/Constants';
import BlazersTvRow from './BlazersTvRow';
import bgImage from 'src/images/icons/blazers-pattern@5x.png';
import logo from 'src/images/icons/Fanzone_Logo_WorkmarkB.png';

interface Props {
    path: string;
}

interface State {
    loading: boolean;
    eventFeedResponse?: EventFeedResponse;
}

const TITLE = 'Tally FANZONE';
const UNITED_FONT = 'Graphik Web';
const MAX_PLAYERS = 5;
export const HIGHLIGHT_1 = '#023777';
export const HIGHLIGHT_2 = '#D63347';
export const RANK_WIDTH = '125px';
export const POINTS_CORRECT_WIDTH = '260px';
export const MARGIN_RIGHT = '30px';

export default class TVFanzoneView extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            eventFeedResponse: undefined,
            loading: true,
        };
        const { path } = this.props;
        const groupId = new URLSearchParams(window.location.search).get('g');
        if (!groupId) {
            console.log('Group ID missing');
            // &g=[groupId]
            return;
        }

        const eventId =
            new URLSearchParams(window.location.search).get('eid') ||
            path.split('/').pop();

        if (!eventId) {
            console.log('Event ID missing');
            return;
        }
        this.refreshEventFeed(eventId, groupId);
        document.title = TITLE;
        document.body.style.backgroundColor = Color.T31_BLACK;

        setInterval(() => {
            this.refreshEventFeed(eventId, groupId!);
        }, 10000);
    }

    // TODO: Should this automatically refresh or just refresh on load?
    // Safer to do on load in case a bad thing happens on refresh.
    private refreshEventFeed = async (eventId: string, groupId?: string) => {
        try {
            const eventFeedResponse = (await ServerApi.getFeed(
                eventId,
                groupId,
            )) as EventFeedResponse;
            this.setState({
                eventFeedResponse,
                loading: false,
            });
        } catch (error) {
            Logger.error(`Error refreshing event feed: ${error}`);
        }
    };

    private getValidatedPredictionCount = (
        predictions: FeedPrediction[],
    ): number => {
        const answeredPredictions = predictions.filter(
            (prediction) =>
                prediction.state === FeedPredictionAnswerState.ANSWERED,
        );

        return answeredPredictions.length;
    };

    private renderHeader = () => {
        const { eventFeedResponse } = this.state;
        if (!eventFeedResponse) {
            console.log('No event feed available');
            return null;
        }
        const { name } = eventFeedResponse;
        const { leaderboard } = eventFeedResponse;
        //const {groupDetail} = eventFeedResponse;
        return (
            <HeaderContainer>
                <GameInfoContainer>
                    <LogoImage />
                    <HeadLineContainer>
                        <Headline>{name}</Headline>
                        <SubHeadline>
                            @{' '}
                            {eventFeedResponse.groupDetails &&
                                eventFeedResponse.groupDetails.name}
                        </SubHeadline>
                    </HeadLineContainer>
                    {eventFeedResponse.groupDetails &&
                        eventFeedResponse.groupDetails.groupConfig &&
                        eventFeedResponse.groupDetails.groupConfig.groupQR &&
                        leaderboard &&
                        leaderboard.length > 0 &&
                        leaderboard.filter((row) => row.rank == 1)[0].points >
                            0 && (
                            <QRImage
                                src={
                                    eventFeedResponse.groupDetails.groupConfig
                                        .groupQR
                                }
                            />
                        )}
                </GameInfoContainer>
            </HeaderContainer>
        );
    };

    private renderTableHeader = () => {
        return (
            <StatHeaderRow>
                <StatColumnContainer width={RANK_WIDTH}>
                    <StatName>
                        <FormattedMessage id="fanzone.tvView.lbHeader.rank" />
                    </StatName>
                </StatColumnContainer>
                <StatColumnContainer flexVal={2}>
                    <StatName>
                        <FormattedMessage id="fanzone.tvView.lbHeader.player" />
                    </StatName>
                </StatColumnContainer>
                <StatColumnContainer width={POINTS_CORRECT_WIDTH}>
                    <StatName>
                        <FormattedMessage id="fanzone.tvView.lbHeader.points" />
                    </StatName>
                </StatColumnContainer>
                <StatColumnContainer
                    width={POINTS_CORRECT_WIDTH}
                    noRightMargin={true}
                >
                    <StatName>
                        <FormattedMessage id="fanzone.tvView.lbHeader.correct" />
                    </StatName>
                </StatColumnContainer>
            </StatHeaderRow>
        );
    };

    private renderLeaderboard = (entries: FeedLeaderboardEntry[]) => {
        const { eventFeedResponse } = this.state;

        if (!eventFeedResponse) {
            return null;
        }

        const { predictions } = eventFeedResponse;
        const validatedQuestionCount =
            this.getValidatedPredictionCount(predictions);

        let playerCount = 0;
        const topFive = [];

        for (const entry of entries) {
            if (playerCount >= MAX_PLAYERS) {
                break;
            }
            topFive.push(entry);
            playerCount += entry.playerCount;
        }

        return topFive.map((entry, index: number) => (
            <BlazersTvRow
                fontFamily={UNITED_FONT}
                entry={entry}
                key={index}
                validatedQuestionCount={validatedQuestionCount}
            />
        ));
    };

    private renderJoinTheGame = () => {
        const { eventFeedResponse } = this.state;
        if (!eventFeedResponse) {
            console.log('No event feed available');
            return null;
        }
        const { name } = eventFeedResponse;
        const groupName =
            (eventFeedResponse.groupDetails &&
                eventFeedResponse.groupDetails &&
                eventFeedResponse.groupDetails.name) ||
            'your friends';
        return (
            <BodyContainer>
                <BodyMsgContainer>
                    <BodyPromo>
                        <FormattedMessage
                            id="fanzone.tvView.body.preGameMsg"
                            values={{ game: name, groupName: groupName }}
                        />
                    </BodyPromo>
                    <BodyPromo color={HIGHLIGHT_2}>
                        <FormattedMessage id="fanzone.tvView.body.preGameCTA" />
                    </BodyPromo>
                </BodyMsgContainer>
                {eventFeedResponse.groupDetails &&
                    eventFeedResponse.groupDetails.groupConfig &&
                    eventFeedResponse.groupDetails.groupConfig.groupQR && (
                        <QRImage2
                            src={
                                eventFeedResponse.groupDetails.groupConfig
                                    .groupQR
                            }
                            size="290px"
                        />
                    )}
            </BodyContainer>
        );
    };

    public renderRanking = (leaderboard: FeedLeaderboardEntry[]) => {
        return (
            <div>
                {/* TODO: remove disclaimer when we change the group leaderboards publishing issue is fixed */}
                <Disclaimer>
                    Ranks change until game is final. Must be present to win.
                </Disclaimer>
                {this.renderTableHeader()}
                {this.renderLeaderboard(leaderboard)}
            </div>
        );
    };
    public render() {
        const { eventFeedResponse } = this.state;
        if (!eventFeedResponse) {
            return null;
        }
        const { leaderboard } = eventFeedResponse;
        return (
            <PageContainer>
                <ContentContainer>
                    {this.renderHeader()}
                    {leaderboard &&
                    leaderboard.length > 0 &&
                    leaderboard.filter((row) => row.rank == 1)[0].points > 0
                        ? this.renderRanking(leaderboard)
                        : this.renderJoinTheGame()}
                </ContentContainer>
            </PageContainer>
        );
    }
}

const GameInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 145px;
`;

const PageContainer = styled.div`
    background-image: url(${bgImage});
    background-position: 100% 60px;
    background-size: 30px;
    background-repeat: no-repeat;
    display: flex;
    font-family: ${UNITED_FONT};
    justify-content: center;
    padding: 0 0 60px 0;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 1200px;
    max-width: 1600px;
`;

const BodyContainer = styled.div`
    display: flex;
    flex-flow: row;
    height: 400px;
    vertical-align: middle;
    margin: 200px 0 0 0;
`;

const BodyMsgContainer = styled.div`
    display: flex;
    flex-flow: column;
`;
const BodyPromo = styled.p<{ color?: string }>`
    color: ${(props) => props.color || Color.P1_WHITE};
    font-family: ${UNITED_FONT};
    font-weight: bold;
    font-size: 56px;
    letter-spacing: 5px;
    text-align: left;
    margin: 10px 0 50px 0;
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 0 35px 0;
`;

const HeadLineContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 60%;
    max-width: 1000px;
    margin: 0 20px 0 20px;
`;

const LogoImage = styled.div`
    background-image: url(${logo});
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 0 0 0;
    height: 145px;
    width: 435px;
`;

const QRImage2 = styled.img<{ src: string; size?: string }>`
    margin: 0 0 0 100px;
    height: ${(props) => props.size};
    width: ${(props) => props.size};
`;
const QRImage = styled.div<{ src: string }>`
    background-image: url(${(props) => props.src});
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 0 0 0;
    height: 145px;
    width: 145px;
`;

const Headline = styled.p`
    color: ${HIGHLIGHT_1};
    font-family: ${UNITED_FONT};
    font-weight: bold;
    font-size: 42px;
    letter-spacing: 5px;
    text-align: center;
    text-transform: upperCase;
    margin: 10px 0 0 0;
`;

const SubHeadline = styled.p`
    color: ${HIGHLIGHT_2};
    font-family: ${UNITED_FONT};
    font-weight: bold;
    font-size: 30px;
    letter-spacing: 5px;
    text-align: center;
    margin: 10px 0 0 0;
`;

const Disclaimer = styled.p`
    color: ${Color.P1_WHITE};
    font-family: ${UNITED_FONT};
    font-size: 20px;
    text-align: center;
    margin: 10px 0 0 0;
`;
const StatHeaderRow = styled.div`
    display: flex;
    justify-content: space-between;
    text-align: left;
    width: 100%;
    margin: 50px 0 0 0;
`;

const StatColumnContainer = styled.div<{
    flexVal?: number;
    width?: string;
    noRightMargin?: boolean;
}>`
    border: 2px solid ${HIGHLIGHT_1};
    display: flex;
    flex-direction: column;
    height: 58px;
    justify-content: center;
    min-width: ${(props) => props.width};
    flex: ${(props) => props.flexVal};
    margin-right: ${(props) => (props.noRightMargin ? 0 : MARGIN_RIGHT)};
    text-align: left;
`;

const StatName = styled.p`
    color: ${Color.P1_WHITE};
    font-size: 42px;
    font-weight: bold;
    letter-spacing: 5px;
    padding: 18px 0px 18px 14px;
    text-transform: uppercase;
`;
