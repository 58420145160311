import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useRef } from 'react';
import { useLocale } from 'src/hooks/useLocale';
import languageIcon from 'src/images/icons/method-draw-image.svg';
import { Color } from 'src/styles/Constants';
import { LocaleId } from 'src/util/LocaleHelpers';
import { languageNames } from 'src/locales';
import config from 'src/util/Config';

const { supportedLanguages } = config.partnerData.properties;

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    menuButton: {
        color: Color.P1_WHITE,
        fontFamily: 'Graphik Web',
        fontSize: 16,
    },
    menuItem: {
        fontFamily: 'Graphik Web',
    },
    image: {
        marginRight: 4,
        height: 20,
        width: 20,
    },
});

const LanguageMenu = () => {
    const classes = useStyles();
    const anchorRef = useRef<null | HTMLDivElement>(null);
    const { localeId, setLocaleId } = useLocale();

    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleLocaleChange = (code: LocaleId) => {
        return (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
            e.stopPropagation();
            setLocaleId(code);
            handleClose();
        };
    };

    return supportedLanguages && supportedLanguages.length > 1 ? (
        <div className={classes.root} ref={anchorRef}>
            <IconButton
                className={classes.menuButton}
                aria-controls="language-menu"
                aria-haspopup="true"
                onClick={() => setOpen(true)}
            >
                <img className={classes.image} src={languageIcon} />
                {localeId}
            </IconButton>
            <Menu
                id="language-menu"
                disableScrollLock
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                anchorEl={anchorRef.current}
                getContentAnchorEl={null}
                keepMounted
                open={open}
                onClose={handleClose}
            >
                {supportedLanguages.map((code: LocaleId): JSX.Element => {
                    return (
                        <MenuItem
                            key={code}
                            className={classes.menuItem}
                            onClick={handleLocaleChange(code)}
                            selected={localeId === code}
                        >
                            {languageNames.get(code)}
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    ) : null;
};

export default LanguageMenu;
