import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from '@emotion/styled';
import TouchButton from 'src/components/shared/controls/TouchButton';
import SquareLoading from 'src/components/shared/animations/SquareLoading';
import TextInput from 'src/components/shared/controls/fields/TextInput';
import { Color, TextStyles, TextStyleTypes } from 'src/styles/Constants';
import TallyMarkdown from 'src/components/shared/ui/TallyMarkdown';
import ServerError from 'src/services/ServerError';
import HelpLinks from 'src/components/shared/ui/HelpLinks';
import InputLabel from 'src/components/shared/ui/InputLabel';
import CheckboxInput from 'src/components/shared/controls/fields/CheckboxInput';
import { EMAIL_REGEX } from 'src/util/validation';
import { ValidationError } from 'src/util/validation';
import { usePartner } from 'src/hooks/usePartner';
import { TileCheckbox } from '../../utils';
import { getContactInfo, useUserState } from 'src/contexts/UserStateContext';
import { BackgroundPanel } from 'src/components/shared/ui/BackgroundPanel';

export type TileData = {
    email?: string;
    [key: string]: string | boolean | undefined;
};

type Props = {
    prizes?: string[];
    checkboxes: TileCheckbox[];
    onContactSupportClick: () => void;
    onFieldSubmit: (data: TileData) => void;
    errors?: { [key: string]: ServerError | Error };
    tileError?: ServerError | Error;
    onError?: (error: ValidationError) => void;
    onClearError?: (field: string) => void;
    screenHasBg?: boolean | false;
};

export default ({
    errors,
    tileError,
    onClearError,
    onContactSupportClick,
    onError,
    onFieldSubmit,
    checkboxes,
    prizes,
    screenHasBg,
}: Props): JSX.Element => {
    const intl = useIntl();
    const partner = usePartner();
    const userData = useUserState();
    const { marketingOptInTitle, marketingOptInMessageOverride } =
        partner.properties;

    const fieldTitle = intl.formatMessage({
        id: 'onboarding.requestEmail.field.title',
    });

    const fieldDescription =
        marketingOptInMessageOverride ||
        intl.formatMessage({
            id:
                prizes && prizes.length > 0
                    ? 'onboarding.requestEmail.bodyWithPrizes.markdown'
                    : 'onboarding.requestEmail.bodyWithoutPrizes.markdown',
        });

    const tileTitle = intl.formatMessage({
        id: 'onboarding.updates.title',
    });

    const getInitialTileData = (): TileData => {
        const contactData = getContactInfo(userData);
        const initialData: TileData = {
            email: contactData && contactData.email,
        };
        checkboxes.forEach(
            ({ field, defaultValue }) => (initialData[field] = defaultValue),
        );
        return initialData;
    };
    const initialTileData = getInitialTileData();

    const [tileData, setValue] = useState<TileData>(initialTileData);
    const [isSubmittingField, setIsSubmittingField] = useState<boolean>(false);

    const shouldShowGeneralTileError = () =>
        tileError && !(errors && errors.email);

    useEffect(() => {
        (tileError || (errors && errors.length)) &&
            isSubmittingField &&
            setIsSubmittingField(false);
    }, [isSubmittingField, tileError, errors]);

    const disableSubmit =
        checkboxes.some(
            ({ mandatory, field }) => mandatory && !tileData[field],
        ) || //some mandatory checkbox is not checked
        Boolean(errors && Object.getOwnPropertyNames(errors).length) || //some error regarding existing fields
        !Boolean(tileData.email) || //email field is not set
        Boolean(tileError); //error for the entire tile

    const checkboxesToRender = checkboxes.map(({ label, field }) => {
        return (
            <CheckboxContainer key={field + label}>
                <CheckboxInput
                    field={field}
                    onChange={() => {
                        setValue((previous: TileData) => ({
                            ...previous,
                            [field]: !previous[field],
                        }));
                    }}
                    value={Boolean(tileData[field])}
                    label={label}
                />
            </CheckboxContainer>
        );
    });

    return (
        <BackgroundPanel screenHasBg={screenHasBg} topConnect={true}>
            <TileTitle color={Color.T31_BLACK}>{tileTitle}</TileTitle>
            {fieldDescription && (
                <DescriptionText>
                    <TallyMarkdown source={fieldDescription} />
                </DescriptionText>
            )}
            <TextInput
                field="email"
                disabled={false}
                onChange={(value: string) =>
                    setValue((previous: TileData) => ({
                        ...previous,
                        email: value,
                    }))
                }
                onClear={() =>
                    setValue((previous: TileData) => ({
                        ...previous,
                        email: '',
                    }))
                }
                error={errors && errors.email}
                label={fieldTitle}
                onContactSupportClick={onContactSupportClick}
                validation={EMAIL_REGEX}
                validationErrorText={intl.formatMessage({
                    id: 'onboarding.invalidEmail',
                })}
                onError={onError}
                onClearError={onClearError}
                validateOnBlurOnly={false}
                initialValue={tileData.email}
            />
            {checkboxesToRender && checkboxesToRender.length && (
                <OptInContainer>
                    {marketingOptInTitle && (
                        <TallyMarkdown source={marketingOptInTitle} />
                    )}
                    <CheckboxContainer>{checkboxesToRender}</CheckboxContainer>
                </OptInContainer>
            )}

            {shouldShowGeneralTileError() && (
                <>
                    <InputLabel error={tileError} />
                    <HelpLinks onContactSupportClick={onContactSupportClick} />
                </>
            )}
            <ButtonContainer>
                <TouchButton
                    disabled={disableSubmit}
                    displayText={
                        <FormattedMessage id="infoCollectionTitle.touchButtonLabel" />
                    }
                    backgroundColor={Color.T31_BLACK}
                    textColor={Color.P1_WHITE}
                    onClick={async () => {
                        console.log('oops');
                        setIsSubmittingField(true);
                        onFieldSubmit({
                            ...tileData,
                            email: tileData.email,
                        });
                    }}
                />
            </ButtonContainer>
            <LoadingContainer>
                {isSubmittingField && (
                    <SquareLoading color={Color.P6_ELECTRIC_BLUE} />
                )}
            </LoadingContainer>
        </BackgroundPanel>
    );
};

const TileTitle = styled.div`
    ${TextStyles[TextStyleTypes.H3]}
    border-bottom: 2px solid ${Color.G8_CONCRETE};
    text-align: left;
    width: 100%;
`;

const ButtonContainer = styled.div`
    padding: 25px 0 15px 0;
`;

const DescriptionText = styled.div`
    margin: 15px 0 30px 0;
    ${TextStyles[TextStyleTypes.P1]}
    text-align: left;
    width: 100%;
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 20px;
    margin: 20px 0;
`;

const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
`;

const OptInContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 30px 0 5px 0;
`;
