import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { TextStyles, TextStyleTypes } from 'src/styles/Constants';
import ClipLoader from 'react-spinners/ClipLoader';
import { DebouncedFunc } from 'lodash';
import TextInput from 'src/components/shared/controls/fields/TextInput';
import { SearchVenueByNameResult } from 'src/services/ServerApi';

import VenueTable from './VenueTable';

type Props = {
    venueList: SearchVenueByNameResult[] | null;
    onJoinGroup: (groupId: string) => void;
    pendingTextSearch: boolean;
    setVenueList: React.Dispatch<
        React.SetStateAction<SearchVenueByNameResult[] | null>
    >;
    onChange: DebouncedFunc<(value: string) => Promise<void>>;
    pendingGroupJoin: boolean;
};

export default (props: Props) => {
    const intl = useIntl();
    const {
        onChange,
        setVenueList,
        pendingTextSearch,
        venueList,
        onJoinGroup,
        pendingGroupJoin,
    } = props;
    return (
        <>
            <TextSearchContainer>
                <TextInput
                    field="searchString"
                    onChange={onChange}
                    onClear={() => {
                        setVenueList(null);
                    }}
                    label={intl.formatMessage({
                        id: 'infoCollectionTitle.searchByNameLabel',
                    })}
                    validateOnBlurOnly={false}
                    placeHolder={intl.formatMessage({
                        id: 'infoCollectionTitle.testSearchplaceholder',
                    })}
                />
            </TextSearchContainer>
            {pendingTextSearch ? (
                <SpinnerContainer>
                    <ClipLoader size={20} loading={true} center={true} />
                </SpinnerContainer>
            ) : venueList === null ? null : venueList.length === 0 ? (
                <DescriptionText>
                    {intl.formatMessage({
                        id: 'infoCollectionTitle.noTextSearchResults',
                    })}
                </DescriptionText>
            ) : (
                <>
                    <DescriptionText>
                        <FormattedMessage
                            id={
                                'infoCollectionTitle.groupModeDefaultDescription'
                            }
                        />
                    </DescriptionText>
                    <VenueTable
                        venues={venueList}
                        actionFunction={onJoinGroup}
                        pendingGroupJoin={pendingGroupJoin}
                    />
                </>
            )}
        </>
    );
};

const DescriptionText = styled.div`
    margin: 15px 0 15px 0;
    ${TextStyles[TextStyleTypes.P1]}
    text-align: left;
    width: 100%;
`;

const TextSearchContainer = styled.div`
    margin: 0px 0 30px 0;
    padding: 30px 0 0 0;
`;

const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 30px 0 50px 0;
    flex-direction: row;
`;
