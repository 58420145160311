import { TranslatableEventModel } from 'src/util/TallyFirestore';
import { getEventFromDocumentSnapshot } from 'src/util/FirestoreDao';
import useLiveFirestoreDocument from '../../../hooks/useLiveFirestoreDocument';

const useLiveEvent = (initialEvent: TranslatableEventModel) => {
    return useLiveFirestoreDocument<TranslatableEventModel>(
        initialEvent.path,
        initialEvent,
        getEventFromDocumentSnapshot,
    );
};

export default useLiveEvent;
