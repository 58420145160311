import React, { Component } from 'react';
import styled from '@emotion/styled';
import { keyframes } from 'emotion';

interface Props {
    resource: string;
    pulseActive?: boolean;
}

export default class Pulsate extends Component<Props> {
    public render() {
        const { resource, pulseActive = true } = this.props;
        return (
            <Container>
                <Graphic backgroundImage={resource} />
                {pulseActive && <PulsatingGraphic backgroundImage={resource} />}
            </Container>
        );
    }
}

const ExpandAnimation = keyframes`
    0% {
        opacity: 0.8;
    }
    100% {
        transform: scale(3.5);
        opacity: 0;
    }
`;

const Container = styled.div`
    position: relative;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4px;
`;

const Graphic = styled.div<{ backgroundImage: string }>`
    position: absolute;
    background-image: url(${(props) => props.backgroundImage});
    background-size: 26px;
    background-repeat: no-repeat;
    height: 26px;
    width: 26px;
`;

const PulsatingGraphic = styled(Graphic)`
    animation: ${ExpandAnimation} 2s infinite;
`;
