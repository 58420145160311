import React, { useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from '@emotion/styled';
import { ColorMode } from 'src/styles/Constants';

interface Props {
    barBackgroundColorMode: ColorMode;
    barColor: string;
    timeLeftCount?: number;
}

// the threshold in seconds at which the bar starts to drain.
const ANIMATED_COUNTDOWN_THREASHOLD_SECONDS = 30;

export default function AnimatedCountdownBar(props: Props) {
    const { barBackgroundColorMode, barColor, timeLeftCount } = props;

    const [width, setWidth] = useSpring(() => {
        // a little hack during initial mount that advances the animation bar
        // to the proper initial second. gets wiped away almost immediately on next render cycle.

        const initialWidth = calculateNextWidth(
            timeLeftCount !== undefined ? timeLeftCount + 1 : timeLeftCount,
        );
        return {
            config: {
                duration: 0,
            },
            width: `${initialWidth}%`,
        };
    });

    useEffect(() => {
        const nextWidth = calculateNextWidth(timeLeftCount);
        setWidth({
            config: {
                duration: 1000,
            },
            width: `${nextWidth}%`,
        });
    }, [timeLeftCount]);

    return (
        <BarContainer>
            <BarBackground
                backgroundColor={barColor}
                colorMode={barBackgroundColorMode}
            />
            <Bar style={width} backgroundColor={barColor} />
        </BarContainer>
    );
}

function calculateNextWidth(timeLeftCount?: number) {
    if (
        timeLeftCount !== undefined &&
        timeLeftCount < ANIMATED_COUNTDOWN_THREASHOLD_SECONDS
    ) {
        return timeLeftCount > 0
            ? (timeLeftCount / ANIMATED_COUNTDOWN_THREASHOLD_SECONDS) * 100
            : 0;
    } else {
        return 100;
    }
}

const BarContainer = styled.div`
    position: relative;
    flex: 1;
    height: 100%;
`;

const BarBackground = styled(({ backgroundColor, colorMode, ...rest }) => (
    <div {...rest} />
))<{
    backgroundColor: string;
    colorMode: ColorMode;
}>`
    display: flex;
    flex: 1;
    height: 100%;
    position: absolute;
    width: 100%;
    background-color: ${(props) => props.backgroundColor};
    opacity: ${(props) => (props.colorMode === ColorMode.DARK ? '1' : '0.3')};
    filter: brightness(
        ${(props) => (props.colorMode === ColorMode.DARK ? '30%' : '100%')}
    );
`;

const Bar = styled(({ backgroundColor, ...rest }) => (
    <animated.div {...rest} />
))<{
    backgroundColor: string;
}>`
    display: flex;
    height: 100%;
    position: absolute;
    filter: brightness(100%);
    left: 0;
    background-color: ${(props) => props.backgroundColor};
`;
