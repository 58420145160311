import React, { useRef } from 'react';

import { getIframeAspectRatio } from 'src/util/Iframe';
import { AdBase } from 'src/util/TallyFirestore';
import { useAnalytics } from 'src/contexts/AnalyticsContext';
import { AppArea, EventName } from 'src/util/AnalyticsConstants';
import AdIframe from './AdIframe';
import {
    AdContainer,
    AdHeadline,
    AspectRatioContainer,
    Disclaimer,
} from './shared';

type Props = {
    eventId: string;
    borderSizePx?: number;
    textAlign?: string;
    width?: string;
} & AdBase;

const AspectRatioIframeAd = ({
    eventId,
    iframeHtml,
    headline,
    disclaimer,
    borderSizePx,
    textAlign,
    width = '100%',
}: Props) => {
    const analytics = useAnalytics();

    const aspectRatio = getIframeAspectRatio(iframeHtml);

    const ref = useRef<HTMLDivElement>(null);

    const onIframeLoad = () => {
        analytics.logEvent({
            eventName: EventName.iframeSeen,
            params: {
                event: eventId,
                ad_headline: headline,
            },
            eventCategory: AppArea.game,
        });
    };

    return (
        <AdContainer ref={ref} centerItems={true} textAlign={textAlign}>
            <div
                style={{
                    width: width,
                    ...(aspectRatio ? { maxWidth: aspectRatio.width } : {}),
                }}
            >
                {headline && <AdHeadline>{headline}</AdHeadline>}
                <AspectRatioContainer
                    aspectRatio={aspectRatio}
                    borderSizePx={borderSizePx}
                >
                    <AdIframe iframeHtml={iframeHtml} onLoad={onIframeLoad} />
                </AspectRatioContainer>
                {disclaimer && <Disclaimer>{disclaimer}</Disclaimer>}
            </div>
        </AdContainer>
    );
};

export default AspectRatioIframeAd;
