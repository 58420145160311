import React, {
    useCallback,
    useEffect,
    createContext,
    ReactNode,
    useContext,
} from 'react';
import { Group, TeamGroup, UserGroup, VenueGroup } from 'src/types';
import ServerError from 'src/services/ServerError';
import { ActionTypes as Types, useGroupReducer } from './useGroupReducer';
import { usePartner } from 'src/hooks/usePartner';
import ServerApi from 'src/services/ServerApi';
import { addEventSlugSearchParam, getEventSlugSearchParam } from 'src/routes';

export type GroupsState = {
    userGroup: UserGroup | undefined;
    teamGroup: TeamGroup | undefined;
    venueGroup: VenueGroup | undefined;
};

type GroupContextValue = {
    isLoading: boolean;
    groups: GroupsState;
    createGroup: {
        createGroup: (group: { name: string }) => void;
        error: null | Error;
    };
    deleteGroup: {
        deleteGroup: (id: string) => void;
        error: null | Error;
    };
    joinGroup: {
        joinGroup: (partnerId: string, id: string) => void;
        error: null | Error;
    };
    getUserGroups: {
        getUserGroups: (partnerId: string) => void;
        error: null | Error;
    };
};

const dumbFunc = () => {};

const GroupContext = createContext<GroupContextValue>({
    isLoading: true,
    groups: {
        userGroup: undefined,
        teamGroup: undefined,
        venueGroup: undefined,
    },
    createGroup: {
        createGroup: dumbFunc,
        error: null,
    },
    deleteGroup: {
        deleteGroup: dumbFunc,
        error: null,
    },
    joinGroup: {
        joinGroup: dumbFunc,
        error: null,
    },
    getUserGroups: {
        getUserGroups: dumbFunc,
        error: null,
    },
});

const GroupContextProvider = GroupContext.Provider;

type Props = {
    children: ReactNode;
    iterationId: string;
};

export const getInviteLink = (location: Location, group: Group): string => {
    const inviteLinkBase = `${location.protocol}//${location.host}/groups/${group.id}`;
    const eventSlug = getEventSlugSearchParam(location.search);

    if (eventSlug) {
        return `${inviteLinkBase}?${addEventSlugSearchParam(
            location.search,
            eventSlug,
        )}`;
    }

    return inviteLinkBase;
};

export const GroupConsumer = GroupContext.Consumer;

const getSortedGroups = (groups: Group[]) => {
    const userGroup = groups.find((g) => g.groupType === 'USER_GROUP');
    const teamGroup = groups.find((g) => g.groupType === 'TEAM');
    const venueGroup = groups.find((g) => g.groupType === 'VENUE');

    return {
        userGroup: userGroup ? (userGroup as UserGroup) : undefined,
        teamGroup: teamGroup ? (teamGroup as TeamGroup) : undefined,
        venueGroup: venueGroup ? (venueGroup as VenueGroup) : undefined,
    };
};

export const GroupProvider = ({ iterationId, children }: Props) => {
    const [state, dispatch] = useGroupReducer();
    const partner = usePartner();
    const partnerId = partner.partnerId;

    const getUserGroups = () => {
        ServerApi.getUserGroups(partnerId, iterationId)
            .then((groups) => {
                dispatch({
                    type: Types.GET_GROUPS_SUCCESS,
                    payload: {
                        groups: getSortedGroups(groups),
                    },
                });
            })
            .catch((error) => {
                if (error instanceof ServerError && error.status === 404) {
                    dispatch({
                        type: Types.GET_GROUPS_SUCCESS,
                        payload: {
                            groups: getSortedGroups([]),
                        },
                    });
                } else {
                    dispatch({
                        type: Types.GET_GROUPS_FAILURE,
                        payload: { error },
                    });
                }
            });
    };

    const getUserGroupsCallback = useCallback(getUserGroups, [
        partnerId,
        iterationId,
        dispatch,
    ]);

    useEffect(() => getUserGroupsCallback(), [getUserGroupsCallback]);

    return (
        <GroupContextProvider
            value={{
                isLoading: state.isLoading,
                createGroup: {
                    ...state.createGroup,
                    createGroup: ({ name }) => {
                        dispatch({
                            type: Types.CREATE_GROUP_REQUEST,
                        });
                        ServerApi.createGroup({ partnerId, name })
                            .then((newGroup) => {
                                dispatch({
                                    type: Types.CREATE_GROUP_SUCCESS,
                                    payload: { group: newGroup },
                                });
                            })
                            .catch((error) =>
                                dispatch({
                                    type: Types.CREATE_GROUP_FAILURE,
                                    payload: { error },
                                }),
                            );
                    },
                },
                deleteGroup: {
                    ...state.deleteGroup,
                    deleteGroup: (id) => {
                        dispatch({ type: Types.DELETE_GROUP_REQUEST });
                        ServerApi.deleteGroup({ partnerId, id })
                            .then(() =>
                                dispatch({
                                    type: Types.DELETE_GROUP_SUCCESS,
                                }),
                            )
                            .catch((error) =>
                                dispatch({
                                    type: Types.DELETE_GROUP_FAILURE,
                                    payload: { error },
                                }),
                            );
                    },
                },
                joinGroup: {
                    ...state.joinGroup,
                    joinGroup: (partnerId, id) => {
                        dispatch({ type: Types.JOIN_GROUP_REQUEST });
                        ServerApi.joinGroup({ partnerId, id })
                            .then((group) =>
                                dispatch({
                                    type: Types.JOIN_GROUP_SUCCESS,
                                    payload: {
                                        group,
                                    },
                                }),
                            )
                            .catch((error) =>
                                dispatch({
                                    type: Types.JOIN_GROUP_FAILURE,
                                    payload: { error },
                                }),
                            );
                    },
                },
                getUserGroups: {
                    ...state.getUserGroups,
                    getUserGroups: getUserGroups,
                },
                groups: state.groups,
            }}
        >
            {children}
        </GroupContextProvider>
    );
};

export const useGroupProvider = () => useContext(GroupContext);
