import theme from 'src/util/Theme';
import {
    CurrentAssignedTriviaQuestion,
    PastAssignedTriviaQuestion,
} from 'src/services/ServerApi';
import { TriviaGameProperties } from 'src/util/TallyFirestore';
import { Color } from 'src/styles/Constants';
import { FormattedMessage } from 'react-intl';

type QuestionResult = { points?: number } | 'incorrect' | 'missed';

type BasicProps = {
    trivia: TriviaGameProperties;
    countdownSeconds?: number;
    screenHasBg?: boolean;
    txtBgOpacity?: number;
    imgDisclaimer?: string;
};

export type InitialProps = BasicProps & {
    question: CurrentAssignedTriviaQuestion;
    onAnswer: (optionId: string) => void;
    loading: false;
};

export type LoadingAnswerProps = BasicProps & {
    question: CurrentAssignedTriviaQuestion;
    selectedOptionId: string | null;
    loading: true;
};

export type AnsweredProps = BasicProps & {
    question: PastAssignedTriviaQuestion;
    points?: number;
    loading: false;
};

export type Props = InitialProps | LoadingAnswerProps | AnsweredProps;

export const isLoadingAnswer = (props: Props): props is LoadingAnswerProps => {
    return props.loading;
};

export const isAnswered = (props: Props): props is AnsweredProps => {
    return !!props.question.answeredDate;
};

export const getBorderColor = (questionResult: QuestionResult): string => {
    return typeof questionResult === 'object'
        ? theme.correctCardColor
        : questionResult === 'incorrect'
        ? theme.incorrectCardColor
        : theme.missedCardColor;
};

export const getQuestionResult = (props: Props): QuestionResult | undefined => {
    if (isAnswered(props)) {
        if (props.question.selectedOptionId === null) {
            return 'missed';
        }
        if (
            props.question.correctOptionId === props.question.selectedOptionId
        ) {
            return { points: props.points };
        }
        return 'incorrect';
    }
};

export const getAnswerStatus = (
    questionResult: QuestionResult,
): JSX.Element | undefined => (
    <FormattedMessage
        id={
            typeof questionResult === 'object'
                ? 'triviaGame.status.correct'
                : questionResult === 'incorrect'
                ? 'triviaGame.status.incorrect'
                : 'triviaGame.status.missed'
        }
    />
);

export const getAnswerStatusColor = (questionResult: QuestionResult): string =>
    typeof questionResult === 'object'
        ? Color.PREDICTION_GREEN_DARK
        : questionResult === 'incorrect'
        ? Color.P4_CHILI_PEPPER
        : Color.T31_BLACK;

export const calculateDecreasingPoints = ({
    countdownSeconds,
    pointsPerQuestion,
    secondsPerQuestion,
}: {
    pointsPerQuestion: number;
    secondsPerQuestion: number;
    countdownSeconds?: number;
}) => {
    return Math.round(
        (1 / 2) *
            pointsPerQuestion *
            (1 +
                (countdownSeconds === undefined
                    ? 1
                    : countdownSeconds / secondsPerQuestion)),
    );
};
