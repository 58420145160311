export enum OfferType {
    GLOBAL = 'GLOBAL',
    ONE_TIME = 'ONE_TIME',
    NO_CODE = 'NO_CODE',
}

export type IsoDateString = string;

// TODO change some fields to optional, add offer_code_type?
export type Offer<TDate = IsoDateString> = {
    id: string;
    type: OfferType;
    name: string;
    displayName: string;
    expirationDate: TDate;
    redemptionCtaUrl?: string;
    redemptionMarkdown: string;
    legalMarkdown: string;
};

export enum EventSponsorshipUnitTemplateId {
    EVENT = 'event',
}

export enum PredictionSponsorshipUnitTemplateId {
    DOUBLE_POINT_POWERUP = 'double_pt_powerup',
    PRIZE_REDEMPTION = 'prize_redemption',
}

export type DoublePointSponsorshipUnit = {
    id: string;
    name: string;
    sponsorId: string;
    templateId: PredictionSponsorshipUnitTemplateId.DOUBLE_POINT_POWERUP;
};

export type PrizeRedemptionProps = {
    tagLine: string;
    asset: string;
};

export type PrizeRedemptionSponsorshipUnit = {
    id: string;
    name: string;
    sponsorId: string;
    templateId: PredictionSponsorshipUnitTemplateId.PRIZE_REDEMPTION;
    offer: Offer<IsoDateString>;
    properties: PrizeRedemptionProps;
    bonusDescription: string | null;
};

export type PredictionSponsorshipUnit =
    | DoublePointSponsorshipUnit
    | PrizeRedemptionSponsorshipUnit;

export const isPrizeRedemptionSponsorshipUnit = (
    sponsorship: PredictionSponsorshipUnit,
): sponsorship is PrizeRedemptionSponsorshipUnit =>
    sponsorship.templateId ===
    PredictionSponsorshipUnitTemplateId.PRIZE_REDEMPTION;

type EventSponsorshipUnitProps = {
    footerAd?: string;
    gameLogoUrl?: string;
    gameWideLogoUrl?: string;
    gameWideBanner: string;
    gameWideBannerLink?: string;
    tileAd?: string;
    presentedBy?: string;
};

export type EventSponsorshipUnit = {
    id: string;
    name: string;
    sponsorId: string;
    templateId: EventSponsorshipUnitTemplateId.EVENT;
    properties: EventSponsorshipUnitProps;
};
