import config from './Config';
import { Color } from 'src/styles/Constants';
import prizeModalBackground from 'src/images/backgrounds/PrizesModal@3x.png';
import seesaw from 'src/images/animations/Seesaw.json';
import { ToastTextColor } from 'src/components/shared/ui/Toast';
import liveIcon from 'src/images/icons/Live-ChiliPepper@3x.svg';
import predictionLiveIcon from 'src/images/icons/Live-White@3x.svg';
import { createTheme, withStyles } from '@material-ui/core';

const seesawColor: number[] | undefined =
    config.partnerData.properties &&
    config.partnerData.properties.seesawLottieColor;

if (seesawColor) {
    // Somewhat hacky, but it works:
    // Replace the colour in each of the layers of the seesaw animation data.
    for (const layer of seesaw.layers) {
        layer.shapes[0].it[1].c!.k = seesawColor;
    }
}
const { partnerData } = config;

class Theme {
    public readonly modalBackgroundColor =
        partnerData.properties.modalBackgroundColor ||
        partnerData.properties.themeHighlightFallbackColor ||
        Color.P6_ELECTRIC_BLUE;

    public readonly newPredictionBackgroundColor =
        partnerData.properties.newPredictionBackgroundColor ||
        Color.P4_CHILI_PEPPER;

    public readonly leaderboardPrizeTextColor =
        partnerData.properties.leaderboardPrizeTextColor ||
        partnerData.properties.themeHighlightFallbackColor ||
        Color.P6_ELECTRIC_BLUE;

    public readonly leaderboardCurrentUserTextColor =
        partnerData.properties.leaderboardCurrentUserTextColor ||
        Color.P4_CHILI_PEPPER;

    public readonly leaderboardHorizontalRuleColor =
        partnerData.properties.leaderboardHorizontalRuleColor ||
        Color.P4_CHILI_PEPPER;

    public readonly navLogoUrl =
        partnerData.properties.navLogoUrl || partnerData.properties.logoUrl;

    public readonly navBarTextColor =
        partnerData.properties.navBarTextColor ||
        partnerData.properties.headingColor ||
        Color.P1_WHITE;

    public readonly navBarBackgroundColor =
        partnerData.properties.navBarBackgroundColor || Color.T31_BLACK;

    public readonly onboardingFooterImageUrl =
        partnerData.properties.onboardingFooterImageUrl;

    public readonly partnerLogoUrl = partnerData.properties.logoUrl;

    // TODO: These are not used. Delete?
    // public readonly partnerOnboardingToastColor =
    //     partnerData.properties.onboardingToastColor;

    // public readonly partnerOnboardingToastTextColor = partnerData.properties
    //     .onboardingToastInvertTextColor
    //     ? ToastTextColor.BLACK
    //     : ToastTextColor.WHITE;

    public readonly prizeModalBackgroundImageUrl =
        partnerData.properties.prizeDetailsModalBackgroundImageUrl ||
        prizeModalBackground;

    public readonly seesawLottieAnimationData = seesaw;

    public readonly stayTunedTileLoadingDotsColor =
        partnerData.properties.stayTunedTileLoadingDotsColor;

    public readonly tournamentHeaderBackgroundColor =
        partnerData.properties.tournamentHeaderBackgroundColor ||
        Color.T31_BLACK;

    public readonly tournamentHeaderGraphicUrl =
        partnerData.properties.tournamentHeaderGraphicUrl;

    public readonly waitingRoomPartnerLogoSize = parseInt(
        partnerData.properties.logoSizeWaitingRoom || '80',
    );

    public readonly geoFencePartnerLogoSize = parseInt(
        partnerData.properties.logoSizeGeoFence || '80',
    );

    public readonly geoFenceBackgroundColor =
        partnerData.properties.geoFenceBackgroundColor || Color.T31_BLACK;

    public readonly loadingScreenLogoSize = parseInt(
        partnerData.properties.logoSizeLoadingScreen || '80',
    );

    public readonly waitingRoomToastBackgroundColor =
        partnerData.properties.waitingRoomToastBackgroundColor ||
        Color.P4_CHILI_PEPPER;

    public readonly waitingRoomToastTextColor = partnerData.properties
        .waitingRoomToastInvertTextColor
        ? ToastTextColor.BLACK
        : ToastTextColor.WHITE;

    public readonly waitingRoomBackgroundColor =
        partnerData.properties.waitingRoomBackgroundColor || Color.T31_BLACK;

    public readonly liveIconUrl =
        partnerData.properties.liveIconUrl || liveIcon;

    public readonly predictionAnimGifUrl =
        partnerData.properties.predictionAnimGifUrl;
    public readonly predictionAnimGifSecs =
        partnerData.properties.predictionAnimGifSecs;

    public readonly unbrandNewPredictions =
        partnerData.properties.unbrandNewPredictions;

    public readonly predictionLiveIcon =
        partnerData.properties.predictionLiveIconUrl || predictionLiveIcon;

    public readonly predictionLiveIconBackgroundColor =
        (partnerData.properties.predictionLiveIconBackgroundColor as Color) ||
        Color.P4_CHILI_PEPPER;

    public readonly predictionAnsweredIconBackgroundColor =
        (partnerData.properties
            .predictionAnsweredIconBackgroundColor as Color) ||
        Color.P6_ELECTRIC_BLUE;

    public readonly nextLockUrgencyColor =
        partnerData.properties.nextLockUrgencyColor || Color.P4_CHILI_PEPPER;

    public readonly disableConsent = partnerData.properties.disableConsent;

    public readonly loadingScreenImageUrl =
        partnerData.properties.loadingScreenImageUrl;

    public readonly themeHighlightFallbackColor =
        partnerData.properties.themeHighlightFallbackColor ||
        Color.P6_ELECTRIC_BLUE;

    public readonly defaultLinkColor =
        partnerData.properties.defaultLinkColor ||
        this.themeHighlightFallbackColor;

    public readonly headingColor =
        partnerData.properties.headingColor || '#3f51b5';

    public readonly correctCardColor = Color.PREDICTION_GREEN;
    public readonly incorrectCardColor = Color.P4_CHILI_PEPPER;
    public readonly missedCardColor = Color.T28_FOG;

    public readonly tallyUnbranded = partnerData.properties.unbrandUI || false;
}

const theme = new Theme();

export const muiTheme = createTheme({
    typography: {
        fontFamily: ['Graphik Web'].join(','),
    },
    palette: {
        secondary: {
            main: theme.headingColor,
        },
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                    backgroundColor: Color.T26_MARBLE,
                },
            },
        },
        // Style sheet name ⚛️
        MuiBottomNavigationAction: {
            // Name of the rule
            label: {
                // Some CSS
                marginTop: 3,
            },
        },
        MuiTypography: {
            h1: {
                color: theme.headingColor,
                textAlign: 'center',
                fontSize: 18,
                letterSpacing: 1,
                fontWeight: 500,
                lineHeight: '30px',
                textTransform: 'uppercase',
            },
            h2: {
                textAlign: 'center',
                fontSize: 13,
                letterSpacing: 1,
                fontWeight: 500,
                lineHeight: '22px',
                margin: '16px 0',
                textTransform: 'uppercase',
            },
        },
    },
});

export const GlobalCss = withStyles({
    // @global is handled by jss-plugin-global.
    // '@global': {
    //   // You should target [class*="MuiButton-root"] instead if you nest themes.
    //   '.MuiTab-root.Mui-selected': {
    //     fontWeight: 550,
    //   },
    // },
})(() => null);

export default theme;
