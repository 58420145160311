import { useEffect, useState } from 'react';
import firebase, { DocumentSnapshot } from 'src/services/Firebase';

function useLiveFirestoreDocument<T>(
    path: string | undefined,
    initialState: T,
    getDataFromSnapshot?: (snapshot: DocumentSnapshot) => T | undefined,
) {
    const [state, setState] = useState<T>(initialState);

    useEffect(() => {
        setState(initialState);
        if (path) {
            const unsubscribe = firebase
                .firestore()
                .doc(path)
                .onSnapshot((snapshot: DocumentSnapshot) => {
                    const item = getDataFromSnapshot
                        ? getDataFromSnapshot(snapshot)
                        : (snapshot.data() as T | undefined);
                    if (item) {
                        setState(item);
                    }
                });

            return unsubscribe;
        }
    }, [initialState, path]);

    return state;
}

export default useLiveFirestoreDocument;
