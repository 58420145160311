import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import css from '@emotion/css';

import { ModalPresentationDetails, ModalType } from './ModalHoster';
import { Color, StyleDefaults } from 'src/styles/Constants';
import TouchButton from 'src/components/shared/controls/TouchButton';
import PredictionCountdownTimer from 'src/components/shared/animations/PredictionCountdownTimer';
import { CloseButtonColor } from '../controls/CloseButton';
import { EventModel, PredictionModel } from 'src/util/TallyFirestore';

interface Props {
    requestClose: () => void;
    activePrediction: PredictionModel;
    onPredictNowClick: () => void;
}

interface State {
    longCountdown: boolean;
}

export interface NewPredictionModalDetails {
    modalType: ModalType.NEW_PREDICTION;
    event?: EventModel;
    activePrediction: PredictionModel;
    onPredictNowClick: () => void;
}

export default class NewPredictionModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            longCountdown: false,
        };
    }

    public static getPresentationDetails = () => {
        return {
            backgroundColor: Color.P1_WHITE,
            closeIconColor: CloseButtonColor.GRAY,
        } as ModalPresentationDetails;
    };

    public onPredictNowClick = () => {
        const { onPredictNowClick, requestClose } = this.props;
        onPredictNowClick();
        requestClose();
    };

    private onCountdownUpdated = (countdown?: number) => {
        if (countdown) {
            const isLong: boolean = Boolean(countdown);
            if (this.state.longCountdown !== isLong) {
                this.setState({
                    longCountdown: isLong,
                });
            }
        }
    };

    public render() {
        const { activePrediction, requestClose } = this.props;
        const { longCountdown } = this.state;
        return (
            <Container>
                <LiveText>
                    <FormattedMessage id="newPredictionModal.label" />
                </LiveText>
                <NextPredictionCountdown
                    longText={longCountdown}
                    prediction={activePrediction}
                    onCountdownExpired={requestClose}
                    onCountdownUpdated={this.onCountdownUpdated}
                />
                <ButtonContainer>
                    <TouchButton
                        stretchWidth={true}
                        backgroundColor={Color.T31_BLACK}
                        textColor={Color.P1_WHITE}
                        displayText={
                            <FormattedMessage id="newPredictionModal.buttonText" />
                        }
                        onClick={this.onPredictNowClick}
                    />
                </ButtonContainer>
            </Container>
        );
    }
}

const NextPredictionCountdown = styled(PredictionCountdownTimer)<{
    longText: boolean;
}>`
    font-weight: 900;
    text-align: center;
    ${(props) =>
        props.longText ? NextPredictionLargeStyle : NextPredictionMediumStyle};
`;

const NextPredictionMediumStyle = css`
    font-size: 35px;
    letter-spacing: -0.6px;
    line-height: 37px;
`;

const NextPredictionLargeStyle = css`
    font-size: 45px;
    letter-spacing: -0.77px;
    line-height: 37px;
`;

const ButtonContainer = styled.div`
    margin: 30px 0 20px 0;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: ${StyleDefaults.MODAL_BODY_PADDING};
    box-sizing: border-box;
`;

const LiveText = styled.div`
    color: ${Color.P4_CHILI_PEPPER};
    text-transform: uppercase;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.71px;
    line-height: 18px;
    margin: 0 0 10px 0;
`;
