import React, { Component } from 'react';
import styled from '@emotion/styled';
import {
    FormattedMessage,
    injectIntl,
    WrappedComponentProps,
} from 'react-intl';

import { Color } from 'src/styles/Constants';
import {
    SummarizedLeaderboard,
    LeaderboardEntry,
    SummarizedLeaderboardProRankEntry,
    SummarizedLeaderboardProPlayerEntry,
    SummarizedLeaderboardPlayerEntry,
} from 'src/util/TallyFirestore';
import TouchButton from '../../shared/controls/TouchButton';
import Theme from 'src/util/Theme';
import TextButton from 'src/components/shared/controls/TextButton';

import ProsLeaderboard from './ProsLeaderboard';
import LeaderboardRow from './LeaderboardRow';
import LeaderboardCheckMark from './LeaderboardCheckMark';
import { TranslationMessageId } from 'src/util/LocaleHelpers';

type Props = {
    leaderboard: SummarizedLeaderboard;
    leaderboardEntry?: LeaderboardEntry;
    snapshotMode?: boolean;
    prizes?: string[];
    showPrizeDetails?: () => void;
    disclaimer?: TranslationMessageId;
    omitCorrectAnswers?: boolean;
} & WrappedComponentProps;

const isPro = (
    player: SummarizedLeaderboardPlayerEntry,
): player is SummarizedLeaderboardProPlayerEntry => !!player.isPro;

const getProRanks = (
    leaderboard: SummarizedLeaderboard,
): SummarizedLeaderboardProRankEntry[] => {
    let proRanks: SummarizedLeaderboardProRankEntry[] = [];

    leaderboard.ranks.forEach((rank) => {
        let proPlayers: SummarizedLeaderboardProPlayerEntry[] = [];
        rank.players.forEach((player) => {
            if (isPro(player)) {
                proPlayers.push(player);
            }
        });
        if (proPlayers.length) {
            proRanks.push({
                ...rank,
                totalCount: proPlayers.length,
                players: proPlayers,
            });
        }
    });

    return proRanks;
};

const shouldDisplayCorrectPicks = (summary: SummarizedLeaderboard): boolean =>
    !!summary.ranks.length &&
    !!summary.ranks[0].players.length &&
    typeof summary.ranks[0].players[0].correctAnswers === 'number';

const SNAPSHOT_RANK_CUTOFF = 3;
class Leaderboard extends Component<Props> {
    public render = () => {
        const {
            leaderboard,
            snapshotMode,
            leaderboardEntry,
            prizes,
            intl,
            showPrizeDetails,
            omitCorrectAnswers,
        } = this.props;
        const rankRows: JSX.Element[] = [];

        let numberOfRanks = leaderboard.ranks.length;
        if (snapshotMode && numberOfRanks > SNAPSHOT_RANK_CUTOFF) {
            numberOfRanks = SNAPSHOT_RANK_CUTOFF;
        }

        for (let i = 0; i < numberOfRanks; i++) {
            rankRows.push(
                <LeaderboardRow
                    key={leaderboard.ranks[i].rank}
                    rowNumber={i}
                    rowData={leaderboard.ranks[i]}
                    leaderboardEntry={leaderboardEntry}
                    showPrizeDetails={showPrizeDetails}
                    prizes={prizes}
                    omitCorrectAnswers={omitCorrectAnswers}
                />,
            );
        }

        const showPrizeDetailsButton = rankRows.length === 0;

        const proRanks = getProRanks(leaderboard);
        const thereArePros = proRanks.length > 0;

        const displayCorrectPicks = shouldDisplayCorrectPicks(leaderboard);
        return (
            <Container>
                {thereArePros && <ProsLeaderboard proRanks={proRanks} />}
                {this.props.disclaimer && (
                    <LeaderboardDisclaimer thereArePros={thereArePros}>
                        <FormattedMessage id={this.props.disclaimer} />
                    </LeaderboardDisclaimer>
                )}
                <LegendContainer>
                    {showPrizeDetails && (
                        <TextButton
                            color={Color.T30_CHARCOAL}
                            text={intl.formatMessage({
                                id: 'leaderBoard.prizeDetails',
                            })}
                            onClick={showPrizeDetails}
                        />
                    )}
                    {displayCorrectPicks && (
                        <CheckMarkContainer>
                            <LeaderboardCheckMark
                                text={intl.formatMessage({
                                    id: 'leaderBoard.correctPicks',
                                })}
                            />
                        </CheckMarkContainer>
                    )}
                </LegendContainer>
                {rankRows}
                {showPrizeDetailsButton && showPrizeDetails && (
                    <TouchButton
                        backgroundColor={Color.TRANSPARENT}
                        textColor={Color.P2_DARK_OLIVE}
                        displayText={intl.formatMessage({
                            id: 'leaderBoard.prizeDetailsButton',
                        })}
                        onClick={showPrizeDetails}
                        stretchWidth={true}
                        underline={true}
                    />
                )}
            </Container>
        );
    };
}

export default injectIntl(Leaderboard);

const CheckMarkContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
`;

const LegendContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0 0 4px 0;
    justify-content: space-between;
    flex: 1;
    border-bottom: 1px solid ${Theme.leaderboardHorizontalRuleColor};
`;

const Container = styled.div`
    align-self: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 90%;
    margin: 0 0 0 0;
    font-family: Graphik Web;
    color: ${Color.T31_BLACK};
`;

const LeaderboardDisclaimer = styled.div<{ thereArePros: boolean }>`
    margin: ${(props) => (props.thereArePros ? '40px 0 0 0' : '0 0 20px 0')};
    font-size: 12px;
`;
