import styled from '@emotion/styled';
import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import {
    Color,
    StyleDefaults,
    TextStyles,
    TextStyleTypes,
} from 'src/styles/Constants';

type Props = {
    backgroundColor: string;
    displayText: string | JSX.Element;
    textColor: Color;
    disabled?: boolean;
    stretchWidth?: boolean;
    underline?: boolean;
    id?: string;
    secondaryStyle?: boolean;
    className?: string;
    loading?: boolean;
} & (
    | {
          type?: 'button' | 'reset';
          onClick: () => void;
      }
    | { type: 'submit'; onClick?: () => void }
);

export default function TouchButton(props: Props): JSX.Element {
    const {
        backgroundColor,
        displayText,
        onClick,
        textColor,
        disabled,
        type,
        stretchWidth,
        underline,
        secondaryStyle,
        id,
        className,
        loading,
    } = props;

    const targetBackgroundColor = disabled ? Color.T29_STONE : backgroundColor;

    return (
        <Button
            id={id}
            disabled={disabled}
            className={className}
            type={type || 'button'}
            backgroundColor={targetBackgroundColor}
            color={textColor}
            onClick={onClick}
            stretchWidth={stretchWidth}
            underline={underline}
            secondaryStyle={secondaryStyle}
        >
            {loading ? (
                <ClipLoader
                    color={textColor}
                    size={10}
                    loading={true}
                    center={true}
                />
            ) : (
                displayText
            )}
        </Button>
    );
}

const Button = styled.button<{
    backgroundColor: string;
    color: Color;
    disabled?: boolean;
    stretchWidth?: boolean;
    underline?: boolean;
    secondaryStyle?: boolean;
}>`
    transition: box-shadow 0.15s ease 0s;
    &:focus {
        outline: 0;
        box-shadow: #3bf 0px 0px 2px 3px;
    }
    &:active {
        opacity: 0.6;
    }
    ${(props) =>
        !props.disabled &&
        `
        &:hover {
            filter: brightness(80%);
        }
    `}
    color: ${(props) => props.color};
    transition: all 0.3s;
    cursor: ${(props) => (props.disabled ? 'initial' : 'pointer')};
    text-align: center;
    width: ${(props) => (props.stretchWidth ? '100%' : undefined)};
    background-color: ${(props) => props.backgroundColor};
    border: none;
    outline: none;

    font-family: ${StyleDefaults.FONT_FAMILY};
    text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};

    padding: ${(props) => (props.secondaryStyle ? '8px 23px' : '12px 44px')};
    ${(props) =>
        props.secondaryStyle
            ? TextStyles[TextStyleTypes.B2]
            : TextStyles[TextStyleTypes.B1]}
`;
