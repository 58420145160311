import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';

import {
    Color,
    TextStyleTypes,
    TextStyles,
    PistachioZIndex,
} from 'src/styles/Constants';
import { motion, useAnimation } from 'framer-motion';
import { SponsorModel } from 'src/util/TallyFirestore';
import { PredictionSponsorshipUnitTemplateId } from 'src/util/SponsorshipTypes';
import SvgDotsPredictionIntro from 'src/images/backgrounds/DotsPredictionIntro';
import SvgPowerup from 'src/images/icons/PowerUp';
import SvgBonus from 'src/images/icons/Bonus';

interface Props {
    onAnimationEnd: () => void;
    sponsor: SponsorModel;
    sponsorshipType: string;
}

const ANIMATION_END_DELAY = 1.5;

export default function PowerUpAnimation(props: Props) {
    const { onAnimationEnd, sponsor, sponsorshipType } = props;

    const containerControls = useAnimation();
    const iconControls = useAnimation();

    useEffect(() => {
        startAnimation();
    }, []);

    const startAnimation = async () => {
        await containerControls.start({
            opacity: 1,
        });

        await iconControls.start({
            opacity: 1,
            transition: {
                delay: 1.0,
            },
        });

        setTimeout(async () => {
            await containerControls.start({
                opacity: 0,
            });
            onAnimationEnd();
        }, ANIMATION_END_DELAY * 1000);
    };

    let icon;
    let label;
    const sponsorPrimaryColor =
        sponsor && sponsor.primaryColor
            ? sponsor.primaryColor
            : Color.P4_CHILI_PEPPER;

    if (
        sponsorshipType ===
        PredictionSponsorshipUnitTemplateId.DOUBLE_POINT_POWERUP
    ) {
        icon = (
            <SvgPowerup
                color={sponsorPrimaryColor}
                style={{
                    height: '150px',
                    width: '150px',
                }}
            />
        );
        label = (
            <FormattedMessage
                id="powerUpAnimation.doublePointPowerup.label"
                values={{ name: sponsor.shortName }}
            />
        );
    } else {
        icon = (
            <SvgBonus
                color={sponsorPrimaryColor}
                style={{
                    height: '150px',
                    width: '150px',
                }}
            />
        );

        label = (
            <FormattedMessage
                id="powerUpAnimation.fallback.label"
                values={{ name: sponsor.shortName }}
            />
        );
    }

    return (
        <Container animate={containerControls}>
            <Body>
                <Label>{label}</Label>
                <IconContainer animate={iconControls}>{icon}</IconContainer>
            </Body>
            <BackgroundContainer>
                <Test>
                    <SvgDotsPredictionIntro
                        color={Color.G4_CHARCOAL}
                        style={{ width: '100%', height: '500px', bottom: 0 }}
                    />
                </Test>
            </BackgroundContainer>
        </Container>
    );
}

const Label = styled(motion.div)`
    ${TextStyles[TextStyleTypes.S4]}
    font-weight: 500;
    margin: 15px 0;
    z-index: ${PistachioZIndex.PREDICTION_ANIMATION_BODY};
`;

const Test = styled.div`
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: ${PistachioZIndex.PREDICTION_ANIMATION_BACKGROUND};
`;

const BackgroundContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: -3px;
    display: flex;
    justify-content: center;
    overflow: hidden;
`;

const Container = styled(motion.div)`
    background-color: ${Color.P1_WHITE};
    width: 100%;
    height: 100%;
    opacity: 0;
    position: relative;
`;

const Body = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 100%;
    box-sizing: border-box;
    z-index: ${PistachioZIndex.PREDICTION_ANIMATION_BODY};
`;

const IconContainer = styled(motion.div)`
    z-index: ${PistachioZIndex.PREDICTION_ANIMATION_BODY};
    opacity: 0;
`;
