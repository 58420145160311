import styled from '@emotion/styled';

import { BOTTOM_NAV_HEIGHT, PistachioZIndex } from 'src/styles/Constants';

export const Container = styled.div<{
    appEmbedded?: boolean;
}>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: ${(props) =>
        props.appEmbedded ? '100vh' : `calc(100vh - ${BOTTOM_NAV_HEIGHT}px)`};
    min-height: ${(props) =>
        props.appEmbedded ? '100svh' : `calc(100svh - ${BOTTOM_NAV_HEIGHT}px)`};
    margin-bottom: ${(props) =>
        props.appEmbedded ? '0' : `${BOTTOM_NAV_HEIGHT}px`};
`;

export const StickyContainer = styled.div`
    width: 100%;
    position: sticky;
    top: 0;
    z-index: ${PistachioZIndex.STICKY_HEADER};
`;

export const AdCardContentContainer = styled.div`
    margin: 0 0 0 0;
    width: 100%;
`;

export const SignInButton = styled.div`
    margin: 5px 0;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    text-decoration: underline;
`;
