import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import {
    Color,
    StyleDefaults,
    TextStyles,
    TextStyleTypes,
} from 'src/styles/Constants';
import { ModalPresentationDetails, ModalType } from './ModalHoster';
import {
    SponsorModel,
    UserAnswer,
    PredictionModel,
} from 'src/util/TallyFirestore';
import { CloseButtonColor } from 'src/components/shared/controls/CloseButton';
import Prediction from 'src/components/shared/predictions/Prediction';
import { PrizeRedemptionSponsorshipUnit } from 'src/util/SponsorshipTypes';
import TouchButton from 'src/components/shared/controls/TouchButton';

interface Props {
    requestClose: () => void;
    userAnswer: UserAnswer;
    prediction: PredictionModel<PrizeRedemptionSponsorshipUnit>;
    sponsor: SponsorModel;
    onSeeRewardsClick: () => void;
}

export type OfferWinModalDetails = {
    modalType: ModalType.OFFER_WIN;
    userAnswer: UserAnswer;
    prediction: PredictionModel<PrizeRedemptionSponsorshipUnit>;
    sponsor: SponsorModel;
    onSeeRewardsClick: () => void;
};

export default class OfferWinModal extends PureComponent<Props> {
    public static getPresentationDetails = (): ModalPresentationDetails => {
        return {
            closeIconColor: CloseButtonColor.GRAY,
        };
    };

    public render() {
        const {
            prediction,
            sponsor,
            userAnswer,
            onSeeRewardsClick,
            requestClose,
        } = this.props;

        const sponsorship = prediction.sponsorship;

        const onClickWrapper = () => {
            requestClose();
            onSeeRewardsClick();
        };

        return (
            <Container>
                {sponsorship && (
                    <LabelContainers>
                        <StyledImage maxHeight={30} src={sponsor.wideLogoUrl} />
                        <YouWonLabel>
                            <FormattedMessage id="offerWinModal.youWon.label" />
                        </YouWonLabel>
                        <OfferLabel>
                            {sponsorship.properties.tagLine}
                        </OfferLabel>
                    </LabelContainers>
                )}
                <PredictionContainer>
                    <Prediction
                        prediction={prediction}
                        isLive={false}
                        answer={userAnswer}
                        chosenOptionOnly={true}
                    />
                    <ButtonContainer>
                        <TouchButton
                            backgroundColor={Color.G1_BLACK}
                            textColor={Color.P1_WHITE}
                            displayText={
                                <FormattedMessage id="offerWinModal.rewardsButton.text" />
                            }
                            stretchWidth={false}
                            onClick={onClickWrapper}
                        />
                    </ButtonContainer>
                </PredictionContainer>
            </Container>
        );
    }
}

const StyledImage = styled.img<{ maxHeight: number }>`
    max-height: ${(props) => props.maxHeight}px;
    margin: 4px 0;
`;

const ButtonContainer = styled.div`
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    bottom: -25px;
    display: flex;
    justify-content: center;
`;

const OfferLabel = styled.div`
    ${TextStyles[TextStyleTypes.H3]};
    font-weight: 700;
    color: ${Color.G1_BLACK};
`;

const YouWonLabel = styled.div`
    text-transform: uppercase;
    margin: 0 6px 0 0;
    ${TextStyles[TextStyleTypes.S3]};
    color: ${Color.G1_BLACK};
`;

const LabelContainers = styled.div`
    margin: 0 0 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const PredictionContainer = styled.div`
    position: relative;
    padding: 20px;
    border: 1px solid ${Color.G7_FOG};
    margin: 0 0 60px 0;
`;

const Container = styled.div`
    color: ${Color.P1_WHITE};
    display: flex;
    text-align: left;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${StyleDefaults.MODAL_BODY_PADDING};
`;
