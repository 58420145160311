import styled from '@emotion/styled';
import css from '@emotion/css';
import { Color, TextStyles, TextStyleTypes } from 'src/styles/Constants';

export const LeftColumn = css`
    display: flex;
    flex: 1;
`;

export const RightColumn = css`
    display: flex;
    margin-left: 30px;
    width: 60px;
`;

export const ValueStyle = css`
    font-size: 27px;
    font-weight: 300;
    height: 38px;
    letter-spacing: -0.45px;
    line-height: 32px;
`;

export const InputContainer = styled.div`
    ${LeftColumn}
    align-items: center;
    position: relative;
    padding-bottom: 2px;
    padding-top: 6px;
`;

export const Input = styled.input<{
    inactiveBorderColor: string;
    error: boolean;
}>`
    -webkit-appearance: none;
    ${ValueStyle}
    padding-left: 10px;
    padding-right: 10px;
    background-color: ${Color.TRANSPARENT};
    border: 1px solid
        ${(props) =>
            props.error ? Color.S12_VARSITY_RED : props.inactiveBorderColor};
    &:focus {
        border-color: ${Color.TRANSPARENT};
        outline: 1px solid
            ${(props) =>
                props.error ? Color.S12_VARSITY_RED : Color.P6_ELECTRIC_BLUE};
    }
    width: 100%;
    ${TextStyles[TextStyleTypes.S1]};
`;

export const LabelTextStyle = css`
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 18px;
    text-transform: uppercase;
`;

export const AverageValue = styled.div`
    ${RightColumn}
    ${ValueStyle}
    padding-top: 10px;
`;

export const TotalPoints = styled.p`
    font-size: 12px;
    font-style: italic;
    letter-spacing: 0.2px;
    line-height: 18px;
`;

export const InputError = styled.p`
    font-size: 12px;
    letter-spacing: 0.2px;
    line-height: 18px;
    color: ${Color.S12_VARSITY_RED};
`;

export const InputLabel = styled.div`
    ${LeftColumn}
    ${LabelTextStyle}
`;

export const AverageLabel = styled.div`
    ${RightColumn}
    ${LabelTextStyle}
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Row = styled.div<{ error: boolean }>`
    margin: 0 0 ${(props) => (props.error ? 0 : '18px')} 0;
    display: flex;
    flex-direction: row;
`;

export const ResolvedIcon = styled.img`
    position: absolute;
    right: 12px;
    height: 18px;
    width: 18px;
`;
