import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from '@emotion/styled';
import Close from '@material-ui/icons/Close';
import { OfferCodeType, UserOffer } from 'src/util/TallyFirestore';
import { TextStyleTypes, TextStyles, Color } from 'src/styles/Constants';
import TouchButton from 'src/components/shared/controls/TouchButton';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core';
import MachineReadableOfferCode from './MachineReadableOfferCode';
import Expiration from './Expiration';
import Section from './Section';

interface Props {
    onClose: () => void;
    userOffer: UserOffer;
}

function Code({
    code,
    type,
    redemptionCtaUrl,
}: {
    code: string;
    type: OfferCodeType;
    redemptionCtaUrl?: string;
}) {
    const intl = useIntl();

    const onRedeemClick = () => {
        window.open(redemptionCtaUrl, '_blank');
    };

    return (
        <CodeContainer>
            <CodeLabel>
                <FormattedMessage id="offerDetails.redeem.codeTitle" />
            </CodeLabel>
            {type !== 'TEXT_ONLY' && (
                <MachineReadableOfferCode code={code} type={type} />
            )}
            <CodeText>{code}</CodeText>
            {redemptionCtaUrl && (
                <RedeemButtonContainer>
                    <TouchButton
                        backgroundColor={Color.G1_BLACK}
                        displayText={intl.formatMessage({
                            id: 'offerDetails.redeem.ButtonText',
                        })}
                        onClick={onRedeemClick}
                        textColor={Color.P1_WHITE}
                    />
                </RedeemButtonContainer>
            )}
        </CodeContainer>
    );
}

const REDEEM_BUTTON_OVERHANG = '20px';

const CodeContainer = styled.div`
    align-items: center;
    background-color: ${Color.G10_WHITISH};
    border: 1px solid ${Color.T28_FOG};
    display: flex;
    flex-direction: column;
    margin-bottom: ${REDEEM_BUTTON_OVERHANG};
    width: 100%;
`;

const CodeLabel = styled.p`
    ${TextStyles[TextStyleTypes.P3]};
    margin-top: 35px;
`;

const CodeText = styled.h3`
    ${TextStyles[TextStyleTypes.H3]};
    margin-bottom: 20px;
`;

const RedeemButtonContainer = styled.div`
    transform: translate(0, ${REDEEM_BUTTON_OVERHANG});
`;

const useStyles = makeStyles({
    heading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 39,
    },
});

export default function OfferDetails({ onClose, userOffer }: Props) {
    const intl = useIntl();
    const classes = useStyles();
    const {
        codeData,
        displayName,
        expirationDate,
        legalMarkdown,
        redemptionCtaUrl,
        redemptionMarkdown,
        codeType,
    } = userOffer;

    return (
        <Container>
            <TopSection>
                <div className={classes.heading}>
                    <div>
                        <OfferNameText>{displayName}</OfferNameText>
                        <Expiration expirationDate={new Date(expirationDate)} />
                    </div>
                    <IconButton
                        disableFocusRipple
                        disableTouchRipple
                        onClick={onClose}
                    >
                        <Close />
                    </IconButton>
                </div>
                {codeData && codeType && (
                    <Code
                        code={codeData}
                        type={codeType}
                        redemptionCtaUrl={redemptionCtaUrl}
                    />
                )}
            </TopSection>
            <Section
                title={intl.formatMessage({ id: 'offerDetails.redeem.howTo' })}
                markdown={redemptionMarkdown}
            />
            <Section
                title={intl.formatMessage({
                    id: 'offerDetails.redeem.disclaimer',
                })}
                markdown={legalMarkdown}
            />
        </Container>
    );
}

const Container = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const OfferNameText = styled.h2`
    ${TextStyles[TextStyleTypes.H3]};
    margin-bottom: 12px;
`;

const TopSection = styled.div`
    position: relative;
    align-items: stretch;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    width: 100%;
`;
