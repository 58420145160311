import { Typography, TypographyProps, withStyles } from '@material-ui/core';

export const CustomTypography = ({
    color,
    ...rest
}: {
    color: string;
} & Omit<TypographyProps, 'color'>) => {
    const StyledTypography = withStyles({
        root: {
            color: color,
        },
    })(Typography);

    return <StyledTypography {...rest} />;
};
