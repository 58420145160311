import {
    GeneralLeaderboardWithGroups,
    IterationGroupModeAndProps,
    IterationGroupModeTeamUserGroup,
    SummarizedLeaderboard,
    IterationGroupModeTeam,
    IterationGroupModeUserGroup,
    IterationGroupModeVenue,
    IterationGroupModeDisabled,
} from 'src/util/TallyFirestore';
import { useGroupProvider } from 'src/contexts/GroupProvider';
import useLiveFirestoreDocument from './useLiveFirestoreDocument';
import { TeamGroup, UserGroup, VenueGroup } from 'src/types';
import useLiveGeneralLeaderboard from 'src/hooks/useLiveGeneralLeaderboard';
import {
    getInterGroupLeaderboardPath,
    getIntraGroupLeaderboardPath,
} from 'src/util/FirestoreDao';

type LeaderboardsBase = {
    generalLeaderboard: GeneralLeaderboardWithGroups;
};

export type TeamUserGroupLeaderboards = LeaderboardsBase & {
    groupMode: 'TEAM,USER_GROUP';
    groupModeProperties: IterationGroupModeTeamUserGroup;
    teamGroup: {
        group?: TeamGroup;
        intraGroupLeaderboardSummary?: SummarizedLeaderboard;
        interGroupLeaderboardSummary?: SummarizedLeaderboard;
    };
    userGroup: {
        group?: UserGroup;
        intraGroupLeaderboardSummary?: SummarizedLeaderboard;
    };
};

export type TeamGroupLeaderboards = LeaderboardsBase & {
    groupMode: 'TEAM';
    groupModeProperties: IterationGroupModeTeam;
    teamGroup: {
        group?: TeamGroup;
        intraGroupLeaderboardSummary?: SummarizedLeaderboard;
        interGroupLeaderboardSummary?: SummarizedLeaderboard;
    };
};

export type VenueGroupLeaderboards = LeaderboardsBase & {
    groupMode: 'VENUE';
    groupModeProperties: IterationGroupModeVenue;
    venueGroup: {
        group?: VenueGroup;
        intraGroupLeaderboardSummary?: SummarizedLeaderboard;
    };
};

export type UserGroupLeaderboards = LeaderboardsBase & {
    groupMode: 'USER_GROUP';
    groupModeProperties: IterationGroupModeUserGroup;
    userGroup: {
        group?: UserGroup;
        intraGroupLeaderboardSummary?: SummarizedLeaderboard;
    };
};

export type NoGroupsLeaderboards = LeaderboardsBase & {
    groupMode: null;
    groupModeProperties: IterationGroupModeDisabled;
};

export type Leaderboards =
    | NoGroupsLeaderboards
    | TeamGroupLeaderboards
    | TeamUserGroupLeaderboards
    | VenueGroupLeaderboards
    | UserGroupLeaderboards;

function useLiveEntityLeaderboards<
    T extends string | undefined,
    R = T extends string ? Leaderboards : undefined,
>(path: string | undefined, groupModeAndProps: IterationGroupModeAndProps): R {
    const { groupMode, groupModeProperties } = groupModeAndProps;
    const { groups } = useGroupProvider();
    const generalLeaderboard = useLiveGeneralLeaderboard(path);
    const { teamGroup, userGroup, venueGroup } = groups;

    const intraTeamGroupLeaderboard = useLiveFirestoreDocument<
        SummarizedLeaderboard | undefined
    >(
        (groupMode === 'TEAM' || groupMode === 'TEAM,USER_GROUP') &&
            teamGroup !== undefined &&
            path
            ? getIntraGroupLeaderboardPath(
                  path,
                  teamGroup.groupType,
                  teamGroup.id,
              )
            : undefined,
        undefined,
    );

    const interTeamGroupLeaderboard = useLiveFirestoreDocument<
        SummarizedLeaderboard | undefined
    >(
        (groupMode === 'TEAM' || groupMode === 'TEAM,USER_GROUP') && path
            ? getInterGroupLeaderboardPath(path, 'TEAM')
            : undefined,
        undefined,
    );

    const intraUserGroupLeaderboard = useLiveFirestoreDocument<
        SummarizedLeaderboard | undefined
    >(
        (groupMode === 'USER_GROUP' || groupMode === 'TEAM,USER_GROUP') &&
            userGroup &&
            path
            ? getIntraGroupLeaderboardPath(
                  path,
                  userGroup.groupType,
                  userGroup.id,
              )
            : undefined,
        undefined,
    );

    const intraVenueGroupLeaderboard = useLiveFirestoreDocument<
        SummarizedLeaderboard | undefined
    >(
        groupMode === 'VENUE' && venueGroup && path
            ? getIntraGroupLeaderboardPath(
                  path,
                  venueGroup.groupType,
                  venueGroup.id,
              )
            : undefined,
        undefined,
    );

    if (typeof path === 'undefined') {
        return undefined as R;
    }

    switch (groupMode) {
        case 'TEAM,USER_GROUP': {
            const result: TeamUserGroupLeaderboards = {
                groupMode: groupMode,
                groupModeProperties: groupModeProperties,
                generalLeaderboard,
                teamGroup: {
                    group: groups.teamGroup,
                    intraGroupLeaderboardSummary: intraTeamGroupLeaderboard,
                    interGroupLeaderboardSummary: interTeamGroupLeaderboard,
                },
                userGroup: {
                    group: groups.userGroup,
                    intraGroupLeaderboardSummary: intraUserGroupLeaderboard,
                },
            };
            return result as R;
        }
        case 'TEAM': {
            const result: TeamGroupLeaderboards = {
                groupMode: groupMode,
                groupModeProperties: groupModeProperties,
                generalLeaderboard,
                teamGroup: {
                    group: groups.teamGroup,
                    intraGroupLeaderboardSummary: intraTeamGroupLeaderboard,
                    interGroupLeaderboardSummary: interTeamGroupLeaderboard,
                },
            };
            return result as R;
        }
        case 'VENUE': {
            const result: VenueGroupLeaderboards = {
                groupMode: groupMode,
                groupModeProperties: groupModeProperties,
                generalLeaderboard,
                venueGroup: {
                    group: groups.venueGroup,
                    intraGroupLeaderboardSummary: intraVenueGroupLeaderboard,
                },
            };
            return result as R;
        }
        case 'USER_GROUP': {
            const result: UserGroupLeaderboards = {
                groupMode: groupMode,
                groupModeProperties: groupModeProperties,
                generalLeaderboard,
                userGroup: {
                    group: groups.userGroup,
                    intraGroupLeaderboardSummary: intraUserGroupLeaderboard,
                },
            };
            return result as R;
        }

        case null: {
            const result: NoGroupsLeaderboards = {
                generalLeaderboard,
                groupMode: groupMode,
                groupModeProperties: groupModeProperties,
            };
            return result as R;
        }
    }
}

export default useLiveEntityLeaderboards;
