import { makeStyles } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { TRANSITION_ANIMATION_TIME } from 'src/styles/Constants';

type Props = {
    children: React.ReactNode;
    onEntered?: () => void;
    onExited?: () => void;
};

const useStyles = makeStyles({
    enterActive: {
        transition: `height ${TRANSITION_ANIMATION_TIME}ms ease-in, opacity ${TRANSITION_ANIMATION_TIME}ms ease-in;`,
    },
    appearActive: {
        transition: `height ${TRANSITION_ANIMATION_TIME}ms ease-in, opacity ${TRANSITION_ANIMATION_TIME}ms ease-in;`,
    },
    exitActive: {
        transition: `height ${TRANSITION_ANIMATION_TIME}ms ease-in, opacity ${TRANSITION_ANIMATION_TIME}ms ease-in;`,
    },
});

const AnimatedListItem = ({
    children,
    onEntered,
    onExited,
    ...props
}: Props) => {
    const classes = useStyles();
    const [height, setHeight] = useState<number | 'auto'>(0);
    const [opacity, setOpacity] = useState<number>(0);
    const listItemRef = useRef<HTMLDivElement | null>(null);

    return (
        <CSSTransition
            nodeRef={listItemRef}
            timeout={TRANSITION_ANIMATION_TIME}
            onEntering={() => {
                if (listItemRef.current) {
                    setHeight(listItemRef.current.scrollHeight);
                    setOpacity(1);
                }
            }}
            onEntered={() => {
                setHeight('auto');
                if (onEntered) {
                    onEntered();
                }
            }}
            onExit={() => {
                if (listItemRef.current) {
                    setHeight(listItemRef.current.scrollHeight);
                }
            }}
            onExiting={() => {
                setHeight(0);
                setOpacity(0);
            }}
            onExited={() => {
                if (onExited) {
                    onExited();
                }
            }}
            classNames={classes}
            {...props}
        >
            <div ref={listItemRef} style={{ height, opacity }}>
                {children}
            </div>
        </CSSTransition>
    );
};

export default AnimatedListItem;
