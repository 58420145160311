import React, { useEffect, useRef } from 'react';

type Props = {
    iframeHtml: string;
    onLoad: () => void;
};

const AdIframe = ({ iframeHtml, onLoad }: Props) => {
    const ref = useRef<HTMLDivElement>(null);

    const handleIframeLoad = () => {
        onLoad();
    };

    useEffect(() => {
        const iframe = ref.current?.querySelector('iframe');

        if (!iframe) {
            return;
        }

        iframe.addEventListener('load', handleIframeLoad);

        return () => {
            iframe.removeEventListener('load', handleIframeLoad);
        };
    }, []);

    return <div ref={ref} dangerouslySetInnerHTML={{ __html: iframeHtml }} />;
};

export default AdIframe;
