import React, { createContext, ReactNode, useContext, useState } from 'react';
import {
    ContactInfo,
    UserModel,
    UserOffer,
    UserWithPrivateData,
} from 'src/util/TallyFirestore';

// authenticated with firestore
export type Authenticated = {
    type: 'authenticated';
    uid: string;
};

export type Anonymous = {
    type: 'annonymous';
    uid: string;
};

export type Initialized = {
    type: 'initialized';
    uid: string;
} & UserWithPrivateData;

export type UserAuthData = Anonymous | Authenticated | Initialized;

type Props = {
    initialUserAuthData: UserAuthData;
    children: ReactNode;
};

export const UserStateContext = createContext<UserAuthData>({
    type: 'annonymous',
    uid: 'dummy',
});

const UserInitializeContext = createContext<{
    signIn: (uid: string) => void;
    initialize: (uid: string, user: UserWithPrivateData) => void;
    updateContactInfo: (contactInfo: Partial<ContactInfo>) => void;
    setContactInfo: (contactInfo: ContactInfo) => void;
}>({} as any);

export const UserStateProvider = ({ initialUserAuthData, children }: Props) => {
    const [userAuthData, setUserAuthData] = useState(initialUserAuthData);

    const initialize = async (uid: string, user: UserWithPrivateData) => {
        const newState: Initialized = {
            type: 'initialized',
            uid,
            ...user,
        };
        setUserAuthData(newState);
    };

    const signIn = async (uid: string) => {
        const newState: Authenticated = { type: 'authenticated', uid };
        setUserAuthData(newState);
    };

    const updateContactInfo = (contactInfo: Partial<ContactInfo>) => {
        setUserAuthData((previousState) => {
            if (previousState.type === 'initialized') {
                return {
                    ...previousState,
                    contactInfo: previousState.contactInfo && {
                        ...previousState.contactInfo,
                        ...contactInfo,
                    },
                };
            }
            return previousState;
        });
    };

    const setContactInfo = (contactInfo: ContactInfo) => {
        setUserAuthData((previousState) => {
            if (previousState.type === 'initialized') {
                return {
                    ...previousState,
                    contactInfo,
                };
            }
            return previousState;
        });
    };

    return (
        <UserStateContext.Provider value={userAuthData}>
            <UserInitializeContext.Provider
                value={{
                    initialize,
                    signIn,
                    updateContactInfo,
                    setContactInfo,
                }}
            >
                {children}
            </UserInitializeContext.Provider>
        </UserStateContext.Provider>
    );
};

export const UserAuthConsumer = UserStateContext.Consumer;

export const useUserState = () => {
    return useContext(UserStateContext);
};

export const useInitializeUser = () => {
    return useContext(UserInitializeContext).initialize;
};

export const useSignInUser = () => {
    return useContext(UserInitializeContext).signIn;
};

export const useUpdateContactInfo = () => {
    return useContext(UserInitializeContext).updateContactInfo;
};

export const useSetContactInfo = () => {
    return useContext(UserInitializeContext).setContactInfo;
};

export const getDetails = (userData: UserAuthData): UserModel | undefined =>
    userData.type === 'initialized' ? userData.details : undefined;

export const getContactInfo = (
    userData: UserAuthData,
): ContactInfo | undefined =>
    userData.type === 'initialized' ? userData.contactInfo : undefined;

export const getOffers = (userData: UserAuthData): UserOffer[] | undefined =>
    userData.type === 'initialized' ? userData.offers : undefined;
