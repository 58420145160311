import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import ProgressivePoll, {
    OnAnswerCallback,
} from 'src/components/shared/progressivePoll/ProgressivePoll';
import { Color } from 'src/styles/Constants';
import {
    ProgressivePollModel,
    ProgressivePollAnswer,
} from 'src/util/TallyFirestore';
import pollIcon from 'src/images/icons/Polls.svg';
import Pill from 'src/components/pistachioGame/Pill';

type Props = {
    answer?: ProgressivePollAnswer;
    poll: ProgressivePollModel;
    pointValue: number;
    onAnswer?: OnAnswerCallback;
    isResolved: boolean;
    projectedSelectedOptionId?: string;
};

export default ({
    poll,
    answer,
    onAnswer,
    pointValue,
    isResolved,
    projectedSelectedOptionId,
}: Props) => (
    <Container>
        <Card>
            <IconContainer>
                <Pill
                    details={{
                        asset: pollIcon,
                        circleColor: Color.T34_SQUIRTLE,
                        pillColor: Color.S18_BRIGHT_CYAN,
                    }}
                    expand={!isResolved}
                />
            </IconContainer>
            <CardContent>
                <PollPoints>
                    <PollHeading>
                        <FormattedMessage id="newPredictionCard.pollHeading" />
                    </PollHeading>
                    {pointValue}{' '}
                    <FormattedMessage id="newPredictionCard.pollPoints" />
                </PollPoints>
                <ProgressivePoll
                    answer={answer}
                    poll={poll}
                    onAnswer={onAnswer}
                    isResolved={isResolved}
                    projectedSelectedOptionId={projectedSelectedOptionId}
                />
            </CardContent>
        </Card>
    </Container>
);

const Container = styled.div`
    padding-bottom: 60px;
`;

const IconContainer = styled.div`
    position: absolute;
    top: -15px;
    left: 20px;
    z-index: 10;
`;

const Card = styled.div`
    padding-bottom: 60px;
    position: relative;
    top: 0;
    border: 1px solid ${Color.T34_SQUIRTLE};
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.08);
    background-color: ${Color.P1_WHITE};
    transition: border 0.4s;
    width: 100%;
    padding: 40px 20px 20px 20px;
    box-sizing: border-box;
`;

const CardContent = styled.div`
    position: relative;
    z-index: 1;
`;

const PollHeading = styled.span`
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.13px;
    line-height: 28px;
    margin-right: 5px;
`;

const PollPoints = styled.div`
    text-align: center;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: -0.13px;
    line-height: 28px;
    margin-bottom: 6px;
`;
