import React from 'react';
import styled from '@emotion/styled';

import { Color, TextStyles, TextStyleTypes } from 'src/styles/Constants';
import {
    OptionModel,
    ProgressivePollModel,
    ProgressivePollAnswer,
} from 'src/util/TallyFirestore';
import { ProgressivePollOption } from './Option';
import TallyMarkdown from 'src/components/shared/ui/TallyMarkdown';

export type OnAnswerCallback = (params: {
    optionId: string;
    poll: ProgressivePollModel;
}) => Promise<boolean>;

interface Props {
    answer?: ProgressivePollAnswer;
    onAnswer?: OnAnswerCallback;
    poll: ProgressivePollModel;
    isResolved: boolean;
    projectedSelectedOptionId?: string;
}

export default ({
    poll,
    answer,
    onAnswer,
    isResolved,
    projectedSelectedOptionId,
}: Props) => {
    const options = poll.options;
    const selectedOptionId =
        (answer && answer.optionId) || projectedSelectedOptionId;

    const onOptionPress = async (option: OptionModel) => {
        const { id: optionId } = option;
        const success = onAnswer
            ? await onAnswer({
                  optionId,
                  poll,
              })
            : true;
        return success;
    };

    return (
        <Container>
            <QuestionText>{poll.text}</QuestionText>
            {poll.detailsText && (
                <SubText>
                    <TallyMarkdown
                        linkColor={Color.T31_BLACK}
                        paragraphPaddingBottom="4px"
                        source={poll.detailsText}
                    />
                </SubText>
            )}
            <OptionsWrapper>
                {options.map((option) => (
                    <OptionWrapper key={option.id}>
                        <ProgressivePollOption
                            onPress={onOptionPress}
                            option={option}
                            progressivePollLocked={isResolved}
                            selectedOptionId={selectedOptionId}
                        />
                    </OptionWrapper>
                ))}
            </OptionsWrapper>
        </Container>
    );
};

const QuestionText = styled.h2`
    color: ${Color.T31_BLACK};
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.1px;
    line-height: 24px;
`;

const Container = styled.section`
    font-family: Graphik Web;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const SubText = styled.div`
    color: ${Color.T31_BLACK};
    ${TextStyles[TextStyleTypes.C1]};
    margin: 3px 0;
    display: flex;
    flex-direction: column;
`;

const OptionWrapper = styled.div`
    margin: 10px 0 0 0;
`;

const OptionsWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 15px 0 17px 0;
`;
