import React from 'react';
import styled from '@emotion/styled';
import { FormattedMessage, useIntl } from 'react-intl';

import TallyMarkdown from 'src/components/shared/ui/TallyMarkdown';
import { PastAssignedTriviaQuestion } from 'src/services/ServerApi';
import {
    isNoPointsTriviaGame,
    LeaderboardEntry,
    TriviaGameProperties,
} from 'src/util/TallyFirestore';
import UserStats from 'src/components/shared/ui/UserStats';
import { EventModel } from 'src/util/TallyFirestore';
import { useHistory } from 'react-router-dom';
import { goToGeneralEventLeaderboard } from 'src/routes';
import { BackgroundPanel } from 'src/components/shared/ui/BackgroundPanel';
import { useLocale } from 'src/hooks/useLocale';
import { LocaleId } from 'src/util/LocaleHelpers';

type Props = {
    questions: PastAssignedTriviaQuestion[];
    event: EventModel;
    trivia: TriviaGameProperties;
    leaderboardEntry?: LeaderboardEntry;
    screenHasBg?: boolean | false;
};

const GameOver = ({
    event,
    trivia,
    leaderboardEntry,
    questions,
    screenHasBg = false,
}: Props) => {
    const history = useHistory();
    const intl = useIntl();
    const { localeId } = useLocale();

    const { shortName } = event;
    const gameOverMessage =
        event && event.ui && event.ui.gameOverMessage
            ? JSON.parse(JSON.stringify(event.ui.gameOverMessage))
            : '';

    const questionsAnsweredCorrectly: number = questions.reduce((acc, cur) => {
        return cur.correctOptionId === cur.selectedOptionId ? ++acc : acc;
    }, 0);

    return (
        <>
            <BackgroundPanel screenHasBg={screenHasBg}>
                <HeaderText>
                    <FormattedMessage id="triviaGame.gameOver.header" />
                </HeaderText>
                {trivia.mode === 'pointsCountdown' ? (
                    <TallyMarkdown
                        source={intl.formatMessage(
                            {
                                id: 'triviaGame.gameOver.description.pointsCountdown',
                            },
                            {
                                questionsAnsweredCorrectly,
                                numberOfQuestions: trivia.numberOfQuestions,
                            },
                        )}
                    />
                ) : (
                    <TallyMarkdown
                        source={intl.formatMessage(
                            { id: 'triviaGame.gameOver.description' },
                            {
                                questionsAnsweredCorrectly,
                                numberOfQuestions: trivia.numberOfQuestions,
                            },
                        )}
                    />
                )}
                {gameOverMessage && (
                    <TallyMarkdown
                        source={gameOverMessage[localeId as LocaleId]}
                    />
                )}
                {gameOverMessage && <TallyMarkdown source={gameOverMessage} />}
            </BackgroundPanel>
            {true && (
                <UserStats
                    leaderboardEntry={leaderboardEntry}
                    showShadow={true}
                    titleOverride={shortName}
                    callToActionButton={
                        isNoPointsTriviaGame(trivia)
                            ? undefined
                            : {
                                  label: (
                                      <FormattedMessage id="predictPage.userStats.callToActionButton.label" />
                                  ),
                                  onClick: () => {
                                      goToGeneralEventLeaderboard(
                                          history,
                                          location,
                                          event.slug,
                                      );
                                  },
                              }
                    }
                />
            )}
        </>
    );
};

export default GameOver;

const HeaderText = styled.div`
    text-align: center;
    font-size: 35px;
    letter-spacing: -0.6px;
    line-height: 37px;
    font-weight: 900;
    margin: 0 0 20px 0;
`;
