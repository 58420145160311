import React, { createContext, ReactNode, useContext } from 'react';
import { webStorageKeys } from 'src/constants';
import { usePartner } from './usePartner';
import useStorageState from './useStorageState';

const UserAgreementContext = createContext<{
    userAgreed: boolean;
    agree: () => void;
}>({ userAgreed: false, agree: () => {} });

export const UserAgreementConsumer = UserAgreementContext.Consumer;

type Props = {
    children: ReactNode;
};

const isBoolean = (v: unknown): v is boolean => {
    return typeof v === 'boolean';
};

export const UserAgreementProvider = ({ children }: Props) => {
    const partner = usePartner();
    const [userAgreed, setUserAgreed] = useStorageState(
        partner.properties.onboardingAgreement?.forceRecurrentAgreementDisplay
            ? sessionStorage
            : localStorage,
        webStorageKeys.agreement,
        isBoolean,
        false,
    );

    const agree = () => {
        setUserAgreed(true);
    };

    return (
        <UserAgreementContext.Provider value={{ userAgreed, agree }}>
            {children}
        </UserAgreementContext.Provider>
    );
};

const useUserAgreement = () => useContext(UserAgreementContext);

export default useUserAgreement;
