import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from '@emotion/styled';
import TouchButton from 'src/components/shared/controls/TouchButton';
import SquareLoading from 'src/components/shared/animations/SquareLoading';
import PhoneInput from 'src/components/shared/controls/fields/PhoneInput';
import { Color, TextStyles, TextStyleTypes } from 'src/styles/Constants';
import TallyMarkdown from 'src/components/shared/ui/TallyMarkdown';
import ServerError from 'src/services/ServerError';
import HelpLinks from 'src/components/shared/ui/HelpLinks';
import InputLabel from 'src/components/shared/ui/InputLabel';
import { usePartner } from 'src/hooks/usePartner';
import formatPhoneNumberLeadingPlus from 'src/util/phoneNumber';
import ServerApi from 'src/services/ServerApi';
import { useAnalytics } from 'src/contexts/AnalyticsContext';
import { AppArea, EventName } from 'src/util/AnalyticsConstants';
import { NetworkError } from 'src/util/errors/NetworkError';
import { useSetContactInfo } from 'src/contexts/UserStateContext';
import FirebaseAuth from 'src/services/FirebaseAuth';
import { getContactInfo } from 'src/util/FirestoreDao';

type Props = {
    iterationId: string;
    onContactSupportClick: () => void;
};

const PhoneTile = ({
    iterationId,
    onContactSupportClick,
}: Props): JSX.Element => {
    const intl = useIntl();
    const partner = usePartner();
    const setContactInfo = useSetContactInfo();
    const analytics = useAnalytics();

    const [tileError, setTileError] = useState<Error | undefined>(undefined);
    const [fieldError, setFieldError] = useState<Error | undefined>(undefined);

    const { onboardingPhoneAuthBannerUrl } = partner.properties;

    const fieldDescription = intl.formatMessage({
        id: 'onboarding.requestPhone.field.description',
    });

    const tileTitle = intl.formatMessage({
        id: 'onboarding.requestPhone.title',
    });

    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [isSubmittingField, setIsSubmittingField] = useState<boolean>(false);

    const handleSubmit = async () => {
        setIsSubmittingField(true);
        try {
            await ServerApi.updateUser({
                phoneNumber: formatPhoneNumberLeadingPlus(phoneNumber),
                lastIterationOptIn: iterationId,
            });

            const uid = FirebaseAuth.getUser()?.uid;

            if (!uid) {
                throw new Error(
                    'User should have been already authenticated in here...',
                );
            }

            const contactInfo = await getContactInfo(uid);

            if (!contactInfo) {
                throw new Error('Error updating contact info in firestore');
            }

            setContactInfo(contactInfo);

            analytics.logEvent({
                eventName: EventName.signUpPhoneNonAuthSubmit,
                eventCategory: AppArea.signup,
            });
        } catch (error) {
            setIsSubmittingField(false);
            if (error instanceof NetworkError) {
                setTileError(
                    new Error(
                        intl.formatMessage({ id: 'error.failedToFetch' }),
                    ),
                );
                return;
            }
            if (error instanceof ServerError && error.field) {
                setFieldError(error);
                setTileError(
                    new Error(intl.formatMessage({ id: 'error.generic' })),
                );
                return;
            }

            setTileError(error as Error);
        }
    };

    const disableSubmit =
        !phoneNumber || isSubmittingField || !!fieldError || !!tileError;

    return (
        <>
            <TileTitle color={Color.T31_BLACK}>{tileTitle}</TileTitle>
            {onboardingPhoneAuthBannerUrl && (
                <DescriptionImg src={onboardingPhoneAuthBannerUrl} />
            )}
            {fieldDescription && (
                <DescriptionText>
                    <TallyMarkdown source={fieldDescription} />
                </DescriptionText>
            )}

            <PhoneInput
                value={phoneNumber}
                onChange={(value: string) => {
                    setFieldError(undefined);
                    setTileError(undefined);
                    setPhoneNumber(value);
                }}
                error={fieldError}
                onContactSupportClick={onContactSupportClick}
            />
            {tileError && (
                <>
                    <InputLabel label={''} error={tileError} />
                    <HelpLinks onContactSupportClick={onContactSupportClick} />
                </>
            )}
            <ButtonContainer>
                <TouchButton
                    disabled={disableSubmit}
                    displayText={
                        <FormattedMessage id="infoCollectionTitle.touchButtonLabel" />
                    }
                    backgroundColor={Color.T31_BLACK}
                    textColor={Color.P1_WHITE}
                    onClick={handleSubmit}
                />
            </ButtonContainer>
            <LoadingContainer>
                {isSubmittingField && (
                    <SquareLoading color={Color.P6_ELECTRIC_BLUE} />
                )}
            </LoadingContainer>
        </>
    );
};

export default PhoneTile;

const TileTitle = styled.div`
    ${TextStyles[TextStyleTypes.H3]}
    border-bottom: 2px solid ${Color.G8_CONCRETE};
    text-align: left;
    width: 100%;
`;

const ButtonContainer = styled.div`
    padding: 25px 0 15px 0;
`;

const DescriptionText = styled.div`
    margin: 15px 0 30px 0;
    ${TextStyles[TextStyleTypes.P1]}
    text-align: left;
    width: 100%;
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 20px;
    margin: 20px 0;
`;

const DescriptionImg = styled.img`
    margin: 15px 0 30px 0;
    width: 100%;
`;
