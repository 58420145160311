import React from 'react';
import styled from '@emotion/styled';

import { Color } from 'src/styles/Constants';
import { SummarizedLeaderboardPlayerEntry } from 'src/util/TallyFirestore';
import Theme from 'src/util/Theme';

type Props = {
    currentRank: number;
    player: SummarizedLeaderboardPlayerEntry;
    currentPlayer?: SummarizedLeaderboardPlayerEntry;
    omitFirstPlayerHighlight?: boolean;
};

const LeaderboardPlayer = ({
    currentRank,
    player,
    currentPlayer,
    omitFirstPlayerHighlight,
}: Props) => {
    const color =
        currentPlayer && currentPlayer.uid === player.uid
            ? Theme.leaderboardCurrentUserTextColor
            : player.isPro
            ? Theme.leaderboardHorizontalRuleColor
            : Color.T31_BLACK;
    if (!omitFirstPlayerHighlight && currentRank === 1) {
        return (
            <FirstRankPlayer key={player.uid} color={color}>
                {player.isPro && Theme.navLogoUrl && (
                    <ProImage src={Theme.navLogoUrl} />
                )}
                {player.displayName}
            </FirstRankPlayer>
        );
    } else {
        return (
            <RankPlayer key={player.uid} color={color}>
                {player.isPro && Theme.navLogoUrl && (
                    <ProImage src={Theme.navLogoUrl} />
                )}
                {player.displayName}
            </RankPlayer>
        );
    }
};

export default LeaderboardPlayer;

const RankPlayer = styled.div<{ color: string }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    color: ${(props) => props.color};
    text-align: left;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
`;

const FirstRankPlayer = styled.div<{ color: string }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    color: ${(props) => props.color};
    text-align: left;
    font-size: 20px;
    font-weight: 900;
    letter-spacing: -0.34px;
    flex: 2;
    margin-bottom: 3px;
`;

const ProImage = styled.img<{ src: string }>`
    margin-right: 4px;
    height: 1em;
`;
