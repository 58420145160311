import { useEffect, useState } from 'react';
import firebase, { DocumentSnapshot } from 'src/services/Firebase';

import { useUserState } from 'src/contexts/UserStateContext';
import {
    Collection,
    GeneralLeaderboardWithGroups,
    LeaderboardEntryWithGroupRanks,
    SummarizedLeaderboard,
} from 'src/util/TallyFirestore';
import useLiveFirestoreDocument from './useLiveFirestoreDocument';

/**
 *
 * @param entityPath either iteration or event path.
 * pass undefined to not make any subscriptions
 * @returns  GeneralLeaderboardWithGroups
 */
const useLiveGeneralLeaderboard = (
    entityPath: string | undefined,
): GeneralLeaderboardWithGroups => {
    const leaderboardSummary = useLiveFirestoreDocument<
        SummarizedLeaderboard | undefined
    >(entityPath && `${entityPath}/summaries/topPlayers`, undefined);

    const [leaderboardEntry, setLeaderboardEntry] = useState<
        LeaderboardEntryWithGroupRanks | undefined
    >(undefined);

    const userData = useUserState();

    useEffect(() => {
        if (userData.type === 'initialized' && entityPath) {
            const unsubscribe = firebase
                .firestore()
                .doc(`${entityPath}/${Collection.leaderboard}/${userData.uid}`)
                .onSnapshot((snapshot: DocumentSnapshot) => {
                    if (snapshot.exists) {
                        setLeaderboardEntry(
                            snapshot.data() as LeaderboardEntryWithGroupRanks,
                        );
                    } else {
                        setLeaderboardEntry(undefined);
                    }
                });

            return unsubscribe;
        }
    }, [entityPath, userData]);

    return {
        leaderboardEntry,
        leaderboardSummary,
    };
};

export default useLiveGeneralLeaderboard;
