import styled from '@emotion/styled';
import React, { Component, ContextType } from 'react';
import { Color } from 'src/styles/Constants';
import {
    FeedLeaderboardEntry,
    isNormalEntry,
    SpecialFeedLeaderboardEntry,
} from 'src/services/ServerApi';
import { BLAZERS_RED } from './TVBroadcastView';
import { LocaleContext } from 'src/hooks/useLocale';

interface Props {
    validatedQuestionCount: number;
    entry: FeedLeaderboardEntry | SpecialFeedLeaderboardEntry;
    fontFamily?: string;
}

const MARGIN_RIGHT = '30px';

let defaultStyle = {
    color: Color.T31_BLACK,
    fontFamily: 'United Sans Cond',
    padding: '16px 0',
};

let normalRankStyle = {
    ...defaultStyle,
    width: '129px',
};

let normalNameStyle = {
    ...defaultStyle,
    flexVal: 2,
    fontFamily: 'Trade Gothic LT Std',
    padding: '16px 0 3px 0', // Gothic's font is not vertically centered -_-'.
};

let pointsAndCorrectStyle = {
    ...defaultStyle,
    width: '264px',
};

const MAX_PLAYERS_PER_ROW = 5;

export default class BlazersTvRow extends Component<Props> {
    public static contextType = LocaleContext;
    public context!: ContextType<typeof LocaleContext>;

    // function will only render first player with stats
    private renderSinglePlayerRow = (entry: FeedLeaderboardEntry) => {
        const { validatedQuestionCount } = this.props;
        const { localeId } = this.context;
        const { correctAnswers, players, points, rank } = entry;
        const playerName = players[0];
        const hideTopBorder = rank === 1;

        return (
            <StatsRow key={playerName}>
                <StatValue
                    hideBorder={hideTopBorder}
                    style={normalRankStyle}
                    text={rank.toLocaleString(localeId)}
                />
                <StatValue
                    hideBorder={hideTopBorder}
                    style={normalNameStyle}
                    text={playerName}
                />
                <StatValue
                    hideBorder={hideTopBorder}
                    style={pointsAndCorrectStyle}
                    text={points.toLocaleString(localeId)}
                />
                <StatValue
                    validatedCountText={
                        validatedQuestionCount > 0
                            ? `${validatedQuestionCount}`
                            : ''
                    }
                    hideBorder={hideTopBorder}
                    style={pointsAndCorrectStyle}
                    text={correctAnswers.toLocaleString(localeId)}
                    noRightMargin={true}
                />
            </StatsRow>
        );
    };

    private renderPlayerNameOnlyRow = (player: string) => {
        return (
            <StatsRow key={player}>
                <StatValue
                    hideBorder={true}
                    style={normalRankStyle}
                    text={''}
                    noTopPadding={true}
                />
                <StatValue
                    hideBorder={true}
                    style={normalNameStyle}
                    text={player}
                    noTopPadding={true}
                />
                <StatValue
                    hideBorder={true}
                    style={pointsAndCorrectStyle}
                    text={''}
                    noTopPadding={true}
                />
                <StatValue
                    hideBorder={true}
                    style={pointsAndCorrectStyle}
                    text={''}
                    noTopPadding={true}
                    noRightMargin={true}
                />
            </StatsRow>
        );
    };

    // renders a row with player name only without stats.
    private renderPlayerNameRow = (entry: FeedLeaderboardEntry) => {
        const { players } = entry;

        const playerNames = [];

        // ignore the first user. as we have already rendered them.
        for (let i = 1; i < players.length; i++) {
            if (i === MAX_PLAYERS_PER_ROW) {
                break;
            }

            const player = players[i];

            playerNames.push(this.renderPlayerNameOnlyRow(player));
        }

        const overflowCount = entry.playerCount - (playerNames.length + 1);
        if (overflowCount > 0) {
            playerNames.push(
                this.renderPlayerNameOnlyRow(`+${overflowCount.toString()}`),
            );
        }

        return playerNames;
    };

    private renderTiedRow = (entry: FeedLeaderboardEntry) => {
        return (
            <>
                {this.renderSinglePlayerRow(entry)}
                {this.renderPlayerNameRow(entry)}
            </>
        );
    };

    public render() {
        const { entry } = this.props;
        if (this.props.fontFamily) {
            defaultStyle.fontFamily = this.props.fontFamily;
            normalNameStyle.fontFamily = this.props.fontFamily;
            normalRankStyle.fontFamily = this.props.fontFamily;
            pointsAndCorrectStyle.fontFamily = this.props.fontFamily;
        }

        if (isNormalEntry(entry)) {
            const playerCount = entry.playerCount;

            return (
                <Container>
                    {playerCount === 1 && this.renderSinglePlayerRow(entry)}
                    {playerCount > 1 && this.renderTiedRow(entry)}
                </Container>
            );
        }
    }
}

const Container = styled.div`
    display: flex;
    flex 1;
    flex-direction: column;
`;

const StatValue = (props: {
    text: string;
    style: {
        color: string;
        fontFamily: string;
        flexVal?: number;
        width?: string;
        padding?: string;
    };
    validatedCountText?: string;
    hideBorder?: boolean;
    noTopPadding?: boolean;
    noRightMargin?: boolean;
}) => (
    <StatColumnContainer
        border={props.hideBorder ? 'none' : `2px solid #414042`}
        flexVal={props.style.flexVal}
        padding={props.style.padding}
        width={props.style.width}
        noRightMargin={props.noRightMargin}
    >
        <UserStat color={Color.P1_WHITE} fontFamily={props.style.fontFamily}>
            {props.text}
        </UserStat>
        {props.validatedCountText && (
            <>
                <Slash>/</Slash>
                <UserStat
                    color={BLAZERS_RED}
                    fontFamily={props.style.fontFamily}
                >
                    {props.validatedCountText}
                </UserStat>
            </>
        )}
    </StatColumnContainer>
);

const StatsRow = styled.div`
    display: flex;
    text-align: left;
    width: 100%;
`;

const StatColumnContainer = styled.div<{
    border: string;
    padding?: string;
    flexVal?: number;
    width?: string;
    noRightMargin?: boolean;
}>`
    align-items: center;
    border-top: ${(props) => props.border};
    display: flex;
    flex-direction: row;
    flex: ${(props) => props.flexVal};
    margin-right: ${(props) => (props.noRightMargin ? 0 : MARGIN_RIGHT)};
    width: ${(props) => props.width};
    padding: ${(props) => props.padding};
`;

const UserStat = styled.p<{
    fontFamily: string;
    color?: string;
}>`
    color: ${(props) => (props.color ? props.color : '#000000')};
    font-family: ${(props) => props.fontFamily};
    font-size: 55px;
    font-weight: bold;
    letter-spacing: -0.5px;
    line-height: 55px;
`;

const Slash = styled.span`
    color: #c8102e;
    font-size: 55px;
    font-weight: 200;
    letter-spacing: -0.5px;
    margin: 0px 10px;
`;
