import React from 'react';
import styled from '@emotion/styled';

import { useSponsors } from 'src/contexts/SponsorsContext';
import { UserOffer } from 'src/util/TallyFirestore';
import OfferCard from './OfferCard';

export type OfferWithContext = {
    userOffer: UserOffer;
    pending: boolean; // true if the event hasn't finalized yet, so we don't have a code.
};

type Props = {
    offers: Array<OfferWithContext>;
    onOfferClick: (userOffer: UserOffer) => void;
};

const OfferList = ({ offers, onOfferClick }: Props) => {
    const sponsors = useSponsors();

    return offers.length > 0 ? (
        <Container>
            {offers.map((offer) => {
                const sponsor = sponsors.find(
                    (s) => s.id === offer.userOffer.sponsorId,
                );
                return (
                    <OfferCard
                        key={offer.userOffer.id}
                        userOffer={offer.userOffer}
                        onClick={onOfferClick}
                        pending={offer.pending}
                        sponsorLogoUrl={sponsor && sponsor.wideLogoUrl}
                    />
                );
            })}
        </Container>
    ) : null;
};

export default OfferList;

const Container = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
`;
