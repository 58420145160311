import React, {
    useEffect,
    useState,
    createContext,
    ReactNode,
    useContext,
    RefObject,
} from 'react';
import { Map } from 'immutable';

import request from 'src/services/requestAdServer';
import {
    AdSizingPolicy,
    ServerAd,
    PredictionAd,
} from 'src/util/TallyFirestore';
import { useLocale } from 'src/hooks/useLocale';

const PREDICTION_ID_KEY = 'tally.predicitonId';

type AdContextValue = {
    serverAd: undefined | null | ServerAd;
    predictionIdToAdHeight: Map<string, number>;
};

type Props = {
    children: ReactNode;
    partnerId: string;
};

const initialState = {
    serverAd: undefined,
    predictionIdToAdHeight: Map<string, number>(),
};

export const AdContext = createContext<AdContextValue>(initialState);

const AdContextProvider = AdContext.Provider;

const useServerAd = (partnerId: string) => {
    const { localeId } = useLocale();
    const [serverAd, setServerAd] = useState<undefined | null | ServerAd>(
        undefined,
    );

    useEffect(() => {
        request({ path: `ad/${partnerId}?language_code=${localeId}` })
            .then((ad: ServerAd) => {
                setServerAd(ad);
            })
            .catch((err) => {
                setServerAd(null);
            });
    }, [partnerId, localeId, setServerAd]);

    return serverAd;
};

const useIframedAdHeights = () => {
    const [predictionIdToAdHeight, setPredictionIdToAdHeight] = useState(
        Map<string, number>(),
    );

    useEffect(() => {
        const handleMessage = (e: MessageEvent) => {
            if (
                e.data.hasOwnProperty('frameHeight') &&
                e.data.hasOwnProperty('predictionId')
            ) {
                const { predictionId, frameHeight } = e.data;
                setPredictionIdToAdHeight((prevState) =>
                    prevState.set(predictionId, frameHeight),
                );
            }
        };

        window.addEventListener('message', handleMessage);
    }, [setPredictionIdToAdHeight]);

    return predictionIdToAdHeight;
};

const AdProvider = ({ partnerId, children }: Props) => {
    const serverAd = useServerAd(partnerId);
    const predictionIdToAdHeight = useIframedAdHeights();

    return (
        <AdContextProvider value={{ serverAd, predictionIdToAdHeight }}>
            {children}
        </AdContextProvider>
    );
};

const insertUrlParam = (url: string, key: string, value: string) => {
    let urlObj = new URL(url);
    urlObj.searchParams.set(key, value);

    return urlObj.href;
};

const injectPredictionIdToIframe = (
    predictionId: string,
    iframeString: string,
) => {
    const el = document.createElement('html');
    el.innerHTML = iframeString;
    const iframe = el.querySelector('iframe');
    if (iframe) {
        let src = iframe.getAttribute('src');
        if (src) {
            iframe.setAttribute(
                'src',
                insertUrlParam(src, PREDICTION_ID_KEY, predictionId),
            );
        }
        return iframe.outerHTML;
    }
    return iframeString;
};

export const useAd = (
    predictionAd: PredictionAd,
    predictionId: string,
    parentRef: RefObject<HTMLElement>,
) => {
    const [showReadMoreButton, setShowReadMoreButton] = useState(true);
    const { sizingPolicy, cutOffHeight } = predictionAd;
    const { serverAd, predictionIdToAdHeight } = useContext(AdContext);

    const adHtml = predictionAd.iframeHtml || (serverAd && serverAd.iframeHtml);

    const observedAdHeight = predictionIdToAdHeight.get(predictionId);

    useEffect(() => {
        const iframe =
            parentRef.current && parentRef.current.querySelector('iframe');

        if (iframe) {
            iframe.scrolling = 'no';
            iframe.frameBorder = '0';
            if (observedAdHeight) {
                if (sizingPolicy === AdSizingPolicy.cutOff) {
                    iframe.style.width = '100%';
                    iframe.style.height =
                        Math.min(cutOffHeight, observedAdHeight) + 'px';
                } else if (sizingPolicy === AdSizingPolicy.auto) {
                    iframe.style.width = '100%';
                    iframe.style.height = observedAdHeight + 'px';
                } else if (sizingPolicy === AdSizingPolicy.readMore) {
                    iframe.style.width = '100%';
                    iframe.style.height =
                        (showReadMoreButton
                            ? Math.min(cutOffHeight, observedAdHeight)
                            : observedAdHeight) + 'px';
                }
            }
            if (sizingPolicy === AdSizingPolicy.cutOff) {
                iframe.style.width = '100%';
                iframe.style.height = cutOffHeight + 'px';
            }
        }
    }, [
        observedAdHeight,
        showReadMoreButton,
        parentRef,
        sizingPolicy,
        cutOffHeight,
    ]);

    const headline = predictionAd.iframeHtml
        ? predictionAd.headline
        : (serverAd && serverAd.headline) || predictionAd.headline || '';

    const disclaimer = predictionAd.iframeHtml
        ? predictionAd.disclaimer
        : (serverAd && serverAd.disclaimer) || predictionAd.disclaimer || '';

    return {
        ad: {
            adHtml: adHtml && injectPredictionIdToIframe(predictionId, adHtml),
            headline,
            disclaimer,
            sizingPolicy: predictionAd.iframeHtml
                ? predictionAd.sizingPolicy
                : AdSizingPolicy.preserveAspectRatio,
        },
        showReadMoreButton,
        setShowReadMoreButton,
    };
};

export default AdProvider;
