import {
    getSponsorFromDocumentSnapshot,
    getSponsorsCollectionPath,
} from 'src/util/FirestoreDao';
import useLiveFirestoreCollection from '../../../hooks/useLiveFirestoreCollection';

const useLiveSponsors = (partnerId: string) => {
    const collectionPath = getSponsorsCollectionPath(partnerId);
    return useLiveFirestoreCollection(
        collectionPath,
        getSponsorFromDocumentSnapshot,
        [],
    );
};

export default useLiveSponsors;
