import { Color } from 'src/styles/Constants';

const COLOR_SQUIRTLE = '#82F2FF';

export const stringToNonNegativeInt = (value: string): number | undefined => {
    const valueWithDigitsOnly = value.replace(/\D/g, '');

    if (valueWithDigitsOnly === '') {
        return undefined;
    }

    return parseInt(valueWithDigitsOnly);
};

export const getInactiveBorderColor = (
    predictionLocked: boolean,
    predictionResolved: boolean,
    userIsCorrect?: boolean,
) => {
    if (!predictionLocked) {
        return COLOR_SQUIRTLE;
    }

    if (!predictionResolved) {
        return Color.T28_FOG;
    }

    if (userIsCorrect === undefined) {
        // User did not answer
        return Color.T28_FOG;
    }

    return userIsCorrect ? Color.PREDICTION_GREEN : Color.P4_CHILI_PEPPER;
};
