import { useRef, useState } from 'react';
import styled from '@emotion/styled';

export const SettingHeaderText = styled.h1`
    flex: 1;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 18px;
    text-transform: uppercase;
`;

export const SettingContainer = styled.div<{ marginTop?: string }>`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: ${(props) => props.marginTop || '0'};
    width: 100%;
`;

type OnValueChanged<T> = (b: T) => void;
type UpdateFn<T> = (b: T) => Promise<any>;
type Update<T> = (b: T) => void;

// Use it for switches or radio buttons to manage their immediate update
// use isUpdating value to disable the input
export const useImmediateSetting = <T,>(
    initialValue: T,
    updateFn: UpdateFn<T>,
    onValueChanged: OnValueChanged<T>,
): {
    value: T;
    error: null | Error;
    update: Update<T>;
    isUpdating: boolean;
} => {
    const onValueChangedRef = useRef(onValueChanged);
    onValueChangedRef.current = onValueChanged;

    const [value, setValue] = useState(initialValue);
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const previousValue = useRef(initialValue);

    const update = (newValue: T) => {
        setError(null);
        setIsLoading(true);
        previousValue.current = value;
        setValue(newValue);
        updateFn(newValue)
            .then(() => {
                setIsLoading(false);
                onValueChangedRef.current(newValue);
            })
            .catch((error) => {
                setIsLoading(false);
                setValue(previousValue.current);
                setError(error);
            });
    };

    return { value, error, update, isUpdating: isLoading };
};
