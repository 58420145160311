/* tslint:disable object-literal-sort-keys */
import firebase from 'firebase/app';
import {
    authenticationMethods,
    FirebaseConfig,
    ConfigPartnerData,
    AdditionalDataFieldsTypeEnum,
    DateValidationType,
} from './ConfigTypes';

// For partner-specific GA keys and facebook app ids, see:
// https://docs.google.com/spreadsheets/d/1GtekrtW25XP4oBPsEbDnoaK_L_yNHhxkSkHa216VBgY/edit#gid=0
const MEGAPHONE_INTERNAL_GOOGLE_ANALYTICS_KEY = 'UA-100929221-12';
const MEGAPHONE_INTERNAL_FACEBOOK_APP_ID = '153879262174163';
export const PLAYGROUND_MODE = false;

const firebaseConfigs: { [projectId: string]: FirebaseConfig } = {
    'megaphone-development': {
        apiKey: 'AIzaSyDLAsz5sw78B_9sB7MelJQS3JVftkel5no',
        authDomain: 'megaphone-development.firebaseapp.com',
        databaseURL: 'https://megaphone-development.firebaseio.com',
        messagingSenderId: '268979593375',
        projectId: 'megaphone-development',
        storageBucket: 'megaphone-development.appspot.com',
    },
    'partner-web-development': {
        apiKey: 'AIzaSyAOu-0OcML2W_cCjqZs68PVLXpu05jAEvw',
        authDomain: 'partner-web-development.firebaseapp.com',
        databaseURL: 'https://partner-web-development.firebaseio.com',
        messagingSenderId: '37100540030',
        projectId: 'partner-web-development',
        storageBucket: 'partner-web-development.appspot.com',
    },
    'tally-dev-coherent-2': {
        appId: '1:1081362651808:web:35ee14356d9db10f6607c5',
        apiKey: 'AIzaSyBkqSDgxJPZu5hPI8uy7AdBW5CqXwiK7AQ',
        projectId: 'tally-dev-coherent-2',
        authDomain: 'tally-dev-coherent-2.firebaseapp.com',
        databaseURL: 'https://tally-dev-coherent-2.firebaseio.com',
        storageBucket: 'tally-dev-coherent-2.appspot.com',
        messagingSenderId: '1081362651808',
    },
};

const DEV_PARTNER_ANALYTICS_KEYS = {
    google: MEGAPHONE_INTERNAL_GOOGLE_ANALYTICS_KEY,
};

// to launch a specific partner:
// Apple: export REACT_APP_LOCALHOST_PARTNER=[partner-name]
// Windows: $env:REACT_APP_LOCALHOST_PARTNER=[partner-name]
const partners: { [partnerId: string]: ConfigPartnerData } = {
    tallyansDev: {
        analyticsKeys: {
            ...DEV_PARTNER_ANALYTICS_KEYS,
        },
        authenticationMethod: authenticationMethods.none,
        categoryId: '3c9f927e-292c-4e0b-a688-5387ecd7027b',
        collectEmail: false,
        collectPhone: false,
        environment: 'LOCAL',
        facebookAppId: MEGAPHONE_INTERNAL_FACEBOOK_APP_ID,
        firebaseConfig: firebaseConfigs['partner-web-development'],
        name: 'Tally Internal',
        partnerId: 'tallyansDev',
        properties: {
            headingColor: '#feca22',
            logoUrl:
                'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Concacaf_Gold_Cup_2021.svg/1200px-Concacaf_Gold_Cup_2021.svg.png',
            leaderboardsPage: {
                headlineImage: {
                    EN: 'https://pokeworks.com/wp-content/uploads/2022/01/pokeworks-logo-wide.png',
                    PT: 'https://static.vecteezy.com/system/resources/previews/001/197/191/non_2x/golden-decorative-banner-png.png',
                },
                backgroundImgUrl:
                    'https://www.hdsfoundation.org/wp-content/uploads/dark-blue-background-hd-wallpaper-12829.jpg',
                backgroundColor: '#060925',

                secondaryHeadingTextColor: 'green',
                contentTextColor: 'brown',
            },
            homePageContent: {
                headlineImage: {
                    EN: 'https://pokeworks.com/wp-content/uploads/2022/01/pokeworks-logo-wide.png',
                    PT: 'https://static.vecteezy.com/system/resources/previews/001/197/191/non_2x/golden-decorative-banner-png.png',
                },

                subHeadLineText: {
                    EN: `PLAY & WIN`,
                    PT: `PT: PLAY & WIN`,
                },
                eventListHeadline: {
                    EN: '**PLAY** NOW',
                    PT: '**PT PLAY** NOW',
                },

                backgroundImgUrl:
                    'https://www.hdsfoundation.org/wp-content/uploads/dark-blue-background-hd-wallpaper-12829.jpg',
                backgroundColor: '#060925',

                secondaryHeadingTextColor: 'red',
                contentTextColor: 'red',
                header: {
                    EN: 'Mexico vs USA',
                    PT: 'PT Mexico vs USA',
                },
                subHeader: {
                    EN: '3pm KO - 06/10',
                    PT: 'PT 3pm KO - 06/10',
                },
                bodyMarkdown: {
                    EN: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                    ![Footbal game](https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/Football_in_Bloomington%2C_Indiana%2C_1996.jpg/1920px-Football_in_Bloomington%2C_Indiana%2C_1996.jpg)`,

                    PT: `PT Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                    ![Footbal game](https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/Football_in_Bloomington%2C_Indiana%2C_1996.jpg/1920px-Football_in_Bloomington%2C_Indiana%2C_1996.jpg)`,
                },
            },
            leaderboardBanner: {
                EN: {
                    imageUrl:
                        'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ef/Pantheon_Rom_1_cropped.jpg/405px-Pantheon_Rom_1_cropped.jpg',
                    linkUrl: 'https://en.wikipedia.org/wiki/Pantheon,_Rome',
                },
                PT: {
                    imageUrl:
                        'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1e/Collage_Venezia.jpg/1280px-Collage_Venezia.jpg',
                    linkUrl: 'https://en.wikipedia.org/wiki/Venice',
                },
            },
            // personalSettingsPageContent: {
            //     deleteAccountLink: {
            //         active: true,
            //         label: {
            //             EN: 'Delete me',
            //             PT: 'Me exclua'
            //         }
            //     }
            // },
            tallyOptInIsActive: true,
            tallyOptInLabel: {
                EN: 'Submit button text right on the button',
                PT: 'submitButtonLabel in PT translation',
            },
            onboardingDisclaimer: {
                EN: 'By continuing you confirm that you are 19 years or older and agree to',
                PT: 'Ao continuar você confirma ser maior de 19 anos e que concorda com nosso regulamento de',
                'EN-AU':
                    'By continuing you confirm that you are 19 years or older and agree to',
            },
            initialUserConsent: {
                analytics: false,
            },
            countriesActive: ['IN', 'US', 'PK', 'LK', 'DE'],
            countrySelected: 'IN',
            supportedLanguages: ['EN', 'PT'],
            // dataCollection: {
            //     headline: { EN: 'Extra Fields', PT: 'Entrada Extra' },
            //     description: {
            //         EN: 'Some description ...',
            //         PT: 'Alguma descrição',
            //     },
            //     fields: [
            //         {
            //             id: 'sex',
            //             label: {
            //                 EN: 'Select your sex in dropdown EN',
            //                 PT: 'Select your sex in dropdown PT',
            //             },
            //             type: AdditionalDataFieldsTypeEnum.SELECT,
            //             options: [
            //                 {
            //                     label: {
            //                         EN: 'Male en',
            //                         PT: 'Male pt',
            //                     },
            //                     value: 'male',
            //                 },
            //                 {
            //                     label: {
            //                         EN: 'Female en',
            //                         PT: 'Female pt',
            //                     },
            //                     value: 'female',
            //                 },
            //                 {
            //                     label: {
            //                         EN: 'Alien en',
            //                         PT: 'Alien pt',
            //                     },
            //                     value: 'alien',
            //                 },
            //             ],
            //             validationRegexp: `^(male|female)$`,
            //             validationErrorText: {
            //                 EN: 'Aliens not welcome, we are xenophobic EN',
            //                 PT: 'Aliens not welcome, we are xenophobic PT',
            //             },
            //         },
            //         {
            //             id: 'dob',
            //             label: {
            //                 EN: 'Date of birth (older than 18yo)',
            //                 PT: 'PT: Date of birth (older than 18yo)',
            //             },
            //             type: AdditionalDataFieldsTypeEnum.DATE,
            //             dateValidation: {
            //                 type: DateValidationType.OLDER_THAN_Y_O,
            //                 olderThanYO: 18,
            //             },
            //             validationErrorText: {
            //                 EN: 'Age must b >=18',
            //                 PT: 'O primeiro nome deve ser alfa',
            //             },
            //         },
            //         {
            //             id: 'after',
            //             label: {
            //                 EN: 'Date after 2021-01-01',
            //                 PT: 'PT: Date after 2021-01-01',
            //             },
            //             type: AdditionalDataFieldsTypeEnum.DATE,
            //             dateValidation: {
            //                 type: DateValidationType.AFTER,
            //                 dateFrom: new Date('2021-01-01'),
            //             },
            //             validationErrorText: {
            //                 EN: 'Date must b after 2021-01-01',
            //                 PT: 'O primeiro nome deve ser alfa',
            //             },
            //         },
            //         {
            //             id: 'before',
            //             label: {
            //                 EN: 'Date before 2021-10-12',
            //                 PT: 'PT: Date before 2021-10-12',
            //             },
            //             type: AdditionalDataFieldsTypeEnum.DATE,
            //             dateValidation: {
            //                 type: DateValidationType.BEFORE,
            //                 dateTo: new Date('2021-10-12'),
            //             },
            //             validationErrorText: {
            //                 EN: 'Date must b before 2021-10-12',
            //                 PT: 'O primeiro nome deve ser alfa',
            //             },
            //         },
            //         {
            //             id: 'between',
            //             label: {
            //                 EN: 'Date between 2021-01-01 & 2021-12-31',
            //                 PT: 'PT: Date between 2021-01-01 & 2021-12-31',
            //             },
            //             type: AdditionalDataFieldsTypeEnum.DATE,
            //             dateValidation: {
            //                 type: DateValidationType.BETWEEN,
            //                 dateFrom: new Date('2021-01-01'),
            //                 dateTo: new Date('2021-12-31'),
            //             },
            //             validationErrorText: {
            //                 EN: 'Date must be between 2021-01-01 & 2021-12-31',
            //                 PT: 'O primeiro nome deve ser alfa',
            //             },
            //         },
            //     ],
            // },
            // onboardingAgreement: {
            //     text: {
            //         EN: 'Are you winning, son? Details',
            //         PT: 'In PT: Are you winning, son? Details',
            //     },
            //     heading: {
            //         EN: 'Are you winning, son?',
            //         PT: 'In PT: Are you winning, son?',
            //     },
            //     agreeButtonLabel: {
            //         EN: 'Yes!',
            //         PT: 'In PT: Yes!',
            //     },
            //     disagreeButtonLabel: {
            //         EN: 'ALT+TAB',
            //         PT: 'In PT: ALT+TAB',
            //     },
            //     redirectUrl: 'https://www.google.com/',
            //     forceRecurrentAgreementDisplay: false,
            // },
            optInValidSince: firebase.firestore.Timestamp.fromDate(
                new Date('Sat Jun 04 2022 17:28:06 GMT+0300'),
            ),
            optionalOptInEnabled: true,
            emailReOptInIsMandatory: false,
            smsReOptInIsMandatory: false,
            optionalReOptInIsMandatory: false,
            marketingReOptInMessage: {
                EN: 'marketingReOptInMessage is enter email here',
                PT: 'In PT: marketingReOptInMessage is enter email here',
            },

            navLogoUrl: 'https://assets.playtally.com/tally/tally-square.png',
            // appEmbedded: true,
            embeddedUtmValues: ['app'],
            infoModuleStayTunedBody: {
                EN: "Watch your rank update in realtime as the action unfolds. Get in on another set of picks during halftime—more points will be on the line, so don't miss out!",
            },
            infoModuleStayTunedTitle: {
                EN: 'More predictions at halftime',
            },
            blueBoxPromoImageUrl: {
                EN: 'https://assets.playtally.com/partners/ipl/images/hiq-win-weekly.png',
                PT: 'https://i.pinimg.com/736x/d6/a5/91/d6a591050bd9cb72a2dad618a6760bcb.jpg',
            },
            smsMarketingLabel: {
                EN: 'Get Blues **Texts**\n\nIn EN from config',
                PT: 'Get Blues **Texts**\n\nin PT from config',
            },
            emailMarketingLabel: {
                EN: 'Get Blues **Emails**\n\nIn EN from config',
                PT: 'Get Blues **Emails**\n\nIn PT from config',
            },
            marketingOptInTitle: {
                EN: '## Marketing Opt In Title\n\nMarketing Opt In Title\n\nIn EN from config',
                PT: '## Marketing Opt In Title in PT\n\nMarketing Opt In Title\n\nIn PT from config',
            },
            marketingOptInMessageOverride: {
                EN: 'Enter **personal** data  here\n\nIn EN from config',
                PT: 'Enter **personal** data  here\n\nIn PT from config',
            },
            waitingRoomDetailsUrl: {
                EN: 'https://avatars1.githubusercontent.com/u/6990603?s=460&v=4',
                PT: 'https://avatars1.githubusercontent.com/u/6990604?s=460&v=4',
            },
            waitingRoomHeadline: {
                EN: 'Win Season Tickets',
                PT: 'PT: Season Tickets',
            },
            waitingRoomSubHeadline: {
                EN: 'Compete for the grand prize',
                PT: 'PT: Compete for the grand prize',
            },
            blueBoxPromoLink: {
                EN: 'https://www.cnn.com',
                PT: 'https://google.com',
            },
            addDisclaimer: new Array(
                {
                    EN: {
                        displayText: 'Privacy Policy',
                        linkUrl: 'https://pechanga.com',
                    },
                    PT: {
                        displayText: 'Privacy Policy in PT',
                        linkUrl: 'https://google.com',
                    },
                },
                {
                    EN: {
                        displayText: 'Contest Rules',
                        linkUrl: 'https://pechanga.com',
                    },
                    PT: {
                        displayText: 'Contest Rules in PT',
                        linkUrl: 'https://google.com',
                    },
                },
                {
                    EN: {
                        displayText: 'Terms and Conditions',
                        linkUrl: 'https://playtally.com',
                    },
                    PT: {
                        displayText: 'Terms and Conditions in PT',
                        linkUrl: 'https://google.com',
                    },
                },
            ),
            gameDisclaimer: {
                EN: 'Game responsibly and please also refer to ',
                PT: 'in PT: Game responsibly and please also refer to ',
            },
            surveyUrl: {
                EN: 'https://www.therams.com/pickem/rules',
                PT: 'https://google.com',
            },
            rulesUrl: {
                EN: 'https://www.therams.com/pickem/rules',
                PT: 'https://google.com',
            },
            showLeaderboardPrizeMarkdown: false,
            smsMarketingEnabled: true,
            collectIterationUpdate: true,
            emailOptInIsMandatory: false,
            smsOptInIsMandatory: false,
            optionalOptInIsMandatory: false,
            termsUrl: {
                EN: 'https://playtally.com/policies/terms',
                PT: 'https://google.com',
            },
            privacyUrl: {
                EN: 'https://www.playtally.com/policies/privacy',
                PT: 'https://google.com',
            },
            privacyLabel: {
                EN: 'EN privacyLabel',
                PT: 'PT privacyLabel',
            },
            termsLabel: {
                EN: 'EN termsLabel',
                PT: 'PT termsLabel',
            },
            rulesLabel: {
                EN: 'EN rulesLabel',
                PT: 'PT rulesLabel',
            },

            onboardingRulesCheckboxDescription: {
                EN: 'This is checkbox description right above it',
                PT: 'fieldCheckboxMessageDescription in PT translation',
            },
            onboardingRulesCheckboxField: {
                EN: 'checkboxField is near checkbox itself',
                PT: 'checkboxField in PT translation',
            },
            onboardingRulesFieldTitle: {
                EN: 'fieldTitle in EN translation',
                PT: 'fieldTitle in PT translation',
            },
            onboardingRulesFieldDescription: {
                EN: `Very long text as description is here:
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ultrices efficitur nulla maximus pharetra. Praesent consequat arcu quis iaculis consequat. Nulla ac magna vulputate, sagittis purus vitae, convallis ante. Sed ut turpis ultricies, accumsan elit non, dictum neque. Pellentesque sed purus vel odio laoreet cursus. Nullam nec convallis nulla, sed pharetra sem. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                `,
                PT: 'fieldDescription in PT translation',
            },
            onboardingRulesFieldPlaceholder: {
                EN: 'fieldPlaceholder in EN translation',
                PT: 'fieldPlaceholder in PT translation',
            },
            onboardingRulesSubmitButtonLabel: {
                EN: 'Submit button text right on the button',
                PT: 'submitButtonLabel in PT translation',
            },
            onboardingRulesTile: {
                EN: 'Tile title in header',
                PT: 'tile in PT translation',
            },
            socialShare: {
                title: 'Play Tallyans Dev Games',
                description:
                    'Official Tallyans Dev free-to-play game. Predict the action for a chance to win prizes!',
                languageInfo: {
                    defaultLanguageId: 'EN',
                    entityTranslations: [
                        {
                            title: 'Jogue jogos de Tallyans Dev',
                            languageId: 'PT',
                            description:
                                'Jogo oficial gratuito de Tallyans Dev. Preveja a ação para ter a chance de ganhar prêmios!',
                        },
                    ],
                },
            },
        },
    },
    play: {
        collectEmail: true,
        analyticsKeys: {
            google: 'UA-158674388-2',
        },
        name: 'Tally Dev',
        smsAlertsEnabled: true,
        authenticationMethod: authenticationMethods.phone,
        facebookAppId: '976277666965921',
        primarySmsPhoneNumber: '+1 800 555 1234',
        properties: {
            dataCollection: {
                fields: [
                    {
                        id: 'first_name',
                        type: AdditionalDataFieldsTypeEnum['TEXT'],
                        label: {
                            EN: 'First Name',
                        },
                        validationRegexp: '^[^"]*$',
                        validationErrorText: {
                            EN: 'Please use characters only',
                        },
                    },
                    {
                        id: 'last_name',
                        type: AdditionalDataFieldsTypeEnum['TEXT'],
                        label: {
                            EN: 'Last Name',
                        },
                        validationRegexp: '^[^"]*$',
                        validationErrorText: {
                            EN: 'Please use characters only',
                        },
                    },
                    {
                        id: 'zip',
                        type: AdditionalDataFieldsTypeEnum['TEXT'],
                        label: {
                            EN: 'ZIP',
                        },
                        validationRegexp: '^[0-9]{5}$',
                        validationErrorText: {
                            EN: 'Please enter your 5 digit ZIP code',
                        },
                    },
                    {
                        id: 'dob',
                        type: AdditionalDataFieldsTypeEnum['DATE'],
                        label: {
                            EN: 'Date of birth',
                        },
                        dateValidation: {
                            type: DateValidationType['OLDER_THAN_Y_O'],
                            olderThanYO: 21,
                        },
                        validationErrorText: {
                            EN: 'Not eligible to enter promotion',
                        },
                    },
                ],
                headline: {
                    EN: 'Your Information',
                },
                description: {
                    EN: 'Please tell us a little bit more about yourself:',
                },
            },
            unbrandUI: false,
            smsAlertsExternal: true,
            //smsAlertExternalMsg: { EN: "This is english", DE: "Und dies ist deutsch" },

            tournamentHeaderBackgroundColor: '#0E2050',
            tournamentHeaderGraphicUrl:
                'https://www-league.nhlstatic.com/images/logos/league-dark/133-flat.svg',
            privacyUrl: {
                EN: 'https://www.pepsico.com/legal/privacy',
            },
            rulesUrl: {
                EN: 'https://s3.us-west-1.amazonaws.com/megaphone-assets.playtally.com/concacaf/sponsors/concacaf/img/ENGLISH%20RULES%20FINAL%2027-06.pdf',
                ES: 'https://s3.us-west-1.amazonaws.com/megaphone-assets.playtally.com/concacaf/sponsors/concacaf/img/Spanish%20Rules%20Final%2027-06.pdf',
            },
            surveyUrl: {
                EN: '',
            },
            termsUrl: {
                EN: 'https://contact.pepsico.com/pepsi/terms-conditions',
            },

            addDisclaimer: [
                {
                    EN: {
                        linkUrl:
                            'https://www.playtally.com/policies/privacy/tally-privacy-policy',
                        displayText: 'Tally Privacy Policy',
                    },
                    ES: {
                        linkUrl:
                            'https://s3.us-west-1.amazonaws.com/megaphone-assets.playtally.com/concacaf/img/Tally-Privacy-Policy-TSG-Es%20Arial.pdf',
                        displayText: 'Tally política de privacidad',
                    },
                },
            ],
            waitingRoomToastBackgroundColor: '#0000ff',
            modalBackgroundColor: '#ddc9a3',
            waitingRoomBackgroundColor: '#0E2050',

            stayTunedTileLoadingDotsColor: '#0E2050',
            logoUrl:
                'https://s3.us-west-1.amazonaws.com/megaphone-assets.playtally.com/nbbx/img/newCropPepsiAssets_Challenge_300x600_Transparent%20%281%29%20%281%29.png',
            navBarBackgroundColor: '#0E2050',
            navLogoUrl:
                'https://s3.us-west-1.amazonaws.com/megaphone-assets.playtally.com/nbbx/img/Champions_League_Logo.png',
            navBarTextColor: '#5AF7DC',
            logoSizeWaitingRoom: '140',
            logoSizeLoadingScreen: '140',
            leaderboardHorizontalRuleColor: '#0E2050',
            leaderboardPrizeTextColor: '#0E2050',
            onboardingToastColor: '#0E2050',

            smsMarketingEnabled: false,
            waitingRoomToastInvertTextColor: true,
            newPredictionBackgroundColor: '#0E2050',
            leaderboardCurrentUserTextColor: '#0e4431',
            seesawLottieColor: [0, 0, 0, 1],
            embeddedUtmValues: ['app', 'bot'],
            //liveIconUrl:
            //    'https://s3-us-west-2.amazonaws.com/asset-archive.playtally.com/core/Small-Red-Circle.svg',
            //predictionLiveIconUrl:
            //    'https://s3-us-west-2.amazonaws.com/asset-archive.playtally.com/core/Small-Red-Circle.svg',
            unbrandNewPredictions: false,
            predictionAnimGifSecs: 5,
            onboardingPhoneAuthMessage: {
                EN: 'Sign up for free to test your knowledge and to enteri to win Exclusive Prizes. Enter your mobile phone number to get started.',
            },
            emailMarketingLabel: {
                EN: 'Please share my information with PepsiCo to receive email from PepsiCo Tasty Rewards, PepsiCo and its brands so I never miss out on exciting updates, offers or sweepstakes. I acknowledge and agree to PepsiCo’s Privacy Policy and Terms of Use.',
                ES: 'Por favor, comparta mi información con PepsiCo para recibir correos electrónicos de PepsiCo Tasty Rewards, PepsiCo y sus marcas para que nunca me pierda actualizaciones, ofertas o sorteos interesantes. Reconozco y acepto la política de privacidad y las condiciones de uso de PepsiCo.',
            },
            optionalOptInEnabled: true,
            optionalOptInLabel: {
                EN: 'I agree to the [OFFICIAL RULES](https://s3.us-west-1.amazonaws.com/megaphone-assets.playtally.com/concacaf/sponsors/concacaf/img/ENGLISH%20RULES%20FINAL%2027-06.pdf).*',
            },
            optionalOptInIsMandatory: true,

            disableClientOptInChange: true,
            tallyOptInIsActive: true,
            gameDisclaimer: {
                EN: 'config game disclaimer',
                PT: 'config game disclaimer PT',
                ES: 'config game disclaimer ES',
            },
            countriesActive: ['US'],
            countrySelected: 'US',

            homePageContent: {
                headlineImage: {
                    EN: 'https://pokeworks.com/wp-content/uploads/2022/01/pokeworks-logo-wide.png',
                    PT: 'https://static.vecteezy.com/system/resources/previews/001/197/191/non_2x/golden-decorative-banner-png.png',
                },
                subHeadLineText: {
                    EN: `PLAY & WIN`,
                    PT: `PT: PLAY & WIN`,
                },
                secondaryHeadingTextColor: '#ff0000',
                eventListHeadline: {
                    EN: 'Guten **Tag**',
                    PT: '**PT PLAY** NOW',
                },
                eventListHeadlineColor: '#ff00ff',

                backgroundImgUrl:
                    'https://s3.us-west-1.amazonaws.com/megaphone-assets.playtally.com/play/img/background.jpg',
                backgroundImgLock: 'true',
                backgroundColor: '#060925',
                header: {
                    EN: 'Mexico vs USA',
                    PT: 'PT Mexico vs USA',
                },
                subHeader: {
                    EN: '3pm KO - 06/10',
                    PT: 'PT 3pm KO - 06/10',
                },
                bodyMarkdown: {
                    EN: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                    ![Footbal game](https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/Football_in_Bloomington%2C_Indiana%2C_1996.jpg/1920px-Football_in_Bloomington%2C_Indiana%2C_1996.jpg)`,

                    PT: `PT Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                    ![Footbal game](https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/Football_in_Bloomington%2C_Indiana%2C_1996.jpg/1920px-Football_in_Bloomington%2C_Indiana%2C_1996.jpg)`,
                },
            },
            marketingPreferencesHowTo: {
                EN: 'Go to [Rams](https://therams.com)',
            },
            supportedLanguages: ['EN', 'ES'],
            //predictionLiveIconBackgroundColor: '#00ff00',
            collectIterationUpdate: true,
            disableConsent: false,
            appEmbedded: true,
        },
        environment: 'PROD',
        categoryId: 'c37f4585-21b4-4c2c-b024-a6c79036cc7f',
        collectPhone: false,
        shortName: 'Wild (S)',
        firebaseConfig: {
            appId: '1:529637969450:web:ccf84fc73605bde4503d53',
            apiKey: 'AIzaSyA5HwgijpEvf5tcYNkXVIXkuIDoUYrLp4g',
            projectId: 'play-catchall',
            authDomain: 'play-catchall.firebaseapp.com',
            databaseURL: 'https://play-catchall.firebaseio.com',
            storageBucket: 'play-catchall.appspot.com',
            messagingSenderId: '529637969450',
        },
        partnerId: 'play',
    },
    devcoherent2: {
        collectEmail: false,
        analyticsKeys: {
            google: 'UA-158674388-2',
        },
        name: 'Tally Dev',
        smsAlertsEnabled: false,
        authenticationMethod: authenticationMethods.none,
        facebookAppId: '976277666965921',
        properties: {
            hideLeaderboards: true,
            homePageContent: {
                headlineImage: {
                    EN: 'https://pokeworks.com/wp-content/uploads/2022/01/pokeworks-logo-wide.png',
                    PT: 'https://static.vecteezy.com/system/resources/previews/001/197/191/non_2x/golden-decorative-banner-png.png',
                },
                subHeadLineText: {
                    EN: `PLAY & WIN`,
                    PT: `PT: PLAY & WIN`,
                },
                eventListHeadline: {
                    EN: '**PLAY** NOW',
                    PT: '**PT PLAY** NOW',
                },
                funEventListHeadline: {
                    EN: '**PLAY** FOR FUN',
                    PT: '**PT PLAY** FOR FUN',
                },
                backgroundImgUrl:
                    'https://www.hdsfoundation.org/wp-content/uploads/dark-blue-background-hd-wallpaper-12829.jpg',
                backgroundColor: '#060925',
                header: {
                    EN: 'Mexico vs USA',
                    PT: 'PT Mexico vs USA',
                },
                subHeader: {
                    EN: '3pm KO - 06/10',
                    PT: 'PT 3pm KO - 06/10',
                },
                bodyMarkdown: {
                    EN: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                    ![Footbal game](https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/Football_in_Bloomington%2C_Indiana%2C_1996.jpg/1920px-Football_in_Bloomington%2C_Indiana%2C_1996.jpg)`,

                    PT: `PT Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                    ![Footbal game](https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/Football_in_Bloomington%2C_Indiana%2C_1996.jpg/1920px-Football_in_Bloomington%2C_Indiana%2C_1996.jpg)`,
                },
            },

            // dataCollection: {
            //     headline: { EN: 'Extra Fields', PT: 'Entrada Extra' },
            //     description: {
            //         EN: 'Some description ...',
            //         PT: 'Alguma descrição',
            //     },
            //     fields: [
            //         {
            //             id: 'sex',
            //             label: {
            //                 EN: 'Select your sex in dropdown EN',
            //                 PT: 'Select your sex in dropdown PT',
            //             },
            //             type: AdditionalDataFieldsTypeEnum.SELECT,
            //             options: [
            //                 {
            //                     label: {
            //                         EN: 'Male en',
            //                         PT: 'Male pt',
            //                     },
            //                     value: 'male',
            //                 },
            //                 {
            //                     label: {
            //                         EN: 'Female en',
            //                         PT: 'Female pt',
            //                     },
            //                     value: 'female',
            //                 },
            //                 {
            //                     label: {
            //                         EN: 'Alien en',
            //                         PT: 'Alien pt',
            //                     },
            //                     value: 'alien',
            //                 },
            //             ],
            //             validationRegexp: `^(male|female)$`,
            //             validationErrorText: {
            //                 EN: 'Aliens not welcome, we are xenophobic EN',
            //                 PT: 'Aliens not welcome, we are xenophobic PT',
            //             },
            //         },
            //         {
            //             id: 'dob',
            //             label: {
            //                 EN: 'Date of birth (older than 18yo)',
            //                 PT: 'PT: Date of birth (older than 18yo)',
            //             },
            //             type: AdditionalDataFieldsTypeEnum.DATE,
            //             dateValidation: {
            //                 type: DateValidationType.OLDER_THAN_Y_O,
            //                 olderThanYO: 18,
            //             },
            //             validationErrorText: {
            //                 EN: 'Age must b >=18',
            //                 PT: 'O primeiro nome deve ser alfa',
            //             },
            //         },
            //         {
            //             id: 'after',
            //             label: {
            //                 EN: 'Date after 2021-01-01',
            //                 PT: 'PT: Date after 2021-01-01',
            //             },
            //             type: AdditionalDataFieldsTypeEnum.DATE,
            //             dateValidation: {
            //                 type: DateValidationType.AFTER,
            //                 dateFrom: new Date('2021-01-01'),
            //             },
            //             validationErrorText: {
            //                 EN: 'Date must b after 2021-01-01',
            //                 PT: 'O primeiro nome deve ser alfa',
            //             },
            //         },
            //         {
            //             id: 'before',
            //             label: {
            //                 EN: 'Date before 2021-10-12',
            //                 PT: 'PT: Date before 2021-10-12',
            //             },
            //             type: AdditionalDataFieldsTypeEnum.DATE,
            //             dateValidation: {
            //                 type: DateValidationType.BEFORE,
            //                 dateTo: new Date('2021-10-12'),
            //             },
            //             validationErrorText: {
            //                 EN: 'Date must b before 2021-10-12',
            //                 PT: 'O primeiro nome deve ser alfa',
            //             },
            //         },
            //         {
            //             id: 'between',
            //             label: {
            //                 EN: 'Date between 2021-01-01 & 2021-12-31',
            //                 PT: 'PT: Date between 2021-01-01 & 2021-12-31',
            //             },
            //             type: AdditionalDataFieldsTypeEnum.DATE,
            //             dateValidation: {
            //                 type: DateValidationType.BETWEEN,
            //                 dateFrom: new Date('2021-01-01'),
            //                 dateTo: new Date('2021-12-31'),
            //             },
            //             validationErrorText: {
            //                 EN: 'Date must be between 2021-01-01 & 2021-12-31',
            //                 PT: 'O primeiro nome deve ser alfa',
            //             },
            //         },
            //     ],
            // },
            onboardingAgreement: {
                text: {
                    EN: 'Are you winning, son? // Details',
                    PT: 'In PT: Are you winning, son? // Details',
                },
                heading: {
                    EN: 'Are you winning, son?',
                    PT: 'In PT: Are you winning, son?',
                },
                agreeButtonLabel: {
                    EN: 'Yes!',
                    PT: 'In PT: Yes!',
                },
                disagreeButtonLabel: {
                    EN: 'ALT+TAB',
                    PT: 'In PT: ALT+TAB',
                },
                redirectUrl: 'https://www.google.com/',
            },
            optInValidSince: firebase.firestore.Timestamp.fromDate(
                new Date('Thu May 26 2022 13:24:51 GMT+0300'),
            ),
            emailOptInIsMandatory: false,
            supportedLanguages: ['EN', 'PT'],
            smsMarketingLabel: {
                EN: 'Get Blues **Texts**\n\nIn EN from config',
                PT: 'Get Blues **Texts**\n\nin PT from config',
            },
            emailMarketingLabel: {
                EN: 'Get Blues **Emails**\n\nIn EN from config',
                PT: 'Get Blues **Emails**\n\nIn PT from config',
            },
            marketingOptInTitle: {
                EN: '## Marketing Opt In Title\n\nMarketing Opt In Title\n\nIn EN from config',
                PT: '## Marketing Opt In Title in PT\n\nMarketing Opt In Title\n\nIn PT from config',
            },
            marketingOptInMessageOverride: {
                EN: 'Enter **email** here\n\nIn EN from config',
                PT: 'Enter **email** here\n\nIn PT from config',
            },
            logoUrl:
                'https://s3.us-west-1.amazonaws.com/megaphone-assets.playtally.com/concacaf/sponsors/concacaf/img/logo-2.png',
            leaderboardPrizeTextColor: '#0042C5',
            logoSizeWaitingRoom: '250px',
            modalBackgroundColor: '#041D42',
            navLogoUrl:
                'https://s3.us-west-1.amazonaws.com/megaphone-assets.playtally.com/concacaf/sponsors/concacaf/img/icon-100_100.png',
            onboardingToastColor: '#00349C',
            seesawLottieColor: [1, 0.749, 0.196, 1],
            stayTunedTileLoadingDotsColor: '#00349C',
            tournamentHeaderBackgroundColor: '#042042',
            tournamentHeaderGraphicUrl:
                'https://assets.playtally.com/partners/blues/dark_bg.jpg',
            optInDefault: false,
            supportEmail: 'support@poweredbytally.com',

            embeddedUtmValues: ['app', 'bot'],
            smsMarketingEnabled: true,
            termsUrl: {
                EN: 'https://playtally.com/policies/terms',
                PT: 'https://google.com',
            },
            privacyUrl: {
                EN: 'https://www.playtally.com/policies/privacy',
                PT: 'https://google.com',
            },
            privacyLabel: {
                EN: 'EN privacyLabel',
                PT: 'PT privacyLabel',
            },
            termsLabel: {
                EN: 'EN termsLabel',
                PT: 'PT termsLabel',
            },
            rulesLabel: {
                EN: 'EN rulesLabel',
                PT: 'PT rulesLabel',
            },

            onboardingRulesCheckboxDescription: {
                EN: 'This is checkbox description right above it',
                PT: 'fieldCheckboxMessageDescription in PT translation',
            },
            onboardingRulesCheckboxField: {
                EN: 'checkboxField is near checkbox itself',
                PT: 'checkboxField in PT translation',
            },
            onboardingRulesFieldTitle: {
                EN: 'fieldTitle in EN translation',
                PT: 'fieldTitle in PT translation',
            },
            onboardingRulesFieldDescription: {
                EN: `Very long text as description is here:
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ultrices efficitur nulla maximus pharetra. Praesent consequat arcu quis iaculis consequat. Nulla ac magna vulputate, sagittis purus vitae, convallis ante. Sed ut turpis ultricies, accumsan elit non, dictum neque. Pellentesque sed purus vel odio laoreet cursus. Nullam nec convallis nulla, sed pharetra sem. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                `,
                PT: 'fieldDescription in PT translation',
            },
            onboardingRulesFieldPlaceholder: {
                EN: 'fieldPlaceholder in EN translation',
                PT: 'fieldPlaceholder in PT translation',
            },
            onboardingRulesSubmitButtonLabel: {
                EN: 'Submit button text right on the button',
                PT: 'submitButtonLabel in PT translation',
            },
            onboardingRulesTile: {
                EN: 'Tile title in header',
                PT: 'tile in PT translation',
            },
            rulesUrl: {
                EN: 'https://playtally.com/policies/rules-blues',
            },
            tallyOptInIsActive: true,
            tallyOptInLabel: {
                EN: 'Submit button text right on the button',
                PT: 'submitButtonLabel in PT translation',
            },
            onboardingPhoneAuthBannerUrl:
                'https://s3.us-west-1.amazonaws.com/megaphone-assets.playtally.com/ad-images-retool/RP_2021-22_5050%20AD_Playoffs_600x300%28Generic%29.jpg',

            headingColor: '#fec925',
        },
        environment: 'LOCAL',
        categoryId: 'b955428d-9dc7-4733-9651-44464f5695fe',
        // categoryId: '2fc8f216-6d52-4434-8645-9bf4512e62ad',
        collectPhone: false,
        shortName: 'Wild (S)',
        firebaseConfig: {
            appId: '1:1081362651808:web:35ee14356d9db10f6607c5',
            apiKey: 'AIzaSyBkqSDgxJPZu5hPI8uy7AdBW5CqXwiK7AQ',
            projectId: 'tally-dev-coherent-2',
            authDomain: 'tally-dev-coherent-2.firebaseapp.com',
            databaseURL: 'https://tally-dev-coherent-2.firebaseio.com',
            storageBucket: 'tally-dev-coherent-2.appspot.com',
            messagingSenderId: '1081362651808',
        },
        partnerId: 'devcoherent2',
    },
};

const LOCALHOST_PARTNER_NAME =
    process.env.REACT_APP_LOCALHOST_PARTNER || 'tallyansDev';

// console.log('Running on ' + process.env.REACT_APP_LOCALHOST_PARTNER);
// prettier-ignore
// tslint:disable:object-literal-sort-keys
const HOSTNAME_MAPPINGS: { [hostname: string]: ConfigPartnerData } = {
    // Localhost mapping
    localhost: {
        ...partners[LOCALHOST_PARTNER_NAME], // see README.md on how to customize this for your own development
    },

    // Developer machines mappings
    '10.12.3.59': partners.julianDev, // Julian's laptop
    '10.0.0.229': partners.julianDev, // Julian's laptop at home
    '10.12.3.78': partners.chennanDev, // Chennan's laptop
    '192.168.86.29': partners.chennanDev, // Chennan's laptop
    '192.168.50.23': partners.julianDev,
    '172.16.0.23': partners.rupertDev, // on AWS WS
    '192.168.68.105': partners.play, // on AWS WS
};
export function getPartnerDataFromHostname() {
    return HOSTNAME_MAPPINGS[window.location.hostname.toLowerCase()];
}
