import {
    TranslatableEventModel,
    PredictionModel,
    SponsorModel,
} from './TallyFirestore';

export const getGameWideSponsor = (
    event: Pick<TranslatableEventModel, 'sponsorship'>,
    sponsors: SponsorModel[],
): SponsorModel | undefined => {
    return sponsors.find(
        (sponsor) =>
            event.sponsorship && event.sponsorship.sponsorId === sponsor.id,
    );
};

export const getBonusSponsors = (
    sponsors: SponsorModel[],
    predictions: PredictionModel[],
): SponsorModel[] => {
    const bonusSponsors = new Set<SponsorModel>();
    for (const prediction of predictions) {
        if (prediction.sponsorship) {
            const sponsorId = prediction.sponsorship.sponsorId;
            const sponsor = sponsors.find((s) => s.id === sponsorId);
            if (sponsor) {
                bonusSponsors.add(sponsor);
            }
        }
    }

    return Array.from(bonusSponsors);
};
