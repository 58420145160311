import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { useHistory, useLocation } from 'react-router-dom';

import routeUrls from 'src/routes';
import { SignInButton } from 'src/Iteration/components';
import { FullLeaderboard } from 'src/util/TallyFirestore';
import Leaderboard from 'src/components/pistachioGame/leaderboard/Leaderboard';
import { StyleDefaults, Color } from 'src/styles/Constants';
import TextButton from 'src/components/shared/controls/TextButton';
import { ModalType } from 'src/components/shared/modals/ModalHoster';
import UserStats from 'src/components/shared/ui/UserStats';
// TODO: Remove BlazersGraphic. It can be referenced from S3 using Theme.tournamentHeaderGraphicUrl
import BlazersGraphic from 'src/images/icons/blazers-pattern-rotated@5x.png';
import LeaderboardDeadstate from 'src/components/pistachioGame/leaderboard/LeaderboardDeadstate';
import Theme from 'src/util/Theme';

import { TranslatedIteration } from 'src/util/i18n';
import { useUserState } from 'src/contexts/UserStateContext';
import { useModalHoster } from 'src/contexts/ModalHosterContext';

import TallyMarkdown from 'src/components/shared/ui/TallyMarkdown';

type Props = {
    leaderboard: FullLeaderboard;
    iteration: TranslatedIteration;
};

const IterationGeneralLeaderboardPage = ({ leaderboard, iteration }: Props) => {
    const location = useLocation();
    const history = useHistory();
    const modalHoster = useModalHoster();
    const userState = useUserState();
    const intl = useIntl();

    const onHowToPlayTournamentClick =
        modalHoster &&
        (() => {
            modalHoster.showModal({
                modalType: ModalType.HOW_TO_PLAY_TOURNAMENT,
                body: iteration.infoModalBody,
                title: iteration.tournamentHeadline,
            });
        });

    const {
        tournamentEditorial,
        tournamentSubheadline,
        tournamentHeadline,
        prizes,
    } = iteration;

    const userName =
        userState.type === 'initialized' ? userState.details.displayName : '';

    return (
        <TabContent>
            <Header>
                <WidthRestrictor>
                    <Headline>{tournamentHeadline}</Headline>
                    <SubHeadline>{tournamentSubheadline}</SubHeadline>
                    <DividerLine />
                    <Editorial>
                        <TallyMarkdown source={tournamentEditorial || ''} />
                    </Editorial>
                    {onHowToPlayTournamentClick && (
                        <HowToPlayButton
                            text={intl.formatMessage({
                                id: 'cumulativeLeaderboardTab.button',
                            })}
                            onClick={onHowToPlayTournamentClick}
                            color={Color.P1_WHITE}
                        />
                    )}
                </WidthRestrictor>
            </Header>
            <WidthRestrictor>
                {userName ? (
                    <UserStats
                        leaderboardEntry={leaderboard.leaderboardEntry}
                        name={userName}
                        titleOverride={intl.formatMessage({
                            id: 'cumulativeLeaderboardTab.myStats',
                        })}
                    />
                ) : (
                    <SignInButton
                        onClick={() =>
                            history.push({
                                pathname: routeUrls.onboarding,
                                state: { from: location },
                            })
                        }
                    >
                        <FormattedMessage id="leaderBoardTab.signInButton" />
                    </SignInButton>
                )}
                {leaderboard.leaderboardSummary && (
                    <Leaderboard
                        leaderboard={leaderboard.leaderboardSummary}
                        leaderboardEntry={leaderboard.leaderboardEntry}
                        prizes={prizes}
                        disclaimer="leaderBoardTab.finalAfterGameDisclaimer"
                    />
                )}
                {!leaderboard.leaderboardSummary && <LeaderboardDeadstate />}
            </WidthRestrictor>
        </TabContent>
    );
};

export default IterationGeneralLeaderboardPage;

const HowToPlayButton = styled(TextButton)`
    margin: 18px 0 0 0;
`;

const TabContent = styled.div<{
    backgroundColor?: string;
}>`
    max-width: ${StyleDefaults.MAX_ELEMENT_WIDTH};
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${(props) => props.backgroundColor || Color.G10_WHITISH};
    padding-bottom: ${StyleDefaults.PAGE_PADDING_BOTTOM};
`;

const Header = styled.div`
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: ${Theme.tournamentHeaderBackgroundColor};
    color: ${Color.P1_WHITE};
    background-image: url(${Theme.tournamentHeaderGraphicUrl ||
    BlazersGraphic});
    background-repeat: no-repeat;
    background-position: ${Theme.tournamentHeaderGraphicUrl
        ? undefined
        : '80% bottom'};
    background-size: ${Theme.tournamentHeaderGraphicUrl
        ? 'cover'
        : '500px 20px'};
`;

const WidthRestrictor = styled.div`
    max-width: ${StyleDefaults.MAX_ELEMENT_WIDTH};
    width: ${StyleDefaults.ELEMENT_WIDTH};
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Headline = styled.div`
    font-size: 35px;
    letter-spacing: -0.6px;
    line-height: 37px;
    font-weight: 900;
    text-align: center;
`;

const SubHeadline = styled.div`
    margin: 10px 0 0 0;
    font-size: 13px;
    font-style: italic;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 22px;
    text-transform: upperCase;
`;

const Editorial = styled.div`
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 18px;
`;

const DividerLine = styled.div`
    margin: 18px 0;
    height: 2px;
    width: 20px;
    background-color: ${Color.T30_CHARCOAL};
`;
