export const getOddsWin = (oddsBet = 0, theOdds = 0) => {
    let win = 0;

    if (theOdds > 0) {
        win = oddsBet * (theOdds / 100);
    }
    if (theOdds < 0) {
        win = oddsBet * (100 / -theOdds);
    }

    return Math.round(win);
};
