import React, { Component } from 'react';

import TimeUntilLock from '../renderless/TimeUntilLock';
import LockText, { TimeUntilLockMode } from '../predictions/LockText';
import { PredictionModel, PredictionLockType } from 'src/util/TallyFirestore';

interface Props {
    prediction: PredictionModel;
    timeExpiredText?: string;
    onCountdownExpired?: () => void;
    onCountdownUpdated?: (countdown?: number) => void;
    className?: string;
}

interface State {
    countdownSeconds?: number;
}

export default class PredictionCountdownTimer extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            countdownSeconds: undefined,
        };
    }

    private onCountdownUpdated = (countdown?: number) => {
        const { onCountdownUpdated } = this.props;

        if (onCountdownUpdated) {
            onCountdownUpdated(countdown);
        }

        if (countdown) {
            this.setState({
                countdownSeconds: countdown,
            });
        }
    };

    private onCountdownExpired = () => {
        const { timeExpiredText, onCountdownExpired } = this.props;

        if (onCountdownExpired) {
            onCountdownExpired();
        }

        if (timeExpiredText) {
            this.setState({
                countdownSeconds: undefined,
            });
        }
    };

    public render() {
        const { prediction, className } = this.props;
        const { countdownSeconds } = this.state;

        return (
            <div className={className}>
                {prediction.lockType === PredictionLockType.manual &&
                    prediction.lockDescription}
                {prediction.lockType === PredictionLockType.autoamtic && (
                    <>
                        {countdownSeconds !== undefined && (
                            <LockText
                                mode={TimeUntilLockMode.medium}
                                countdownDate={prediction.lockDate.toDate()}
                                countdownSeconds={countdownSeconds}
                            />
                        )}
                        <TimeUntilLock
                            countdownDate={prediction.lockDate.toDate()}
                            onCountdownUpdated={this.onCountdownUpdated}
                            onCountdownExpired={this.onCountdownExpired}
                        />
                    </>
                )}
            </div>
        );
    }
}
