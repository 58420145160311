import styled from '@emotion/styled';
import { Color } from 'src/styles/Constants';
import { ReactNode } from 'react';

type Props = {
    screenHasBg?: boolean | false; // apply UI changes to accomodate background image/color
    btmConnect?: boolean | false; // if false there will not be a margin between this element and the previous one
    topConnect?: boolean | false; // if false there will not be a margin between this element and the previous one
    opacity?: number;
    children: ReactNode;
};

export const BackgroundPanel = ({
    screenHasBg,
    btmConnect = false,
    topConnect = false,
    opacity = 100,
    children,
}: Props): any => {
    return (
        <>
            {screenHasBg ? (
                <BGPanel
                    btmConnect={btmConnect}
                    topConnect={topConnect}
                    opacity={opacity}
                >
                    {children}
                </BGPanel>
            ) : (
                <>{children}</>
            )}
        </>
    );
};

const BGPanel = styled.div<{
    btmConnect: boolean;
    topConnect: boolean;
    opacity?: number;
}>`
    background-color: ${(props) =>
        props.opacity
            ? props.opacity && props.opacity <= 100 && props.opacity >= 0
                ? Color.P1_WHITE +
                  Math.floor((props.opacity / 100) * 255).toString(16)
                : Color.P1_WHITE
            : Color.P1_WHITE};
    color: ${Color.G1_BLACK};
    padding-top: 5px;
    padding-bottom: ${(props) => (props.btmConnect ? '5px' : '10px')};
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: ${(props) => (props.btmConnect ? '-1px' : '20px')};
    /* box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.08); */
    transition: border 0.4s;

    ${(props) =>
        props.topConnect
            ? ''
            : `
       /* border-top: 1px solid ${Color.G1_BLACK};*/
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    `}
    ${(props) =>
        props.btmConnect
            ? ''
            : `
       /* border-bottom: 1px solid ${Color.G1_BLACK};*/
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    `} /* border-right: 1px solid ${Color.G1_BLACK};
    border-left: 1px solid ${Color.G1_BLACK};*/
`;
