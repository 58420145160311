import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import ClipLoader from 'react-spinners/ClipLoader';

import { ErrorMessage, SubmitGroup } from '../../../shared';
import { useGroupProvider } from 'src/contexts/GroupProvider';
import { Color } from 'src/styles/Constants';
import TouchButton from 'src/components/shared/controls/TouchButton';

interface Props {
    onCancel?: () => void;
}

const CreateGroupForm = (props: Props) => {
    const [name, setName] = useState('');
    const { onCancel } = props;

    const intl = useIntl();
    const { createGroup, isLoading } = useGroupProvider();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        createGroup.createGroup({ name });
    };

    const placeholder = intl.formatMessage({
        id: 'createGroupForm.inputPlaceholder',
    });

    return (
        <form style={{ width: '100%' }} onSubmit={handleSubmit}>
            <ErrorMessage error={createGroup.error} />
            <InputGroup>
                <InputLabel>
                    <FormattedMessage id="createGroupForm.inputLabel" />
                </InputLabel>
                <Input
                    required={true}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder={placeholder}
                />
            </InputGroup>
            <SubmitGroup>
                <TouchButton
                    backgroundColor={Color.S15_DEEP_TEAL}
                    type="submit"
                    displayText={
                        <FormattedMessage id="createGroupForm.buttonText" />
                    }
                    textColor={Color.T31_BLACK}
                    secondaryStyle={true}
                    disabled={isLoading}
                />
                {onCancel && (
                    <TouchButton
                        backgroundColor={Color.T31_BLACK}
                        type="button"
                        displayText={
                            <FormattedMessage id="createGroupForm.cancel" />
                        }
                        textColor={Color.P1_WHITE}
                        secondaryStyle={true}
                        disabled={isLoading}
                        onClick={onCancel}
                    />
                )}
                <ClipLoader size={20} loading={isLoading} />
            </SubmitGroup>
        </form>
    );
};

const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    margin-bottom: 16px;
`;

const Input = styled.input`
    font-family: Graphik Web;
    font-size: 16px;
    padding: 6px 10px;
    border: 1px #dbdbdb solid;
    border-radius: 2px;
    transition: box-shadow 0.15s ease 0s;
    &:focus {
        outline: 0;
        box-shadow: #3bf 0px 0px 2px 3px;
    }
`;

const InputLabel = styled.label`
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 18px;
    margin-bottom: 8px;
`;

export default CreateGroupForm;
