import {
    determineTile,
    OnboardingTileType,
} from 'src/components/pistachioGame/onboarding/utils';
import { useGroupProvider } from 'src/contexts/GroupProvider';
import { useUserState } from 'src/contexts/UserStateContext';
import useIteration from './useIteration';
import { usePartner } from './usePartner';
import useUserAgreement from './useUserAgreement';

const useOnboardingTile = (): OnboardingTileType | null => {
    const partner = usePartner();
    const iteration = useIteration();
    const userData = useUserState();
    const groupsState = useGroupProvider();
    const { userAgreed } = useUserAgreement();

    const onboardingTile = determineTile({
        groupsState,
        partner,
        userAgreed,
        userData,
        iteration,
    });

    return onboardingTile;
};

export const useShouldShowOnboarding = () => {
    const tile = useOnboardingTile();
    return Boolean(tile) && tile !== 'spinner';
};

export default useOnboardingTile;
