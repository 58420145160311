import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { usePartner } from 'src/hooks/usePartner';
import { FullLeaderboard } from 'src/util/TallyFirestore';
import tallySquare from 'src/images/icons/tally-square.png';

export type Props = {
    name: string;
    imageUrl: string | undefined;
    pathName: string;
    leaderboard: FullLeaderboard;
};

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 210,
    },
    media: {
        height: 140,
        backgroundPosition: 'center',
        backgroundSize: 'contain',
    },
    cardContent: {
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    cardContentRankHeading: {
        fontWeight: 500,
        fontSize: 30,
    },
    cardContentRankOutOfLabel: {
        fontWeight: 400,
        fontSize: 10,
    },
}));

const LeaderboardCard = ({ name, imageUrl, pathName, leaderboard }: Props) => {
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const partner = usePartner();

    return (
        <Grid item xs={6}>
            <Card
                className={classes.root}
                style={{
                    border: `3px solid ${partner.properties.headingColor}`,
                }}
            >
                <CardActionArea
                    onClick={() =>
                        history.push({
                            pathname: pathName,
                            search: location.search,
                        })
                    }
                >
                    <CardMedia
                        className={classes.media}
                        image={imageUrl || tallySquare}
                        title={name}
                    />
                    <CardContent className={classes.cardContent}>
                        <Typography gutterBottom variant="h2" component="h2">
                            {name}
                        </Typography>
                        <Typography
                            variant="h3"
                            component="h3"
                            color="primary"
                            className={classes.cardContentRankHeading}
                        >
                            {leaderboard.leaderboardEntry?.rank || 'X'}
                        </Typography>
                        <Typography
                            component="p"
                            className={classes.cardContentRankOutOfLabel}
                        >
                            <FormattedMessage
                                id="leaderboardCard.rankOutOf.label"
                                values={{
                                    totalPlayers:
                                        leaderboard.leaderboardSummary
                                            ?.playersCount || 'X',
                                }}
                            />
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
};

export default LeaderboardCard;
