import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocale } from 'src/hooks/useLocale';

interface Props {
    points: number;
}
export function IntlPointsText({ points }: Props) {
    const { localeId } = useLocale();
    const localizedPoints = points.toLocaleString(localeId);

    return (
        <FormattedMessage
            id="global.pointsValue"
            values={{ points: localizedPoints }}
        />
    );
}
