import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import {
    Color,
    StyleDefaults,
    Orientation,
    Alignment,
} from 'src/styles/Constants';
import Theme from 'src/util/Theme';
import PartnerLogo from '../shared/ui/PartnerLogo';
import TallyLogo from '../shared/animations/TallyLogo';
import Pulsate from 'src/components/shared/animations/Pulsate';
import config from 'src/util/Config';
import PresentedBy from 'src/components/shared/ui/PresentedBy';
import { FormattedMessage } from 'react-intl';
import { usePartner } from 'src/hooks/usePartner';
import { EventModel } from 'src/util/TallyFirestore';

interface Props {
    event?: EventModel;
}

const GeoFence = ({ event }: Props) => {
    const partner = usePartner();
    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        document.body.style.backgroundColor = Color.T31_BLACK;
        setTimeout(() => {
            setOpacity(1);
        }, 100);
    }, []);

    const sponsorLogoUrl = event
        ? event.sponsorship
            ? event.sponsorship.properties.gameWideLogoUrl ||
              event.sponsorship.properties.gameLogoUrl
            : undefined
        : undefined;

    const gameLogoUrl = event
        ? event.sponsorship
            ? event.sponsorship.properties.gameLogoUrl ||
              event.sponsorship.properties.gameWideLogoUrl
            : undefined
        : undefined;

    const { geoFenceMessageHeadline, geoFenceMessageSubHeadline } =
        partner.properties;

    let smallActionText: string | JSX.Element = geoFenceMessageSubHeadline ? (
        geoFenceMessageSubHeadline
    ) : (
        <FormattedMessage id={'geoFence.message.subHeadline'} />
    );

    let bigActionText: string | JSX.Element = geoFenceMessageHeadline ? (
        geoFenceMessageHeadline
    ) : (
        <FormattedMessage id={'geoFence.message.headline'} />
    );

    return (
        <Container>
            <Content opacity={opacity}>
                {Theme.partnerLogoUrl && (
                    <PartnerLogo
                        logoUrl={Theme.partnerLogoUrl}
                        maxHeight={Theme.geoFencePartnerLogoSize}
                    />
                )}
                {!Theme.partnerLogoUrl && (
                    <TallyLogo
                        backgroundColor={Color.T31_BLACK}
                        color={Color.P1_WHITE}
                        scale={0.65}
                    />
                )}
                {gameLogoUrl && !partner.properties.geoFenceHideSponsor && (
                    <GameLogo src={gameLogoUrl} />
                )}
                <MarginBelowLogos />
                <Pulsate resource={Theme.liveIconUrl} pulseActive={true} />
                {smallActionText && (
                    <ContentText>{smallActionText}</ContentText>
                )}
                {bigActionText && <HeadlineText>{bigActionText}</HeadlineText>}
            </Content>
            {sponsorLogoUrl && (
                <PresentedByBanner>
                    <PresentedBy
                        sponsorshipUnit={event ? event.sponsorship : undefined}
                        alignment={Alignment.CENTER}
                        asset={sponsorLogoUrl}
                        orientation={Orientation.HORIZONTAL}
                    />
                </PresentedByBanner>
            )}
        </Container>
    );
};

export default GeoFence;

export const shouldGeoFence = () => !!(config.oktaUID && config.geoFenceBlock);

const Container = styled.div`
    background-color: ${Theme.geoFenceBackgroundColor};
    color: ${Color.P1_WHITE};
    display: flex;
    flex-direction: column;
    font-family: ${StyleDefaults.FONT_FAMILY};
    height: 100%;
    position: absolute;
    width: 100%;
`;

const MarginBelowLogos = styled.div`
    height: 20px;
`;

const Content = styled.div<{ opacity: number }>`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    opacity: ${(props) => props.opacity};
    text-align: center;
    transition: opacity 1.7s;
    width: 100%;
`;

const ContentText = styled.div<{ marginTop?: string }>`
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.71px;
    line-height: 18px;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: ${(props) => props.marginTop || '0'};
    text-transform: uppercase;
`;

const HeadlineText = styled.div`
    font-size: 35px;
    letter-spacing: -0.6px;
    font-weight: 900;
    text-transform: uppercase;
    transition: 0.3s all;
    white-space: pre-line;
    margin: 0 40px;
`;

const GameLogo = styled.img`
    height: ${195 - Theme.geoFencePartnerLogoSize}px;
`;

const PresentedByBanner = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    aligh-items: center;
    background-color: ${Color.G11_WHITE};
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.08);
    position: sticky;
    bottom: 0;
`;
