import config from 'src/util/Config';
import FirebaseAuth from './FirebaseAuth';
import log from 'src/util/Logger';
import ServerError, { ServerErrorType } from 'src/services/ServerError';
import { getFullUrl } from './shered';
import { NetworkError } from 'src/util/errors/NetworkError';

interface ErrorResponse {
    code?: string;
    field?: string;
    message?: string;
    type?: ServerErrorType;
}

export default class ApiClient {
    public request = async (params: {
        baseUrl?: string;
        path: string;
        method: string;
        query?: any;
        body?: any;
    }) => {
        const {
            baseUrl = config.apiBaseUrl,
            path,
            method = 'GET',
            query,
            body,
        } = params;

        const user = FirebaseAuth.getUser();
        const token = user ? await user.getIdToken() : undefined;
        const fullPath = getFullUrl(baseUrl, path, query);
        try {
            const headers = this.getHeaders(token);

            const response: Response = await fetch(fullPath, {
                body: body && JSON.stringify(body),
                headers,
                method,
            });

            if (!response.ok) {
                // status 4xx
                throw await this.buildServerError(response);
            }
            if (response.status !== 204) {
                try {
                    return await response.json();
                } catch (jsonError) {
                    // Looks like there was no body to parse.
                    // Carry on...
                }
            }
        } catch (err) {
            const userSnapshot = {
                isAnonymous: user ? user.isAnonymous : undefined,
                uid: user ? user.uid : undefined,
            };

            if (err instanceof ServerError) {
                log.error(
                    `${err.status} ${method} ${fullPath}`,
                    err,
                    params,
                    userSnapshot,
                );
                throw err;
            }

            log.error(`${method} ${fullPath}`, err, params, userSnapshot);

            if (err instanceof TypeError && err.message === 'Failed to fetch') {
                throw new NetworkError(err.message);
            }

            throw err;
        }
    };

    private getHeaders = (authToken: string = '') => {
        return {
            Accept: 'application/json',
            Authorization: authToken,
            'Content-Type': 'application/json',
        };
    };

    private buildServerError = async (
        response: Response,
    ): Promise<ServerError | Error> => {
        try {
            const body: ErrorResponse = await response.json();
            const { status } = response;
            const { message, code, type, field } = body;

            return new ServerError({ status, message, code, type, field });
        } catch (e) {
            // Parsing server error failed.
            log.error('Internal Error - Parsing Tally error failed', response);

            return new Error('Something went wrong.');
        }
    };
}
