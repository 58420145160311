import { useEffect } from 'react';

type Props = {
    countdownDate: Date;
    onCountdownUpdated: (secondsLeft: number) => void;
};

const useCountdown = ({ countdownDate, onCountdownUpdated }: Props) => {
    useEffect(() => {
        const updateCountdown = () => {
            const diff = (countdownDate.getTime() - Date.now()) / 1000;
            const secondsLeft = Math.floor(diff);

            if (!secondsLeft) {
                clearInterval(intervalId);
                onCountdownUpdated(secondsLeft);
            } else {
                onCountdownUpdated(secondsLeft);
            }

            onCountdownUpdated(secondsLeft);
        };

        const intervalId = setInterval(() => {
            updateCountdown();
        }, 1000);

        updateCountdown();

        return () => clearInterval(intervalId);
    }, [countdownDate, onCountdownUpdated]);
};

export default useCountdown;
