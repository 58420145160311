import React, { useState } from 'react';
import ServerError from 'src/services/ServerError';
import PhoneInput from 'src/components/shared/controls/fields/PhoneInput';
import { FormattedMessage, useIntl } from 'react-intl';
import { EventModel, EventState } from 'src/util/TallyFirestore';
import { TranslatedConfigPartnerData } from 'src/util/ConfigTypes';
import ServerApi from 'src/services/ServerApi';
import { useAnalytics } from 'src/contexts/AnalyticsContext';
import { AppArea, EventName } from 'src/util/AnalyticsConstants';
import Logger from 'src/util/Logger';
import { NetworkError } from 'src/util/errors/NetworkError';
import {
    ButtonContainer,
    DescriptionImg,
    DescriptionText,
    LoadingContainer,
    TileTitle,
} from './shared';
import { Color } from 'src/styles/Constants';
import TallyMarkdown from 'src/components/shared/ui/TallyMarkdown';
import { getPhoneAuthTranslatedErrorMessage } from '../utils';
import formatPhoneNumberLeadingPlus from 'src/util/phoneNumber';
import InputLabel from 'src/components/shared/ui/InputLabel';
import HelpLinks from 'src/components/shared/ui/HelpLinks';
import TouchButton from 'src/components/shared/controls/TouchButton';
import SquareLoading from 'src/components/shared/animations/SquareLoading';
import { BackgroundPanel } from 'src/components/shared/ui/BackgroundPanel';

const submitPhoneNumber = async (
    phoneNumber: string,
    partnerId: string,
): Promise<{ verifyToken: string; phoneNumber: string }> => {
    const response = await ServerApi.sendVerifySMS(partnerId, phoneNumber);
    const { verifyToken } = response;

    return { verifyToken, phoneNumber };
};

type Props = {
    onSubmitted: ({
        phoneNumber,
        verifyToken,
    }: {
        phoneNumber: string;
        verifyToken: string;
    }) => void;
    partner: TranslatedConfigPartnerData;
    event?: EventModel;
    onContactSupportClick: () => void;
    screenHasBg?: boolean | false;
    disclaimer?: JSX.Element;
};

const PhoneNumberAuthTile = ({
    partner,
    event,
    onSubmitted,
    onContactSupportClick,
    screenHasBg,
    disclaimer,
}: Props) => {
    const intl = useIntl();
    const analytics = useAnalytics();

    const [phoneNumber, setPhoneNumber] = useState('');
    const [tileError, setTileError] = useState<Error | undefined>(undefined);
    const [fieldError, setFieldError] = useState<Error | undefined>(undefined);

    const [isSubmittingPhoneNumber, setIsSubmittingPhoneNumber] =
        useState<boolean>(false);

    const disablePhoneSubmit =
        !phoneNumber ||
        isSubmittingPhoneNumber ||
        Boolean(fieldError) ||
        Boolean(tileError);

    const {
        onboardingPhoneAuthMessage,
        onboardingPhoneAuthBannerUrl,
        countrySelected,
        countriesActive,
    } = partner.properties;

    const fieldDescription =
        event && event.state === EventState.final
            ? intl.formatMessage({
                  id: 'onboarding.requestPhone.description.gameIsFinal',
              })
            : onboardingPhoneAuthMessage ||
              (!onboardingPhoneAuthBannerUrl
                  ? intl.formatMessage({
                        id: 'onboarding.requestPhone.description.gameIsLive',
                    })
                  : '');

    const tileTitle = intl.formatMessage({
        id:
            event && event.state === EventState.final
                ? 'onboarding.requestPhone.title.gameIsFinal'
                : 'onboarding.requestPhone.title.gameIsLive', // TODO: there could be no game
    });

    const handleSubmit = () => {
        setIsSubmittingPhoneNumber(true);
        submitPhoneNumber(
            formatPhoneNumberLeadingPlus(phoneNumber),
            partner.partnerId,
        )
            .then((submittedState) => {
                setIsSubmittingPhoneNumber(false);
                analytics.logEvent({
                    eventName: EventName.signUpSendVerifyCode,
                    eventCategory: AppArea.signup,
                });
                onSubmitted(submittedState);
            })
            .catch((error) => {
                setIsSubmittingPhoneNumber(false);
                Logger.error('Error submitting sending phone number', error);
                if (!(error instanceof Error)) {
                    setTileError(
                        new Error(intl.formatMessage({ id: 'error.generic' })),
                    );
                    return;
                }
                if (error instanceof NetworkError) {
                    setTileError(
                        new Error(
                            intl.formatMessage({ id: 'error.failedToFetch' }),
                        ),
                    );
                    return;
                }
                if (error instanceof ServerError) {
                    if (error.type) {
                        setFieldError(
                            new Error(
                                getPhoneAuthTranslatedErrorMessage(
                                    intl,
                                    error.type,
                                ) || error.message,
                            ),
                        );
                        return;
                    }
                }
                setTileError(error);
            });
    };

    return (
        <>
            <BackgroundPanel screenHasBg={screenHasBg} topConnect={true}>
                <TileTitle color={Color.T31_BLACK}>{tileTitle}</TileTitle>
                {onboardingPhoneAuthBannerUrl && (
                    <DescriptionImg src={onboardingPhoneAuthBannerUrl} />
                )}
                {fieldDescription && (
                    <DescriptionText>
                        <TallyMarkdown source={fieldDescription} />
                    </DescriptionText>
                )}
            </BackgroundPanel>

            <BackgroundPanel screenHasBg={screenHasBg} btmConnect={true}>
                <PhoneInput
                    value={phoneNumber}
                    onChange={(value: string) => {
                        setFieldError(undefined);
                        setTileError(undefined);
                        setPhoneNumber(value);
                    }}
                    error={fieldError}
                    onContactSupportClick={onContactSupportClick}
                    initialCountryCode={
                        countrySelected && countrySelected.toLowerCase()
                    }
                    onlyCountries={
                        countriesActive &&
                        countriesActive.map((code) => code.toLowerCase())
                    }
                    screenHasBg={screenHasBg}
                />
                {tileError && (
                    <>
                        <InputLabel label={''} error={tileError} />
                        <HelpLinks
                            onContactSupportClick={onContactSupportClick}
                        />
                    </>
                )}
            </BackgroundPanel>
            <BackgroundPanel screenHasBg={screenHasBg} topConnect={true}>
                {disclaimer}
            </BackgroundPanel>

            <ButtonContainer>
                <TouchButton
                    disabled={disablePhoneSubmit}
                    displayText={
                        <FormattedMessage id="infoCollectionTitle.touchButtonLabel" />
                    }
                    backgroundColor={Color.T31_BLACK}
                    textColor={Color.P1_WHITE}
                    onClick={handleSubmit}
                />
            </ButtonContainer>
            <LoadingContainer>
                {isSubmittingPhoneNumber && (
                    <SquareLoading color={Color.P6_ELECTRIC_BLUE} />
                )}
            </LoadingContainer>
        </>
    );
};

export default PhoneNumberAuthTile;
