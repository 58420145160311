import React from 'react';
import styled from '@emotion/styled';
import { useAnalytics } from 'src/contexts/AnalyticsContext';
import { EventName, AppArea } from 'src/util/AnalyticsConstants';

interface Props {
    url: string;
    displayText?: string | JSX.Element;
    className?: string;
    onClick?: any;
}

const LinkText = styled.div`
    cursor: pointer;
`;

export default ({ url, displayText, className, onClick }: Props) => {
    const analytics = useAnalytics();

    const openUrl = () => {
        if (onClick) {
            if (typeof onClick === 'function') {
                onClick();
            }
        } else if (url) {
            analytics.logEvent({
                eventName: EventName.linkClick,
                params: {
                    url,
                },
                eventCategory: AppArea.outbound,
            });

            window.open(url, '_blank');
        }
    };

    return (
        <LinkText onClick={openUrl} className={className}>
            {displayText ? displayText : url}
        </LinkText>
    );
};
