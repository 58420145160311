import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Color, TextStyles, TextStyleTypes } from 'src/styles/Constants';
import { motion, useAnimation } from 'framer-motion';

interface Props {
    expand: boolean;
    details: PillDetails;
}

export interface PillDetails {
    asset: string;
    circleColor: Color;
    pillColor?: Color;
    label?: string;
    labelColor?: Color;
    outlineColor?: Color;
}

const ICON_SIZE = 30;

export default function Pill(props: Props) {
    const { details, expand } = props;
    const {
        asset,
        circleColor,
        label,
        labelColor = Color.G11_WHITE,
        outlineColor,
        pillColor = circleColor,
    } = details;
    const pillControls = useAnimation();
    const labelControls = useAnimation();
    const circleControls = useAnimation();

    useEffect(() => {
        if (label) {
            if (expand) {
                expandPill();
            } else {
                collapsePill();
            }
        }
    }, [expand]);

    useEffect(() => {
        changeIcon();
    }, [asset]);

    const changeIcon = async () => {
        await circleControls.start({
            scale: [1, 1.2, 1],
        });
    };

    const collapsePill = async () => {
        await labelControls.start({
            opacity: 0,
        });

        pillControls.start({
            width: `${ICON_SIZE}px`,
        });
    };

    const expandPill = async () => {
        await pillControls.start({
            transition: {
                delay: 0.3,
            },
            width: 'auto',
        });

        labelControls.start({
            opacity: 1,
        });
    };

    return (
        <Container>
            <Circle
                backgroundColor={circleColor}
                animate={circleControls}
                transition={{ duration: 0.3 }}
            >
                <IconContainer src={asset} />
            </Circle>
            <TestPill
                backgroundColor={pillColor}
                outlineColor={outlineColor}
                animate={pillControls}
                transition={{ stiffness: 150 }}
            >
                <Label
                    animate={labelControls}
                    color={labelColor}
                    transition={{ duration: 0.2 }}
                >
                    {label}
                </Label>
            </TestPill>
        </Container>
    );
}

const Label = styled(motion.div)<{ color: Color }>`
    color: ${(props) => props.color};
    opacity: 0;
    padding: 0 20px 0 ${ICON_SIZE + 10}px;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    ${TextStyles[TextStyleTypes.S3]};
`;

const TestPill = styled(({ outlineColor, backgroundColor, ...rest }) => (
    <motion.div {...rest} />
))<{
    backgroundColor: Color;
    outlineColor?: Color;
}>`
    background-color: ${(props) => props.backgroundColor};
    border: ${(props) =>
        props.outlineColor ? ` 1px solid ${props.outlineColor}` : undefined};
    justify-content: center;
    align-items: center;
    height: ${ICON_SIZE}px;
    width: ${ICON_SIZE}px;
    border-radius: ${ICON_SIZE / 2}px;
    overflow: hidden;
`;

const Circle = styled(({ backgroundColor, ...rest }) => (
    <motion.div {...rest} />
))<{ backgroundColor: Color }>`
    left: 0;
    position: absolute;
    background-color: ${(props) => props.backgroundColor};
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${ICON_SIZE}px;
    width: ${ICON_SIZE}px;
    border-radius: ${ICON_SIZE / 2}px;
`;

const IconContainer = styled.img`
    width: 72%;
    height: 72%;
`;

const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;
