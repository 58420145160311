import React, { Component } from 'react';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';

import { Color } from 'src/styles/Constants';

interface Props {
    text: string;
    onClick: () => void;
    color?: Color | string;
    className?: string;
}

export default class TextButton extends Component<Props> {
    public render() {
        const { className, color, text, onClick } = this.props;
        return (
            <Button
                className={className}
                onClick={onClick}
                color={color || Color.T31_BLACK}
            >
                {text}
            </Button>
        );
    }
}

const Button = styled.div<{ color: Color | string }>`
    color: ${(props) => props.color};
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.75px;
    text-align: center;
    text-transform: upperCase;
    cursor: pointer;
    text-decoration: underline;
`;
