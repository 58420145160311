import { useState } from 'react';

const getValueFromStorage = <T>(
    storage: Storage,
    key: string,
    isT: (x: unknown) => x is T,
): T | undefined => {
    try {
        const parsedValue = JSON.parse(storage.getItem(key) || '');
        if (isT(parsedValue)) {
            return parsedValue;
        }
        return undefined;
    } catch (e) {
        return undefined;
    }
};

const getInitialState = <T>(
    storage: Storage,
    key: string,
    isT: (v: unknown) => v is T,
    initialState: T,
) => {
    const stateFromStorage = getValueFromStorage(storage, key, isT);
    if (stateFromStorage !== undefined) {
        return stateFromStorage;
    }
    return initialState;
};

const useStorageState = <T>(
    storage: Storage,
    key: string,
    isT: (v: unknown) => v is T,
    initialState: T,
): [T, (v: T) => void] => {
    const [state, setReactState] = useState(() =>
        getInitialState(storage, key, isT, initialState),
    );

    const setState = (value: T) => {
        storage.setItem(key, JSON.stringify(value));
        setReactState(value);
    };

    return [state, setState];
};

export default useStorageState;
