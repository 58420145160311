import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import config from 'src/util/Config';

firebase.initializeApp(config.partnerData.firebaseConfig);

export default firebase;

export type DocumentReference = firebase.firestore.DocumentReference;
export type CollectionReference = firebase.firestore.CollectionReference;
export type DocumentData = firebase.firestore.DocumentData;
export type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
export type QuerySnapshot = firebase.firestore.QuerySnapshot;
export type Timestamp = firebase.firestore.Timestamp;
