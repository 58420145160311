import React from 'react';
import styled from '@emotion/styled';
import { OrderedMap } from 'immutable';
import { Redirect, Route } from 'react-router-dom';
import firebase from 'src/services/Firebase';

import AccountPage from 'src/components/AccountPage';
import GroupPage from 'src/components/GroupPage';
import EventIframeAd from 'src/components/shared/events/EventIframeAd';
import {
    AdCardContentContainer,
    StickyContainer,
} from 'src/Iteration/components';
import routeUrls from 'src/routes';
import { TranslatedConfigPartnerData } from 'src/util/ConfigTypes';
import {
    AdSizingPolicy,
    EventModel,
    IGameEventModel,
    SponsorModel,
} from 'src/util/TallyFirestore';
import PistachioTitlebar from 'src/components/pistachioGame/PistachioTitlebar';
import { TranslatedIteration } from 'src/util/i18n';
import { useUserState } from 'src/contexts/UserStateContext';
import { ModalType } from 'src/components/shared/modals/ModalHoster';
import PrivateRoute from 'src/components/shared/PrivateRoute';
import Onboarding from 'src/components/pistachioGame/onboarding/Onboarding';
import LeaderboardSummaryPage from 'src/Iteration/MultiEvent/LeaderboardSummaryPage';
import { Leaderboards } from 'src/hooks/useLiveEntityLeaderboards';
import { Color } from 'src/styles/Constants';
import PresentedBy from 'src/components/shared/ui/PresentedBy';

import { useModalHoster } from 'src/contexts/ModalHosterContext';
import { WithNotNullProperty } from 'src/utilityTypes';
import { ShowEventPrizeDetailsContext } from 'src/contexts/ShowEventPrizeDetailsContext';
import IGame from './IGame';
import { getEventUITheme } from '../utils';
import { BaseEventPageContainer } from '../components';

type Props = {
    partner: TranslatedConfigPartnerData;
    gameWideSponsor?: SponsorModel;
    iteration: TranslatedIteration;
    events: OrderedMap<string, EventModel>;
    iterationLeaderboards?: Leaderboards;
    event: IGameEventModel<firebase.firestore.Timestamp>;
    eventLeaderboards: Leaderboards;
};

const IGameEvent = ({
    partner,
    iteration,
    events,
    gameWideSponsor,
    iterationLeaderboards,
    event,
    eventLeaderboards,
}: Props) => {
    const userData = useUserState();

    const modalHoster = useModalHoster();

    const showEventPrizeDetails =
        modalHoster &&
        ((event: WithNotNullProperty<EventModel, 'prizeDetailsMarkdown'>) =>
            modalHoster.showModal({
                event,
                gameWideSponsor,
                modalType: ModalType.PRIZES,
            }));
    const isAdSticky =
        event.adPinned &&
        event.adSizingPolicy === AdSizingPolicy.preserveAspectRatio;
    const shouldDisplayAd = event.adEnabled && event.adIframeHtml;
    const presentedBySponsorLogo = event.sponsorship
        ? event.sponsorship.properties.gameWideLogoUrl ||
          event.sponsorship.properties.gameLogoUrl
        : undefined;

    return (
        <ShowEventPrizeDetailsContext.Provider value={showEventPrizeDetails}>
            <StickyContainer>
                <PistachioTitlebar iteration={iteration} event={event} />
                {/* ATODO: why is the ad in two places in this file? */}
                {isAdSticky &&
                    shouldDisplayAd &&
                    (event.adIframeHtml ? (
                        <AdCardContentContainer>
                            <EventIframeAd
                                eventId={event.id}
                                eventAd={{
                                    iframeHtml: event.adIframeHtml,
                                    headline: event.adHeadline,
                                    disclaimer: event.adDisclaimer,
                                    sizingPolicy: event.adSizingPolicy,
                                    cutOffHeight: event.adCutOffHeight,
                                }}
                            />
                        </AdCardContentContainer>
                    ) : null)}
            </StickyContainer>
            <Route path={routeUrls.onboarding}>
                <Onboarding
                    partner={partner}
                    iteration={iteration}
                    event={event}
                    gameWideSponsor={gameWideSponsor}
                />
            </Route>
            <PrivateRoute
                path={routeUrls.play}
                children={(props) => (
                    <IGamePageContainer
                        visible={Boolean(props.match)}
                        {...getEventUITheme(event, partner)}
                    >
                        {presentedBySponsorLogo && (
                            <PresentedByBanner>
                                <PresentedBy
                                    sponsorshipUnit={event.sponsorship}
                                    asset={presentedBySponsorLogo}
                                />
                            </PresentedByBanner>
                        )}
                        {!isAdSticky &&
                            shouldDisplayAd &&
                            (event.adIframeHtml ? (
                                <AdCardContentContainer>
                                    <EventIframeAd
                                        eventId={event.id}
                                        eventAd={{
                                            iframeHtml: event.adIframeHtml,
                                            headline: event.adHeadline,
                                            disclaimer: event.adDisclaimer,
                                            sizingPolicy: event.adSizingPolicy,
                                            cutOffHeight: event.adCutOffHeight,
                                        }}
                                    />
                                </AdCardContentContainer>
                            ) : null)}
                        <IGame
                            history={props.history}
                            location={props.location}
                            match={props.match}
                            userData={userData}
                            event={event}
                            partner={partner}
                            iteration={iteration}
                            eventLeaderboardEntry={
                                eventLeaderboards.generalLeaderboard
                                    .leaderboardEntry
                            }
                            modalHoster={modalHoster}
                        ></IGame>
                    </IGamePageContainer>
                )}
            />
            <Route path={`${routeUrls.leaderboardSummary}/:tabPanelId`}>
                {partner.properties.hideLeaderboards ? (
                    <Redirect to={routeUrls.play} />
                ) : (
                    <LeaderboardSummaryPage
                        iterationLeaderboards={iterationLeaderboards}
                        iteration={iteration}
                        events={events}
                        eventWithLeaderboards={{
                            event,
                            leaderboards: eventLeaderboards,
                        }}
                    />
                )}
            </Route>
            <PrivateRoute path={routeUrls.account}>
                <AccountPage iteration={iteration} event={event} />
            </PrivateRoute>
            <PrivateRoute path={routeUrls.group}>
                <GroupPage iteration={iteration} />
            </PrivateRoute>
        </ShowEventPrizeDetailsContext.Provider>
    );
};

const PresentedByBanner = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    aligh-items: center;
    background-color: ${Color.G11_WHITE};
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.08);
`;

const IGamePageContainer = styled(BaseEventPageContainer)`
    padding: 25px 0 80px 0;
`;

export default IGameEvent;
