import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import routeUrls from 'src/routes';
import { Color } from 'src/styles/Constants';
import { useLocale } from 'src/hooks/useLocale';
import Theme from 'src/util/Theme';
import { TranslationMessageId } from 'src/util/LocaleHelpers';

import TouchButton from 'src/components/shared/controls/TouchButton';
import Tile from './Tile';

import profileIcon from 'src/images/icons/UserProfile-Filled.svg';
import { LeaderboardEntry } from 'src/util/TallyFirestore';
import { usePartner } from 'src/hooks/usePartner';

interface Props {
    leaderboardEntry?: LeaderboardEntry;
    name?: string;
    titleOverride?: string | JSX.Element;
    showShadow?: boolean;
    callToActionButton?: {
        label: string | JSX.Element;
        onClick: () => void;
    };
    omitCorrectAnswers?: boolean;
}

const DASH = '—';

export default function UserStats(props: Props): JSX.Element {
    const {
        leaderboardEntry,
        name,
        titleOverride,
        showShadow,
        callToActionButton,
        omitCorrectAnswers,
    } = props;

    return (
        <Tile label={titleOverride} showShadow={showShadow}>
            <Container>
                {name && (
                    <NameAndGear>
                        <NameText>{name}</NameText>
                        <Link to={routeUrls.account}>
                            <Icon src={profileIcon} />
                        </Link>
                    </NameAndGear>
                )}
                <Stats
                    leaderboardEntry={leaderboardEntry}
                    omitCorrectAnswers={omitCorrectAnswers}
                />
                {callToActionButton && (
                    <CTAContainer>
                        <TouchButton
                            backgroundColor={Theme.themeHighlightFallbackColor}
                            textColor={Color.P1_WHITE}
                            displayText={callToActionButton.label}
                            onClick={callToActionButton.onClick}
                            stretchWidth={false}
                            secondaryStyle={true}
                        />
                    </CTAContainer>
                )}
            </Container>
        </Tile>
    );
}

function Stats({
    leaderboardEntry,
    omitCorrectAnswers,
}: {
    leaderboardEntry?: LeaderboardEntry;
    omitCorrectAnswers?: boolean;
}) {
    const { localeId } = useLocale();
    const {
        properties: { hideLeaderboards },
    } = usePartner();

    let rank: string | undefined;
    let points: string | undefined;
    let score: string | undefined;

    if (!leaderboardEntry) {
        if (!hideLeaderboards) {
            rank = DASH;
            points = DASH;
        }
        if (!omitCorrectAnswers) {
            score = DASH;
        }
    } else {
        const {
            correctAnswers,
            totalQuestionCount,
            rank: leaderboardRank,
            points: leaderboardPoints,
        } = leaderboardEntry;

        if (!hideLeaderboards) {
            if (leaderboardRank !== null) {
                rank = leaderboardRank.toLocaleString(localeId);
            } else {
                rank = DASH;
            }

            points = leaderboardPoints.toLocaleString(localeId);
        }

        if (correctAnswers && !omitCorrectAnswers) {
            score = correctAnswers.toLocaleString(localeId);

            if (totalQuestionCount) {
                score += ` / ${totalQuestionCount.toLocaleString(localeId)}`;
            }
        }
    }

    if (!rank && !points && !score) {
        return null;
    }

    return (
        <StatsContainer>
            <StatsRow>
                {rank && <Stat name="userStats.rank" value={rank} />}
                {points && <Stat name="userStats.points" value={points} />}
                {score && <Stat name="userStats.correct" value={score} />}
            </StatsRow>
        </StatsContainer>
    );
}

const Stat = (props: { name: TranslationMessageId; value: string }) => (
    <StatRowContainer>
        <StatName>
            <FormattedMessage id={props.name} />
        </StatName>
        <StatValue>{props.value}</StatValue>
    </StatRowContainer>
);

const CTAContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 15px 0;
`;

const Container = styled.div`
    align-items: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const NameAndGear = styled.div`
    border-bottom: 2px solid #e0faff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 14px;
    padding-bottom: 6px;
`;

const NameText = styled.h1`
    align-self: center;
    font-size: 27px;
    font-weight: 300;
    letter-spacing: -0.45px;
    line-height: 32px;
`;

const StatsContainer = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const StatsRow = styled.div`
    display: flex;
    flex: 1;
    text-align: left;
    width: 100%;
`;

const StatRowContainer = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const StatName = styled.p`
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 18px;
    text-transform: uppercase;
`;

const StatValue = styled.p`
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 18px;
`;

const Icon = styled.img`
    margin: 0 5px;
    cursor: pointer;
`;
