import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import { makeStyles } from '@material-ui/styles';
import { Link } from '@material-ui/core';
import { useIntl } from 'react-intl';
import {
    EventModel,
    getIntraGroupLeaderboardEntry,
} from 'src/util/TallyFirestore';
import Leaderboard from 'src/components/pistachioGame/leaderboard/Leaderboard';
import { StyleDefaults } from 'src/styles/Constants';
import UserStats from 'src/components/shared/ui/UserStats';
import LeaderboardDeadstate from 'src/components/pistachioGame/leaderboard/LeaderboardDeadstate';
import HeaderText from 'src/components/shared/ui/HeaderText';
import { getInviteLink, useGroupProvider } from 'src/contexts/GroupProvider';
import { ReactComponent as InfoIcon } from 'src/images/icons/info-black-18dp.svg';
import CreateGroupForm from './AccountPage/Settings/GroupSettings/CreateGroupForm';
import GroupView from './GroupPage/GroupView';
import { TranslatedIteration } from 'src/util/i18n';
import { NetworkError } from 'src/util/errors/NetworkError';
import ShareGroupLinkButton from './groups/ShareGroupLinkButton';
import ShareGroupLinkQr from './groups/ShareGroupLinkQr';
import { useUserState } from 'src/contexts/UserStateContext';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import routeUrls from 'src/routes';
import {
    Leaderboards,
    TeamUserGroupLeaderboards,
    TeamGroupLeaderboards,
    UserGroupLeaderboards,
    VenueGroupLeaderboards,
} from 'src/hooks/useLiveEntityLeaderboards';
import { PageContainer } from 'src/Iteration/MultiEvent/components';
import { usePartner } from 'src/hooks/usePartner';

const useStyles = makeStyles({
    linkContainer: {
        display: 'flex',
        marginTop: 12,
    },
    shareButtonContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 16,
        margin: '0 auto',
    },
});

const ErrorMessage = ({ error }: { error: null | Error }) =>
    error ? (
        <ErrorStyled>
            {error instanceof NetworkError ? (
                <FormattedMessage id="error.failedToFetch" />
            ) : (
                error.message
            )}
        </ErrorStyled>
    ) : null;

const ErrorStyled = styled.div`
    font-size: 14px;
    color: red;
    margin-bottom: 8px;
`;

type Props = {
    iteration: TranslatedIteration;
    leaderboards: Leaderboards;
    omitCorrectAnswers?: boolean;
};

const supportedInterGroupTypePaths = ['teams', 'venues', 'usergroups'] as const;

const IntraGroupLeaderboardPage = ({
    iteration,
    leaderboards,
    omitCorrectAnswers,
}: Props) => {
    const { grouptype: groupType } = useParams<{ grouptype: string }>();

    const supportedPath = supportedInterGroupTypePaths.find(
        (supportedPath) => supportedPath === groupType,
    );

    if (!supportedPath) {
        return <Redirect to={routeUrls.leaderboardSummary} />;
    }

    if (
        supportedPath === 'teams' &&
        (leaderboards.groupMode === 'TEAM,USER_GROUP' ||
            leaderboards.groupMode === 'TEAM')
    ) {
        return (
            <IntraTeamLeaderboardPage
                leaderboards={leaderboards}
                omitCorrectAnswers={omitCorrectAnswers}
            />
        );
    }

    if (
        (supportedPath === 'venues' && leaderboards.groupMode === 'VENUE') ||
        (supportedPath === 'usergroups' &&
            (leaderboards.groupMode === 'TEAM,USER_GROUP' ||
                leaderboards.groupMode === 'USER_GROUP'))
    ) {
        return (
            <IntraUserGroupOrVenueLeaderboard
                iteration={iteration}
                leaderboards={leaderboards}
                omitCorrectAnswers={omitCorrectAnswers}
            />
        );
    }

    return <Redirect to={routeUrls.leaderboardSummary} />;
};

const IntraTeamLeaderboardPage = ({
    leaderboards,
    omitCorrectAnswers,
}: {
    leaderboards: TeamUserGroupLeaderboards | TeamGroupLeaderboards;
    omitCorrectAnswers?: boolean;
}) => {
    const {
        generalLeaderboard,
        teamGroup: { intraGroupLeaderboardSummary },
    } = leaderboards;

    return (
        <PageContainer>
            <HeaderText
                headerText={
                    leaderboards.teamGroup.group?.name ||
                    'Intra Team Leaderboard'
                }
            />
            <UserStats
                omitCorrectAnswers={omitCorrectAnswers}
                leaderboardEntry={
                    generalLeaderboard.leaderboardEntry
                        ? {
                              ...generalLeaderboard.leaderboardEntry,
                              rank:
                                  generalLeaderboard.leaderboardEntry?.groupRanks?.find(
                                      (gr) =>
                                          gr.groupId ===
                                          leaderboards.teamGroup.group?.id,
                                  )?.userRank || null,
                          }
                        : undefined
                }
                name={generalLeaderboard.leaderboardEntry?.displayName}
            />
            {intraGroupLeaderboardSummary ? (
                <Leaderboard
                    leaderboard={intraGroupLeaderboardSummary}
                    omitCorrectAnswers={omitCorrectAnswers}
                />
            ) : (
                <LeaderboardDeadstate />
            )}
        </PageContainer>
    );
};

const IntraUserGroupOrVenueLeaderboard = ({
    leaderboards,
    iteration,
    event,
    omitCorrectAnswers,
}: {
    leaderboards:
        | UserGroupLeaderboards
        | TeamUserGroupLeaderboards
        | VenueGroupLeaderboards;
    iteration: TranslatedIteration;
    event?: EventModel;
    omitCorrectAnswers?: boolean;
}) => {
    const partner = usePartner();
    const userData = useUserState();
    const styles = useStyles();
    const history = useHistory();
    const location = useLocation();

    const intl = useIntl();
    const {
        groups,
        isLoading,
        getUserGroups: { error },
    } = useGroupProvider();

    const [showlinkToCopy, setShowLinkToCopy] = useState<boolean>(false);

    const handleOnCancelGroupCreation = () => {
        history.push({
            pathname: routeUrls.leaderboardSummary,
            search: location.search,
        });
    };

    const userDetails =
        userData.type === 'initialized' ? userData.details : undefined;
    const userName = userDetails ? userDetails.displayName : '';

    const group =
        iteration.groupMode === 'VENUE' ? groups.venueGroup : groups.userGroup;
    const isNotInGroup = !group && !isLoading && !error;

    const leaderboardSummary =
        leaderboards.groupMode === 'VENUE'
            ? leaderboards.venueGroup.intraGroupLeaderboardSummary
            : leaderboards.userGroup.intraGroupLeaderboardSummary;

    let leaderboardEntry = getIntraGroupLeaderboardEntry(
        leaderboards.groupMode === 'VENUE' ? 'VENUE' : 'USER_GROUP',
        leaderboards.generalLeaderboard.leaderboardEntry,
    );

    return (
        <PageContainer>
            <HeaderText
                headerText={
                    isLoading
                        ? intl.formatMessage({
                              id: 'group.isLoading',
                          })
                        : group
                        ? group.name
                        : error
                        ? intl.formatMessage({
                              id: 'groupLeaderboardTab.headerText.error',
                          })
                        : intl.formatMessage({
                              id: 'groupLeaderboardTab.headerText.notInGroup',
                          })
                }
            />
            {isNotInGroup ? (
                <>
                    <DescriptionText>
                        <FormattedMessage id="groupLeaderboardTab.headerText.notInGroup.descriptionStart" />
                    </DescriptionText>
                    <CreateGroupForm onCancel={handleOnCancelGroupCreation} />
                    <DescriptionText>
                        <InfoIcon
                            style={{
                                float: 'left',
                                marginRight: '4px',
                            }}
                        />{' '}
                        <FormattedMessage id="groupLeaderboardTab.headerText.notInGroup.descriptionBottom" />
                    </DescriptionText>
                </>
            ) : (
                <>
                    <ErrorMessage error={error} />
                    {group && event && (
                        <>
                            <div className={styles.shareButtonContainer}>
                                <ShareGroupLinkButton
                                    group={group}
                                    event={event}
                                    onCopyToClipboardFailed={() => {
                                        setShowLinkToCopy(true);
                                    }}
                                />
                                <ShareGroupLinkQr group={group} />
                                {showlinkToCopy && (
                                    <div className={styles.linkContainer}>
                                        <Link
                                            href={getInviteLink(
                                                window.location,
                                                group,
                                            )}
                                        >
                                            {getInviteLink(
                                                window.location,
                                                group,
                                            )}
                                        </Link>
                                    </div>
                                )}
                            </div>
                            <DescriptionText>
                                <FormattedMessage
                                    id={
                                        group.groupType === 'VENUE'
                                            ? 'group.shareLink.description.venue'
                                            : 'group.shareLink.description.groups'
                                    }
                                    values={{
                                        partnerName: partner.name,
                                        partyName: group && group.name,
                                    }}
                                />
                            </DescriptionText>
                        </>
                    )}
                    <UserStats
                        leaderboardEntry={leaderboardEntry}
                        name={userName}
                        omitCorrectAnswers={omitCorrectAnswers}
                    />
                    {leaderboardSummary ? (
                        <Leaderboard
                            leaderboard={leaderboardSummary}
                            leaderboardEntry={leaderboardEntry}
                            disclaimer="groupLeaderboardTab.pointsDescription"
                            omitCorrectAnswers={omitCorrectAnswers}
                        />
                    ) : group ? (
                        <div>
                            <SubHead>
                                <FormattedMessage
                                    id={
                                        iteration.groupMode === 'VENUE'
                                            ? 'groupPage.groupView.subHead.venue'
                                            : 'groupPage.groupView.subHead.groups'
                                    }
                                />
                            </SubHead>
                            <GroupView group={group} listOnly={true} />
                        </div>
                    ) : (
                        <LeaderboardDeadstate />
                    )}
                </>
            )}
        </PageContainer>
    );
};

export default IntraGroupLeaderboardPage;

const SubHead = styled.div`
    text-align: center;
    margin-top: 10px;
    font-family: ${StyleDefaults.FONT_FAMILY};
`;

const DescriptionText = styled.p`
    margin: 20px 0;
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 18px;
    text-align: left;
    font-family: Graphik Web;
`;
