import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import qrcode from 'qrcode';

import { makeStyles } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';

import { Group } from 'src/types';
import Theme from 'src/util/Theme';
import { Color, StyleDefaults } from 'src/styles/Constants';

import HeaderText from '../shared/ui/HeaderText';
import TouchButton from '../shared/controls/TouchButton';
import CloseButton from '../shared/controls/CloseButton';
import { ErrorMessage } from '../AccountPage/shared';
import { getInviteLink } from 'src/contexts/GroupProvider';

const useStyles = makeStyles({
    modalStyles: {
        backgroundColor: 'black',
    },
    qrCode: {
        border: '1px solid white',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '& > *': {
            color: 'white',
        },
        overflow: 'scroll',
        height: '100%',
    },
    close: {
        position: 'fixed',
        top: 25,
        right: 25,
    },
    subhead: {
        textAlign: 'center',
        color: 'white',
        fontFamily: `${StyleDefaults.FONT_FAMILY}`,
        fontSize: '1.25rem',
    },
});

type Props = {
    group: Group;
};

const ShareGroupLinkQr = ({ group }: Props) => {
    const intl = useIntl();
    const styles = useStyles();
    const [qrCodeError, setQrCodeError] = useState<Error | null>(null);
    const [isModalUp, setIsModalUp] = useState(false);

    const toggleModal = () => {
        setIsModalUp((prev) => !prev);
    };

    const canvasRefCallback = (canvasRef: HTMLCanvasElement | null) => {
        if (canvasRef) {
            qrcode.toCanvas(
                canvasRef,
                getInviteLink(window.location, group),
                { scale: 6 },
                (error) => {
                    if (error) {
                        setQrCodeError(error);
                    } else {
                        setQrCodeError(null);
                    }
                },
            );
        }
    };

    return (
        <>
            <Modal className={styles.modalStyles} open={isModalUp}>
                <>
                    <CloseButton
                        className={styles.close}
                        onClick={toggleModal}
                    />
                    <Box className={styles.content}>
                        <Box margin={4}>
                            <Box marginBottom={4}>
                                <HeaderText headerText={group.name} />
                            </Box>

                            <Box display="flex" justifyContent="center">
                                <canvas ref={canvasRefCallback} />
                            </Box>

                            <Box textAlign="center">
                                <ErrorMessage error={qrCodeError} />
                            </Box>

                            <Box marginTop={4}>
                                <Box className={styles.subhead}>
                                    <FormattedMessage
                                        id="group.shareQr.description"
                                        values={{ groupName: group.name }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </>
            </Modal>
            <TouchButton
                backgroundColor={Theme.themeHighlightFallbackColor}
                textColor={Color.P1_WHITE}
                displayText={intl.formatMessage({ id: 'group.shareQr.button' })}
                onClick={toggleModal}
                stretchWidth={false}
                secondaryStyle={true}
            />
        </>
    );
};

export default ShareGroupLinkQr;
