import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import SquareLoading from 'src/components/shared/animations/SquareLoading';
import {
    BingoProperties,
    EventModel,
    LeaderboardEntry,
} from 'src/util/TallyFirestore';
import theme from 'src/util/Theme';
import { useUserState } from 'src/contexts/UserStateContext';
import { useAnalytics } from 'src/contexts/AnalyticsContext';
import { AppArea, Screen } from 'src/util/AnalyticsConstants';
import useBingoGame from './useBingoGame';
import Welcome from './Welcome';
import SeeYouNextTime from './SeeYouNextTime';
import BingoScorecard from './BingoScorecard';
import { TranslatedIteration } from 'src/util/i18n';
import {
    LoadingContainer,
    LoadingIndicatorContainer,
    WidthRestrictor,
} from './shared';
import UserStats from 'src/components/shared/ui/UserStats';

type Props = {
    iteration: TranslatedIteration;
    event: EventModel;
    bingo: BingoProperties;
    leaderboardEntry?: LeaderboardEntry;
};

const BingoGame = ({ iteration, event, bingo, leaderboardEntry }: Props) => {
    const analytics = useAnalytics();
    const user = useUserState();
    const { state: gameState, generateScorecard } = useBingoGame(
        event.id,
        iteration.path,
    );

    const userName =
        user.type === 'initialized' ? user.details.displayName : '';

    useEffect(() => {
        analytics.logScreenView({
            screenName: `${event.shortName} - ${Screen.eventBingo}`,
            screenType: Screen.eventBingo,
            appArea: AppArea.game,
        });
    }, [analytics]);

    switch (gameState.id) {
        case 'getScorecardLoading':
        case 'getBingoSquaresLoading':
            return (
                <LoadingContainer>
                    <LoadingIndicatorContainer>
                        <SquareLoading
                            color={theme.themeHighlightFallbackColor}
                        />
                    </LoadingIndicatorContainer>
                </LoadingContainer>
            );
        case 'getScorecardError':
        case 'getBingoSquaresError':
            return (
                <span style={{ color: 'red' }}>{gameState.error.message}</span>
            );
        case 'noScorecard':
        case 'generateScorecardError':
        case 'generateScorecardLoading':
            return event.state === 'final' ? (
                <SeeYouNextTime />
            ) : (
                <Welcome
                    loading={gameState.id === 'generateScorecardLoading'}
                    bingo={bingo}
                    event={event}
                    error={
                        gameState.id === 'generateScorecardError'
                            ? gameState.error
                            : undefined
                    }
                    onGenerateScoreCard={generateScorecard}
                />
            );
        case 'playingBingo':
            return (
                <>
                    <BingoScorecard gameState={gameState} bingo={bingo} />
                    <WidthRestrictor>
                        <UserStats
                            titleOverride={
                                <FormattedMessage id="predictPage.userStats.titleOverride" />
                            }
                            leaderboardEntry={leaderboardEntry}
                            name={userName}
                        />
                    </WidthRestrictor>
                </>
            );
    }
};

export default BingoGame;
