import { PositionProperty } from 'csstype';

import { Color, StyleDefaults } from 'src/styles/Constants';
import { predictionTypeIsMultipleChoicy } from 'src/util/PredictionHelpers';
import {
    OptionModel,
    PredictionModel,
    PredictionType,
    UserAnswer,
} from 'src/util/TallyFirestore';
import theme from 'src/util/Theme';

export const styles = {
    activeContainer: {
        '&:active': {
            opacity: 0.4,
        },
    },
    container: {
        borderColor: Color.T28_FOG,
        borderStyle: 'solid',
        borderWidth: 1,
        color: Color.T31_BLACK,
        fontFamily: StyleDefaults.FONT_FAMILY,
        marginBottom: 0,
        position: 'relative' as PositionProperty,
        transition: 'border-color 0.3s',
    },
    progress: {
        height: '4px',
        marginTop: '3px',
    },
};

export const OPTION_CONTAINER_STYLES = {
    correct_and_user_correct: {
        backgroundColor: Color.TRANSPARENT,
        borderColor: Color.PREDICTION_GREEN,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderTopWidth: 1,
    },
    default: {
        backgroundColor: Color.TRANSPARENT,
        borderColor: Color.T28_FOG,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderTopWidth: 1,
    },
    incorrect: {
        backgroundColor: Color.TRANSPARENT,
        borderColor: Color.P4_CHILI_PEPPER,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderTopWidth: 1,
    },
    selected: {
        backgroundColor: Color.TRANSPARENT,
        borderColor: theme.predictionAnsweredIconBackgroundColor,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderTopWidth: 1,
    },
};

// Returns styling for the top portion of the option container.
// Describes the current state of a multiple choice prediction option.
export enum OptionOutcome {
    // This option's prediction has not been resolved yet.
    UNRESOLVED,

    // This option is the correct one.
    CORRECT,

    // This option is not the correct one.
    INCORRECT,
}

export const getOptionOutcome = (option: {
    correct?: boolean;
}): OptionOutcome => {
    const { correct } = option;
    if (correct) {
        return OptionOutcome.CORRECT;
    }
    if (correct === false) {
        return OptionOutcome.INCORRECT;
    }
    return OptionOutcome.UNRESOLVED;
};

export const getOptionContainerStyle = (
    optionOutcome: OptionOutcome,
    selected: boolean,
) => {
    if (optionOutcome === OptionOutcome.CORRECT) {
        if (selected) {
            return OPTION_CONTAINER_STYLES.correct_and_user_correct;
        }
        return OPTION_CONTAINER_STYLES.default;
    }
    if (optionOutcome === OptionOutcome.INCORRECT && selected) {
        return OPTION_CONTAINER_STYLES.incorrect;
    }
    if (optionOutcome === OptionOutcome.UNRESOLVED && selected) {
        return OPTION_CONTAINER_STYLES.selected;
    }

    // Otherwise return a dimmed container.
    return OPTION_CONTAINER_STYLES.default;
};

type AnswerInfo = {
    projectedSelectedOptionId?: string;
    answerSelectedOptionId?: string;
};

export const getAnswerInfo = (
    predictionType: PredictionType,
    answer: UserAnswer | undefined,
    projectedSelectedOptionId: string | undefined,
): AnswerInfo => {
    let answerSelectedOptionId: undefined | string;
    if (answer) {
        // There is no projected answer - so we will trust the answer from firestore.
        if (predictionTypeIsMultipleChoicy(predictionType)) {
            for (const id in answer) {
                if (answer[id] === true) {
                    answerSelectedOptionId = id;
                }
            }
        }
    }

    return {
        projectedSelectedOptionId,
        answerSelectedOptionId,
    };
};

export const getTotalCount = (options: OptionModel[]) =>
    options.reduce(
        (total, option: OptionModel) => total + (option.selectedCount || 0),
        0,
    );

export const computeOptionPointValue = (
    optionCount: number,
    totalCount: number,
    totalPoints: number,
    allOrNothing: boolean,
) => {
    const otherCount = totalCount - optionCount;
    // If everybody selected this one option, give them all the points
    if (otherCount === 0 || allOrNothing) {
        return totalPoints;
    }
    // Otherwise give them the percent of people who bet against them
    // times total points (decimal places shaved off)
    const percentileOther = otherCount / totalCount;
    const pointValue = percentileOther * totalPoints;
    return Math.trunc(pointValue);
};

// returns the value if only one is present, or both values are the same but not undefined
function getIfCongruent<T>(a: T | undefined, b: T | undefined) {
    if (a !== undefined && b !== undefined && a !== b) {
        return undefined;
    }

    return a || b;
}

export const mergeAnswerInfoWithOptions = ({
    answerInfo: { projectedSelectedOptionId, answerSelectedOptionId },
    prediction,
}: {
    answerInfo: AnswerInfo;
    prediction: PredictionModel;
}): OptionModel[] => {
    const eitherSelectedOptionId = getIfCongruent(
        projectedSelectedOptionId,
        answerSelectedOptionId,
    );

    // user answered, but counts may not be updated yet
    if (eitherSelectedOptionId) {
        const initialTotalCount = getTotalCount(prediction.options);
        return prediction.options.map((option) => {
            if (option.id === eitherSelectedOptionId) {
                const selectedCount = (option.selectedCount || 0) + 1;
                const totalCount = initialTotalCount + 1;

                const pointValue = computeOptionPointValue(
                    selectedCount,
                    totalCount,
                    prediction.pointValue,
                    prediction.type === 'MULTIPLE_CHOICE' &&
                        prediction.allOrNothing,
                );
                return { ...option, selectedCount, pointValue };
            }

            return option;
        });
    }

    // user changed the answer and it is not synced yet
    if (
        typeof projectedSelectedOptionId === 'string' &&
        typeof answerSelectedOptionId === 'string' &&
        projectedSelectedOptionId !== answerSelectedOptionId
    ) {
        const initialTotalCount = getTotalCount(prediction.options);
        return prediction.options.map((option) => {
            if (option.id === projectedSelectedOptionId) {
                const selectedCount = (option.selectedCount || 0) + 1;
                const totalCount = initialTotalCount + 1;

                const pointValue = computeOptionPointValue(
                    selectedCount,
                    totalCount,
                    prediction.pointValue,
                    prediction.type === 'MULTIPLE_CHOICE' &&
                        prediction.allOrNothing,
                );
                return { ...option, selectedCount, pointValue };
            }

            if (option.id === answerSelectedOptionId) {
                const selectedCount = (option.selectedCount || 1) - 1;
                const totalCount = initialTotalCount;

                const pointValue = computeOptionPointValue(
                    selectedCount,
                    totalCount,
                    prediction.pointValue,
                    prediction.type === 'MULTIPLE_CHOICE' &&
                        prediction.allOrNothing,
                );
                return { ...option, selectedCount, pointValue };
            }
            return option;
        });
    }

    // local data corresponds to firestore data
    return prediction.options;
};
