export default {
    fullLanguageName: 'Deutsch',
    messages: {
        'menu.play': 'Tippen',
        'menu.playing': 'Spielen: {eventName}',
        'menu.gameLeaderboard': 'Tabelle',
        'menu.groups.groups': 'Gruppen',
        'menu.tournament': 'Turnier',
        'menu.account': 'Account',

        'infoCollectionTitle.touchButtonLabel': 'Weiter',

        'onboarding.requestDisplayName.title': 'Spielername',
        'onboarding.requestDisplayName.description':
            'Wähle einen Spielernamen! Der Name wird in der Tabelle angezeigt.',
        'onboarding.requestDisplayName.field.title': 'Spielername',
        'onboarding.requestDisplayName.field.placeholder': 'Platzhalter',

        'onboarding.okta.title': 'Spiel wird geladen ...',
        'onboarding.updates.title': 'Updates',

        'inputField.helpMe': 'Kontakt',
        'inputField.helpQuestion':
            'Fragen? Besuche unsere {helpPage} oder {contactPage}',

        'inputField.helpLinkTitle': 'Hilfe',
        'inputField.contactLinkTitle': 'Kontakt Seite',
        'error.phoneAuth.invalidPhoneNumber': 'Ungültige Telefonnummer',
        'error.phoneAuth.tooManyAttempts':
            'Zuviele Versuche. Bitte versuche es später nocheinmal',
        'error.phoneAuth.incorrectVerificationCode': 'Falscher Code',
        'error.phoneAuth.verificationCodeExpired':
            'Code ist abgelaufen. Bitte neuen Code anfordern',
        'error.phoneAuth.verifyRequestFailed':
            'Code konnte nicht verifiziert werden',
        'error.failedToFetch':
            'Bitte überprüfe Deine Internetverbindung und versuche es nochmal',
        'error.displayName.isTaken': 'Ups. Der Name ist schon vergeben.',
        'error.offensiveLanguage': 'Na das war nicht jugendfrei ...',
        'inputField.error.charLimit': 'Bitte nicht mehr als {limit} Zeichen',

        'onboarding.disclaimer':
            'Indem Du fortfährst, stimmst Du den folgenden Regeln zu',
        'onboarding.officialRules': 'Offizielle Regeln',
        'onboarding.termsAndConditions': 'Geschäftsbedingungen',
        'onboarding.privacyPolicy': 'Datenschutz-Bestimmungen',
        'onboarding.separator': 'und',
        'onboarding.cookies': 'Cookies',

        'onboarding.requestEmail.field.title': 'E-Mail-Addresse:',
        'onboarding.requestEmail.bodyWithPrizes.markdown':
            'Wir benötigen Deine E-Mail um Dich zu kontaktieren falls Du gewinnst',
        'onboarding.requestEmail.bodyWithoutPrizes.markdown':
            'Bitte gib Deine E-Mail für Spielupdates ein.',
        'onboarding.invalidEmail': 'Bitte gib eine gültige E-Mail-Adresse ein',

        'optIn.marketing.phone.label': 'Ich möchte SMS bekommen.',
        'optIn.marketing.email.label': 'Ich möchte Emails bekommen.',
        'optIn.marketing.3p.label': 'Ich möchte kontaktiert werden',

        'onboarding.requestPhone.title': 'Willkommen',
        'onboarding.requestPhone.title.gameIsLive': 'Willkommen',
        'onboarding.requestPhone.description.gameIsLive':
            "Tippe kostenlos bevor und während des Spiels und gewinne exklusive Preise. Gib Deine Handynummer ein und los geht's",
        'onboarding.requestPhone.title.gameIsFinal': 'Einloggen',
        'onboarding.requestPhone.description.gameIsFinal':
            'Das Spiel ist vorbei, aber Du kannst Dich immer noch anmelden, um Ihre Ergebnisse zu sehen oder um für das nächste Spiel angemeldet zu sein',
        'onboarding.requestPhone.field.title': 'Telefonnummer:',
        'onboarding.requestPhone.field.placeholder': 'Telefonnummer',
        'onboarding.requestPhone.field.description':
            'Wir verwenden Deine Telefonnummer nur, um Dich zu kontaktieren wenn Du gewinnst',

        'onboarding.verifyCode.title': 'Code überprüfen',
        'onboarding.verifyCode.description':
            'Bitte gib den Bestätigungscode ein den wir an Dein Handy geschickt haben {phoneNumber}',
        'onboarding.verifyCode.resendCode': 'Code nochmal senden',

        'onboarding.newIteration.fallbackTitle': 'Spielregeln akzeptieren.',
        'onboarding.newIteration.fallbackNewOptInBody':
            'Bitte stimme den offiziellen Regeln und Allgemeinen Geschäftsbedingungen zu, um teilzunehmen.',
        'onboarding.newIteration.checkBox1FallbackLabel':
            'Durch das Anklicken dieser Box und Fortsetzung stimme ich den unten verlinkten offiziellen Regeln und Allgemeinen Geschäftsbedingungen zu',

        'gameFooter.contactSupport': 'Kontaktiere Support',
        'gameFooter.poweredBy': 'Powered By',

        'broadcastLeaderbord.playerLeaderBoard.title': 'Tabellenführung',
        'broadcastLeaderbord.playerLeaderBoard.stats.rank': 'Platz',
        'broadcastLeaderbord.playerLeaderBoard.stats.player': 'Spieler',
        'broadcastLeaderbord.playerLeaderBoard.stats.points': 'Punkte',

        'leaderBoardTab.switch.tournamentLeaderBoard': 'Turnier-Tabelle',
        'leaderBoardTab.switch.groupLeaderBoard': 'Gruppen-Tabelle',
        'leaderBoardTab.header': 'Tabelle',
        'leaderBoardTab.signInButton': 'Schon gespielt? Melde Dich jetzt an',
        'leaderBoardTab.finalAfterGameDisclaimer':
            'Bleib am Ball! Die Tabelle ändert sich laufend während des Spiels.',

        'prosLeaderboard.header': 'Besser als die Pros?',

        'leaderBoard.prizeDetails': 'Preisdetails',
        'leaderBoard.prizeDetailsButton': 'Preisdetails',
        'leaderBoard.correctPicks': 'Richtige Antworten',

        'settings.titleBar': 'PROFIL',
        'settings.welcomeText': 'Hallo, {username}',
        'settings.joinedText': 'Spieler seit: {date}',

        'account.rewards.header': 'Preise',
        'account.stats.header': 'Statistiken',
        'account.stats.previous': 'Zurück',
        'account.stats.next': 'Weiter',

        'settings.personalInfo.header': 'Kontaktdaten',
        'settings.personalInfo.description': 'E-Mail, Telefonnummer usw.',
        'settings.personalInfo.email.label': 'Email',
        'settings.personalInfo.email.button': 'Speichern',
        'settings.personalInfo.email.placeholder': 'Deine Emailadresse',
        'settings.personalInfo.phoneNumber.label': 'Telefonnummer',

        'settings.marketing.header': 'Marketing',
        'settings.marketing.descriptionText':
            'Erhalte  ___ und Anegbote von {partnerName}',
        'settings.smsAlertHeader': 'Spielbenachrichtigungen',
        'settings.smsAlertLabel': 'SMS-Spielbenachrichtigungen',
        'settings.smsAlertText':
            'Erhalte SMS-Benachrichtigungen immer wenn neue Vorhersagen live gehen, so daß Du keine Frage verpasst.',
        'settings.smsAlertUnstopText':
            "Um sich wieder in SMS-Benachrichtigungen zu entscheiden, sende 'unstop' an",
        'settings.smsAlertUnstopLink':
            'Klicke hier, um SMS Benachrichtigungen zu reaktivieren',
        'settings.subscribeButton.subscribe': 'Abonniert',
        'settings.subscribeButton.subscribed': 'Abonnieren',

        'groupSettings.groupLeaderbord': 'Gruppentabelle',
        'groupSettings.groupLeaderbordDescription':
            'Erstelle Gruppen, um gegen Freunde zu spielen',
        'groupSettings.definitionList.owner': 'Du bist der Besitzer von',
        'groupSettings.definitionList.isLoading': 'Lade Gruppe ...',
        'groupSettings.definitionList.member': 'Du bist Mitglied von',
        'groupSettings.definitionList.inviteLink': 'Gruppenlink',
        'groupSettings.definitionList.deleteGroup': 'Gruppe löschen',
        'groupSettings.definitionList.leaveGroup': 'Gruppe verlassen',
        'groupSettings.createGroup.inputLabel': 'Gruppenname',
        'groupSettings.createGroup.inputPlaceholder': 'Ein Gruppenname',
        'groupSettings.createGroup.buttonText': 'Gruppe erstellen',
        'groupSettings.createGroup.description':
            'Freunde die schon eine Gruppe haben? Frag nach dem Einladungslink!',

        'predictPage.userStats.titleOverride': 'Mein Spielstand',
        'predictPage.userStats.callToActionButton.label': 'TABELLE',
        'predictPage.userStats.howToPlayButton.label': 'Spielanleitung',
        'predictPage.predictionList.groupTitle': 'Noch zu beantworten',
        'predictPage.predictionList.groupTitleTokenized': 'Fragen',
        'predictPage.heroicPredictionNotice': 'Neue Fragen',
        'predictPage.predictionList.lockLabel': 'Tippe',
        'userStats.rank': 'Platz',
        'userStats.points': 'Punkte',
        'userStats.correct': 'Richtig',

        'toast.nextLocksIn': 'Tippen bis: {textAddon}',
        'toast.resultsSoon': 'Endergebnisse kommen in Kürze',
        'toast.gameOver': 'Game Over',

        'survey.callToAction': 'Wir würden gerne von Dir hören',
        'survey.subText': 'Sag uns wie Dir das Spiel gefallen hat',
        'survey.subTextLink': '2 Minuten Zeit für eine Umfrage?',

        'howToPlayModal.tag': 'Kostenlos spielen!',
        'howToPlayModal.header': 'Spielanleitung',
        'howToPlayModal.button': 'Zum Spiel',
        'howToPlayModal.defaultBody':
            'Neue Fragen kommen vor udn während des Spiels. Beantworte all Fragen bevor wir sie schliessen. Keine Antwort = keine Punkte.',

        'howToPlayTournamentModal.header': 'Spielanleitung',
        'howToPlayTournamentModal.button': 'Zum Spiel',

        'cumulativeLeaderboardTab.button': 'Spielanleitung',
        'cumulativeLeaderboardTab.myStats': 'Meine Turnierstatistiken',
        'cumulativeLeaderboardTab.gameLeaderboard': 'Tabelle',
        'cumulativeLeaderboardTab.groupLeaderboard': 'Gruppentabelle',

        'group.isLoading': 'Lade Gruppe ...',
        'group.shareLink.button': 'Gruppenlink kopieren',
        'group.shareLink.description.groups':
            'Kopiere den Gruppenlink und sende ihn per E-Mail, Text oder Social um Deine Freunde einzuladen.',

        'groupLeaderboardTab.headerText.error': 'Problem beim Laden der Gruppe',
        'groupLeaderboardTab.pointsDescription':
            'Benutzer und Punkte in der Gruppentabelle werden während und nach dem Spiel aktualisiert',
        'groupLeaderboardTab.headerText.notInGroup':
            'Bitte werde zuerst Mitgleid einer Gruppe oder starte Deine eigene Gruppe',
        'groupLeaderboardTab.headerText.notInGroup.descriptionStart':
            'Erstelle eine Gruppe, um mit Deinen Freunden zu spielen.',
        'groupLeaderboardTab.headerText.notInGroup.descriptionBottom':
            "Du kannst auch einer bestehenden Gruppe beitreten. Frag Deine Freunde nach dem Gruppenlink und schon kann's los gehen!",
        'groupLeaderboardTab.button': 'Spielanleitung',
        'groupLeaderboardTab.myStats': 'Meine Turnierstatistiken',
        'groupLeaderboardTab.gameLeaderboard': 'Tabelle',
        'groupLeaderboardTab.tournamentLeaderboard': 'Turniertabelle',

        'predictPage.smsOptInInfoModuleDetails.body':
            'Erhalte eine SMS wenn neue Fragen live gehen. Wir senden maximal 3 Nachrichten pro Spiel.',

        'predictPage.smsOptInInfoModuleDetails.button': 'Ja, Bitte!',
        'predictPage.smsOptInInfoModuleDetails.preTitle':
            'Weitere Fragen während des Spiels',
        'predictPage.smsOptInInfoModuleDetails.title': "Bleib' am Ball",
        'predictPage.noSmsOptInInfoModuleDetails.preTitle':
            'BENACHRICHTIGT WERDEN',
        'predictPage.noSmsOptInInfoModuleDetails.title':
            'Bald noch weitere Fragen',
        'predictPage.deadStateAndAllAnsweredInfoModuleDetails.body':
            'Neue Fragen während des gesamten Spiels. Wir benachrichtigen Dich per SMS wenn neue Fragen live sind',
        'predictPage.deadStateAndAllAnsweredInfoModuleDetails.title':
            'Bald noch weitere Fragen',
        'predictPage.deadStateAndAllReleasedInfoModuleDetails.body':
            'Punkte werden laufend aktualisiert. Verfolge das Spiel um zu sehen wo Du gerade stehst. Jeder Punkt ist wichtig!',
        'predictPage.deadStateAndAllReleasedInfoModuleDetails.preTitle':
            "Bleib' am Ball",
        'predictPage.deadStateAndAllReleasedInfoModuleDetails.title':
            'Bleib dabei bis zum Abpfiff!',
        'predictPage.activePredictionsInfoModuleDetails.body':
            'Aussenseitertips geben mehr Punkte als sichere Vorhersagen',
        'predictPage.activePredictionsInfoModuleDetails.preTitle': 'Achtung',
        'predictPage.activePredictionsInfoModuleDetails.title':
            'Die Tabelle verändert sich während des Spiels - jeder Tipp zählt!',
        'predictPage.smsOptInGameOverInfoModuleDetails.title': 'Game Over',
        'predictPage.basicGameOverInfoModuleDetails.bodyWithPrizes':
            'Gewinner werden per E-Mail kontaktiert. Das nächste Spile kommt bald!',
        'predictPage.basicGameOverInfoModuleDetails.bodyWithoutPrizes':
            'Hat Dir das Spiel gefallen? Schau in der Tabelle nach wo Du stehst!',
        'predictPage.basicGameOverInfoModuleDetails.preTitle':
            'Glückwunsch an die Gewinner',
        'predictPage.basicGameOverInfoModuleDetails.title': 'Game Over',
        'predictPage.readMoreButton.text': 'Weiterlesen',

        'pistachioGame.stickyButton.text.single':
            '{count} unbeantwortete Frage',
        'pistachioGame.stickyButton.text.many': '{count} unbeantwortete Fragen',
        'pistachioGame.stickyButton.gameDisclaimerFallback': 'Bitte lies auch',
        'pistachioGame.toast.correct.title': 'Genau richtig!',
        'pistachioGame.toast.correct.text': '+{points} Punkte',
        'pistachioGame.toast.wrong.title': 'Leider nicht richtig',
        'pistachioGame.toast.wrong.text': 'Weiter so @{Name}',
        'pistachioGame.toast.noCorrect.title': 'Schade! 0 / {Count} richtig',
        'pistachioGame.toast.noCorrect.text': 'Weiter so @{Name}',
        'pistachioGame.toast.someCorrect.title': 'Super!',
        'pistachioGame.toast.someCorrect.text': '+{points} Punkte',
        'pistachioGame.pollToast.text': '+{points} Punkte',
        'pistachioGame.pollToast.title': 'Umfrage ist gezählt!',

        'heroicCountdown.headline': 'Fragen beantworten',

        'informationModule.closeButton': 'NEIN DANKE',

        'countdownHeader.label': '{timeLeftText}',

        'waitingRoom.smallPrediction.ended': 'Du hast diese Fragen verpasst!',
        'waitingRoom.smallPrediction.notEnded': 'Spielstart',
        'waitingRoom.emailNotifyTitle.ended':
            "Erhalte ein SMS Benachrichtigung für's nächste Spiel",
        'waitingRoom.emailNotifyTitle.notEnded':
            'Ja, ich biite schickt eine Email wenn das Spiel startet',
        'waitingRoom.emailSubmitted.smallActionText': 'Du bist auf der Liste',
        'waitingRoom.emailSubmitted.bigActionText': 'Zack!',
        'waitingRoom.detailsButton': 'Details',
        'waitingRoom.submitButton': 'Senden',
        'waitingRoom.continueSkipButton.continue': 'Weiter',
        'waitingRoom.continueSkipButton.skip': 'Nächste',
        'waitingRoom.gameOver': 'Game Over',
        'waitingRoom.soon': 'Bald',
        'waitingRoom.tomorrowTime': 'Morgen {time}',

        'newPredictionCard.status.missed': 'Verpasst',
        'newPredictionCard.status.anytime': 'Jederzeit',
        'newPredictionCard.status.incorrect': 'Falsch',
        'newPredictionCard.status.participated': 'Beantwortet',
        'newPredictionCard.status.correct': 'Richtig',
        'newPredictionCard.status.pending': 'Fragen kommen {when}',
        'newPredictionCard.pollHeading': 'UMFRAGE',
        'newPredictionCard.pollPoints': 'Punkte',
        'newPredictionCard.sponsorshipTag': 'Gewinne mit dem richtigen Tipp',

        'offerDetails.redeem.ButtonText': 'Einlösen',
        'offerDetails.redeem.codeTitle': 'Dein Code:',
        'offerDetails.expirationText.expired': 'Am {when} abgelaufen',
        'offerDetails.expirationText.expires': 'Läuft am {when} ab',
        'offerDetails.redeem.howTo': 'Anleitung zum Einlösen ',
        'offerDetails.redeem.disclaimer': 'Haftungsausschluss Text',

        'leaderboardDeadstate.subHead': 'Ergebnisse in Kürze ⏲',
        'leaderboardDeadstate.waitingLabel':
            'Schaue nach Spielstart wieder vorbei',
        'leaderboardDeadstate.buttonText': 'Die Preise',

        'incognitoModal.tagLineText': 'Achtung',
        'incognitoModal.headerText': 'Inkognito-Modus',
        'incognitoModal.bodyText':
            'Das Spielen dieses Spiels im Incognito-Modus kann dazu führen, dass Vorhersagen nicht gespeichert werden. Bitte wechsel in den regulären Browser-Modus, um sicherzustellen, dass Deine Tips und Dein Spielername erhalten bleiben.',
        'incognitoModal.buttonText': 'Ich stimme zu',

        'offerWinModal.youWon.label': 'Du hast gewonnen',
        'offerWinModal.rewardsButton.text': 'Preise Ansehen',

        'newPredictionModal.label': 'Jetzt schnell tippen',
        'newPredictionModal.buttonText': 'Jetzt tippen',

        'timeUntilLock.timeUntilLockMode.short': '1 TAG',
        'timeUntilLock.timeUntilLockMode.medium': 'Morgen {timeString}',
        'timeUntilLock.timeUntilLockMode.fallback': 'Morgen {timeString}',
        'timeUntilLock.dateType.actualDate': 'Tippe bis {dateString}',
        'timeUntilLock.dateType.daysUntil':
            'Tippe innerhalb von {daysUntilStart} Tagen',

        'dates.longWeekDay.sunday': 'Sonntag',
        'dates.longWeekDay.monday': 'Montag',
        'dates.longWeekDay.tuesday': 'Dienstag',
        'dates.longWeekDay.wednesday': 'Mittwoch',
        'dates.longWeekDay.thursday': 'Donnerstag',
        'dates.longWeekDay.friday': 'Freitag',
        'dates.longWeekDay.saturday': 'Samstag',
        'dates.shortWeekDay.sunday': 'Sonne.',
        'dates.shortWeekDay.monday': 'Anzunehmen',
        'dates.shortWeekDay.tuesday': 'Tue.',
        'dates.shortWeekDay.wednesday': 'Heiraten.',
        'dates.shortWeekDay.thursday': 'Thu.',
        'dates.shortWeekDay.friday': 'Frech',
        'dates.shortWeekDay.saturday': 'Sat.',

        'countdownPageBreak.batchLabel': 'Tippe',

        'powerUpAnimation.doublePointPowerup.label': 'Ein Bonus von {name}',
        'powerUpAnimation.fallback.label': 'Ein Bonus von {name}',

        'verifyCodeCountdown.button.label': 'Code nochmal senden',
        'verifyCodeCountdown.underlineText': 'Code gesendet',
        'verifyCodeCountdown.underlineCountdown':
            'Zeit bis neuer Code gesendet wird ',

        'bonusSponsors.thankYouLabel': 'Vielen Dank an unsere Sponsoren',

        'playgroundGame.decoySpacer': 'Willkommen zum Spiel 🤘',

        'prizeDetailsModal.button': "Los geht's",
        'prizeDetailsModal.header': 'Preise',

        'broadcastSideboard.copyQuestionButton': 'KOPIEREN',
        'broadcastSideboard.copyOptionButton': 'KOPIEREN',
        'broadcastSideboard.percentSpan': 'Fülle die Lücke aus',
        'broadcastSideboard.clipboardText': 'kopiert!',
        'broadcastView.playerCount': '{count} Spieler',
        'broadcastView.emptyState': 'Es wurden noch keine Fragen beantwortet.',
        'tvBroadcastView.rank': 'Platz',
        'tvBroadcastView.player': 'Spieler',
        'tvBroadcastView.points': 'Punkte',
        'tvBroadcastView.correct': '# Richtig',
        'groupPage.groupView.subHead': 'Aktuelle Gruppenmitglieder:',
        'groupPage.groupView.owner': 'Besitzer',
        'groupPage.error': 'Fehler beim Abrufen der Gruppe!',
        'groupPage.warning':
            'Du kannst nur Mitglied in einer Gruppe sein. Wenn Du einer neuen Gruppe beitritts verlässt Du automatisch die Grupper in der Du derzeit Mitglied bist.',
        'groupPage.playButton': "Los geht's",
        'groupPage.joinButton': 'Gruppe beitreten',
        'groupPage.joinedButton': 'Du bist jetzt in der Gruppe!',
        'presentedBy.label': 'Präsentiert von',
        'createGroupForm.inputLabel': 'Gruppenname',
        'createGroupForm.buttonText': 'Gruppe erstellen',
        'createGroupForm.cancel': 'Abbrechen',
        'createGroupForm.inputPlaceholder': 'Ein Gruppenname',
        'deleteGroupForm.ownerDefinition': 'Du bist der Besitzer von',
        'deleteGroupForm.memberDefinition': 'Du bist Mitglied von',
        'deleteGroupForm.ownerButtonText': 'Gruppe löschen',
        'deleteGroupForm.memberButtonText': 'Gruppe verlassen',
        'copyToClipboardButton.tooltip.copied': 'Kopiert',
        'socialTitle.label': 'Teilen Sie das Spiel',
        'offerCard.status.pending': 'Entriegelt nach dem Spiel',
        'offerCard.status.today': 'Heute',
        'offerCard.status.tomorrow': 'Morgen',
        'offerCard.status.inDays': '{in} Tagen',
        'offerCard.status.expd': 'Abgelaufen: {when}',
        'offerCard.status.exps': 'Erlischt: {when}',
        'predictions.blank.label': 'Mein Tip',
        'predictions.blank.average': 'ø',
        'predictions.blank.result': 'ERGEBNIS',
        'predictions.blank.errorGreater': 'Der Maximalwert beträgt {value}',
        'predictions.blank.errorBelow': 'Minimalwert ist {value}',

        'prediction.option.odds.betToWin': 'Wette {bet} um {win} zu gewinnen',
        'prediction.option.odds.liveOddsBy':
            'Live-Kurse, die von {sportsbook} bereitgestellt werden',
        'prediction.option.odds.oddsType.MONEY_LINE': 'Moneyline',
        'prediction.option.odds.oddsType.OVER_UNDER': 'Over/Under',
        'prediction.option.draw': 'Draw',
        'prediction.option.nobet': 'No Bet',

        'storageConsentModal.description':
            'Diese Website speichert Daten wie Cookies, um wichtige Website-Funktionen zu ermöglichen, einschließlich Analysen, Targeting und Personalisierung. Sie können Ihre Einstellungen jederzeit ändern oder die Standardeinstellungen akzeptieren.',
        'storageConsentModal.storagePolicy.link.text':
            'Richtlinie zur Datenspeicherung',
        'storageConsentModal.necessary.switch.label': 'Notwendig',
        'storageConsentModal.analytics.switch.label': 'Analyse',
        'storageConsentModal.button.save': 'Speichern',
    },
};
