import styled from '@emotion/styled';
import React, { useState, ChangeEvent } from 'react';
import { keyframes } from 'emotion';
import { Color } from 'src/styles/Constants';
import clearLightIcon from 'src/images/icons/Clear-Default@3x.svg';
import clearErrorIcon from 'src/images/icons/Clear-Error@3x.svg';
import ServerError from 'src/services/ServerError';
import {
    dateBetweenValidation,
    dateAfterValidation,
    dateBeforeValidation,
    dateOlderThanYOValidation,
} from 'src/util/validation';
import InputLabel from '../../ui/InputLabel';
import HelpLinks from '../../ui/HelpLinks';
import { DateValidationType, DateValidation } from 'src/util/ConfigTypes';
import { ValidationError } from 'src/util/validation';

interface Props {
    field: string;
    error?: Error | ServerError;
    onClear?: () => void;
    onChange?: (value: any) => void;
    label?: string;
    onContactSupportClick: () => void;
    validation?: DateValidation;
    validationErrorText?: string;
    onError?: (error: ValidationError) => void;
    onClearError?: (field: string) => void; // clear upper accumulator for errors by its field identification
}

export default ({
    field,
    onChange,
    onClear,
    error,
    label,
    onContactSupportClick,
    validation,
    validationErrorText,
    onError,
    onClearError,
}: Props): any => {
    const [currentValue, setCurrentValue] = useState<string>('');

    const backgroundColor = Color.P1_WHITE;
    const color = Color.T31_BLACK;
    const clearIcon = clearLightIcon;

    let borderColor = error ? Color.S13_MAROON : Color.T34_SQUIRTLE;

    const validate = (value: string | undefined): void => {
        if (!value) {
            const error = new ValidationError({
                message: validationErrorText || 'Invalid input',
                field,
            });

            onError && onError(error);
            return;
        }
        let result = true;
        if (validation) {
            switch (validation.type) {
                case DateValidationType.BETWEEN:
                    result = dateBetweenValidation({
                        value,
                        dateValidation: validation,
                    });
                    break;
                case DateValidationType.AFTER:
                    result = dateAfterValidation({
                        value,
                        dateValidation: validation,
                    });
                    break;
                case DateValidationType.BEFORE:
                    result = dateBeforeValidation({
                        value,
                        dateValidation: validation,
                    });
                    break;
                case DateValidationType.OLDER_THAN_Y_O:
                    result = dateOlderThanYOValidation({
                        value,
                        dateValidation: validation,
                    });
                    break;
                default:
                    result = true;
                    break;
            }
        }

        if (result) {
            onClearError && onClearError(field);
        } else {
            const error = new ValidationError({
                message: validationErrorText || 'Invalid input',
                field: field,
            });

            onError && onError(error);
        }
    };

    const change = (event: ChangeEvent<HTMLInputElement>) => {
        setCurrentValue(event.target.value);
        validate(event.target.value);
        onChange && onChange(event.target.value);
    };
    const clear = () => {
        setCurrentValue('');
        validate(undefined);
        onClear && onClear();
    };

    return (
        <>
            <InputLabel label={label} error={error} />
            {error && (
                <HelpLinks onContactSupportClick={onContactSupportClick} />
            )}
            <InputContainer
                backgroundColor={backgroundColor}
                borderColor={borderColor}
                showError={!!error}
            >
                <DateInput
                    color={color}
                    type="date"
                    onChange={change}
                    value={currentValue}
                />
                <ClearButton
                    onClick={clear}
                    src={error ? clearErrorIcon : clearIcon}
                />
            </InputContainer>
        </>
    );
};

const errorBounce = keyframes`
    from, 80%, to {
        transform: translate3d(0,0,0);
    }

    20% {
        transform: translate3d(-7px, 0, 0);
    }

    40% {
        transform: translate3d(7px, 0, 0);
    }

    60% {
        transform: translate3d(-3px,0,0);
    }

    70% {
        transform: translate3d(3px,0,0);
    }
`;

const ClearButton = styled.img`
    cursor: pointer;
    margin: 0 10px;
    transition: 0.3s all;
`;

const InputContainer = styled.div<{
    backgroundColor: Color;
    borderColor: Color;
    showError: boolean;
}>`
    margin: 10px 0 0 0;
    font-family: Graphik Web;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.backgroundColor};
    width: 100%;
    border: 1px solid ${(props) => props.borderColor};
    animation: ${(props) =>
        props.showError ? errorBounce : undefined} 0.3s ease;
    transition 0.3s all;
`;

const DateInput = styled.input<{ color: Color }>`
    display: flex;
    flex: 1;
    color: ${(props) => props.color};
    background-color: transparent;
    font-size: 14px;
    border: 0px;
    height: 45px;
    outline: none;
    padding: 0 3px 0 15px;
`;
