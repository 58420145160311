import React from 'react';
import styled from '@emotion/styled';
import { BINGO_SQUARE_STATE_TO_COLOR, IMAGE_SIZE } from './constants';
import { Timestamp } from 'src/services/Firebase';
import { BingoSquareModel } from 'src/util/TallyFirestore';

const BingoSquare = ({
    text,
    imageUrl,
    occurredDate,
    win,
}: BingoSquareModel & { win?: boolean }) => {
    return (
        <BingoCell occurredDate={occurredDate} win={win}>
            <SquareAspectRatioThingy>
                <BingoImage
                    width={IMAGE_SIZE}
                    height={IMAGE_SIZE}
                    src={imageUrl}
                    alt={text}
                />
                <Label occurredDate={occurredDate} win={win}>
                    {text}
                </Label>
            </SquareAspectRatioThingy>
        </BingoCell>
    );
};

export default BingoSquare;

function propsToState({
    win,
    occurredDate,
}: {
    win?: boolean;
    occurredDate: Timestamp | null;
}): keyof typeof BINGO_SQUARE_STATE_TO_COLOR {
    return win ? 'win' : occurredDate ? 'occurred' : 'neutral';
}

const BingoCell = styled.div<{ win?: boolean; occurredDate: Timestamp | null }>`
    background-color: ${(props) =>
        BINGO_SQUARE_STATE_TO_COLOR[propsToState(props)].backgroundColor};
    color: ${(props) => BINGO_SQUARE_STATE_TO_COLOR[propsToState(props)].color};
`;

const SquareAspectRatioThingy = styled.div`
    padding-top: 100%;
    position: relative;
    overflow: hidden;
`;

const Label = styled.div<{ win?: boolean; occurredDate: Timestamp | null }>`
    position: absolute;
    bottom: 0;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    background-color: ${(props) =>
        BINGO_SQUARE_STATE_TO_COLOR[propsToState(props)].backgroundColor};
    color: ${(props) => BINGO_SQUARE_STATE_TO_COLOR[propsToState(props)].color};
`;

const BingoImage = styled.img`
    position: absolute;
    top: 0;
    max-height: ${IMAGE_SIZE}px;
    max-width: ${IMAGE_SIZE}px;
    width: 100%;
    height: 100%;
`;
