import React, { useState } from 'react';
import PhoneCodeAuthTile from './PhoneCodeAuthTile';
import { usePartner } from 'src/hooks/usePartner';
import PhoneNumberAuthTile from './PhoneNumberAuthTile';
import { EventModel } from 'src/util/TallyFirestore';

type Props = {
    event?: EventModel;
    onContactSupportClick: () => void;
    screenHasBg?: boolean | false;
    disclaimer?: JSX.Element;
};

const PhoneAuthTiles = ({
    onContactSupportClick,
    screenHasBg,
    disclaimer,
}: Props): JSX.Element => {
    const partner = usePartner();
    // once the phoneNumber is sent, verification code is requested
    // for now we can't go back.
    const [phoneSubmitState, setPhoneSubmitState] = useState<
        { phoneNumber: string; verifyToken: string } | undefined
    >(undefined);

    return phoneSubmitState ? (
        <PhoneCodeAuthTile
            partner={partner}
            phoneNumber={phoneSubmitState.phoneNumber}
            verifyToken={phoneSubmitState.verifyToken}
            onContactSupportClick={onContactSupportClick}
            screenHasBg={screenHasBg}
            disclaimer={disclaimer}
        />
    ) : (
        <PhoneNumberAuthTile
            partner={partner}
            onSubmitted={({ verifyToken, phoneNumber }) => {
                setPhoneSubmitState({ verifyToken, phoneNumber });
            }}
            onContactSupportClick={onContactSupportClick}
            screenHasBg={screenHasBg}
            disclaimer={disclaimer}
        />
    );
};

export default PhoneAuthTiles;
