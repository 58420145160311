import firebase, {
    DocumentSnapshot,
    DocumentReference,
    QuerySnapshot,
} from 'src/services/Firebase';
import {
    CategoryModel,
    TranslatableEventModel,
    PredictionModel,
    UserModel,
    ContactInfo,
    Collection,
    UserPredictionModel,
    Iteration,
    SponsorModel,
    UserOffer,
    UserData,
    ProgressivePollAnswer,
    ProgressivePollModel,
    SummarizedLeaderboard,
    NormalLeaderboardEntry,
    UserWithPrivateData,
    BingoSquareModel,
} from './TallyFirestore';
import config from 'src/util/Config';
import { GroupType } from 'src/types';

const partnerId = config.partnerData.partnerId;

const pathsByGroupType: Record<GroupType, Collection> = {
    TEAM: Collection.teams,
    VENUE: Collection.venues,
    USER_GROUP: Collection.userGroups,
} as const;

export const getInterGroupLeaderboardPath = (
    entityPath: string,
    groupType: GroupType,
) =>
    `${entityPath}/${Collection.groupLeaderboards}/${pathsByGroupType[groupType]}`;

export const getIntraGroupLeaderboardPath = (
    entityPath: string,
    groupType: GroupType,
    groupId: string,
) =>
    `${getInterGroupLeaderboardPath(entityPath, groupType)}/${
        Collection.intraGroupLeaderboards
    }/${groupId}`;

export async function getEventByPath(
    eventPath: string,
): Promise<TranslatableEventModel> {
    const eventRef = firebase.firestore().doc(eventPath);
    return getEventFromDocumentSnapshot(await eventRef.get());
}

export function getSponsorsCollectionPath(partnerId: string) {
    return `/partners/${partnerId}/sponsors`;
}

function attachIdAndPath<T extends { id: string; path: string }>(
    documentSnapshot: DocumentSnapshot,
): T {
    return {
        ...(documentSnapshot.data() as any),
        id: documentSnapshot.id,
        path: documentSnapshot.ref.path,
    };
}

function attachId<T extends { id: string }>(
    documentSnapshot: DocumentSnapshot,
): T {
    return {
        ...(documentSnapshot.data() as any),
        id: documentSnapshot.id,
    };
}
export function getBingoSquareFromDocumentSnapshot(
    doc: DocumentSnapshot,
): BingoSquareModel {
    return attachId<BingoSquareModel>(doc);
}

export function getEventFromDocumentSnapshot(
    doc: DocumentSnapshot,
): TranslatableEventModel {
    return attachIdAndPath<TranslatableEventModel>(doc);
}

export function getSponsorFromDocumentSnapshot(
    doc: DocumentSnapshot,
): SponsorModel {
    return doc.data() as SponsorModel;
}

export function getPredictionFromDocumentSnapshot(
    doc: DocumentSnapshot,
): PredictionModel {
    const prediction: PredictionModel = doc.data() as PredictionModel;
    prediction.id = doc.id!;
    return prediction;
}

export function getUserAnswersFromDocumentSnapshot(
    doc: DocumentSnapshot,
): UserPredictionModel {
    return doc.data() as UserPredictionModel;
}

export function getProgressivePollFromDocumentSnapshot(
    doc: DocumentSnapshot,
): ProgressivePollModel {
    return doc.data() as ProgressivePollModel;
}

export const getUserPPollAnswersPath = ({
    partnerId,
    iterationId,
    uid,
}: {
    partnerId: string;
    iterationId: string;
    uid: string;
}): string =>
    `${Collection.partners}/${partnerId}/${Collection.users}/${uid}/${Collection.progressivePollIterations}/${iterationId}`;

export function getProgressivePollAnswersFromDocumentSnapshot(
    data: DocumentSnapshot,
): ProgressivePollAnswer[] {
    const docData = data.data();
    return docData
        ? Object.keys(docData).map((pollId) => ({
              optionId: docData[pollId].optionId,
              pollId,
              eventId: docData[pollId].eventId,
          }))
        : [];
}

export function getUserAnswersForEventRef(params: {
    eventId: string;
    partnerId: string;
    uid: string;
}): DocumentReference {
    const { eventId, partnerId, uid } = params;
    return firebase
        .firestore()
        .collection(Collection.partners)
        .doc(partnerId)
        .collection(Collection.users)
        .doc(uid)
        .collection(Collection.predictions)
        .doc(eventId);
}

export async function getIterationByPath(
    iterationPath: string,
): Promise<Iteration> {
    const iterationDoc = await firebase.firestore().doc(iterationPath).get();

    return attachIdAndPath<Iteration>(iterationDoc);
}

export async function getCurrentIteration(
    categoryId: string,
): Promise<Iteration | undefined> {
    const startedIterationsSnapshot: QuerySnapshot = await firebase
        .firestore()
        .collection(
            `/partners/${partnerId}/categories/${categoryId}/iterations`,
        )
        .where('startDate', '<=', new Date())
        .get();

    const iterations = startedIterationsSnapshot.docs.map(
        (doc: DocumentSnapshot) => {
            return attachIdAndPath<Iteration>(doc);
        },
    );

    const filteredIterations = iterations.filter(
        (iteration) =>
            !iteration.endDate || iteration.endDate.seconds * 1000 > Date.now(),
    );

    const sortedIterations = filteredIterations.sort(
        (a: Iteration, b: Iteration) =>
            ((a.startDate && a.startDate.seconds) || 0) >
            ((b.startDate && b.startDate.seconds) || 0)
                ? -1
                : 1,
    );

    return sortedIterations[0];
}

export async function getAllEventsForIterationByPath(
    iterationPath: string,
): Promise<TranslatableEventModel[]> {
    const snapshot = await firebase
        .firestore()
        .doc(iterationPath)
        .collection('events')
        .get();
    return snapshot.docs.map(getEventFromDocumentSnapshot);
}

export async function getUserWithPrivateData(
    uid: string,
): Promise<UserWithPrivateData | undefined> {
    const details = await getUser(uid);
    if (!details) {
        return undefined;
    }
    const [contactInfo, offers] = await Promise.all([
        getContactInfo(uid),
        getOffers(uid),
    ]);

    return {
        details,
        contactInfo,
        offers,
    };
}

export async function getUser(uid: string): Promise<UserModel | undefined> {
    const path = `/partners/${partnerId}/users/${uid}`;
    const document = await firebase.firestore().doc(path).get();
    const user = document.data() as UserModel;
    return user;
}

export async function getCategory(
    categoryId: string,
): Promise<CategoryModel | undefined> {
    const path = `/partners/${partnerId}/categories/${categoryId}`;
    const document = await firebase.firestore().doc(path).get();
    const category = document.data() as CategoryModel;
    return category;
}

// TODO: quick VENUE feature. TODO: rework after there are event intra-group leaderboards
// https://coherentsolutions.atlassian.net/browse/TP-337
export async function getPreviousIterationGroupLeaderboard({
    currentIteration,
    groupId,
    userId,
}: {
    currentIteration: { id: string; categoryId: string };
    groupId: string;
    userId: string;
}): Promise<
    | {
          leaderboard: SummarizedLeaderboard;
          leaderboardEntry: NormalLeaderboardEntry | undefined;
          iteration: Iteration;
      }
    | undefined
> {
    const startedIterationsSnapshot: QuerySnapshot = await firebase
        .firestore()
        .doc(
            `/partners/${partnerId}/categories/${currentIteration.categoryId}/`,
        )
        .collection('iterations')
        .where('startDate', '<=', new Date())
        .get();

    let sortedIterations = startedIterationsSnapshot.docs
        .map((doc: DocumentSnapshot) => {
            return attachIdAndPath<Iteration>(doc);
        })
        .filter((doc) => doc.id !== currentIteration.id)
        .sort((a: Iteration, b: Iteration) =>
            ((a.startDate && a.startDate.seconds) || 0) >
            ((b.startDate && b.startDate.seconds) || 0)
                ? -1
                : 1,
        );
    const previousIteration = sortedIterations[0];

    if (previousIteration) {
        const groupDocRef = firebase
            .firestore()
            .doc(
                `/partners/${partnerId}/categories/${currentIteration.categoryId}/iterations/${previousIteration.id}/groups/${groupId}`,
            );

        const topPlayersRef = await groupDocRef
            .collection('summaries')
            .doc('topPlayers')
            .get();

        if (topPlayersRef.exists) {
            const leaderboard: SummarizedLeaderboard | undefined =
                topPlayersRef.data() as SummarizedLeaderboard;

            const leaderboardEntryRef = await groupDocRef
                .collection('leaderboard')
                .doc(userId)
                .get();

            return {
                leaderboard,
                leaderboardEntry: leaderboardEntryRef.exists
                    ? (leaderboardEntryRef.data() as NormalLeaderboardEntry)
                    : undefined,
                iteration: previousIteration,
            };
        }
    }
}

export async function getContactInfo(
    uid: string,
): Promise<ContactInfo | undefined> {
    const path = `/partners/${partnerId}/users/${uid}/privateData/contactInfo`;
    const document = await firebase.firestore().doc(path).get();
    const contactInfo = document.data() as ContactInfo;
    return contactInfo;
}

export async function getOffers(uid: string): Promise<UserOffer[] | undefined> {
    const path = `/partners/${partnerId}/users/${uid}/privateData/userData`;
    const document = await firebase.firestore().doc(path).get();
    if (!document.data()) {
        return;
    }
    const userData = document.data() as UserData;
    const { offers } = userData;
    return offers;
}
