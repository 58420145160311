import { RefObject } from 'react';
import {
    PredictionModel,
    ProgressivePollModel,
    ProgressivePollProperties,
} from 'src/util/TallyFirestore';

const PredictionPageScrollTargets = [
    'livePredictionList',
    'pastPredictionList',
] as const;

export type PredictionPageScrollTarget =
    (typeof PredictionPageScrollTargets)[number];

export type ProjectedAnswers = {
    [predictionId: string]: string;
};

type PredictionScrollTargets = {
    livePredictionListRef: RefObject<HTMLDivElement>;
    pastPredictionListRef: RefObject<HTMLDivElement>;
};

const findTheClosestToScrollTo = (rects: DOMRect[], offset: number): number => {
    let index = 0;
    rects.reduce((prevRect, curRect, curIndex) => {
        if (
            Math.abs(curRect.top + offset) < Math.abs(prevRect.bottom + offset)
        ) {
            index = curIndex;
            return curRect;
        }
        return prevRect;
    });

    return index;
};

export const getPredicitonPageScrollTarget = (
    { livePredictionListRef, pastPredictionListRef }: PredictionScrollTargets,
    offset: number,
): PredictionPageScrollTarget => {
    if (livePredictionListRef.current && pastPredictionListRef.current) {
        return PredictionPageScrollTargets[
            findTheClosestToScrollTo(
                [
                    livePredictionListRef.current.getBoundingClientRect(),
                    pastPredictionListRef.current.getBoundingClientRect(),
                ],
                offset,
            )
        ];
    }

    return 'livePredictionList';
};

export const getProgressivePollToShow = ({
    progressivePollConfig,
    prediction,
    progressivePolls,
}: {
    progressivePollConfig: ProgressivePollProperties;
    prediction: PredictionModel;
    progressivePolls?: ProgressivePollModel[];
}) => {
    const progressivePollIndexToPick: number =
        progressivePollConfig.desiredPollPlaces.findIndex(
            (place) => place === prediction.number,
        );
    if (progressivePolls && progressivePollIndexToPick != -1) {
        const progressivePollToShow: ProgressivePollModel =
            progressivePolls[progressivePollIndexToPick];

        return progressivePollToShow || null;
    } else {
        return null;
    }
};
