export default {
    fullLanguageName: 'Italiano',
    messages: {
        'menu.play': 'Giocare',
        'menu.playing': 'Giocando: {eventName}',
        'menu.gameLeaderboard': 'Tabella di classificazione',
        'menu.groups.groups': 'Gruppi',
        'menu.groups.venue': 'Scommesse al bar',
        'menu.tournament': 'Torneo',
        'menu.account': 'Account',
        'infoCollectionTitle.touchButtonLabel': 'Seguente',
        'onboarding.requestDisplayName.title': 'Scegli un nome utente',
        'onboarding.requestDisplayName.description':
            'Scegli un nome utente! Questo apparirà nella tabella di classificazione, quindi assicurati di sceglierlo.',
        'onboarding.requestDisplayName.field.title': 'Nome utente',
        'onboarding.requestDisplayName.field.placeholder': 'segnaposto',
        'onboarding.okta.title': 'Accedi con Okta',
        'onboarding.updates.title': 'Aggiornamenti',
        'inputField.helpMe': 'Contattaci',
        'inputField.helpQuestion':
            'Hai bisogno di aiuto? Visita la nostra pagina {helppage} o {contactPage}',
        'inputField.helpQuestionShort':
            'Hai bisogno di aiuto? Visita la nostra pagina {helppage}',
        'inputField.helpLinkTitle': 'Aiuto',
        'inputField.contactLinkTitle': 'Contattaci',
        'error.phoneAuth.invalidPhoneNumber': 'Numero di telefono non valido',
        'error.phoneAuth.verificationCodesBlocked':
            'La spedizione non è riuscita. Verifica di aver inserito un numero di cellulare sul modulo XXX-XXX-XXXX',
        'error.phoneAuth.tooManyAttempts':
            'Troppi tentativi. Riprovare più tardi.',
        'error.phoneAuth.incorrectVerificationCode':
            'il codice di verifica è sbagliato',
        'error.phoneAuth.verificationCodeExpired':
            'Il codice di verifica è scaduto. Per favore, inoltra e riprova.',
        'error.phoneAuth.verifyRequestFailed':
            "L'applicazione per verificare il codice di un utente non è riuscita.",
        'error.failedToFetch':
            'Si prega di controllare la tua connessione Internet e riprovare',
        'error.generic': "C'è stato un errore",
        'error.displayName.isTaken': 'Che peccato! Il nome è già in uso.',
        'error.offensiveLanguage':
            'È stato rilevato un linguaggio inappropriato',
        'inputField.error.charLimit':
            'Ha superato {limite} il limite del carattere',
        'inputField.error.validationError': 'Catena non valida',
        'displayName.error':
            'Il nome deve avere tra i caratteri {min} e {max}, può contenere lettere, numeri, meno o sottolineato con almeno una lettera',
        'onboarding.disclaimer':
            "Continuando sei d'accordo con i nostri termini",
        'onboarding.officialRules': 'Regole ufficiali',
        'onboarding.termsAndConditions': 'Termini e Condizioni',
        'onboarding.privacyPolicy': 'politica sulla riservatezza',
        'onboarding.separator': 'e',
        'onboarding.cookies': 'cookies',
        'onboarding.requestEmail.field.title': 'E-mail',
        'onboarding.requestEmail.bodyWithPrizes.markdown':
            'Per richiedere i premi, abbiamo bisogno della tua email per contattarti',
        'onboarding.requestEmail.bodyWithoutPrizes.markdown':
            'Inviaci la tua email in modo da poter comunicare.',
        'onboarding.invalidEmail': 'Inserisci una email valida',
        'optIn.marketing.phone.label': 'Ricevere testi',
        'optIn.marketing.email.label': 'Ricevi messaggi di posta elettronica',
        'optIn.marketing.3p.label': 'Ricevi messaggi dai nostri sponsor',
        'optIn.marketing.tally.label':
            'Vorrei ricevere comunicazioni future da Tall e dai suoi associati tramite e-mail e accettare la [Informativa sulla privacy] (https://www.playtallly.com/policies/privacy/tallly-privacy-policy) che appare di seguito',
        'onboarding.requestPhone.title': 'Ben arrivato',
        'onboarding.requestPhone.title.gameIsLive': 'Ben arrivato',
        'onboarding.requestPhone.description.gameIsLive':
            'Fai previsioni gratuite mentre le partite sono in gioco e competi per vincere premi esclusivi.',
        'onboarding.requestPhone.title.gameIsFinal': 'Accesso',
        'onboarding.requestPhone.description.gameIsFinal':
            'Il gioco è finito ma puoi entrare per vedere i tuoi risultati.',
        'onboarding.requestPhone.field.title': 'Numero di telefono',
        'onboarding.requestPhone.field.placeholder': 'XXX-XXX-XXXX',
        'onboarding.requestPhone.field.description':
            'Usiamo il tuo numero di telefono solo per contattarti se hai vinto',
        'onboarding.verifyCode.title': 'Verificare il codice',
        'onboarding.verifyCode.description':
            'Inserisci il codice di verifica che ti abbiamo inviato {PhoneNumber}',
        'onboarding.verifyCode.resendCode': 'inviare nuovamente il codice',
        'onboarding.newIteration.fallbackTitle': 'Accetta le regole del gioco',
        'onboarding.newIteration.fallbackNewOptInBody':
            'Accetta le regole e i termini e le condizioni ufficiali per partecipare.',
        'onboarding.newIteration.checkBox1FallbackLabel':
            'Quando si segna questa casella e continua, accetto le regole ufficiali e i termini e le condizioni collegati di seguito.',
        'gameFooter.contactSupport': 'Contattare il supporto',
        'gameFooter.poweredBy': 'Powered by',
        'broadcastLeaderbord.playerLeaderBoard.title':
            'Leader di classificazione',
        'broadcastLeaderbord.playerLeaderBoard.stats.rank': 'Classificazione',
        'broadcastLeaderbord.playerLeaderBoard.stats.player': 'Giocatore',
        'broadcastLeaderbord.playerLeaderBoard.stats.points': 'Punti',
        'leaderBoardTab.switch.tournamentLeaderBoard':
            'Classificazione del torneo',
        'leaderBoardTab.switch.groupLeaderBoard': 'Classificazione del gruppo',
        'leaderBoardTab.header': 'Tabella di classificazione',
        'leaderBoardTab.signInButton':
            'Hai già giocato? Premere qui per accedere',
        'leaderBoardTab.finalAfterGameDisclaimer':
            'Le tabelle di classificazione verranno aggiornate durante il gioco e saranno finite solo al termine del gioco',
        'prosLeaderboard.header': 'Come hanno fatto i professionisti',
        'leaderBoard.prizeDetails': 'Informazioni sul premio',
        'leaderBoard.prizeDetailsButton': 'Informazioni sul premio',
        'leaderBoard.correctPicks': 'Selezione corretta',
        'settings.titleBar': 'PROFILO',
        'settings.welcomeText': 'Ciao, {username}',
        'settings.joinedText': 'Ammesso {data}',
        'account.rewards.header': 'Premi',
        'account.stats.header': 'Statistiche',
        'account.stats.previous': 'Precedente',
        'account.stats.next': 'Prossimo',
        'settings.personalInfo.header': 'Informazione personale',
        'settings.personalInfo.description': 'E-mail, numero di telefono',
        'settings.personalInfo.email.label': 'E-mail',
        'settings.personalInfo.email.button': 'Salva',
        'settings.personalInfo.email.placeholder':
            'Il tuo indirizzo di posta elettronica',
        'settings.personalInfo.phoneNumber.label': 'Numero di telefono',
        'settings.personalInfo.deleteAccount.label':
            'Se vuoi cancellare il tuo account o esercitare i tuoi diritti sui tuoi dati personali, clicca qui e invia un messaggio al nostro supporto',
        'settings.marketing.header': 'Marketing',
        'settings.marketing.descriptionText':
            'Resta aggiornato con {PartnerName} Accettando per promozioni e marketing speciali.',
        'settings.smsAlertHeader': 'Avvisi di gioco',
        'settings.smsAlertLabel': 'Avvisi di gioco SMS',
        'settings.smsAlertText':
            'Ricevi SMS ogni volta che le previsioni vengono annunciate e non perdi un minuto del torneo',
        'settings.smsAlertUnstopText':
            'Per iscriverti ad avvisi SMS di nuovo, invia ”UNSTOP" a',
        'settings.smsAlertUnstopLink': 'Clicca qui per riattivare',
        'settings.subscribeButton.subscribe': 'Sottoscritto',
        'settings.subscribeButton.subscribed': 'Iscriverti',
        'groupSettings.groupLeaderbord': 'Classificazione del gruppo',
        'groupSettings.groupLeaderbordDescription':
            'Crea gruppi per competere in un gruppo privato con le tue classifiche di gruppo. La tua partecipazione di gruppo non influisce sui tuoi risultati nei concorsi per partita o nei tornei stagionali.',
        'groupSettings.definitionList.owner': 'Sei il proprietario di ...',
        'groupSettings.definitionList.isLoading': 'Caricamento del gruppo ....',
        'groupSettings.definitionList.member': 'Sei un membro di',
        'groupSettings.definitionList.inviteLink': 'Link di invito',
        'groupSettings.definitionList.deleteGroup': 'Elimina gruppo',
        'groupSettings.definitionList.leaveGroup': 'Esci dal gruppo',
        'groupSettings.createGroup.inputLabel': 'Nome del gruppo',
        'groupSettings.createGroup.inputPlaceholder': 'Un nome di gruppo',
        'groupSettings.createGroup.buttonText': 'Creare un gruppo',
        'groupSettings.createGroup.description':
            'Puoi anche unirti al gruppo esistente seguendo il link fornito da qualsiasi membro del gruppo.',
        'predictPage.userStats.titleOverride': 'Statistiche del mio gioco',
        'predictPage.userStats.callToActionButton.label':
            'Tabella di classificazione',
        'predictPage.userStats.howToPlayButton.label': 'Come giocare',
        'predictPage.predictionList.groupTitle': 'Previsioni in sospeso',
        'predictPage.predictionList.groupTitleTokenized': 'Previsioni',
        'predictPage.heroicPredictionNotice': 'Nuove previsioni',
        'predictPage.predictionList.lockLabel': 'Chiuso',
        'triviaGame.welcome.header': 'Benvenuto in {eventName}!',
        'triviaGame.welcome.description.pointsCountdown':
            'Il gioco consiste in **{numberOfQuestions}** domande trivia. Avrai **{secondsPerQuestion}** secondi per ogni domanda a cui rispondere. Più velocemente rispondi, più punti guadagnerai!',
        'triviaGame.button.start': 'Inizio',
        'triviaGame.button.next': 'Prossimo',
        'triviaGame.button.finish': 'Fine',
        'triviaGame.countdown.headline':
            'Domanda {questionNumber}/{numberOfQuestions}',
        'triviaGame.gameOver.header': 'Grazie per aver giocato!',
        'triviaGame.gameOver.description.pointsCountdown':
            'Hai risposto a **{questionsAnsweredCorrectly}** dalle **{numberOfQuestions}** domande correttamente. Più punti per risposte più rapide! La classifica e il tuo grado si aggiorneranno in pochi minuti.',
        'triviaGame.status.correct': 'Corretta',
        'triviaGame.status.incorrect': 'Sbagliata',
        'triviaGame.status.missed': 'Persa',
        'triviaGame.status.pointsCountdown':
            'Punti in diminuzione: {punti} su {totalPoints} rimasti',
        'triviaGame.ad.button.countdown': 'Procedi tra {countdownSeconds}',
        'userStats.rank': 'Classificazione',
        'userStats.points': 'Punti',
        'userStats.correct': 'Colpi',
        'toast.nextLocksIn': 'Previsioni chiuse successive: {TextAddon}',
        'toast.resultsSoon': 'Risultato finale a breve',
        'toast.gameOver': 'Fine del gioco',
        'survey.callToAction': 'Vogliamo sapere di te',
        'survey.subText': 'Raccontaci la tua esperienza',
        'survey.subTextLink': 'Rispondere a un sondaggio di 2 minuti',
        'howToPlayModal.tag': 'Gioca gratis!',
        'howToPlayModal.header': 'Come giocare',
        'howToPlayModal.button': 'Sono pronto, giochiamo!',
        'howToPlayModal.defaultBody':
            "Le previsioni vengono lanciate prima dell'inizio del gioco, alla fine di ogni periodo di gioco e durante gli spot pubblicitari. C'è un limite di tempo per rispondere prima della chiusura delle previsioni. Se non rispondi non punteggi.",
        'onboardingAgreement.header': 'Hai più di 18 anni?',
        'onboardingAgreement.text':
            'Hai più di 18 anni? Questa è la nostra età minima.',
        'onboardingAgreement.button.agree': 'Sì',
        'onboardingAgreement.button.disagree': 'No',
        'howToPlayTournamentModal.header': 'Come giocare',
        'howToPlayTournamentModal.button': 'Sono pronto, giochiamo',
        'cumulativeLeaderboardTab.button': 'Come giocare',
        'cumulativeLeaderboardTab.myStats': 'Statistiche del mio gioco',
        'cumulativeLeaderboardTab.gameLeaderboard': 'Classificazione del gioco',
        'cumulativeLeaderboardTab.groupLeaderboard':
            'Classificazione del gruppo',

        'group.isLoading': 'Gruppo di caricamento ...',

        'groupLeaderboardTab.headerText.error':
            'Si è verificato un errore durante il caricamento del gruppo',
        'groupLeaderboardTab.pointsDescription':
            "Utenti e punteggi verranno aggiornati durante e dopo l'evento",
        'groupLeaderboardTab.headerText.notInGroup':
            'Non sei ancora nel gruppo!',
        'groupLeaderboardTab.headerText.notInGroup.descriptionStart':
            'Crea un gruppo per competere con i tuoi amici',
        'groupLeaderboardTab.headerText.notInGroup.descriptionBottom':
            'Puoi anche inserire altri gruppi. Richiedi un link di invito ai tuoi amici e unisciti a loro per competere.',
        'groupLeaderboardTab.button': 'Come giocare',
        'groupLeaderboardTab.myStats': 'Le mie statistiche sul torneo',
        'groupLeaderboardTab.gameLeaderboard': 'Classificazione del gioco',
        'groupLeaderboardTab.tournamentLeaderboard':
            'Classificazione del torneo',
        'predictPage.smsOptInInfoModuleDetails.body':
            'Ricevi un avviso quando nuove scelte vengono pubblicate. Invieremo solo fino a 3 messaggi per gioco.',
        'predictPage.smsOptInInfoModuleDetails.button': 'Ricevi avvisi SMS',
        'predictPage.smsOptInInfoModuleDetails.preTitle': 'Ricevere notifiche',
        'predictPage.smsOptInInfoModuleDetails.title':
            'Riceverai più domande a breve',
        'predictPage.noSmsOptInInfoModuleDetails.preTitle':
            'Ricevere notifiche',
        'predictPage.noSmsOptInInfoModuleDetails.title':
            'Riceverai più domande a breve',
        'predictPage.deadStateAndAllAnsweredInfoModuleDetails.body':
            'Le domande verranno rilasciate durante il gioco e ti informeremo quando saranno pronte',
        'predictPage.deadStateAndAllAnsweredInfoModuleDetails.title':
            'Riceverai più domande a breve',
        'predictPage.deadStateAndAllReleasedInfoModuleDetails.body':
            'Segui i risultati in diretta per vedere come progredisci durante il gioco. Ogni punto conta!',
        'predictPage.deadStateAndAllReleasedInfoModuleDetails.preTitle':
            'Rimani connesso',
        'predictPage.deadStateAndAllReleasedInfoModuleDetails.title':
            'Le classificazioni sono aggiornate dal vivo',
        'predictPage.activePredictionsInfoModuleDetails.body':
            'I punti dipendono da come gli altri rispondono. Le risposte meno probabili offrono risultati migliori.',
        'predictPage.activePredictionsInfoModuleDetails.preTitle': 'ATTENZIONE',
        'predictPage.activePredictionsInfoModuleDetails.title':
            'Il punteggio cambia man mano che le ipotesi si verificano fino al tempo limite',
        'predictPage.smsOptInGameOverInfoModuleDetails.title': 'GIOCO FINITO',
        'predictPage.basicGameOverInfoModuleDetails.bodyWithPrizes':
            'I vincitori dei premi saranno contattati via e-mail. Stai attento per il prossimo gioco.',
        'predictPage.basicGameOverInfoModuleDetails.bodyWithoutPrizes':
            'Grazie per aver giocato. Controlla la tabella di classificazione per vedere come è andata.',
        'predictPage.basicGameOverInfoModuleDetails.preTitle':
            'Congratulazioni ai vincitori',
        'predictPage.basicGameOverInfoModuleDetails.title': 'GIOCO FINITO',
        'predictPage.readMoreButton.text': 'Leggi di più',
        'pistachioGame.stickyButton.text.single':
            '{count} non eseguita la previsione',
        'pistachioGame.stickyButton.text.many':
            '{count} non eseguita la previsione',
        'pistachioGame.stickyButton.gameDisclaimerFallback':
            'Si prega di consultare al',
        'pistachioGame.toast.correct.title': "L'hai fatto!",
        'pistachioGame.toast.correct.text': '+{points} Punti',
        'pistachioGame.toast.wrong.title': 'Non hai avuto successo',
        'pistachioGame.toast.wrong.text': 'Continua @{name}',
        'pistachioGame.toast.noCorrect.title': 'Peccato 0/{conta} giuste',
        'pistachioGame.toast.noCorrect.text': 'Continua @{name}',
        'pistachioGame.toast.someCorrect.title': "L'hai fatto!",
        'pistachioGame.toast.someCorrect.text': '+{points} Punti',
        'pistachioGame.pollToast.text': '+{points} Punti',
        'pistachioGame.pollToast.title':
            'I risultati del sondaggio sono pronti!',
        'heroicCountdown.headline': 'Previsioni chiuse',
        'informationModule.closeButton': 'NO GRAZIE!',
        'countdownHeader.label': 'Tempo limite per la risposta: {timeLeftText}',
        'waitingRoom.smallPrediction.ended': 'Hai perso questa!',
        'waitingRoom.smallPrediction.notEnded':
            'Previsioni pre-partita in diretta:',
        'waitingRoom.emailNotifyTitle.ended':
            'Ricevi notifiche per il prossimo gioco',
        'waitingRoom.emailNotifyTitle.notEnded': 'Ricevere notifiche',
        'waitingRoom.emailSubmitted.smallActionText': 'Sei nella lista',
        'waitingRoom.emailSubmitted.bigActionText': 'Boom!',
        'waitingRoom.detailsButton': 'Dettagli',
        'waitingRoom.submitButton': 'Inviare',
        'waitingRoom.continueSkipButton.continue': 'Continua',
        'waitingRoom.continueSkipButton.skip': 'Saltare',
        'waitingRoom.gameOver': 'Gioco finito',
        'waitingRoom.soon': 'Presto',
        'waitingRoom.tomorrowTime': 'Domani {time}',
        'newPredictionCard.status.missed': 'Perso',
        'newPredictionCard.status.anytime': 'In qualsiasi momento',
        'newPredictionCard.status.incorrect': 'Sbagliato',
        'newPredictionCard.status.participated': 'Ha partecipato',
        'newPredictionCard.status.correct': 'Giusto',
        'newPredictionCard.status.pending': 'In sospeso - {when}',
        'newPredictionCard.pollHeading': 'SONDAGGIO',
        'newPredictionCard.pollPoints': 'Punti',
        'newPredictionCard.sponsorshipTag': 'Indovina e vinci',
        'offerDetails.redeem.ButtonText': 'Recuperare il codice',
        'offerDetails.redeem.codeTitle': 'Il tuo codice:',
        'offerDetails.expirationText.expired': 'Scaduto {when}',
        'offerDetails.expirationText.expires': 'Scadere {when}',
        'offerDetails.redeem.howTo': 'Come rivendicare',
        'offerDetails.redeem.disclaimer': 'Testo di avviso legale',
        'leaderboardDeadstate.waitingSubHead':
            'I risultati saranno presentati a breve',
        'leaderboardDeadstate.waitingLabel':
            "Consultare di nuovo dopo l'inizio del partito",
        'leaderboardDeadstate.buttonText': 'Informazioni sul premio',
        'incognitoModal.tagLineText': 'Avvertimento',
        'incognitoModal.headerText': 'Modalità incognito',
        'incognitoModal.bodyText':
            'Giocare a questo gioco in modalità di navigazione in incognito potrebbe far sì che le tue previsioni non vengano salvate. Passa alla modalità predefinita per preservare le tue previsioni.',
        'incognitoModal.buttonText': 'Capisco',
        'offerWinModal.youWon.label': 'Hai vinto',
        'offerWinModal.rewardsButton.text': 'Vedi i premi',
        'newPredictionModal.label': 'Nuova previsione bloccata',
        'newPredictionModal.buttonText': 'Inserisci ora le tue previsioni',
        'timeUntilLock.timeUntilLockMode.short': '1 GIORNO',
        'timeUntilLock.timeUntilLockMode.medium': 'Domani {timestring}',
        'timeUntilLock.timeUntilLockMode.fallback': 'Domani {timestring}',
        'timeUntilLock.dateType.actualDate':
            'Le previsioni si chiudono in {DateString}',
        'timeUntilLock.dateType.daysUntil':
            'Le previsioni si chiudono in {DaySunTilstart} giorni',
        'dates.longWeekDay.sunday': 'Domenica',
        'dates.longWeekDay.monday': 'Lunedì',
        'dates.longWeekDay.tuesday': 'Martedì',
        'dates.longWeekDay.wednesday': 'Mercoledì',
        'dates.longWeekDay.thursday': 'Giovedì',
        'dates.longWeekDay.friday': 'Venerdì',
        'dates.longWeekDay.saturday': 'Sabato',
        'dates.shortWeekDay.sunday': 'Domenica',
        'dates.shortWeekDay.monday': 'Lun',
        'dates.shortWeekDay.tuesday': 'Mar',
        'dates.shortWeekDay.wednesday': 'Mer',
        'dates.shortWeekDay.thursday': 'Gio',
        'dates.shortWeekDay.friday': 'Ven',
        'dates.shortWeekDay.saturday': 'Sab',
        'countdownPageBreak.batchLabel': 'Chiude a',
        'powerUpAnimation.doublePointPowerup.label':
            '{name} vuole darti una mano nel gioco',
        'powerUpAnimation.fallback.label': '{name} vuole darti un vantaggio',
        'verifyCodeCountdown.button.label': 'Rispedisce il codice',
        'verifyCodeCountdown.underlineText': 'codice rispedito',
        'verifyCodeCountdown.underlineCountdown': 'Rispedizione tra ',
        'bonusSponsors.thankYouLabel': 'Bonus di ringraziamento dello sponsor',
        'playgroundGame.decoySpacer': 'Benvenuti nel campo di gioco 🤘',
        'prizeDetailsModal.button': 'Gioca adesso',
        'prizeDetailsModal.header': 'Premi',
        'broadcastSideboard.copyQuestionButton': 'COPIA',
        'broadcastSideboard.copyOptionButton': 'COPIA',
        'broadcastSideboard.percentSpan': 'Completa lo spazio vuoto',
        'broadcastSideboard.clipboardText': 'Copiato!',
        'broadcastView.playerCount': '{Count} giocatori',
        'broadcastView.emptyState': 'Nessuna previsione è stata fatta',
        'tvBroadcastView.rank': 'Classificazione',
        'tvBroadcastView.player': 'Giocatore',
        'tvBroadcastView.points': 'Punti',
        'tvBroadcastView.correct': '# Corretta',
        'groupPage.groupView.subHead.groups': 'Membri attuali del gruppo:',
        'groupPage.groupView.subHead.venue': 'Giocando nel tuo bar:',
        'groupPage.groupView.owner': 'Proprietario',
        'groupPage.error': 'Errore nella ricerca del gruppo',
        'groupPage.warning':
            "Puoi essere membro solo di un gruppo. Entrare in un altro gruppo ti fa uscire automaticamente dall'altro gruppo. Lasciare un gruppo di cui sei il creatore comporterà l'estinzione del gruppo",
        'groupPage.playButton': 'Inizia il gioco',
        'groupPage.joinButton': 'Unisciti al gruppo',
        'groupPage.joinedButton': 'Ti sei unito al gruppo!',
        'presentedBy.label': 'Presentato da',
        'createGroupForm.inputLabel': 'Nome del gruppo',
        'createGroupForm.buttonText': 'Creare un gruppo',
        'createGroupForm.cancel': 'Annulla',
        'createGroupForm.inputPlaceholder': 'Un nome di gruppo',
        'deleteGroupForm.ownerDefinition': 'Sei il proprietario di',
        'deleteGroupForm.memberDefinition': 'Sei un membro di',
        'deleteGroupForm.ownerButtonText': 'Elimina gruppo',
        'deleteGroupForm.memberButtonText': 'Esci dal gruppo',
        'copyToClipboardButton.tooltip.copied': 'Copiato',
        'socialTitle.label': 'Condividi il gioco',
        'offerCard.status.pending': 'Sbloccato dopo la partita',
        'offerCard.status.today': 'Oggi',
        'offerCard.status.tomorrow': 'Domani',
        'offerCard.status.inDays': '{in} giorni',
        'offerCard.status.expd': 'Scaduto: {when}',
        'offerCard.status.exps': 'Scadenza: {when}',
        'predictions.blank.label': 'Le mie previsioni',
        'predictions.blank.average': 'Media',
        'predictions.blank.result': 'RISULTATO',
        'predictions.blank.errorGreater': 'Il valore massimo è {value}',
        'predictions.blank.errorBelow': 'Il valore minimo è {value}',
        'prediction.option.odds.betToWin': 'Scommessa {bet} per vincere {win}',
        'prediction.option.odds.liveOddsBy':
            'Probabilità in diretta fornite da {sportsbook}',
        'prediction.option.odds.betToWinShort':
            'I tuoi punti {bet} potrebbero farti guadagnare {win} punti',
        'prediction.option.odds.betWonShort':
            'I tuoi punti {bet} hanno vinto {win} punti',
        'prediction.option.odds.oddClaim': 'Hai chiuso a',
        'prediction.option.odds.oddsAware':
            'La risposta alle previsioni di simulazione delle scommesse non possono essere modificate una volta effettuata la tua scelta',
        'prediction.option.odds.oddsType.MONEY_LINE': 'Linea di denaro',
        'prediction.option.odds.oddsType.SPREAD': 'Differenza',
        'prediction.option.odds.oddsType.OVER_UNDER': 'Sopra sotto',
        'prediction.option.draw': 'Estratto',
        'prediction.option.nobet': 'Senza scommesse',
        'storageConsentModal.description':
            'Questo sito Web memorizza dati come cookie per consentire funzioni significative del sito, come analisi, orientamento e personalizzazione. È possibile modificare le tue preferenze in qualsiasi momento o accettare la configurazione predefinita.',
        'storageConsentModal.storagePolicy.link.text':
            'Politica di archiviazione dei dati',
        'storageConsentModal.necessary.switch.label': 'Strettamente necessario',
        'storageConsentModal.analytics.switch.label': 'Analisi',
        'storageConsentModal.button.save': 'Salva',
        'geoFence.message.subHeadline':
            "Devi trovarti in un'area specifica per giocare",
        'geoFence.message.headline': 'Siamo spiacenti, non sei autorizzato',
    },
} as const;
