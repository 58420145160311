import React, { useState } from 'react';
import { useSetContactInfo, useUserState } from 'src/contexts/UserStateContext';
import FirebaseAuth from 'src/services/FirebaseAuth';
import ServerApi from 'src/services/ServerApi';
import { useAnalytics } from 'src/contexts/AnalyticsContext';
import { AppArea, EventName, Screen } from 'src/util/AnalyticsConstants';
import * as FirestoreDao from 'src/util/FirestoreDao';
import Logger from 'src/util/Logger';
import { ValidationError } from 'src/util/validation';
import { getMarketingCheckboxes } from './utils';
import MarketingOptInTile from './MarketingOptInTile';
import { TranslatedConfigPartnerData } from 'src/util/ConfigTypes';
import { useIntl } from 'react-intl';
import { NetworkError } from 'src/util/errors/NetworkError';
import ServerError from 'src/services/ServerError';

type Props = {
    partner: TranslatedConfigPartnerData;
    prizes?: string[];
    iterationId: string;
    onContactSupportClick: () => void;
    screenHasBg?: boolean | false;
};

const EmailTile = ({
    partner,
    prizes,
    iterationId,
    onContactSupportClick,
    screenHasBg,
}: Props) => {
    const intl = useIntl();
    const userData = useUserState();
    const analytics = useAnalytics();

    const [tileError, setTileError] = useState<Error | undefined>(undefined);
    const [fieldErrors, setFieldErrors] = useState<{
        [key: string]: Error;
    }>({});

    const {
        smsMarketingLabel,
        emailMarketingLabel,
        optionalOptInLabel,
        tallyOptInLabel,
        optInDefault,
        emailOptInIsMandatory,
        smsMarketingEnabled,
        smsOptInIsMandatory,
        optionalOptInEnabled,
        optionalOptInIsMandatory,
        tallyOptInIsActive,
    } = partner.properties;

    const setContactInfo = useSetContactInfo();

    const onSubmitEmail = async (data: {
        email?: string;
        emailCheckboxChecked?: boolean;
        smsCheckboxChecked?: boolean;
        optionalOptIn?: boolean;
        tallyOptIn?: boolean;
    }) => {
        const {
            email,
            emailCheckboxChecked,
            smsCheckboxChecked,
            optionalOptIn,
            tallyOptIn,
        } = data;

        try {
            await ServerApi.updateUser({
                email,
                lastIterationOptIn: iterationId,
                marketingOptIn:
                    emailCheckboxChecked === undefined
                        ? undefined
                        : Boolean(emailCheckboxChecked),
                smsMarketingOptIn:
                    smsCheckboxChecked === undefined
                        ? undefined
                        : Boolean(smsCheckboxChecked),
                optionalOptIn:
                    optionalOptIn === undefined
                        ? undefined
                        : Boolean(optionalOptIn),
                tallyOptIn:
                    tallyOptIn === undefined ? undefined : Boolean(tallyOptIn),
            });

            const uid = FirebaseAuth.getUser()?.uid;

            if (!uid) {
                throw new Error(
                    'User should have been already authenticated in here...',
                );
            }

            const contactInfo = await FirestoreDao.getContactInfo(uid);

            if (!contactInfo) {
                throw new Error('Error updating contact info in firestore');
            }

            setContactInfo(contactInfo);

            analytics.logEvent({
                eventName: EventName.signUpEmailComplete,
                params: {
                    marketing_opt_in: emailCheckboxChecked,
                    sms_marketing_opt_in: smsCheckboxChecked,
                },
                eventCategory: AppArea.signup,
            });

            if (emailCheckboxChecked || smsCheckboxChecked) {
                analytics.logEvent({
                    eventName: EventName.marketingOptIn,
                    params: {
                        emailOptedIn: emailCheckboxChecked,
                        screen: Screen.signupEmail,
                        smsOptedIn: smsCheckboxChecked,
                    },
                });
            }
        } catch (error) {
            if (!(error instanceof Error)) {
                Logger.error('Error submitting email.', error);
                setTileError(
                    new Error(intl.formatMessage({ id: 'error.generic' })),
                );
                return;
            }

            if (error instanceof NetworkError) {
                setTileError(
                    new Error(
                        intl.formatMessage({ id: 'error.failedToFetch' }),
                    ),
                );
                return;
            }
            if (error instanceof ServerError && error.field) {
                setFieldErrors({ ...fieldErrors, [error.field]: error });
                setTileError(
                    new Error(intl.formatMessage({ id: 'error.generic' })),
                );
                return;
            }
            setTileError(error);
        }
    };

    const handleErrorReset = (field: string, force?: boolean): void => {
        setFieldErrors((previousState) => {
            if (force) {
                return {};
            }
            const newState = { ...previousState };
            delete newState[field];
            return newState;
        });
        setTileError(undefined);
    };

    const handleValidationError = (error: ValidationError): void => {
        setFieldErrors((previousState) => ({
            ...previousState,
            [error.field]: error,
        }));
    };

    return (
        <MarketingOptInTile
            prizes={prizes}
            onClearError={handleErrorReset}
            onContactSupportClick={onContactSupportClick}
            onError={handleValidationError}
            onFieldSubmit={onSubmitEmail}
            errors={fieldErrors}
            checkboxes={getMarketingCheckboxes({
                userData,
                smsMarketingLabel,
                emailMarketingLabel,
                optionalOptInLabel,
                tallyOptInLabel,
                optInDefault,
                emailOptInIsMandatory,
                smsMarketingEnabled,
                smsOptInIsMandatory,
                optionalOptInEnabled,
                optionalOptInIsMandatory,
                tallyOptInIsActive,
                intl,
            })}
            tileError={tileError}
            screenHasBg={screenHasBg}
        />
    );
};

export default EmailTile;
