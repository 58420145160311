export default {
    fullLanguageName: 'Português',
    messages: {
        'fallback.empty.message': ' ',
        'menu.play': 'Jogar',
        'menu.playing': 'Jogando: {eventName}',
        'menu.gameLeaderboard': 'Classificação',
        'menu.groups.groups': 'Grupos',
        'menu.groups.venue': 'Fanzone',
        'menu.tournament': 'Torneio',
        'menu.account': 'Conta',
        'infoCollectionTitle.touchButtonLabel': 'Próximo',
        'onboarding.requestDisplayName.title': 'Escolha um nome',
        'onboarding.requestDisplayName.description':
            'Escolha o nome do usuário! Este nome irá aparecer na tabela de classificação então atenção na escolha',
        'onboarding.requestDisplayName.field.title': 'nome de usuário',
        'onboarding.requestDisplayName.field.placeholder': 'Placeholder',
        'onboarding.okta.title': 'Fazendo login com Okta',
        'onboarding.updates.title': 'Atualizações',
        'inputField.helpMe': 'Entre em contato',
        'inputField.helpQuestion':
            'Precisa de ajuda? Visite nossa página {helpPage} ou {contactPage}',
        'inputField.helpLinkTitle': 'Página de Ajuda',
        'inputField.contactLinkTitle': 'Fale Conosco',
        'inputField.error.3715': 'Número de telefone invalido',
        'inputField.error.7':
            'Falha de envio. Cerifique se que tenha inserido um celular valido',
        'inputField.error.1': 'Já houve várias tentativas. Tente mais tarde',
        'inputField.error.16': 'Código de verificação incorreto',
        'inputField.error.6': 'Código expirado. Favor tentar novamente.',
        'inputField.error.blank': 'erro',
        'inputField.error.verification': 'Erro de verificação de código',
        'inputField.error.failedToFetch':
            'Favor verificar conexão e tente novamente',
        'inputField.error.nameIsTaken': 'Este nome já está sendo usado',
        'inputField.error.offensiveLanguage': 'Linguagem inapropriada',
        'inputField.error.charLimit': 'Excedeu {limit} de caracteres',
        'inputField.error.validationError': 'String inválida',

        'onboarding.disclaimer':
            'Ao continuar você confirma ser maior de 18 anos e que concorda com nosso regulamento de uso',
        'onboarding.officialRules': 'Regras oficiais',
        'onboarding.termsAndConditions': 'Termos e Condições',
        'onboarding.privacyPolicy': 'Política de Privacidade',
        'onboarding.separator': 'e',
        'onboarding.cookies': 'Cookies',
        'onboarding.requestEmail.field.title': 'E-mail',
        'onboarding.requestEmail.bodyWithPrizes.markdown':
            'Para que possa receber seus prêmios, precisaremos do seu e-mail para entrar em contato',
        'onboarding.requestEmail.bodyWithoutPrizes.markdown':
            'Favor fornecer seu e-mail para futuras comunicações ',
        'onboarding.invalidEmail': 'Entre com um e-mail valido',
        'optIn.marketing.phone.label': 'Receber Textos',
        'optIn.marketing.email.label': 'Receber emails',
        'optIn.marketing.3p.label': 'Receber contato de nosso parceiro',
        'onboarding.requestPhone.title': 'Bem Vindo',
        'onboarding.requestPhone.title.gameIsLive': 'Bem Vindo',
        'onboarding.requestPhone.description.gameIsLive':
            'Deixe seu palpite de graça enquanto o jogo rola e concorra a prêmios exclusivos',
        'onboarding.requestPhone.title.gameIsFinal': 'Acesse',
        'onboarding.requestPhone.description.gameIsFinal':
            'O jogo já terminou, mas você ainda pode entrar e conferir seu resultado',
        'onboarding.requestPhone.field.title': 'Telefone',
        'onboarding.requestPhone.field.placeholder': 'xxx-xxx-xxxx',
        'onboarding.requestPhone.field.description':
            'Se ganhar algum prêmio entraremos em contato',
        'onboarding.verifyCode.title': 'Verificar código',
        'onboarding.verifyCode.description':
            'Por favor entre com seu codigo de verificação',
        'onboarding.verifyCode.resendCode': 'Reenviar código',
        'onboarding.newIteration.fallbackTitle': 'NOT NEEDED',
        'onboarding.newIteration.fallbackNewOptInBody': 'NOT NEEDED',
        'onboarding.newIteration.checkBox1FallbackLabel': 'NOT NEEDED',
        'gameFooter.contactSupport': 'Suporte',
        'gameFooter.poweredBy': 'Powered by',
        'broadcastLeaderbord.playerLeaderBoard.title': 'Líder do Ranking',
        'broadcastLeaderbord.playerLeaderBoard.stats.rank': 'Classificação',
        'broadcastLeaderbord.playerLeaderBoard.stats.player': 'Jogador',
        'broadcastLeaderbord.playerLeaderBoard.stats.points': 'Pontuação',
        'leaderBoardTab.switch.tournamentLeaderBoard':
            'Classificação do torneio',
        'leaderBoardTab.switch.groupLeaderBoard': 'Classificação do grupo',
        'leaderBoardTab.header': 'Classificação',
        'leaderBoardTab.signInButton': 'Ja jogou? Clique aqui',
        'leaderBoardTab.finalAfterGameDisclaimer':
            'A classificação muda durante o jogo mas somente e finalizada após a partida',
        'prosLeaderboard.header': 'Classificação dos profissionais',
        'leaderBoard.prizeDetails': 'Sobre os prêmios',
        'leaderBoard.prizeDetailsButton': 'Sobre os prêmios',
        'leaderBoard.correctPicks': 'Acertos',
        'settings.titleBar': 'Perfil',
        'settings.welcomeText': 'Olá {username}',
        'settings.joinedText': 'Entrou {date}',
        'account.rewards.header': 'Recompensas',
        'account.stats.header': 'Estatísticas',
        'account.stats.previous': 'Anterior',
        'account.stats.next': 'Seguinte',
        'settings.personalInfo.header': 'Informação pessoal',
        'settings.personalInfo.description':
            'Email, número de telefone, etc...',
        'settings.personalInfo.email.label': 'O email',
        'settings.personalInfo.email.button': 'Salvar',
        'settings.personalInfo.email.placeholder': 'Seu endereço de email',
        'settings.personalInfo.phoneNumber.label': 'Número de telefone',
        'settings.personalInfo.deleteAccount.label':
            'Caso queira apagar sua conta ou exercer seus direitos sobre os seus dados pessoais, clique aqui e mande uma mensagem para nosso suporte',

        'settings.marketing.header': 'Marketing',
        'settings.marketing.descriptionText':
            'Fique por dentro das novidades do  {partnerName}',
        'settings.smsAlertHeader': 'Notificações de Jogo',
        'settings.smsAlertLabel': 'Alerta de palpites',
        'settings.smsAlertText':
            'Receba alertas por SMS toda vez que um novo palpite estiver no ar. Nunca fique atrás do seus competidores.',
        'settings.smsAlertUnstopText': 'Para receber SM, envie Enviar para',
        'settings.smsAlertUnstopLink': 'Clique aqui para reativar',
        'settings.subscribeButton.subscribe': 'Cadastrado',
        'settings.subscribeButton.subscribed': 'Cadastro',
        'groupSettings.groupLeaderbord': 'Classificação do grupo',
        'groupSettings.groupLeaderbordDescription':
            'Crie grupos para competir entre membros',
        'groupSettings.definitionList.owner': 'Você é dono do',
        'groupSettings.definitionList.isLoading': 'Carregando o grupo',
        'groupSettings.definitionList.member': 'Você é membro do',
        'groupSettings.definitionList.inviteLink': 'Convite',
        'groupSettings.definitionList.deleteGroup': 'Apagar grupo',
        'groupSettings.definitionList.leaveGroup': 'Saia do grupo',
        'groupSettings.createGroup.inputLabel': 'Nome do grupo',
        'groupSettings.createGroup.inputPlaceholder': 'Nome de algum grupo',
        'groupSettings.createGroup.buttonText': 'Criar grupo',
        'groupSettings.createGroup.description':
            'Você pode se juntar a um grupo existente entrando no link enviado por qualquer membro do grupo.',
        'predictPage.userStats.titleOverride': 'estatísticas do jogo',
        'predictPage.userStats.callToActionButton.label': 'Classificação ',
        'predictPage.userStats.howToPlayButton.label': 'Como jogar',
        'predictPage.predictionList.groupTitle': 'PALPITES PENDENTES',
        'predictPage.predictionList.groupTitleTokenized': 'PALPITES',
        'predictPage.heroicPredictionNotice': 'Novos Palpites',
        'predictPage.predictionList.lockLabel': 'Travado',

        'triviaGame.welcome.header': 'Bem vindo a {eventName}!',
        'triviaGame.welcome.description.pointsCountdown': `O jogo consiste em **{numberOfQuestions}** perguntas de Trivia. Você terá **{secondsPerQuestion}** segundos para responder cada pergunta. Quanto mais rápido você responder, mais pontos marca.  \nBoa Sorte!`,
        'triviaGame.button.start': 'Começar',
        'triviaGame.button.next': 'Seguinte',
        'triviaGame.button.finish': 'Terminar',
        'triviaGame.countdown.headline':
            'Question {questionNumber}/{numberOfQuestions}',
        'triviaGame.gameOver.header': 'Obrigado por jogar!',
        'triviaGame.gameOver.description.pointsCountdown': `Você respondeu corretamente a **{questionsAnsweredCorrectly}** de cada **{numberOfQuestions}** perguntas. Mais pontos para respostas mais rápidas! A tabela de classificação e seu ranking serão atualizados em alguns minutos.`,
        'triviaGame.status.correct': 'Acertos',
        'triviaGame.status.incorrect': 'Incorreto',
        'triviaGame.status.missed': 'Errou',
        'triviaGame.status.pointsCountdown':
            'Pontos diminuindo: {points} de {totalPoints} restantes',
        'triviaGame.ad.button.countdown': 'Prossiga em {countdownSeconds}',

        'userStats.rank': 'Classificação ',
        'userStats.points': 'Pontuação',
        'userStats.correct': 'Acertos',
        'toast.nextLocksIn': 'Novo palpite travado: {textAddon}',
        'toast.resultsSoon': 'Resultados divulgados em breve',
        'toast.gameOver': 'Final do jogo',
        'survey.callToAction': 'Queremos te escutar',
        'survey.subText': 'Me conte o que achou da experiência',
        'survey.subTextLink': 'Participe de nossa pesquisa: leva 2 minutos',
        'howToPlayModal.tag': 'Jogue sem pagar nada',
        'howToPlayModal.header': 'Como jogar',
        'howToPlayModal.button': 'Está Pronto, vamos começar',
        'howToPlayModal.defaultBody':
            'Palpites serão lançados antes do início do jogo, no final de todos os períodos e durante os intervalos comerciais. Você terá um tempo limite para fazer seus palpites',
        'howToPlayTournamentModal.header': 'Como jogar',
        'howToPlayTournamentModal.button': 'Estou pronto, vamos!',
        'cumulativeLeaderboardTab.button': 'Como jogar',
        'cumulativeLeaderboardTab.myStats': 'Meus pontos na competição',
        'cumulativeLeaderboardTab.gameLeaderboard': 'Classificação do jogo',
        'cumulativeLeaderboardTab.groupLeaderboard': 'Classificação de Grupos',

        'group.isLoading': 'Carregando grupo',
        'group.shareQr.button': 'Mostrar QR Code',
        'group.shareQr.description':
            'Mostre este QR code para seus amigos para que eles entrem no grupo de imediato.',
        'group.shareLink.button': 'Copie o link do seu convite',
        'group.shareLink.description.groups':
            'Copie o link e envie para seus amigos via e-mail, SMS ou media social',

        'groupLeaderboardTab.headerText.error': 'Erro ao carregar o grupo',
        'groupLeaderboardTab.pointsDescription':
            'Usuários e pontuação no ranking de cada grupo serão  atualizados durante e depois do jogo',
        'groupLeaderboardTab.headerText.notInGroup':
            'Você ainda não está no grupo',
        'groupLeaderboardTab.headerText.notInGroup.descriptionStart':
            'Crie um grupo para competir com seus amigos',
        'groupLeaderboardTab.headerText.notInGroup.descriptionBottom':
            'Você também pode entrar em um grupo existente. Peça seus amigos para enviar um convite e se junte a diversão.',
        'groupLeaderboardTab.button': 'Como jogar',
        'groupLeaderboardTab.myStats': 'Meus pontos na competição',
        'groupLeaderboardTab.gameLeaderboard': 'Classificação do jogo',
        'groupLeaderboardTab.tournamentLeaderboard':
            'Classificação da competição',
        'predictPage.smsOptInInfoModuleDetails.body':
            'Seja avisado quando novas perguntas estiverem no ar. Enviamos apenas 3 mensagens por jogo',
        'predictPage.smsOptInInfoModuleDetails.button':
            'RECEBER AVISOS POR SMS',
        'predictPage.smsOptInInfoModuleDetails.preTitle': 'RECEBER AVISOS',
        'predictPage.smsOptInInfoModuleDetails.title':
            'MAIS PERGUNTAS EM BREVE',
        'predictPage.noSmsOptInInfoModuleDetails.body':
            'Receba alerta quando novos palpites forem lançados. Enviaremos somente no máximo 3 mensagens por jogo  ',
        'predictPage.noSmsOptInInfoModuleDetails.preTitle':
            'Receba notificações',
        'predictPage.noSmsOptInInfoModuleDetails.title':
            'Novos palpitem em breve',
        'predictPage.deadStateAndAllAnsweredInfoModuleDetails.body':
            'Soltaremos perguntas durante o jogo. Avisaremos quando mais perguntas forem ao ar.',
        'predictPage.deadStateAndAllAnsweredInfoModuleDetails.title':
            'Mais perguntas em breve',
        'predictPage.deadStateAndAllReleasedInfoModuleDetails.body':
            'Acompanhe os resultados ao vivo e veja seu desempenho a medida que a bola rola. Todo ponto vale!',
        'predictPage.deadStateAndAllReleasedInfoModuleDetails.preTitle':
            'Fique ligado',
        'predictPage.deadStateAndAllReleasedInfoModuleDetails.title':
            'Classificação atualizada em tempo real',
        'predictPage.activePredictionsInfoModuleDetails.body':
            'A pontuaçao depende de como todos responderem. As vezes as respostas menos prováveis oferecem melhores resultados',
        'predictPage.activePredictionsInfoModuleDetails.preTitle': 'ATENÇÃO',
        'predictPage.activePredictionsInfoModuleDetails.title':
            'A Pontuação muda a medida que os palpites vão acontecendo até o horário limite',
        'predictPage.smsOptInGameOverInfoModuleDetails.body':
            'receba alertas quando um novo jogo estiver no ar, dessa forma você nunca perde uma chance',
        'predictPage.smsOptInGameOverInfoModuleDetails.button':
            'Receber Alertas via SMS',
        'predictPage.smsOptInGameOverInfoModuleDetails.preTitle':
            'Receber Alertas via SMS',
        'predictPage.smsOptInGameOverInfoModuleDetails.title': 'FIM DE JOGO',
        'predictPage.basicGameOverInfoModuleDetails.bodyWithPrizes':
            'Ganhadores receberão detalhes da premiação por e-mail. Fiquem atentos para participar dos próximos jogos.',
        'predictPage.basicGameOverInfoModuleDetails.bodyWithoutPrizes':
            'Ganhadores receberão detalhes da premiação por e-mail. Fiquem atentos para participar dos próximos jogos.',
        'predictPage.basicGameOverInfoModuleDetails.preTitle':
            'PARABENS AOS VENCEDORES',
        'predictPage.basicGameOverInfoModuleDetails.title': 'FINAL DE JOGO',
        'predictPage.readMoreButton.text': 'Leia mais',
        'pistachioGame.stickyButton.text.single': '{count} não respondeu',
        'pistachioGame.stickyButton.text.many': '{count} não respondeu',
        'pistachioGame.stickyButton.gameDisclaimerFallback':
            'Por favor se atentar ao',
        'pistachioGame.toast.correct.title': 'Acertou em cheio!',
        'pistachioGame.toast.correct.text': '+ {points} Pontuação',
        'pistachioGame.toast.wrong.title': 'Você não acertou',
        'pistachioGame.toast.wrong.text': 'Siga jogando @ {name}',
        'pistachioGame.toast.noCorrect.title':
            'Que pena! 0 ({count}) perguntas corretas',
        'pistachioGame.toast.noCorrect.text': 'Siga jogando @ {name}',
        'pistachioGame.toast.someCorrect.title': 'Acertou em cheio!',
        'pistachioGame.toast.someCorrect.text': '+{points} pontos',
        'pistachioGame.pollToast.text': '+{points} pontos',
        'pistachioGame.pollToast.title': 'Resultado das pesquisas finalizados!',
        'heroicCountdown.headline': 'Os palpites travam',
        'informationModule.closeButton': 'NÃO OBRIGADO',
        'countdownHeader.label': 'Tempo limite para resposta: {timeLeftText}',
        'waitingRoom.smallPrediction.ended': 'Você errou essa',
        'waitingRoom.smallPrediction.notEnded': 'Palpites pré-jogo no ar',
        'waitingRoom.emailNotifyTitle.ended':
            'Receber aviso para o próximo jogo',
        'waitingRoom.emailNotifyTitle.notEnded': 'Receber aviso',
        'waitingRoom.emailSubmitted.smallActionText': 'Você esta cadastrado',
        'waitingRoom.emailSubmitted.bigActionText': 'BUUUMMM',
        'waitingRoom.detailsButton': 'Detalhes',
        'waitingRoom.submitButton': 'Enviar',
        'waitingRoom.continueSkipButton.continue': 'Continuar',
        'waitingRoom.continueSkipButton.skip': 'Pular',
        'waitingRoom.gameOver': 'Fim do Jogo',
        'waitingRoom.soon': 'Chegando em breve',
        'waitingRoom.tomorrowTime': 'Amanha {time}',
        'newPredictionCard.status.missed': 'Errou',
        'newPredictionCard.status.anytime': 'A qualquer hora',
        'newPredictionCard.status.incorrect': 'Incorreto',
        'newPredictionCard.status.participated': 'Participou',
        'newPredictionCard.status.correct': 'Correto',
        'newPredictionCard.status.pending': 'Aguardando Resposta - {when}',
        'newPredictionCard.pollHeading': 'Pesquisa',
        'newPredictionCard.pollPoints': 'Pontos',
        'newPredictionCard.sponsorshipTag': 'palpite certo e ganhe',
        'offerDetails.redeem.ButtonText': 'Recuperar Código',
        'offerDetails.redeem.codeTitle': 'Seu Código',
        'offerDetails.expirationText.expired': 'Expirado {when}',
        'offerDetails.expirationText.expires': 'Expira {when}',
        'offerDetails.redeem.howTo': 'Como retirar',
        'offerDetails.redeem.disclaimer': 'Texto de aviso legal',
        'leaderboardDeadstate.waitingSubHead': 'Resultados em breve',
        'leaderboardDeadstate.waitingLabel':
            'Volte aqui depois que o jogo começar',
        'leaderboardDeadstate.buttonText': 'Descrição do prêmio',
        'incognitoModal.tagLineText': 'Aviso',
        'incognitoModal.headerText': 'Modo anônimo',
        'incognitoModal.bodyText':
            'Jogar esse jogo no modo anônimo pode fazer com que seus palpites não sejam salvos. Mude para o modo padrão para preservar seus palpites.',
        'incognitoModal.buttonText': 'Eu compreendo',
        'offerWinModal.youWon.label': 'Você ganhou',
        'offerWinModal.rewardsButton.text': 'Veja prêmios',
        'newPredictionModal.label': 'Novo palpite travado',
        'newPredictionModal.buttonText': 'Jogue agora',
        'timeUntilLock.timeUntilLockMode.short': '1 Dia',
        'timeUntilLock.timeUntilLockMode.medium': 'TMRW {timeString}',
        'timeUntilLock.timeUntilLockMode.fallback': 'Amanha {timeString}',
        'timeUntilLock.dateType.actualDate':
            'Palpites travam no dia {dateString}',
        'timeUntilLock.dateType.daysUntil':
            'Palpties travam em {daysString} dias',
        'timeUntilLock.dateType.tomorrow':
            'Palpites travam amanha as {timeString}',
        'timeUntilLock.dateType.time': 'Palpites travam as {timeString}',
        'dates.longWeekDay.sunday': 'Domingo',
        'dates.longWeekDay.monday': 'Segunda ',
        'dates.longWeekDay.tuesday': 'Terça ',
        'dates.longWeekDay.wednesday': 'Quarta ',
        'dates.longWeekDay.thursday': 'Quinta',
        'dates.longWeekDay.friday': 'Sexta',
        'dates.longWeekDay.saturday': 'Sabado',
        'dates.shortWeekDay.sunday': 'Dom.',
        'dates.shortWeekDay.monday': 'Seg.',
        'dates.shortWeekDay.tuesday': 'Ter.',
        'dates.shortWeekDay.wednesday': 'Qua.',
        'dates.shortWeekDay.thursday': 'Qui.',
        'dates.shortWeekDay.friday': 'Sex.',
        'dates.shortWeekDay.saturday': 'Sab.',
        'countdownPageBreak.batchLabel': 'Travam em',
        'powerUpAnimation.doublePointPowerup.label':
            '{name} quer te dar uma força no jogo',
        'powerUpAnimation.fallback.label': '{name} quer te dar uma vantagem',
        'verifyCodeCountdown.button.label': 'Reenvie Código',
        'verifyCodeCountdown.underlineText': 'código reenviado',
        'verifyCodeCountdown.underlineCountdown': 'Reenvio em',
        'bonusSponsors.thankYouLabel':
            'Bonus de agradecimento dos patrocinadores',
        'playgroundGame.decoySpacer': 'Bem vindo a diversão',
        'prizeDetailsModal.button': 'Jogue agora',
        'prizeDetailsModal.header': 'Prêmios',
        'broadcastSideboard.copyQuestionButton': 'Copiar',
        'broadcastSideboard.copyOptionButton': 'Copiar',
        'broadcastSideboard.percentSpan': 'Preencha o espaço em branco',
        'broadcastSideboard.clipboardText': 'Copiado!',
        'broadcastView.playerCount': '{count} jogadores',
        'broadcastView.emptyState':
            'Nenhum palpite foi respondido ate o momento',
        'tvBroadcastView.rank': 'Classificação',
        'tvBroadcastView.player': 'Jogador',
        'tvBroadcastView.points': 'Pontos',
        'tvBroadcastView.correct': '# Correto',
        'groupPage.groupView.subHead': 'Membros do grupo',
        'groupPage.groupView.owner': 'Criador',
        'groupPage.error': 'Erro ao buscar grupo',
        'groupPage.warning':
            'Você só pode ser membro de um grupo. Se juntar a outro grupo faz com que automaticamente deixe o outro grupo. Deixar um grupo em que você e o criador, resultara na extinção do grupo',
        'groupPage.playButton': 'Começe o jogo',
        'groupPage.joinButton': 'Se junte ao grupo',
        'groupPage.joinedButton': 'Juntou-se ao grupo',
        'presentedBy.label': 'Apresentado por',
        'createGroupForm.inputLabel': 'Nome do grupo',
        'createGroupForm.buttonText': 'Crie um grupo',
        'createGroupForm.cancel': 'Cancele',
        'createGroupForm.inputPlaceholder': 'nome de algum grupo',
        'deleteGroupForm.ownerDefinition': 'Você e o criador do',
        'deleteGroupForm.memberDefinition': 'Você e membro do',
        'deleteGroupForm.ownerButtonText': 'Apague o grupo',
        'deleteGroupForm.memberButtonText': 'Deixe o grupo',
        'copyToClipboardButton.tooltip.copied': 'Copiado!',
        'socialTitle.label': 'Compartilhe o jogo',
        'offerCard.status.pending': 'Desbloqueado depois do jogo',
        'offerCard.status.today': 'Hoje',
        'offerCard.status.tomorrow': 'Amanha',
        'offerCard.status.inDays': '{in} dias',
        'offerCard.status.expd': 'Expirado {when}',
        'offerCard.status.exps': 'Expira {when}',
        'predictions.blank.label': 'Meus palpites',
        'predictions.blank.average': 'Média',
        'predictions.blank.result': 'Resultado',
        'predictions.blank.errorGreater': '',
        'predictions.blank.errorBelow': '',
        'prediction.option.odds.betToWin': '',
        'prediction.option.odds.liveOddsBy': '',
        'prediction.option.odds.oddsType.MONEY_LINE': '',
        'prediction.option.odds.oddsType.SPREAD': '',
        'prediction.option.odds.oddsType.OVER_UNDER': '',
        'prediction.option.draw': '',
        'prediction.option.nobet': '',
        'storageConsentModal.description':
            'Este site armazena dados tais como cookies para permitir importantes funcionalidades do site, incluindo análise, direcionamento e personalização. Você pode alterar suas preferências a qualquer momento ou aceitar as configurações padrão.',
        'storageConsentModal.storagePolicy.link.text':
            'Politica de armazenamento de dados',
        'storageConsentModal.necessary.switch.label': 'Estritamente necessàrio',
        'storageConsentModal.analytics.switch.label': 'Analítico',
        'storageConsentModal.button.save': 'Salvar',
        'error.displayName.isTaken': 'O nome de usuário já existe.',
        'displayName.error':
            'O nome deve estar entre {min} e {max} caracteres, pode conter letras, números, hífens ou sublinhados com pelo menos uma letra',
        'optIn.marketing.tally.label':
            'Gostaria de receber futuras comunicações da Tally via e-mail e concordo com a política de privacidade abaixo',
        'error.generic': 'Ocorreu algum erro',
    },
} as const;
