import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import SquareLoading from 'src/components/shared/animations/SquareLoading';
import {
    EventModel,
    LeaderboardEntry,
    TriviaGameProperties,
} from 'src/util/TallyFirestore';
import theme from 'src/util/Theme';
import { useAnalytics } from 'src/contexts/AnalyticsContext';
import { AppArea, EventName, Screen } from 'src/util/AnalyticsConstants';
import GameOver from './GameOver';
import TriviaAdTile from './TriviaInterstitialAd';
import useTriviaGame from './useTriviaGame';
import {
    ButtonContainer,
    LoadingContainer,
    LoadingIndicatorContainer,
} from './shared';
import Welcome from './Welcome';
import TriviaGameQuestion from './TriviaGameQuestion';
import { BackgroundPanel } from 'src/components/shared/ui/BackgroundPanel';
import { Color } from 'src/styles/Constants';

type Props = {
    event: EventModel;
    trivia: TriviaGameProperties;
    leaderboardEntry?: LeaderboardEntry;
};

const TriviaGame = ({ event, trivia, leaderboardEntry }: Props) => {
    const intl = useIntl();
    const analytics = useAnalytics();
    const {
        state: gameState,
        start,
        answer,
        next,
    } = useTriviaGame(event.id, trivia);

    useEffect(() => {
        analytics.logScreenView({
            screenName: `${event.shortName} - ${Screen.eventTrivia}`,
            screenType: Screen.eventTrivia,
            appArea: AppArea.game,
        });
    }, [analytics]);

    const hasBg =
        event?.ui?.textBlockBackgroundOpacity !== undefined &&
        event?.ui?.textBlockBackgroundOpacity > 0;
    const textBgOpacity = event?.ui?.textBlockBackgroundOpacity || 100;

    // TODO: should pass opacity to every component - right now we only pass it to TriviaGameQuestions
    switch (gameState.id) {
        case 'initialLoading':
            return (
                <LoadingContainer>
                    <LoadingIndicatorContainer>
                        <SquareLoading
                            color={theme.themeHighlightFallbackColor}
                        />
                    </LoadingIndicatorContainer>
                </LoadingContainer>
            );
        case 'welcome':
        case 'assignLoading':
        case 'assignLoadingError':
            return (
                <WelcomeContainer>
                    {gameState.id === 'assignLoadingError' && (
                        <span style={{ color: 'red' }}>
                            {gameState.errorMessage}
                        </span>
                    )}
                    <Welcome
                        trivia={trivia}
                        event={event}
                        screenHasBg={hasBg}
                    />
                    <BackgroundPanel screenHasBg={hasBg} topConnect={true}>
                        <ButtonContainer>
                            <Button
                                disabled={gameState.id === 'assignLoading'}
                                onClick={() => {
                                    start();
                                    analytics.logEvent({
                                        eventName: EventName.triviaStarted,
                                    });
                                }}
                                style={{
                                    backgroundColor: Color.G1_BLACK,
                                    color: Color.G11_WHITE,
                                    fontSize: '125%',
                                }}
                            >
                                {intl.formatMessage({
                                    id: 'triviaGame.button.start',
                                })}
                            </Button>
                        </ButtonContainer>
                    </BackgroundPanel>
                    <LoadingIndicatorContainer>
                        {gameState.id === 'assignLoading' && (
                            <SquareLoading
                                color={theme.themeHighlightFallbackColor}
                            />
                        )}
                    </LoadingIndicatorContainer>
                </WelcomeContainer>
            );
        case 'initialLoadingError':
        case 'answerLoadingError':
        case 'assignLoadingError':
            return (
                <span style={{ color: 'red' }}>{gameState.errorMessage}</span>
            );
        case 'nextLoading': {
            if (gameState.ad) {
                return (
                    <TriviaAdTile
                        eventId={event.id}
                        eventUI={event.ui}
                        ad={gameState.ad}
                        buttonText={
                            gameState.past.length ===
                            gameState.numberOfQuestions
                                ? intl.formatMessage({
                                      id: 'triviaGame.button.finish',
                                  })
                                : intl.formatMessage({
                                      id: 'triviaGame.button.next',
                                  })
                        }
                    />
                );
            }
            return (
                <TriviaGameQuestion
                    gameState={gameState}
                    trivia={trivia}
                    answer={answer}
                    next={next}
                    event={event}
                    screenHasBg={hasBg}
                    txtBgOpacity={textBgOpacity}
                />
            );
        }
        case 'current':
        case 'past':
        case 'nextLoadingError':
        case 'answerSuccess':
        case 'answerLoading': {
            return (
                <TriviaGameQuestion
                    gameState={gameState}
                    trivia={trivia}
                    answer={answer}
                    next={next}
                    event={event}
                    screenHasBg={hasBg}
                    txtBgOpacity={textBgOpacity}
                />
            );
        }
        case 'postAnswerAd': {
            return (
                <TriviaAdTile
                    eventId={event.id}
                    eventUI={event.ui}
                    ad={gameState.ad}
                    onNextQuestion={next}
                    buttonText={
                        gameState.past.length === gameState.numberOfQuestions
                            ? intl.formatMessage({
                                  id: 'triviaGame.button.finish',
                              })
                            : intl.formatMessage({
                                  id: 'triviaGame.button.next',
                              })
                    }
                />
            );
        }
        case 'gameOver': {
            return (
                <GameOverContainer>
                    <GameOver
                        event={event}
                        trivia={trivia}
                        leaderboardEntry={leaderboardEntry}
                        questions={gameState.past}
                        screenHasBg={hasBg}
                    />
                </GameOverContainer>
            );
        }
    }
};

const WelcomeContainer = styled.div`
    padding: 20px 20px;
`;

const GameOverContainer = WelcomeContainer;

export default TriviaGame;
