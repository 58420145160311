import styled from '@emotion/styled';
import { TextAlignProperty } from 'csstype';
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

import { Color, Height } from 'src/styles/Constants';
import { keyframes } from 'emotion';
import Theme from 'src/util/Theme';
import {
    TranslatableEventModel,
    EventState,
    PredictionModel,
} from 'src/util/TallyFirestore';
import CountdownHeader from 'src/components/pistachioGame/CountdownHeader';

import closeDark from 'src/images/icons/Close-Black@3x.svg';
import closeLight from 'src/images/icons/Close-Gray@3x.svg';
import { useLocation } from 'react-router-dom';
import routeUrls from 'src/routes';

export type LockInfo =
    | 'lockDescription'
    | { timeLeftCount: number; lockDate: Date };

export type NextPredictionToLockInfo = {
    hasAnswered: boolean;
    lockInfo: LockInfo;
};

type Props = {
    event?: TranslatableEventModel;
    isPistachio?: boolean;
    nextPredictionToLockInfo?: NextPredictionToLockInfo;
    nextPredictionToLock?: PredictionModel;
    onLockTimeClick?: () => void;
};

export enum ToastTextColor {
    BLACK = 'BLACK',
    WHITE = 'WHITE',
}

export interface ToastDetails {
    backgroundColor: string;
    backgroundImage?: string;
    color: ToastTextColor;
    title?: string;
    text?: string;
    onClick?: () => void;
    disableClose?: boolean;
}

export interface ShowableToast {
    show: (details: ToastDetails) => void;
}

const Toast: React.RefForwardingComponent<ShowableToast, Props> = (
    props,
    forwardedRef,
) => {
    const intl = useIntl();
    const location = useLocation();

    const [isAtTopOfScroll, setIsAtTopOfScroll] = useState(true);
    const [currentToast, setCurrentToast] = useState<ToastDetails | undefined>(
        undefined,
    );

    useEffect(() => {
        const onScroll = () => {
            setIsAtTopOfScroll(window.scrollY <= 100);
        };
        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [setIsAtTopOfScroll]);

    useImperativeHandle(
        forwardedRef,
        () => ({
            show: (details: ToastDetails) => {
                setCurrentToast(details);
            },
        }),
        [setCurrentToast],
    );

    const onClick = () => {
        if (currentToast && currentToast.onClick) {
            setCurrentToast(undefined);
            currentToast.onClick();
        }
    };

    const onClose = () => {
        setCurrentToast(undefined);
    };

    const {
        event,
        nextPredictionToLockInfo,
        nextPredictionToLock,
        onLockTimeClick,
    } = props;
    if (!event) {
        return null;
    }
    const { state: eventState } = event;

    const hasActiveUnansweredPrediction =
        nextPredictionToLockInfo && !nextPredictionToLockInfo.hasAnswered;

    let onboardingToastBackgroundColor: undefined | string;
    let onboardingToastTextColor: undefined | ToastTextColor;

    const suppressNextPredictionToast =
        location.pathname === routeUrls.play && isAtTopOfScroll;

    // There is a currently requested toast. Show it, unless the user has active predictions they haven't answered yet.
    if (
        currentToast &&
        (!hasActiveUnansweredPrediction || suppressNextPredictionToast)
    ) {
        return (
            <ToastUI
                details={currentToast}
                onClick={onClick}
                onClose={onClose}
            />
        );
    }

    // Show the time-to-lock contextual bar unless we're on the predictions tab and scrolled to the top.
    if (
        nextPredictionToLockInfo &&
        nextPredictionToLock &&
        !suppressNextPredictionToast
    ) {
        return (
            <CountdownHeader
                onClick={onLockTimeClick}
                visible={true}
                nextPredictionToLockInfo={nextPredictionToLockInfo}
                nextPredictionToLock={nextPredictionToLock}
            />
        );
    }

    if (eventState === EventState.pending) {
        return (
            <ToastUI
                details={{
                    backgroundColor:
                        onboardingToastBackgroundColor ||
                        Color.T35_ALMOST_BLACK,
                    color: onboardingToastTextColor || ToastTextColor.WHITE,
                    disableClose: true,
                    text: intl.formatMessage({ id: 'toast.resultsSoon' }),
                }}
            />
        );
    }

    if (eventState === EventState.final) {
        return (
            <ToastUI
                details={{
                    backgroundColor:
                        onboardingToastBackgroundColor || Color.S21_MISTY_BLUE,
                    color: onboardingToastTextColor || ToastTextColor.BLACK,
                    disableClose: true,
                    text: intl.formatMessage({ id: 'toast.gameOver' }),
                }}
            />
        );
    }

    return null;
};

export default forwardRef(Toast);

const fadein = keyframes`
    from { opacity: 0; }
    to   { opacity: 1; }
`;

const Container = styled.div<{
    backgroundColor: string;
    backgroundImage?: string;
    color: string;
    showClickFeedback: boolean;
}>`
    align-items: center;
    background-color: ${(props) => props.backgroundColor};
    background-image: ${(props) =>
        props.backgroundImage ? `url(${props.backgroundImage})` : 'none'};
    background-position: right center;
    background-repeat: no-repeat;
    background-size: contain;
    color: ${(props) => props.color};
    cursor: ${(props) => (props.showClickFeedback ? 'pointer' : 'initial')};
    display: flex;
    flex-direction: row;
    min-height: ${Height.COUNTDOWN_HEADER}px;
    padding: 0px 20px;
    animation: ${fadein} 300ms;
    transition: background-color 0.3s;
`;

const Content = styled.div<{ textAlign?: TextAlignProperty }>`
    flex: 1;
    padding: 6px 0;
    text-align: ${(props) => props.textAlign || 'left'};
    overflow: hidden;
`;

const Title = styled.div`
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 16px;
    text-transform: uppercase;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const Text = styled.p`
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1px;
    font-weight: 500;
    text-transform: uppercase;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const CloseButton = styled.img`
    border: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-color: transparent;
    margin: 0 20px 0 0;
`;

export function ToastUI(props: {
    details: ToastDetails;
    onClick?: () => void;
    onClose?: () => void;
}) {
    const { details, onClick, onClose } = props;
    const {
        backgroundImage,
        backgroundColor,
        color,
        text,
        title,
        disableClose,
    } = details;

    const textColor =
        color === ToastTextColor.BLACK ? Color.T31_BLACK : Color.P1_WHITE;

    const icon = color === ToastTextColor.BLACK ? closeDark : closeLight;

    // TODO: Wire up white vs black close button

    return (
        <Container
            backgroundColor={backgroundColor}
            backgroundImage={backgroundImage}
            color={textColor}
            showClickFeedback={!!onClick}
        >
            {!disableClose && onClose && (
                <CloseButton onClick={onClose} src={icon} />
            )}
            <Content
                onClick={onClick}
                textAlign={onClose && !disableClose ? 'left' : 'center'}
            >
                {title && <Title>{title}</Title>}
                {text && <Text>{text}</Text>}
            </Content>
        </Container>
    );
}
