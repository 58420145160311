export default {
    fullLanguageName: 'English',
    messages: {
        'global.pointsValue': '{points} points',
        'menu.play': 'Play',
        'menu.playing': 'Playing: {eventName}',
        'menu.gameLeaderboard': 'Leaderboard',
        'menu.groups.groups': 'Groups',
        'menu.groups.venue': 'Fanzone',
        'menu.tournament': 'Tournament',
        'menu.account': 'Account',

        'infoCollectionTitle.touchButtonLabel': 'NEXT',

        'infoCollectionTitle.noTextSearchResults':
            'Nothing found. Try changing search text.',
        'infoCollectionTitle.testSearchplaceholder': 'Start typing',

        'infoCollectionTitle.groupModeDefaultDescription':
            'For a chance to win a $50 Amazon Gift Card (one per bar, gift card will be sent to the winner via email) please chose your bar below.',
        'infoCollectionTitle.groupModeTileTitle': 'Select your venue',
        'infoCollectionTitle.groupAndTeamsModeTileTitle':
            'Pick your team to represent',
        'infoCollectionTitle.geoListTitle':
            'List of venues closest to your current location.',
        'infoCollectionTitle.touchButtonSearchLabel': 'SEARCH BY NAME INSTEAD',
        'infoCollectionTitle.searchByNameLabel': 'Search by name',
        'infoCollectionTitle.touchButtonJoin': 'PLAY HERE',
        'infoCollectionTitle.touchButtonJoinTeam': 'Join',
        'infoCollectionTitle.geolocationGathering':
            'We are going to use your geolocation to find the location participating in {name} nearest to you...',
        'infoCollectionTitle.noSearchResults': `The search didn't return any results. Please check the name.`,

        'onboarding.requestDisplayName.title': 'Display Name',
        'onboarding.requestDisplayName.description': 'Choose a display name:',
        'onboarding.requestDisplayName.field.title': 'display name:',
        'onboarding.requestDisplayName.field.placeholder': 'Placeholder',

        'onboarding.okta.title': 'Getting the game ready ...',
        'onboarding.updates.title': 'Stay in Touch',

        'inputField.helpMe': 'contact us.',
        'inputField.helpQuestion':
            'Need help? Visit our {helpPage} or {contactPage}',
        'inputField.helpQuestionShort': 'Need help? Visit our {helpPage}',

        'inputField.helpLinkTitle': 'Help Page',
        'inputField.contactLinkTitle': 'contact us.',

        'error.phoneAuth.invalidPhoneNumber': 'Invalid phone number',
        'error.phoneAuth.verificationCodesBlocked':
            'Submission failed. Please double check that you enter a mobile number in the fomrat xxx-xxx-xxxx',
        'error.phoneAuth.tooManyAttempts':
            'Too many attempts. Try again later.',
        'error.phoneAuth.incorrectVerificationCode':
            'Incorrect verification code.',
        'error.phoneAuth.verificationCodeExpired':
            'Code expired. Please resend and try again.',
        // 'error.phoneAuth.sendSmsRequestFailed': `Request to send a verification code to user failed.`,
        'error.phoneAuth.verifyRequestFailed':
            "Request to verify a user's code failed.",

        'error.failedToFetch':
            'Please check internet connection and try again.',
        'error.generic': 'Some error occurred',
        'error.displayName.isTaken': 'Oops. That name is already taken.',
        'error.offensiveLanguage': 'Offensive language detected.',
        'inputField.error.charLimit': 'EXCEEDS {limit} CHARACTER LIMIT',
        'inputField.error.validationError': 'Invalid string',

        'displayName.error':
            'Name must be between {min} and {max} chars, can contain letters, numbers, hyphens or underscores with at least one letter',

        'onboarding.disclaimer': 'By continuing you agree to ',
        'onboarding.officialRules': 'Official Rules',
        'onboarding.termsAndConditions': 'Terms and Conditions',
        'onboarding.privacyPolicy': 'Privacy Policy',
        'onboarding.separator': 'and',
        'onboarding.cookies': 'Cookies',

        'onboarding.requestEmail.field.title': 'Email address:',
        'onboarding.requestEmail.bodyWithPrizes.markdown':
            'In order to redeem prizes we will need your email to contact you.',
        'onboarding.requestEmail.bodyWithoutPrizes.markdown':
            'Please provide your email for future communication.',
        'onboarding.invalidEmail': 'Please enter a valid email address',

        'optIn.marketing.phone.label': 'Get Texts',
        'optIn.marketing.email.label': 'Get Emails',
        'optIn.marketing.3p.label': 'Be contacted by our partners',
        'optIn.marketing.tally.label':
            'I would like to receive future communication from Tally and their partners via e-mail and I agree to the [privacy policy](https://www.playtally.com/policies/privacy/tally-privacy-policy).',

        'onboarding.requestPhone.title': 'Welcome',
        'onboarding.requestPhone.title.gameIsLive': 'Welcome',
        'onboarding.requestPhone.description.gameIsLive':
            'Predict for free as the action unfolds and compete for Exclusive Prizes. Enter your mobile phone number to get started.',
        'onboarding.requestPhone.title.gameIsFinal': 'Sign In',
        'onboarding.requestPhone.description.gameIsFinal':
            'The game is over, but you can still sign in to see your results or sign up with your mobile number to get ready for the next game.',

        'onboarding.requestPhone.field.title': 'phone number:',
        'onboarding.requestPhone.field.placeholder': 'xxx-xxx-xxxx',
        'onboarding.requestPhone.field.description':
            'We only use your phone number to contact you if you win.',

        'onboarding.verifyCode.title': 'Verify Code',
        'onboarding.verifyCode.description':
            'Please enter the verification code sent to {phoneNumber}',
        'onboarding.verifyCode.resendCode': 'Resend code',

        'onboarding.newIteration.fallbackTitle': 'Accept Game Rules',
        'onboarding.newIteration.fallbackNewOptInBody':
            'Please agree to the Official Rules and Terms & Conditions to participate.',
        'onboarding.newIteration.checkBox1FallbackLabel':
            'By checking this box and continuing I agree to the official rules and terms & conditions linked below',

        'gameFooter.contactSupport': 'Contact Support',
        'gameFooter.poweredBy': 'Powered by',

        'broadcastLeaderbord.playerLeaderBoard.title': 'Top of the leaderboard',
        'broadcastLeaderbord.playerLeaderBoard.stats.rank': 'Rank',
        'broadcastLeaderbord.playerLeaderBoard.stats.player': 'Player',
        'broadcastLeaderbord.playerLeaderBoard.stats.points': 'Points',

        'leaderBoardTab.switch.tournamentLeaderBoard': 'Tournament Leaderboard',
        'leaderBoardTab.switch.groupLeaderBoard': 'Group Leaderboard',
        'leaderBoardTab.header': 'Leaderboard',
        'leaderBoardTab.signInButton': 'Already played? Tap to sign in.',
        'leaderBoardTab.finalAfterGameDisclaimer':
            'Leaderboards will change during the game and are only final after the game is over and point totals have been reviewed for accuracy by the operator.',

        'prosLeaderboard.header': 'How the Pros Did',

        // #region added for concacaf
        'leaderboardCard.rankOutOf.label': 'out of {totalPlayers}',
        //#endregion

        'leaderBoard.prizeDetails': 'Prize details',
        'leaderBoard.prizeDetailsButton': 'Prize details',
        'leaderBoard.correctPicks': ' PICKS CORRECT',

        'settings.titleBar': 'PROFILE',
        'settings.welcomeText': 'Hi, {username}',
        'settings.joinedText': 'Joined: {date}',

        'account.rewards.header': 'Rewards',
        'account.stats.header': 'Stats',
        'account.stats.previous': 'Previous',
        'account.stats.next': 'Next',

        'settings.personalInfo.header': 'Personal Info',
        'settings.personalInfo.description': 'Email and phone number',
        'settings.personalInfo.email.label': 'Email',
        'settings.personalInfo.email.button': 'Save',
        'settings.personalInfo.email.placeholder': 'Your email address',
        'settings.personalInfo.phoneNumber.label': 'Phone number',
        'settings.personalInfo.deleteAccount.label':
            'If you want to close your account please click here to contact support',
        'settings.marketing.header': 'Marketing',
        'settings.marketing.descriptionText':
            'Stay updated with {partnerName} by opting in for special promotions and marketing.',
        'settings.smsAlertHeader': 'Game Alerts',
        'settings.smsAlertLabel': 'SMS Game Alerts',
        'settings.smsAlertText':
            'Receive SMS alerts every time new predictions go live, and never miss out on the competition.',
        'settings.smsAlertExternalText':
            'Please follow instructions in the sign up message you received or text HELP to {partnerSMSNumber} for more options. Msg & data rates may apply.',
        'settings.smsAlertUnstopText':
            'To opt back into SMS alerts, send ”UNSTOP" to',
        'settings.smsAlertUnstopLink': 'Click here to reactivate',
        'settings.subscribeButton.subscribe': 'Subscribed',
        'settings.subscribeButton.subscribed': 'Subscribe',

        'groupSettings.groupLeaderbord': 'Group Leaderboard',
        'groupSettings.groupLeaderbordDescription':
            'Create groups to compete in a private group with your own group leaderboards. Your group participation does not affect your results in the overall tournament.',
        'groupSettings.definitionList.owner': "You're the owner of",
        'groupSettings.definitionList.isLoading': 'Loading group...',
        'groupSettings.definitionList.member': "You're a member of",
        'groupSettings.definitionList.inviteLink': 'Invite link',
        'groupSettings.definitionList.deleteGroup': 'Delete group',
        'groupSettings.definitionList.leaveGroup': 'Leave group',
        'groupSettings.createGroup.inputLabel': 'Group name',
        'groupSettings.createGroup.inputPlaceholder': 'Some group name',
        'groupSettings.createGroup.buttonText': 'Create group',
        'groupSettings.createGroup.description':
            'You can also join the existing group by following the link provided by any group member.',

        'leaderboardPage.tab.overall.label': 'Overall',

        'leaderboardPage.backToGame':
            'To return to the game and see how you did, {leaderboardLink}.',
        'leaderboardPage.backToGame.linkText': 'click here',

        'predictPage.userStats.titleOverride': 'My Game Stats',
        'predictPage.userStats.callToActionButton.label': 'LEADERBOARD',
        'predictPage.userStats.howToPlayButton.label': 'How To Play',
        'predictPage.predictionList.groupTitle': 'PENDING PREDICTIONS',
        'predictPage.predictionList.groupTitleTokenized': 'PREDICTIONS',
        'predictPage.heroicPredictionNotice': 'New Predictions',
        'predictPage.predictionList.lockLabel': 'Locks',

        'triviaGame.welcome.header': 'Welcome to {eventName}!',
        'triviaGame.welcome.description.countdownNoPoints': `The game consists of **{numberOfQuestions}** trivia questions. You'll have **{secondsPerQuestion}** seconds for each question to answer.`,
        'triviaGame.welcome.description.noCountdownNoPoints': `The game consists of **{numberOfQuestions}** trivia questions. Show your knowledge - pick the right answers for a chance to win.`,
        'triviaGame.welcome.description.pointsCountdown': `The game consists of **{numberOfQuestions}** trivia questions. You'll have **{secondsPerQuestion}** seconds for each question to answer. The faster you answer, the more points you'll score!`,
        'triviaGame.welcome.description.countdownFullPoints': `The game consists of **{numberOfQuestions}** trivia questions. The correct answer to a question is worth **{pointsPerQuestion}** points each. You'll have **{secondsPerQuestion}** seconds for each question to answer.`,
        'triviaGame.welcome.description.noCountdownFullPoints': `The game consists of **{numberOfQuestions}** trivia questions. The correct answer to a question is worth **{pointsPerQuestion}** points each. No time limit, but once you chose your answer it's locked in.`,
        'triviaGame.button.start': 'Start',
        'triviaGame.button.next': 'Next',
        'triviaGame.button.finish': 'Finish',
        'triviaGame.countdown.headline':
            'Question {questionNumber}/{numberOfQuestions}',
        'triviaGame.gameOver.header': 'Thanks for playing!',
        'triviaGame.gameOver.description.pointsCountdown': `You answered **{questionsAnsweredCorrectly}** out of **{numberOfQuestions}** questions correctly. More points for faster answers! The leaderboard and your rank will update in a few minutes.`,
        'triviaGame.gameOver.description': `You answered **{questionsAnsweredCorrectly}** out of **{numberOfQuestions}** questions correctly.`,
        'triviaGame.status.correct': 'Correct',
        'triviaGame.status.incorrect': 'Incorrect',
        'triviaGame.status.missed': 'Missed',
        'triviaGame.status.pointsCountdown':
            'Points ticking down: {points} out of {totalPoints} left',
        'triviaGame.status.fullPoints':
            '{totalPoints} points for the right answer',
        'triviaGame.ad.button.countdown': 'Proceed in {countdownSeconds}',

        'bingoGame.welcome.header': 'Welcome to {eventName}!',
        'bingoGame.welcome.description': `Press Generate Scorecard below to get a virtual {scorecardSize} by {scorecardSize} square bingo card. The card will have squares with randomly generated game events, e.g. how many points a player will score in a quarter. A row, column or diagonal with all 5 events marked constitutes a bingo. Keep track all game long. Getting your virtual cards and up to 5 bingos count toward the Lakers Arcade Weekly Contest. Read contest rules.`,
        'bingoGame.welcome.button.generate': 'Generate Scorecard',

        'userStats.rank': 'Rank',
        'userStats.points': 'Points',
        'userStats.correct': 'Correct',

        'toast.nextLocksIn': 'Next Prediction locks: {textAddon}',
        'toast.resultsSoon': 'Final results coming soon',
        'toast.gameOver': 'Game over',

        'survey.callToAction': 'We want to hear from you',
        'survey.subText': 'Tell us what you thought of the experience.',
        'survey.subTextLink': 'Take 2-minute survey',

        'howToPlayModal.tag': 'Play for Free!',
        'howToPlayModal.header': 'How to Play',
        'howToPlayModal.button': "I'm ready, let's go",
        'howToPlayModal.defaultBody':
            'Predictions go live before tip-off, at the end of each quarter and at commercial breaks. You have a limited amount of time answer before they lock! No answer = no points.',

        'onboardingAgreement.header': 'Are you over 18 years old?',
        'onboardingAgreement.text': "Are you over 18? That's our minimum age.",
        'onboardingAgreement.button.agree': 'Yes',
        'onboardingAgreement.button.disagree': 'No',

        'howToPlayTournamentModal.header': 'How to Play',
        'howToPlayTournamentModal.button': "I'm ready, let's go",

        'cumulativeLeaderboardTab.button': 'How to play',
        'cumulativeLeaderboardTab.myStats': 'My tournament stats',
        'cumulativeLeaderboardTab.gameLeaderboard': 'Game Leaderboard',
        'cumulativeLeaderboardTab.groupLeaderboard': 'Group Leaderboard',

        'group.generalLeaderboard': 'General Leaderboard',
        'group.isLoading': 'Loading group...',
        'group.shareQr.button': 'Show QR Code',
        'group.shareQr.description':
            'Show this QR code to your friends for them to scan and join your group right away.',
        'group.shareLink.button': 'Share Link',
        'group.shareLink.description.groups':
            'Get with your friends to create a group and see who becomes the {partnerName} leader each game and throughout the season! Copy the link and send it to your friends via email, text, or social! Your group participation does not affect your results in the per game contests or season long tournament.',
        'group.shareLink.description.venue':
            'Play against other patrons of {partyName} and see who becomes the leader each game! Copy the link and send it to your friends via email, text, or social!',

        'group.share.title': 'Join {groupName} and play {eventName}!',
        'group.share.text.prize':
            'Join me playing {eventName} and show me what you’ve got - you can also win {prize}!',
        'group.share.text.noPrize':
            'Join me playing {eventName} and show me what you’ve got',
        'group.share.text.noEvent':
            'Join me playing {groupName} and show me what you’ve got',

        'groupLeaderboardTab.headerText.error': 'Error loading the group',
        'groupLeaderboardTab.pointsDescription':
            'Users and points on the group leaderboard will be updated during and after the event',
        'groupLeaderboardTab.headerText.notInGroup':
            "Your're not in a group yet!",
        'groupLeaderboardTab.headerText.notInGroup.descriptionStart':
            'Create a group to compete with your friends.',
        'groupLeaderboardTab.headerText.notInGroup.descriptionBottom':
            'You can also join an existing group. Ask your friends for the invitation link and get in on the fun!',
        'groupLeaderboardTab.button': 'How to play',
        'groupLeaderboardTab.myStats': 'My tournament stats',
        'groupLeaderboardTab.gameLeaderboard': 'Game Leaderboard',
        'groupLeaderboardTab.tournamentLeaderboard': 'Tournament Leaderboard',

        'predictPage.smsOptInInfoModuleDetails.body':
            "Get alerted when new picks go live. We'll only send up to 3 messages per game.",

        'predictPage.smsOptInInfoModuleDetails.button': 'GET SMS ALERTS',
        'predictPage.smsOptInInfoModuleDetails.preTitle': 'GET NOTIFIED',
        'predictPage.smsOptInInfoModuleDetails.title': 'MORE QUESTIONS COMING',
        'predictPage.noSmsOptInInfoModuleDetails.preTitle': 'GET NOTIFIED',
        'predictPage.noSmsOptInInfoModuleDetails.title':
            'MORE QUESTIONS COMING',
        'predictPage.deadStateAndAllAnsweredInfoModuleDetails.body':
            "Questions get released throughout the game. We'll notify you when the next set is live.",
        'predictPage.deadStateAndAllAnsweredInfoModuleDetails.title':
            'More questions coming',
        'predictPage.deadStateAndAllReleasedInfoModuleDetails.body':
            'Follow along as results are tallied live, shifting your spot on the leaderboard as the game progresses. Every point matters!',
        'predictPage.deadStateAndAllReleasedInfoModuleDetails.preTitle':
            'Stay Tuned',
        'predictPage.deadStateAndAllReleasedInfoModuleDetails.title':
            'Ranks Update in Real Time',
        'predictPage.activePredictionsInfoModuleDetails.body':
            'Points depend on how everyone predicts. Longshots are worth more than safe bets.',
        'predictPage.activePredictionsInfoModuleDetails.preTitle': 'HEADS UP!',
        'predictPage.activePredictionsInfoModuleDetails.title':
            'POINTS SHIFT UNTIL PICKS LOCK',
        'predictPage.smsOptInGameOverInfoModuleDetails.title': 'GAME OVER',
        'predictPage.basicGameOverInfoModuleDetails.bodyWithPrizes':
            'Prize winners will be contacted by email. Keep your eyes peeled for the next game!',
        'predictPage.basicGameOverInfoModuleDetails.bodyWithoutPrizes':
            'Thanks for playing. Check the leaderboard to see how you did.',

        'predictPage.basicGameOverInfoModuleDetails.preTitle':
            'CONGRATS WINNERS',
        'predictPage.basicGameOverInfoModuleDetails.title': 'GAME OVER',
        'predictPage.readMoreButton.text': 'Read More',

        'pistachioGame.stickyButton.text.single': '{count} Unmade Prediction',
        'pistachioGame.stickyButton.text.many': '{count} Unmade Predictions',
        'pistachioGame.stickyButton.gameDisclaimerFallback':
            'Please also refer to',
        'pistachioGame.toast.correct.title': 'You nailed it!',
        'pistachioGame.toast.correct.text': '+{points} pts.',
        'pistachioGame.toast.wrong.title': "You didn't get it right.",
        'pistachioGame.toast.wrong.text': 'Keep going @{name}',
        'pistachioGame.toast.noCorrect.title': 'Bummer! 0/{count} correct',
        'pistachioGame.toast.noCorrect.text': 'Keep going @{name}',
        'pistachioGame.toast.someCorrect.title': 'Nailed it!',
        // 'pistachioGame.toast.someCorrect.title': 'Nailed it! ${totalCorrect}/${totalPredictions} correct',
        'pistachioGame.toast.someCorrect.text': '+{points} pts.',
        'pistachioGame.pollToast.text': '+{points} pts.',
        // 'pistachioGame.pollToast.title': '{count} Poll results in!',
        'pistachioGame.pollToast.title': 'Poll results in!',

        'heroicCountdown.headline': 'Predictions lock:',

        'informationModule.closeButton': 'NO THANKS',

        'countdownHeader.label': 'PREDICTIONS LOCK: {timeLeftText}',

        'waitingRoom.smallPrediction.ended': 'You missed this one!',
        'waitingRoom.smallPrediction.notEnded': 'Pre-game predictions live:',
        'waitingRoom.emailNotifyTitle.ended': 'Get notified for the next game',
        'waitingRoom.emailNotifyTitle.notEnded': 'Get notified',
        'waitingRoom.emailSubmitted.smallActionText': "You're on the list",
        'waitingRoom.emailSubmitted.bigActionText': 'Boom!',
        'waitingRoom.detailsButton': 'Details',
        'waitingRoom.submitButton': 'Submit',
        'waitingRoom.continueSkipButton.continue': 'Continue',
        'waitingRoom.continueSkipButton.skip': 'Skip',
        'waitingRoom.gameOver': 'Game over',
        'waitingRoom.soon': 'Coming soon',
        'waitingRoom.tomorrowTime': 'Tomorrow {time}',

        'newPredictionCard.status.missed': 'Missed',
        'newPredictionCard.status.anytime': 'Anytime',
        'newPredictionCard.status.incorrect': 'Incorrect',
        'newPredictionCard.status.participated': 'Participated',
        'newPredictionCard.status.correct': 'Correct',
        'newPredictionCard.status.pending': 'Pending - {when}',
        'newPredictionCard.pollHeading': 'POLL',
        'newPredictionCard.pollPoints': 'PTS',
        'newPredictionCard.sponsorshipTag': 'Pick correct & win',

        'offerDetails.redeem.ButtonText': 'Redeem',
        'offerDetails.redeem.codeTitle': 'Your Code:',
        'offerDetails.expirationText.expired': 'Expired {when}',
        'offerDetails.expirationText.expires': 'Expires {when}',
        'offerDetails.redeem.howTo': 'How to claim',
        'offerDetails.redeem.disclaimer': 'Disclaimer Text',

        'leaderboardDeadstate.waitingSubHead': 'Results coming shortly ⏲',
        'leaderboardDeadstate.waitingLabel': 'Check back after game start',
        'leaderboardDeadstate.buttonText': 'Prize Details',

        'incognitoModal.tagLineText': 'Warning',
        'incognitoModal.headerText': 'Incognito Mode',
        'incognitoModal.bodyText':
            'Playing this game in incognito mode may result in predictions not being saved. Please switch over to regular browser mode to ensure your picks and username are preserved.',
        'incognitoModal.buttonText': 'I understand',

        'offerWinModal.youWon.label': 'You won',
        'offerWinModal.rewardsButton.text': 'See rewards',

        'newPredictionModal.label': 'Next Prediction Locks',
        'newPredictionModal.buttonText': 'Predict Now',

        'timeUntilLock.timeUntilLockMode.short': '1 DAY',
        'timeUntilLock.timeUntilLockMode.medium': 'TMRW {timeString}',
        'timeUntilLock.timeUntilLockMode.fallback': 'Tomorrow {timeString}',
        'timeUntilLock.dateType.actualDate': 'PREDICTIONS LOCK ON {dateString}',
        'timeUntilLock.dateType.daysUntil':
            'PREDICTIONS LOCK IN {daysUntilStart} DAYS',

        'dates.longWeekDay.sunday': 'Sunday',
        'dates.longWeekDay.monday': 'Monday',
        'dates.longWeekDay.tuesday': 'Tuesday',
        'dates.longWeekDay.wednesday': 'Wednesday',
        'dates.longWeekDay.thursday': 'Thursday',
        'dates.longWeekDay.friday': 'Friday',
        'dates.longWeekDay.saturday': 'Saturday',
        'dates.shortWeekDay.sunday': 'Sun.',
        'dates.shortWeekDay.monday': 'Mon.',
        'dates.shortWeekDay.tuesday': 'Tue.',
        'dates.shortWeekDay.wednesday': 'Wed.',
        'dates.shortWeekDay.thursday': 'Thu.',
        'dates.shortWeekDay.friday': 'Fri.',
        'dates.shortWeekDay.saturday': 'Sat.',

        'countdownPageBreak.batchLabel': 'Locks in ',

        'powerUpAnimation.doublePointPowerup.label':
            '{name} wants to up your game',
        'powerUpAnimation.fallback.label': '{name} wants to give you an edge',

        'verifyCodeCountdown.button.label': 'RESEND CODE',
        'verifyCodeCountdown.underlineText': 'code resent',
        'verifyCodeCountdown.underlineCountdown': 'RESEND IN',

        'bonusSponsors.thankYouLabel': 'thank you bonus sponsors',

        'playgroundGame.decoySpacer': 'Welcome to the PLAYGROUND 🤘',

        'prizeDetailsModal.button': 'Play now',
        'prizeDetailsModal.header': 'Prizes',

        'broadcastSideboard.copyQuestionButton': 'COPY',
        'broadcastSideboard.copyOptionButton': 'COPY',
        'broadcastSideboard.percentSpan': 'Fill in the blank',
        'broadcastSideboard.clipboardText': 'copied!',
        'broadcastView.playerCount': '{count} Players',
        'broadcastView.emptyState': 'No predictions have been answered yet.',
        'tvBroadcastView.rank': 'Rank',
        'tvBroadcastView.player': 'Player',
        'tvBroadcastView.points': 'Points',
        'tvBroadcastView.correct': '# Correct',
        'groupPage.groupView.subHead.groups': 'Current Group Members:',
        'groupPage.groupView.subHead.venue': 'Playing in your bar:',
        'groupPage.groupView.owner': 'Owner',
        'groupPage.error': 'Error fetching group!',
        'groupPage.warning.game':
            "You can only be a member of one group, so joining a group will make you leave the group you're currently in. Leaving the group you're the owner of will cause the deletion of the group!",
        'groupPage.warning.venue':
            "You can only be member of one bar's fanzone. You can switch though: joining another fanzone means you'll leave your current bar's fanzone and join the new one's. Don't worry, you won't lose your points!",
        'groupPage.playButton': "Don't join, just play the game",
        'groupPage.joinButton': 'Join this group and play',
        'groupPage.stayInFanzone': "Don't switch fanzones",
        'groupPage.joinedButton': "Joined - Let's Play!",
        'presentedBy.label': 'Presented by',
        'createGroupForm.inputLabel': 'Group name',
        'createGroupForm.buttonText': 'Create group',
        'createGroupForm.cancel': 'Cancel',
        'createGroupForm.inputPlaceholder': 'Some group name',
        'deleteGroupForm.ownerDefinition': "You're the owner of",
        'deleteGroupForm.memberDefinition': "You're a member of",
        'deleteGroupForm.ownerButtonText': 'Delete group',
        'deleteGroupForm.memberButtonText': 'Leave group',
        'copyToClipboardButton.tooltip.copied': 'Copied',
        'socialTitle.label': 'Share the game',
        'offerCard.status.pending': 'Unlocked after the game',
        'offerCard.status.today': 'Today',
        'offerCard.status.tomorrow': 'Tomorrow',
        'offerCard.status.inDays': '{in} days',
        'offerCard.status.expd': 'Expired: {when}',
        'offerCard.status.exps': 'Expires: {when}',

        'predictions.blank.label': 'My prediction',
        'predictions.blank.average': 'AVG',
        'predictions.blank.result': 'RESULT',
        'predictions.blank.errorGreater': 'Maximum value is {value}',
        'predictions.blank.errorBelow': 'Minimum value is {value}',

        'prediction.option.odds.betToWin': 'Bet {bet} to win {win}',
        'prediction.option.odds.betToWinShort':
            'Your {bet} points could win you {win} points',
        'prediction.option.odds.betWonShort':
            'Your {bet} points has won {win} points',
        'prediction.option.odds.liveOddsBy':
            'Live Odds provided by {sportsbook}',
        'prediction.option.odds.oddClaim': 'You locked at',
        'prediction.option.odds.oddsAware':
            'Betting simulation predictions answer cant be changed once you make your choice',
        'prediction.option.odds.oddsType.MONEY_LINE': 'Money Line',
        'prediction.option.odds.oddsType.OVER_UNDER': 'Over/Under',
        'prediction.option.draw': 'Draw',
        'prediction.option.nobet': 'No Bet',

        'storageConsentModal.description':
            'This website stores data such as cookies to enable important site functionality including analytics, targeting, and personalization. You may alter your preferences at any time or accept the default settings.',
        'storageConsentModal.storagePolicy.link.text': 'Data Storage Policy',
        'storageConsentModal.necessary.switch.label': 'Strictly Necessary',
        'storageConsentModal.analytics.switch.label': 'Analytics',
        'storageConsentModal.button.save': 'Save',

        'geoFence.message.subHeadline':
            'You need to be in specific area to play the game',
        'geoFence.message.headline': 'Sorry, you are not allowed',

        'fanzone.tvView.lbHeader.rank': 'Rank',
        'fanzone.tvView.lbHeader.player': 'Player',
        'fanzone.tvView.lbHeader.points': 'Points',
        'fanzone.tvView.lbHeader.correct': 'Correct',
        'fanzone.tvView.body.preGameMsg':
            'Play the {game} game at {groupName} for the chance to win a legacy replica jersey.',
        'fanzone.tvView.body.preGameCTA': 'Scan the QR Code to join!',

        'igame.noTriesLeft': 'You exceeded amount of tries, come back later!',
    },
} as const;
