import styled from '@emotion/styled';
import React, { Component } from 'react';
import { StyleDefaults } from 'src/styles/Constants';

interface Props {
    headerText?: string;
    subHeaderText?: string;
    descriptionText?: string;
}

export default class HeaderText extends Component<Props> {
    public render() {
        const { headerText, subHeaderText, descriptionText } = this.props;

        return (
            <Container>
                {!!headerText && (
                    <Header>
                        <>
                            {headerText}
                            {!!subHeaderText && <span>{subHeaderText}</span>}
                        </>
                    </Header>
                )}
                {!!descriptionText && (
                    <Description>{descriptionText}</Description>
                )}
            </Container>
        );
    }
}

const Container = styled.div`
    display: flex;
    font-family: ${StyleDefaults.FONT_FAMILY};
    flex-direction: column;
`;

const Header = styled.h1`
    padding-bottom: 20px;
    align-items: center;
    line-height: 37px;
    font-size: 35px;
    letter-spacing: -0.6px;
    font-weight: 900;
    justify-content: center;
    text-align: center;
    span {
        display: block;
        font-size: 25px;
    }
`;

const Description = styled.div`
    align-items: center;
    font-size: 12px;
    line-height: 18px;
    justify-content: center;
    text-align: center;
    margin-top: 14px;
`;
