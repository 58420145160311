import {
    EventState,
    PredictionModel,
    PredictionType,
} from 'src/util/TallyFirestore';

export interface Tab {
    id: string;
    text: string;
}

export interface PrizeRenderData {
    heading?: string;
    prize?: string;
}

export enum AnswerCorrectness {
    correct = 'correct',
    incorrect = 'incorrect',
    unknown = 'unknown',
}

export type PredictionState = 'live' | 'pending' | 'final';

export type PredictionsByState = {
    [key in PredictionState]: PredictionModel[];
};

export function getPredictionState(
    prediction: PredictionModel,
    now: Date,
): PredictionState {
    if (
        Math.floor(
            (prediction.lockDate.toDate().getTime() - now.getTime()) / 1000,
        ) > 0
    ) {
        return 'live';
    }

    if (prediction.resolved) {
        return 'final';
    }

    return 'pending';
}

export function parseEventPath(eventPath: string): EventPathParts | undefined {
    const rx =
        /partners\/(.*)\/categories\/(.*)\/iterations\/(.*)\/events\/(.*)/;
    const matches = rx.exec(eventPath);
    if (!matches || matches.length < 5) {
        return undefined;
    }

    return {
        categoryId: matches[2],
        eventId: matches[4],
        iterationId: matches[3],
        partnerId: matches[1],
    };
}

export function getIterationPathFromEventPath(eventPath: string): string {
    const pathParts = parseEventPath(eventPath);

    if (!pathParts) {
        throw new Error(`Error parsing event path ${eventPath}`);
    }

    const { categoryId, iterationId, partnerId } = pathParts;
    return `partners/${partnerId}/categories/${categoryId}/iterations/${iterationId}`;
}

export interface EventPathParts {
    categoryId: string;
    eventId: string;
    iterationId: string;
    partnerId: string;
}
