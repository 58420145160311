import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSpring, animated } from 'react-spring';
import styled from '@emotion/styled';

import { Color, ColorMode } from 'src/styles/Constants';
import { NextPredictionToLockInfo } from 'src/components/shared/ui/Toast';
import AnimatedCountdownBar from 'src/components/shared/animations/AnimatedCountdownBar';
import Theme from 'src/util/Theme';
import LockText, {
    TimeUntilLockMode,
} from 'src/components/shared/predictions/LockText';
import { DEFAULT_HIGHLIGHT_HOURS, SECONDS_IN_HOUR } from 'src/util/Dates';
import { PredictionModel } from 'src/util/TallyFirestore';

interface Props {
    nextPredictionToLock?: PredictionModel;
    nextPredictionToLockInfo?: NextPredictionToLockInfo;
}

export default function HeroicCountdown(props: Props) {
    const { nextPredictionToLock, nextPredictionToLockInfo } = props;
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setVisible(!!nextPredictionToLock);
    });

    const containerSpring = useSpring({
        height: visible ? '100px' : '0px',
        opacity: visible ? 1 : 0,
        paddingTop: visible ? '25px' : '0px',
    });

    const barColor =
        nextPredictionToLockInfo && nextPredictionToLockInfo.hasAnswered
            ? Color.P6_ELECTRIC_BLUE
            : Color.P4_CHILI_PEPPER;
    const labelColor =
        nextPredictionToLockInfo && nextPredictionToLockInfo.hasAnswered
            ? Theme.nextLockUrgencyColor //Color.G1_BLACK
            : Theme.nextLockUrgencyColor;

    if (nextPredictionToLockInfo) {
        return (
            <Container style={containerSpring}>
                <CountdownHeadline>
                    <FormattedMessage id="heroicCountdown.headline" />
                </CountdownHeadline>
                {nextPredictionToLockInfo.lockInfo &&
                    (nextPredictionToLockInfo.lockInfo === 'lockDescription' ? (
                        <CountdownLabel color={labelColor}>
                            {nextPredictionToLock &&
                                nextPredictionToLock.lockDescription}
                        </CountdownLabel>
                    ) : (
                        nextPredictionToLock && (
                            <>
                                <CountdownLabel color={labelColor}>
                                    <LockText
                                        countdownSeconds={
                                            nextPredictionToLockInfo.lockInfo
                                                .timeLeftCount
                                        }
                                        countdownDate={nextPredictionToLock.lockDate.toDate()}
                                        mode={TimeUntilLockMode.medium}
                                    />
                                </CountdownLabel>
                                {nextPredictionToLockInfo.lockInfo &&
                                    nextPredictionToLockInfo.lockInfo
                                        .timeLeftCount <=
                                        DEFAULT_HIGHLIGHT_HOURS *
                                            SECONDS_IN_HOUR && (
                                        <BarContainer>
                                            <AnimatedCountdownBar
                                                barBackgroundColorMode={
                                                    ColorMode.LIGHT
                                                }
                                                barColor={barColor}
                                                timeLeftCount={
                                                    nextPredictionToLockInfo
                                                        .lockInfo.timeLeftCount
                                                }
                                            />
                                        </BarContainer>
                                    )}
                            </>
                        )
                    ))}
            </Container>
        );
    }

    return null;
}

const Container = styled(animated.div)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 25px 0 0 0;
`;

export const CountdownLabel = styled.div<{ color: string }>`
    font-size: 35px;
    font-weight: 900;
    letter-spacing: -0.6px;
    line-height: 37px;
    color: ${(props) => props.color};
    text-transform: upperCase;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
`;

export const CountdownHeadline = styled.div`
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.75px;
    line-height: 18px;
    text-transform: upperCase;
`;

const BarContainer = styled.div`
    margin-top: 10px;
    height: 8px;
    width: 150px;
`;
