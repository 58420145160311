export default {
    fullLanguageName: 'Español',
    messages: {
        'menu.play': 'Jugar',
        'menu.playing': 'Jugando: {eventName}',
        'menu.gameLeaderboard': 'Tabla de clasificación',
        'menu.groups.groups': 'Grupos',
        'menu.tournament': 'Torneo',
        'menu.account': 'Cuenta',
        'infoCollectionTitle.touchButtonLabel': 'Siguiente',
        'infoCollectionTitle.groupAndTeamsModeTileTitle':
            'Elige tu equipo para representar',
        'onboarding.requestDisplayName.title': 'Escoge un nombre de usuario',
        'onboarding.requestDisplayName.description':
            '¡Escoge un nombre de usuario!',
        'onboarding.requestDisplayName.field.title': 'nombre de usuario',
        'onboarding.requestDisplayName.field.placeholder': 'Comodín',
        'onboarding.okta.title': 'Iniciar sesión con Okta',
        'onboarding.updates.title': 'Manténgase en contacto',
        'inputField.helpMe': 'Contáctanos',
        'inputField.helpQuestion':
            '¿Necesitas ayuda? Visita nuestra página {helpPage} o {contactPage}',
        'inputField.helpLinkTitle': 'Ayuda',
        'inputField.contactLinkTitle': 'Contáctanos',

        'error.phoneAuth.invalidPhoneNumber': 'Número de teléfono no válido',
        'error.phoneAuth.verificationCodesBlocked':
            'El envío falló. Verifica que hayas ingresado un número de teléfono móvil en el formulario xxx-xxx-xxxx',
        'error.phoneAuth.tooManyAttempts':
            'Demasiados intentos. Intenta de nuevo más tarde.',
        'error.phoneAuth.incorrectVerificationCode':
            'El código de verificación es incorrecto',
        'error.phoneAuth.verificationCodeExpired':
            'El código de verificación ha expirado. Por favor reenvíalo e inténtalo de nuevo.',
        'error.phoneAuth.verifyRequestFailed':
            'La solicitud para verificar el código de un usuario falló.',
        'error.failedToFetch':
            'Por favor verifica tu conexión de internet e inténtalo de nuevo',
        'error.displayName.isTaken': '¡Qué pena! El nombre ya está en uso.',
        'error.offensiveLanguage': 'Se ha detectado un lenguaje inapropiado',
        'inputField.error.charLimit': 'Excede {limit} el límite de caracteres',
        'inputField.error.validationError': 'Cadena no válida',

        'onboarding.disclaimer':
            'Al continuar estás de acuerdo con nuestros términos',
        'onboarding.officialRules': 'Reglas Oficiales',
        'onboarding.termsAndConditions': 'Términos y Condiciones',
        'onboarding.privacyPolicy': 'Política de privacidad',
        'onboarding.separator': 'y',
        'onboarding.cookies': 'Cookies',
        'onboarding.requestEmail.field.title': 'Correo electrónico',
        'onboarding.requestEmail.bodyWithPrizes.markdown':
            'Para reclamar los premios necesitamos tu correo electrónico para contactarte',
        'onboarding.requestEmail.bodyWithoutPrizes.markdown':
            'Por favor envíanos tu correo electrónico para que podamos comunicarnos.',
        'onboarding.invalidEmail':
            'Por favor ingresa un correo electrónico valido',
        'optIn.marketing.phone.label': 'Recibe textos',
        'optIn.marketing.email.label': 'Recibe mensajes de correo electrónico',
        'optIn.marketing.3p.label':
            'Recibe mensajes de nuestros patrocinadores',
        'onboarding.requestPhone.title': 'Bienvenido',
        'onboarding.requestPhone.title.gameIsLive': 'Bienvenidos',
        'onboarding.requestPhone.description.gameIsLive':
            'Haz predicciones gratis mientras los partidos están en juego y compite para ganar premios exclusivos.',
        'onboarding.requestPhone.title.gameIsFinal': 'Iniciar sesión',
        'onboarding.requestPhone.description.gameIsFinal':
            'El juego ha finalizado pero puedes ingresar para ver tus resultados.',
        'onboarding.requestPhone.field.title': 'Número de teléfono',
        'onboarding.requestPhone.field.placeholder': 'xxx-xxx-xxxx',
        'onboarding.requestPhone.field.description':
            'Sólo usamos tu número de teléfono para contactarte si has ganado',
        'onboarding.verifyCode.title': 'Verificar código',
        'onboarding.verifyCode.description':
            'Por favor ingresa el código de verificación que te enviamos {phoneNumber}',
        'onboarding.verifyCode.resendCode': 'Reenviar código',
        'onboarding.newIteration.fallbackTitle': 'Aceptar las reglas de juego',
        'onboarding.newIteration.fallbackNewOptInBody':
            'Acepta las Reglas oficiales y los Términos y condiciones para participar.',
        'onboarding.newIteration.checkBox1FallbackLabel':
            'Al marcar esta casilla y continuar, acepto las reglas oficiales y los términos y condiciones vinculados a continuación.',
        'gameFooter.contactSupport': 'Contacta al Soporte',
        'gameFooter.poweredBy': 'Powered by',
        'broadcastLeaderbord.playerLeaderBoard.title': 'Líder de clasificación',
        'broadcastLeaderbord.playerLeaderBoard.stats.rank': 'Clasificado',
        'broadcastLeaderbord.playerLeaderBoard.stats.player': 'Jugador',
        'broadcastLeaderbord.playerLeaderBoard.stats.points': 'Puntos',
        'leaderBoardTab.switch.tournamentLeaderBoard':
            'Clasificación de Torneo',
        'leaderBoardTab.switch.groupLeaderBoard': 'Clasificación de Grupo',
        'leaderBoardTab.header': 'Tabla de Clasificación',
        'leaderBoardTab.signInButton':
            '¿Ya jugaste? Presiona aquí para Iniciar sesión',
        'leaderBoardTab.finalAfterGameDisclaimer':
            'Las tablas de clasificación se actualizarán durante el juego y sólo serán finalizadas cuando termine el juego',
        'prosLeaderboard.header': 'Cómo lo hicieron los profesionales',
        // #region added for concacaf
        'leaderboardCard.rankOutOf.label': 'de {totalPlayers}',
        //#endregion
        'leaderBoard.prizeDetails': 'Información del premio',
        'leaderBoard.prizeDetailsButton': 'Información del premio',
        'leaderBoard.correctPicks': 'SELECCIÓN CORRECTA',
        'settings.titleBar': 'PERFIL',
        'settings.welcomeText': 'Hola, {username}',
        'settings.joinedText': 'Ingresado {date}',
        'account.rewards.header': 'Recompensas',
        'account.stats.header': 'Estadísticas',
        'account.stats.previous': 'Anterior',
        'account.stats.next': 'Próximo',

        'settings.personalInfo.header': 'Información personal',
        'settings.personalInfo.description':
            'Correo electrónico, número de teléfono',
        'settings.personalInfo.email.label': 'Correo electrónico',
        'settings.personalInfo.email.button': 'Guardar',
        'settings.personalInfo.email.placeholder':
            'Tu dirección de correo electrónico',
        'settings.personalInfo.phoneNumber.label': 'Número de teléfono',
        'settings.marketing.header': 'Mercadeo',
        'settings.marketing.descriptionText':
            'Mantente actualizado con {partnerName} aceptando por promociones especiales y marketing.',
        'settings.smsAlertHeader': 'Alertas de juego',
        'settings.smsAlertLabel': 'Alertas SMS de juego',
        'settings.smsAlertText':
            'Recibe alertas SMS cada vez nuevas que predicciones sean anunciadas en vivo y no te pierdas ni un minuto del torneo',
        'settings.smsAlertUnstopText':
            'Para suscribirte nuevamente a SMS alertas, envía UNSTOP a',
        'settings.smsAlertUnstopLink': 'Haz clic aquí para reactivar',
        'settings.subscribeButton.subscribe': 'Suscripto',
        'settings.subscribeButton.subscribed': 'Suscribir',
        'groupSettings.groupLeaderbord': 'Clasificación de Grupo',
        'groupSettings.groupLeaderbordDescription':
            'Crea grupos para competir con otros miembros',
        'groupSettings.definitionList.owner': 'Tú eres el dueño de...',
        'groupSettings.definitionList.isLoading': 'Cargando grupo....',
        'groupSettings.definitionList.member': 'Eres miembro de',
        'groupSettings.definitionList.inviteLink': 'Link de invitación',
        'groupSettings.definitionList.deleteGroup': 'Eliminar grupo',
        'groupSettings.definitionList.leaveGroup': 'Salir grupo',
        'groupSettings.createGroup.inputLabel': 'Nombre de grupo',
        'groupSettings.createGroup.inputPlaceholder': 'Algún nombre de grupo',
        'groupSettings.createGroup.buttonText': 'Crear grupo',
        'groupSettings.createGroup.description':
            'También puedes ingresar a otros grupos usando el link de invitación de otros miembros',

        'leaderboardPage.tab.overall.label': 'General',

        'leaderboardPage.backToGame':
            'Para volver al juego y ver cómo lo hiciste, haz {leaderboardLink}.',
        'leaderboardPage.backToGame.linkText': 'clic aquí',

        'predictPage.userStats.titleOverride': 'Mis estadísticas de juego',
        'predictPage.userStats.callToActionButton.label':
            'TABLA DE CLASIFICACIÓN',
        'predictPage.userStats.howToPlayButton.label': 'Cómo jugar',
        'predictPage.predictionList.groupTitle': 'PREDICCIONES PENDIENTES',
        'predictPage.predictionList.groupTitleTokenized': 'PREDICCIONES',
        'predictPage.heroicPredictionNotice': 'Nuevas predicciones',
        'predictPage.predictionList.lockLabel': 'Cerradas',

        'triviaGame.welcome.header': 'Bienvenido a {eventName}!',
        'triviaGame.welcome.description.pointsCountdown': `El juego consta de **{numberOfQuestions}** Preguntas de trivia. Tendrás **{secondsPerQuestion}** segundos para responder a cada pregunta. ¡Cuanto más rápido responda, más puntos obtendrás!`,
        'triviaGame.welcome.description.countdownFullPoints': `El juego consta de **{numberOfQuestions}** Preguntas de trivia. La respuesta correcta a una pregunta vale **{pointsPerQuestion}** puntos cada una. Tendrás **{secondsPerQuestion}** segundos para cada pregunta para responder.`,
        'triviaGame.welcome.description.noCountdownFullPoints': `El juego consta de **{numberOfQuestions}** Preguntas de trivia. La respuesta correcta a una pregunta vale **{pointsPerQuestion}** puntos cada una. No hay límite de tiempo, pero una vez que eligió su respuesta, está bloqueado.`,
        'triviaGame.button.start': 'Iniciar',
        'triviaGame.button.next': 'Próximo',
        'triviaGame.button.finish': 'Terminar',
        'triviaGame.countdown.headline':
            'Pregunta {questionNumber}/{numberOfQuestions}',
        'triviaGame.gameOver.header': 'Gracias por jugar!',
        'triviaGame.gameOver.description.pointsCountdown': `Has respondido correctamente a **{questionsAnsweredCorrectly}** de **{numberOfQuestions}** preguntas. ¡Más puntos por respuestas rápidas!  La clasificación se actualizará en unos minutos.`,
        'triviaGame.gameOver.description': `Has respondido correctamente a **{questionsAnsweredCorrectly}** de **{numberOfQuestions}** preguntas.`,
        'triviaGame.status.correct': 'Correct',
        'triviaGame.status.incorrect': 'Incorrecto',
        'triviaGame.status.missed': 'Se há perdido',
        'triviaGame.status.pointsCountdown':
            'Los puntos van bajando: {points} de {totalPoints} restantes',
        'triviaGame.status.fullPoints': `{totalPoints} puntos para la respuesta correcta`,
        'triviaGame.ad.button.countdown': 'Proceda en {countdownSeconds}',

        'userStats.rank': 'Clasificación',
        'userStats.points': 'Puntos',
        'userStats.correct': 'Aciertos',
        'toast.nextLocksIn': 'Próxima predicciones cerradas: {textAddon}',
        'toast.resultsSoon': 'Resultado final en breve',
        'toast.gameOver': 'Fin del juego',
        'survey.callToAction': 'Queremos saber de ti',
        'survey.subText': 'Cuéntanos tu experiencia',
        'survey.subTextLink': 'Responde a una encuesta de 2 minutos',
        'howToPlayModal.tag': '¡Juega gratis!',
        'howToPlayModal.header': 'Cómo jugar',
        'howToPlayModal.button': 'Estoy listo, ¡juguemos!',
        'howToPlayModal.defaultBody':
            'Las predicciones se lanzan antes del inicio del juego, al final de cada período de juego y durante las comerciales. Hay límite de tiempo para responder antes de que las predicciones se cierren. Si no hay respuesta = no sumas puntos.',
        'howToPlayTournamentModal.header': 'Cómo jugar',
        'howToPlayTournamentModal.button': 'Estoy listo, juguemos',
        'cumulativeLeaderboardTab.button': 'Cómo jugar',
        'cumulativeLeaderboardTab.myStats': 'Mis estadísticas de juego',
        'cumulativeLeaderboardTab.gameLeaderboard': 'Clasificación de juego',
        'cumulativeLeaderboardTab.groupLeaderboard': 'Clasificación de grupo',

        'group.isLoading': 'Cargando grupo...',
        'group.shareQr.button': 'Mostrar código QR',
        'group.shareQr.description':
            'Muestre este código QR a sus amigos para que lo escaneen y se unan a su grupo de inmediato.',
        'group.shareLink.button': 'Copiar link de invitación',
        'group.shareLink.description.groups':
            '¡Únete a tus amigos para crear un grupo y ver quién se convierte en el líder de {partnerName} en cada juego y durante toda la temporada! ¡Copie el enlace y envíelo a sus amigos por correo electrónico, mensaje de texto o redes sociales! La participación de su grupo no afecta sus resultados en los concursos por juego o en el torneo de toda la temporada.',
        'group.shareLink.description.venue':
            '¡Juega contra otros patrocinadores de {partyName} y mira quién se convierte en el líder de cada juego! ¡Copie el enlace y envíelo a sus amigos por correo electrónico, mensaje de texto o redes sociales!',
        'group.share.title': 'Únete a {groupName} y juega {eventName}',
        'group.share.text.prize':
            'Únete a mí jugando {eventName} y muéstrame lo que tienes. ¡También puedes ganar {premio}!',
        'group.share.text.noPrize':
            'Únete a mí jugando {eventName} y muéstrame lo que tienes',
        'group.share.text.noEvent':
            'Únete a mí jugando {groupName} y muéstrame lo que tienes',

        'groupLeaderboardTab.headerText.error':
            'Se produjo un error al cargar el grupo',
        'groupLeaderboardTab.pointsDescription':
            'Los usuarios y puntajes se actualizarán durante y después del evento en la clasificación de grupo',
        'groupLeaderboardTab.headerText.notInGroup':
            '¡No estás en el grupo todavía!',
        'groupLeaderboardTab.headerText.notInGroup.descriptionStart':
            'Crea un grupo para competir con tus amigos',
        'groupLeaderboardTab.headerText.notInGroup.descriptionBottom':
            'También puedes ingresar a otros grupos. Solicita un link de invitación de tus amigos y únete a ellos para competir.',
        'groupLeaderboardTab.button': 'Cómo jugar',
        'groupLeaderboardTab.myStats': 'Mis estadísticas del torneo',
        'groupLeaderboardTab.gameLeaderboard': 'Clasificación de juego',
        'groupLeaderboardTab.tournamentLeaderboard': 'Clasificación de torneo',
        'predictPage.smsOptInInfoModuleDetails.body':
            'Recibe alertas cuando nuevas selecciones se lanzan. Sólo te mandamos hasta 3 mensajes por juego.',
        'predictPage.smsOptInInfoModuleDetails.button': 'RECIBE ALERTAS SMS',
        'predictPage.smsOptInInfoModuleDetails.preTitle':
            'RECIBE NOTIFICACIONES',
        'predictPage.smsOptInInfoModuleDetails.title':
            'A LA BREVEDAD RECIBIRÁS MÁS PREGUNTAS',
        'predictPage.noSmsOptInInfoModuleDetails.preTitle':
            'RECIBE NOTIFICACIONES',
        'predictPage.noSmsOptInInfoModuleDetails.title':
            'A LA BREVEDAD RECIBIRÁS MÁS PREGUNTAS',
        'predictPage.deadStateAndAllAnsweredInfoModuleDetails.body':
            'Las preguntas se lanzarán durante el juego y te notificaremos cuando estén listas',
        'predictPage.deadStateAndAllAnsweredInfoModuleDetails.title':
            'A la brevedad recibirás más preguntas',
        'predictPage.deadStateAndAllReleasedInfoModuleDetails.body':
            'Sigue los resultados en vivo para ver cómo progresas durante el juego. ¡Cada punto cuenta!',
        'predictPage.deadStateAndAllReleasedInfoModuleDetails.preTitle':
            'Quédate conectado',
        'predictPage.deadStateAndAllReleasedInfoModuleDetails.title':
            'Las clasificaciones se actualizan en vivo',
        'predictPage.activePredictionsInfoModuleDetails.body':
            'Los puntos dependen de cómo los demás responden. Las respuestas con menos probabilidad de ganar ofrecen mejores resultados.',
        'predictPage.activePredictionsInfoModuleDetails.preTitle': '¡ALERTA!',
        'predictPage.activePredictionsInfoModuleDetails.title':
            'LOS PUNTOS CAMBIAN HASTA QUE SE CIERRAN LAS ELECCIONES',
        'predictPage.smsOptInGameOverInfoModuleDetails.title':
            'JUEGO FINALIZADO',
        'predictPage.basicGameOverInfoModuleDetails.bodyWithPrizes':
            'Los ganadores de premios serán contactados por correo electrónico. Mantente alerta para el próximo juego.',
        'predictPage.basicGameOverInfoModuleDetails.bodyWithoutPrizes':
            'Gracias por jugar. Consulta la tabla de clasificación para ver cómo te fue.',
        'predictPage.basicGameOverInfoModuleDetails.preTitle':
            'FELICITACIONES A LOS GANADORES',
        'predictPage.basicGameOverInfoModuleDetails.title': 'JUEGO FINALIZADO',
        'predictPage.readMoreButton.text': 'Leer más',
        'pistachioGame.stickyButton.text.single':
            '{count} predicción no realizada',
        'pistachioGame.stickyButton.text.many':
            '{count} predicciones no realizadas',
        'pistachioGame.stickyButton.gameDisclaimerFallback':
            'Por favor, también consulta a',
        'pistachioGame.toast.correct.title': '¡Lo lograste!',
        'pistachioGame.toast.correct.text': '',
        'pistachioGame.toast.wrong.title': 'No lo lograste',
        'pistachioGame.toast.wrong.text': 'Continúa @{name}',
        'pistachioGame.toast.noCorrect.title': 'Qué lástima 0/{count} correcto',
        'pistachioGame.toast.noCorrect.text': 'Continúa @{name}',
        'pistachioGame.toast.someCorrect.title': '¡Lo lograste!',
        'pistachioGame.toast.someCorrect.text': '',
        'pistachioGame.pollToast.text': '',
        'pistachioGame.pollToast.title':
            'Los resultados de la encuesta están listos!',
        'heroicCountdown.headline': 'predicciones cerradas',
        'informationModule.closeButton': '¡NO GRACIAS!',
        'countdownHeader.label': 'PREDICCIONES CERRADAS: {timeLeftText}',
        'waitingRoom.smallPrediction.ended': '¡Perdiste este!',
        'waitingRoom.smallPrediction.notEnded':
            'predicciones pre-juego en vivo:',
        'waitingRoom.emailNotifyTitle.ended':
            'Recibe notificaciones para el próximo juego',
        'waitingRoom.emailNotifyTitle.notEnded': 'Recibe notificaciones',
        'waitingRoom.emailSubmitted.smallActionText': 'Estás en la lista',
        'waitingRoom.emailSubmitted.bigActionText': '¡Boom!',
        'waitingRoom.detailsButton': 'Detalles',
        'waitingRoom.submitButton': 'Enviar',
        'waitingRoom.continueSkipButton.continue': 'Continuar',
        'waitingRoom.continueSkipButton.skip': 'Saltear',
        'waitingRoom.gameOver': 'Juego finalizado',
        'waitingRoom.soon': 'En breve',
        'waitingRoom.tomorrowTime': 'Mañana {time}',
        'newPredictionCard.status.missed': 'Perdido',
        'newPredictionCard.status.anytime': 'Cualquier tiempo',
        'newPredictionCard.status.incorrect': 'incorrecto',
        'newPredictionCard.status.participated': 'Participó',
        'newPredictionCard.status.correct': 'Correcto',
        'newPredictionCard.status.pending': 'pendiente - {when}',
        'newPredictionCard.pollHeading': 'ENCUESTA',
        'newPredictionCard.pollPoints': 'PTS',
        'newPredictionCard.sponsorshipTag': 'Elige el correcto y gana',
        'offerDetails.redeem.ButtonText': 'Reclamar',
        'offerDetails.redeem.codeTitle': 'Tu código:',
        'offerDetails.expirationText.expired': 'Expirado {when}',
        'offerDetails.expirationText.expires': 'Expira {when}',
        'offerDetails.redeem.howTo': 'Cómo reclamar',
        'offerDetails.redeem.disclaimer':
            'Texto de exención de responsabilidad',
        'leaderboardDeadstate.waitingSubHead':
            'Resultados se presentarán a la brevedad',
        'leaderboardDeadstate.waitingLabel':
            'Volver a consultar tras el início del partido',
        'leaderboardDeadstate.buttonText': 'Información del premio',
        'incognitoModal.tagLineText': 'Advertencia',
        'incognitoModal.headerText': 'Modo incógnito',
        'incognitoModal.bodyText':
            'Al jugar en modo incógnito puede resultar que tu información no se registre con el sitio web. Por favor, asegúrate de salir del modo incógnito de tu browser para que tus predicciones, elecciones y nombre de usuario se registren y archiven.',
        'incognitoModal.buttonText': 'Entiendo',
        'offerWinModal.youWon.label': 'Ganaste',
        'offerWinModal.rewardsButton.text': 'Ver recompensas',
        'newPredictionModal.label': 'Próximo cierre de predicciones',
        'newPredictionModal.buttonText': 'Ingresa tus predicciones ahora',
        'timeUntilLock.timeUntilLockMode.short': '1 DÍA',
        'timeUntilLock.timeUntilLockMode.medium': 'MAÑANA {timeString}',
        'timeUntilLock.timeUntilLockMode.fallback': 'Mañana {timeString}',
        'timeUntilLock.dateType.actualDate':
            'LAS PREDICCIONES SE CIERRAN EN {dateString}',
        'timeUntilLock.dateType.daysUntil':
            'LAS PREDICCIONES SE CIERRAN EN {daysUntilStart} DÍAS',

        'dates.longWeekDay.sunday': 'Domingo',
        'dates.longWeekDay.monday': 'Lunes',
        'dates.longWeekDay.tuesday': 'Martes',
        'dates.longWeekDay.wednesday': 'Miércoles',
        'dates.longWeekDay.thursday': 'Jueves',
        'dates.longWeekDay.friday': 'Viernes',
        'dates.longWeekDay.saturday': 'Sábado',
        'dates.shortWeekDay.sunday': 'Dom.',
        'dates.shortWeekDay.monday': 'Lun.',
        'dates.shortWeekDay.tuesday': 'Mar.',
        'dates.shortWeekDay.wednesday': 'Mie.',
        'dates.shortWeekDay.thursday': 'Jue.',
        'dates.shortWeekDay.friday': 'Vie.',
        'dates.shortWeekDay.saturday': 'Sáb.',
        'countdownPageBreak.batchLabel': 'Cierra a las',
        'powerUpAnimation.doublePointPowerup.label':
            '{name} quieres potenciar tu juego',
        'powerUpAnimation.fallback.label': '{name} quieres obtener ventaja',
        'verifyCodeCountdown.button.label': 'REENVIAR CÓDIGO',
        'verifyCodeCountdown.underlineText': 'Código reenviado',
        'verifyCodeCountdown.underlineCountdown': 'REENVIARLO',
        'bonusSponsors.thankYouLabel':
            'gracias a los patrocinadores de bonificación',
        'playgroundGame.decoySpacer': 'Bienvenido/a al CAMPO DE JUEGO 🤘',
        'prizeDetailsModal.button': 'Juega ahora',
        'prizeDetailsModal.header': 'Premios',
        'broadcastSideboard.copyQuestionButton': 'COPIAR',
        'broadcastSideboard.copyOptionButton': 'COPIAR',
        'broadcastSideboard.percentSpan': 'Completa el espacio en blanco',
        'broadcastSideboard.clipboardText': '¡copiado!',
        'broadcastView.playerCount': '{count} jugadores',
        'broadcastView.emptyState': 'Aun no se han respondido las predicciones',
        'tvBroadcastView.rank': 'Clasificación',
        'tvBroadcastView.player': 'Jugador',
        'tvBroadcastView.points': 'Puntos',
        'tvBroadcastView.correct': '# Correcto',
        'groupPage.groupView.subHead.groups': 'Miembros actuales del Grupo:',

        'groupPage.groupView.subHead': 'Miembros del Grupo Actual:',
        'groupPage.groupView.owner': 'Dueño',
        'groupPage.error': '¡Se produjo un error al recuperar el grupo!',
        'groupPage.warning.game':
            'Sólo puedes ser miembro de un grupo, por lo que unirte a un grupo te hará abandonar el grupo en el que estás actualmente. Abandonar el grupo del que eres propietario provocará la eliminación del grupo.',
        'groupPage.warning':
            'Sólo puedes ser miembro de un grupo a la vez, entonces si te unes a otro grupo automáticamente dejas el grupo donde estabas. ¡Si eres el dueño de un grupo y lo dejas automáticamente eliminas el grupo!',
        'groupPage.playButton': 'Juega',
        'groupPage.joinButton': 'Únete a este grupo y juega',
        'groupPage.joinedButton': '¡Te has unido al grupo!',
        'presentedBy.label': 'Presentado por',
        'createGroupForm.inputLabel': 'Nombre de grupo',
        'createGroupForm.buttonText': 'Crear grupo',
        'createGroupForm.cancel': 'Cancelar',
        'createGroupForm.inputPlaceholder': 'Algún nombre de grupo',
        'deleteGroupForm.ownerDefinition': 'Eres el dueño de',
        'deleteGroupForm.memberDefinition': 'Eres un miembro de',
        'deleteGroupForm.ownerButtonText': 'Eliminar grupo',
        'deleteGroupForm.memberButtonText': 'Salir del grupo',
        'copyToClipboardButton.tooltip.copied': 'Copiado',
        'socialTitle.label': 'Compartir el juego',
        'offerCard.status.pending': 'Desbloqueado después del juego',
        'offerCard.status.today': 'Hoy',
        'offerCard.status.tomorrow': 'Mañana',
        'offerCard.status.inDays': '{in} días',
        'offerCard.status.expd': 'Expirada: {when}',
        'offerCard.status.exps': 'Expira: {when}',
        'predictions.blank.label': 'Mi predicción',
        'predictions.blank.average': 'PROM.',
        'predictions.blank.result': 'RESULTADO',
        'predictions.blank.errorGreater': 'El valor máximo es {value}',
        'predictions.blank.errorBelow': 'El valor mínimo es {value}',
        'prediction.option.odds.betToWin': 'Apuesta {bet} para ganar {win}',
        'prediction.option.odds.liveOddsBy':
            'Probabilidades en vivo proporcionadas por {sportsbook}',
        'prediction.option.odds.oddsType.MONEY_LINE': 'Línea de dinero',
        'prediction.option.odds.oddsType.OVER_UNDER': 'Por encima/Por debajo',
        'prediction.option.draw': 'Extraer',
        'prediction.option.nobet': 'Sin apuestas',
        'storageConsentModal.description':
            'Este sitio web almacena datos como las cookies para permitir funciones importantes del sitio, como el análisis, la orientación y la personalización. Puede modificar sus preferencias en cualquier momento o aceptar la configuración por defecto.',
        'storageConsentModal.storagePolicy.link.text':
            'política de almacenamiento de datos',
        'storageConsentModal.necessary.switch.label': 'Estrictamente necesario',
        'storageConsentModal.analytics.switch.label': 'Analítica',
        'storageConsentModal.button.save': 'Guardar',
        'displayName.error':
            'El nombre debe tener entre {min} y {max} caracteres, puede contener letras, números, guiones o guiones bajos con al menos una letra',
        'optIn.marketing.tally.label':
            'Me gustaría recibir futuras comunicaciones de Tally y sus asociados por correo electrónico y acepto la [política de privacidad](https://www.playtally.com/policies/privacy/tally-privacy-policy) que aparece a continuación',
        'error.generic': 'Se ha producido un error',
    },
} as const;
