import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import ServerApi, {
    EventFeedResponse,
    FeedPrediction,
    FeedPredictionAnswerState,
    FeedLeaderboardEntry,
} from 'src/services/ServerApi';
import Logger from 'src/util/Logger';
import { Color } from 'src/styles/Constants';
import BlazersTvRow from './BlazersTvRow';
import bgImage from 'src/images/icons/blazers-pattern@5x.png';
import logo from 'src/images/icons/TrailBlazers_Logo.png';

interface Props {
    path: string;
}

interface State {
    loading: boolean;
    eventFeedResponse?: EventFeedResponse;
}

const TITLE = 'Beat The Buzzer';
const ACTION_URL = 'play now: trailblazers.com/buzzer';
const UNITED_FONT = 'United Sans Cond';
const MAX_PLAYERS = 5;
export const BLAZERS_RED = '#c8102e';
export const BLAZERS_LIGHT_GRAY = '#dcddde';
export const RANK_WIDTH = '125px';
export const POINTS_CORRECT_WIDTH = '260px';
export const MARGIN_RIGHT = '30px';

export default class TVBroadcastView extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            eventFeedResponse: undefined,
            loading: true,
        };

        const { path } = this.props;

        const eventId = path.split('/').pop();
        if (!eventId) {
            return;
        }
        this.refreshEventFeed(eventId);
        document.title = TITLE;
        document.body.style.backgroundColor = Color.T31_BLACK;

        setInterval(() => {
            this.refreshEventFeed(eventId);
        }, 10000);
    }

    // TODO: Should this automatically refresh or just refresh on load?
    // Safer to do on load in case a bad thing happens on refresh.
    private refreshEventFeed = async (eventId: string) => {
        try {
            const eventFeedResponse = (await ServerApi.getFeed(
                eventId,
            )) as EventFeedResponse;
            this.setState({
                eventFeedResponse,
                loading: false,
            });
        } catch (error) {
            Logger.error(`Error refreshing event feed: ${error}`);
        }
    };

    private getValidatedPredictionCount = (
        predictions: FeedPrediction[],
    ): number => {
        const answeredPredictions = predictions.filter(
            (prediction) =>
                prediction.state === FeedPredictionAnswerState.ANSWERED,
        );

        return answeredPredictions.length;
    };

    private renderHeader = () => {
        const { eventFeedResponse } = this.state;
        if (!eventFeedResponse) {
            return null;
        }
        const { name } = eventFeedResponse;

        return (
            <HeaderContainer>
                <GameInfoContainer>
                    <LogoImage />
                    <Title>{TITLE}</Title>
                    <Divider />
                    <GameName>{name}</GameName>
                </GameInfoContainer>
                <Subtitle>{ACTION_URL}</Subtitle>
            </HeaderContainer>
        );
    };

    private renderTableHeader = () => {
        return (
            <StatHeaderRow>
                <StatColumnContainer width={RANK_WIDTH}>
                    <StatName>
                        <FormattedMessage id="tvBroadcastView.rank" />
                    </StatName>
                </StatColumnContainer>
                <StatColumnContainer flexVal={2}>
                    <StatName>
                        <FormattedMessage id="tvBroadcastView.player" />
                    </StatName>
                </StatColumnContainer>
                <StatColumnContainer width={POINTS_CORRECT_WIDTH}>
                    <StatName>
                        <FormattedMessage id="tvBroadcastView.points" />
                    </StatName>
                </StatColumnContainer>
                <StatColumnContainer
                    width={POINTS_CORRECT_WIDTH}
                    noRightMargin={true}
                >
                    <StatName>
                        <FormattedMessage id="tvBroadcastView.correct" />
                    </StatName>
                </StatColumnContainer>
            </StatHeaderRow>
        );
    };

    private renderLeaderboard = (entries: FeedLeaderboardEntry[]) => {
        const { eventFeedResponse } = this.state;

        if (!eventFeedResponse) {
            return null;
        }

        const { predictions } = eventFeedResponse;
        const validatedQuestionCount =
            this.getValidatedPredictionCount(predictions);

        let playerCount = 0;
        const topFive = [];

        for (const entry of entries) {
            if (playerCount >= MAX_PLAYERS) {
                break;
            }
            topFive.push(entry);
            playerCount += entry.playerCount;
        }

        return topFive.map((entry, index: number) => (
            <BlazersTvRow
                entry={entry}
                key={index}
                validatedQuestionCount={validatedQuestionCount}
            />
        ));
    };

    public render() {
        const { eventFeedResponse } = this.state;
        if (!eventFeedResponse) {
            return null;
        }
        const { leaderboard } = eventFeedResponse;

        return (
            <PageContainer>
                <ContentContainer>
                    {this.renderHeader()}
                    {this.renderTableHeader()}
                    {this.renderLeaderboard(leaderboard)}
                </ContentContainer>
            </PageContainer>
        );
    }
}

const GameInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const GameName = styled.div`
    display: flex;
    align-self: center;
    font-family: ${UNITED_FONT};
    font-size: 32px;
    letter-spacing: 3.2px;
    line-height: 36px;
    text-transform: upperCase;
    color: ${Color.P1_WHITE};
    width: ${POINTS_CORRECT_WIDTH};
`;

const Divider = styled.div`
    width: 60px;
    align-self: center;
    height: 1px;
    background-color: ${BLAZERS_LIGHT_GRAY};
    margin: 0 55px;
`;

const PageContainer = styled.div`
    background-image: url(${bgImage});
    background-position: 100% 60px;
    background-size: 30px;
    background-repeat: no-repeat;
    display: flex;
    font-family: ${UNITED_FONT};
    justify-content: center;
    padding: 0 0 60px 0;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 1200px;
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 120px 0 80px 0;
`;

const LogoImage = styled.div`
    background-image: url(${logo});
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 6px ${MARGIN_RIGHT} 0 0;
    height: 86px;
    width: ${RANK_WIDTH};
`;

const Title = styled.div`
    color: ${Color.P1_WHITE};
    font-family: 'United Sans Cond';
    font-size: 118px;
    line-height: 100px;
    font-weight: bold;
    letter-spacing: 0.44px;
    text-transform: upperCase;
`;

const Subtitle = styled.p`
    color: ${Color.P1_WHITE};
    font-family: ${UNITED_FONT};
    font-weight: bold;
    font-size: 42px;
    letter-spacing: 5px;
    margin: 10px 0 0 158px;
    text-transform: upperCase;
`;

const StatHeaderRow = styled.div`
    display: flex;
    justify-content: space-between;
    text-align: left;
    width: 100%;
`;

const StatColumnContainer = styled.div<{
    flexVal?: number;
    width?: string;
    noRightMargin?: boolean;
}>`
    border: 2px solid ${BLAZERS_RED};
    display: flex;
    flex-direction: column;
    height: 58px;
    justify-content: center;
    min-width: ${(props) => props.width};
    flex: ${(props) => props.flexVal};
    margin-right: ${(props) => (props.noRightMargin ? 0 : MARGIN_RIGHT)};
    text-align: left;
`;

const StatName = styled.p`
    color: ${Color.P1_WHITE};
    font-size: 42px;
    font-weight: bold;
    letter-spacing: 5px;
    padding: 18px 0px 18px 14px;
    text-transform: uppercase;
`;
