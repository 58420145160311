import React from 'react';
import styled from '@emotion/styled';

import { Color } from 'src/styles/Constants';

export const ErrorMessage = ({ error }: { error: null | Error }) =>
    error ? <ErrorStyled>{`Error: ${error.message}`}</ErrorStyled> : null;

const ErrorStyled = styled.div`
    font-size: 14px;
    color: red;
    margin-bottom: 8px;
`;

export const SectionHeaderText = styled.h1<{ marginTop?: string }>`
    border-bottom: 1px solid ${Color.P6_ELECTRIC_BLUE};
    font-size: 27px;
    font-weight: 300;
    letter-spacing: -0.45px;
    line-height: 32px;
    margin-bottom: 10px;
    margin-top: ${(props) => props.marginTop || '0'};
    text-align: left;
    width: 100%;
`;

export const DescriptionText = styled.p<{
    marginRight?: string;
    marginTop?: string;
}>`
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 18px;
    margin-right: ${(props) => props.marginRight || '0'};
    margin-top: ${(props) => props.marginTop || '0'};
    text-align: left;
`;

export const SubmitGroup = styled.div`
    & *:first-of-type {
        margin-right: 12px;
    }
    margin-top: 16px;
    display: flex;
    align-items: center;
`;
