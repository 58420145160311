import styled from '@emotion/styled';
import React from 'react';
import { BingoProperties } from 'src/util/TallyFirestore';
import { PlayingBingo } from './useBingoStateReducer';
import { BINGO_SQUARE_STATE_TO_COLOR, IMAGE_SIZE } from './constants';
import BingoSquare from './BingoSquare';
import { Timestamp } from 'src/services/Firebase';

type Props = {
    gameState: PlayingBingo;
    bingo: BingoProperties;
};

const BINGO_WORD_LETTERS = ['B', 'I', 'N', 'G', 'O'] as const;

type Wins = boolean[];

const scorecardOrderBySize = {
    3: [0, 1, 2],
    5: [0, 1, 2, 3, 4],
} as const;

const getWins = (
    scorecard: { occurredDate: null | Timestamp }[],
    scorecardSize: 3 | 5,
): Wins => {
    let wins: Wins = Array(scorecardSize ** 2).fill(false);

    let majorDiagonalWin = true;
    for (let i = 0; i < scorecardSize; i++) {
        if (scorecard[i * scorecardSize + i].occurredDate === null) {
            majorDiagonalWin = false;
            break;
        }
    }

    if (majorDiagonalWin) {
        for (let i = 0; i < scorecardSize; i++) {
            wins[i * scorecardSize + i] = true;
        }
    }

    let minorDiagonalWin = true;
    for (let i = 0; i < scorecardSize; i++) {
        if (
            scorecard[(scorecardSize - i - 1) * scorecardSize + i]
                .occurredDate === null
        ) {
            minorDiagonalWin = false;
            break;
        }
    }

    if (minorDiagonalWin) {
        for (let i = 0; i < scorecardSize; i++) {
            wins[(scorecardSize - i - 1) * scorecardSize + i] = true;
        }
    }

    let rowWinIndexes = new Set<number>(scorecardOrderBySize[scorecardSize]);
    for (let i = 0; i < scorecardSize; i++) {
        for (let j = 0; j < scorecardSize; j++) {
            if (scorecard[i * scorecardSize + j].occurredDate === null) {
                rowWinIndexes.delete(i);
                break;
            }
        }
    }

    rowWinIndexes.forEach((rowIndex) => {
        for (let i = 0; i < scorecardSize; i++) {
            wins[rowIndex * scorecardSize + i] = true;
        }
    });

    let columnWinIndexes = new Set<number>(scorecardOrderBySize[scorecardSize]);
    for (let i = 0; i < scorecardSize; i++) {
        for (let j = 0; j < scorecardSize; j++) {
            if (scorecard[j * scorecardSize + i].occurredDate === null) {
                columnWinIndexes.delete(i);
                break;
            }
        }
    }

    columnWinIndexes.forEach((columnIndex) => {
        for (let i = 0; i < scorecardSize; i++) {
            wins[i * scorecardSize + columnIndex] = true;
        }
    });

    return wins;
};

const BingoScorecard = ({ gameState, bingo }: Props) => {
    const wins = getWins(gameState.scorecard, bingo.scorecardSize);
    return (
        <BingoGrid scorecardSize={bingo.scorecardSize}>
            {bingo.scorecardSize === 5 &&
                BINGO_WORD_LETTERS.map((letter, id) => (
                    <HeaderText key={id}>{letter}</HeaderText>
                ))}
            {gameState.scorecard.map((bingoSquare, i) => (
                <BingoSquare
                    key={bingoSquare.id}
                    {...bingoSquare}
                    win={wins[i]}
                />
            ))}
        </BingoGrid>
    );
};

const BingoGrid = styled.div<{ scorecardSize: 3 | 5 }>`
    align-self: stretch;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(
        ${(props) => props.scorecardSize},
        min(${IMAGE_SIZE}px, 100% / ${(props) => props.scorecardSize})
    );
    padding-bottom: 20px;
`;

const HeaderText = styled.div`
    text-align: center;
    font-size: 35px;
    letter-spacing: -0.6px;
    line-height: 37px;
    font-weight: 900;
    padding: 5px;
    background-color: ${BINGO_SQUARE_STATE_TO_COLOR.neutral.backgroundColor};
    color: ${BINGO_SQUARE_STATE_TO_COLOR.neutral.color};
`;

export default BingoScorecard;
