import { useEffect, useState } from 'react';
import firebase, {
    DocumentSnapshot,
    QuerySnapshot,
} from 'src/services/Firebase';

function useLiveFirestoreCollection<T>(
    path: string,
    getDataFromSnapshot: (snapshot: DocumentSnapshot) => T | undefined,
    initialState: T[],
) {
    const [state, setState] = useState<T[]>(initialState);

    useEffect(() => {
        const unsubscribe = firebase
            .firestore()
            .collection(path)
            .onSnapshot((snapshot: QuerySnapshot) => {
                const items: T[] = [];
                snapshot.forEach((documentSnapshot: DocumentSnapshot) => {
                    const item = getDataFromSnapshot(documentSnapshot);
                    if (item) {
                        items.push(item);
                    }
                });
                setState(items);
            });

        return unsubscribe;
    }, [path]);

    return state;
}

export default useLiveFirestoreCollection;
