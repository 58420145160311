import { LocaleId } from './LocaleHelpers';

const LOCAL_STORAGE_SELECTED_LANGUAGE_KEY = 'selected-language' as const;

export const isLocaleId = (value: any): value is LocaleId =>
    LocaleId.findIndex((localeId) => localeId === value) !== -1;

export const storePreferredLanguageToLocalStorage = (
    localeId: LocaleId,
): void => {
    localStorage.setItem(LOCAL_STORAGE_SELECTED_LANGUAGE_KEY, localeId);
};

export const getPreferredLanguageFromLocalStorage = (): LocaleId | null => {
    const preferredLanguage = localStorage.getItem(
        LOCAL_STORAGE_SELECTED_LANGUAGE_KEY,
    );

    return isLocaleId(preferredLanguage) ? preferredLanguage : null;
};

export const initialPreferredLanguage = getPreferredLanguageFromLocalStorage();
