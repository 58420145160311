import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import { Color, TextStyles, TextStyleTypes } from 'src/styles/Constants';

import VenueTable from './VenueTable';
import {
    SearchVenueByDistanceResult,
    SearchVenueByNameResult,
} from 'src/services/ServerApi';
import { SearchMode } from '.';

type Props = {
    geoVenueList: SearchVenueByDistanceResult[];
    onJoinGroup: (groupId: string) => void;
    setSearchMode: React.Dispatch<React.SetStateAction<SearchMode>>;
    setGeoVenueList: React.Dispatch<
        React.SetStateAction<SearchVenueByDistanceResult[]>
    >;
    setVenueList: React.Dispatch<
        React.SetStateAction<SearchVenueByNameResult[] | null>
    >;
    pendingGroupJoin: boolean;
};

export default (props: Props) => {
    const {
        geoVenueList,
        onJoinGroup,
        setSearchMode,
        setGeoVenueList,
        setVenueList,
        pendingGroupJoin,
    } = props;
    return (
        <>
            <DescriptionText>
                <FormattedMessage
                    id={'infoCollectionTitle.groupModeDefaultDescription'}
                />
            </DescriptionText>
            <VenueTable
                venues={geoVenueList}
                actionFunction={onJoinGroup}
                pendingGroupJoin={pendingGroupJoin}
            />
            <ButtonContainer>
                <ManualSearchLabel
                    onClick={() => {
                        setSearchMode(SearchMode.manualSearch);
                        setGeoVenueList([]);
                        setVenueList(null);
                    }}
                >
                    <FormattedMessage id="infoCollectionTitle.touchButtonSearchLabel" />
                </ManualSearchLabel>
            </ButtonContainer>
        </>
    );
};

const ButtonContainer = styled.div`
    padding: 0 0 50px 0;
`;

const ManualSearchLabel = styled.a`
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.75px;
    line-height: 18px;
    text-transform: upperCase;
    color: ${Color.T31_BLACK};
    cursor: pointer;
    text-decoration: underline;
`;

const DescriptionText = styled.div`
    margin: 15px 0 15px 0;
    ${TextStyles[TextStyleTypes.P1]}
    text-align: left;
    width: 100%;
`;
