import ApiClient from 'src/services/ApiClient';
import config from 'src/util/Config';
import { LocaleId } from 'src/util/LocaleHelpers';
import { SMSAlertsState } from 'src/util/TallyFirestore';
import { Group, UserGroup, TeamGroup } from 'src/types';

const client = new ApiClient();

const partnerId = config.partnerData.partnerId;
export interface EventFeedResponse {
    // event name: ex: Raptors Vs. Trail Blazers
    name: string;
    groupDetails: GroupDetails;
    leaderboard: FeedLeaderboardEntry[];
    playerCount: number;
    predictions: FeedPrediction[];
    startDate: Date;
    state: string;
    updatedDate: Date;
    specialUsersLeaderboard: SpecialFeedLeaderboardEntry[];
}

export interface FeedPrediction {
    lockDate: string;
    options: Array<{
        correct: boolean;
        text: string;
        usersChoicePercentage: string;
    }>;
    playerCount: number;
    state: string;
    text: string;
}
export interface GroupDetails {
    name: string;
    groupConfig: null | {
        groupQR?: string | null;
    };
}

// If we remove the 'players' property or add 'players' to SpecialFeedLeaderboardEntry adjust 'isNormalEntry'
export interface FeedLeaderboardEntry {
    correctAnswers: number;
    totalQuestionCount?: number;
    // isNormalEntry depends on this so be careful renaming!
    players: string[];
    playerCount: number;
    points: number;
    rank: number;
}

export interface SpecialFeedLeaderboardEntry {
    correctAnswers: number;
    totalQuestionCount?: number;
    displayName: string;
    points: number;
    rank: number;
}

export enum FeedPredictionAnswerState {
    ANSWERED = 'answered',
    LOCKED = 'locked',
    UNLOCKED = 'unlocked',
}

// Returns FeedLeaderBoardEntry if entry has 'players' property, SpecialFeedLeaderboardEntry otherwise
export function isNormalEntry(
    entry: FeedLeaderboardEntry | SpecialFeedLeaderboardEntry,
): entry is FeedLeaderboardEntry {
    return 'players' in entry;
}

export type TriviaQuestionAd = {
    iframeHtml: string;
    headline: string | null;
    disclaimer: string | null;
};

type TextOnlyTriviaOption = {
    id: string;
    text: string;
    imageUrl: null;
};

type ImageOnlyTriviaOption = {
    id: string;
    text: null;
    imageUrl: string;
};

type TextAndImageTriviaOption = {
    id: string;
    text: string;
    imageUrl: string;
};

export type TriviaOption =
    | TextOnlyTriviaOption
    | ImageOnlyTriviaOption
    | TextAndImageTriviaOption;

export type TriviaQuestion<
    TriviaOptionType extends TriviaOption = TriviaOption,
> = {
    id: string;
    text: string;
    options: TriviaOptionType[];
    ad?: TriviaQuestionAd | null;
};

export function isTextOnlyOptionTriviaQuestion(
    question: AssignedTriviaQuestion,
): question is AssignedTriviaQuestion<TextOnlyTriviaOption> {
    return (
        question.options.length > 0 &&
        question.options[0].text !== null &&
        question.options[0].imageUrl === null
    );
}

export function isOptionWithImageTriviaQuestion(
    question: AssignedTriviaQuestion,
): question is AssignedTriviaQuestion<
    ImageOnlyTriviaOption | TextAndImageTriviaOption
> {
    return question.options.length > 0 && question.options[0].imageUrl !== null;
}

type CurrentAssignedTriviaInfo<DateType = Date> = {
    lockDate: DateType;
    answeredDate: null;
};

export type CurrentAssignedTriviaQuestion<
    DateType = Date,
    TriviaOptionType extends TriviaOption = TriviaOption,
> = TriviaQuestion<TriviaOptionType> & CurrentAssignedTriviaInfo<DateType>;

type PastAssignedTriviaInfo<DateType = Date> = {
    selectedOptionId: string | null;
    correctOptionId: string;
    lockDate: DateType;
    answeredDate: DateType;
};

export type PastAssignedTriviaQuestion<
    DateType = Date,
    TriviaOptionType extends TriviaOption = TriviaOption,
> = TriviaQuestion<TriviaOptionType> & PastAssignedTriviaInfo<DateType>;

export type AssignedTriviaQuestion<
    TriviaOptionType extends TriviaOption = TriviaOption,
> =
    | CurrentAssignedTriviaQuestion<Date, TriviaOptionType>
    | PastAssignedTriviaQuestion<Date, TriviaOptionType>;

export type UserTriviaState<DateType = Date> = {
    current?: CurrentAssignedTriviaQuestion<DateType>;
    past: PastAssignedTriviaQuestion<DateType>[];
    numberOfQuestions: number;
};

export type IGameStatus = {
    userId: string;
    eventId: string;
    gamesPlayed: number;
    points: number;
    canPlay: boolean;
};

export type UserAnswerResponse = {
    selectedOptionId: string | null;
    correctOptionId: string;
    points: number;
    answeredDate: Date;
};

export interface GeoSearchParams extends VenueSearchBaseParams {
    lat: number;
    long: number;
}

export interface TextSearchParams extends VenueSearchBaseParams {
    name: string;
}

interface VenueSearchBaseParams {
    partnerId: string;
}

export type GeoOrText<T extends GeoSearchParams | TextSearchParams> =
    T extends GeoSearchParams
        ? SearchVenueByDistanceResult[]
        : SearchVenueByNameResult[];

export type SearchVenueByNameResult = {
    id: string;
    name: string;
    address: string;
    city: string;
    zipCode: string;
};

export type SearchVenueByDistanceResult = SearchVenueByNameResult & {
    distance: number;
};

class ServerApi {
    // trivia
    loadNextTriviaQuestion = async ({
        partnerId,
        eventId,
        languageId,
    }: {
        partnerId: string;
        eventId: string;
        languageId: LocaleId;
    }): Promise<CurrentAssignedTriviaQuestion> => {
        const deserialize = (
            response: CurrentAssignedTriviaQuestion<string>,
        ): CurrentAssignedTriviaQuestion<Date> => {
            return {
                ...response,
                lockDate: new Date(response.lockDate),
            };
        };

        const responseBody = await client.request({
            method: 'PUT',
            path: `trivia/partner/${partnerId}/event/${eventId}/lockNext?language_id=${languageId}`,
            body: {},
        });

        return deserialize(responseBody);
    };

    public assignEventTriviaQuestions = async ({
        partnerId,
        eventId,
        languageId,
    }: {
        partnerId: string;
        eventId: string;
        languageId: LocaleId;
    }): Promise<{
        current?: CurrentAssignedTriviaQuestion<Date>;
        numberOfQuestions: number;
    }> => {
        const responseBody = await client.request({
            method: 'PUT',
            path: `trivia/partner/${partnerId}/event/${eventId}/assign?language_id=${languageId}`,
            body: {},
        });

        if (!responseBody.current) {
            return { numberOfQuestions: responseBody.numberOfQuestions };
        }

        return {
            current: {
                ...responseBody.current,
                lockDate: new Date(responseBody.current.lockDate),
            },
            numberOfQuestions: responseBody.numberOfQuestions,
        };
    };

    public getTriviaState = async ({
        partnerId,
        eventId,
        languageId,
    }: {
        partnerId: string;
        eventId: string;
        languageId: LocaleId;
    }): Promise<UserTriviaState> => {
        const deserialize = (response: any): UserTriviaState<Date> => {
            let current: CurrentAssignedTriviaQuestion<Date> | undefined;
            if (response.current) {
                current = {
                    ...response.current,
                    lockDate: new Date(response.current.lockDate),
                };
            }
            return {
                current,
                numberOfQuestions: response.numberOfQuestions,
                past: response.past.map((question: any) => ({
                    id: question.id,
                    text: question.text,
                    options: question.options,
                    selectedOptionId: question.selectedOptionId,
                    correctOptionId: question.correctOptionId,
                    lockDate: new Date(question.lockDate),
                    answeredDate: new Date(question.answeredDate),
                    points: question.points,
                })),
            };
        };

        const responseBody = await client.request({
            method: 'GET',
            path: `trivia/partner/${partnerId}/event/${eventId}?language_id=${languageId}`,
        });

        return deserialize(responseBody);
    };

    public answerTriviaQuestion = async ({
        partnerId,
        eventId,
        questionId,
        selectedOptionId,
    }: {
        partnerId: string;
        eventId: string;
        questionId: string;
        selectedOptionId: string | null;
    }): Promise<UserAnswerResponse> => {
        const responseBody = await client.request({
            method: 'PUT',
            path: `trivia/partner/${partnerId}/event/${eventId}/question/${questionId}/answer`,
            body: {
                selectedOptionId,
            },
        });

        return {
            selectedOptionId: responseBody.selectedOptionId,
            answeredDate: new Date(responseBody.answeredDate),
            correctOptionId: responseBody.correctOptionId,
            points: responseBody.points,
        };
    };

    //#region igame
    public getIgameStatus = async ({
        eventId,
        partnerId,
    }: {
        eventId: string;
        partnerId: string;
    }): Promise<IGameStatus> => {
        const responseBody = await client.request({
            method: 'GET',
            path: `igame/partner/${partnerId}/event/${eventId}`,
        });
        return responseBody;
    };

    public submitIGameResult = async ({
        points,
        eventId,
        partnerId,
    }: {
        points: number;
        eventId: string;
        partnerId: string;
    }): Promise<IGameStatus> => {
        const responseBody = await client.request({
            body: {
                points,
            },
            method: 'POST',
            path: `igame/partner/${partnerId}/event/${eventId}/submit-result`,
        });
        return responseBody;
    };
    //#endregion igame

    //#region bingo
    public generateScorecard = async ({
        partnerId,
        eventId,
    }: {
        partnerId: string;
        eventId: string;
    }): Promise<void> => {
        await client.request({
            body: {},
            method: 'POST',
            path: `bingo/partner/${partnerId}/event/${eventId}/generateScorecard`,
        });
    };
    //#endregion bingo

    //#region groups
    public getUserGroups = (
        partnerId: string,
        iterationId: string,
    ): Promise<Group[]> => {
        return client.request({
            method: 'GET',
            path: `partners/${partnerId}/user/groups?iteration_id=${iterationId}`,
        });
    };

    public getGroup = (id: string): Promise<Group> => {
        return client.request({
            method: 'GET',
            path: `partners/${partnerId}/groups/${id}`,
        });
    };

    public createGroup = ({
        partnerId,
        name,
    }: {
        partnerId: string;
        name: string;
    }): Promise<UserGroup> => {
        return client.request({
            body: {
                name,
            },
            method: 'POST',
            path: `partners/${partnerId}/groups`,
        });
    };

    public deleteGroup = ({
        partnerId,
        id,
    }: {
        partnerId: string;
        id: string;
    }): Promise<void> => {
        return client.request({
            method: 'DELETE',
            path: `partners/${partnerId}/groups/${id}`,
        });
    };

    public joinGroup = ({
        partnerId,
        id,
    }: {
        partnerId: string;
        id: string;
    }): Promise<Group> => {
        return client.request({
            method: 'POST',
            path: `partners/${partnerId}/groups/${id}/join`,
            body: {},
        });
    };
    //#endregion groups

    public searchVenues<T extends GeoSearchParams | TextSearchParams>(
        geoOrText: T,
    ): Promise<GeoOrText<T>> {
        const { partnerId, ...rest } = geoOrText;
        return client.request({
            method: 'GET',
            path: `partners/${partnerId}/venues/search`,
            query: rest,
        }) as Promise<GeoOrText<T>>;
    }
    public getTeams(iterationId: string): Promise<TeamGroup[]> {
        return client.request({
            method: 'GET',
            path: `partner/${partnerId}/iterations/${iterationId}/teams`,
        });
    }

    // Attempt to initialize the user
    public initUser = async (parameters: {
        displayName: string;
        email?: string;
        phoneNumber?: string;
        eventId?: string;
        preferredLanguage: LocaleId;
        additionalDataCollection?: string;
        lastIterationOptIn?: string;
    }) => {
        const {
            displayName,
            email,
            phoneNumber,
            eventId,
            preferredLanguage,
            additionalDataCollection,
            lastIterationOptIn,
        } = parameters;

        return client.request({
            body: {
                displayName,
                email,
                phoneNumber,
                eventId,
                partnerId,
                preferredLanguage,
                additionalDataCollection,
                lastIterationOptIn,
            },
            method: 'POST',
            path: 'users/initialize',
        });
    };

    public initOktaUser = async (parameters: {
        oktaToken: string;
        eventId?: string;
        preferredLanguage: LocaleId;
    }) => {
        const { eventId, preferredLanguage, oktaToken } = parameters;

        return client.request({
            body: {
                oktaToken,
                eventId,
                partnerId,
                preferredLanguage,
            },
            method: 'POST',
            path: 'users/initializeOktaUser',
        });
    };

    public initOktaUserById = async (parameters: {
        oktaUID: string;
        displayName?: string;
        eventId?: string;
        preferredLanguage: LocaleId;
    }) => {
        const { eventId, preferredLanguage, oktaUID, displayName } = parameters;

        return client.request({
            body: {
                oktaUID,
                displayName,
                eventId,
                partnerId,
                preferredLanguage,
            },
            method: 'POST',
            path: 'users/initializeOktaUserId',
        });
    };

    public submitPrediction = async (parameters: {
        predictionId: string;
        optionId: string;
        value: boolean | string;
    }) => {
        const { predictionId, optionId, value } = parameters;

        return client.request({
            body: {
                [optionId]: value,
            },
            method: 'PUT',
            path: `predictions/${predictionId}`,
        });
    };

    public submitProgressivePoll = async (parameters: {
        pollId: string;
        optionId: string;
        eventId: string;
    }) => {
        const { pollId, optionId, eventId } = parameters;

        return client.request({
            body: {
                optionId,
                partnerId,
                eventId,
            },
            method: 'PUT',
            path: `progressive-polls/${pollId}`,
        });
    };

    public updateUser = async (parameters: {
        email?: string;
        marketingOptIn?: boolean;
        phoneNumber?: string;
        smsAlertsState?: SMSAlertsState;
        smsMarketingOptIn?: boolean;
        optionalOptIn?: boolean;
        tallyOptIn?: boolean;
        lastIterationOptIn?: string;
        preferredLanguage?: LocaleId;
    }) => {
        const {
            email,
            phoneNumber,
            marketingOptIn,
            smsAlertsState,
            smsMarketingOptIn,
            optionalOptIn,
            lastIterationOptIn,
            preferredLanguage,
            tallyOptIn,
        } = parameters;

        return client.request({
            body: {
                email,
                marketingOptIn,
                partnerId,
                phoneNumber,
                smsAlertsState,
                smsMarketingOptIn,
                optionalOptIn,
                lastIterationOptIn,
                preferredLanguage,
                tallyOptIn,
            },
            method: 'PATCH',
            path: 'users',
        });
    };

    public getFeed = async (
        eventId: string,
        groupId?: string,
        commaSeparatedDisplayNames?: string,
    ) => {
        const response = await client.request({
            baseUrl: config.apiFeedUrl,
            method: 'GET',
            path: `events/${eventId}${
                groupId ? '/groups/' + groupId : ''
            }/feed`,
            query: {
                'display-names': commaSeparatedDisplayNames,
            },
        });
        return response as EventFeedResponse;
    };

    public sendEmailForMarketing = (eventId: string, email: string) => {
        return client.request({
            body: {
                email,
            },
            method: 'PUT',
            path: `events/${eventId}/emails`,
        });
    };

    public sendVerifySMS = (
        partnerId: string,
        phoneNumber: string,
    ): Promise<{
        codeExpireSeconds: string; // parsable int
        resendCodeSeconds: string; // parsable int
        verifyToken: string;
    }> => {
        return client.request({
            body: {
                phoneNumber,
            },
            method: 'POST',
            path: `partners/${partnerId}/auth/sendSmsCode`,
        });
    };

    public verifySMSCode = (
        partnerId: string,
        phoneNumber: string,
        verifyToken: string,
        code: string,
    ) => {
        return client.request({
            body: {
                code,
                phoneNumber,
                verifyToken,
            },
            method: 'POST',
            path: `partners/${partnerId}/auth/verifySmsCode`,
        });
    };

    public subscribeToSmsAlerts = () =>
        this.updateUser({
            smsAlertsState: SMSAlertsState.OPTED_IN,
        });
}

export default new ServerApi();
