import { DocumentReference, Timestamp } from 'src/services/Firebase';
import {
    Offer,
    PredictionSponsorshipUnit,
    EventSponsorshipUnit,
    IsoDateString,
    PrizeRedemptionSponsorshipUnit,
    isPrizeRedemptionSponsorshipUnit,
} from './SponsorshipTypes';
import { LocaleId } from 'src/util/LocaleHelpers';
import { GroupType } from 'src/types';
import { WithNotNullProperty } from 'src/utilityTypes';
import { TranslatableConfigMessage } from './ConfigTypes';

export enum Collection {
    answers = 'answers',
    bingoScorecard = 'bingoScorecard',
    bingoSquares = 'bingoSquares',
    categories = 'categories',
    events = 'events',
    iterations = 'iterations',
    leaderboard = 'leaderboard',
    groupLeaderboards = 'groupLeaderboards',
    intraGroupLeaderboards = 'intraGroupLeaderboards',
    teams = 'teams',
    venues = 'venues',
    userGroups = 'userGroups',
    parties = 'parties',
    partners = 'partners',
    predictions = 'predictions',
    privateData = 'privateData',
    sponsors = 'sponsors',
    suggestedFollows = 'suggestedFollows',
    summaries = 'summaries',
    topPlayers = 'topPlayers',
    users = 'users',
    winners = 'winners',
    progressivePolls = 'progressivePoll',
    progressivePollIterations = 'progressivePollIterations',
}

export enum Documents {
    userData = 'userData',
    contactInfo = 'contactInfo',
}

export enum EventState {
    unpublished = 'unpublished',
    unlocked = 'unlocked',
    live = 'live',
    pending = 'pending',
    final = 'final',
}

export interface IterationReference {
    displayName: string;
    iteration: string;
}

export enum OddsType {
    moneyLine = 'MONEY_LINE',
    overUnder = 'OVER_UNDER',
}

export enum PredictionLockType {
    manual = 'MANUAL',
    autoamtic = 'AUTOMATIC',
}

export enum PredictionAdPlacement {
    inline = 'inline',
    above = 'above',
    below = 'below',
}

export enum PredictionAdRenderTime {
    rightAway = 'right away',
    afterPrediction = 'after prediction',
}

export enum AdSizingPolicy {
    preserveAspectRatio = 'preserve aspect ratio',
    auto = 'auto',
    cutOff = 'cut off',
    readMore = 'read more',
}

export type AdBase = {
    iframeHtml: string;
    headline: string | null;
    disclaimer: string | null;
};

export type ServerAd = AdBase;

// Trivia Ad is a presrveAspectRatio ad
export type TriviaInterstitialAd = {
    duration: number;
} & AdBase;

export type Ad = AdBase & {
    sizingPolicy: AdSizingPolicy;
    cutOffHeight: number;
};

export type PredictionAd = {
    iframeHtml: string | null;
    headline: string | null;
    disclaimer: string | null;
    sizingPolicy: AdSizingPolicy;
    cutOffHeight: number;
};

interface AdInfo {
    adEnabled: boolean;
    adIframeHtml: string | null;
    adHeadline: string | null;
    adDisclaimer: string | null;
    adSizingPolicy: AdSizingPolicy;
    adCutOffHeight: number;
}

export type EventAd = AdInfo & {
    adPinned: boolean;
};

export interface OddsModel {
    sportsbook: string;
    oddType: OddsType;
    oddsAmerican: number[];
}

export function containsPrizeRedemptionSponsorshipUnit(
    prediction: PredictionModel,
): prediction is PredictionModel<PrizeRedemptionSponsorshipUnit> {
    return (
        !!prediction.sponsorship &&
        isPrizeRedemptionSponsorshipUnit(prediction.sponsorship)
    );
}

export type OptionModel = {
    answer?: string;
    averagePrediction?: string;
    hideOption?: boolean;
    id: string;
    pointValue?: number;
    selectedCount?: number;
    text: string;
    correct?: boolean;
};

export type PollOptionModel = Omit<OptionModel, 'correct'> & {
    correct: true;
};

export interface EntityTranslationOptionModel {
    text: string;
}

export type PredictionEntityTranslation = {
    languageCodeId: LocaleId;
    // if undefined, the default language one is reused
    adIframeHtml?: string | null;
    adDisclaimer: string | null;
    adHeadline: string | null;
    lockDescription?: string;
    options: EntityTranslationOptionModel[];

    // detailsText and answerExplanation are stored in Firestore to calculate subText
    detailsText: string | null;
    answerExplanation?: string | null;

    subText?: string | null;

    text: string;
};

export type PollQuestionModel<SponsorshipUnit = PredictionSponsorshipUnit> = {
    id: string;
    lockDate: Timestamp;
    number: number;
    type: 'POLL';
    text: string;
    subText?: string | null;
    path: string;
    pointValue: number;
    releaseMilestone?: string;
    answerMilestone: string;
    resolved: boolean;
    options: PollOptionModel[];
    // we only use visible ones
    // visible?: boolean;
    sponsorship?: SponsorshipUnit;
    lockDescription?: string;
    lockType: PredictionLockType;
    // inline ad info
    adEnabled: boolean;
    adPlacement: PredictionAdPlacement;
    adIframeHtml: string | null;
    adHeadline: string | null;
    adDisclaimer: string | null;
    adRenderTime: PredictionAdRenderTime;
    adSizingPolicy: AdSizingPolicy;
    adCutOffHeight: number;
    // odds
    odds?: OddsModel;
};

export type ActualPredictionModelBase<
    SponsorshipUnit = PredictionSponsorshipUnit,
> = {
    id: string;
    lockDate: Timestamp;
    resolvedDate?: Timestamp;
    number: number;
    text: string;
    subText?: string | null;
    path: string;
    pointValue: number;
    releaseMilestone?: string;
    answerMilestone: string;
    resolved: boolean;
    options: OptionModel[];
    // we only use visible ones
    // visible?: boolean;
    sponsorship?: SponsorshipUnit;
    lockDescription?: string;
    lockType: PredictionLockType;
    // inline ad info
    adEnabled: boolean;
    adPlacement: PredictionAdPlacement;
    adIframeHtml: string | null;
    adHeadline: string | null;
    adDisclaimer: string | null;
    adRenderTime: PredictionAdRenderTime;
    adSizingPolicy: AdSizingPolicy;
    adCutOffHeight: number;
    // odds
    odds?: OddsModel;
};

export type ActualPredictionModel<SponsorshipUnit = PredictionSponsorshipUnit> =
    ActualPredictionModelBase<SponsorshipUnit> &
        (
            | {
                  type: 'MULTIPLE_CHOICE';
                  allOrNothing: boolean;
                  hideOptionMetrics: boolean;
              }
            | {
                  type: 'FILL_IN_THE_BLANK' | 'BET_SIM';
              }
        );

export type PredictionModel<SponsorshipUnit = PredictionSponsorshipUnit> =
    | PollQuestionModel<SponsorshipUnit>
    | ActualPredictionModel<SponsorshipUnit>;

export type PredictionType = PredictionModel['type'];

type PredictionLanguageInfo = {
    defaultLanguageId?: LocaleId;
    entityTranslations?: PredictionEntityTranslation[];
};

type TranslatablePollQuestionModel = PollQuestionModel & PredictionLanguageInfo;

type TranslatableActualPredictionModel = ActualPredictionModel &
    PredictionLanguageInfo;

// export type TranslatablePredictionModel =
//     TranslatablePollQuestionModel | TranslatableActualPredictionModel;

export type TranslatablePredictionModel = (
    | PollQuestionModel
    | ActualPredictionModel
) &
    PredictionLanguageInfo;

type CategoryBase<TDate> = {
    id: string;
    name: string;
    reportingName: string;
    shortName: string;
    slug: string;
    description: string;
    emoji?: string;
    displayOrder: number;
    backgroundImageUrl: string;
    startDate: TDate;
    endDate: TDate;
    iterationsToShow: IterationReference[];
    path: string;
    eventStateDescriptions?: {
        final?: string;
        live?: string;
        pending?: string;
        unlocked?: string;
    };
    upcomingGamesDescription?: string;
    showCountdown?: boolean;
    suppressDeadDayUi?: boolean;
    disclaimerMarkdown?: string;
    visible: boolean;
    eventMilestones?: string[];
};

export type SingleEventCategory<TDate = Timestamp> = CategoryBase<TDate> & {
    primaryEvent: string;
};

export type MultiEventCategory<TDate = Timestamp> = CategoryBase<TDate> & {
    primaryEvent: null;
};

export type CategoryModel<TDate = Timestamp> =
    | SingleEventCategory<TDate>
    | MultiEventCategory<TDate>;

export type IterationGroupModeTranslationBase = {
    generalLeaderboardName?: string;
    generalLeaderboardImageUrl?: string;
};

export type IterationGroupModeUserGroupTranslation =
    IterationGroupModeTranslationBase & {
        userGroupImageUrl?: string;
    };

export type IterationGroupModeTeamUserGroupTranslation =
    IterationGroupModeTranslationBase & {
        interGroupLeaderboardName?: string;
        interGroupLeaderboardImageUrl?: string;
        userGroupImageUrl?: string;
    };

export type IterationGroupModeTeamTranslation =
    IterationGroupModeTranslationBase & {
        interGroupLeaderboardName?: string;
        interGroupLeaderboardImageUrl?: string;
    };

export type IterationGroupModeVenueTranslation =
    IterationGroupModeTranslationBase;

export type IterationGroupModeDisabledTranslation =
    IterationGroupModeTranslationBase;

export type IterationGroupModeTranslation =
    | IterationGroupModeUserGroupTranslation
    | IterationGroupModeTeamUserGroupTranslation
    | IterationGroupModeTeamTranslation
    | IterationGroupModeVenueTranslation
    | IterationGroupModeDisabledTranslation;

export interface IterationEntityTranslation {
    languageCodeId: LocaleId;

    infoModalBody?: string;
    stayTunedModalHeader?: string;
    stayTunedModalBody?: string;

    tournamentHeadline?: string;
    tournamentSubheadline?: string;
    tournamentEditorial?: string;

    groupModeProperties?: IterationGroupModeTranslation;

    socialShare?: SocialShare;
}

export type IterationGroupModeBase = {
    generalLeaderboardName?: string;
    generalLeaderboardImageUrl: string;
};

export type IterationGroupModeUserGroup = IterationGroupModeBase & {
    userGroupImageUrl: string;
};

export type IterationGroupModeTeamUserGroup = IterationGroupModeBase & {
    interGroupLeaderboardName: string;
    interGroupLeaderboardImageUrl: string;
    userGroupImageUrl: string;
};

export type IterationGroupModeTeam = IterationGroupModeBase & {
    interGroupLeaderboardName: string;
    interGroupLeaderboardImageUrl: string;
};

export type IterationGroupModeVenue = IterationGroupModeBase;

export type IterationGroupModeDisabled = IterationGroupModeBase;

export type IterationGroupModeAndProps =
    | {
          groupMode: null;
          groupModeProperties: IterationGroupModeDisabled;
      }
    | {
          groupMode: 'USER_GROUP';
          groupModeProperties: IterationGroupModeUserGroup;
      }
    | {
          groupMode: 'TEAM,USER_GROUP';
          groupModeProperties: IterationGroupModeTeamUserGroup;
      }
    | {
          groupMode: 'VENUE';
          groupModeProperties: IterationGroupModeVenue;
      }
    | {
          groupMode: 'TEAM';
          groupModeProperties: IterationGroupModeTeam;
      };

export type IterationBase<TDate> = {
    id: string;
    path: string;
    categoryId: string;
    name: string;
    slug: string;
    startDate?: TDate;
    endDate?: TDate;

    infoModalBody?: string;
    stayTunedModalHeader?: string;
    stayTunedModalBody?: string;

    tournamentEnabled: boolean;
    tournamentHeadline?: string;
    tournamentSubheadline?: string;
    tournamentEditorial?: string;

    prizes?: string[];

    socialShare?: SocialShare;
};

export type IterationWithTranslationsBase<TDate> = IterationBase<TDate> & {
    defaultLanguageId?: LocaleId;
    entityTranslations?: IterationEntityTranslation[];
};

export type Iteration<TDate = Timestamp> =
    IterationWithTranslationsBase<TDate> & IterationGroupModeAndProps;

export interface EventAssets {
    backgroundImageUrl: string;
}

export interface Team {
    // See the following doc for info on the different name values: https://goo.gl/aQKFKr

    // Shared
    score?: number;
    shortName: string; // Raiders or N'western
    name: string; // Raiders or Northwestern
    abbreviation?: string; // OAK or NW

    // NFL only
    city?: string; // Oakland

    // NCAA Only
    mascot?: string; // Wildcats
    school?: string; // Northwestern
}

export type NormalLeaderboardEntry = {
    uid: string;
    displayName: string;
    isPro?: boolean;
    correctAnswers?: number;
    totalQuestionCount?: number;
    points: number;
    rank: number;
};

export type PartialLeaderboardEntry = {
    uid: string;
    displayName: string;
    isPro?: boolean;
    correctAnswers?: number;
    totalQuestionCount?: number;
    points: number;
    // rank is missing in a trivia game if the full leaderboard isn't published yet
    rank: null;
};

export type LeaderboardEntry = NormalLeaderboardEntry | PartialLeaderboardEntry;

export type FullLeaderboard = {
    leaderboardEntry?: LeaderboardEntry;
    leaderboardSummary?: SummarizedLeaderboard;
};

export type GroupRank = {
    groupId: string;
    groupName: string;
    groupType: GroupType;
    userRank: number;
};

export type LeaderboardEntryWithGroupRanks = LeaderboardEntry & {
    groupRanks?: GroupRank[];
};

export const getIntraGroupLeaderboardEntry = (
    groupType: GroupType,
    leaderboardEntry?: LeaderboardEntryWithGroupRanks,
): LeaderboardEntry | undefined => {
    if (!leaderboardEntry) {
        return undefined;
    }

    const groupRank = leaderboardEntry.groupRanks?.find(
        (groupRank) => groupRank.groupType === groupType,
    );
    if (!groupRank) {
        return undefined;
    }
    return {
        uid: leaderboardEntry.uid,
        displayName: leaderboardEntry.displayName,
        correctAnswers: leaderboardEntry.correctAnswers,
        totalQuestionCount: leaderboardEntry.totalQuestionCount,
        points: leaderboardEntry.points,
        rank: groupRank.userRank,
    };
};

export type GeneralLeaderboardWithGroups = {
    leaderboardEntry?: LeaderboardEntryWithGroupRanks;
    leaderboardSummary?: SummarizedLeaderboard;
};

export interface SummarizedLeaderboard {
    playersCount: number;
    // ATODO: is lastPublishedDate used? Is it stored in firestore?
    lastPublishedDate: Timestamp;
    ranks: SummarizedLeaderboardRankEntry[];
}

export interface SummarizedLeaderboardRankEntry {
    points: number;
    rank: number;
    totalCount: number;
    players: SummarizedLeaderboardPlayerEntry[];
}

export interface SummarizedLeaderboardPlayerEntry {
    uid: string;
    displayName: string;
    isPro?: boolean;
    correctAnswers?: number;
    totalQuestionCount?: number;
    imageUrl?: string | null;
}

export interface SummarizedLeaderboardProRankEntry {
    points: number;
    rank: number;
    totalCount: number;
    players: SummarizedLeaderboardProPlayerEntry[];
}

export interface SummarizedLeaderboardProPlayerEntry {
    uid: string;
    displayName: string;
    isPro: true;
    correctAnswers: number;
    totalQuestionCount?: number;
    points: number;
    rank: number;
}

export type KeysEnum<T> = { [P in keyof Required<T>]?: true };

export type TriviaAds = {
    // zero based index of the question to show ad after
    [index: number]: { [key in LocaleId]: TriviaInterstitialAd };
};

type BaseTriviaGameProperties = {
    numberOfQuestions: number;

    ads?: TriviaAds;
};

type CountdownDecreasingPoints = BaseTriviaGameProperties & {
    mode: 'pointsCountdown';
    pointsPerQuestion: number;
    secondsPerQuestion: number;
};

type CountdownFullPoints = BaseTriviaGameProperties & {
    mode: 'countdownFullPoints';
    pointsPerQuestion: number;
    secondsPerQuestion: number;
};

type NoCountdownFullPoints = BaseTriviaGameProperties & {
    mode: 'noCountdownFullPoints';
    pointsPerQuestion: number;
};

type CountdownNoPoints = BaseTriviaGameProperties & {
    mode: 'countdownNoPoints';
    secondsPerQuestion: number;
};

type NoCountdownNoPoints = BaseTriviaGameProperties & {
    mode: 'noCountdownNoPoints';
};

export type TriviaGameProperties =
    | CountdownDecreasingPoints
    | CountdownFullPoints
    | NoCountdownFullPoints
    | CountdownNoPoints
    | NoCountdownNoPoints;

export function isNoPointsTriviaGame(
    trivia: TriviaGameProperties,
): trivia is CountdownNoPoints | NoCountdownNoPoints {
    return (
        trivia.mode === 'countdownNoPoints' ||
        trivia.mode === 'noCountdownNoPoints'
    );
}

export function isNoCountdownTriviaGame(
    trivia: TriviaGameProperties,
): trivia is NoCountdownFullPoints | NoCountdownNoPoints {
    return (
        trivia.mode === 'noCountdownFullPoints' ||
        trivia.mode === 'noCountdownNoPoints'
    );
}

export type ProgressivePollOptionModel = { id: string; text: string };

export type ProgressivePollModel = {
    detailsText: string;
    id: string;
    number: number;
    options: ProgressivePollOptionModel[];
    text: string;
};

export type ProgressivePollProperties = {
    pointsPerPoll: number;
    desiredPollPlaces: number[];
};

export type ProgressivePollAnswer = {
    pollId: string;
    optionId: string;
    eventId: string;
};

export type UIEventConfig = {
    backgroundColor: string;
    backgroundImgUrl?: string;
    backgroundDesktopImgUrl?: string;
    textBlockBackgroundOpacity?: number;
    welcomeImgUrl?: string;
    welcomeText?: TranslatableConfigMessage;
    gameOverMessage?: string;
    backgroundBtmAlign?: boolean;
    textColor: string;
    imageDisclaimer?: string;
};

type EventBase<TDate> = EventAd & {
    id: string;
    path: string;
    shortName: string;
    shareName: string;

    state: EventState;
    name: string;
    slug: string;
    description: string | null;
    endDate: TDate | null;
    startDate: TDate | null;
    prizes: string[];
    prizeDetailsMarkdown: string | null;
    playerCount: number;
    visible: boolean;
    winners?: LeaderboardEntry[];
    sponsorship?: EventSponsorshipUnit;

    eventImageUrl: string | null;
    socialShare?: SocialShare;

    omitFromIterationLeaderboards?: boolean;

    lastPublishedDate: TDate;

    ui: UIEventConfig | null;
};

export type PredictionEventModel<TDate = Timestamp> = EventBase<TDate> & {
    type: 'PREDICTION';
    actualStartDate: TDate | null;
    progressivePolls?: ProgressivePollProperties;
};

export type TriviaEventModel<TDate = Timestamp> = EventBase<TDate> & {
    type: 'TRIVIA';
    trivia: TriviaGameProperties;
};

type IGamePropertiesBase = {
    iframeUrl: string;
    rulesMarkdown?: string;
    // natural number
    numberOfTries: number | 'infinity';
};

type NoPointsIGameProperties = IGamePropertiesBase & {
    pointsCalcType: 'NO_POINTS';
};

type IGamePropertiesWithPoints = IGamePropertiesBase & {
    pointsCalcType: 'SUM_OF_POINTS' | 'MAX_POINTS' | 'NUM_OF_GAMES_PLAYED';
    // positive floating point number
    pointsMultiplier: number;
};

export type IGameProperties =
    | NoPointsIGameProperties
    | IGamePropertiesWithPoints;

export type IGameEventModel<TDate = Timestamp> = EventBase<TDate> & {
    type: 'IGAME';
    igame: IGameProperties;
};

export type BingoProperties = {
    scorecardSize: 3 | 5;
    pointsPerBingo: number;
    pointsForParticipation: number;
    sportRadarGameUrl: string;
};

export type BingoEventModel<TDate = Timestamp> = EventBase<TDate> & {
    type: 'BINGO';
    actualStartDate: Date | null;
    bingo: BingoProperties;
};

export type EventModel<TDate = Timestamp> =
    | PredictionEventModel<TDate>
    | TriviaEventModel<TDate>
    | IGameEventModel<TDate>
    | BingoEventModel<TDate>;

export const eventHasPrizeDetailsMarkdown = (
    event: EventModel,
): event is WithNotNullProperty<EventModel, 'prizeDetailsMarkdown'> =>
    typeof event.prizeDetailsMarkdown === 'string' &&
    event.prizeDetailsMarkdown.length > 0;

export type EventEntityTranslation = {
    languageCodeId: LocaleId;
    eventImageUrl?: string;
    shortName: string;
    prizeDetailsMarkdown?: string;
    prizes: string[];
    sponsorship?: EventSponsorshipUnit;

    // ad start
    adIframeHtml?: string;
    adHeadline?: string;
    adDisclaimer?: string;
    // ad end

    //Igame
    igame?: {
        rulesMarkdown?: string;
    };

    socialShare?: SocialShare;
};

type EventLanguageInfo = {
    defaultLanguageId?: LocaleId;
    entityTranslations?: EventEntityTranslation[];
};

export type TranslatablePredictionEvent<TDate = Timestamp> =
    PredictionEventModel<TDate> & EventLanguageInfo;

export type TranslatableTriviaEvent<TDate = Timestamp> =
    TriviaEventModel<TDate> & EventLanguageInfo;

export type TranslatableIGameEvent<TDate = Timestamp> = IGameEventModel<TDate> &
    EventLanguageInfo;

export type TranslatableEventModel<TDate = Timestamp> =
    | TranslatablePredictionEvent<TDate>
    | TranslatableTriviaEvent<TDate>
    | TranslatableIGameEvent<TDate>;

export function isTranslatablePredictionEvent<TDate>(
    event: TranslatableEventModel<TDate>,
): event is TranslatablePredictionEvent<TDate> {
    return event.type === 'PREDICTION';
}

export type PredictionTranslatableFields = {
    adDisclaimer: string | null;
    adHeadline: string | null;
    lockDescription?: string;
    options: OptionModel[];
    subText?: string;
    text: string;
};

export type BingoSquareModel = {
    id: string;
    text: string;
    imageUrl: string;
    occurredDate: Timestamp | null;
};

export interface SponsorModel {
    id: string;
    logoUrl: string;
    name: string;
    partnerId: string;
    shortName: string;
    slug: string;
    wideLogoUrl?: string;
    primaryColor?: string;
}

export type UserModel = {
    displayName: string;
    gamesPlayed: number;
    playerSince: Timestamp;
    preferredLanguage: LocaleId | undefined;
};

export type UserWithPrivateData = {
    details: UserModel;
    contactInfo?: ContactInfo;
    offers?: UserOffer[];
};

export enum SMSAlertsState {
    OPTED_IN = 'OPTED_IN',
    OPTED_OUT_DIRECT = 'OPTED_OUT_DIRECT',
    OPTED_OUT_CARRIER = 'OPTED_OUT_CARRIER',
}

export interface ContactInfo {
    email?: string;
    phoneNumber?: string;
    marketingOptIn: boolean;
    smsAlertsState?: SMSAlertsState;
    smsMarketingOptIn: boolean;
    optionalOptIn?: boolean;
    tallyOptIn?: boolean;
    lastIterationOptIn: string;
    lastOptInDate?: Timestamp; //Date in firestore.Timestamp notation;
}

export type OfferCodeType =
    | 'TEXT_ONLY'
    | 'UPC_A'
    | 'EAN_8'
    | 'EAN_13'
    | 'CODE_39'
    | 'CODE_128'
    | 'ITF_14'
    | 'QR_CODE';

export interface UserOffer<TDate = IsoDateString> extends Offer<TDate> {
    codeData?: string;
    codeType?: OfferCodeType;
    sponsorId: string;
}

export interface UserData {
    offers?: UserOffer[];
}

export interface UserAnswer {
    // The keys of each answer object are option IDs.
    // The value at each option ID is the user answer (e.g. true, '21').
    // For example, on a multiple choice prediction, answer would look like:
    // {
    //   "30b3a848-dbd1-427f-9046-17025224df97": false,
    //   "705bc402-6bd8-4fb8-b98a-608ca22d028d": false,
    //   "e5374d29-6c38-46a6-9a0f-9a0c14027696": true,
    //   "f8c6928f-7bca-4219-a351-0b5e48f67a63": false
    // }
    // For a text entry prediction, answer would look like:
    // {
    //   "698730c5-15b6-4b17-8df9-341685e97a6c": "28",
    //   "e3ae14f8-76d3-465c-bae5-10b15372dd2d": "21"
    // }
    [optionId: string]: string | boolean | undefined;
}

export interface UserAnswerSet {
    [predictionId: string]: UserAnswer;
}

export interface Claim {
    [optionId: string]: number | undefined;
}
export interface OddClaim {
    [predictionId: string]: Claim;
}

export interface UserPredictionModel {
    answers: UserAnswerSet;
    parties: string[];
    event: DocumentReference;
    oddsClaims: OddClaim;
}

export type UserProgressivePollAnswer = {
    eventId: string;
    optionId: string;
    path: string;
};
export interface UserProgressivePollAnswerModel {
    answers: UserProgressivePollAnswer[];
}

export type LanguageInfo<EntityTranslation> = {
    entityTranslations?: Array<EntityTranslation & { languageId: LocaleId }>;
    defaultLanguageId: LocaleId;
};

export type SocialShare = {
    title: string;
    description: string;
    imageUrl?: string;
};

export type SocialShareConfig = SocialShare & {
    languageInfo: LanguageInfo<SocialShare>;
};
