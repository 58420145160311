import React, { Component, ChangeEvent } from 'react';
import styled from '@emotion/styled';
import { Color } from 'src/styles/Constants';
import { keyframes } from 'emotion';
import Theme from 'src/util/Theme';
interface Props {
    codeLength: number;
    onInputUpdate: (value: string) => void;
    showError?: boolean;
}

interface State {
    values: string[];
    focused: boolean;
    currentIndex: number;
}

const errorBounce = keyframes`
    from, 80%, to {
        transform: translate3d(0,0,0);
    }

    20% {
        transform: translate3d(-7px, 0, 0);
    }

    40% {
        transform: translate3d(7px, 0, 0);
    }

    60% {
        transform: translate3d(-3px,0,0);
    }

    70% {
        transform: translate3d(3px,0,0);
    }
`;

export default class InputCode extends Component<Props, State> {
    private inputRef = React.createRef<HTMLInputElement>();

    constructor(props: Props) {
        super(props);

        this.state = {
            currentIndex: 0,
            focused: false,
            values: new Array(props.codeLength).fill(''),
        };
    }

    public componentDidMount() {
        this.onClicked();
    }

    public componentDidUpdate() {
        if (this.state.focused && this.inputRef.current) {
            this.inputRef.current.focus();
        }
    }

    private onClicked = () => {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    };

    private onFocus = () => {
        this.setState({
            focused: true,
        });
    };

    private onBlur = () => {
        this.setState({
            focused: false,
        });
    };

    private onInputCharacter = (event: ChangeEvent<HTMLInputElement>) => {
        const { onInputUpdate } = this.props;
        const value = event.target.value;
        const { values, currentIndex } = this.state;

        // this is a pasted event and we already handled this.
        if (value.length > 1) {
            return;
        }

        if (currentIndex < values.length) {
            values[currentIndex] = value;
            const nextIndex = currentIndex + 1;
            onInputUpdate(values.join(''));
            this.setState({
                currentIndex:
                    nextIndex <= values.length ? nextIndex : currentIndex,
                values,
            });

            // if we have all the input spots filled in. hide keyboard
            if (nextIndex === values.length && this.inputRef.current) {
                this.inputRef.current.blur();
            }
        }
    };

    private onKeyUp = (event: any) => {
        if (event.key === 'Backspace') {
            const { onInputUpdate } = this.props;
            const { values, currentIndex } = this.state;
            const indexToDelete = currentIndex - 1;
            if (indexToDelete >= 0 && indexToDelete < values.length) {
                values[indexToDelete] = '';
                onInputUpdate && onInputUpdate(values.join(''));
                this.setState({
                    currentIndex: indexToDelete,
                    values,
                });
            }
        }
    };

    private onPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        const { onInputUpdate } = this.props;
        const { values } = this.state;
        const value = event.clipboardData.getData('text/plain');
        const pastedValues = value.split('');
        const newValues = pastedValues.slice(0, values.length);

        const currentCode = newValues.join('');
        onInputUpdate && onInputUpdate(currentCode);
        this.setState({
            currentIndex: values.length,
            values: newValues,
        });
    };

    private renderBoxes = () => {
        const { showError } = this.props;
        const { values, currentIndex, focused } = this.state;
        const boxes: JSX.Element[] = [];
        {
            values.map((c, index) => {
                const onLastNumber =
                    currentIndex === values.length &&
                    index === currentIndex - 1;
                const showInput = currentIndex === index || onLastNumber;
                const showNumber = currentIndex !== index;

                let borderColor = Color.T28_FOG.toString();
                if (showError) {
                    borderColor = Color.S12_VARSITY_RED;
                } else if (focused && (showInput || onLastNumber)) {
                    borderColor = Theme.themeHighlightFallbackColor;
                } else if (values[index]) {
                    borderColor = Color.T34_SQUIRTLE;
                }

                boxes.push(
                    <Box key={`BOX_${index}`} borderColor={borderColor}>
                        {showInput && (
                            <Input
                                value=""
                                ref={this.inputRef}
                                onChange={this.onInputCharacter}
                                onPaste={this.onPaste}
                                onFocus={this.onFocus}
                                onBlur={this.onBlur}
                                onKeyUp={this.onKeyUp}
                                type="text"
                                pattern="[0-9]*"
                                hidden={onLastNumber}
                                inputMode="numeric"
                            />
                        )}
                        {showNumber && values[index]}
                    </Box>,
                );
            });
        }

        return boxes;
    };

    public reset = () => {
        this.setState({
            currentIndex: 0,
            focused: true,
            values: new Array(this.props.codeLength).fill(''),
        });
    };

    public render() {
        const { showError } = this.props;
        const boxes = this.renderBoxes();
        return (
            <Container onClick={this.onClicked} showError={showError}>
                {boxes}
            </Container>
        );
    }
}

const Input = styled.input<{ hidden: boolean }>`
    position: ${(props) => (props.hidden ? 'absolute' : undefined)};
    display: flex;
    border: none;
    font-size: 32px;
    text-align: center;
    padding: 0;
    background-color: transparent;
    outline: none;
    width: 25px;
    opacity: ${(props) => (props.hidden ? '0' : '1')};
`;

const Box = styled.div<{ borderColor: string }>`
    height: 40px;
    width: 25px;
    margin: 0 10px;
    font-size: 32px;
    text-align: center;
    border-bottom: 2px solid ${(props) => props.borderColor};
    transition: border-bottom 0.3s;
`;

const Container = styled.div<{ showError?: boolean }>`
    font-family: Graphik Web;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    animation: ${(props) =>
        props.showError ? errorBounce : undefined} 0.3s ease;
    transition 0.3s all;
`;
