export enum EventName {
    contactSupport = 'contact_support_clicked',
    dismissInfoModuleSms = 'dismiss_info_module_sms',
    linkClick = 'link_click',
    marketingOptIn = 'marketing_opt_in', // sms, email
    marketingReOptIn = 'marketing_re_opt_in', // sms, email
    modalView = 'modal_view',
    pageViewFB = 'fb_mobile_content_view',
    pageViewPrefix = 'page_view',
    playMoreOnTallyClicked = 'play_more_on_tally_clicked',
    predictionChanged = 'prediction_changed',
    predictionMade = 'prediction_made',
    predictionsStarted = 'predictions_started',
    triviaAnswerMade = 'trivia_answer_made',
    triviaStarted = 'trivia_started',
    signUpComplete = 'fb_mobile_complete_registration',
    signUpDisplayNameComplete = 'signup_display_name_complete',
    signUpEmailComplete = 'signup_email_complete',
    signUpOktaComplete = 'signup_okta_complete',
    signUpPhoneAuthSubmit = 'signup_phone_auth_submit',
    signUpPhoneNonAuthSubmit = 'signup_phone_non_auth_submit',
    signUpSmsResendCode = 'signup_sms_code_resend_requested',
    signUpVerifySmsComplete = 'signup_verify_sms_complete',
    signUpSendVerifyCode = 'signup_send_verify_code',
    smsAlertsEnabled = 'sms_alerts_enabled',
    uiPredictionChangeExpansion = 'ui_prediction_change_expansion',
    waitingRoomEmailComplete = 'waiting_room_email_complete',
    contentReadMore = 'content_read_more',
    iframeSeen = 'iframe_seen',
}

export enum AppArea {
    game = 'game',
    outbound = 'outbound',
    partnerTools = 'partner Tools',
    profile = 'profile',
    signup = 'signup',
    landing = 'landing page',
}

export enum Screen {
    eventWaitingRoom = 'Waiting Room',
    eventBroadcastView = 'Broadcast',
    eventPredictions = 'Predictions',
    eventTrivia = 'Trivia',
    eventBingo = 'Bingo',
    eventLeaderboard = 'Leaderboard',
    eventTournamentLeaderboard = 'Tournament Leaderboard',
    groupLeaderboard = 'Group Leaderboard',
    signupDisplayName = 'Sign Up Display Name',
    signupEmail = 'Sign Up Email',
    reOptIn = 'Re-opt in',
    oktaSSO = 'Okta SSO sign in',
    additionalDataCollection = 'Additional data collection',
    oktaUID = 'Okta user id sign in',
    signUpPhoneAuth = 'Sign Up Phone Auth',
    signUpPhoneNonAuth = 'Sign Up Phone Non-Auth',
    signupSMS = 'Sign Up Verify SMS',
    unknown = 'Unknown',
    userSettings = 'User Settings',
    newIterationOptIn = 'New Iteration Rule Opt In',
    landingPage = 'Game Center Landing Page',
}

// We want to log some top-level events that are dynamically generated.
// We replace all non-word characters, and lower-case the entire suffix.
export function eventNameWithSuffix(eventName: string, suffix: string) {
    return `${eventName}_${suffix.replace(/[^\w]/gi, '_').toLowerCase()}`;
}
