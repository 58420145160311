import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from '@emotion/styled';
import ClipLoader from 'react-spinners/ClipLoader';

import {
    DescriptionText,
    ErrorMessage,
    SectionHeaderText,
    SubmitGroup,
} from '../../shared';
import { Color } from 'src/styles/Constants';
import TouchButton from 'src/components/shared/controls/TouchButton';
import { useEmailForm } from './hooks';
import { ContactInfo } from 'src/util/TallyFirestore';
import { isSso } from 'src/util/ConfigTypes';
import config from 'src/util/Config';
import { usePartner } from 'src/hooks/usePartner';

type Props = {
    contactInfo: ContactInfo;
    onContactSupportClick: () => void;
};

const DeleteAccountLink = ({
    onContactSupportClick,
}: {
    onContactSupportClick: () => void;
}) => {
    const {
        properties: { personalSettingsPageContent },
    } = usePartner();
    const deleteAccountLink = personalSettingsPageContent?.deleteAccountLink;
    const isDeleteAccountLinkActive = deleteAccountLink?.active;
    const deleteAccountLinkLabel = deleteAccountLink?.label;

    return isDeleteAccountLinkActive ? (
        <ButtonLink onClick={onContactSupportClick}>
            {deleteAccountLinkLabel || (
                <FormattedMessage id="settings.personalInfo.deleteAccount.label" />
            )}
        </ButtonLink>
    ) : null;
};

const PersonalSettings = ({ contactInfo, onContactSupportClick }: Props) => {
    const intl = useIntl();
    const { error, update, isUpdating, email, setEmail, submitDisabled } =
        useEmailForm(contactInfo ? contactInfo.email || '' : '');

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        update(email);
    };

    const emailPlaceholder = intl.formatMessage({
        id: 'settings.personalInfo.email.placeholder',
    });

    return isSso(config.partnerData.authenticationMethod) ? null : (
        <>
            <SectionHeaderText marginTop="30px">
                <FormattedMessage id="settings.personalInfo.header" />
            </SectionHeaderText>
            <DescriptionText>
                <FormattedMessage id="settings.personalInfo.description" />
            </DescriptionText>
            <SettingContainer marginTop="16px" marginBottom="16px">
                {contactInfo.phoneNumber && (
                    <InputGroup>
                        <InputLabel>
                            <FormattedMessage id="settings.personalInfo.phoneNumber.label" />
                        </InputLabel>
                        <Input
                            required={true}
                            value={contactInfo && contactInfo.phoneNumber}
                            disabled
                        />
                    </InputGroup>
                )}
                {contactInfo.email && (
                    <form style={{ width: '100%' }} onSubmit={handleSubmit}>
                        <ErrorMessage error={error} />
                        <InputGroup>
                            <InputLabel>
                                <FormattedMessage id="settings.personalInfo.email.label" />
                            </InputLabel>
                            <Input
                                required={true}
                                value={email}
                                disabled={isUpdating}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder={emailPlaceholder}
                            />
                        </InputGroup>
                        <SubmitGroup>
                            <TouchButton
                                backgroundColor={Color.S15_DEEP_TEAL}
                                type="submit"
                                displayText={
                                    <FormattedMessage id="settings.personalInfo.email.button" />
                                }
                                textColor={Color.T31_BLACK}
                                secondaryStyle={true}
                                disabled={submitDisabled}
                            />
                            <ClipLoader size={20} loading={isUpdating} />
                        </SubmitGroup>
                    </form>
                )}
                <DeleteAccountLink
                    onContactSupportClick={onContactSupportClick}
                />
            </SettingContainer>
        </>
    );
};

const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    margin-bottom: 16px;
`;

const Input = styled.input`
    font-family: Graphik Web;
    font-size: 16px;
    padding: 6px 10px;
    border: 1px #dbdbdb solid;
    border-radius: 2px;
    transition: box-shadow 0.15s ease 0s;

    &:focus {
        outline: 0;
        box-shadow: #3bf 0px 0px 2px 3px;
    }
`;

const InputLabel = styled.label`
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 18px;
    margin-bottom: 8px;
`;

const SettingContainer = styled.div<{
    marginTop?: string;
    marginBottom?: string;
}>`
    margin-top: ${(props) => props.marginTop || '0'};
    margin-bottom: ${(props) => props.marginBottom || '0'};
    width: 100%;
`;

const ButtonLink = styled.a`
    cursor: pointer;
    display: block;
    margin-top: 15px;
    underline: true;
    font-size: 12px;
    line-height: 16px;
    text-decoration: none;
`;

export default PersonalSettings;
