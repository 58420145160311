import { Timestamp } from 'src/services/Firebase';

import {
    OddsType,
    PredictionModel,
    PredictionType,
    UserAnswer,
    UserAnswerSet,
    UserOffer,
    OddClaim,
    TranslatablePredictionModel,
} from './TallyFirestore';
import { AnswerCorrectness } from './EventHelpers';
import {
    IsoDateString,
    PredictionSponsorshipUnitTemplateId,
    PrizeRedemptionSponsorshipUnit,
} from './SponsorshipTypes';
import { getOddsWin } from './bettingOdds';

export function isPredictionAnswered(
    prediction: PredictionModel,
    answer?: UserAnswer,
) {
    if (!answer) {
        return false;
    }

    let answered = true;
    const { options } = prediction;
    for (const option of options) {
        const { id: optionId } = option;
        if (!answer.hasOwnProperty(optionId)) {
            answered = false;
            break;
        }
    }

    return answered;
}

export type MultipleChoicyPredictionType =
    | 'MULTIPLE_CHOICE'
    | 'POLL'
    | 'BET_SIM';

export type MultipleChoiceOddsType = OddsType.moneyLine | OddsType.overUnder;

export function predictionTypeIsMultipleChoicy(
    predictionType: PredictionType,
): predictionType is MultipleChoicyPredictionType {
    return (
        predictionType === 'MULTIPLE_CHOICE' ||
        predictionType === 'POLL' ||
        predictionType === 'BET_SIM'
    );
}

// ATODO: Should be only for M CH
const multipleChoicyCorrectnessCheck = (
    prediction: PredictionModel,
    userAnswers: UserAnswer,
) => {
    let selectedOptionId: string | undefined = undefined;

    for (const id in userAnswers) {
        if (userAnswers[id] === true) {
            selectedOptionId = id;
            break;
        }
    }

    if (!selectedOptionId) {
        return AnswerCorrectness.incorrect;
    }

    const selectedPredictionOption = prediction.options.find(
        ({ id }) => id === selectedOptionId,
    );

    return selectedPredictionOption && selectedPredictionOption.correct
        ? AnswerCorrectness.correct
        : AnswerCorrectness.incorrect;
};
const fillInTheBlanksCorrectnessCheck = (
    prediction: PredictionModel,
    userAnswers: UserAnswer,
) => {
    const fillInBlankIsAllCorrect = prediction.options.every(
        ({ id, answer: predictionOptionAnswer }) => {
            const userAnswer = userAnswers[id];
            return (
                userAnswer &&
                predictionOptionAnswer &&
                userAnswer === predictionOptionAnswer
            );
        },
    );
    return fillInBlankIsAllCorrect
        ? AnswerCorrectness.correct
        : AnswerCorrectness.incorrect;
};

// ATODO: what did actually happen with polls???
export function predictionIsCorrect(
    prediction: PredictionModel,
    answer?: UserAnswer,
): AnswerCorrectness {
    if (!prediction.resolved) {
        return AnswerCorrectness.unknown;
    }
    // ATODO: why not unknown???
    if (!answer) {
        return AnswerCorrectness.incorrect;
    }
    switch (prediction.type) {
        case 'MULTIPLE_CHOICE':
        case 'BET_SIM':
            return multipleChoicyCorrectnessCheck(prediction, answer);
        case 'FILL_IN_THE_BLANK':
            return fillInTheBlanksCorrectnessCheck(prediction, answer);
        case 'POLL':
            return AnswerCorrectness.correct;
        default:
            return AnswerCorrectness.incorrect;
    }
}

export type PredictionBatch<G> = Batch<G, PredictionModel>;

export type Batch<G, E> = {
    group: G;
    entities: E[];
};

export function mapBatchEntities<G, E1, E2>(
    batch: Batch<G, E1>,
    callback: (entity: E1) => E2,
): Batch<G, E2> {
    return {
        group: batch.group,
        entities: batch.entities.map(callback),
    };
}

export type AnswerMilestone = string;
export type LockDate = Timestamp;
export type LockDescription = string;
export type PredictionBatchType = LockDate | LockDescription;

export type GroupedPredictions<Prediction = PredictionModel> = {
    live: {
        automatic: Array<Batch<LockDate, Prediction>>;
        manual: Array<Batch<LockDescription, Prediction>>;
    };
    pending: Batch<'Pending', Prediction>;
    final: Array<Batch<AnswerMilestone, Prediction>>;
};

export type GroupedTranslatablePredictions =
    GroupedPredictions<TranslatablePredictionModel>;

export function mapGroupedPredictions<P1, P2>(
    groupedPredictions: GroupedPredictions<P1>,
    callback: (prediction: P1) => P2,
): GroupedPredictions<P2> {
    return {
        live: {
            automatic: groupedPredictions.live.automatic.map((batch) =>
                mapBatchEntities(batch, callback),
            ),
            manual: groupedPredictions.live.manual.map((batch) =>
                mapBatchEntities(batch, callback),
            ),
        },
        pending: mapBatchEntities(groupedPredictions.pending, callback),
        final: groupedPredictions.final.map((batch) =>
            mapBatchEntities(batch, callback),
        ),
    };
}

export const hasActivePredictions = (groupedPredictions: GroupedPredictions) =>
    groupedPredictions.live.automatic.length > 0 ||
    groupedPredictions.live.manual.length > 0;

// ATODO: why isn't it used anymore?
export const getPendingOffers = (
    predictions: PredictionModel[],
    answers: UserAnswerSet,
): UserOffer[] => {
    const userOffers: Array<UserOffer<IsoDateString>> = predictions
        .filter((p): p is PredictionModel<PrizeRedemptionSponsorshipUnit> => {
            // Ignore predictions that don't have an offer or aren't resolved yet.
            if (
                !p.resolved ||
                !p.sponsorship ||
                p.sponsorship.templateId !==
                    PredictionSponsorshipUnitTemplateId.PRIZE_REDEMPTION
            ) {
                return false;
            }

            // Return offer predictions that the user got correct.
            return (
                predictionIsCorrect(p, answers[p.id]) ===
                AnswerCorrectness.correct
            );
        })
        .map((p) => {
            const userOffer: UserOffer<IsoDateString> = {
                ...p.sponsorship!.offer!,
                sponsorId: p.sponsorship!.sponsorId,
            };
            return userOffer;
        });

    return userOffers;
};

export const calcOddsWinPoints = (
    answers: UserAnswerSet,
    oddClaims: OddClaim,
    prediction: PredictionModel,
): number => {
    const selectedOptionId = Object.keys(answers[prediction.id]).find(
        (optionId) => answers[prediction.id][optionId] === true,
    );
    const claim =
        selectedOptionId &&
        oddClaims[prediction.id] &&
        oddClaims[prediction.id][selectedOptionId];
    if (claim) {
        return getOddsWin(prediction.pointValue, claim);
    }
    return prediction.pointValue;
};
