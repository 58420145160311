import log from 'src/util/Logger';

interface EventLocalStorageObject {
    dismissedSmsModule: boolean;
    eventId?: string;
}

const LOCAL_STORAGE_KEY = 'TallyEventDetails';
const LOCAL_STORAGE_DEFAULTS: EventLocalStorageObject = {
    dismissedSmsModule: false,
};

export function hasDismissedSmsModuleForEvent(eventId: string): boolean {
    const eventLocalStorageObject = getPreferencesFromStorage(eventId);
    return eventLocalStorageObject.dismissedSmsModule;
}

export function localStorageSetModalDismissedForEvent(eventId: string) {
    const eventLocalStorageObject = getPreferencesFromStorage(eventId);
    eventLocalStorageObject.dismissedSmsModule = true;
    writeToLocalStorage(LOCAL_STORAGE_KEY, eventLocalStorageObject);
}

export function getPreferencesFromStorage(
    eventId: string,
): EventLocalStorageObject {
    const defaultsObj = { ...LOCAL_STORAGE_DEFAULTS, eventId };

    if (!window || !window.localStorage) {
        log.error('User does not have localStorage');
        return defaultsObj;
    }

    const eventInfoString = window.localStorage.getItem(LOCAL_STORAGE_KEY);

    // If first ever time visiting, or visiting in incognito set to default.
    if (!eventInfoString) {
        setDefaultsForEvent(eventId);
        return defaultsObj;
    }

    const eventLocalStorageObject = JSON.parse(eventInfoString);

    // If no info for this event in local storage, set it to default.
    if (eventLocalStorageObject.eventId !== eventId) {
        setDefaultsForEvent(eventId);
        return defaultsObj;
    }
    return eventLocalStorageObject as EventLocalStorageObject;
}

function setDefaultsForEvent(eventId: string) {
    const defaultsObj = { ...LOCAL_STORAGE_DEFAULTS, eventId };
    writeToLocalStorage(LOCAL_STORAGE_KEY, defaultsObj);
}

function writeToLocalStorage(localStorageKey: string, data: object) {
    const stringifiedData = JSON.stringify(data);
    try {
        window.localStorage.setItem(localStorageKey, stringifiedData);
    } catch (e) {
        log.error('Error writing user preferences to local storage', e);
    }
}
