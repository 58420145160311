import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import { Color, StyleDefaults } from 'src/styles/Constants';
import css from '@emotion/css';
import TouchButton from 'src/components/shared/controls/TouchButton';
import { ModalPresentationDetails, ModalType } from './ModalHoster';
import howToPlayBackground from 'src/images/backgrounds/HowToPlayAccent.svg';
import Theme from 'src/util/Theme';
import TallyMarkdown from 'src/components/shared/ui/TallyMarkdown';
import { CloseButtonColor } from 'src/components/shared/controls/CloseButton';
import { EventModel } from 'src/util/TallyFirestore';

interface Props {
    requestClose: () => void;
    title?: string;
    body?: string;
}

export interface HowToPlayTournamentModalDetails {
    modalType: ModalType.HOW_TO_PLAY_TOURNAMENT;
    event?: EventModel;
    title?: string;
    body?: string;
}

export default class HowToPlayTournamentModal extends PureComponent<Props> {
    public static getPresentationDetails = (): ModalPresentationDetails => {
        return {
            backgroundColor: Theme.modalBackgroundColor,
            backgroundImageUrl: howToPlayBackground,
            backgroundPosition: 'bottom',
            closeIconColor: CloseButtonColor.GRAY,
        };
    };

    public render() {
        const { requestClose, title, body } = this.props;

        return (
            <Container>
                <TagLineText>{title}</TagLineText>
                <HeaderText>
                    <FormattedMessage id="howToPlayTournamentModal.header" />
                </HeaderText>
                <BodyText>{body && <TallyMarkdown source={body} />}</BodyText>
                <CloseButtonContainer>
                    <TouchButton
                        backgroundColor={Color.P1_WHITE}
                        textColor={Color.T31_BLACK}
                        displayText={
                            <FormattedMessage id="howToPlayTournamentModal.button" />
                        }
                        onClick={requestClose}
                        stretchWidth={true}
                    />
                </CloseButtonContainer>
            </Container>
        );
    }
}

const Container = styled.div`
    color: ${Color.P1_WHITE};
    display: flex;
    text-align: left;
    flex-direction: column;
    padding: ${StyleDefaults.MODAL_BODY_PADDING};
`;

const TagLineText = styled.div`
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 18px;
    text-transform: upperCase;
`;

const HeaderText = styled.div`
    font-size: 35px;
    letter-spacing: -0.6px;
    line-height: 37px;
    font-weight: 900;
    margin: 0 0 20px 0;
`;

const BodyTextStyling = css`
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 18px;
`;

const BodyText = styled.div`
    ${BodyTextStyling}
    margin: 10px 0;
`;

const CloseButtonContainer = styled.div`
    width: 100%;
    margin: 40px 0 20px 0;
`;
