import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useUpdateContactInfo } from 'src/contexts/UserStateContext';

import ServerApi from 'src/services/ServerApi';
import { isValidEmail } from 'src/util/validation';

const updateEmail = (email: string) =>
    ServerApi.updateUser({
        email,
    });

export const useEmailForm = (initialEmail: string) => {
    const intl = useIntl();
    const updateContactInfo = useUpdateContactInfo();
    const [email, setEmail] = useState(initialEmail);
    const [inputIsDirty, setInputIsDirty] = useState(false);
    const [error, setError] = useState<null | Error>(null);
    const [isUpdating, setIsUpdating] = useState(false);

    const update = (email: string) => {
        if (!isValidEmail(email)) {
            setError(
                new Error(
                    intl.formatMessage({ id: 'onboarding.invalidEmail' }),
                ),
            );
            return;
        }
        setIsUpdating(true);
        updateEmail(email)
            .then(() => {
                updateContactInfo({ email });
                setIsUpdating(false);
                setInputIsDirty(false);
            })
            .catch((e) => {
                setIsUpdating(false);
                setError(e);
            });
    };

    return {
        update,
        isUpdating,
        error,
        email,
        setEmail: (e: string) => {
            setInputIsDirty(true);
            setError(null);
            setEmail(e);
        },
        submitDisabled: isUpdating || !inputIsDirty,
    };
};
