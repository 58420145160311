export type AspectRatio = {
    width: number;
    height: number;
};

export const getIframeAspectRatio = (
    adIframe: string | null,
): AspectRatio | null => {
    if (adIframe) {
        let el = document.createElement('html');

        if (el) {
            el.innerHTML = adIframe;
            const iframe = el.getElementsByTagName('iframe')[0];
            if (iframe) {
                const width = parseInt(iframe.getAttribute('width') || '', 10);
                const height = parseInt(
                    iframe.getAttribute('height') || '',
                    10,
                );
                if (!isNaN(width) && !isNaN(height)) {
                    return { width, height };
                }
            }
        }
    }
    return null;
};
