import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';

import {
    EventModel,
    LeaderboardEntry,
    UserOffer,
} from 'src/util/TallyFirestore';
import { TranslatedIteration } from 'src/util/i18n';
import { IsoDateString } from 'src/util/SponsorshipTypes';
import Offers from './Offers';
import { Color, StyleDefaults } from 'src/styles/Constants';
import NameJoined from './NameJoined';
import { Group } from 'src/types';
import { useUserState } from 'src/contexts/UserStateContext';
import Settings from './Settings';

interface Props {
    iteration: TranslatedIteration;
    event?: EventModel;
    leaderboardEntry?: LeaderboardEntry;
    pendingOffers?: Array<UserOffer<IsoDateString>>;
    group?: Group;
}

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        marginTop: 20,
        marginBottom: 20,
        maxWidth: StyleDefaults.MAX_ELEMENT_WIDTH,
        width: StyleDefaults.ELEMENT_WIDTH,
        backgroundColor: Color.P1_WHITE,
    },
    settings: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 80,
    },
});

const AccountPage = ({ pendingOffers, event, iteration }: Props) => {
    const classes = useStyles();
    const userData = useUserState();

    document.body.style.backgroundColor = Color.P1_WHITE;

    return (
        <div className={classes.root}>
            {userData.type === 'initialized' && (
                <NameJoined user={userData.details} />
            )}
            <Offers pendingOffers={pendingOffers} />
            <Settings iteration={iteration} event={event} />
        </div>
    );
};

export default AccountPage;
