import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import {
    Color,
    StyleDefaults,
    Orientation,
    Alignment,
} from 'src/styles/Constants';
import TouchButton from 'src/components/shared/controls/TouchButton';
import { ModalPresentationDetails, ModalType } from './ModalHoster';
import { SponsorModel } from 'src/util/TallyFirestore';
import TallyMarkdown from 'src/components/shared/ui/TallyMarkdown';
import PresentedBy from 'src/components/shared/ui/PresentedBy';
import { EventSponsorshipUnit } from 'src/util/SponsorshipTypes';
import BonusSponsors from 'src/components/pistachioGame/BonusSponsors';
import theme from 'src/util/Theme';
import { EventModel } from 'src/util/TallyFirestore';
import { WithNotNullProperty } from 'src/utilityTypes';

interface Props {
    requestClose: () => void;
    event?: EventModel;
    eventSponsorshipUnit?: EventSponsorshipUnit;
    gameWideSponsor?: SponsorModel;
    bonusSponsors?: SponsorModel[];
    prizeDetailsMarkdown: string;
}

const renderPrizeInfo = (renderPrizeInfo: string): JSX.Element => {
    return (
        <MarkdownText>
            <TallyMarkdown source={renderPrizeInfo} />
        </MarkdownText>
    );
};

export interface PrizeInfoModalDetails {
    modalType: ModalType.PRIZES;
    event: WithNotNullProperty<EventModel, 'prizeDetailsMarkdown'>;
    gameWideSponsor?: SponsorModel;
    bonusSponsors?: SponsorModel[];
}

export default class PrizeDetailsModal extends PureComponent<Props> {
    public static getPresentationDetails = (): ModalPresentationDetails => {
        return {
            backgroundColor: Color.P1_WHITE,
            backgroundPosition: 'top',
            hideCloseButton: true,
        };
    };

    public render() {
        const {
            requestClose,
            eventSponsorshipUnit,
            gameWideSponsor,
            bonusSponsors,
        } = this.props;

        const presentedByLogo =
            eventSponsorshipUnit && eventSponsorshipUnit.properties.gameLogoUrl;
        //        gameWideSponsor && gameWideSponsor.wideLogoUrl;
        return (
            <Container>
                {gameWideSponsor && (
                    <SponsorshipBanner
                        src={eventSponsorshipUnit!.properties.gameWideBanner}
                    />
                )}
                <ModalBody>
                    <HeaderText extraTopPadding={!gameWideSponsor}>
                        <FormattedMessage id="prizeDetailsModal.header" />
                    </HeaderText>
                    <LogoContainer>
                        {presentedByLogo && (
                            <PresentedBy
                                sponsorshipUnit={eventSponsorshipUnit}
                                asset={presentedByLogo}
                                orientation={Orientation.VERTICAL}
                                alignment={Alignment.LEFT}
                            />
                        )}
                    </LogoContainer>
                    {renderPrizeInfo(this.props.prizeDetailsMarkdown)}
                    <LogoContainer />
                    <BonusSponsors bonusSponsors={bonusSponsors} />
                    <CloseButtonContainer>
                        <TouchButton
                            backgroundColor={
                                theme.themeHighlightFallbackColor ||
                                Color.G1_BLACK
                            }
                            textColor={Color.G11_WHITE}
                            displayText={
                                <FormattedMessage id="prizeDetailsModal.button" />
                            }
                            onClick={requestClose}
                            stretchWidth={false}
                        />
                    </CloseButtonContainer>
                </ModalBody>
            </Container>
        );
    }
}

const BannerContainer = styled.div`
    width: 100%;
    heigth: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
`;
const SponsorshipBanner = styled.img`
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: auto;
    max-height: 120px;
    max-width: auto;
`;
const SponsorBanner = styled.img`
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: auto;
`;

const ModalBody = styled.div`
    padding: ${StyleDefaults.MODAL_BODY_PADDING};
`;

const LogoContainer = styled.div`
    width: 100%;
    padding: 0 0 14px 0;
`;

const Container = styled.div`
    color: ${Color.G1_BLACK};
    display: flex;
    text-align: left;
    flex-direction: column;
`;

const HeaderText = styled.div<{
    extraTopPadding: boolean;
}>`
    font-size: 35px;
    letter-spacing: -0.6px;
    line-height: 37px;
    font-weight: 900;
    padding: ${(props) => (props.extraTopPadding ? '30px' : '12px')} 0 4px 0;
    border-bottom: 2px solid ${Color.S21_MISTY_BLUE};
`;

const MarkdownText = styled.div`
    a {
        color: ${Color.P1_WHITE};
    }
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: normal;
    padding: 0 0 8px 0;
    border-bottom: 2px solid ${Color.S21_MISTY_BLUE};
`;

const CloseButtonContainer = styled.div`
    width: 100%;
    margin: 20px 0 0 0;
    display: flex;
    justify-content: center;
`;
