import React, { KeyboardEvent, ChangeEvent, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { OptionModel } from 'src/util/TallyFirestore';
import checkmark from 'src/images/icons/Picks-Correct-M@3x.svg';
import wrongmark from 'src/images/icons/Picks-Wrong-M@3x.svg';
import SubmitStatusIndicator, {
    IndicatorState,
} from 'src/components/shared/animations/SubmitStatusIndicator';
import { IntlPointsText } from 'src/components/shared/ui/IntlPointsText';
import { getInactiveBorderColor, stringToNonNegativeInt } from './util';
import {
    AverageLabel,
    AverageValue,
    Container,
    Input,
    InputContainer,
    InputError,
    InputLabel,
    ResolvedIcon,
    Row,
    TotalPoints,
} from './styles';

interface Props {
    option: OptionModel;
    onSubmit?: (option: OptionModel, value: string) => Promise<boolean>;
    predictionPointValue: number;
    predictionResolved: boolean;
    predictionLocked: boolean;
    userAnswer?: string;
}

interface State {
    loadingState: IndicatorState;
    errorMessage: string | undefined;
}

const DASH = '—';
const MIN_VALUE = 0;
const MAX_VALUE = 9999;

const Blank = (props: Props) => {
    const intl = useIntl();

    const inputRef = useRef<HTMLInputElement>(null);
    const [state, setState] = useState<State>({
        loadingState: IndicatorState.HIDDEN,
        errorMessage: undefined,
    });
    const dirtyRef = useRef(false);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        dirtyRef.current = true;
        const parsedNumber = stringToNonNegativeInt(e.target.value);
        if (parsedNumber === undefined) {
            inputRef.current!.value = '';
        } else {
            inputRef.current!.value = parsedNumber + '';
        }

        setState((prevState) => ({
            ...prevState,
            errorMessage: parsedNumber
                ? (parsedNumber > MAX_VALUE &&
                      intl.formatMessage(
                          { id: 'predictions.blank.errorGreater' },
                          {
                              value: MAX_VALUE,
                          },
                      )) ||
                  (parsedNumber < MIN_VALUE &&
                      intl.formatMessage(
                          {
                              id: 'predictions.blank.errorBelow',
                          },
                          {
                              value: MIN_VALUE,
                          },
                      )) ||
                  undefined
                : undefined,
        }));
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            inputRef.current!.blur();
        }
    };

    const handleKeyPress = (event: KeyboardEvent) => {
        // user can only input numbers
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
    };

    const handleSubmit = async () => {
        const { onSubmit, option } = props;
        if (!inputRef.current || !dirtyRef.current || state.errorMessage) {
            return;
        }
        const value = inputRef.current.value;
        if (onSubmit) {
            setState((prevState) => ({
                ...prevState,
                loadingState: IndicatorState.LOADING,
            }));
            const success = await onSubmit(option, value);
            dirtyRef.current = false;
            setState((prevState) => ({
                ...prevState,
                loadingState: success
                    ? IndicatorState.CONFIRMATION
                    : IndicatorState.HIDDEN,
            }));
        }
    };

    const {
        option,
        predictionLocked,
        predictionPointValue,
        predictionResolved,
        userAnswer,
    } = props;
    const { loadingState } = state;

    const { answer, averagePrediction } = option;
    const showAverage = !predictionResolved;
    const averageValue = (showAverage ? averagePrediction : answer) || DASH;
    const userIsCorrect =
        predictionResolved && userAnswer !== undefined
            ? answer === userAnswer
            : undefined;
    const inactiveBorderColor = getInactiveBorderColor(
        predictionLocked,
        predictionResolved,
        userIsCorrect,
    );

    return (
        <Container>
            <Row error={!!state.errorMessage}>
                <InputContainer>
                    <Input
                        inactiveBorderColor={inactiveBorderColor}
                        defaultValue={userAnswer}
                        disabled={predictionLocked}
                        onChange={handleChange}
                        onBlur={handleSubmit}
                        min={MIN_VALUE}
                        max={MAX_VALUE}
                        error={!!state.errorMessage}
                        onKeyDown={handleKeyDown}
                        onKeyPress={handleKeyPress}
                        ref={inputRef}
                        type="number"
                    />
                    {predictionResolved && userAnswer !== undefined && (
                        <ResolvedIcon
                            src={userIsCorrect ? checkmark : wrongmark}
                        />
                    )}
                    <SubmitStatusIndicator state={loadingState} />
                </InputContainer>
                <AverageValue>{averageValue}</AverageValue>
            </Row>
            <InputError>{state.errorMessage}</InputError>
            <Row error={false}>
                <InputLabel>
                    <FormattedMessage id="predictions.blank.label" />
                </InputLabel>
                <AverageLabel>
                    <FormattedMessage
                        id={
                            showAverage
                                ? 'predictions.blank.average'
                                : 'predictions.blank.result'
                        }
                    />
                </AverageLabel>
            </Row>
            <TotalPoints>
                <IntlPointsText points={predictionPointValue} />
            </TotalPoints>
        </Container>
    );
};

export default Blank;
