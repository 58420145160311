import { createContext, useContext } from 'react';
import { EventModel } from 'src/util/TallyFirestore';
import { WithNotNullProperty } from 'src/utilityTypes';

export const ShowEventPrizeDetailsContext = createContext<
    | ((event: WithNotNullProperty<EventModel, 'prizeDetailsMarkdown'>) => void)
    | undefined
>(undefined);

export const useShowEventPrizeDetails = () =>
    useContext(ShowEventPrizeDetailsContext);
