import dateFormat from 'dateformat';
import { useIntl } from 'react-intl';

import { IsoDateString } from 'src/util/SponsorshipTypes';
import { Color } from 'src/styles/Constants';
import { getDayDateString, getDaysBetween } from 'src/util/Dates';
import clockIcon from 'src/images/icons/Expire.svg';
import lockIcon from 'src/images/icons/Lock.svg';

export const useStatus = ({
    expirationDate,
    pending,
}: {
    pending?: boolean;
    expirationDate: IsoDateString | Date;
}) => {
    const { formatMessage } = useIntl();

    if (pending) {
        return {
            color: Color.G4_CHARCOAL,
            iconSrc: lockIcon,
            statusText: formatMessage({ id: 'offerCard.status.pending' }),
        };
    }

    const now = new Date();
    const expiration =
        expirationDate instanceof Date
            ? expirationDate
            : new Date(expirationDate);

    if (now >= expiration) {
        return {
            color: Color.G4_CHARCOAL,
            statusText: formatMessage(
                { id: 'offerCard.status.expd' },
                {
                    when: getDayDateString(expiration),
                },
            ),
        };
    }

    const daysUntilExpiration = getDaysBetween(now, expiration);

    // Determine date string
    let dateString;
    let almostExpired = false;
    if (daysUntilExpiration === 0) {
        dateString = formatMessage({ id: 'offerCard.status.today' });
    } else if (daysUntilExpiration === 1) {
        dateString = formatMessage({ id: 'offerCard.status.tomorrow' });
    } else if (daysUntilExpiration <= 2) {
        almostExpired = true;
        dateString = formatMessage(
            { id: 'offerCard.status.inDays' },
            {
                when: daysUntilExpiration,
            },
        );
    } else {
        dateString = getDayDateString(expiration);
    }

    return {
        color: almostExpired ? Color.P4_CHILI_PEPPER : Color.G4_CHARCOAL,
        iconSrc: almostExpired ? clockIcon : undefined,
        statusText: formatMessage(
            { id: 'offerCard.status.exps' },
            { when: dateString },
        ),
    };
};
