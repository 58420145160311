import HelpScout from 'src/util/HelpScout';
import log from './Logger';
import ServerApi from 'src/services/ServerApi';
import {
    EventModel,
    TranslatableEventModel,
    EventState,
} from './TallyFirestore';
import config from './Config';
import { isValidEmail } from './validation';
import { TranslatedIteration } from './i18n';
import { UserAuthData } from 'src/contexts/UserStateContext';

// analytics.logEvent({ eventName: EventName.waitingRoomEmailComplete });
// should be called next to this function
export async function submitMarketingEmail(eventId: string, email: string) {
    if (!isValidEmail(email)) {
        throw new Error('Please enter a valid email address');
    }

    try {
        await ServerApi.sendEmailForMarketing(eventId, email);
    } catch (error) {
        log.error('Failed to submit email', {
            email,
            error,
            eventId,
        });
    }
}

export function shouldShowWaitingRoom(
    userData: UserAuthData,
    event: TranslatableEventModel,
    hasDismissedPostEventWaitingRoom: boolean,
) {
    const { startDate } = event;
    const eventHasStarted = startDate && startDate.toDate() <= new Date();
    const eventHasEnded = event.state === EventState.final;

    // Show the waiting room when...
    // - the event has started yet
    // OR
    // - the event has ended for non-logged in users.
    //   for these users, hide the waiting room if they've pressed the button to dismiss the waiting room.
    return (
        !eventHasStarted ||
        (eventHasEnded &&
            userData.type !== 'initialized' &&
            !hasDismissedPostEventWaitingRoom)
    );
}

export function openCustomerSupport(
    subject: { event: EventModel } | { iteration: TranslatedIteration },
) {
    const toggled = HelpScout.toggle();
    if (!toggled) {
        const mailSubject =
            'event' in subject ? subject.event.name : subject.iteration.name;
        window.open(
            `mailto:${
                config.partnerData.properties.supportEmail
                    ? config.partnerData.properties.supportEmail
                    : config.supportEmail
            }?subject=${encodeURI(mailSubject)}`,
            '_self',
        );
    }
}
