import CryptoJS from 'crypto-js';
import { useEffect } from 'react';
import { UserAuthData, useUserState } from 'src/contexts/UserStateContext';

declare global {
    interface Window {
        Beacon: any;
    }
}

type IdentifyParams = {
    partnerId: string;
    iterationId: string;
    eventId?: string;
    userAuthData: UserAuthData;
};

class HelpScout {
    public init(beaconId: string) {
        if (!window.Beacon) {
            return;
        }

        window.Beacon('init', beaconId);
    }

    public toggle(): boolean {
        if (!window.Beacon) {
            return false;
        }

        window.Beacon('toggle');
        return true;
    }

    public identify({
        partnerId,
        iterationId,
        eventId,
        userAuthData,
    }: IdentifyParams) {
        if (!window.Beacon) {
            return;
        }

        let email: string | undefined;
        let name: string | undefined;

        if (userAuthData.type === 'initialized') {
            name = userAuthData.details.displayName.toLowerCase();
            email = userAuthData.contactInfo?.email;
        }

        // identify user to Help Scout for messaging history in the beacon
        const signature = email
            ? CryptoJS.HmacSHA256(
                  email,
                  //'eL+AbE8hdqnYyaFrSVJ7zFKG1ONj3dPWfk5RTN8CP+w='
                  'VPkIXnlYev5xX8hwF7WaZzhWzKmIn7iZ/KL4J71T5mE=',
              ).toString()
            : undefined;

        // get gravatar url if avail
        const avatar = email
            ? `https://www.gravatar.com/avatar/${CryptoJS.MD5(
                  email,
              )}.jpg?r=pg&d=retro`
            : undefined;

        const payload = {
            avatar,
            email,
            event: eventId,
            iteration: iterationId,
            name,
            signature,
            partner: partnerId,
        };

        if (!payload.avatar) {
            delete payload.avatar;
        }

        window.Beacon('identify', payload);
    }
}

const helpScout = new HelpScout();
export default helpScout;

// ATODO: call helpscout identify somewhere...
// ATODO: this potentially could be used in several places...
export const useHelpscoutIdentify = ({
    partnerId,
    iterationId,
    eventId,
}: {
    partnerId: string;
    iterationId: string;
    eventId?: string;
}) => {
    const userAuthData = useUserState();

    useEffect(() => {
        helpScout.identify({
            partnerId,
            iterationId,
            eventId,
            userAuthData,
        });
    }, [partnerId, iterationId, eventId, userAuthData]);
};
