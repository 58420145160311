import React, { Component, createRef } from 'react';
import styled from '@emotion/styled';
import { keyframes } from 'emotion';
import { Color } from 'src/styles/Constants';
import Theme from 'src/util/Theme';

interface Props {
    onCheckmarkAnimationComplete?: () => void;
    showCheck: boolean;
    sizePixels: number;
    color?: Color;
}

interface State {
    showingCheck: boolean;
}

// A bag of values that are calculated from props.size.
// We store them so we don't have to recompute them each render cycle.
interface CachedValues {
    checkHeight: string;
    checkWidth: string;
    checkThickness: string;
    checkLeft: string;
    size: string;
}

const CHECKMARK_FADE_OUT_DURATION_MILLIS = 200;
const CHECKMARK_FADE_OUT_DELAY_MILLIS = 1000;

export default class LoadingCheckmark extends Component<Props, State> {
    private values: CachedValues;
    private checkmarkAnimation: string;
    private containerRef = createRef<HTMLDivElement>();

    constructor(props: Props) {
        super(props);
        const { sizePixels } = props;
        this.state = {
            showingCheck: false,
        };
        this.values = {
            checkHeight: `${sizePixels / 2}px`,
            checkLeft: `${sizePixels * 0.16}px`,
            checkThickness: `${sizePixels / 10}px`,
            checkWidth: `${sizePixels / 4}px`,
            size: `${sizePixels}px`,
        };
        this.checkmarkAnimation = this.getCheckmarkAnimation(this.values);
    }

    public componentDidMount = () => {
        if (this.containerRef.current) {
            this.containerRef.current.addEventListener(
                'animationiteration',
                this.onNextLoadingIteration,
            );
        }
    };

    public componentDidUpdate(prevProps: Props) {
        if (prevProps.showCheck && !this.props.showCheck) {
            this.setState({
                showingCheck: false,
            });
        }
    }

    private onNextLoadingIteration = () => {
        const { onCheckmarkAnimationComplete, showCheck } = this.props;
        const { showingCheck } = this.state;

        // We just finished a revolution of the spinning circle animation.
        // Start showing the checkmark if desired.
        if (showCheck && !showingCheck) {
            this.setState({
                showingCheck: true,
            });

            // Put a bit of a delay before we call the completion handler
            // so that the checkmark stays visible for a bit.
            if (onCheckmarkAnimationComplete) {
                setTimeout(() => {
                    this.setState({ showingCheck: false });
                    onCheckmarkAnimationComplete();
                }, CHECKMARK_FADE_OUT_DELAY_MILLIS + CHECKMARK_FADE_OUT_DURATION_MILLIS);
            }
        }
    };

    private getCheckmarkAnimation = (values: CachedValues) => {
        const { checkHeight: height, checkWidth: width } = values;
        return keyframes`
            0% {
                height: 0;
                width: 0;
                opacity: 1;
            }
            20% {
                height: 0;
                width: ${width};
                opacity: 1;
            }
            40% {
                height: ${height};
                width: ${width};
                opacity: 1;
            }
            100% {
                height: ${height};
                width: ${width};
                opacity: 1;
            }
        `;
    };

    public render() {
        const { color } = this.props;
        const { showingCheck } = this.state;
        return (
            <Container
                animate={!showingCheck}
                color={color || Theme.predictionAnsweredIconBackgroundColor}
                ref={this.containerRef}
                size={this.values.size}
                thickness={this.values.checkThickness}
            >
                {showingCheck && (
                    <Checkmark
                        checkmarkAnimation={this.checkmarkAnimation}
                        color={
                            color || Theme.predictionAnsweredIconBackgroundColor
                        }
                        values={this.values}
                    />
                )}
            </Container>
        );
    }
}

const spin = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;

const Container = styled.div<{
    animate: boolean;
    color: Color;
    size: string;
    thickness: string;
}>`
    margin-bottom: ${(props) => props.size} / 2;
    border: ${(props) => props.thickness} solid rgba(0, 0, 0, 0.2);
    border-left-color: ${(props) => props.color};
    animation: ${(props) =>
        props.animate ? `${spin} 0.8s infinite linear` : 'none'};
    ${(props) =>
        !props.animate
            ? `
        -webkit-animation: none;
        border-color: ${props.color};
        transition: border 500ms ease-out;
        `
            : undefined}
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    opacity: ${(props) => (props.animate ? '1' : '0')};
    transition: opacity ${CHECKMARK_FADE_OUT_DURATION_MILLIS}ms ease-in;
    transition-delay: ${CHECKMARK_FADE_OUT_DELAY_MILLIS}ms;
`;

const Checkmark = styled.div<{
    checkmarkAnimation: string;
    color: Color;
    values: CachedValues;
}>`
    &:after {
        animation: ${(props) => props.checkmarkAnimation} 800ms ease;
        transform: scaleX(-1) rotate(135deg);
        opacity: 1;
        height: ${(props) => props.values.checkHeight};
        width: ${(props) => props.values.checkWidth};
        transform-origin: left top;
        border-right: ${(props) => props.values.checkThickness} solid
            ${(props) => props.color};
        border-top: ${(props) => props.values.checkThickness} solid
            ${(props) => props.color};
        content: '';
        left: ${(props) => props.values.checkLeft};
        top: ${(props) => props.values.checkHeight};
        position: absolute;
    }
`;
