import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import { Color } from 'src/styles/Constants';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Location } from 'history';
import routeUrls, {
    getNavItem,
    NavItem,
    navItems,
    navItemToRoute,
} from 'src/routes';
import { usePartner } from 'src/hooks/usePartner';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    menuItem: {
        fontFamily: 'Graphik Web',
    },
    menuButton: {
        color: Color.P1_WHITE,
    },
    link: {
        color: '#000',
        textDecoration: 'none',
    },
});

const TallyMenu = () => {
    const history = useHistory();
    const classes = useStyles();
    const anchorRef = useRef<null | HTMLDivElement>(null);
    const {
        properties: { hideLeaderboards, hideAccountPage },
    } = usePartner();
    const [open, setOpen] = useState(false);
    const location = useLocation();

    const leaderboardMatch = useRouteMatch<{ leaderboardPanelId: string }>(
        `${routeUrls.leaderboardSummary}/:leaderboardPanelId`,
    );

    const navItem = getNavItem(location.pathname, leaderboardMatch);

    const makeGoTo =
        (location: Location<unknown>, newNavItem: NavItem) => () => {
            // if we are already on play and we hit play nevertheless, we want to get to the events
            const search =
                navItem === newNavItem && newNavItem === navItems.play
                    ? undefined
                    : location.search;

            if (
                navItem === newNavItem &&
                newNavItem === navItems.leaderboard &&
                leaderboardMatch
            ) {
                history.push({
                    pathname: leaderboardMatch.url,
                    search,
                });

                return;
            }

            history.push({
                pathname: navItemToRoute[newNavItem],
                search,
            });

            setOpen(false);
        };

    return (
        <div className={classes.root} ref={anchorRef}>
            <IconButton
                className={classes.menuButton}
                aria-controls="tally-menu"
                aria-haspopup="true"
                onClick={() => setOpen(true)}
            >
                <MenuIcon />
            </IconButton>
            <Menu
                id="tally-menu"
                disableScrollLock
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                anchorEl={anchorRef.current}
                getContentAnchorEl={null}
                keepMounted
                open={open}
                onClose={() => setOpen(false)}
            >
                <MenuItem
                    selected={navItem === navItems.play}
                    className={classes.menuItem}
                    onClick={makeGoTo(location, navItems.play)}
                >
                    <FormattedMessage id="menu.play" />
                </MenuItem>
                {!hideLeaderboards && (
                    <MenuItem
                        selected={navItem === navItems.leaderboard}
                        className={classes.menuItem}
                        onClick={makeGoTo(location, navItems.leaderboard)}
                    >
                        <FormattedMessage id="menu.gameLeaderboard" />
                    </MenuItem>
                )}
                {!hideAccountPage && (
                    <MenuItem
                        className={classes.menuItem}
                        selected={navItem === navItems.account}
                        onClick={makeGoTo(location, navItems.account)}
                    >
                        <FormattedMessage id="menu.account" />
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
};

export default TallyMenu;
