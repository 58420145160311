import { History, Search } from 'history';
import { match } from 'react-router-dom';
import { LocaleId } from './util/LocaleHelpers';

const eventSlugUrlParamKey = 'e' as const;
const languageUrlParamKey = 'language' as const;

export function goToGeneralEventLeaderboard(
    history: History,
    location: Location,
    eventSlug: string,
): void {
    history.push({
        pathname: `${routeUrls.leaderboardSummary}/${eventSlug}${routeUrls.generalLeaderboard}`,
        search: location.search,
    });
}

export const getEventSlugSearchParam = (search: string): string | null =>
    new URLSearchParams(search).get(eventSlugUrlParamKey);

export const getLanguageSearchParam = (search: string): string | null =>
    new URLSearchParams(search).get(languageUrlParamKey);

export const addEventSlugSearchParam = (
    search: Search,
    eventSlug: string,
): Search => {
    const searchParams = new URLSearchParams(search);
    searchParams.set(eventSlugUrlParamKey, eventSlug);
    return searchParams.toString();
};

export const addLanguageSearchParam = (
    search: Search,
    localeId: LocaleId,
): Search => {
    const searchParams = new URLSearchParams(search);
    searchParams.set(languageUrlParamKey, localeId.toLowerCase());
    return searchParams.toString();
};

const leaderboardSummary = '/leaderboard';

// make sure to check ROUTES in partner-web-edge when you edit those
const routeUrls = {
    play: '/play',
    onboarding: '/onboarding',
    leaderboardSummary,
    iterationLeaderboardSummary: `${leaderboardSummary}/iteration`,
    generalLeaderboard: '/general',
    interGroupLeaderboard: '/inter-group',
    intraGroupLeaderboard: '/intra-group',
    group: '/groups/:groupId',
    account: '/account',
    broadcast: '/broadcast',
    tv: '/tv',
    fanzone: '/fanzone',
} as const;

export type RouteUrl = (typeof routeUrls)[keyof typeof routeUrls];

export default routeUrls;

export const navItems = {
    play: 'play',
    leaderboard: 'leaderboard',
    account: 'account',
} as const;

export type NavItem = (typeof navItems)[keyof typeof navItems];

export const navItemToRoute: Record<NavItem, RouteUrl> = {
    play: routeUrls.play,
    leaderboard: routeUrls.iterationLeaderboardSummary,
    account: routeUrls.account,
} as const;

export const getNavItem = (
    pathname: string,
    leaderboardMatch: match<{}> | null,
): NavItem | undefined => {
    if (pathname === '/' || pathname === routeUrls.play) {
        return navItems.play;
    }

    if (leaderboardMatch) {
        return navItems.leaderboard;
    }

    if (pathname === routeUrls.account) {
        return navItems.account;
    }

    return undefined;
};
