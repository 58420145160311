import React from 'react';
import styled from '@emotion/styled';
import { Color, TextStyles, TextStyleTypes } from 'src/styles/Constants';
import TallyMarkdown from 'src/components/shared/ui/TallyMarkdown';
import { usePartner } from 'src/hooks/usePartner';
import { useIntl, FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import useUserAgreement from 'src/hooks/useUserAgreement';

const AgreementTile = () => {
    const intl = useIntl();
    const {
        properties: { onboardingAgreement },
    } = usePartner();
    const { agree } = useUserAgreement();

    const {
        text,
        heading,
        agreeButtonLabel,
        disagreeButtonLabel,
        redirectUrl,
    } = onboardingAgreement!;

    const onAgreeClick = () => {
        agree();
    };

    const onDisagreeClick = () => {
        window.location.href = redirectUrl;
    };

    const headingText = heading || (
        <FormattedMessage id="onboardingAgreement.header" />
    );
    const descriptionText =
        text ||
        intl.formatMessage({
            id: 'onboardingAgreement.text',
        });
    const agreeButtonText = agreeButtonLabel || (
        <FormattedMessage id="onboardingAgreement.button.agree" />
    );
    const disagreeButtonText = disagreeButtonLabel || (
        <FormattedMessage id="onboardingAgreement.button.disagree" />
    );

    return (
        <>
            <TileTitle>{headingText}</TileTitle>
            <DescriptionText>
                <TallyMarkdown
                    source={descriptionText}
                    paragraphPaddingBottom="0"
                />
            </DescriptionText>
            <ButtonContainer>
                <Button
                    style={{
                        borderRadius: 0,
                        backgroundColor: Color.G2_CARBON_FIBER,
                        color: Color.P1_WHITE,
                    }}
                    onClick={onAgreeClick}
                >
                    {agreeButtonText}
                </Button>
                <Button
                    style={{
                        borderRadius: 0,
                        backgroundColor: Color.G7_FOG,
                        color: Color.T31_BLACK,
                    }}
                    onClick={onDisagreeClick}
                >
                    {disagreeButtonText}
                </Button>
            </ButtonContainer>
        </>
    );
};

export default AgreementTile;

const TileTitle = styled.div`
    ${TextStyles[TextStyleTypes.H3]}
    border-bottom: 2px solid ${Color.G8_CONCRETE};
    text-align: left;
    width: 100%;
`;

const DescriptionText = styled.div`
    margin: 15px 0 0 0;
    ${TextStyles[TextStyleTypes.P1]}
    text-align: left;
    width: 100%;
`;

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 15px 0 15px 0;
    column-gap: 20px;
`;
