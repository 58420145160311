import React from 'react';
import dateFormat from 'dateformat';
import { FormattedMessage } from 'react-intl';
import { TextStyleTypes, TextStyles } from 'src/styles/Constants';
import styled from '@emotion/styled';

function Expiration(props: { expirationDate: Date }) {
    const { expirationDate } = props;
    const expired = expirationDate.getTime() <= new Date().getTime();
    return (
        <ExpirationText>
            <FormattedMessage
                id={
                    expired
                        ? 'offerDetails.expirationText.expired'
                        : 'offerDetails.expirationText.expires'
                }
                values={{
                    when: dateFormat(expirationDate, 'mm/dd'),
                }}
            />
        </ExpirationText>
    );
}
export default Expiration;

const ExpirationText = styled.p`
    ${TextStyles[TextStyleTypes.B2]};
`;
