import {
    AdditionalDataFieldsTypeEnum,
    AdditionalDataCollectionField,
    DateValidationAfter,
    DateValidationBefore,
    DateValidationBetween,
    DateValidationOlderThan,
    DateValidationType,
} from 'src/util/ConfigTypes';

export const EMAIL_REGEX: RegExp =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const DISPLAY_NAME_MIN_LENGTH = 3;
export const DISPLAY_NAME_MAX_LENGTH = 15;
export const DISPLAY_NAME_REGEX: RegExp = new RegExp(
    `(?!^[\\d_-]+$)^[a-zA-Z-_\\d]{${DISPLAY_NAME_MIN_LENGTH},${DISPLAY_NAME_MAX_LENGTH}}$`,
);

export const isValidEmail = (email: string) => EMAIL_REGEX.test(email);

export const regexpValidation = ({
    validationRegexp,
    value,
}: {
    validationRegexp?: RegExp;
    value?: string;
}): boolean => {
    if (validationRegexp) {
        if (!validationRegexp.test(value || '')) {
            return false;
        }
    }
    return true;
};

export const dateBetweenValidation = ({
    value,
    dateValidation,
}: {
    value: string;
    dateValidation: DateValidationBetween;
}): boolean => {
    const { dateFrom, dateTo } = dateValidation;

    return !(
        dateFrom &&
        dateTo &&
        (new Date(value) < dateFrom || new Date(value) > dateTo)
    );
};
export const dateAfterValidation = ({
    value,
    dateValidation,
}: {
    value: string;
    dateValidation: DateValidationAfter;
}): boolean => {
    const { dateFrom } = dateValidation;
    return !(dateFrom && new Date(value) < dateFrom);
};
export const dateBeforeValidation = ({
    value,
    dateValidation,
}: {
    value: string;
    dateValidation: DateValidationBefore;
}): boolean => {
    const { dateTo } = dateValidation;
    return !(dateTo && new Date(value) > dateTo);
};
export const dateOlderThanYOValidation = ({
    value,
    dateValidation,
}: {
    value: string;
    dateValidation: DateValidationOlderThan;
}): boolean => {
    const today = new Date();
    const birthDate = new Date(value);
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    const { olderThanYO } = dateValidation;
    return !(
        new Date(value) > new Date() || //check future date input
        (olderThanYO && age < olderThanYO)
    );
};

export const validateAdditionalDataCollection = (
    value: string,
    additionalDataFieldMeta?: AdditionalDataCollectionField<string>,
): boolean => {
    if (additionalDataFieldMeta) {
        switch (additionalDataFieldMeta.type) {
            case AdditionalDataFieldsTypeEnum.DATE:
                const { dateValidation } = additionalDataFieldMeta;
                if (dateValidation) {
                    switch (dateValidation.type) {
                        case DateValidationType.BETWEEN:
                            return dateBetweenValidation({
                                value,
                                dateValidation,
                            });

                        case DateValidationType.AFTER:
                            return dateAfterValidation({
                                value,
                                dateValidation,
                            });

                        case DateValidationType.BEFORE:
                            return dateBeforeValidation({
                                value,
                                dateValidation,
                            });

                        case DateValidationType.OLDER_THAN_Y_O:
                            return dateOlderThanYOValidation({
                                value,
                                dateValidation,
                            });
                        default:
                            return true;
                    }
                }
                return true;
            case AdditionalDataFieldsTypeEnum.SELECT:
            case AdditionalDataFieldsTypeEnum.TEXT:
                return regexpValidation({
                    validationRegexp: additionalDataFieldMeta.validationRegexp
                        ? new RegExp(additionalDataFieldMeta.validationRegexp)
                        : undefined,
                    value,
                });
            default:
                return true;
        }
    }
    return true;
};

export class ValidationError extends Error {
    public field: string;
    constructor({ message, field }: { message?: string; field: string }) {
        super(message);
        this.field = field;
    }
}
