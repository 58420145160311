import React from 'react';
import styled from '@emotion/styled';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import tallySquare from 'src/images/icons/tally-square.png';
import routeUrls, { addEventSlugSearchParam } from 'src/routes';
import { EventModel } from 'src/util/TallyFirestore';
import { CustomTypography } from './shared';
import TallyMarkdown from 'src/components/shared/ui/TallyMarkdown';

type Props = {
    headline?: string;
    events: [string, EventModel][];
    headlineColor: string;
};

const MAX_EVENT_IMAGE_HEIGHT = 120 as const;
const MIN_EVENT_IMAGE_HEIGHT = 100 as const;

const GAP = 16;
const useStyles = makeStyles({
    eventList: {
        display: 'flex',
        gap: GAP,
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        alignItems: 'start',
    },
    eventListItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    eventShortName: {
        width: 0,
        minWidth: '100%',
    },
});

const EventGridSection = ({ headline, events, headlineColor }: Props) => {
    const styles = useStyles();

    return (
        <Box sx={{ marginTop: 40 }}>
            {headline && (
                <CustomTypography variant="h1" color={headlineColor}>
                    <TallyMarkdown source={headline} />
                </CustomTypography>
            )}

            <div className={styles.eventList}>
                {events.map(([slug, event]) => {
                    return (
                        <Link
                            key={slug}
                            className={styles.eventListItem}
                            to={`${routeUrls.play}?${addEventSlugSearchParam(
                                window.location.search,
                                event.slug,
                            )}`}
                        >
                            <StyledImg
                                height={MIN_EVENT_IMAGE_HEIGHT}
                                width={MIN_EVENT_IMAGE_HEIGHT}
                                src={event.eventImageUrl || tallySquare}
                            />
                            {!event.eventImageUrl && (
                                <Typography
                                    align="center"
                                    color="secondary"
                                    className={styles.eventShortName}
                                >
                                    {event.shortName}
                                </Typography>
                            )}
                        </Link>
                    );
                })}
            </div>
        </Box>
    );
};

const StyledImg = styled.img`
    max-height: ${MAX_EVENT_IMAGE_HEIGHT}px;
    min-height: ${MIN_EVENT_IMAGE_HEIGHT}px;
    height: calc(30vw - ${GAP}px * 2);
    width: auto;
`;

export default EventGridSection;
