import { createContext, useContext } from 'react';
import { InitialEventData } from 'src/util/Config';

const InitialEventDataContext = createContext({} as InitialEventData);

export const InitialEventDataProvider = InitialEventDataContext.Provider;
export const InitialEventDataConsumer = InitialEventDataContext.Consumer;

export const useInitialEventData = () => {
    return useContext(InitialEventDataContext);
};
