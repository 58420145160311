import React, { Component } from 'react';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';
import { Color } from 'src/styles/Constants';
import { FeedPrediction } from 'src/services/ServerApi';
import { keyframes } from 'emotion';
import CountdownTimer from '../shared/ui/CountdownTimer';

interface Props {
    prediction: FeedPrediction;
    displayOnly?: boolean;
}

interface State {
    copiedSuccessAnimation?: string;
    hideCountdown: boolean;
}

export default class BroadcastSideBoard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            hideCountdown: false,
        };
    }

    private handleCopyGenerator = (textToCopy: string) => {
        return () => {
            this.handleCopyQuestion(textToCopy);
        };
    };

    private handleCopyQuestion = (textToCopy: string) => {
        const hiddenEl = document.createElement('textarea');
        hiddenEl.value = textToCopy;
        document.body.appendChild(hiddenEl);
        hiddenEl.select();
        document.execCommand('copy');
        document.body.removeChild(hiddenEl);
        this.showCopiedText();
    };

    private showCopiedText = () => {
        this.setState({
            copiedSuccessAnimation: FADE_IN,
        });

        setTimeout(
            () =>
                this.setState({
                    copiedSuccessAnimation: FADE_OUT,
                }),
            1000,
        );
    };

    private hideCountdown = () => {
        this.setState({
            hideCountdown: true,
        });
    };

    public render() {
        const { prediction } = this.props;
        const { copiedSuccessAnimation, hideCountdown } = this.state;
        const { options } = prediction;
        const isMultipleChoice = options.length > 1;

        const lockDateToDate = new Date(prediction.lockDate);

        return (
            <PredictionContainer>
                <Timer
                    onCountdownExpired={this.hideCountdown}
                    startDate={lockDateToDate}
                />
                {!hideCountdown && <HorizontalBorder />}
                <QuestionText>{prediction.text}</QuestionText>
                {!this.props.displayOnly && (
                    <CopyQuestionButton
                        onClick={this.handleCopyGenerator(prediction.text)}
                    >
                        <FormattedMessage id="broadcastSideboard.copyQuestionButton" />
                    </CopyQuestionButton>
                )}
                <ClipboardText animation={copiedSuccessAnimation}>
                    <FormattedMessage id="broadcastSideboard.clipboardText" />
                </ClipboardText>
                <HorizontalBorder />
                {isMultipleChoice &&
                    options.map((option, index) => {
                        const usersChoicePercentage =
                            option.usersChoicePercentage.slice(0, -3);

                        return (
                            <OptionContainer key={index}>
                                <AnswerOption>- {option.text}</AnswerOption>
                                <PercentSpan>
                                    {usersChoicePercentage}%
                                </PercentSpan>
                                {!this.props.displayOnly && (
                                    <CopyOptionButton
                                        onClick={this.handleCopyGenerator(
                                            option.text,
                                        )}
                                    >
                                        <FormattedMessage id="broadcastSideboard.copyOptionButton" />
                                    </CopyOptionButton>
                                )}
                            </OptionContainer>
                        );
                    })}
                {!isMultipleChoice && (
                    <PercentSpan>
                        <FormattedMessage id="broadcastSideboard.clipboardText" />
                    </PercentSpan>
                )}
            </PredictionContainer>
        );
    }
}

const PredictionContainer = styled.div`
    background-color: ${Color.P1_WHITE};
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    margin-left: 18px;
    margin-right: 25px;
    margin-bottom: 20px;
    padding: 25px;
    max-width: 490px;
`;

const Timer = styled(CountdownTimer)`
    color: ${Color.P4_CHILI_PEPPER};
    font-size: 30px;
    font-weight: bold;
    letter-spacing: -0.5px;
    line-height: 37px;
`;

const HorizontalBorder = styled.div`
    background-color: #dedede;
    height: 2px;
    margin-bottom: 25px;
`;

const QuestionText = styled.p`
    color: ${Color.T31_BLACK};
    font-size: 30px;
    letter-spacing: -0.5px;
    line-height: 40px;
    margin-bottom: 20px;
    margin-top: 24px;
`;

const CopyQuestionButton = styled.button`
    cursor: pointer;
    border-radius: 5px;
    background-color: #383838;
    color: ${Color.P1_WHITE};
    font-size: 16px;
    font-weight: 500;
    height: 40px;
    letter-spacing: 2.33px;
    line-height: 19px;
    margin-bottom: 35px;
    width: 206px;
    &:focus {
        outline: none;
    }
`;

const ClipboardText = styled.span<{ animation?: string }>`
    animation: ${(props) => props.animation} 1.1s ease;
    margin-left: 15px;
    opacity: 0;
`;

const OptionContainer = styled.div`
    align-items: flex-end;
    display: flex;
    padding: 20px 0px;
`;

const AnswerOption = styled.span`
    color: ${Color.T31_BLACK};
    font-size: 30px;
    letter-spacing: -0.5px;
    line-height: 26px;
`;

const CopyOptionButton = styled.button`
    background-color: ${Color.P1_WHITE};
    border: 1px solid #979797;
    border-radius: 5px;
    color: #9b9b9b;
    font-size: 16px;
    font-weight: 500;
    height: 40px;
    letter-spacing: 2.33px;
    line-height: 18px;
    margin-left: auto;
    width: 80px;
    &:focus {
        outline: none;
    }
    &:hover {
        border-color: ${Color.T31_BLACK};
        color: ${Color.T31_BLACK};
        cursor: pointer;
    }
`;

const PercentSpan = styled.span`
    color: #9b9b9b;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 2.33px;
    line-height: 18px;
    margin: 0px 14px;
`;

const FADE_IN = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const FADE_OUT = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;
