import styled from '@emotion/styled';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    isOptionWithImageTriviaQuestion,
    isTextOnlyOptionTriviaQuestion,
} from 'src/services/ServerApi';

import {
    Color,
    StyleDefaults,
    TextStyles,
    TextStyleTypes,
} from 'src/styles/Constants';
import { isNoPointsTriviaGame } from 'src/util/TallyFirestore';
import TextOption from './TextOption';
import ImageOption from './ImageOption';
import {
    calculateDecreasingPoints,
    getAnswerStatus,
    getAnswerStatusColor,
    getQuestionResult,
    isAnswered,
    isLoadingAnswer,
    Props,
} from './utils';
import { BackgroundPanel } from 'src/components/shared/ui/BackgroundPanel';

const MultipleChoiceQuestion = (props: Props) => {
    const {
        question,
        trivia,
        screenHasBg,
        txtBgOpacity = 100,
        imgDisclaimer,
    } = props;
    const questionResult = getQuestionResult(props);
    const containsTextOnlyOptions = isTextOnlyOptionTriviaQuestion(question);
    const optionsContainImages = isOptionWithImageTriviaQuestion(question);
    const optionsContainsVideo = question.ad !== undefined;
    const isNoPointsMode = isNoPointsTriviaGame(trivia);

    return (
        <Container>
            {!isNoPointsMode && questionResult ? (
                <StatusContainer color={getAnswerStatusColor(questionResult)}>
                    <AnswerStatusLabel>
                        {getAnswerStatus(questionResult)}
                    </AnswerStatusLabel>
                    {typeof questionResult === 'object' &&
                        !!questionResult.points && (
                            <Points>+{questionResult.points} pts</Points>
                        )}
                </StatusContainer>
            ) : isNoPointsMode ? null : (
                <StatusContainer color={Color.T31_BLACK}>
                    <AnswerStatusLabel>
                        <Points>
                            {trivia.mode === 'noCountdownFullPoints' ||
                            trivia.mode === 'countdownFullPoints' ? (
                                <FormattedMessage
                                    id="triviaGame.status.fullPoints"
                                    values={{
                                        totalPoints: trivia.pointsPerQuestion,
                                    }}
                                />
                            ) : (
                                <FormattedMessage
                                    id="triviaGame.status.pointsCountdown"
                                    values={{
                                        points: calculateDecreasingPoints({
                                            countdownSeconds:
                                                props.countdownSeconds,
                                            pointsPerQuestion:
                                                trivia.pointsPerQuestion,
                                            secondsPerQuestion:
                                                trivia.secondsPerQuestion,
                                        }),
                                        totalPoints: trivia.pointsPerQuestion,
                                    }}
                                />
                            )}
                        </Points>
                    </AnswerStatusLabel>
                </StatusContainer>
            )}
            <TitleLabel
                big={!optionsContainsVideo}
                centered={!containsTextOnlyOptions}
            >
                <BackgroundPanel
                    screenHasBg={screenHasBg}
                    opacity={txtBgOpacity}
                >
                    {question.text}
                </BackgroundPanel>
            </TitleLabel>
            {containsTextOnlyOptions ? (
                <TextOptionsWrapper>
                    {question.options.map(({ id, text }) => {
                        return isLoadingAnswer(props) ? (
                            <TextOption
                                key={id}
                                type="answerLoading"
                                text={text}
                                picked={id === props.selectedOptionId}
                            />
                        ) : isAnswered(props) ? (
                            <TextOption
                                key={id}
                                type="answered"
                                text={text}
                                correct={id === props.question.correctOptionId}
                                picked={id === props.question.selectedOptionId}
                            />
                        ) : (
                            <TextOption
                                key={id}
                                type="answerable"
                                text={text}
                                onSelect={() => {
                                    props.onAnswer(id);
                                }}
                            />
                        );
                    })}
                </TextOptionsWrapper>
            ) : optionsContainImages ? (
                <>
                    <ImageOptionsWrapper>
                        {question.options.map(({ id, imageUrl, text }) => {
                            return isLoadingAnswer(props) ? (
                                <ImageOption
                                    key={id}
                                    type="answerLoading"
                                    imageUrl={imageUrl}
                                    text={text}
                                    picked={id === props.selectedOptionId}
                                />
                            ) : isAnswered(props) ? (
                                <ImageOption
                                    key={id}
                                    type="answered"
                                    imageUrl={imageUrl}
                                    text={text}
                                    correct={
                                        id === props.question.correctOptionId
                                    }
                                    picked={
                                        id === props.question.selectedOptionId
                                    }
                                />
                            ) : (
                                <ImageOption
                                    key={id}
                                    type="answerable"
                                    imageUrl={imageUrl}
                                    text={text}
                                    onSelect={() => {
                                        props.onAnswer(id);
                                    }}
                                />
                            );
                        })}
                    </ImageOptionsWrapper>
                    {imgDisclaimer && (
                        <ImageDisclaimer>{imgDisclaimer}</ImageDisclaimer>
                    )}
                </>
            ) : null}
        </Container>
    );
};

export default MultipleChoiceQuestion;

const TitleLabel = styled.div<{ big: boolean; centered: boolean }>`
    display: inline-block;
    width: 100%;
    ${(props) =>
        props.big
            ? TextStyles[TextStyleTypes.S1]
            : TextStyles[TextStyleTypes.P3]}
    text-align: ${(props) => (props.centered ? 'center' : 'left')};
    transition:
        font-weight 0.3s,
        font-size 0.3s;
`;

const Container = styled.div`
    text-align: center;
    align-self: center;
    position: relative;
    top: 0;
    transition: border 0.4s;
    width: ${StyleDefaults.MAX_ELEMENT_WIDTH};
    margin-top: 20px;
    box-sizing: border-box;
`;

const StatusContainer = styled.div<{ color: string }>`
    display: flex;
    color: ${(props) => props.color};
`;

const AnswerStatusLabel = styled.div`
    font-size: 12px;
    letter-spacing: 0.34px;
    line-height: 16px;
    text-transform: uppercase;
`;

const Points = styled(AnswerStatusLabel)`
    font-weight: 600;
    margin-left: 4px;
`;

const TextOptionsWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 15px 0 17px 0;
`;

const ImageOptionsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 10px;
    width: 100%;
`;
const ImageDisclaimer = styled.div`
    color: ${Color.G11_WHITE};
`;
