import styled from '@emotion/styled';
import { Color, StyleDefaults, PistachioZIndex } from '../../styles/Constants';

export const BaseEventPageContainer = styled.div<{
    visible: boolean;
    backgroundColor?: string;
    backgroundImage?: string;
    color?: string;
}>`
    display: ${(props) => (props.visible ? 'flex' : 'none')};
    flex-direction: column;
    align-items: center;
    max-width: ${StyleDefaults.MAX_ELEMENT_WIDTH};
    width: 100%;
    background-color: ${(props) => props.backgroundColor || Color.P1_WHITE};
    background-image: url('${(props) => props.backgroundImage}');
    background-repeat: no-repeat;
    background-position: center top;
    color: ${(props) => props.color || Color.T31_BLACK};
`;

export const GameBG = styled.div<{
    backgroundColor?: string;
    backgroundImgUrl?: string;
    backgroundDesktopImgUrl?: string;
    backgroundBtmAlign?: boolean;
    color?: string;
}>`
    position: fixed;
    z-index: ${PistachioZIndex.BACKGROUND_DIV};
    top: 0px;
    height: 100%;
    width: 100%;

    /* default: mobile */
    background-image: url('${(props) => props.backgroundImgUrl}');

    /* DT case */
    @media (min-width: 801px) {
        background-image: url('${(props) =>
            props.backgroundDesktopImgUrl
                ? props.backgroundDesktopImgUrl
                : props.backgroundImgUrl}');
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    background-color: ${(props) => props.backgroundColor || Color.P1_WHITE};
    background-repeat: no-repeat;
    background-position: center
        ${(props) => (props.backgroundBtmAlign ? 'bottom' : 'top')};
    background-attachment: local;
    background-size: 100vw;
`;

/**
 *  max-width: 500px;
    background-size: 100%;
 */
