import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { OrderedMap } from 'immutable';
import { animateScroll as scroll } from 'react-scroll';
import { Redirect, Route, useHistory } from 'react-router-dom';
import firebase, { QuerySnapshot } from 'src/services/Firebase';
import AccountPage from 'src/components/AccountPage';
import GroupPage from 'src/components/GroupPage';
import PredictGame from 'src/components/pistachioGame/PredictGame';
import EventIframeAd from 'src/components/shared/events/EventIframeAd';
import Toast, {
    NextPredictionToLockInfo,
    ShowableToast,
    ToastDetails,
} from 'src/components/shared/ui/Toast';
import {
    AdCardContentContainer,
    StickyContainer,
} from 'src/Iteration/components';
import routeUrls from 'src/routes';
import { TranslatedConfigPartnerData } from 'src/util/ConfigTypes';
import {
    AdSizingPolicy,
    Collection,
    containsPrizeRedemptionSponsorshipUnit,
    EventModel,
    PredictionEventModel,
    PredictionModel,
    ProgressivePollAnswer,
    ProgressivePollModel,
    SponsorModel,
    TranslatablePredictionModel,
} from 'src/util/TallyFirestore';
import { AnswerCorrectness } from 'src/util/EventHelpers';
import { debounce } from 'lodash';
import {
    checkHasUnansweredActivePrediction,
    getEventsProgressivePollsAndAnswers,
    getPredictionsFromQuerySnapshot,
    makePollToast,
    makePredictionToast,
    transformPredictionsToExpectedType,
} from './util';
import PistachioTitlebar from 'src/components/pistachioGame/PistachioTitlebar';
import {
    TranslatedIteration,
    translateFirestorePrediction,
    translateGroupedPredictions,
} from 'src/util/i18n';
import { useLocale } from 'src/hooks/useLocale';
import {
    GroupedPredictions,
    GroupedTranslatablePredictions,
    isPredictionAnswered,
    predictionIsCorrect,
} from 'src/util/PredictionHelpers';
import { getDetails, useUserState } from 'src/contexts/UserStateContext';
import { useIntl } from 'react-intl';
import PistachioGrouper from 'src/components/pistachioGame/PistachioGrouper';
import PistachioPredictionChangeDetector from 'src/components/shared/renderless/PistachioPredictionChangeDetector';
import { ModalType } from 'src/components/shared/modals/ModalHoster';
import useAnswers from './hooks/useAnswers';
import PrivateRoute from 'src/components/shared/PrivateRoute';
import Onboarding from 'src/components/pistachioGame/onboarding/Onboarding';
import LeaderboardSummaryPage from 'src/Iteration/MultiEvent/LeaderboardSummaryPage';
import { Leaderboards } from 'src/hooks/useLiveEntityLeaderboards';
import { Color } from 'src/styles/Constants';
import HeroicCountdown from 'src/components/pistachioGame/PredictGame/HeroicCountdown';
import PresentedBy from 'src/components/shared/ui/PresentedBy';
import useLiveFirestoreCollection from 'src/hooks/useLiveFirestoreCollection';
import {
    getProgressivePollAnswersFromDocumentSnapshot,
    getProgressivePollFromDocumentSnapshot,
    getUserPPollAnswersPath,
} from 'src/util/FirestoreDao';
import useLiveFirestoreDocument from 'src/hooks/useLiveFirestoreDocument';
import { useModalHoster } from 'src/contexts/ModalHosterContext';
import { getBonusSponsors } from 'src/util/sponsor';
import { WithNotNullProperty } from 'src/utilityTypes';
import { ShowEventPrizeDetailsContext } from 'src/contexts/ShowEventPrizeDetailsContext';
import { useAnalytics } from 'src/contexts/AnalyticsContext';
import { getEventUITheme } from '../utils';
import { BaseEventPageContainer, GameBG } from '../components';

type Props = {
    partner: TranslatedConfigPartnerData;
    sponsors: SponsorModel[];
    gameWideSponsor?: SponsorModel;
    iteration: TranslatedIteration;
    events: OrderedMap<string, EventModel>;
    iterationLeaderboards?: Leaderboards;
    event: PredictionEventModel;
    eventLeaderboards: Leaderboards;
};

type GroupedPredictionsState = {
    groupedPredictions: GroupedPredictions;
    groupedPredictionsUpdatedTimestamp: Date;
};

const initialGroupedPredictionsState: GroupedPredictionsState = {
    groupedPredictions: {
        live: {
            automatic: [],
            manual: [],
        },
        final: [],
        pending: { group: 'Pending', entities: [] },
    },
    groupedPredictionsUpdatedTimestamp: new Date(),
};

const onCountdownBarClick = () => {
    scroll.scrollToTop({
        duration: 300,
    });
};

const emptyArray: any[] = [];

const PredictionEvent = ({
    partner,
    iteration,
    events,
    sponsors,
    gameWideSponsor,
    iterationLeaderboards,
    event,
    eventLeaderboards,
}: Props) => {
    const analytics = useAnalytics();
    const userData = useUserState();
    const intl = useIntl();
    const history = useHistory();

    const modalHoster = useModalHoster();
    const { localeId } = useLocale();

    const toastRef = useRef<null | ShowableToast>(null);
    const predictionGrouperRef = useRef<null | PistachioGrouper>(null);

    const [
        { groupedPredictions, groupedPredictionsUpdatedTimestamp },
        setGroupedPredictionsState,
    ] = useState<GroupedPredictionsState>(initialGroupedPredictionsState);

    const [lastBatchReleased, setLastBatchReleased] = useState(false);
    const [nextPredictionToLock, setNextPredictionToLock] = useState<
        TranslatablePredictionModel | undefined
    >(undefined);
    const [nextPredictionToLockInfo, setNextPredictionToLockInfo] = useState<
        NextPredictionToLockInfo | undefined
    >(undefined);

    const [predictions, setPredictions] = useState<
        TranslatablePredictionModel[]
    >([]);

    const [predictionsUpdatedTimestamp, setPredictionsUpdatedTimestamp] =
        useState<Date>(new Date());

    const bonusSponsors = getBonusSponsors(sponsors, predictions);
    const showEventPrizeDetails =
        modalHoster &&
        ((event: WithNotNullProperty<EventModel, 'prizeDetailsMarkdown'>) =>
            modalHoster.showModal({
                bonusSponsors,
                event,
                gameWideSponsor,
                modalType: ModalType.PRIZES,
            }));

    const { answers, oddClaims, answersUpdatedTimestamp } = useAnswers(
        event.id,
    );

    const progressivePolls = useLiveFirestoreCollection<ProgressivePollModel>(
        `${iteration.path}/${Collection.progressivePolls}`,
        getProgressivePollFromDocumentSnapshot,
        emptyArray,
    );

    const progressivePollsAnswers = useLiveFirestoreDocument<
        ProgressivePollAnswer[]
    >(
        getUserPPollAnswersPath({
            partnerId: partner.partnerId,
            iterationId: iteration.id,
            uid: userData.uid,
        }),
        emptyArray,
        getProgressivePollAnswersFromDocumentSnapshot,
    );

    const eventPath = event.path;

    const onPredictionLocked = () => {
        if (predictionGrouperRef.current) {
            predictionGrouperRef.current.forceRegroup();
        }
    };

    useEffect(() => {
        const setPredictionStateWrapper = (predictions: PredictionModel[]) => {
            setPredictions(transformPredictionsToExpectedType(predictions));
            setPredictionsUpdatedTimestamp(new Date());
        };

        // ATODO: why was it implemented like this in the first place???
        const debouncedFunction = debounce(setPredictionStateWrapper, 300, {
            leading: false,
            trailing: true,
        });

        const onPredictionsUpdate = (snapshot: QuerySnapshot) => {
            const predictions =
                getPredictionsFromQuerySnapshot(event)(snapshot);

            debouncedFunction(
                // TODO: rewrite with firestore query to subscribe to visible predictions only
                // (once firebase is updated)
                predictions.filter((prediction) => (prediction as any).visible),
            );
        };

        // TODO: rewrite with firestore query to subscribe to visible predictions only
        // (once firebase is updated)
        return firebase
            .firestore()
            .collection(`${eventPath}/${Collection.predictions}`)
            .onSnapshot(onPredictionsUpdate);
    }, [event, sponsors]);

    const { availableProgressivePolls, thisEventProgressivePollsAnswers } =
        getEventsProgressivePollsAndAnswers({
            event,
            progressivePolls,
            progressivePollsAnswers,
        });

    const onNewPredictionsGrouped = (
        groupedPredictions: GroupedTranslatablePredictions,
    ) => {
        setGroupedPredictionsState({
            groupedPredictions:
                translateGroupedPredictions(localeId)(groupedPredictions),
            groupedPredictionsUpdatedTimestamp: new Date(),
        });
    };

    const onLastBatchReleased = () => {
        setLastBatchReleased(true);
    };

    const onPredictionsResolved = (resolved: PredictionModel[]) => {
        let atleastOnePrediction = false;
        const answeredPredictions: PredictionModel[] = [];
        resolved.map((prediction) => {
            if (isPredictionAnswered(prediction, answers[prediction.id])) {
                answeredPredictions.push(prediction);
            }
            if (prediction.type !== 'POLL') {
                atleastOnePrediction = true;
            }
        });

        if (answeredPredictions.length === 0) {
            return;
        }

        let toastTarget: [ToastDetails, string];

        // predictions always take priority. as long as there is 1,
        // no matter how many polls, show prediction toasts
        if (atleastOnePrediction) {
            toastTarget = makePredictionToast({
                intl,
                localeId,
                userDetails: getDetails(userData),
                predictions: answeredPredictions,
                answers,
                oddClaims,
            });
        } else {
            toastTarget = makePollToast({
                intl,
                predictions: answeredPredictions,
            });
        }

        for (const prediction of answeredPredictions) {
            const userAnswer = answers[prediction.id];
            const answeredCorrectly =
                predictionIsCorrect(prediction, userAnswer) ===
                AnswerCorrectness.correct;

            if (
                answeredCorrectly &&
                prediction.sponsorship &&
                containsPrizeRedemptionSponsorshipUnit(prediction)
            ) {
                const sponsorship = prediction.sponsorship;
                const sponsor = bonusSponsors.find(
                    (s) => s.id === sponsorship.sponsorId,
                );
                if (sponsor && modalHoster) {
                    modalHoster.showModal({
                        modalType: ModalType.OFFER_WIN,
                        onSeeRewardsClick: () =>
                            history.push(routeUrls.account),
                        prediction,
                        sponsor,
                        userAnswer,
                    });
                }
                // just pop the modal for the first won offer.
                break;
            }
        }

        const [toastDetails] = toastTarget;

        if (toastRef.current) {
            toastRef.current.show(toastDetails);
        }
    };

    const onNextPredictionToLock = (nextPredictionToLock?: PredictionModel) => {
        // TODO: tackle this in tp-422
        // Disable for now, as we don't the pop up to be shown on the /onboarding
        // and /play pages.
        // if (modalHoster) {
        //     if (nextPredictionToLock && location.pathname !== routeUrls.play) {
        //         modalHoster.showModal({
        //             modalType: ModalType.NEW_PREDICTION,
        //             activePrediction: translateFirestorePrediction(localeId)(
        //                 nextPredictionToLock
        //             ),
        //             onPredictNowClick: () => {
        //                 history.push({
        //                     pathname: routeUrls.play,
        //                     search: location.search,
        //                 });
        //             },
        //         });
        //     }
        // }

        setNextPredictionToLock(nextPredictionToLock);
    };

    const onCountdownUpdated = (
        prediction: PredictionModel,
        countdown?: number,
    ) => {
        const soonestToLockAutomaticPredictions = groupedPredictions.live
            .automatic[0]
            ? groupedPredictions.live.automatic[0].entities
            : [];

        // nextPredictionToLockInfo stores whether or not we have answered the prediction yet, via
        // hasAnwered. For a single prediction, this is straightforward. For a batch of predictions,
        // it is true if all predictions have been answered by the user (false otherwise).
        let hasAnsweredAllInBatch = false;

        if (answers) {
            // onCountdownUpdated only gets called back with a single predictionId (that is next to unlock).
            // Use it as our starting point.
            hasAnsweredAllInBatch = !!answers[prediction.id];

            // Now we go through the entire batch. There may be multiple predictions that have the same upcoming
            // lock time. If that's the case, we want to see if we've answered them all.
            if (hasAnsweredAllInBatch) {
                for (const predictionInBatch of soonestToLockAutomaticPredictions) {
                    if (!answers[predictionInBatch.id]) {
                        hasAnsweredAllInBatch = false;
                        break;
                    }
                }
            }
        }
        const nextPredictionToLockInfo: NextPredictionToLockInfo =
            countdown && countdown < 7776000 // if it is less than roughly 3 months
                ? {
                      hasAnswered: hasAnsweredAllInBatch,
                      lockInfo: {
                          timeLeftCount: countdown,
                          lockDate: prediction.lockDate.toDate(),
                      },
                  }
                : {
                      lockInfo: 'lockDescription',
                      hasAnswered: hasAnsweredAllInBatch,
                  };

        setNextPredictionToLockInfo(nextPredictionToLockInfo);
    };

    const isAdSticky =
        event.adPinned &&
        event.adSizingPolicy === AdSizingPolicy.preserveAspectRatio;

    const shouldDisplayAd = event.adEnabled && event.adIframeHtml;

    const hasUnansweredActivePrediction = checkHasUnansweredActivePrediction(
        groupedPredictions,
        answers,
    );

    const presentedBySponsorLogo = event.sponsorship
        ? event.sponsorship.properties.gameWideLogoUrl ||
          event.sponsorship.properties.gameLogoUrl
        : undefined;

    return (
        <ShowEventPrizeDetailsContext.Provider value={showEventPrizeDetails}>
            <StickyContainer>
                <PistachioTitlebar iteration={iteration} event={event} />
                <Toast
                    event={event}
                    nextPredictionToLock={
                        nextPredictionToLock &&
                        translateFirestorePrediction(localeId)(
                            nextPredictionToLock,
                        )
                    }
                    nextPredictionToLockInfo={nextPredictionToLockInfo}
                    onLockTimeClick={onCountdownBarClick}
                    ref={toastRef}
                />
                {isAdSticky &&
                    shouldDisplayAd &&
                    (event.adIframeHtml ? (
                        <AdCardContentContainer>
                            <EventIframeAd
                                eventId={event.id}
                                eventAd={{
                                    iframeHtml: event.adIframeHtml,
                                    headline: event.adHeadline,
                                    disclaimer: event.adDisclaimer,
                                    sizingPolicy: event.adSizingPolicy,
                                    cutOffHeight: event.adCutOffHeight,
                                }}
                            />
                        </AdCardContentContainer>
                    ) : null)}
            </StickyContainer>
            <Route path={routeUrls.onboarding}>
                <Onboarding
                    partner={partner}
                    iteration={iteration}
                    event={event}
                    gameWideSponsor={gameWideSponsor}
                />
            </Route>
            <PrivateRoute
                path={routeUrls.play}
                children={(props) => (
                    <>
                        {Boolean(props.match) && (
                            <GameBG {...getEventUITheme(event, partner)}>
                                &nbsp;
                            </GameBG>
                        )}
                        <PredictPageContainer
                            visible={Boolean(props.match)}
                            backgroundColor="rgba(0,0,0,0)"
                        >
                            {presentedBySponsorLogo && (
                                <PresentedByBanner>
                                    <PresentedBy
                                        sponsorshipUnit={event.sponsorship}
                                        asset={presentedBySponsorLogo}
                                    />
                                </PresentedByBanner>
                            )}
                            {!isAdSticky &&
                                shouldDisplayAd &&
                                (event.adIframeHtml ? (
                                    <AdCardContentContainer>
                                        <EventIframeAd
                                            eventId={event.id}
                                            eventAd={{
                                                iframeHtml: event.adIframeHtml,
                                                headline: event.adHeadline,
                                                disclaimer: event.adDisclaimer,
                                                sizingPolicy:
                                                    event.adSizingPolicy,
                                                cutOffHeight:
                                                    event.adCutOffHeight,
                                            }}
                                        />
                                    </AdCardContentContainer>
                                ) : null)}
                            <HeroicCountdown
                                nextPredictionToLock={
                                    nextPredictionToLock &&
                                    translateFirestorePrediction(localeId)(
                                        nextPredictionToLock,
                                    )
                                }
                                nextPredictionToLockInfo={
                                    nextPredictionToLockInfo
                                }
                            />
                            <PredictGame
                                analytics={analytics}
                                history={props.history}
                                location={props.location}
                                match={props.match}
                                userData={userData}
                                event={event}
                                partner={partner}
                                isAdSticky={isAdSticky}
                                answers={answers}
                                answersUpdatedTimestamp={
                                    answersUpdatedTimestamp
                                }
                                bonusSponsors={bonusSponsors}
                                gameWideSponsor={gameWideSponsor}
                                hasUnansweredActivePrediction={
                                    hasUnansweredActivePrediction
                                }
                                groupedPredictions={groupedPredictions}
                                groupedPredictionsUpdatedTimestamp={
                                    groupedPredictionsUpdatedTimestamp
                                }
                                iteration={iteration}
                                leaderboard={
                                    eventLeaderboards.generalLeaderboard
                                        .leaderboardSummary
                                }
                                eventLeaderboardEntry={
                                    eventLeaderboards.generalLeaderboard
                                        .leaderboardEntry
                                }
                                prizes={event.prizes}
                                modalHoster={modalHoster}
                                lastBatchReleased={lastBatchReleased}
                                sponsors={sponsors}
                                oddClaims={oddClaims}
                                progressivePolls={availableProgressivePolls}
                                progressivePollsAnswers={
                                    thisEventProgressivePollsAnswers
                                }
                            />
                        </PredictPageContainer>
                    </>
                )}
            />
            <Route path={`${routeUrls.leaderboardSummary}/:tabPanelId`}>
                {partner.properties.hideLeaderboards ? (
                    <Redirect to={routeUrls.play} />
                ) : (
                    <LeaderboardSummaryPage
                        iterationLeaderboards={iterationLeaderboards}
                        iteration={iteration}
                        events={events}
                        eventWithLeaderboards={{
                            event,
                            leaderboards: eventLeaderboards,
                        }}
                    />
                )}
            </Route>
            <PrivateRoute path={routeUrls.account}>
                <AccountPage iteration={iteration} event={event} />
            </PrivateRoute>
            <PrivateRoute path={routeUrls.group}>
                <GroupPage iteration={iteration} />
            </PrivateRoute>
            <PistachioGrouper
                ref={predictionGrouperRef}
                answers={answers}
                predictions={predictions}
                predictionsUpdatedTimestamp={predictionsUpdatedTimestamp}
                onPredictionsGrouped={onNewPredictionsGrouped}
                onLastBatchReleased={onLastBatchReleased}
            />
            <PistachioPredictionChangeDetector
                groupedPredictions={groupedPredictions}
                groupedPredictionsUpdatedTimestamp={
                    groupedPredictionsUpdatedTimestamp
                }
                onCountdownUpdated={onCountdownUpdated}
                onPredictionLocked={onPredictionLocked}
                onPredictionsResolved={onPredictionsResolved}
                onNextPredictionToLock={onNextPredictionToLock}
                predictions={predictions}
                predictionsUpdatedTimestamp={predictionsUpdatedTimestamp}
            />
        </ShowEventPrizeDetailsContext.Provider>
    );
};

const PresentedByBanner = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    aligh-items: center;
    background-color: ${Color.G11_WHITE};
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.08);
`;

const PredictPageContainer = BaseEventPageContainer;

export default PredictionEvent;
