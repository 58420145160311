import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import firebase from 'firebase/app';
import { OrderedMap } from 'immutable';
import { Box } from '@material-ui/core';
import { partition } from 'lodash';

import { TranslatedIteration } from 'src/util/i18n';
import { EventModel, EventState } from 'src/util/TallyFirestore';
import styled from '@emotion/styled';
import theme from 'src/util/Theme';
import { usePartner } from 'src/hooks/usePartner';
import { useAnalytics } from 'src/contexts/AnalyticsContext';
import TallyMarkdown from 'src/components/shared/ui/TallyMarkdown';
import { Color, StyleDefaults } from 'src/styles/Constants';
import { Screen, AppArea } from 'src/util/AnalyticsConstants';
import { TranslatedHomePageContent } from 'src/util/ConfigTypes';
import { CustomTypography } from './shared';
import EventGridSection from './EventGridSection';

import TextButton from 'src/components/shared/controls/TextButton';
import { ModalType } from 'src/components/shared/modals/ModalHoster';
import { useModalHoster } from 'src/contexts/ModalHosterContext';
import { GameBG } from 'src/Iteration/SingleEvent/components';

type Props = {
    events: OrderedMap<string, EventModel>;
    iteration: TranslatedIteration;
};

type EventGridSections = {
    gamesThatCount: {
        headline?: string;
        events: [string, EventModel][];
    };
    gamesThatDontCount?: {
        headline: string;
        events: [string, EventModel][];
    };
};

function getGridEventSections(
    liveEvents: [string, EventModel][],
    homePageContent?: TranslatedHomePageContent,
): EventGridSections {
    if (
        !homePageContent ||
        !homePageContent.eventListHeadline ||
        !homePageContent.funEventListHeadline
    ) {
        return {
            gamesThatCount: {
                events: liveEvents,
            },
        };
    }

    const [eventsThatDontCount, eventsThatCount] = partition(
        liveEvents,
        ([_, event]) => event.omitFromIterationLeaderboards,
    );
    if (eventsThatDontCount.length === 0) {
        return {
            gamesThatCount: {
                headline: homePageContent.eventListHeadline,
                events: liveEvents,
            },
        };
    }

    return {
        gamesThatCount: {
            headline: homePageContent.eventListHeadline,
            events: eventsThatCount,
        },
        gamesThatDontCount: {
            headline: homePageContent.funEventListHeadline,
            events: eventsThatDontCount,
        },
    };
}

const HomePage = ({ events, iteration }: Props) => {
    const partner = usePartner();
    const analytics = useAnalytics();

    const nowTimestamp = firebase.firestore.Timestamp.now();
    const modalHoster = useModalHoster();
    const intl = useIntl();

    const onHowToPlayTournamentClick =
        modalHoster &&
        (() => {
            modalHoster.showModal({
                modalType: ModalType.HOW_TO_PLAY_TOURNAMENT,
                body: iteration.infoModalBody,
                title: iteration.tournamentHeadline,
            });
        });

    const liveEvents = events.filter(
        (event) =>
            event.state !== EventState.final &&
            event.startDate &&
            event.startDate < nowTimestamp,
    );

    const eventGridSections = getGridEventSections(
        liveEvents.toArray(),
        partner.properties.homePageContent,
    );

    const homePageContent = partner.properties.homePageContent;

    const headlineColor =
        (homePageContent && homePageContent.eventListHeadlineColor) ||
        partner.properties.headingColor ||
        Color.G1_BLACK;

    useEffect(() => {
        analytics.logScreenView({
            screenName: `${partner.shortName} - ${Screen.landingPage}`,
            screenType: Screen.landingPage,
            appArea: AppArea.landing,
            params: {
                landing_content:
                    (homePageContent && homePageContent.eventListHeadline) ||
                    '',
            },
        });
    }, [analytics, partner]);

    return (
        <>
            {homePageContent && homePageContent.backgroundImgLock && (
                <GameBG
                    backgroundColor={
                        (homePageContent && homePageContent.backgroundColor) ||
                        Color.P1_WHITE
                    }
                    backgroundImgUrl={
                        homePageContent && homePageContent.backgroundImgUrl
                    }
                    backgroundBtmAlign={false}
                >
                    &nbsp;
                </GameBG>
            )}
            <PageContainer
                backgroundColor={
                    homePageContent && homePageContent.backgroundImgLock
                        ? 'rgba(0,0,0,0)'
                        : (homePageContent &&
                              homePageContent.backgroundColor) ||
                          Color.P1_WHITE
                }
                backgroundImage={
                    (homePageContent &&
                        !homePageContent.backgroundImgLock &&
                        homePageContent.backgroundImgUrl) ||
                    ''
                }
            >
                {theme.partnerLogoUrl &&
                    !(homePageContent && homePageContent.headlineImage) && (
                        <PartnerLogo src={theme.partnerLogoUrl} alt="logo" />
                    )}
                {homePageContent && homePageContent.headlineImage && (
                    <HeadlineImage
                        src={homePageContent.headlineImage}
                        alt="logo"
                    />
                )}
                {homePageContent && homePageContent.subHeadLineText && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                        }}
                    >
                        <CustomTypography
                            color={
                                homePageContent.secondaryHeadingTextColor ||
                                partner.properties.headingColor ||
                                Color.G1_BLACK
                            }
                        >
                            <TallyMarkdown
                                source={homePageContent.subHeadLineText}
                            />
                        </CustomTypography>
                    </Box>
                )}
                <EventGridSection
                    headline={eventGridSections.gamesThatCount.headline}
                    headlineColor={headlineColor}
                    events={eventGridSections.gamesThatCount.events}
                />
                {eventGridSections.gamesThatDontCount && (
                    <EventGridSection
                        headline={eventGridSections.gamesThatDontCount.headline}
                        headlineColor={headlineColor}
                        events={eventGridSections.gamesThatDontCount.events}
                    />
                )}
                {onHowToPlayTournamentClick && iteration.tournamentEnabled && (
                    <HowToPlayButton
                        text={intl.formatMessage({
                            id: 'cumulativeLeaderboardTab.button',
                        })}
                        onClick={onHowToPlayTournamentClick}
                        color={theme.defaultLinkColor || Color.P1_WHITE}
                    />
                )}
                {partner.properties.homePageContent ? (
                    <Box sx={{ marginTop: 40 }}>
                        <CustomTypography
                            variant="h1"
                            color={
                                partner.properties.homePageContent
                                    .secondaryHeadingTextColor || Color.G1_BLACK
                            }
                        >
                            {partner.properties.homePageContent.header}
                        </CustomTypography>
                        {partner.properties.homePageContent.subHeader && (
                            <CustomTypography
                                variant="h2"
                                color={
                                    partner.properties.headingColor ||
                                    Color.G1_BLACK
                                }
                            >
                                {partner.properties.homePageContent.subHeader}
                            </CustomTypography>
                        )}

                        <TallyMarkdown
                            source={
                                partner.properties.homePageContent.bodyMarkdown
                            }
                            textColor={
                                partner.properties.homePageContent
                                    .contentTextColor
                            }
                        />
                    </Box>
                ) : null}
            </PageContainer>
        </>
    );
};

export default HomePage;

const HowToPlayButton = styled(TextButton)`
    margin: 18px 0 0 0;
`;
const PartnerLogo = styled.img`
    max-height: 80px;
    max-width: 100%;
    width: auto;
    height: auto;
    align-self: center;
    margin-bottom: 40px;
`;

const HeadlineImage = styled.img`
    max-width: 100%;
    width: auto;
    height: auto;
    align-self: center;
    margin-bottom: 10px;
`;

const PageContainer = styled.div<{
    backgroundColor?: string;
    backgroundImage?: string;
}>`
    max-width: ${StyleDefaults.MAX_ELEMENT_WIDTH};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 40px 20px 80px 20px;
    background-color: ${(props) => props.backgroundColor || Color.T26_MARBLE};
    background-image: url('${(props) => props.backgroundImage}');
    background-repeat: no-repeat;
    background-position: center top;
`;
