import firebase from './Firebase';
import log from 'src/util/Logger';
import firebaseApp from 'firebase/app';

declare global {
    interface Window {
        recaptchaVerifier: firebase.auth.RecaptchaVerifier;
    }
}

class FirebaseAuth {
    private onAuthStateChangedUnsubscriber?: firebase.Unsubscribe;

    private isFirebaseError = (
        error: Error,
    ): error is firebaseApp.FirebaseError => 'code' in error;

    private mapToFriendlyMessage = (error: firebaseApp.FirebaseError) => {
        switch (error.code) {
            case 'auth/invalid-verification-code':
                return new Error('Incorrect verification code');
            case 'auth/invalid-phone-number':
                return new Error('Invalid phone number');
            case 'auth/captcha-check-failed':
                return new Error('Captcha check failed');
            case 'auth/code-expired':
                return new Error('Code expired. Please resend and try again');
            default:
                return new Error(
                    'Something went wrong. Please try again in a few seconds.',
                );
        }
    };

    // One time hook in to the auth state listener.
    public checkForCurrentUser = async () => {
        return new Promise<firebase.User | undefined>((resolve) => {
            let unsubscriber: firebase.Unsubscribe;
            const onAuthStateChanged = (user: firebase.User | null) => {
                if (unsubscriber) {
                    unsubscriber();
                }

                resolve(user || undefined);
            };
            unsubscriber = firebase
                .auth()
                .onAuthStateChanged(onAuthStateChanged);
        });
    };

    public getUser = () => {
        const user = firebase.auth().currentUser;
        if (user === null) {
            return undefined;
        }

        return user;
    };

    public registerListener = () => {
        this.onAuthStateChangedUnsubscriber = firebase
            .auth()
            .onAuthStateChanged(this.onAuthStateChanged);
    };

    public signInAnonymously = (): Promise<firebase.auth.UserCredential> => {
        return firebase.auth().signInAnonymously();
    };

    public signOut = async () => {
        if (this.onAuthStateChangedUnsubscriber) {
            this.onAuthStateChangedUnsubscriber();
            this.onAuthStateChangedUnsubscriber = undefined;
        }
        await firebase.auth().signOut();
    };

    public signInWithCustomToken = async (
        token: string,
        phoneNumber?: string,
    ): Promise<firebase.auth.UserCredential> => {
        try {
            log.infoTrackJsOnly('Phone Sign In Attempt with token', {
                phoneNumber,
                timeStamp: new Date().toString(),
            });

            const userCredential = await firebase
                .auth()
                .signInWithCustomToken(token);

            log.infoTrackJsOnly('Phone Sign In With Custom Token SUCCESS');
            return userCredential;
        } catch (error) {
            if (!(error instanceof Error)) {
                throw new Error(
                    'Something went wrong. Please try again in a few seconds.',
                );
            }
            if (this.isFirebaseError(error)) {
                const friendlyError = this.mapToFriendlyMessage(error);
                log.error(
                    'Phone Sign In With Custom Token: ' + friendlyError.message,
                    error,
                );
                throw friendlyError;
            }
            throw error;
        }
    };

    private onAuthStateChanged = (user: firebase.User | null) => {
        // uncomment for debugging user auth state;
        /**log.info(
            `Firebase - onAuthStateChanged -  User: ${JSON.stringify(user)}`
        );*/
    };
}

export default new FirebaseAuth();
