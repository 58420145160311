import { Map } from 'immutable';
import EN from './en';
import ES from './es';
import PT from './pt';
import DE from './de';
import IT from './it';
import ENAU from './en-au';

import {
    LocaleId,
    LanguageConfig,
    SecondaryLocaleId,
    SecondaryLanguageConfig,
} from 'src/util/LocaleHelpers';

class LanguagesConfigBuilder {
    public addSecondaryLanguage(
        localeId: SecondaryLocaleId,
        languageConfig: SecondaryLanguageConfig,
    ) {
        this.langConfigMap = this.langConfigMap.set(localeId, {
            messages: { ...EN.messages, ...languageConfig.messages },
            fullLanguageName: languageConfig.fullLanguageName,
        });
        return this;
    }

    public getMap() {
        return this.langConfigMap;
    }

    constructor() {
        this.langConfigMap = Map<LocaleId, LanguageConfig>().set('EN', EN);
    }

    private langConfigMap: Map<LocaleId, LanguageConfig>;
}

const localeIdToLanguageConfig = new LanguagesConfigBuilder()
    .addSecondaryLanguage('DE', DE)
    .addSecondaryLanguage('ES', ES)
    .addSecondaryLanguage('PT', PT)
    .addSecondaryLanguage('IT', IT)
    .addSecondaryLanguage('EN-AU', ENAU)
    .getMap();

const localeIdToMessages = localeIdToLanguageConfig.map(
    (langConfig) => langConfig.messages,
);

export default localeIdToMessages;

export const languageNames = localeIdToLanguageConfig.map(
    (langConfig) => langConfig.fullLanguageName,
);
