import styled from '@emotion/styled';
import React, { useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import { useSpring, animated } from 'react-spring';
import { FormattedMessage } from 'react-intl';
import ProgressivePollComponent, {
    OnAnswerCallback,
} from 'src/components/shared/progressivePoll/ProgressivePoll';
import chevron from 'src/images/icons/Chevron-Expand@3x.svg';
import { Color, TextStyles, TextStyleTypes } from 'src/styles/Constants';
import {
    ProgressivePollAnswer,
    ProgressivePollModel,
} from 'src/util/TallyFirestore';

type Props = {
    answer?: ProgressivePollAnswer;
    poll: ProgressivePollModel;
    pointValue: number;
    onAnswer?: OnAnswerCallback;
    isResolved: boolean;
};

export default ({ poll, answer, onAnswer, pointValue, isResolved }: Props) => {
    const [expanded, setExpanded] = useState(false);
    const rotatingChevronSpring = useSpring({
        transform: expanded ? 'rotateZ(180deg)' : 'rotateZ(0deg)',
    });

    const answered = Boolean(answer);

    const borderColor = Color.T34_SQUIRTLE;

    const status = answered ? (
        <FormattedMessage id="newPredictionCard.status.participated" />
    ) : (
        <FormattedMessage id="newPredictionCard.status.missed" />
    );

    const collapsedHeaderTextColor = answered
        ? Color.PREDICTION_GREEN_DARK
        : Color.PREDICTION_GREY_93;

    return (
        <Container>
            <Card
                borderColor={borderColor}
                onClick={() => setExpanded(!expanded)}
            >
                <CollapsedHeaderBar color={collapsedHeaderTextColor}>
                    <PredictionStatusLabel>{status}</PredictionStatusLabel>
                    <Chevron style={rotatingChevronSpring} />
                </CollapsedHeaderBar>
                <TitleLabel big={false} centered={expanded}>
                    {poll.text}
                </TitleLabel>
                <Collapse in={expanded} timeout="auto">
                    <PollPoints>
                        <PollHeading>
                            <FormattedMessage id="newPredictionCard.pollHeading" />
                        </PollHeading>
                        {pointValue}{' '}
                        <FormattedMessage id="newPredictionCard.pollPoints" />
                    </PollPoints>
                    <ProgressivePollComponent
                        answer={answer}
                        poll={poll}
                        onAnswer={onAnswer}
                        isResolved={isResolved}
                    />
                </Collapse>
            </Card>
        </Container>
    );
};

const Container = styled.div`
    padding-bottom: 24px;
`;

const Card = styled.div<{
    borderColor: string;
    extraTopPadding?: boolean;
}>`
    position: relative;
    top: 0;
    border: 1px solid ${(props) => props.borderColor};
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.08);
    background-color: ${Color.P1_WHITE};
    transition: border 0.4s;
    width: 100%;
    padding: ${(props) => (props.extraTopPadding ? 40 : 20)}px 20px 20px 20px;
    box-sizing: border-box;
    margin-bottom: 20px;
`;

const TitleLabel = styled.div<{ big: boolean; centered: boolean }>`
    display: inline-block;
    width: 100%;
    ${(props) =>
        props.big
            ? TextStyles[TextStyleTypes.S1]
            : TextStyles[TextStyleTypes.P3]}
    text-align: ${(props) => (props.centered ? 'center' : 'left')};
    transition:
        font-weight 0.3s,
        font-size 0.3s;
`;

const CollapsedHeaderBar = styled.div<{
    color: string;
}>`
    display: flex;
    width: 100%;
    color: ${(props) => props.color};
    flex-direction: row;
    justify-content: flex-start;
    margin: 0 0 4px 0;
`;

const PredictionStatusLabel = styled.div`
    font-size: 12px;
    letter-spacing: 0.34px;
    line-height: 16px;
    text-transform: uppercase;
`;

const Chevron = styled(animated.div)`
    background-image: url(${chevron});
    background-position: center;
    background-repeat: no-repeat;
    margin-left: auto;
    width: 18px;
    height: 18px;
`;
const PollHeading = styled.span`
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.13px;
    line-height: 28px;
    margin-right: 5px;
`;

const PollPoints = styled.div`
    text-align: center;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: -0.13px;
    line-height: 28px;
    margin-bottom: 6px;
`;
