import React from 'react';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';

import { Color, TextStyles, TextStyleTypes } from 'src/styles/Constants';
import {
    OptionModel,
    UserAnswer,
    Claim,
    PredictionModel,
} from 'src/util/TallyFirestore';
import { predictionTypeIsMultipleChoicy } from 'src/util/PredictionHelpers';
import Option from './Option';
import { useAnalytics } from 'src/contexts/AnalyticsContext';
import { EventName } from 'src/util/AnalyticsConstants';
import TallyMarkdown from 'src/components/shared/ui/TallyMarkdown';
import Blank from './Blank';
import {
    getAnswerInfo,
    getTotalCount,
    mergeAnswerInfoWithOptions,
} from './util';

export type OnAnswerCallback = (params: {
    optionId: string;
    prediction: PredictionModel;
    value: boolean | string;
}) => Promise<boolean>;

interface Props {
    answer?: UserAnswer;
    oddClaim?: Claim;
    onAnswer?: OnAnswerCallback;
    prediction: PredictionModel;
    isLive: boolean;
    projectedSelectedOptionId?: string;
    showText?: boolean;
    chosenOptionOnly?: boolean;
}

const Prediction = ({
    prediction,
    isLive,
    chosenOptionOnly,
    answer,
    projectedSelectedOptionId,
    showText = true,
    oddClaim,
    onAnswer,
}: Props) => {
    const analytics = useAnalytics();

    const { type: predictionType } = prediction;
    const answerInfo = getAnswerInfo(
        predictionType,
        answer,
        projectedSelectedOptionId,
    );

    // only modify options if the prediction is live
    const options = isLive
        ? mergeAnswerInfoWithOptions({
              answerInfo,
              prediction,
          })
        : prediction.options;

    const totalCount = getTotalCount(options);
    const selectedOptionId =
        answerInfo.projectedSelectedOptionId ||
        answerInfo.answerSelectedOptionId;

    const onOptionPress = async (option: OptionModel) => {
        const { id: optionId } = option;

        const success = onAnswer
            ? await onAnswer({
                  optionId,
                  prediction,
                  value: true,
              })
            : true;
        if (success) {
            // Log different event if player is changing answer for already answered prediction
            if (!selectedOptionId) {
                analytics.logEvent({
                    eventName: EventName.predictionMade,
                    params: {
                        prediction_number: prediction.number,
                        prediction_release_milestone:
                            prediction.releaseMilestone || 'unspecified',
                        prediction_type: predictionType,
                    },
                });
            } else {
                analytics.logEvent({
                    eventName: EventName.predictionChanged,
                    params: {
                        prediction_number: prediction.number,
                        prediction_release_milestone:
                            prediction.releaseMilestone || 'unspecified',
                        prediction_type: predictionType,
                    },
                });
            }
        }
        return success;
    };

    const onSubmitBlank = async (
        option: OptionModel,
        value: string,
    ): Promise<boolean> => {
        const { id: optionId } = option;
        const success = onAnswer
            ? await onAnswer({ optionId, prediction, value })
            : true;
        if (success) {
            const isChange = answer && answer[optionId]; // user already has an answer for this option.
            analytics.logEvent({
                eventName: isChange
                    ? EventName.predictionChanged
                    : EventName.predictionMade,
                params: {
                    prediction_number: prediction.number,
                    prediction_release_milestone:
                        prediction.releaseMilestone || 'unspecified',
                    prediction_type: predictionType,
                },
            });
        }
        return success;
    };

    const isPoll = prediction.type === 'POLL';
    const betSimPredictionWasAlreadyAnswered =
        prediction.type === 'BET_SIM' && oddClaim;

    const multipleChoiceOptionProps =
        prediction.type === 'MULTIPLE_CHOICE'
            ? {
                  allOrNothing: prediction.allOrNothing,
                  hideOptionMetrics: prediction.hideOptionMetrics,
              }
            : {};

    return (
        <Container isPoll={isPoll}>
            {showText && <QuestionText>{prediction.text}</QuestionText>}
            {prediction.subText && (
                <SubText>
                    <TallyMarkdown
                        linkColor={Color.T31_BLACK}
                        paragraphPaddingBottom="4px"
                        source={prediction.subText}
                    />
                </SubText>
            )}
            <OptionsWrapper>
                {predictionTypeIsMultipleChoicy(predictionType)
                    ? options.map(
                          (option: OptionModel, i) =>
                              ((chosenOptionOnly &&
                                  selectedOptionId === option.id) ||
                                  !chosenOptionOnly) &&
                              !option.hideOption && (
                                  <OptionWrapper key={option.id}>
                                      <Option
                                          onPress={
                                              betSimPredictionWasAlreadyAnswered
                                                  ? undefined
                                                  : onOptionPress
                                          }
                                          option={option}
                                          predictionPointValue={
                                              prediction.pointValue
                                          }
                                          predictionResolved={
                                              prediction.resolved
                                          }
                                          predictionLocked={!isLive}
                                          predictionType={predictionType}
                                          selectedOptionId={selectedOptionId}
                                          totalCount={totalCount}
                                          oddsType={
                                              prediction.odds &&
                                              prediction.odds.oddType
                                          }
                                          oddsTheOdds={
                                              prediction.odds &&
                                              prediction.odds.oddsAmerican &&
                                              prediction.odds.oddsAmerican
                                                  .length > i
                                                  ? prediction.odds
                                                        .oddsAmerican[i]
                                                  : 0
                                          }
                                          oddClaim={
                                              oddClaim && oddClaim[option.id]
                                          }
                                          {...multipleChoiceOptionProps}
                                      />
                                  </OptionWrapper>
                              ),
                      )
                    : options.map((option) => {
                          // Some goofiness to coerce the answer to not be a boolean.
                          // The answer should never actually be a boolean, but because
                          // out TypeScript interface says it could be boolean, we have to work around it.
                          const rawUserAnswer = answer && answer[option.id];
                          const userAnswer =
                              typeof rawUserAnswer === 'string'
                                  ? rawUserAnswer
                                  : undefined;

                          return (
                              <OptionWrapper key={option.id}>
                                  <Blank
                                      onSubmit={onSubmitBlank}
                                      option={option}
                                      predictionPointValue={
                                          prediction.pointValue
                                      }
                                      predictionResolved={prediction.resolved}
                                      predictionLocked={!isLive}
                                      userAnswer={userAnswer}
                                  />
                              </OptionWrapper>
                          );
                      })}
            </OptionsWrapper>
            {prediction.odds && prediction.odds.sportsbook && (
                <SubText>
                    <FormattedMessage id="prediction.option.odds.oddsAware" />
                    <p>
                        <FormattedMessage
                            id="prediction.option.odds.liveOddsBy"
                            values={{
                                sportsbook: prediction.odds.sportsbook,
                            }}
                        />
                    </p>
                </SubText>
            )}
        </Container>
    );
};

export default Prediction;

const QuestionText = styled.h2`
    color: ${Color.T31_BLACK};
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.1px;
    line-height: 24px;
`;

const Container = styled.section<{
    isPoll: boolean;
}>`
    font-family: Graphik Web;
    text-align: ${(props) => (props.isPoll ? 'center' : 'left')};
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const SubText = styled.div`
    color: ${Color.T31_BLACK};
    ${TextStyles[TextStyleTypes.C1]};
    margin: 3px 0;
    display: flex;
    flex-direction: column;
`;

export const OptionWrapper = styled.div`
    margin: 10px 0 0 0;
`;

export const OptionsWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 15px 0 17px 0;
`;
