import styled from '@emotion/styled';
import { Color, StyleDefaults } from 'src/styles/Constants';

export const PageContainer = styled.div<{
    backgroundColor?: string;
}>`
    max-width: ${StyleDefaults.MAX_ELEMENT_WIDTH};
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.backgroundColor || Color.G10_WHITISH};
    width: ${StyleDefaults.ELEMENT_WIDTH};
    align-items: stretch;
    padding: ${StyleDefaults.PAGE_PADDING};
`;
