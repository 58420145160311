import React from 'react';
import styled from '@emotion/styled';

interface Props {
    logoUrl: string;
    maxHeight: number;
}

export default function PartnerLogo(props: Props) {
    const { logoUrl, maxHeight } = props;

    return <StyledImage src={logoUrl} maxHeight={maxHeight} />;
}

const StyledImage = styled.img<{
    maxHeight: number;
}>`
    max-height: ${(props) => props.maxHeight}px;
`;
