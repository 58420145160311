import React from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import { ReactComponent as HomeIcon } from 'src/images/icons/house-chimney-1.svg';
import { ReactComponent as LeaderboardIcon } from 'src/images/icons/ranking-winner.svg';
import { ReactComponent as AccountIcon } from 'src/images/icons/single-neutral-circle.svg';

import { Color, PistachioZIndex } from 'src/styles/Constants';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import routeUrls, {
    getNavItem,
    NavItem,
    navItems,
    navItemToRoute,
} from 'src/routes';
import { usePartner } from 'src/hooks/usePartner';

const ICON_WIDTH = 24;

const useStyles = makeStyles({
    root: {
        position: 'fixed',
        zIndex: PistachioZIndex.STICKY_BOTTOM_NAV,
        bottom: 0,
        left: 0,
        width: '100%',
    },
    selected: {
        color: Color.PREDICTION_BLUE,
    },
});

const BottomNav = () => {
    const {
        properties: { hideLeaderboards, hideAccountPage },
    } = usePartner();
    const leaderboardMatch = useRouteMatch<{ leaderboardPanelId: string }>(
        `${routeUrls.leaderboardSummary}/:leaderboardPanelId`,
    );

    const location = useLocation();
    const history = useHistory();

    const intl = useIntl();
    const classes = useStyles();

    const navItem = getNavItem(location.pathname, leaderboardMatch);

    return (
        <BottomNavigation
            value={navItem}
            onChange={(_, newNavItem: NavItem) => {
                // if we are already on play and we hit play nevertheless, we want to get to the events
                const search =
                    navItem === newNavItem && newNavItem === navItems.play
                        ? undefined
                        : location.search;

                // if we are on leaderboards and we hit leaderboards nevertheless, we want to get to the iteration leaderboards
                if (
                    navItem === newNavItem &&
                    newNavItem === navItems.leaderboard
                ) {
                    history.push({
                        pathname: routeUrls.iterationLeaderboardSummary,
                        search,
                    });

                    return;
                }

                history.push({
                    pathname: navItemToRoute[newNavItem],
                    search,
                });
            }}
            showLabels
            className={classes.root}
        >
            <BottomNavigationAction
                classes={{ selected: classes.selected }}
                value={navItems.play}
                label={intl.formatMessage({ id: 'menu.play' })}
                icon={<HomeIcon width={ICON_WIDTH} />}
            />
            {!hideLeaderboards && (
                <BottomNavigationAction
                    classes={{ selected: classes.selected }}
                    value={navItems.leaderboard}
                    label={intl.formatMessage({
                        id: 'menu.gameLeaderboard',
                    })}
                    icon={<LeaderboardIcon width={ICON_WIDTH} />}
                />
            )}
            {!hideAccountPage && (
                <BottomNavigationAction
                    classes={{ selected: classes.selected }}
                    value={navItems.account}
                    label={intl.formatMessage({ id: 'menu.account' })}
                    icon={<AccountIcon width={ICON_WIDTH} />}
                />
            )}
        </BottomNavigation>
    );
};
export default BottomNav;
