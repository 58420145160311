import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import SquareLoading from 'src/components/shared/animations/SquareLoading';
import {
    EventModel,
    isNoCountdownTriviaGame,
    TriviaGameProperties,
} from 'src/util/TallyFirestore';
import theme from 'src/util/Theme';
import { useAnalytics } from 'src/contexts/AnalyticsContext';
import { EventName } from 'src/util/AnalyticsConstants';
import MultipleChoiceQuestion, {
    MultipleChoiceQuestionProps,
} from './MultipleChoiceQuestion';
import { ButtonContainer, LoadingIndicatorContainer } from './shared';
import TriviaCountdown from './TriviaCountdown';
import { TriviaGameStateWithQuestion } from './useTriviaStateReducer';
import AspectRatioIframeAd from 'src/components/shared/ads/AspectRatioIframeAd';

type Props = {
    event: EventModel;
    gameState: TriviaGameStateWithQuestion;
    trivia: TriviaGameProperties;
    screenHasBg?: boolean;
    txtBgOpacity?: number;
    answer: ({
        questionId,
        optionId,
    }: {
        questionId: string;
        optionId: string | null;
    }) => void;
    next: () => void;
};

function getQuestionIndex(gameState: TriviaGameStateWithQuestion): number {
    switch (gameState.id) {
        case 'answerLoading':
        case 'current':
        case 'past':
        case 'nextLoadingError':
            return gameState.past.length;
        case 'answerSuccess':
        case 'nextLoading': {
            return gameState.past.length - 1;
        }
    }
}

function getMultipleChoiceQuestionProps(
    gameState: TriviaGameStateWithQuestion,
    trivia: TriviaGameProperties,
    onAnswer: ({
        questionId,
        optionId,
    }: {
        questionId: string;
        optionId: string | null;
    }) => void,
    countdownSeconds?: number,
): MultipleChoiceQuestionProps {
    switch (gameState.id) {
        case 'current':
            return {
                question: gameState.current,
                countdownSeconds,
                trivia,
                loading: false,
                onAnswer: (optionId: string) => {
                    onAnswer({ questionId: gameState.current.id, optionId });
                },
            };
        case 'answerLoading': {
            return {
                question: gameState.current,
                countdownSeconds,
                trivia,
                selectedOptionId: gameState.selectedOptionId,
                loading: true,
            };
        }
        case 'past':
        case 'nextLoadingError':
        case 'answerSuccess': {
            const question = gameState.past[gameState.past.length - 1];
            return {
                points:
                    gameState.id === 'answerSuccess'
                        ? gameState.latestQuestionPoints
                        : undefined,
                question,
                countdownSeconds,
                trivia,
                loading: false,
            };
        }
        case 'nextLoading': {
            const question = gameState.past[gameState.past.length - 1];
            return {
                question,
                countdownSeconds,
                trivia,
                points: gameState.latestQuestionPoints,
                loading: false,
            };
        }
    }
}

const TriviaGameQuestion = ({
    event,
    gameState,
    trivia,
    answer,
    next,
    screenHasBg = false,
    txtBgOpacity = 100,
}: Props) => {
    const intl = useIntl();
    const analytics = useAnalytics();

    const onAnswer = (answerProps: {
        questionId: string;
        optionId: string | null;
    }) => {
        analytics.logEvent({
            eventName: EventName.triviaAnswerMade,
        });

        answer(answerProps);
    };

    const [countdownSeconds, setCountdownSeconds] = useState<
        undefined | number
    >(undefined);

    const multipleChoiceQuestionProps = getMultipleChoiceQuestionProps(
        gameState,
        trivia,
        onAnswer,
        countdownSeconds,
    );

    const pauseCountdownAndShowNext =
        gameState.id !== 'answerLoading' && gameState.id !== 'current';

    const loading =
        gameState.id === 'answerLoading' || gameState.id === 'nextLoading';

    const questionIndex = getQuestionIndex(gameState);
    const question = multipleChoiceQuestionProps.question;
    return (
        <>
            {isNoCountdownTriviaGame(trivia) ? (
                <NoCountdownHeadline>
                    <FormattedMessage
                        id="triviaGame.countdown.headline"
                        values={{
                            questionNumber: questionIndex + 1,
                            numberOfQuestions: trivia.numberOfQuestions,
                        }}
                    />
                </NoCountdownHeadline>
            ) : (
                <TriviaCountdown
                    lockDate={question.lockDate}
                    trivia={trivia}
                    questionIndex={questionIndex}
                    countdownSeconds={countdownSeconds}
                    onCountdownUpdated={setCountdownSeconds}
                    onCountdownExpired={() => {
                        answer({
                            questionId: question.id,
                            optionId: null,
                        });
                    }}
                    pause={pauseCountdownAndShowNext}
                />
            )}
            {question.ad ? (
                <AspectRatioIframeAd
                    textAlign="center"
                    borderSizePx={5}
                    eventId={event.id}
                    iframeHtml={question.ad.iframeHtml}
                    headline={question.ad.headline}
                    disclaimer={question.ad.disclaimer}
                    width="75%"
                />
            ) : null}
            {gameState.id === 'nextLoadingError' && (
                <span style={{ color: 'red' }}>{gameState.errorMessage}</span>
            )}
            <MultipleChoiceQuestion
                {...multipleChoiceQuestionProps}
                screenHasBg={screenHasBg}
                txtBgOpacity={txtBgOpacity}
                imgDisclaimer={
                    (event.ui && event.ui.imageDisclaimer) || undefined
                }
            />
            {pauseCountdownAndShowNext && (
                <ButtonContainer>
                    <Button
                        onClick={next}
                        disabled={gameState.id === 'nextLoading'}
                        style={
                            gameState.id !== 'nextLoading' &&
                            event.ui?.textColor
                                ? { color: event.ui?.textColor }
                                : {}
                        }
                    >
                        {gameState.past.length === gameState.numberOfQuestions
                            ? intl.formatMessage({
                                  id: 'triviaGame.button.finish',
                              })
                            : intl.formatMessage({
                                  id: 'triviaGame.button.next',
                              })}
                    </Button>
                </ButtonContainer>
            )}
            {loading && (
                <LoadingIndicatorContainer>
                    <SquareLoading color={theme.themeHighlightFallbackColor} />
                </LoadingIndicatorContainer>
            )}
        </>
    );
};

const NoCountdownHeadline = styled.h1`
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 2px;
    color: ${(props) => props.color};
    text-transform: upperCase;
    text-align: center;
    padding: 10px 0;
`;

export default TriviaGameQuestion;
