import { useUpdateContactInfo } from 'src/contexts/UserStateContext';
import ServerApi from 'src/services/ServerApi';
import { useAnalytics } from 'src/contexts/AnalyticsContext';
import { AppArea, EventName, Screen } from 'src/util/AnalyticsConstants';
import { useImmediateSetting } from '../shared';

const updateSmsMarketingOptIn = (smsMarketingOptIn: boolean) =>
    ServerApi.updateUser({
        smsMarketingOptIn,
    });

const updateTallyMarketingOptIn = (tallyOptIn: boolean) =>
    ServerApi.updateUser({
        tallyOptIn,
    });

export const useSmsMarketingOptIn = (initialValue: boolean | undefined) => {
    const updateContactInfo = useUpdateContactInfo();
    const analytics = useAnalytics();

    const onUpdateSmsMarketingOptIn = (smsMarketingOptIn: boolean) => {
        updateContactInfo({
            smsMarketingOptIn,
        });
        analytics.logEvent({
            eventName: EventName.marketingOptIn,
            params: {
                screen: Screen.userSettings,
                smsOptedIn: smsMarketingOptIn,
            },
            eventCategory: AppArea.profile,
        });
    };

    const {
        value: smsMarketingOptIn,
        error,
        update,
        isUpdating,
    } = useImmediateSetting(
        !!initialValue,
        updateSmsMarketingOptIn,
        onUpdateSmsMarketingOptIn,
    );

    return {
        smsMarketingOptIn,
        setSmsMarketingOptIn: update,
        smsMarketingError: error,
        isSmsMarketingUpdating: isUpdating,
    };
};

export const useTallyMarketingOptIn = (initialValue: boolean | undefined) => {
    const updateContactInfo = useUpdateContactInfo();
    const analytics = useAnalytics();

    const onUpdateTallyMarketingOptIn = (tallyOptIn: boolean) => {
        updateContactInfo({ tallyOptIn });

        analytics.logEvent({
            eventName: EventName.marketingOptIn,
            params: {
                screen: Screen.userSettings,
                tallyOptIn,
            },
            eventCategory: AppArea.profile,
        });
    };

    const {
        value: tallyOptIn,
        error,
        update,
        isUpdating,
    } = useImmediateSetting(
        !!initialValue,
        updateTallyMarketingOptIn,
        onUpdateTallyMarketingOptIn,
    );

    return {
        tallyMarketingOptIn: tallyOptIn,
        setTallyMarketingOptIn: update,
        tallyMarketingError: error,
        isTallyMarketingUpdating: isUpdating,
    };
};

const updateEmailMarketingOptIn = (marketingOptIn: boolean) =>
    ServerApi.updateUser({
        marketingOptIn,
    });

export const useEmailMarketingOptIn = (initialValue: boolean | undefined) => {
    const updateContactInfo = useUpdateContactInfo();
    const analytics = useAnalytics();

    const onUpdateEmailMarketingOptIn = (marketingOptIn: boolean) => {
        updateContactInfo({ marketingOptIn });

        analytics.logEvent({
            eventName: EventName.marketingOptIn,
            params: {
                emailOptedIn: marketingOptIn,
                screen: Screen.userSettings,
            },
            eventCategory: AppArea.profile,
        });
    };

    const { value, error, update, isUpdating } = useImmediateSetting(
        !!initialValue,
        updateEmailMarketingOptIn,
        onUpdateEmailMarketingOptIn,
    );

    return {
        emailMarketingOptIn: value,
        setEmailMarketingOptIn: update,
        emailMarketingError: error,
        isEmailMarketingUpdating: isUpdating,
    };
};

const updateOptionalMarketingOptIn = (optionalOptIn: boolean) =>
    ServerApi.updateUser({
        optionalOptIn,
    });

export const useOptionalMarketingOptIn = (
    initialValue: boolean | undefined,
) => {
    const updateContactInfo = useUpdateContactInfo();
    const onUpdateOptionalMarketingOptIn = (optionalOptIn: boolean) => {
        updateContactInfo({ optionalOptIn });
    };
    const { value, error, update, isUpdating } = useImmediateSetting(
        !!initialValue,
        updateOptionalMarketingOptIn,
        onUpdateOptionalMarketingOptIn,
    );
    return {
        optionalMarketingOptIn: value,
        updateOptionalMarketingOptIn: update,
        optionalMarketingError: error,
        isOptionalMarketingUpdating: isUpdating,
    };
};
