import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import { RouteChildrenProps } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import Cookies from 'universal-cookie';

import {
    LeaderboardEntry,
    EventState,
    IGameEventModel,
} from 'src/util/TallyFirestore';
import ModalHoster, {
    ModalType,
} from 'src/components/shared/modals/ModalHoster';
import {
    Color,
    StyleDefaults,
    TextStyles,
    TextStyleTypes,
} from 'src/styles/Constants';
import UserStats from 'src/components/shared/ui/UserStats';
import { TranslatedIteration } from 'src/util/i18n';
import { TranslatedConfigPartnerData } from 'src/util/ConfigTypes';
import { getDetails, UserAuthData } from 'src/contexts/UserStateContext';
import routeUrls, { goToGeneralEventLeaderboard } from 'src/routes';
import Link from 'src/components/shared/ui/Link';
import TallyMarkdown from 'src/components/shared/ui/TallyMarkdown';
import useIGameState from '../hooks/useIGameState';

// TODO: looks like those should be a part of igame event configuration
const iGameIframeSize = {
    height: 540,
    width: 500,
} as const;

type Props = {
    showPrizeDetails?: () => void;
    userData: UserAuthData;
    partner: TranslatedConfigPartnerData;
    iteration: TranslatedIteration;
    event: IGameEventModel;
    modalHoster?: ModalHoster;
    eventLeaderboardEntry?: LeaderboardEntry;
} & RouteChildrenProps;

const shouldShowPrizeModal = ({
    event,
    showPrizeDetails,
}: Pick<Props, 'event' | 'showPrizeDetails'>) => {
    const reactCookie = new Cookies();
    const cookies = reactCookie.getAll();
    return (
        event.state !== EventState.final &&
        !cookies[event.id] &&
        event.prizes &&
        showPrizeDetails
    );
};

const showPrizesModal = ({
    showPrizeDetails,
    event,
}: Pick<Props, 'event' | 'showPrizeDetails'>) => {
    const reactCookie = new Cookies();

    if (showPrizeDetails) {
        reactCookie.set(event.id, true);
        setTimeout(showPrizeDetails, 500);
    }
};

const IGameFrame = ({
    canPlay,
    error,
    event,
    userData,
}: {
    canPlay: boolean | undefined;
    error: Error | undefined;
    event: IGameEventModel;
    userData: UserAuthData;
}) => {
    if (error) {
        return (
            <HeaderText>
                <FormattedMessage id="error.generic" />
            </HeaderText>
        );
    }
    switch (canPlay) {
        case true:
            return (
                <IGameFrameContainer>
                    <StyledIFrame
                        src={event.igame.iframeUrl + `?userID=${userData.uid}`}
                        height={iGameIframeSize.height}
                        width={iGameIframeSize.width}
                        frameBorder={0}
                    ></StyledIFrame>
                </IGameFrameContainer>
            );
        case false:
            return (
                <HeaderText>
                    <FormattedMessage id="igame.noTriesLeft" />
                </HeaderText>
            );
        default:
            return (
                <ClipLoaderContainer>
                    <ClipLoader size={20} loading={true} />
                </ClipLoaderContainer>
            );
    }
};

const StyledIFrame = styled.iframe`
    align-self: center;
    max-width: ${iGameIframeSize.width}px;
    width: 100%;
`;

const IGameEvent = ({
    showPrizeDetails,
    userData,
    partner,
    modalHoster,
    event,
    iteration,
    eventLeaderboardEntry,
    history,
}: Props) => {
    const { error, iGameState, canPlay } = useIGameState(event);

    useEffect(() => {
        if (shouldShowPrizeModal({ event, showPrizeDetails })) {
            showPrizesModal({ event, showPrizeDetails });
        }
    }, [event, showPrizeDetails]);

    const goToLeaderboard = () => {
        goToGeneralEventLeaderboard(history, location, event.slug);
    };

    const onHowToPlayClick = () => {
        if (modalHoster) {
            modalHoster.showModal({
                iteration,
                modalType: ModalType.HOW_TO_PLAY,
            });
        }
    };

    const userDetails = getDetails(userData);
    const userName = userDetails ? userDetails.displayName : '';

    return (
        <>
            <WidthRestrictor marginBottom="22px" marginTop="22px">
                {event.igame.rulesMarkdown && (
                    <DescriptionText>
                        <TallyMarkdown source={event.igame.rulesMarkdown} />
                    </DescriptionText>
                )}
            </WidthRestrictor>
            <IGameFrame
                canPlay={canPlay}
                error={error}
                event={event}
                userData={userData}
            />
            {partner.properties.blueBoxPromoImageUrl && (
                <AdLink
                    href={partner.properties.blueBoxPromoLink}
                    target="blank"
                >
                    <AdContent src={partner.properties.blueBoxPromoImageUrl} />
                </AdLink>
            )}
            <WidthRestrictor marginBottom="22px" marginTop="22px">
                <UserStats
                    leaderboardEntry={eventLeaderboardEntry}
                    name={userName}
                    showShadow={true}
                    titleOverride={
                        <FormattedMessage id="predictPage.userStats.titleOverride" />
                    }
                    callToActionButton={
                        partner.properties.hideLeaderboards
                            ? undefined
                            : {
                                  label: (
                                      <FormattedMessage id="predictPage.userStats.callToActionButton.label" />
                                  ),
                                  onClick: goToLeaderboard,
                              }
                    }
                />
                <HowToPlayButton
                    onClick={onHowToPlayClick}
                    color={event.ui?.textColor}
                >
                    <FormattedMessage id="predictPage.userStats.howToPlayButton.label" />
                </HowToPlayButton>
            </WidthRestrictor>
            {event.sponsorship && event.sponsorship.properties.tileAd && (
                <WidthRestrictor>
                    <TileAd src={event.sponsorship.properties.tileAd} />
                </WidthRestrictor>
            )}
            {event.sponsorship && event.sponsorship.properties.footerAd && (
                <FooterAdContainer>
                    <FooterAd src={event.sponsorship.properties.footerAd} />
                </FooterAdContainer>
            )}
        </>
    );
};

export default IGameEvent;

const VerticalSpace = styled.div<{ height: number }>`
    height: ${(props) => props.height}px;
`;

const FooterAd = styled.img`
    background-color: ${Color.P6_ELECTRIC_BLUE};
    width: ${StyleDefaults.MAX_ELEMENT_WIDTH};
    max-width: 100%;
`;

const FooterAdContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    max-height: 312px;
`;

const TileAd = styled.img`
    width: 100%;
`;

const WidthRestrictor = styled.div<{
    marginBottom?: string;
    marginTop?: string;
}>`
    display: flex;
    flex-direction: column;
    aligh-iterms: center;
    margin: auto;
    margin-bottom: ${(props) => props.marginBottom || '0'};
    margin-top: ${(props) => props.marginTop || '0'};
    max-width: ${StyleDefaults.MAX_ELEMENT_WIDTH};
    width: ${StyleDefaults.ELEMENT_WIDTH};
    font-family: Graphik Web;
`;

const IGameFrameContainer = styled.div`
    height: ${iGameIframeSize.height};
`;

const HowToPlayButton = styled.button<{
    color?: string;
}>`
    &:active {
        opacity: 0.6;
    }
    background-color: transparent;
    border: none;
    color: ${(props) => props.color || Color.G1_BLACK};
    cursor: pointer;
    font-weight: 500;
    font-family: ${StyleDefaults.FONT_FAMILY};
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 18px;
    outline: none;
    padding: 30px 0 15px 0;
    text-align: center;
    text-transform: uppercase;
    text-decoration: underline;
    transition: all 0.3s;
    width: 100%;
`;

const PolicyLinksContainer = styled.div`
    border-top: 1px solid #252525;
    display: flex;
    flex-direction: row;
    margin-top: 26px;
    width: 100%;
`;
const PolicyLink = styled(Link)`
    ${TextStyles[TextStyleTypes.C3]}
    color: #9a9a9a;
    margin: 5px 0;
    underline: false;
    padding-right: 17px;
    padding-top: 4px;
    text-decoration: none;
`;

const ButtonLink = styled.a`
    ${TextStyles[TextStyleTypes.C3]}
    color: #9a9a9a;
    margin: 5px 0;
    underline: false;
    padding-right: 17px;
    padding-top: 4px;
    cursor: pointer;
`;

const AdLink = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 15px;
    height: auto;
    width: auto;
    color: ${Color.P1_WHITE};
`;

const AdContent = styled.img`
    width: 300px;
    height: auto;
`;

const DescriptionText = styled.div`
    margin: 15px 0 0 0;
    ${TextStyles[TextStyleTypes.P1]}
    text-align: left;
    width: 100%;
`;

const ClipLoaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const HeaderText = styled.div`
    text-align: center;
    font-size: 35px;
    letter-spacing: -0.6px;
    line-height: 37px;
    font-weight: 900;
    margin: 0 0 20px 0;
`;
