import { css } from 'emotion';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { PositionProperty } from 'csstype';
import { StyleDefaults } from 'src/styles/Constants';

import SubmitStatusIndicator, {
    IndicatorState,
} from 'src/components/shared/animations/SubmitStatusIndicator';
import { Color, TextStyles, TextStyleTypes } from 'src/styles/Constants';
import { ProgressivePollOptionModel } from 'src/util/TallyFirestore';
import theme from 'src/util/Theme';

interface Props {
    onPress?: (option: ProgressivePollOptionModel) => Promise<boolean>;
    option: ProgressivePollOptionModel;
    progressivePollLocked: boolean;
    selectedOptionId?: string;
}

export const styles = {
    activeContainer: {
        '&:active': {
            opacity: 0.4,
        },
    },
    container: {
        borderColor: Color.T28_FOG,
        borderStyle: 'solid',
        borderWidth: 1,
        color: Color.T31_BLACK,
        fontFamily: StyleDefaults.FONT_FAMILY,
        marginBottom: 0,
        position: 'relative' as PositionProperty,
        transition: 'border-color 0.3s',
    },
    progress: {
        height: '4px',
        marginTop: '3px',
    },
};

export const OPTION_CONTAINER_STYLES = {
    resolved: {
        backgroundColor: Color.TRANSPARENT,
        borderColor: Color.PREDICTION_GREEN,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderTopWidth: 1,
    },
    default: {
        backgroundColor: Color.TRANSPARENT,
        borderColor: Color.T28_FOG,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderTopWidth: 1,
    },
    selected: {
        backgroundColor: Color.TRANSPARENT,
        borderColor: theme.predictionAnsweredIconBackgroundColor,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderTopWidth: 1,
    },
    correct_and_user_correct: {
        backgroundColor: Color.TRANSPARENT,
        borderColor: Color.PREDICTION_GREEN,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderTopWidth: 1,
    },
};

export enum OptionOutcome {
    UNRESOLVED = 'UNRESOLVED',
    RESOLVED = 'RESOLVED',
}

export const getOptionContainerStyle = (
    optionOutcome: OptionOutcome,
    selected: boolean,
) => {
    if (optionOutcome === OptionOutcome.RESOLVED) {
        if (selected) {
            return OPTION_CONTAINER_STYLES.correct_and_user_correct;
        }
        return OPTION_CONTAINER_STYLES.default;
    }
    if (optionOutcome === OptionOutcome.UNRESOLVED && selected) {
        return OPTION_CONTAINER_STYLES.selected;
    }

    // Otherwise return a dimmed container.
    return OPTION_CONTAINER_STYLES.default;
};

export const ProgressivePollOption = ({
    onPress,
    option,
    progressivePollLocked,
    selectedOptionId,
}: Props): JSX.Element => {
    const [loadingState, setLoadingState] = useState<IndicatorState>(
        IndicatorState.HIDDEN,
    );

    const onClick = async (e: React.MouseEvent) => {
        e.stopPropagation(); // prevent triggering of other onclicks. (expanding collapsing)
        if (onPress) {
            setLoadingState(IndicatorState.LOADING);
            const success = await onPress(option);
            setLoadingState(
                success ? IndicatorState.CONFIRMATION : IndicatorState.HIDDEN,
            );
        }
    };

    const optionOutcome = progressivePollLocked
        ? OptionOutcome.RESOLVED
        : OptionOutcome.UNRESOLVED;
    const clickable =
        loadingState !== IndicatorState.LOADING &&
        !progressivePollLocked &&
        selectedOptionId !== option.id;
    const containerStyle = getOptionContainerStyle(
        optionOutcome,
        selectedOptionId === option.id,
    );
    const dimText = progressivePollLocked && !selectedOptionId;

    return (
        <div
            className={css([
                styles.container,
                clickable ? styles.activeContainer : undefined,
                containerStyle,
                {
                    color: dimText ? Color.PREDICTION_GREY_A3 : Color.T31_BLACK,
                    cursor: clickable ? 'pointer' : 'auto',
                },
            ])}
            onClick={clickable ? onClick : undefined}
        >
            <ContentContainer>
                <TextContainer>
                    <OptionText>{option.text}</OptionText>
                </TextContainer>
            </ContentContainer>
            <SubmitStatusIndicator state={loadingState} />
        </div>
    );
};

const ContentContainer = styled.div`
    align-items: center;
    display: flex;
    min-height: 26px;
    padding: 10px;
    text-align: center;
`;

const TextContainer = styled.div`
    flex: 1;
`;

const OptionText = styled.div`
    ${TextStyles[TextStyleTypes.P4]}
    text-transform: uppercase;
`;
