import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';

import { ReactComponent as InfoIcon } from 'src/images/icons/info-black-18dp.svg';

import { ErrorMessage, SectionHeaderText, DescriptionText } from '../../shared';
import DeleteGroupForm from './DeleteGroupForm';
import CreateGroupForm from './CreateGroupForm';
import { useGroupProvider } from 'src/contexts/GroupProvider';
import { EventModel } from 'src/util/TallyFirestore';

type Props = {
    event?: EventModel;
};

const GroupSettings = ({ event }: Props) => {
    const {
        groups,
        isLoading,
        getUserGroups: { error },
    } = useGroupProvider();
    const group = groups.userGroup;
    return (
        <>
            <SectionHeaderText marginTop="30px">
                <FormattedMessage id="groupSettings.groupLeaderbord" />
            </SectionHeaderText>
            <DescriptionText>
                <FormattedMessage id="groupSettings.groupLeaderbordDescription" />
            </DescriptionText>
            <SettingContainer marginTop="16px" marginBottom="16px">
                {isLoading ? (
                    <div>
                        <FormattedMessage id="groupSettings.definitionList.isLoading" />
                    </div>
                ) : error ? (
                    <ErrorMessage error={error} />
                ) : // TODO: DeleteGroupForm needs event as it is used for share group message...
                group ? (
                    <DeleteGroupForm group={group} event={event} />
                ) : (
                    <>
                        <CreateGroupForm />
                        <DescriptionText marginTop="24px">
                            <InfoIcon
                                style={{ float: 'left', marginRight: '4px' }}
                            />{' '}
                            <FormattedMessage id="groupSettings.createGroup.description" />
                        </DescriptionText>
                    </>
                )}
            </SettingContainer>
        </>
    );
};

export default GroupSettings;

const SettingContainer = styled.div<{
    marginTop?: string;
    marginBottom?: string;
}>`
    margin-top: ${(props) => props.marginTop || '0'};
    margin-bottom: ${(props) => props.marginBottom || '0'};
    width: 100%;
`;
