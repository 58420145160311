import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import ClipLoader from 'react-spinners/ClipLoader';
import { Link } from '@material-ui/core';

import { Color } from 'src/styles/Constants';
import { ErrorMessage, SubmitGroup } from '../../../shared';
import TouchButton from 'src/components/shared/controls/TouchButton';
import ShareGroupLinkButton from 'src/components/groups/ShareGroupLinkButton';
import ShareGroupLinkQr from 'src/components/groups/ShareGroupLinkQr';
import { EventModel } from 'src/util/TallyFirestore';
import { getInviteLink, useGroupProvider } from 'src/contexts/GroupProvider';
import { UserGroup } from 'src/types';
import { useUserState } from 'src/contexts/UserStateContext';

type Props = {
    group: UserGroup;
    event: EventModel | undefined;
};

const DeleteGroupForm = ({ group, event }: Props) => {
    const userData = useUserState();
    const [showLinkToCopy, setShowLinkToCopy] = useState<boolean>(false);

    const userUID = userData.uid;
    const isOwner = group ? group.ownerId === userUID : false;

    const { deleteGroup, isLoading } = useGroupProvider();

    return (
        <>
            <ErrorMessage error={deleteGroup.error} />
            <DefinitionList>
                <dt>
                    <FormattedMessage
                        id={
                            isOwner
                                ? 'deleteGroupForm.ownerDefinition'
                                : 'deleteGroupForm.memberDefinition'
                        }
                    />
                </dt>
                <dd>
                    <GroupNameDefinition>{group.name}</GroupNameDefinition>
                </dd>
            </DefinitionList>
            <SubmitGroup>
                <ShareGroupLinkButton
                    group={group}
                    event={event}
                    onCopyToClipboardFailed={() => {
                        setShowLinkToCopy(true);
                    }}
                />
                <ShareGroupLinkQr group={group} />
                <TouchButton
                    backgroundColor={Color.S10_PEACHY}
                    displayText={
                        <FormattedMessage
                            id={
                                isOwner
                                    ? 'deleteGroupForm.ownerButtonText'
                                    : 'deleteGroupForm.memberButtonText'
                            }
                        />
                    }
                    onClick={() => deleteGroup.deleteGroup(group.id)}
                    textColor={Color.T31_BLACK}
                    secondaryStyle={true}
                    disabled={isLoading}
                />
                <ClipLoader size={20} loading={isLoading} />
            </SubmitGroup>
            {showLinkToCopy && (
                <LinkContainer>
                    <Link href={getInviteLink(window.location, group)}>
                        {getInviteLink(window.location, group)}
                    </Link>
                </LinkContainer>
            )}
        </>
    );
};

export default DeleteGroupForm;

const LinkContainer = styled.div`
    margin-top: 12px;
`;

const GroupNameDefinition = styled.div`
    padding: 6px 10px;
    background-color: #efefef;

    border: 1px #dbdbdb solid;
`;

const DefinitionList = styled.dl`
    margin: 0;
    & dt {
        user-select: none;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 18px;
        margin-bottom: 8px;
    }
    & dd {
        margin-left: 0;
    }
    & dd + dt {
        margin-top: 16px;
    }
`;
