import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import BroadcastLeaderboardRow from 'src/components/broadcast/BroadcastLeaderboardRow';
import {
    FeedLeaderboardEntry,
    SpecialFeedLeaderboardEntry,
} from 'src/services/ServerApi';
import { StyleDefaults } from 'src/styles/Constants';

import PresentedBy from 'src/components/shared/ui/PresentedBy';
import { Orientation } from 'src/styles/Constants';
import { EventModel } from 'src/util/TallyFirestore';

interface Props {
    isPlayerLeaderboard: boolean;
    playerCount: number;
    validatedQuestionCount: number;
    normalBroadcastLeaderboard?: FeedLeaderboardEntry[];
    specialBroadcastLeaderboard?: SpecialFeedLeaderboardEntry[];
    sponsorLogo?: string;
    event?: EventModel;
}

export default class BroadcastLeaderboard extends Component<Props> {
    private renderTableHeader = () => {
        const { isPlayerLeaderboard, event } = this.props;

        if (isPlayerLeaderboard) {
            return (
                <PlayerLeaderboardTitle>
                    <FormattedMessage id="broadcastLeaderbord.playerLeaderBoard.title" />
                    {this.props.sponsorLogo && (
                        <SponsorLogo>
                            <PresentedBy
                                asset={this.props.sponsorLogo}
                                assetMaxHeight={50}
                                orientation={Orientation.HORIZONTAL}
                                sponsorshipUnit={event && event.sponsorship}
                            />
                        </SponsorLogo>
                    )}
                </PlayerLeaderboardTitle>
            );
        }

        return (
            <StatsRow>
                <StatHeader
                    text={
                        <FormattedMessage id="broadcastLeaderbord.playerLeaderBoard.stats.rank" />
                    }
                    marginRight="18px"
                    width="70px"
                />
                <StatHeader
                    text={
                        <FormattedMessage id="broadcastLeaderbord.playerLeaderBoard.stats.player" />
                    }
                    marginRight="18px"
                    flexVal={2}
                />
                <StatHeader
                    text={
                        <FormattedMessage id="broadcastLeaderbord.playerLeaderBoard.stats.points" />
                    }
                    marginRight="18px"
                    flexVal={1}
                />
                <StatHeader
                    align="center"
                    text="# Correct"
                    marginRight="18px"
                    width="110px"
                />
                <StatHeader align="center" text="% Rank" width="75px" />
            </StatsRow>
        );
    };

    private renderLeaderboard = (entries: FeedLeaderboardEntry[]) => {
        const { playerCount, validatedQuestionCount } = this.props;
        return entries.map((entry, index: number) => (
            <BroadcastLeaderboardRow
                entry={entry}
                key={index}
                playerCount={playerCount}
                validatedQuestionCount={validatedQuestionCount}
            />
        ));
    };

    private renderSpecialLeaderboard = (
        entries: SpecialFeedLeaderboardEntry[],
    ) => {
        const { playerCount, validatedQuestionCount } = this.props;
        return entries.map((entry) => (
            <BroadcastLeaderboardRow
                entry={entry}
                key={entry.displayName}
                playerCount={playerCount}
                validatedQuestionCount={validatedQuestionCount}
            />
        ));
    };

    public render() {
        const {
            isPlayerLeaderboard,
            normalBroadcastLeaderboard,
            specialBroadcastLeaderboard,
        } = this.props;
        return (
            <StatsContainer>
                {this.renderTableHeader()}
                {isPlayerLeaderboard &&
                    normalBroadcastLeaderboard &&
                    this.renderLeaderboard(normalBroadcastLeaderboard)}

                {!isPlayerLeaderboard &&
                    specialBroadcastLeaderboard &&
                    this.renderSpecialLeaderboard(specialBroadcastLeaderboard)}
            </StatsContainer>
        );
    }
}

// Data table header. Rank, Player Name, etc...
const StatHeader = (props: {
    text: string | JSX.Element;
    align?: string;
    marginRight?: string;
    flexVal?: number;
    width?: string;
}) => (
    <StatRowContainer
        align={props.align}
        marginRight={props.marginRight}
        flexVal={props.flexVal}
        width={props.width}
    >
        <StatName>{props.text}</StatName>
    </StatRowContainer>
);

const PlayerLeaderboardTitle = styled.p`
    color: #9b9b9b;
    font-family: ${StyleDefaults.FONT_FAMILY};
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 2.33px;
    line-height: 18px;
    margin: 35px 0;
    text-align: left;
    text-transform: uppercase;
`;
const SponsorLogo = styled.div`
    float: right;
    width: 300px; /*Width of left column*/
    margin-top: -25px;
    margin-right: -40px;
`;

const StatRowContainer = styled.div<{
    align?: string;
    marginRight?: string;
    flexVal?: number;
    width?: string;
}>`
    display: flex;
    flex-direction: column;
    min-width: ${(props) => props.width};
    flex: ${(props) => props.flexVal};
    margin-right: ${(props) => props.marginRight};
    text-align: ${(props) => props.align};
`;

const StatsContainer = styled.section`
    display: flex;
    flex-direction: column;
`;

const StatsRow = styled.div`
    display: flex;
    justify-content: space-between;
    text-align: left;
    width: 100%;
`;

const StatName = styled.p`
    color: #9b9b9b;
    font-family: ${StyleDefaults.FONT_FAMILY};
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 2.33px;
    text-transform: uppercase;
`;
