import { Comparator, OrderedMap } from 'immutable';

function convertArrayToOrderedMap<V extends {}, K extends keyof V>(
    arr: V[],
    key: K,
    comparator: Comparator<V>,
): OrderedMap<V[K], V> {
    const map = OrderedMap<V[K], V>(
        arr.map((value) => [value[key], value]),
    ).sort(comparator);
    return map;
}

export default convertArrayToOrderedMap;
