import React from 'react';
import styled from '@emotion/styled';
import {
    PredictionBatch,
    PredictionBatchType,
} from 'src/util/PredictionHelpers';
import { PredictionType, UserAnswerSet } from 'src/util/TallyFirestore';
import { Color } from 'src/styles/Constants';
import Theme from 'src/util/Theme';
import { ProjectedAnswers } from '../../utils';

type Props = {
    predictionBatches: Array<PredictionBatch<PredictionBatchType>>;
    visibleActiveCards: Set<string>;
    answers: UserAnswerSet;
    projectedAnswers: ProjectedAnswers;
};

const Pips = ({
    predictionBatches,
    visibleActiveCards,
    answers,
    projectedAnswers,
}: Props) => {
    const pips: JSX.Element[] = [];
    let highlightedPredictionId: string | undefined;
    for (const batch of predictionBatches) {
        for (const prediction of batch.entities) {
            if (
                !highlightedPredictionId &&
                visibleActiveCards.has(prediction.id)
            ) {
                highlightedPredictionId = prediction.id;
            }
            const answered = !!(
                answers[prediction.id] || projectedAnswers[prediction.id]
            );
            pips.push(
                <Circle
                    answered={answered}
                    highlighted={highlightedPredictionId === prediction.id}
                    key={`pips-${prediction.id}`}
                    poll={prediction.type === 'POLL'}
                />,
            );
        }
    }

    return <>{pips}</>;
};

export default Pips;

const ELECTRIC_BLUE_20_OPACITY = '#C5CCF8';
const CHILI_PEPPER_20_OPACITY = '#EFD1CE';
const SQUIRTLE_ACCESSIBLE_20_OPACITY = '#D6F6FC';

const Circle = styled.div<{
    answered: boolean;
    highlighted: boolean;
    poll: boolean;
}>`
    border: 4px solid
        ${(props) => {
            if (!props.highlighted) {
                return Color.G9_MARBLE;
            }
            if (props.answered) {
                return ELECTRIC_BLUE_20_OPACITY;
            }
            return props.poll
                ? SQUIRTLE_ACCESSIBLE_20_OPACITY
                : CHILI_PEPPER_20_OPACITY;
        }};
    border-radius: 8px;
    height: 6px;
    width: 6px;
    background-color: ${(props) => {
        if (!props.answered) {
            return props.poll
                ? Color.T36_SQUIRTLE_ACCESSIBLE
                : Theme.predictionLiveIconBackgroundColor;
        }
        return props.highlighted
            ? Theme.predictionAnsweredIconBackgroundColor
            : Color.PREDICTION_GREY_A3;
    }};
    transition:
        border 0.5s,
        background-color 0.5s;
`;
