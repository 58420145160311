// TODO: colors should probably in the bingo event configuration
export const BINGO_SQUARE_STATE_TO_COLOR = {
    win: {
        backgroundColor: '#00ff00',
        color: '#000000',
    },
    occurred: {
        backgroundColor: '#fdb727',
        color: '#000000',
    },
    neutral: {
        backgroundColor: '#552583',
        color: '#ffffff',
    },
} as const;

export const IMAGE_SIZE = 200;
