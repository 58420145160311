import { useReducer } from 'react';

import { BingoSquareModel } from 'src/util/TallyFirestore';

type GetScorecardLoading = {
    id: 'getScorecardLoading';
};

type GetScorecardError = {
    id: 'getScorecardError';

    error: Error;
};

type NoScorecard = {
    id: 'noScorecard';
};

type GenerateScorecardError = {
    id: 'generateScorecardError';

    error: Error;
};

type GenerateScorecardLoading = {
    id: 'generateScorecardLoading';
};

export type GetBingoSquaresLoading = {
    id: 'getBingoSquaresLoading';

    bingoSquareIds: string[];
};

export type GetBingoSquaresError = {
    id: 'getBingoSquaresError';

    bingoSquareIds: string[];
    error: Error;
};

export type PlayingBingo = {
    id: 'playingBingo';

    scorecard: BingoSquareModel[];
};

export type BingoGameState =
    | GetScorecardLoading
    | GetScorecardError
    | GetBingoSquaresLoading
    | GetBingoSquaresError
    | GenerateScorecardError
    | NoScorecard
    | PlayingBingo
    | GenerateScorecardLoading;

type BingoGameAction =
    | {
          type: 'getScorecardFailure';
          payload: { error: Error };
      }
    | {
          type: 'getScorecardSuccess';
          // there is already a scorecard or not yet
          payload: { bingoSquareIds: string[] } | null;
      }
    | {
          type: 'getBingoSquaresFailure';
          payload: { error: Error };
      }
    | {
          type: 'getBingoSquaresSuccess';
          // there is already a scorecard or not yet
          payload: { bingoSquares: BingoSquareModel[] };
      }
    | { type: 'generateScorecardLoading' }
    | { type: 'generateScorecardSuccess' }
    | {
          type: 'generateScorecardFailure';
          payload: { error: Error };
      };

const reducer = (
    state: BingoGameState,
    action: BingoGameAction,
): BingoGameState => {
    switch (action.type) {
        case 'getScorecardSuccess':
            if (action.payload === null) {
                return { id: 'noScorecard' };
            }
            return {
                id: 'getBingoSquaresLoading',
                bingoSquareIds: action.payload.bingoSquareIds,
            };
        case 'getScorecardFailure':
            return {
                id: 'getScorecardError',
                error: action.payload.error,
            };
        case 'getBingoSquaresSuccess':
            return {
                id: 'playingBingo',
                scorecard: action.payload.bingoSquares,
            };
        case 'getBingoSquaresFailure':
            if (state.id !== 'getBingoSquaresLoading') {
                return state;
            }
            return {
                id: 'getBingoSquaresError',
                bingoSquareIds: state.bingoSquareIds,
                error: action.payload.error,
            };
        case 'generateScorecardLoading':
            return {
                id: 'generateScorecardLoading',
            };
        case 'generateScorecardSuccess':
            return {
                id: 'getScorecardLoading',
            };
        case 'generateScorecardFailure':
            return {
                id: 'generateScorecardError',
                error: action.payload.error,
            };
    }
};

const useBingoStateReducer = () => {
    return useReducer<BingoGameState, BingoGameAction>(reducer, {
        id: 'getScorecardLoading',
    });
};

export default useBingoStateReducer;
