import { useEffect, useState } from 'react';
import { useUserState } from 'src/contexts/UserStateContext';
import { usePartner } from 'src/hooks/usePartner';
import { DocumentSnapshot } from 'src/services/Firebase';
import {
    getUserAnswersForEventRef,
    getUserAnswersFromDocumentSnapshot,
} from 'src/util/FirestoreDao';
import Logger from 'src/util/Logger';
import { UserAnswerSet } from 'src/util/TallyFirestore';
import { UserPredictionModel, OddClaim } from 'src/util/TallyFirestore';

type State = {
    answers: UserAnswerSet;
    oddClaims: OddClaim | undefined;
    answersUpdatedTimestamp: Date;
};
const initialState: State = {
    answers: {},
    oddClaims: undefined,
    answersUpdatedTimestamp: new Date(),
};

const useAnswers = (eventId: string) => {
    const partner = usePartner();
    const userData = useUserState();

    const { partnerId } = partner;
    const uid = userData.uid;
    const [state, setState] = useState<State>(initialState);

    useEffect(() => {
        const onAnswersUpdate = (snapshot: DocumentSnapshot) => {
            const doc: UserPredictionModel =
                getUserAnswersFromDocumentSnapshot(snapshot);
            // Initializing answers to an empty object here is very important.
            // We use that fact to know when we've grabbed answers already.
            const { answers = {}, oddsClaims } = doc || {};

            const timestamp = new Date();

            setState({
                answers,
                answersUpdatedTimestamp: timestamp,
                oddClaims: oddsClaims,
            });

            Logger.infoTrackJsOnly('Got answers update', {
                answers,
                answersUpdateCounter: timestamp,
            });
        };

        const userPredictionsRef = getUserAnswersForEventRef({
            eventId,
            partnerId,
            uid,
        });
        const unsubscribe = userPredictionsRef.onSnapshot(onAnswersUpdate);

        return unsubscribe;
    }, [partnerId, eventId, uid]);

    return state;
};

export default useAnswers;
