import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import { StyleDefaults, Color } from 'src/styles/Constants';
import { Group, GroupMember } from 'src/types';

type Props = {
    group: Group;
    listOnly?: boolean;
};
const GroupView = ({ group, listOnly }: Props) => {
    const groupWithSortedUsers = useMemo(() => {
        let sortedUsers = [];
        if (group.groupType === 'USER_GROUP') {
            for (let i = 0; i < group.users.length; i++) {
                const user: GroupMember = group.users[i];
                if (user.id === group.ownerId) {
                    sortedUsers.unshift(user);
                } else {
                    sortedUsers.push(user);
                }
            }
        }
        return { ...group, users: sortedUsers };
    }, [group]);

    return (
        <div>
            {!listOnly && (
                <>
                    <Heading>{groupWithSortedUsers.name}</Heading>
                    <SubHead>
                        <FormattedMessage
                            id={
                                group.groupType === 'VENUE'
                                    ? 'groupPage.groupView.subHead.venue'
                                    : 'groupPage.groupView.subHead.groups'
                            }
                        />
                    </SubHead>
                </>
            )}
            <UserList>
                {groupWithSortedUsers.users.map((user) => (
                    <UserListItem key={user.id}>
                        <>
                            <UserNameSpan>{user.name}</UserNameSpan>
                            {groupWithSortedUsers.groupType === 'USER_GROUP' &&
                            groupWithSortedUsers.ownerId === user.id ? (
                                <OwnerSpan>
                                    <FormattedMessage id="groupPage.groupView.owner" />
                                </OwnerSpan>
                            ) : null}
                        </>
                    </UserListItem>
                ))}
            </UserList>
        </div>
    );
};

const Heading = styled.h1`
    text-align: center;
    font-family: Graphik Web;
    font-weight: 900;
`;

const SubHead = styled.div`
    text-align: center;
    margin-top: 10px;
    font-family: ${StyleDefaults.FONT_FAMILY};
`;
const UserList = styled.ul`
    padding: 0;
    font-size: 17px;
    margin: 20px 40px;
    & li {
        padding-top: 12px;
        border-bottom: 1px solid ${Color.P4_CHILI_PEPPER};
    }
    font-family: ${StyleDefaults.FONT_FAMILY};
`;

const UserListItem = styled.li`
    display: flex;
    justify-content: space-between;
    padding: 6px 12px;
    border-bottom: 1px solid #ff3524;
    font-family: ${StyleDefaults.FONT_FAMILY};
`;

const UserNameSpan = styled.span`
    font-weight: bold;
    font-family: ${StyleDefaults.FONT_FAMILY};
`;
const OwnerSpan = styled.span`
    color: gray;
    font-family: ${StyleDefaults.FONT_FAMILY};
    margin: 0 0 0 10px;
`;

export default GroupView;
