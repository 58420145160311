import { TrackJS } from 'trackjs';

class Logger {
    public info(msg?: any, ...params: any[]) {
        // tslint:disable:no-console
        console.info(msg, ...params);
        TrackJS.console.info(msg, params);
    }

    public infoTrackJsOnly(msg?: any, ...params: any[]) {
        TrackJS.console.info(msg, params);
    }

    public warn(msg?: any, ...params: any[]) {
        // tslint:disable:no-console
        console.warn(msg, ...params);
        TrackJS.console.warn(msg, params);
    }

    public warnTrackJsOnly(msg?: any, ...params: any[]) {
        TrackJS.console.warn(msg, params);
    }

    public error(msg?: any, ...params: any[]) {
        // tslint:disable:no-console
        console.error(msg, ...params);
        TrackJS.console.error(msg, params);
    }

    public errorTrackJsOnly(msg?: any, ...params: any[]) {
        TrackJS.console.error(msg, params);
    }
}

export default new Logger();
