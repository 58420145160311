import React, { PureComponent, createRef } from 'react';
import styled from '@emotion/styled';

import { Color, PistachioZIndex } from 'src/styles/Constants';
import PrizeDetailsModal, { PrizeInfoModalDetails } from './PrizeDetailsModal';
import HowToPlayModal, {
    HowToPlayModalDetails,
    HowToPlayModalPresentationDetails,
} from './HowToPlayModal';
import OfferWinModal, { OfferWinModalDetails } from './OfferWinModal';
import IncognitoModal from './IncognitoModal';
import NewPredictionModal, {
    NewPredictionModalDetails,
} from './NewPredictionModal';
import HowToPlayTournamentModal, {
    HowToPlayTournamentModalDetails,
} from 'src/components/shared/modals/HowToPlayTournamentModal';
import CloseButton, { CloseButtonColor } from '../controls/CloseButton';
import { EventModel } from 'src/util/TallyFirestore';
import { Analytics } from 'src/contexts/AnalyticsContext';

interface State {
    visible: boolean;
    currentModalDetails?: ModalDetails;
    nextModalDetails?: ModalDetails;
}

export enum ModalType {
    HOW_TO_PLAY = 'HOW_TO_PLAY',
    HOW_TO_PLAY_TOURNAMENT = 'HOW_TO_PLAY_TOURNAMENT',
    NEW_PREDICTION = 'NEW_PREDICTION',
    PRIZES = 'PRIZE DETAILS',
    INCOGNITO = 'INCOGNITO',
    OFFER_WIN = 'OFFER_WIN',
}

export interface ModalPresentationDetails {
    backgroundColor?: string;
    backgroundImageUrl?: string;
    backgroundPosition?: string;
    hideCloseButton?: boolean;
    closeIconColor?: CloseButtonColor;
}

export type ModalDetails =
    | NewPredictionModalDetails
    | OfferWinModalDetails
    | PrizeInfoModalDetails
    | HowToPlayModalDetails
    | HowToPlayTournamentModalDetails
    | { modalType: ModalType.INCOGNITO; event?: EventModel };

export interface ModalCheckboxInfo {
    smsAlertsEnabled: boolean;
    title: string;
    message: string;
}

type Props = { analytics: Analytics };

class ModalHoster extends PureComponent<Props, State> {
    private modalContainer = createRef<HTMLDivElement>();

    constructor(props: Props) {
        super(props);

        this.state = {
            currentModalDetails: {
                modalType: ModalType.HOW_TO_PLAY,
            },
            visible: false,
        };
    }

    public showModal = (modalDetails: ModalDetails) => {
        const { analytics } = this.props;
        const { visible } = this.state;

        if (visible && this.modalContainer.current) {
            this.modalContainer.current.addEventListener(
                'transitionend',
                this.onTransitionFadeOutEnd,
            );

            this.setState({
                nextModalDetails: modalDetails,
                visible: false,
            });
        } else {
            analytics.logModalView({
                modalName: modalDetails.modalType,
            });
            this.setState({
                currentModalDetails: modalDetails,
                visible: true,
            });
        }
    };

    private onTransitionFadeOutEnd = () => {
        const { nextModalDetails } = this.state;
        this.setState({
            currentModalDetails: nextModalDetails,
            nextModalDetails: undefined,
            visible: true,
        });

        if (this.modalContainer.current) {
            this.modalContainer.current.removeEventListener(
                'transitionend',
                this.onTransitionFadeOutEnd,
            );
        }
    };

    public closeModal = async () => {
        this.setState({
            visible: false,
        });
    };

    private static getModal(
        modalDetails: ModalDetails | undefined,
        closeModal: () => void,
    ): [JSX.Element, ModalPresentationDetails] | undefined {
        if (!modalDetails) {
            return undefined;
        }

        switch (modalDetails.modalType) {
            case ModalType.HOW_TO_PLAY: {
                const { iteration } = modalDetails;
                return [
                    <HowToPlayModal
                        requestClose={closeModal}
                        bodyText={iteration && iteration.stayTunedModalBody}
                        headerText={iteration && iteration.stayTunedModalHeader}
                    />,
                    HowToPlayModalPresentationDetails,
                ];
            }
            case ModalType.HOW_TO_PLAY_TOURNAMENT: {
                const { title, body } = modalDetails;
                return [
                    <HowToPlayTournamentModal
                        requestClose={closeModal}
                        title={title}
                        body={body}
                    />,
                    HowToPlayTournamentModal.getPresentationDetails(),
                ];
            }
            case ModalType.INCOGNITO: {
                return [
                    <IncognitoModal requestClose={closeModal} />,
                    IncognitoModal.getPresentationDetails(),
                ];
            }
            case ModalType.NEW_PREDICTION: {
                const { onPredictNowClick, activePrediction } = modalDetails;
                return [
                    <NewPredictionModal
                        requestClose={closeModal}
                        onPredictNowClick={onPredictNowClick}
                        activePrediction={activePrediction}
                    />,
                    NewPredictionModal.getPresentationDetails(),
                ];
            }
            case ModalType.PRIZES: {
                const { event, gameWideSponsor, bonusSponsors } = modalDetails;
                return [
                    <PrizeDetailsModal
                        prizeDetailsMarkdown={event.prizeDetailsMarkdown}
                        gameWideSponsor={gameWideSponsor}
                        bonusSponsors={bonusSponsors}
                        eventSponsorshipUnit={event.sponsorship}
                        requestClose={closeModal}
                    />,
                    PrizeDetailsModal.getPresentationDetails(),
                ];
            }
            case ModalType.OFFER_WIN: {
                const { userAnswer, sponsor, prediction, onSeeRewardsClick } =
                    modalDetails;
                return [
                    <OfferWinModal
                        userAnswer={userAnswer}
                        prediction={prediction}
                        sponsor={sponsor}
                        requestClose={closeModal}
                        onSeeRewardsClick={onSeeRewardsClick}
                    />,
                    OfferWinModal.getPresentationDetails(),
                ];
            }
        }
    }

    // decoy onclick to prevent the parent from auto closing the modal and allowing
    // the modal to respond to the event.
    private decoy = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
    };

    public render() {
        const { visible, currentModalDetails } = this.state;

        const modalDetails = ModalHoster.getModal(
            currentModalDetails,
            this.closeModal,
        );

        if (!modalDetails) {
            return <div />;
        }

        const [modal, presentationDetails] = modalDetails;

        const {
            backgroundColor,
            backgroundImageUrl,
            backgroundPosition,
            hideCloseButton,
            closeIconColor,
        } = presentationDetails;

        return (
            <FullScreen visible={visible} onClick={this.closeModal}>
                <TranslucentLayer />
                <ModalContainer
                    ref={this.modalContainer}
                    onClick={this.decoy}
                    backgroundColor={backgroundColor}
                    backgroundImageUrl={backgroundImageUrl}
                    backgroundPosition={backgroundPosition}
                    visible={visible}
                >
                    {!hideCloseButton && (
                        <ModalHeader>
                            <CloseButton
                                color={closeIconColor}
                                onClick={this.closeModal}
                            />
                        </ModalHeader>
                    )}
                    {modal}
                </ModalContainer>
            </FullScreen>
        );
    }
}

export default ModalHoster;

const ModalHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 20px;
    padding: 20px;
`;

const ModalContainer = styled.div<{
    backgroundColor?: string;
    backgroundImageUrl?: string;
    backgroundPosition?: string;
    visible: boolean;
}>`
    transform: ${(props) => (props.visible ? `scale(1.0)` : `scale(0.95)`)};
    transition: transform 0.3s;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    max-height: 80%;
    max-width: 300px;
    width: 92%;
    z-index: ${PistachioZIndex.MODAL_HOSTER_MODAL};
    margin: 0 10px;
    background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : Color.P1_WHITE};
    background-image: url(${(props) => props.backgroundImageUrl});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: ${(props) => props.backgroundPosition};
`;

const TranslucentLayer = styled.div`
    pointer-events: none;
    position: fixed;
    background-color: ${Color.T31_BLACK};
    opacity: 0.6;
    height: 100%;
    width: 100%;
`;

const FullScreen = styled.div<{ visible: boolean }>`
    pointer-events: ${(props) => (props.visible ? undefined : 'none')};
    opacity: ${(props) => (props.visible ? 1 : 0)};
    transition: opacity 0.3s;
    z-index: ${PistachioZIndex.MODAL_HOSTER_FULL_SCREEN};
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
`;
