import { OfferCodeType } from 'src/util/TallyFirestore';

export type BarcodeType = Exclude<OfferCodeType, 'TEXT_ONLY'>;
export type LinearBarcode = Exclude<BarcodeType, 'QR_CODE'>;

export type JsBarcodeFormat =
    | 'CODE128'
    | 'CODE39'
    | 'ITF14'
    | 'EAN13'
    | 'EAN8'
    | 'UPC';

export const barcodeTypeToJsBarcodeFormat = (
    offerCodeType: LinearBarcode,
): JsBarcodeFormat => {
    switch (offerCodeType) {
        case 'CODE_128':
            return 'CODE128';
        case 'CODE_39':
            return 'CODE39';
        case 'ITF_14':
            return 'ITF14';
        case 'EAN_13':
            return 'EAN13';
        case 'EAN_8':
            return 'EAN8';
        case 'UPC_A':
            return 'UPC';
    }
};
