import queryString from 'query-string';
import config from 'src/util/Config';

export enum BrowserMode {
    standard = 'standard',
    embedded = 'embedded',
}

function detectBrowserMode() {
    const query = window.location && window.location.search;
    const parsedQuery = query && queryString.parse(query);

    let mode;
    if (
        parsedQuery &&
        (parsedQuery.embed === '1' ||
            (typeof parsedQuery.utm_source === 'string' &&
                config.partnerData.properties.embeddedUtmValues &&
                config.partnerData.properties.embeddedUtmValues.includes(
                    parsedQuery.utm_source.toLowerCase(),
                )))
    ) {
        mode = BrowserMode.embedded;
    } else {
        mode = BrowserMode.standard;
    }

    return mode;
}

const browserMode: BrowserMode = detectBrowserMode();

export default browserMode;
