import styled from '@emotion/styled';
import { css } from 'emotion';
import React from 'react';

import checkmark from 'src/images/icons/Picks-Correct-M@3x.svg';
import wrongmark from 'src/images/icons/Picks-Wrong-M@3x.svg';

import {
    ANSWER_CORRECTNESS_COLORS,
    Color,
    TextStyles,
    TextStyleTypes,
} from 'src/styles/Constants';
import theme from 'src/util/Theme';

type BasicProps = {
    text: string;
};

type AnswerableProps = BasicProps & {
    type: 'answerable';
    onSelect: () => void;
};

type AnswerLoadingProps = BasicProps & {
    type: 'answerLoading';
    picked: boolean;
};

type Answered = {
    type: 'answered';
    picked: boolean;
    correct: boolean;
};

type AnsweredProps = BasicProps & Answered;

type Props = AnswerableProps | AnswerLoadingProps | AnsweredProps;

export const styles = {
    activeContainer: {
        '&:active': {
            opacity: 0.4,
        },
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        '&:not(:first-child)': {
            marginTop: 10,
        },
        borderStyle: 'solid',
        borderWidth: 1,
        transition: 'border-color 0.3s',
        padding: 8,
        textAlign: 'initial',
    },
} as const;

export const getOptionBorderColor = (
    props: Props,
    selectedColor?: string,
): string => {
    if (props.type === 'answered') {
        if (props.correct) {
            return ANSWER_CORRECTNESS_COLORS.correct;
        }
        if (props.picked) {
            return ANSWER_CORRECTNESS_COLORS.incorrect;
        }
    }
    if (props.type === 'answerLoading' && props.picked) {
        return selectedColor || theme.predictionAnsweredIconBackgroundColor;
    }

    return Color.T28_FOG;
};

const TextOption = (props: Props) => {
    const clickable = props.type === 'answerable';
    return (
        <div
            className={css([
                styles.container,
                clickable && styles.activeContainer,
                {
                    borderColor: getOptionBorderColor(props),
                },
                {
                    cursor: clickable ? 'pointer' : 'auto',
                },
            ])}
            {...{
                onClick:
                    props.type === 'answerable' ? props.onSelect : undefined,
            }}
        >
            <OptionText>{props.text}</OptionText>
            {props.type === 'answered' && props.picked && (
                <ResolvedIcon src={props.correct ? checkmark : wrongmark} />
            )}
        </div>
    );
};

export default TextOption;

export const ResolvedIcon = styled.img`
    height: 18px;
    width: 18px;
`;

export const OptionText = styled.div`
    flex-grow: 1;
    ${TextStyles[TextStyleTypes.P4]}
`;
