import styled from '@emotion/styled';
import { OrderedMap } from 'immutable';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import AccountPage from 'src/components/AccountPage';
import GroupPage from 'src/components/GroupPage';
import Onboarding from 'src/components/pistachioGame/onboarding/Onboarding';
import PistachioTitlebar from 'src/components/pistachioGame/PistachioTitlebar';
import BingoGame from 'src/components/pistachioGame/BingoGame';
import EventIframeAd from 'src/components/shared/events/EventIframeAd';
import PrivateRoute from 'src/components/shared/PrivateRoute';
import PresentedBy from 'src/components/shared/ui/PresentedBy';
import { Leaderboards } from 'src/hooks/useLiveEntityLeaderboards';
import {
    AdCardContentContainer,
    StickyContainer,
} from 'src/Iteration/components';
import LeaderboardSummaryPage from 'src/Iteration/MultiEvent/LeaderboardSummaryPage';
import routeUrls from 'src/routes';
import { Color } from 'src/styles/Constants';
import { TranslatedConfigPartnerData } from 'src/util/ConfigTypes';
import { TranslatedIteration } from 'src/util/i18n';
import { BingoEventModel, EventModel } from 'src/util/TallyFirestore';
import { AdSizingPolicy, SponsorModel } from 'src/util/TallyFirestore';
import { getEventUITheme } from '../utils';
import { BaseEventPageContainer } from '../components';

export type Props = {
    partner: TranslatedConfigPartnerData;
    gameWideSponsor?: SponsorModel;
    iteration: TranslatedIteration;
    events: OrderedMap<string, EventModel>;
    iterationLeaderboards?: Leaderboards;
    event: BingoEventModel;
    eventLeaderboards: Leaderboards;
};

const BingoEvent = ({
    partner,
    gameWideSponsor,
    iterationLeaderboards,
    events,
    iteration,
    event,
    eventLeaderboards,
}: Props) => {
    const isAdSticky =
        event.adPinned &&
        event.adSizingPolicy === AdSizingPolicy.preserveAspectRatio;

    const shouldDisplayAd = event.adEnabled && event.adIframeHtml;
    const presentedBySponsorLogo = event.sponsorship
        ? event.sponsorship.properties.gameWideLogoUrl ||
          event.sponsorship.properties.gameLogoUrl
        : undefined;

    return (
        <>
            <StickyContainer>
                <PistachioTitlebar iteration={iteration} event={event} />
                {isAdSticky &&
                    shouldDisplayAd &&
                    (event.adIframeHtml ? (
                        <AdCardContentContainer>
                            <EventIframeAd
                                eventId={event.id}
                                eventAd={{
                                    iframeHtml: event.adIframeHtml,
                                    headline: event.adHeadline,
                                    disclaimer: event.adDisclaimer,
                                    sizingPolicy: event.adSizingPolicy,
                                    cutOffHeight: event.adCutOffHeight,
                                }}
                            />
                        </AdCardContentContainer>
                    ) : null)}
            </StickyContainer>
            <Route path={routeUrls.onboarding}>
                <Onboarding
                    partner={partner}
                    iteration={iteration}
                    gameWideSponsor={gameWideSponsor}
                />
            </Route>
            <PrivateRoute
                path={routeUrls.play}
                children={(props) => (
                    <>
                        {presentedBySponsorLogo && (
                            <PresentedByBanner>
                                <PresentedBy
                                    sponsorshipUnit={event.sponsorship}
                                    asset={presentedBySponsorLogo}
                                />
                            </PresentedByBanner>
                        )}
                        <BingoPageContainer
                            visible={Boolean(props.match)}
                            {...getEventUITheme(event, partner)}
                        >
                            <BingoGame
                                iteration={iteration}
                                event={event}
                                bingo={event.bingo}
                                leaderboardEntry={
                                    eventLeaderboards.generalLeaderboard
                                        .leaderboardEntry
                                }
                            />
                        </BingoPageContainer>
                    </>
                )}
            />
            <Route path={`${routeUrls.leaderboardSummary}/:tabPanelId`}>
                {partner.properties.hideLeaderboards ? (
                    <Redirect to={routeUrls.play} />
                ) : (
                    <LeaderboardSummaryPage
                        iteration={iteration}
                        iterationLeaderboards={iterationLeaderboards}
                        events={events}
                        eventWithLeaderboards={{
                            event,
                            leaderboards: eventLeaderboards,
                        }}
                    />
                )}
            </Route>
            <PrivateRoute path={routeUrls.account}>
                <AccountPage iteration={iteration} event={event} />
            </PrivateRoute>
            <PrivateRoute path={routeUrls.group}>
                <GroupPage iteration={iteration} />
            </PrivateRoute>
        </>
    );
};

export default BingoEvent;

const BingoPageContainer = styled(BaseEventPageContainer)`
    padding: 25px 0 80px 0;
`;

const PresentedByBanner = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    aligh-items: center;
    background-color: ${Color.G11_WHITE};
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.08);
`;
