import React from 'react';
import styled from '@emotion/styled';

import { Color, TextStyles, TextStyleTypes } from 'src/styles/Constants';
import { UserOffer } from 'src/util/TallyFirestore';
import Status from './Status';

type Props = {
    userOffer: UserOffer;
    onClick: (userOffer: UserOffer) => void;
    pending: boolean; // if true, then the user has not yet officially won this offer yet.
    sponsorLogoUrl?: string;
};

const OfferCard = ({ onClick, pending, sponsorLogoUrl, userOffer }: Props) => {
    const { displayName, expirationDate } = userOffer;

    const handleClick = () => {
        if (!pending) {
            onClick(userOffer);
        }
    };

    return (
        <Container disabled={pending} onClick={handleClick}>
            <SponsorLogo src={sponsorLogoUrl} />
            <Status expirationDate={expirationDate} pending={pending} />
            <NameText>{displayName}</NameText>
        </Container>
    );
};

export default OfferCard;

const Container = styled.div<{ disabled?: boolean }>`
    &:hover {
        cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    }
    border: 1px solid ${Color.T28_FOG};
    box-sizing: border-box;
    margin-bottom: 8px;
    padding: 20px;
    width: 100%;
`;

const SponsorLogo = styled.img`
    height: 30px;
`;

const NameText = styled.p`
    ${TextStyles[TextStyleTypes.C1]};
`;
