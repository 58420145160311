import React, { useRef } from 'react';
import { useAd } from 'src/contexts/AdProvider';
import { AdSizingPolicy, PredictionAd } from 'src/util/TallyFirestore';
import TextButton from '../../controls/TextButton';
import { useAnalytics } from 'src/contexts/AnalyticsContext';
import { AppArea, EventName } from 'src/util/AnalyticsConstants';
import { getIframeAspectRatio } from 'src/util/Iframe';
import { useIntl } from 'react-intl';
import AdIframe from '../../ads/AdIframe';
import {
    AdContainer,
    AdHeadline,
    AspectRatioContainer,
    Disclaimer,
    GradientOverlay,
} from '../../ads/shared';

type Props = {
    predictionAd: PredictionAd;
    predictionId: string;
};

const PredictionIframeAd = ({ predictionAd, predictionId }: Props) => {
    const intl = useIntl();
    const analytics = useAnalytics();
    const ref = useRef<HTMLDivElement>(null);
    const adData = useAd(predictionAd, predictionId, ref);

    if (!adData) {
        return null;
    }
    const { ad, showReadMoreButton, setShowReadMoreButton } = adData;
    const { adHtml, headline, disclaimer, sizingPolicy } = ad;

    if (!adHtml) {
        return null;
    }

    const onIframeLoad = () => {
        analytics.logEvent({
            eventName: EventName.iframeSeen,
            params: {
                prediction: predictionId,
                ad_headline: headline,
            },
            eventCategory: AppArea.game,
        });
    };

    if (sizingPolicy === AdSizingPolicy.preserveAspectRatio) {
        const aspectRatio = getIframeAspectRatio(adHtml);

        return (
            <AdContainer centerItems={true}>
                <div
                    ref={ref}
                    style={{
                        width: '100%',
                        ...(aspectRatio ? { maxWidth: aspectRatio.width } : {}),
                    }}
                >
                    <AdHeadline>{headline}</AdHeadline>
                    <AspectRatioContainer aspectRatio={aspectRatio}>
                        <AdIframe iframeHtml={adHtml} onLoad={onIframeLoad} />
                    </AspectRatioContainer>
                    <Disclaimer>{disclaimer}</Disclaimer>
                </div>
            </AdContainer>
        );
    }
    return (
        <AdContainer>
            <AdHeadline>{headline}</AdHeadline>
            <div style={{ position: 'relative' }}>
                <div ref={ref} style={{ width: '100%' }}>
                    <AdIframe iframeHtml={adHtml} onLoad={onIframeLoad} />
                </div>
                {sizingPolicy === AdSizingPolicy.readMore &&
                    showReadMoreButton && <GradientOverlay />}
            </div>
            {sizingPolicy === AdSizingPolicy.readMore && showReadMoreButton && (
                <TextButton
                    text={intl.formatMessage({
                        id: 'predictPage.readMoreButton.text',
                    })}
                    onClick={async () => {
                        analytics.logEvent({
                            eventName: EventName.contentReadMore,
                            params: { prediction: predictionId },
                            eventCategory: AppArea.game,
                        });
                        setShowReadMoreButton(false);
                    }}
                />
            )}
            <Disclaimer>{disclaimer}</Disclaimer>
        </AdContainer>
    );
};

export default PredictionIframeAd;
