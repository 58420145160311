import React from 'react';
import { OrderedMap } from 'immutable';
import { TranslatedIteration } from 'src/util/i18n';
import { EventModel } from 'src/util/TallyFirestore';
import { makeStyles, Tab, Tabs } from '@material-ui/core';
import LeaderboardTabPanel from './LeaderboardsTabPanel';
import { FormattedMessage } from 'react-intl';
import routeUrls from 'src/routes';
import {
    Redirect,
    Route,
    Switch,
    useHistory,
    useLocation,
    useParams,
    useRouteMatch,
} from 'react-router-dom';
import IterationGeneralLeaderboardPage from 'src/components/IterationGeneralLeaderboardPage';
import IntraGroupLeaderboardPage from 'src/components/IntraGroupLeaderboardPage';
import InterGroupLeaderboardPage from './InterGroupLeaderboardPage';
import useLiveEntityLeaderboards, {
    Leaderboards,
} from 'src/hooks/useLiveEntityLeaderboards';
import EventGeneralLeaderboardPage from 'src/components/pistachioGame/EventGeneralLeaderboardPage';
import styled from '@emotion/styled';
import { Color, StyleDefaults } from 'src/styles/Constants';
import { usePartner } from 'src/hooks/usePartner';

type Props = {
    iteration: TranslatedIteration;
    iterationLeaderboards?: Leaderboards;
    events: OrderedMap<string, EventModel>;
    eventWithLeaderboards?: {
        event: EventModel;
        leaderboards: Leaderboards;
    };
};

const useStyles = ({
    primaryHeadingTextColor,
    secondaryHeadingTextColor,
}: {
    primaryHeadingTextColor: string;
    secondaryHeadingTextColor: string;
}) =>
    makeStyles((theme) => {
        return {
            tabs: {
                marginBottom: theme.spacing(2),

                '& .MuiTab-root.MuiTab-textColorSecondary.Mui-selected': {
                    color: primaryHeadingTextColor,
                },

                '& .MuiTab-root.MuiTab-textColorSecondary': {
                    color: secondaryHeadingTextColor,
                },
            },
        };
    });

type IterationPanelProps = {
    id: 'iteration';
    iteration: TranslatedIteration;
    leaderboards: Leaderboards;
};

type EventPanelProps = {
    id: 'event';
    iteration: TranslatedIteration;
    event: EventModel;
    leaderboards: Leaderboards;
};

type NotFound = {
    id: 'notFound';
};

type LeaderboardPanelProps = IterationPanelProps | EventPanelProps | NotFound;

const useLeaderboardsPanel = ({
    tabPanelId,
    iteration,
    events,
    iterationLeaderboards,
    eventWithLeaderboards,
}: {
    tabPanelId: 'iteration' | string;
    iteration: TranslatedIteration;
    events: OrderedMap<string, EventModel>;
    iterationLeaderboards?: Leaderboards;

    eventWithLeaderboards?: {
        event: EventModel;
        leaderboards: Leaderboards;
    };
}): LeaderboardPanelProps => {
    const eventInEvents = events.get(tabPanelId);

    const eventHookLeaderboards = useLiveEntityLeaderboards(
        eventInEvents?.path,
        iteration,
    );

    if (tabPanelId === 'iteration') {
        if (!iterationLeaderboards) {
            return { id: 'notFound' };
        }

        return {
            id: 'iteration',
            iteration,
            leaderboards: iterationLeaderboards,
        };
    }

    if (!eventInEvents || !eventHookLeaderboards) {
        return { id: 'notFound' };
    }

    if (
        eventWithLeaderboards &&
        eventInEvents.id === eventWithLeaderboards.event.id
    ) {
        return {
            id: 'event',
            iteration,
            ...eventWithLeaderboards,
        };
    }

    return {
        id: 'event',
        iteration,
        event: eventInEvents,
        leaderboards: eventHookLeaderboards,
    };
};

const LeaderboardSummaryPage = ({
    iteration,
    iterationLeaderboards,
    events,
    eventWithLeaderboards,
}: Props) => {
    const partner = usePartner();

    const { leaderboardsPage } = partner.properties;
    const classes = useStyles({
        primaryHeadingTextColor:
            partner.properties.headingColor || Color.G1_BLACK,
        secondaryHeadingTextColor:
            (leaderboardsPage && leaderboardsPage.secondaryHeadingTextColor) ||
            Color.G1_BLACK,
    })();
    const history = useHistory();
    const location = useLocation();
    const { path, url } = useRouteMatch();

    const { tabPanelId } = useParams<{ tabPanelId: string }>();

    const leaderboardPanel = useLeaderboardsPanel({
        tabPanelId,
        iteration,
        iterationLeaderboards,
        events,
        eventWithLeaderboards,
    });

    const omitCorrectAnswers =
        leaderboardPanel.id === 'event' &&
        (leaderboardPanel.event.type === 'IGAME' ||
            leaderboardPanel.event.type === 'BINGO');

    document.body.style.backgroundColor = Color.G10_WHITISH;

    if (leaderboardPanel.id === 'notFound') {
        return (
            <Redirect
                to={`${routeUrls.leaderboardSummary}/${events
                    .keySeq()
                    .first()}${location.search}`}
            />
        );
    }

    const handleChange = (
        _: React.ChangeEvent<{}>,
        eventSlugOrIteration: string,
    ) => {
        history.push({
            pathname: eventSlugOrIteration,
            search: location.search,
        });
    };
    const eventsArray = events ? events.toArray() : [];

    const tabs: JSX.Element[] = [];

    if (iterationLeaderboards) {
        tabs.push(
            <Tab
                label={
                    <FormattedMessage id="leaderboardPage.tab.overall.label" />
                }
                key="iteration"
                value="iteration"
            />,
        );
    }

    tabs.push(
        ...eventsArray.map(([slug, event]) => {
            return <Tab label={event.shortName} key={slug} value={slug} />;
        }),
    );

    return (
        <Switch>
            <Route
                path={`${routeUrls.iterationLeaderboardSummary}${routeUrls.generalLeaderboard}`}
            >
                <IterationGeneralLeaderboardPage
                    leaderboard={
                        leaderboardPanel.leaderboards.generalLeaderboard
                    }
                    iteration={iteration}
                />
            </Route>
            <Route path={`${path}${routeUrls.generalLeaderboard}`}>
                {leaderboardPanel.id === 'event' ? (
                    <EventGeneralLeaderboardPage
                        leaderboard={
                            leaderboardPanel.leaderboards.generalLeaderboard
                        }
                        event={leaderboardPanel.event}
                        omitCorrectAnswers={omitCorrectAnswers}
                    />
                ) : null}
            </Route>
            <Route
                path={`${path}${routeUrls.intraGroupLeaderboard}/:grouptype`}
            >
                <IntraGroupLeaderboardPage
                    leaderboards={leaderboardPanel.leaderboards}
                    iteration={leaderboardPanel.iteration}
                    omitCorrectAnswers={omitCorrectAnswers}
                />
            </Route>
            <Route
                path={`${path}${routeUrls.interGroupLeaderboard}/:grouptype`}
            >
                <InterGroupLeaderboardPage
                    leaderboards={leaderboardPanel.leaderboards}
                    iteration={iteration}
                    omitCorrectAnswers={omitCorrectAnswers}
                />
            </Route>
            <Route path={path}>
                <PageContainer
                    backgroundColor={
                        (leaderboardsPage &&
                            leaderboardsPage.backgroundColor) ||
                        Color.P1_WHITE
                    }
                    backgroundImage={
                        leaderboardsPage && leaderboardsPage.backgroundImgUrl
                    }
                >
                    {leaderboardsPage && leaderboardsPage.headlineImage && (
                        <HeadlineImage
                            src={leaderboardsPage.headlineImage}
                            alt="logo"
                        />
                    )}

                    <Tabs
                        variant="scrollable"
                        className={classes.tabs}
                        textColor="secondary"
                        value={tabPanelId}
                        onChange={handleChange}
                        TabIndicatorProps={{
                            style: {
                                background: partner.properties.headingColor,
                            },
                        }}
                        TabScrollButtonProps={{
                            style: {
                                color:
                                    partner.properties.headingColor ||
                                    Color.G1_BLACK,
                            },
                        }}
                    >
                        {tabs}
                    </Tabs>
                    <LeaderboardTabPanel
                        url={url}
                        leaderboards={leaderboardPanel.leaderboards}
                        events={events}
                        omitCorrectAnswers={omitCorrectAnswers}
                    />
                </PageContainer>
            </Route>
        </Switch>
    );
};
export default LeaderboardSummaryPage;

const PageContainer = styled.div<{
    backgroundColor?: string;
    backgroundImage?: string;
}>`
    max-width: ${StyleDefaults.MAX_ELEMENT_WIDTH};
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${(props) => props.backgroundColor || Color.G10_WHITISH};
    align-items: stretch;
    padding: 40px 20px 80px 20px;
    background-image: url('${(props) => props.backgroundImage}');
    background-repeat: no-repeat;
    background-position: center top;
`;

const HeadlineImage = styled.img`
    max-width: 100%;
    width: auto;
    height: auto;
    align-self: center;
    margin-bottom: 10px;
`;
