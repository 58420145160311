import { IntlShape } from 'react-intl';

import correctToast from 'src/images/icons/toast-correct@2x.png';
import incorrectToast from 'src/images/icons/toast-incorrect@2x.png';
import { ToastDetails, ToastTextColor } from 'src/components/shared/ui/Toast';
import { QuerySnapshot } from 'src/services/Firebase';
import { Color } from 'src/styles/Constants';
import { AnswerCorrectness } from 'src/util/EventHelpers';
import { getPredictionFromDocumentSnapshot } from 'src/util/FirestoreDao';
import { LocaleId } from 'src/util/LocaleHelpers';
import {
    calcOddsWinPoints,
    GroupedPredictions,
    predictionIsCorrect,
} from 'src/util/PredictionHelpers';
import { PredictionSponsorshipUnitTemplateId } from 'src/util/SponsorshipTypes';
import {
    TranslatableEventModel,
    OddClaim,
    PredictionModel,
    ProgressivePollAnswer,
    ProgressivePollModel,
    UserAnswerSet,
    UserModel,
} from 'src/util/TallyFirestore';

// Turns out, lockDescription can be null, but we didn't expect it. For now, turn it to undefined here...
export const transformPredictionsToExpectedType = (
    predictions: PredictionModel[],
) => {
    return predictions.map(({ lockDescription, ...rest }) => ({
        lockDescription: lockDescription === null ? undefined : lockDescription,
        ...rest,
    }));
};

export const getPredictionsFromQuerySnapshot =
    (event: TranslatableEventModel) =>
    (snapshot: QuerySnapshot): PredictionModel[] => {
        const predictions: PredictionModel[] = [];
        snapshot.forEach((doc) => {
            const prediction = getPredictionFromDocumentSnapshot(doc);

            // we need to lie about point values ont he front end.
            // mutate the data =((((((
            if (
                prediction.sponsorship &&
                prediction.sponsorship.templateId ===
                    PredictionSponsorshipUnitTemplateId.DOUBLE_POINT_POWERUP
            ) {
                prediction.pointValue = prediction.pointValue * 2;
                for (const option of prediction.options) {
                    if (option.pointValue) {
                        option.pointValue = option.pointValue * 2;
                    }
                }
            }

            predictions.push(prediction);
        });

        predictions.sort((a, b) => a.number - b.number);

        return predictions;
    };

export const getEventsProgressivePollsAndAnswers = ({
    event,
    progressivePolls,
    progressivePollsAnswers,
}: {
    event: TranslatableEventModel;
    progressivePolls: ProgressivePollModel[];
    progressivePollsAnswers: ProgressivePollAnswer[];
}) => {
    const availableProgressivePolls = progressivePolls
        .filter(
            (pPoll: ProgressivePollModel) =>
                !progressivePollsAnswers.some(({ pollId, eventId }) => {
                    return pollId === pPoll.id && eventId !== event.id;
                }),
        )
        .sort((a, b) => (a.number > b.number ? 1 : -1));
    const thisEventProgressivePollsAnswers = progressivePollsAnswers.filter(
        (ppa: ProgressivePollAnswer) => ppa.eventId === event.id,
    );
    return {
        availableProgressivePolls,
        thisEventProgressivePollsAnswers,
    };
};

export const makePredictionToast = ({
    intl,
    userDetails,
    localeId,
    predictions,
    answers,
    oddClaims,
}: {
    intl: IntlShape;
    userDetails?: UserModel;
    localeId: LocaleId;
    predictions: PredictionModel[];
    answers: UserAnswerSet;
    oddClaims?: OddClaim;
}): [ToastDetails, string] => {
    let toastDetails: ToastDetails;
    let toastPredictionScrollToTarget: string;

    if (predictions.length === 1) {
        const firstPrediction = predictions[0];
        const readablePointValue = (
            oddClaims
                ? calcOddsWinPoints(answers, oddClaims, firstPrediction)
                : firstPrediction.pointValue
        ).toLocaleString(localeId);

        const correct =
            predictionIsCorrect(
                firstPrediction,
                answers[firstPrediction.id],
            ) === AnswerCorrectness.correct;
        if (correct) {
            toastDetails = {
                backgroundColor: Color.T32_VIKING_ACCESSIBLE,
                backgroundImage: correctToast,
                color: ToastTextColor.BLACK,
                text: intl.formatMessage(
                    {
                        id: `pistachioGame.toast.correct.text`,
                    },
                    {
                        points: readablePointValue,
                    },
                ),
                title: intl.formatMessage({
                    id: 'pistachioGame.toast.correct.title',
                }),
            };
        } else {
            toastDetails = {
                backgroundColor: Color.S12_VARSITY_RED,
                backgroundImage: incorrectToast,
                color: ToastTextColor.WHITE,
                text: intl.formatMessage(
                    {
                        id: `pistachioGame.toast.wrong.text`,
                    },
                    {
                        name: userDetails!.displayName,
                    },
                ),
                title: intl.formatMessage({
                    id: 'pistachioGame.toast.wrong.title',
                }),
            };
        }

        toastPredictionScrollToTarget = firstPrediction.id;
    } else {
        let totalCorrect = 0;
        let totalPoints = 0;
        let totalPredictions = 0;

        for (const prediction of predictions) {
            if (prediction.type !== 'POLL') {
                totalPredictions++;
                if (
                    predictionIsCorrect(prediction, answers[prediction.id]) ===
                    AnswerCorrectness.correct
                ) {
                    totalCorrect++;
                    totalPoints +=
                        prediction.type === 'BET_SIM' && oddClaims
                            ? calcOddsWinPoints(answers, oddClaims, prediction)
                            : prediction.pointValue;
                }
            }
        }

        if (totalCorrect === 0) {
            toastDetails = {
                backgroundColor: Color.S12_VARSITY_RED,
                backgroundImage: incorrectToast,
                color: ToastTextColor.WHITE,
                text: intl.formatMessage(
                    {
                        id: 'pistachioGame.toast.noCorrect.text',
                    },
                    {
                        name: userDetails!.displayName,
                    },
                ),
                title: intl.formatMessage(
                    {
                        id: 'pistachioGame.toast.noCorrect.title',
                    },
                    {
                        count: totalPredictions,
                    },
                ),
            };
        } else {
            const readablePointValue = totalPoints.toLocaleString();
            toastDetails = {
                backgroundColor: Color.T32_VIKING_ACCESSIBLE,
                backgroundImage: correctToast,
                color: ToastTextColor.BLACK,
                text: intl.formatMessage(
                    {
                        id: `pistachioGame.toast.someCorrect.text`,
                    },
                    {
                        points: readablePointValue,
                    },
                ),
                title: intl.formatMessage(
                    {
                        id: 'pistachioGame.toast.someCorrect.title',
                    },
                    {
                        totalCorrect: totalCorrect,
                        totalPredictions: totalPredictions,
                    },
                ),
            };
        }

        toastPredictionScrollToTarget = predictions[0].id;
    }

    return [toastDetails, toastPredictionScrollToTarget];
};

export const makePollToast = ({
    intl,
    predictions,
}: {
    intl: IntlShape;
    predictions: PredictionModel[];
}): [ToastDetails, string] => {
    let points = 0;
    for (const prediction of predictions) {
        points += prediction.pointValue;
    }

    const toastDetails = {
        backgroundColor: Color.T32_VIKING_ACCESSIBLE,
        backgroundImage: correctToast,
        color: ToastTextColor.BLACK,
        text: intl.formatMessage(
            {
                id: `pistachioGame.pollToast.text`,
            },
            { points },
        ),
        title: intl.formatMessage(
            {
                id: 'pistachioGame.pollToast.title',
            },
            {
                count: predictions.length,
            },
        ),
    };

    return [toastDetails, predictions[0].id];
};

export const checkHasUnansweredActivePrediction = (
    groupedPredictions: GroupedPredictions,
    answers: UserAnswerSet,
): boolean => {
    const activePredictionBatches = [
        ...groupedPredictions.live.automatic,
        ...groupedPredictions.live.manual,
    ];

    for (const batch of activePredictionBatches) {
        for (const prediction of batch.entities) {
            if (!answers[prediction.id]) {
                return true;
            }
        }
    }

    return false;
};
