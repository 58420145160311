import React from 'react';
import { FormattedMessage } from 'react-intl';
import dateFormat from 'dateformat';

import { UserModel } from 'src/util/TallyFirestore';
import { makeStyles } from '@material-ui/core';
import styled from '@emotion/styled';
import { TextStyles, TextStyleTypes } from 'src/styles/Constants';

type Props = {
    user: UserModel;
};

export const WelcomeText = styled.div`
    ${TextStyles[TextStyleTypes.H3]};
    text-align: center;
`;

export const UserNameText = styled.span`
    font-weight: 700;
`;

export const JoinedText = styled.div`
    ${TextStyles[TextStyleTypes.B2]};
    margin-top: 2px;
    text-align: center;
`;

const useStyles = makeStyles({
    root: {
        marginTop: 30,
    },
    userNameText: {
        fontWeight: 700,
    },
});

const NameJoined = ({ user }: Props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <WelcomeText>
                <FormattedMessage
                    id="settings.welcomeText"
                    values={{
                        username: (
                            <span className={classes.userNameText}>
                                {user.displayName}
                            </span>
                        ),
                    }}
                />
            </WelcomeText>
            <JoinedText>
                <FormattedMessage
                    id="settings.joinedText"
                    values={{
                        // TODO: fix bad date formatting
                        date: dateFormat(
                            user && user.playerSince
                                ? user.playerSince.toDate()
                                : new Date(),
                            'mm/dd/yy',
                        ),
                    }}
                />
            </JoinedText>
        </div>
    );
};

export default NameJoined;
