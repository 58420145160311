import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import {
    Color,
    TextStyles,
    TextStyleTypes,
    Orientation,
    Alignment,
} from 'src/styles/Constants';
import { EventSponsorshipUnit } from 'src/util/SponsorshipTypes';

interface Props {
    asset: string;
    assetMaxHeight?: number;
    labelColor?: Color;
    orientation?: Orientation;
    alignment?: Alignment;
    sponsorshipUnit?: EventSponsorshipUnit;
}

export default function PresentedBy(props: Props) {
    const {
        asset,
        assetMaxHeight = 30,
        alignment = Alignment.CENTER,
        labelColor = Color.G1_BLACK,
        orientation = Orientation.HORIZONTAL,
        sponsorshipUnit,
    } = props;

    let presentedByLabel =
        sponsorshipUnit && sponsorshipUnit.properties.presentedBy;

    return (
        <PresentedByContainer
            orientation={orientation}
            flexAlignment={alignment}
        >
            <PresentedByLabel color={labelColor}>
                {presentedByLabel != undefined ? (
                    presentedByLabel
                ) : (
                    <FormattedMessage id="presentedBy.label" />
                )}
            </PresentedByLabel>
            <StyledImage src={asset} maxHeight={assetMaxHeight} />
        </PresentedByContainer>
    );
}

const StyledImage = styled.img<{ maxHeight: number }>`
    max-height: ${(props) => props.maxHeight}px;
    margin: 4px 0;
`;

const PresentedByContainer = styled.div<{
    orientation: string;
    flexAlignment: string;
}>`
    display: flex;
    flex-direction: ${(props) => props.orientation};
    justify-content: ${(props) =>
        props.orientation === Orientation.HORIZONTAL
            ? props.flexAlignment
            : 'center'};
    align-items: ${(props) =>
        props.orientation === Orientation.VERTICAL
            ? props.flexAlignment
            : 'center'};
    width: 100%;
    padding: 6px 0;
`;

const PresentedByLabel = styled.div<{ color: Color }>`
    text-transform: uppercase;
    margin: 0 6px 0 0;
    ${TextStyles[TextStyleTypes.S3]};
    color: ${(props) => props.color};
`;
