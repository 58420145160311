const authServerErrorTypes = [
    'inappropriate',
    'displayNameIsTaken',
    'tooManyAttempts',
    'invalidPhoneNumber',
    'sendSmsRequestFailed',
    'verificationCodeExpired',
    'verificationCodesBlocked',
    'incorrectVerificationCode',
    'verifyRequestFailed',
    'userIsNotInitializedYet',
] as const;

export type ServerErrorType = (typeof authServerErrorTypes)[number];

export default class ServerError extends Error {
    // Gets the error message given from the server.
    // Returns the fallback if no message was provided from the server.
    public static getMessage(
        error: any,
        fallback?: string,
    ): string | undefined {
        // error instanceof ServerError doesn't work for some reason.
        // Use the below as a way to be reasonably confident that the error is a server error.
        const serverError =
            error.status !== undefined ? (error as ServerError) : undefined;

        // Note: the Error super class constructor will morph the undefined message into an empty string.
        return (
            (serverError &&
                serverError.message.length &&
                serverError.message) ||
            fallback
        );
    }

    public code?: string;
    public field?: string;
    public type?: ServerErrorType;
    public status: number; // http status code

    constructor({
        status,
        message,
        code,
        type,
        field,
    }: {
        status: number;
        message?: string;
        code?: string;
        type?: string;
        field?: string;
    }) {
        super(message);
        this.code = code;
        this.type = type as ServerErrorType;
        this.field = field;
        this.status = status;
    }
}
