import React from 'react';
import { getDaysBetween, DEFAULT_HIGHLIGHT_HOURS } from 'src/util/Dates';

interface Props {
    onCountdownExpired?: () => void;
    onCountdownUpdated: (countdown?: number) => void;
    countdownDate: Date;
    highlightHours?: number;
}

export default class TimeUntilLock extends React.Component<Props> {
    private countdownInterval: NodeJS.Timeout | undefined;

    public componentDidMount() {
        this.initializeCountdown();
    }

    public componentWillReceiveProps(props: Props) {
        if (
            props.countdownDate.getTime() !== this.props.countdownDate.getTime()
        ) {
            // Re-initialize the timer if the time changed.
            // Do this in a set timeout so that this.props is updated.
            setTimeout(() => {
                this.initializeCountdown();
            });
        }
    }

    public componentDidUpdate(props: Props) {
        if (
            props.countdownDate.getTime() !== this.props.countdownDate.getTime()
        ) {
            // Re-initialize the timer if the time changed.
            // Do this in a set timeout so that this.props is updated.
            setTimeout(() => {
                this.initializeCountdown();
            });
        }
    }

    public componentWillUnmount() {
        this.destroyCountdown();
    }

    private initializeCountdown = () => {
        const {
            onCountdownExpired,
            onCountdownUpdated,
            countdownDate: startDate,
        } = this.props;
        const now = new Date();

        this.destroyCountdown();

        // First validate that startDate is indeed in the future.
        if (startDate.getTime() < now.getTime()) {
            if (onCountdownExpired) {
                onCountdownExpired();
            }
            return;
        }

        const daysUntilStart = getDaysBetween(now, startDate);

        if (daysUntilStart === 0) {
            // Always start the timer, even though we might not show it
            this.countdownInterval = setInterval(() => {
                this.updateCountdown();
            }, 1000);

            this.updateCountdown();
        }

        const diff = Math.floor((startDate.getTime() - now.getTime()) / 1000);
        onCountdownUpdated(diff);
    };

    private destroyCountdown = () => {
        if (this.countdownInterval) {
            clearInterval(this.countdownInterval);
            this.countdownInterval = undefined;
        }
    };

    private updateCountdown = () => {
        const {
            onCountdownUpdated,
            countdownDate: startDate,
            highlightHours,
        } = this.props;

        const now = new Date();
        // Get the difference in seconds
        let diff = Math.floor((startDate.getTime() - now.getTime()) / 1000);
        const hours = Math.floor(diff / (60 * 60));
        // If we are not under the hour limit, we are not going to show the timer yet.
        if (
            hours >= (highlightHours ? highlightHours : DEFAULT_HIGHLIGHT_HOURS)
        ) {
            return;
        }

        onCountdownUpdated(diff);

        // If we've counted all the way down, no use in re-calculating.
        if (diff <= 0) {
            if (this.countdownInterval) {
                clearInterval(this.countdownInterval);
                this.countdownInterval = undefined;
            }

            // Notify parent if they're interested.
            const { onCountdownExpired } = this.props;
            if (onCountdownExpired) {
                onCountdownExpired();
            }
        }
    };

    public render() {
        return null;
    }
}
