import { OrderedMap } from 'immutable';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import BroadcastView from 'src/components/broadcast/BroadcastView';
import TVBroadcastView from 'src/components/broadcast/TVBroadcastView';
import TVFanzoneView from 'src/components/broadcast/TVFanzoneView';
import GeoFence, {
    shouldGeoFence,
} from 'src/components/pistachioGame/GeoFence';
import PrivateRoute from 'src/components/shared/PrivateRoute';
import { useSponsors } from 'src/contexts/SponsorsContext';
import { useLocale } from 'src/hooks/useLocale';
import { usePartner } from 'src/hooks/usePartner';
import { useAnalytics } from 'src/contexts/AnalyticsContext';
import routeUrls from 'src/routes';
import {
    TranslatedIteration,
    translateFirestoreEvent,
    useTranslatedHtmlTitle,
} from 'src/util/i18n';
import { getGameWideSponsor } from 'src/util/sponsor';
import { EventModel, TranslatableEventModel } from 'src/util/TallyFirestore';
import theme from 'src/util/Theme';
import useLiveEntityLeaderboards, {
    Leaderboards,
} from 'src/hooks/useLiveEntityLeaderboards';
import useLiveEvent from './hooks/useLiveEvent';
import PredictionEvent from './PredictionEvent';
import TriviaEvent from './TriviaEvent';
import IGameEvent from './IGameEvent';
import BingoEvent from './BingoEvent';
import { openCustomerSupport } from 'src/util/GameShared';
import GameFooter from 'src/components/shared/ui/GameFooter';
import { Color } from '../../styles/Constants';

type Props = {
    iteration: TranslatedIteration;
    iterationLeaderboards?: Leaderboards;
    event: TranslatableEventModel;
    events: OrderedMap<string, EventModel>;
};

const SingleEvent = ({
    iteration,
    iterationLeaderboards,
    event: initialEvent,
    events,
}: Props) => {
    const analytics = useAnalytics();
    const partner = usePartner();
    const sponsors = useSponsors();
    const { localeId } = useLocale();
    const event = useLiveEvent(initialEvent);
    const eventLeaderboards = useLiveEntityLeaderboards<string>(
        event.path,
        iteration,
    );

    useTranslatedHtmlTitle({
        eventSocialShare: event.socialShare,
        iterationSocialShare: iteration.socialShare,
        partnerSocialShare: partner.properties.socialShare,
    });

    const gameWideSponsor = getGameWideSponsor(event, sponsors);

    const translatedEvent = translateFirestoreEvent(localeId)(event);

    const onContactSupportClick = () => {
        openCustomerSupport(event ? { event } : { iteration });
    };

    const eventBackgroundColor = event.ui?.backgroundColor;

    document.body.style.backgroundColor =
        eventBackgroundColor || Color.G10_WHITISH;

    return (
        <>
            <Route path="/" exact>
                <Redirect to={routeUrls.play} />
            </Route>
            <Route path={routeUrls.tv}>
                <TVBroadcastView path={event.path} />
            </Route>
            <Route path={routeUrls.broadcast}>
                <BroadcastView
                    logoGraphicUrl={theme.navLogoUrl}
                    path={event.path}
                    localeId={localeId}
                    analytics={analytics}
                />
            </Route>
            <Route path={routeUrls.fanzone}>
                <TVFanzoneView path={event.path} />
            </Route>
            {shouldGeoFence() ? (
                <GeoFence event={event} />
            ) : translatedEvent.type === 'TRIVIA' ? (
                <TriviaEvent
                    partner={partner}
                    gameWideSponsor={gameWideSponsor}
                    iteration={iteration}
                    event={translatedEvent}
                    events={events}
                    iterationLeaderboards={iterationLeaderboards}
                    eventLeaderboards={eventLeaderboards}
                />
            ) : translatedEvent.type === 'IGAME' ? (
                <IGameEvent
                    partner={partner}
                    gameWideSponsor={gameWideSponsor}
                    iteration={iteration}
                    event={translatedEvent}
                    events={events}
                    iterationLeaderboards={iterationLeaderboards}
                    eventLeaderboards={eventLeaderboards}
                />
            ) : translatedEvent.type === 'BINGO' ? (
                <BingoEvent
                    partner={partner}
                    gameWideSponsor={gameWideSponsor}
                    iteration={iteration}
                    event={translatedEvent}
                    events={events}
                    iterationLeaderboards={iterationLeaderboards}
                    eventLeaderboards={eventLeaderboards}
                />
            ) : (
                <PredictionEvent
                    partner={partner}
                    sponsors={sponsors}
                    gameWideSponsor={gameWideSponsor}
                    iteration={iteration}
                    event={translatedEvent}
                    events={events}
                    iterationLeaderboards={iterationLeaderboards}
                    eventLeaderboards={eventLeaderboards}
                />
            )}
            <GameFooter onContactSupportClick={onContactSupportClick} />
        </>
    );
};

export default SingleEvent;
