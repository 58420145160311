import React from 'react';
import styled from '@emotion/styled';
import css from '@emotion/css';
import { Color } from 'src/styles/Constants';

interface Props {
    disabled?: boolean;
    error?: Error;
    label?: string;
    onChange?: (value: any) => void;
    onClear?: () => void;
    onContactSupportClick?: () => void;
    placeHolder?: string;
    color?: Color;
}

export default ({ error, label, color }: Props): JSX.Element => {
    return (
        <InputLabel color={color || Color.G1_BLACK}>
            {error ? error.message : label}
        </InputLabel>
    );
};

const LabelFontStyle = css`
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.75px;
    line-height: 18px;
    text-transform: upperCase;
`;

const InputLabel = styled.div<{ color: string }>`
    ${LabelFontStyle}
    color: ${(props) => props.color};
    text-align: left;
    width: 100%;
`;
