import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import Leaderboard from 'src/components/pistachioGame/leaderboard/Leaderboard';
import LeaderboardDeadstate from 'src/components/pistachioGame/leaderboard/LeaderboardDeadstate';
import HeaderText from 'src/components/shared/ui/HeaderText';
import {
    Leaderboards,
    TeamUserGroupLeaderboards,
    TeamGroupLeaderboards,
} from 'src/hooks/useLiveEntityLeaderboards';
import routeUrls from 'src/routes';
import { TranslatedIteration } from 'src/util/i18n';
import { PageContainer } from 'src/Iteration/MultiEvent/components';

type Props = {
    iteration: TranslatedIteration;
    leaderboards: Leaderboards;
    omitCorrectAnswers?: boolean;
};

const supportedInterGroupTypePaths = ['teams'] as const;

const InterGroupLeaderboardPage = ({
    leaderboards,
    omitCorrectAnswers,
}: Props) => {
    const { grouptype: groupType } = useParams<{ grouptype: string }>();

    const supportedPath = supportedInterGroupTypePaths.find(
        (supportedPath) => supportedPath === groupType,
    );

    if (
        !supportedPath ||
        (leaderboards.groupMode !== 'TEAM' &&
            leaderboards.groupMode !== 'TEAM,USER_GROUP')
    ) {
        return <Redirect to={routeUrls.leaderboardSummary} />;
    }

    return (
        <InterTeamGroupLeaderboardPage
            leaderboards={leaderboards}
            omitCorrectAnswers={omitCorrectAnswers}
        />
    );
};

export default InterGroupLeaderboardPage;

const InterTeamGroupLeaderboardPage = ({
    leaderboards,
    omitCorrectAnswers,
}: {
    leaderboards: TeamUserGroupLeaderboards | TeamGroupLeaderboards;
    omitCorrectAnswers?: boolean;
}) => {
    const { interGroupLeaderboardSummary } = leaderboards.teamGroup;

    return (
        <PageContainer>
            <HeaderText
                headerText={
                    leaderboards.groupModeProperties.interGroupLeaderboardName
                }
            />
            {interGroupLeaderboardSummary ? (
                <Leaderboard
                    leaderboard={interGroupLeaderboardSummary}
                    omitCorrectAnswers={omitCorrectAnswers}
                />
            ) : (
                <LeaderboardDeadstate />
            )}
        </PageContainer>
    );
};
