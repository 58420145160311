import React from 'react';
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useConsentModal } from 'src/hooks/useUserStorageConsent';
import { TRANSITION_ANIMATION_TIME } from 'src/styles/Constants';
import UserStorageConsentForm from './UserStorageConsentForm';

const useStyles = makeStyles({
    drawerPaper: {
        fontFamily: `Helvetica, Arial, 'Hiragino Sans GB', STXihei,
        'Microsoft YaHei', 'WenQuanYi Micro Hei', Hind, 'MS Gothic',
        'Apple SD Gothic Neo', 'NanumBarunGothic', sans-serif`,
        fontSize: 16,
        backgroundColor: '#252e39',
        color: '#fff',
        padding: '1.5em',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
});

const UserStorageConsentModal = () => {
    const { isOpen, setIsOpen } = useConsentModal();
    const classes = useStyles();

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <Drawer
            classes={{ paper: classes.drawerPaper }}
            variant="persistent"
            anchor="bottom"
            transitionDuration={TRANSITION_ANIMATION_TIME}
            open={isOpen}
            SlideProps={{ unmountOnExit: true }}
        >
            <UserStorageConsentForm onClose={handleClose} />
        </Drawer>
    );
};

export default UserStorageConsentModal;
