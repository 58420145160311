import React from 'react';
import { Color } from 'src/styles/Constants';

const SvgBonus = (props) => (
    <svg width="1em" height="1em" viewBox="0 0 18 18" {...props}>
        <path
            d="M9.333 12.938L5.746 10.33l.004-.025-3.25-3.33 4.534-.723L9 2l2.006 4.253 4.494.722-3.25 3.33.802 4.695-3.719-2.062zm-1.439.434L4.988 15l.543-3.345 2.363 1.717z"
            fill={props.color ? props.color : Color.P1_WHITE}
            fillRule="nonzero"
        />
    </svg>
);

export default SvgBonus;
