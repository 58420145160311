import React from 'react';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';
import { Color, TallyUrls } from 'src/styles/Constants';
import css from '@emotion/css';

type Props = {
    onContactSupportClick?: () => void;
};

export default ({ onContactSupportClick }: Props): JSX.Element => (
    <HelpLinks color={Color.S13_MAROON}>
        <FormattedMessage
            id={
                onContactSupportClick
                    ? 'inputField.helpQuestion'
                    : 'inputField.helpQuestionShort'
            }
            values={{
                helpPage: (
                    <HelpMe
                        color={Color.S13_MAROON}
                        href={TallyUrls.HELP}
                        target="tally.help"
                    >
                        <FormattedMessage id="inputField.helpLinkTitle" />
                    </HelpMe>
                ),
                contactPage: (
                    <HelpMe
                        onClick={onContactSupportClick}
                        color={Color.S13_MAROON}
                    >
                        <FormattedMessage id="inputField.contactLinkTitle" />
                    </HelpMe>
                ),
            }}
        />
    </HelpLinks>
);

const LabelFontStyle = css`
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.75px;
    line-height: 18px;
    text-transform: upperCase;
`;

const HelpLinks = styled.div<{ color: Color }>`
    ${LabelFontStyle}
    color: ${(props) => props.color};
    text-align: left;
    width: 100%;
    margin: 5px 0 0 0;
`;

const HelpMe = styled.a<{ color: Color }>`
    ${LabelFontStyle}
    color: ${(props) => props.color};
    cursor: pointer;
    text-decoration: underline;
`;
