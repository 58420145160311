import React, { Component } from 'react';
import styled from '@emotion/styled';
import { Color } from 'src/styles/Constants';

interface Props {
    children: React.ReactNode;
    outlineColor?: Color;
    backgroundColor?: Color;
    label?: string | JSX.Element;
    onActionClick?: () => void;
    actionGraphicUrl?: string;
    showShadow?: boolean;
}

export default class Tile extends Component<Props> {
    public render() {
        const {
            children,
            label,
            outlineColor,
            backgroundColor,
            actionGraphicUrl,
            onActionClick,
            showShadow,
        } = this.props;

        return (
            <Container>
                <HeaderContainer>
                    {label && <Label>{label}</Label>}
                    {actionGraphicUrl && onActionClick && (
                        <ActionContainer
                            src={actionGraphicUrl}
                            onClick={onActionClick}
                        />
                    )}
                </HeaderContainer>
                <TileContainer
                    outlineColor={outlineColor}
                    backgroundColor={backgroundColor}
                    showShadow={showShadow}
                >
                    {children}
                </TileContainer>
            </Container>
        );
    }
}

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const ActionContainer = styled.img`
    margin: 0 10px;
    cursor: pointer;
`;

const Container = styled.div`
    justify-content: center;
    font-family: Graphik Web;
    width: 100%;
`;

const Label = styled.h3`
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 500;
    line-height: 22px;
    margin: 16px 0;
    text-transform: uppercase;
`;

const TileContainer = styled.div<{
    outlineColor?: Color;
    backgroundColor?: Color;
    showShadow?: boolean;
}>`
    display: flex;
    align-items: center;
    background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : Color.P1_WHITE};
    border: 1px solid
        ${(props) => (props.outlineColor ? props.outlineColor : Color.T28_FOG)};
    box-shadow: ${(props) =>
        props.showShadow ? '0 0 7px 0 rgba(0, 0, 0, 0.08)' : 'none'};
    padding: 15px 20px;
    color: ${Color.T31_BLACK};
`;
