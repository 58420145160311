import React, { useState } from 'react';
import styled from '@emotion/styled';
import { keyframes } from 'emotion';
import clearLightIcon from 'src/images/icons/Clear-Default@3x.svg';
import clearErrorIcon from 'src/images/icons/Clear-Error@3x.svg';
import { Color } from 'src/styles/Constants';
import ServerError from 'src/services/ServerError';
import InputLabel from '../../ui/InputLabel';
import HelpLinks from '../../ui/HelpLinks';
import { regexpValidation, ValidationError } from 'src/util/validation';
import clearDarkIcon from 'src/images/icons/Clear-Dark@3x.svg';

interface Props {
    field: string; // field identification
    error?: ServerError | Error;
    disabled?: boolean;
    label?: string;
    onChange: (value: any) => void;
    onClear?: () => void;
    onContactSupportClick?: () => void; //
    placeHolder?: string; //input placeholder
    validation?: RegExp; //validation for the field
    validationErrorText?: string; //error message to be shown if validation not passed
    onError?: (error: ValidationError) => void;
    onClearError?: (field: string) => void; // clear upper accumulator for errors by its field identification
    validateOnBlurOnly?: boolean;
    darkMode?: boolean;
    initialValue?: string;
}

export default ({
    field,
    disabled,
    onChange,
    onClear,
    error,
    placeHolder,
    label,
    onContactSupportClick,
    validation,
    validationErrorText,
    onError,
    onClearError,
    validateOnBlurOnly = true,
    darkMode = false,
    initialValue,
}: Props): any => {
    const [value, setValue] = useState<string>(initialValue || '');

    const backgroundColor = darkMode ? Color.T31_BLACK : Color.P1_WHITE;
    const color = darkMode ? Color.P1_WHITE : Color.T31_BLACK;
    const clearIcon = darkMode ? clearDarkIcon : clearLightIcon;

    let borderColor = darkMode ? Color.P1_WHITE : Color.T34_SQUIRTLE;
    if (error) {
        borderColor = Color.S13_MAROON;
    }

    const validate = (value: string | undefined): void => {
        if (regexpValidation({ value, validationRegexp: validation })) {
            onClearError && onClearError(field);
        } else {
            const error = new ValidationError({
                message: validationErrorText || 'Invalid input',
                field: field,
            });
            onError && onError(error);
        }
    };

    const change = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        error
            ? validate(event.target.value)
            : !validateOnBlurOnly && validate(event.target.value);
        onChange && onChange(event.target.value);
    };

    const clearClick = () => {
        setValue('');
        validate('');
        onClear && onClear();
    };

    let errorColor;
    if (darkMode) {
        errorColor = Color.T29_STONE;
    } else if (error) {
        errorColor = Color.S13_MAROON;
    } else {
        errorColor = Color.T31_BLACK;
    }

    return (
        <>
            <InputLabel label={label} error={error} color={errorColor} />
            {error && (
                <>
                    <HelpLinks onContactSupportClick={onContactSupportClick} />
                </>
            )}

            <InputContainer
                backgroundColor={backgroundColor}
                borderColor={borderColor}
                showError={!!error}
            >
                <Input
                    color={color}
                    backgroundColor={backgroundColor}
                    disabled={disabled}
                    onChange={change}
                    value={value}
                    placeholder={placeHolder}
                    onBlur={() => validateOnBlurOnly && validate(value)}
                />
                <ClearButton
                    onClick={clearClick}
                    src={error ? clearErrorIcon : clearIcon}
                />
            </InputContainer>
        </>
    );
};

const errorBounce = keyframes`
    from, 80%, to {
        transform: translate3d(0,0,0);
    }

    20% {
        transform: translate3d(-7px, 0, 0);
    }

    40% {
        transform: translate3d(7px, 0, 0);
    }

    60% {
        transform: translate3d(-3px,0,0);
    }

    70% {
        transform: translate3d(3px,0,0);
    }
`;

const ClearButton = styled.img`
    cursor: pointer;
    margin: 0 10px;
    transition: 0.3s all;
`;

const InputContainer = styled.div<{
    backgroundColor: Color;
    borderColor: Color;
    showError: boolean;
}>`
    margin: 10px 0 0 0;
    font-family: Graphik Web;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.backgroundColor};
    width: 100%;
    border: 1px solid ${(props) => props.borderColor};
    animation: ${(props) =>
        props.showError ? errorBounce : undefined} 0.3s ease;
    transition 0.3s all;
`;

const Input = styled.input<{
    color: Color;
    backgroundColor: Color;
}>`
    display: flex;
    flex: 1;
    color: ${(props) => props.color};
    background-color: ${(props) => props.backgroundColor};
    font-size: 14px;
    border: 0px;
    height: 45px;
    outline: none;
    padding: 0 3px 0 15px;
`;
