import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import styled from '@emotion/styled';
import { OnAnswerCallback } from 'src/components/shared/predictions/Prediction';
import {
    PredictionBatch,
    PredictionBatchType,
} from 'src/util/PredictionHelpers';
import {
    OddClaim,
    ProgressivePollModel,
    ProgressivePollAnswer,
    ProgressivePollProperties,
    SponsorModel,
    UserAnswerSet,
    PredictionModel,
} from 'src/util/TallyFirestore';

import CountdownPageBreak, { TokenPosition } from '../CountdownPageBreak';
import LivePredictionCard from './LivePredictionCard';
import ProgressivePollCard from './LiveProgressivePollCard';
import { OnAnswerCallback as ProgressivePollCallback } from '../../../shared/progressivePoll/ProgressivePoll';
import { List, ListItem } from '../TransitionGroupList';
import { LOCK_TIME_TOKEN } from 'src/styles/Constants';
import { getProgressivePollToShow, ProjectedAnswers } from '../utils';
import Pips from './Pips/Pips';

type Props = {
    predictionBatches: Array<PredictionBatch<PredictionBatchType>>;
    sponsors: SponsorModel[];
    answers: UserAnswerSet;
    oddClaims?: OddClaim;
    projectedAnswers: ProjectedAnswers;
    onAnswer: OnAnswerCallback;
    onEntered: () => void;
    onExited: () => void;
    isAdSticky: boolean;
    progressivePolls: ProgressivePollModel[];
    progressivePollsAnswers: ProgressivePollAnswer[];
    progressivePollConfig?: ProgressivePollProperties;
    onProgressivePollAnswer: ProgressivePollCallback;
};

const useStyles = makeStyles({
    list: {
        padding: '35px 0 20px 0',
        maxWidth: 500,
        width: '100%',
    },
});

const LivePredictionList: React.RefForwardingComponent<
    HTMLDivElement,
    Props
> = (
    {
        predictionBatches,
        sponsors,
        answers,
        projectedAnswers,
        oddClaims,
        onAnswer,
        onEntered,
        onExited,
        isAdSticky,
        progressivePolls,
        progressivePollsAnswers,
        progressivePollConfig,
        onProgressivePollAnswer,
    },
    ref,
) => {
    const { list } = useStyles();
    const [visibleActiveCards, setVisibleActiveCards] = useState(
        new Set<string>(),
    );

    const onCardIconVisibilityChanged = (id: string, cardVisible: boolean) => {
        setVisibleActiveCards((set) => {
            const newSet = new Set(set);
            if (cardVisible) {
                newSet.add(id);
            } else {
                newSet.delete(id);
            }
            return newSet;
        });
    };

    const getProgressivePollItem = ({
        progressivePollConfig,
        prediction,
        progressivePolls,
    }: {
        progressivePollConfig?: ProgressivePollProperties;
        prediction: PredictionModel;
        progressivePolls?: ProgressivePollModel[];
    }) => {
        if (!progressivePollConfig) {
            return null;
        }
        const progressivePollToShow: ProgressivePollModel | null =
            getProgressivePollToShow({
                progressivePollConfig,
                prediction,
                progressivePolls,
            });
        return progressivePollToShow ? (
            <ListItem
                key={progressivePollToShow.id}
                onEntered={onEntered}
                onExited={onExited}
            >
                <ProgressivePollCard
                    pointValue={progressivePollConfig!.pointsPerPoll}
                    poll={progressivePollToShow}
                    onAnswer={onProgressivePollAnswer}
                    answer={
                        progressivePollsAnswers &&
                        progressivePollsAnswers.find(
                            (ppa) => ppa.pollId === progressivePollToShow.id,
                        )
                    }
                    projectedSelectedOptionId={
                        projectedAnswers[progressivePollToShow.id]
                    }
                    isResolved={false}
                />
            </ListItem>
        ) : null;
    };

    const mapBatchesToList = (
        predictionBatches: Array<PredictionBatch<PredictionBatchType>>,
    ) => {
        let listItems: JSX.Element[] = [];

        predictionBatches.forEach(({ group, entities: predictions }, i) => {
            if (i !== 0) {
                listItems.push(
                    <ListItem
                        key={group + predictions[0].id}
                        onEntered={onEntered}
                        onExited={onExited}
                    >
                        <CountdownPageBreak
                            countdownData={group}
                            groupTitleTemplate={{
                                message: 'predictPage.predictionList.lockLabel',
                                token: {
                                    type: LOCK_TIME_TOKEN,
                                    position: TokenPosition.TRAILING,
                                },
                            }}
                            showTitleLine={true}
                            style={{
                                marginTop: -20,
                                marginBottom: 20,
                            }}
                        />
                    </ListItem>,
                );
            }
            predictions.forEach((prediction) => {
                const predictionSponsor = prediction.sponsorship
                    ? sponsors.find(
                          (s) => s.id === prediction.sponsorship!.sponsorId,
                      )
                    : undefined;
                const projectedSelectedOptionId =
                    projectedAnswers[prediction.id];

                listItems.push(
                    <ListItem
                        key={prediction.id}
                        onEntered={onEntered}
                        onExited={onExited}
                    >
                        <LivePredictionCard
                            onCardIconVisibilityChanged={
                                onCardIconVisibilityChanged
                            }
                            prediction={prediction}
                            sponsor={predictionSponsor}
                            answer={answers[prediction.id]}
                            oddClaim={oddClaims && oddClaims[prediction.id]}
                            projectedSelectedOptionId={
                                projectedSelectedOptionId
                            }
                            onAnswer={onAnswer}
                        />
                    </ListItem>,
                );

                const progressivePollToShow = getProgressivePollItem({
                    progressivePollConfig,
                    prediction,
                    progressivePolls,
                });
                progressivePollToShow && listItems.push(progressivePollToShow);
            });
        });
        return listItems;
    };

    return (
        <Container ref={ref}>
            <ListContainer>
                <DecoyVariablePadding isAdSticky={isAdSticky}>
                    <Pips
                        predictionBatches={predictionBatches}
                        visibleActiveCards={visibleActiveCards}
                        answers={answers}
                        projectedAnswers={projectedAnswers}
                    />
                </DecoyVariablePadding>
                <List className={list}>
                    {mapBatchesToList(predictionBatches)}
                </List>
                <DecoyVariablePadding />
            </ListContainer>
        </Container>
    );
};

export default React.forwardRef(LivePredictionList);

const Container = styled.div`
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const ListContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    width: 100%;
    height: 100%;
`;

const DecoyVariablePadding = styled.div<{
    isAdSticky?: boolean;
}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: ${(props) => (props.isAdSticky ? '58%' : '30%')};
    min-width: 20px;
    max-width: 100px;
    width: 10%;
`;
