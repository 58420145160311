import React, { useEffect, useState } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import styled from '@emotion/styled';
import { keyframes } from 'emotion';

import { Color } from 'src/styles/Constants';
import ServerError from 'src/services/ServerError';
import { getSafariVersion } from 'src/util/BrowserInfo';

import HelpLinks from '../../ui/HelpLinks';
import InputLabel from '../../ui/InputLabel';

import 'react-phone-input-2/lib/bootstrap.css';
import { useIntl } from 'react-intl';
import { BackgroundPanel } from '../../ui/BackgroundPanel';

type Props = {
    initialCountryCode?: string; // use to select as default country in the country dropdown list
    preferredCountries?: string[]; // use to move provided countries to the top of dropdown list
    error?: ServerError | Error;
    disabled?: boolean;
    value: string;
    onChange: (value: string) => void;
    onContactSupportClick: () => void; //
    placeHolder?: string; //input placeholder
    validation?: RegExp; //validation for the field
    onlyCountries?: string[]; // use to restrict list of country codes in dropdown list
    excludeCountries?: string[]; // use to exclude from the country dropdown list
    screenHasBg?: boolean | false; // apply UI changes to accomodate background image/color
};

const PhoneInput = ({
    onChange,
    value,
    error,
    placeHolder,
    onContactSupportClick,
    initialCountryCode = 'us',
    preferredCountries,
    excludeCountries,
    onlyCountries,
    screenHasBg,
}: Props): any => {
    const intl = useIntl();
    // TP-252: A temporary disable of the input field to prevent refocus on blur
    const [tempDisable, setTempDisable] = useState<boolean>(false);

    const backgroundColor = Color.P1_WHITE;
    const color = Color.T31_BLACK;

    let borderColor = error ? Color.S13_MAROON : Color.T34_SQUIRTLE;

    const isSafariBrowser = !!getSafariVersion();

    useEffect(() => {
        if (tempDisable) {
            const timeoutId = setTimeout(() => {
                setTempDisable(false);
            }, 100);
            return () => clearTimeout(timeoutId);
        }
    }, [tempDisable]);

    return (
        <>
            <InputLabel
                label={intl.formatMessage({
                    id: 'onboarding.requestPhone.field.title',
                })}
                error={error}
            />
            {error && (
                <>
                    <HelpLinks onContactSupportClick={onContactSupportClick} />
                </>
            )}

            <InputContainer
                backgroundColor={backgroundColor}
                borderColor={borderColor}
                showError={!!error}
            >
                <Input
                    color={color}
                    country={initialCountryCode}
                    preferredCountries={preferredCountries}
                    excludeCountries={excludeCountries}
                    onlyCountries={onlyCountries}
                    disableCountryGuess={isSafariBrowser}
                    countryCodeEditable={false}
                    specialLabel={isSafariBrowser ? undefined : 'Phone'}
                    value={value}
                    onChange={onChange}
                    onBlur={() => {
                        isSafariBrowser && setTempDisable(true);
                    }}
                    placeholder={placeHolder}
                    inputStyle={{
                        border: 0,
                        fontFamily: 'Graphik Web',
                        width: '100%',
                    }}
                    containerStyle={{
                        border: 0,
                        fontFamily: 'Graphik Web',
                        width: '100%',
                    }}
                    inputProps={{
                        autoComplete: isSafariBrowser ? 'off' : undefined,
                        name: isSafariBrowser ? 'Phone' : undefined,
                        disabled: tempDisable,
                    }}
                />
            </InputContainer>
        </>
    );
};

export default PhoneInput;

const errorBounce = keyframes`
    from, 80%, to {
        transform: translate3d(0,0,0);
    }

    20% {
        transform: translate3d(-7px, 0, 0);
    }

    40% {
        transform: translate3d(7px, 0, 0);
    }

    60% {
        transform: translate3d(-3px,0,0);
    }

    70% {
        transform: translate3d(3px,0,0);
    }
`;

const BGPanel = styled.div<{ hasBg?: boolean }>`
    background-color: ${(props) => (props.hasBg ? Color.P1_WHITE : '')};
    padding: ${(props) => (props.hasBg ? '5px' : '')};
    border-radius: ${(props) => (props.hasBg ? '5px' : '')};
`;
const InputContainer = styled.div<{
    backgroundColor: Color;
    borderColor: Color;
    showError: boolean;
}>`
    margin: 10px 0 0 0;
    font-family: Graphik Web;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.backgroundColor};
    width: 100%;
    border: 1px solid ${(props) => props.borderColor};
    animation: ${(props) =>
        props.showError ? errorBounce : undefined} 0.3s ease;
    transition 0.3s all;
`;

const Input = styled(ReactPhoneInput)<{ color: Color }>`
    display: flex;
    flex: 1;
    color: ${(props) => props.color};
    background-color: transparent;
    font-size: 14px;
    border: 0px
    &:focus {
        outline: none;
    }
    height: 45px;
    outline: none;
    padding: 0 3px 0 0;
`;
