import styled from '@emotion/styled';
import { keyframes } from 'emotion';
import React, { Component } from 'react';
import { Color } from 'src/styles/Constants';

const bounce = keyframes`
  from, 20%, 53%, 80%, to {
    transform: translate3d(0,0,0);
  }

  40%, 43% {
    transform: translate3d(0, -20px, 0);
  }

  70% {
    transform: translate3d(0, -10px, 0) rotateZ(180deg);
  }

  90% {
    transform: translate3d(0,-2px,0) rotateZ(180deg);
  }
`;

interface Props {
    animate?: boolean;
    backgroundColor: string;
    color: Color;
    onNextIteration?: () => void;
    scale?: number;
}

const ANIMATED_ID = 'TALLY_LOADING_ANIMATION';

export default class TallyLogo extends Component<Props> {
    public componentDidMount = () => {
        const animation = document.getElementById(ANIMATED_ID);
        if (animation) {
            animation.addEventListener(
                'animationiteration',
                this.onNextIteration,
            );
        }
    };

    public onNextIteration = () => {
        const { onNextIteration } = this.props;
        if (onNextIteration) {
            onNextIteration();
        }
    };

    public render = () => {
        const { animate, backgroundColor, color, scale } = this.props;

        return (
            <Container>
                <Background backgroundColor={backgroundColor}>
                    <LogoContainer scale={scale}>
                        <LogoTopLeft color={color} />
                        <LogoTopRight color={color} />
                        <LogoStem color={color} />
                        <LogoBottomDot color={color} />
                        <LogoTopDot
                            bounce={animate ? bounce : undefined}
                            color={color}
                            id={ANIMATED_ID}
                        />
                    </LogoContainer>
                </Background>
            </Container>
        );
    };
}
const Container = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
`;

const Background = styled.div<{ backgroundColor: string }>`
    background-color: ${(props) => props.backgroundColor};
    border-radius: 5px;
`;

const LogoContainer = styled.div<{ scale?: number }>`
    transform: scale(${(props) => props.scale || '1'});
    display: inline-block;
    height: 74px;
    position: relative;
    width: 74px;
`;

const LogoTopLeft = styled.div<{ color: string }>`
    background-color: ${(props) => props.color};
    display: inline-block;
    height: 14px;
    left: 0;
    position: absolute;
    width: 24px;
`;

const LogoTopRight = styled.div<{ color: string }>`
    background-color: ${(props) => props.color};
    display: inline-block;
    height: 14px;
    left: 29px;
    position: absolute;
    width: 45px;
`;

const LogoStem = styled.div<{ color: string }>`
    background-color: ${(props) => props.color};
    display: inline-block;
    height: 60px;
    left: 29px;
    position: absolute;
    top: 14px;
    width: 16px;
`;

const LogoTopDot = styled.div<{
    bounce?: string;
    color: string;
}>`
    animation: ${(props) =>
        props.bounce ? `${props.bounce} 1s ease infinite` : 'initial'};
    background-color: ${(props) => props.color};
    bottom: 14px;
    display: inline-block;
    height: 7px;
    left: 0;
    position: absolute;
    width: 7px;
`;

const LogoBottomDot = styled.div<{ color: string }>`
    background-color: ${(props) => props.color};
    bottom: 0;
    display: inline-block;
    height: 7px;
    left: 0;
    position: absolute;
    width: 7px;
`;
