import { useReducer } from 'react';
import { Group, TeamGroup, UserGroup, VenueGroup } from 'src/types';

type FetchState = {
    error: null | Error;
};

type State = {
    isLoading: boolean;
    groups: {
        userGroup: UserGroup | undefined;
        teamGroup: TeamGroup | undefined;
        venueGroup: VenueGroup | undefined;
    };
    createGroup: FetchState;
    deleteGroup: FetchState;
    joinGroup: FetchState;
    getUserGroups: FetchState;
};

export enum ActionTypes {
    CREATE_GROUP_REQUEST = 'CREATE_GROUP_REQUEST',
    CREATE_GROUP_FAILURE = 'CREATE_GROUP_FAILURE',
    CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS',

    GET_GROUPS_REQUEST = 'GET_GROUPS_REQUEST',
    GET_GROUPS_FAILURE = 'GET_GROUPS_FAILURE',
    GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS',

    DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST',
    DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE',
    DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS',

    JOIN_GROUP_REQUEST = 'JOIN_GROUP_REQUEST',
    JOIN_GROUP_FAILURE = 'JOIN_GROUP_FAILURE',
    JOIN_GROUP_SUCCESS = 'JOIN_GROUP_SUCCESS',
}

type GroupsState = {
    userGroup: UserGroup | undefined;
    teamGroup: TeamGroup | undefined;
    venueGroup: VenueGroup | undefined;
};

type Action =
    | { type: ActionTypes.CREATE_GROUP_REQUEST }
    | { type: ActionTypes.CREATE_GROUP_FAILURE; payload: { error: Error } }
    | { type: ActionTypes.CREATE_GROUP_SUCCESS; payload: { group: UserGroup } }
    | { type: ActionTypes.DELETE_GROUP_REQUEST }
    | { type: ActionTypes.DELETE_GROUP_FAILURE; payload: { error: Error } }
    | { type: ActionTypes.DELETE_GROUP_SUCCESS }
    | { type: ActionTypes.JOIN_GROUP_REQUEST }
    | { type: ActionTypes.JOIN_GROUP_FAILURE; payload: { error: Error } }
    | {
          type: ActionTypes.JOIN_GROUP_SUCCESS;
          payload: {
              group: UserGroup | TeamGroup | VenueGroup;
          };
      }
    | { type: ActionTypes.GET_GROUPS_REQUEST }
    | { type: ActionTypes.GET_GROUPS_FAILURE; payload: { error: Error } }
    | {
          type: ActionTypes.GET_GROUPS_SUCCESS;
          payload: {
              groups: {
                  userGroup: UserGroup | undefined;
                  teamGroup: TeamGroup | undefined;
                  venueGroup: VenueGroup | undefined;
              };
          };
      };

const initialState = {
    isLoading: true,
    groups: {
        userGroup: undefined,
        teamGroup: undefined,
        venueGroup: undefined,
    },
    createGroup: { error: null },
    deleteGroup: { error: null },
    joinGroup: { error: null },
    getUserGroups: { error: null },
};

const getGroupJoinStateUpdate = (groups: GroupsState, groupToJoin: Group) => {
    switch (groupToJoin.groupType) {
        case 'USER_GROUP':
            return { ...groups, userGroup: groupToJoin };
        case 'VENUE':
            return { ...groups, venueGroup: groupToJoin };
        case 'TEAM':
            return { ...groups, teamGroup: groupToJoin };
    }
};

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case ActionTypes.CREATE_GROUP_REQUEST:
            return {
                ...state,
                isLoading: true,
                createGroup: { error: null },
            };
        case ActionTypes.CREATE_GROUP_FAILURE:
            return {
                ...state,
                isLoading: false,
                createGroup: { error: action.payload.error },
            };
        case ActionTypes.CREATE_GROUP_SUCCESS:
            return {
                ...state,
                isLoading: false,
                groups: { ...state.groups, userGroup: action.payload.group },
                createGroup: { error: null },
            };
        case ActionTypes.DELETE_GROUP_REQUEST:
            return {
                ...state,
                isLoading: true,
                deleteGroup: {
                    error: null,
                },
            };
        case ActionTypes.DELETE_GROUP_FAILURE:
            return {
                ...state,
                isLoading: false,
                deleteGroup: {
                    error: action.payload.error,
                },
            };
        case ActionTypes.DELETE_GROUP_SUCCESS:
            return {
                ...state,
                isLoading: false,
                groups: { ...state.groups, userGroup: undefined },
                deleteGroup: {
                    error: null,
                },
            };
        case ActionTypes.JOIN_GROUP_REQUEST:
            return {
                ...state,
                isLoading: true,
                joinGroup: {
                    error: null,
                },
            };
        case ActionTypes.JOIN_GROUP_FAILURE:
            return {
                ...state,
                isLoading: false,
                joinGroup: {
                    error: action.payload.error,
                },
            };
        case ActionTypes.JOIN_GROUP_SUCCESS:
            return {
                ...state,
                isLoading: false,
                groups: getGroupJoinStateUpdate(
                    state.groups,
                    action.payload.group,
                ),
                joinGroup: {
                    error: null,
                },
            };
        case ActionTypes.GET_GROUPS_REQUEST:
            return {
                ...state,
                isLoading: true,
                getUserGroups: {
                    isLoading: true,
                    error: null,
                },
            };

        case ActionTypes.GET_GROUPS_FAILURE:
            return {
                ...state,
                isLoading: false,
                getUserGroups: {
                    isLoading: false,
                    error: action.payload.error,
                },
            };
        case ActionTypes.GET_GROUPS_SUCCESS:
            return {
                ...state,
                groups: action.payload.groups,
                isLoading: false,
                getUserGroups: {
                    isLoading: false,
                    error: null,
                },
            };
        default:
            return state;
    }
};

export const useGroupReducer = () =>
    useReducer<State, Action>(reducer, initialState);
