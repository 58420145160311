import styled from '@emotion/styled';
import React, { Component, createRef } from 'react';
import lottie from 'lottie-web';
import Theme from 'src/util/Theme';

export default class SeesawAnimation extends Component {
    private animationContainer = createRef<HTMLDivElement>();

    public componentDidMount() {
        if (this.animationContainer.current) {
            lottie.loadAnimation({
                animationData: Theme.seesawLottieAnimationData,
                autoplay: true,
                container: this.animationContainer.current,
                loop: true,
                renderer: 'svg',
            });
        }
    }

    public render() {
        return <AnimationContainer ref={this.animationContainer} />;
    }
}

const AnimationContainer = styled.div`
    width: 250px;
    margin: -50px -75px;
`;
