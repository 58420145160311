import { padStart } from 'lodash';
import { TranslationMessageId } from './LocaleHelpers';
import dateFormat from 'dateformat';

// This file contains a series of helpers to get UI-friendly strings for dates and times.
// This is to overcome Android's lack of support for toLocaleDateString and toLocaleTimeString.

export const DEFAULT_HIGHLIGHT_HOURS = 2;

// TODO: It's better to use a library to get weekdays. That way we won't need to translate them.
const SHORT_WEEKDAYS = [
    'dates.shortWeekDay.sunday',
    'dates.shortWeekDay.monday',
    'dates.shortWeekDay.tuesday',
    'dates.shortWeekDay.wednesday',
    'dates.shortWeekDay.thursday',
    'dates.shortWeekDay.friday',
    'dates.shortWeekDay.saturday',
] as const;

const LONG_WEEKDAYS = [
    'dates.longWeekDay.sunday',
    'dates.longWeekDay.monday',
    'dates.longWeekDay.tuesday',
    'dates.longWeekDay.wednesday',
    'dates.longWeekDay.thursday',
    'dates.longWeekDay.friday',
    'dates.longWeekDay.saturday',
] as const;

export function getShortWeekday(date: Date): TranslationMessageId {
    return SHORT_WEEKDAYS[date.getDay()];
}

export function getLongWeekday(date: Date): TranslationMessageId {
    return LONG_WEEKDAYS[date.getDay()];
}

export function getTimeString(date: Date) {
    const rawHours = date.getHours();
    const hours = rawHours === 0 || rawHours === 12 ? 12 : rawHours % 12;
    const pm = rawHours / 12 >= 1;
    const minutes = padStart(`${date.getMinutes()}`, 2, '0');
    return `${hours}:${minutes}${pm ? 'PM' : 'AM'}`;
}

// TODO: This funciton is bad, because date format depends on locale
export function getDateString(date: Date) {
    const month = date.getMonth() + 1; // getMonth is zero-based
    const day = date.getDate();
    const year = padStart(`${date.getFullYear() % 100}`, 2, '0'); // 2-digit
    return `${month}/${day}/${year}`;
}

function clearTime(date: Date) {
    // This does not handle different time zones properly.
    // We should be ensuring the date is in UTC to avoid comparing date
    // objects across different time zones.
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
}

export const MILLIS_IN_HOUR = 3600000 as const;
export const SECONDS_IN_HOUR = 3600 as const;

export function getDaysBetween(earlierDate: Date, laterDate: Date) {
    // Get two dates, with time 0'd out, so we can compare number of calendar days, rather than
    // time interval in days.
    const earlierDateNoTime = new Date(earlierDate.getTime());
    clearTime(earlierDateNoTime);
    const laterDateNoTime = new Date(laterDate.getTime());
    clearTime(laterDateNoTime);

    // This math does not account for different time zones correctly.
    // startDateNotTime and nowNoTime could have different time zones if one is in daylight savings
    // and the other is not in daylight savings.
    // For now, do a hacky fix to make this handle the PDT / PST case by rounding daysUntilStart
    // to the nearest whole number.
    const daysBetween = Math.round(
        (laterDateNoTime.getTime() - earlierDateNoTime.getTime()) /
            MILLIS_IN_HOUR /
            24,
    );
    return daysBetween;
}

export const getHoursBetween = (earlierDate: Date, laterDate: Date) =>
    (laterDate.getTime() - earlierDate.getTime()) / MILLIS_IN_HOUR;

const paddedTimeString = (time: number): string => {
    return time < 10 ? `0${time}` : `${time}`;
};

export const getCountdownString = (diff: number) => {
    let countdownString = '0:00:00';

    if (diff > 0) {
        const hours = Math.floor(diff / (60 * 60));
        diff = diff % (60 * 60);
        const minutes = Math.floor(diff / 60);
        diff = diff % 60;
        const seconds = Math.floor(diff);

        countdownString = `${hours}:${paddedTimeString(
            minutes,
        )}:${paddedTimeString(seconds)}`;
    }
    return countdownString;
};

export const getDayDateString = (date: Date) => dateFormat(date, 'mm/dd');
