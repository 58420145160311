import { useEffect } from 'react';

import firebase, {
    DocumentSnapshot,
    QuerySnapshot,
} from 'src/services/Firebase';

import useBingoStateReducer, {
    BingoGameState,
    GetBingoSquaresError,
    GetBingoSquaresLoading,
    PlayingBingo,
} from './useBingoStateReducer';
import {
    BingoProperties,
    BingoSquareModel,
    Collection,
} from 'src/util/TallyFirestore';
import FirebaseAuth from 'src/services/FirebaseAuth';
import { usePartner } from 'src/hooks/usePartner';
import { useUserState } from 'src/contexts/UserStateContext';
import { getBingoSquareFromDocumentSnapshot } from 'src/util/FirestoreDao';
import ServerApi from 'src/services/ServerApi';
import Logger from 'src/util/Logger';

const generateScorecardAction = { type: 'generateScorecardLoading' } as const;

function chunk<T>(array: T[], chunkSize: number): T[][] {
    let result: T[][] = [];

    for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
    }

    return result;
}

type Unsubscribe = () => void;

const FIRESTORE_MAX_IN = 10;
// We need this because of the firestore 'in' query filter limit
function subscribeToMultipleDocs<T>(
    firestore: firebase.firestore.Firestore,
    collectionPath: string,
    documentIds: string[],
    getDataFromDocumentSnapshot: (document: DocumentSnapshot) => T,
    onNext: (data: T[]) => void,
    onError?: (error: Error) => void,
): Unsubscribe {
    const resultInChunks: Array<undefined | T[]> = Array(
        Math.ceil(documentIds.length / FIRESTORE_MAX_IN),
    ).fill(undefined);
    const unsubscribes: Unsubscribe[] = [];

    const documentIdsInChunks = chunk(documentIds, FIRESTORE_MAX_IN);

    documentIdsInChunks.forEach((chunk, chunkIndex) => {
        const unsubscribe = firestore
            .collection(collectionPath)
            .where(firebase.firestore.FieldPath.documentId(), 'in', chunk)
            .onSnapshot((snapshot: QuerySnapshot) => {
                resultInChunks[chunkIndex] = snapshot.docs.map(
                    getDataFromDocumentSnapshot,
                );

                if (
                    resultInChunks.every(
                        (resultChunk) => resultChunk !== undefined,
                    )
                ) {
                    onNext((resultInChunks as Array<T[]>).flat());
                }
            }, onError);
        unsubscribes.push(unsubscribe);
    });
    return () => {
        unsubscribes.forEach((unsubscribe) => unsubscribe());
    };
}

const useBingoGame = (
    eventId: string,
    iterationPath: string,
): {
    state: BingoGameState;
    generateScorecard: () => void;
} => {
    const { partnerId } = usePartner();

    const [state, dispatch] = useBingoStateReducer();
    const { uid } = useUserState();
    const { id: stateId } = state;

    useEffect(() => {
        if (stateId === 'getScorecardLoading') {
            firebase
                .firestore()
                .collection(Collection.partners)
                .doc(partnerId)
                .collection(Collection.users)
                .doc(uid)
                .collection(Collection.bingoScorecard)
                .doc(eventId)
                .get()
                .then(
                    (doc) => {
                        const data = doc.data();
                        if (!data) {
                            dispatch({
                                type: 'getScorecardSuccess',
                                payload: null,
                            });
                            return;
                        }
                        dispatch({
                            type: 'getScorecardSuccess',
                            payload: data as { bingoSquareIds: string[] },
                        });
                    },
                    (error) => {
                        dispatch({
                            type: 'getScorecardFailure',
                            payload: { error },
                        });
                    },
                );
        } else if (
            stateId === 'getBingoSquaresLoading' ||
            stateId === 'getBingoSquaresError' ||
            stateId === 'playingBingo'
        ) {
            const extractBingoSquareIds = (
                state:
                    | GetBingoSquaresLoading
                    | GetBingoSquaresError
                    | PlayingBingo,
            ): string[] => {
                if (state.id === 'playingBingo') {
                    return state.scorecard.map(({ id }) => id);
                }
                return state.bingoSquareIds;
            };

            const bingoSquareIds = extractBingoSquareIds(state);

            const unsubscribe = subscribeToMultipleDocs<BingoSquareModel>(
                firebase.firestore(),
                `${iterationPath}/events/${eventId}/${Collection.bingoSquares}`,
                bingoSquareIds,
                getBingoSquareFromDocumentSnapshot,
                (unorderedSquares) => {
                    const bingoSquares = bingoSquareIds.map(
                        (bsId) =>
                            unorderedSquares.find((bs) => bs.id === bsId)!,
                    );

                    dispatch({
                        type: 'getBingoSquaresSuccess',
                        payload: {
                            bingoSquares,
                        },
                    });
                },
            );

            return unsubscribe;
        }
    }, [partnerId, iterationPath, uid, stateId, dispatch]);

    return {
        state,
        generateScorecard: () => {
            dispatch(generateScorecardAction);
            ServerApi.generateScorecard({ partnerId, eventId }).then(
                () => {
                    dispatch({ type: 'generateScorecardSuccess' });
                },
                (error) => {
                    dispatch({
                        type: 'generateScorecardFailure',
                        payload: { error },
                    });
                },
            );
        },
    };
};

export default useBingoGame;
