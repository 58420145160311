import React, { Component, useState, CSSProperties } from 'react';
import styled from '@emotion/styled';
import { animated } from 'react-spring';
import { FormattedMessage } from 'react-intl';

import {
    ANSWER_MILESTONE_TOKEN,
    Color,
    LOCK_TIME_TOKEN,
} from 'src/styles/Constants';
import TimeUntilLock from 'src/components/shared/renderless/TimeUntilLock';
import { Timestamp } from 'src/services/Firebase';
import { TranslationMessageId } from 'src/util/LocaleHelpers';
import {
    DEFAULT_HIGHLIGHT_HOURS,
    getCountdownString,
    getDateString,
    getDaysBetween,
    getTimeString,
    MILLIS_IN_HOUR,
} from 'src/util/Dates';

export enum TokenPosition {
    LEADING = 'LEADING',
    TRAILING = 'TRAILING',
}

interface Props {
    style?: CSSProperties;
    countdownData: Timestamp | string;
    showTitleLine: boolean;
    groupTitleTemplate?: {
        message: TranslationMessageId;
        token?: { type: string; position: TokenPosition };
    };
}

export default class CountdownPageBreak extends Component<Props> {
    public shouldComponentUpdate(nextProps: Props) {
        const { countdownData: nextData } = nextProps;
        const { countdownData: curData } = this.props;

        if (typeof nextData !== typeof curData) {
            return true;
        }
        if (nextData == null) return true;

        if (typeof nextData !== 'string') {
            const nextDate = nextData as Timestamp;
            const curDate = curData as Timestamp;
            return nextDate.toDate().getTime() === curDate.toDate().getTime();
        } else {
            const nextDescription = nextData as string;
            const curDescription = curData as string;
            return nextDescription === curDescription;
        }
    }

    public render() {
        const { countdownData, groupTitleTemplate, showTitleLine, style } =
            this.props;

        let label;
        if (typeof countdownData !== 'string') {
            label = <CountdownLabel countdownDate={countdownData} />;
        } else {
            const tokenType =
                groupTitleTemplate &&
                groupTitleTemplate.token &&
                groupTitleTemplate.token.type;

            const tokenPosition =
                groupTitleTemplate &&
                groupTitleTemplate.token &&
                groupTitleTemplate.token.position;

            label = (
                <BatchLabel>
                    {tokenType &&
                        tokenPosition === TokenPosition.LEADING &&
                        countdownData + ' '}
                    {groupTitleTemplate && (
                        <FormattedMessage id={groupTitleTemplate.message} />
                    )}
                    {tokenType &&
                        tokenPosition === TokenPosition.TRAILING &&
                        ' ' + countdownData}
                </BatchLabel>
            );
        }
        return (
            <PageBreak style={style}>
                {showTitleLine && <DividerLine />}
                {label}
                {showTitleLine && <DividerLine />}
            </PageBreak>
        );
    }
}

interface CountdownLabelProps {
    countdownDate: Timestamp;
}

function CountdownLabel(props: CountdownLabelProps) {
    const [countdownSeconds, setCountdownSeconds] = useState<
        undefined | number
    >(undefined);

    const onCountdownUpdated = (countdownSeconds?: number) => {
        setCountdownSeconds(countdownSeconds);
    };

    return (
        props.countdownDate && (
            <BatchLabel>
                <TimeUntilLock
                    countdownDate={props.countdownDate.toDate()}
                    onCountdownUpdated={onCountdownUpdated}
                    highlightHours={24}
                />
                {countdownSeconds && (
                    <LockText
                        countdownSeconds={countdownSeconds}
                        countdownDate={props.countdownDate.toDate()}
                    />
                )}
            </BatchLabel>
        )
    );
}

const LockText = ({
    countdownDate,
    countdownSeconds,
}: {
    countdownDate: Date;
    countdownSeconds: number;
}) => {
    const now = new Date();

    const daysUntilStart = getDaysBetween(now, countdownDate);
    const timeString = getTimeString(countdownDate);

    if (daysUntilStart === 1) {
        <FormattedMessage id="timeUntilLock.timeUntilLockMode.short" />;
    }
    if (daysUntilStart === 0) {
        const hoursUntilStart =
            (countdownDate.getTime() - now.getTime()) / MILLIS_IN_HOUR;

        if (hoursUntilStart < DEFAULT_HIGHLIGHT_HOURS) {
            return (
                <>
                    <FormattedMessage id="countdownPageBreak.batchLabel" />{' '}
                    {getCountdownString(countdownSeconds)}
                </>
            );
        }
        return <>{timeString}</>;
    }

    if (daysUntilStart <= 6) {
        return (
            <FormattedMessage
                id="timeUntilLock.dateType.daysUntil"
                values={{
                    daysUntilStart,
                }}
            />
        );
    }

    return (
        <>
            <FormattedMessage id="countdownPageBreak.batchLabel" />{' '}
            {getDateString(countdownDate)}
        </>
    );
};

const DividerLine = styled.div`
    display: flex;
    flex: 1;
    height: 1px;
    background-color: ${Color.G6_STONE};
`;

const BatchLabel = styled.div`
    margin: 0 30px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 22px;
    text-transform: uppercase;
`;

const PageBreak = styled(animated.div)`
    width: 100%;
    height: 18px;
    padding: 22px 0 12px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;
