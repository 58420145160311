const stripProperties = (object: any) => {
    const filtered: any = {};
    const keys = Object.keys(object);
    for (const key of keys) {
        const value = object[key];
        if (value !== undefined && value !== null) {
            filtered[key] = value;
        }
    }
    return filtered;
};

export const getFullUrl = (
    baseUrl: string,
    path: string,
    queryParams: any = {},
) => {
    const filteredQueryParams = stripProperties(queryParams);
    let query: string = '';
    Object.keys(filteredQueryParams).forEach((key, i) => {
        const value = filteredQueryParams[key];
        const delim = i === 0 ? '?' : '&';
        query = query.concat(`${delim}${key}=${value}`);
    });

    return `${baseUrl}/${path}${query}`;
};
