import React from 'react';
import styled from '@emotion/styled';
import { IntlShape, useIntl } from 'react-intl';

import tallyLogo from 'src/images/icons/tallylogo-header@1x.png';
import { Height, PistachioZIndex } from 'src/styles/Constants';
import Theme from 'src/util/Theme';
import TallyMenu from './TallyMenu/TallyMenu';
import LanguageMenu from './LanguageMenu';
import { TranslatedIteration } from 'src/util/i18n';
import { EventModel } from 'src/util/TallyFirestore';
import { useHistory, useLocation } from 'react-router-dom';
import routeUrls from 'src/routes';
import { usePartner } from 'src/hooks/usePartner';

interface Props {
    iteration: TranslatedIteration;
    event?: EventModel;
}

const getHeading = ({
    intl,
    iteration,
    event,
    pathname,
}: {
    intl: IntlShape;
    iteration: TranslatedIteration;
    event?: EventModel;
    pathname: string;
}): { title: string; subtitle?: string } => {
    if (pathname === routeUrls.account) {
        return {
            title: intl.formatMessage({ id: 'menu.account' }),
        };
    }

    if (event) {
        if (pathname.includes('leaderboard')) {
            return {
                title: intl.formatMessage({
                    id: 'menu.gameLeaderboard',
                }),
                subtitle: intl.formatMessage(
                    {
                        id: 'menu.playing',
                    },
                    { eventName: event.shortName },
                ),
            };
        }

        return { title: event.shortName };
    }

    if (pathname.includes('leaderboard')) {
        return {
            title: intl.formatMessage({
                id: 'menu.gameLeaderboard',
            }),
        };
    }

    return { title: iteration.name };
};

const PistachioTitlebar = ({ iteration, event }: Props) => {
    const {
        properties: { appEmbedded, hideLeaderboards, hideAccountPage },
    } = usePartner();
    const intl = useIntl();
    const location = useLocation();
    const history = useHistory();
    const logoUrl = Theme.navLogoUrl || tallyLogo;

    const { pathname } = location;

    const { title, subtitle } = getHeading({
        intl,
        iteration,
        event,
        pathname,
    });

    const showMenu = appEmbedded && (!hideLeaderboards || !hideAccountPage);

    return (
        <Container>
            <Heading>
                {title}
                <span>{subtitle}</span>
            </Heading>
            {logoUrl && (
                <LogoContainer
                    onClick={() => history.push(routeUrls.play)}
                    src={logoUrl}
                />
            )}
            <ControlButtons>
                <LanguageMenu />
                {showMenu && <TallyMenu />}
            </ControlButtons>
        </Container>
    );
};

export default PistachioTitlebar;

const Container = styled.div`
    align-items: center;
    color: ${Theme.navBarTextColor};
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: ${Height.TITLEBAR}px;
    background-color: ${Theme.navBarBackgroundColor};
`;

const Heading = styled.h1`
    position: absolute;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    span {
        display: block;
        font-size: 10px;
    }
`;

// The title element is absolutely positioned,
// so we need to have a z-index on the logo and leaderboard button so it shows on top of the title.
const LogoContainer = styled.img`
    cursor: pointer;
    margin-left: 12px;
    max-height: 36px;
    z-index: ${PistachioZIndex.TITLEBAR_EDGE_ELEMENTS};
`;

const ControlButtons = styled.div`
    align-self: stretch;
    align-items: stretch;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
