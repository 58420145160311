import React from 'react';
import { useState } from 'react';

import browserMode, { BrowserMode } from 'src/util/BrowserMode';
import {
    hasDismissedSmsModuleForEvent,
    localStorageSetModalDismissedForEvent,
} from 'src/services/LocalStorageClient';
import { TranslatedConfigPartnerData } from 'src/util/ConfigTypes';
import {
    ContactInfo,
    EventState,
    SMSAlertsState,
} from 'src/util/TallyFirestore';
import { EventModel } from 'src/util/TallyFirestore';
import { usePartner } from 'src/hooks/usePartner';
import { FormattedMessage } from 'react-intl';
import { getContactInfo, useUserState } from 'src/contexts/UserStateContext';
import TallyMarkdown from 'src/components/shared/ui/TallyMarkdown';

export type DisplayOnlyModuleDetails = {
    type: 'displayOnly';
    preTitle: string | JSX.Element;
    title: string | JSX.Element;
    body: string | JSX.Element;
    showSeesaw: boolean;
};

export type InteractiveModuleDetails = {
    type: 'smsOptIn';
    preTitle: string | JSX.Element;
    title: string | JSX.Element;
    body: string | JSX.Element;
    showSeesaw: boolean;
};

export type InformationModuleDetails =
    | DisplayOnlyModuleDetails
    | InteractiveModuleDetails;

const useInformationModuleDetails = ({
    event,
    hasUnansweredActivePrediction,
    hasActivePredictions,
    lastBatchReleased,
}: {
    event: EventModel;
    hasUnansweredActivePrediction: boolean;
    hasActivePredictions: boolean;
    lastBatchReleased: boolean;
}): [InformationModuleDetails, () => void] => {
    const userData = useUserState();
    const [hasDismissedSmsModule, setHasDismissedSmsModule] = useState(
        hasDismissedSmsModuleForEvent(event.id),
    );
    const partner = usePartner();

    const moduleDetails = getInformationModuleDetails({
        contactInfo: getContactInfo(userData),
        partner,
        event,
        hasUnansweredActivePrediction,
        hasActivePredictions,
        lastBatchReleased,
        hasDismissedSmsModule,
    });

    return [
        moduleDetails,
        () => {
            setHasDismissedSmsModule(true);
            localStorageSetModalDismissedForEvent(event.id);
        },
    ];
};

const getInformationModuleDetails = (params: {
    contactInfo?: ContactInfo;
    partner: TranslatedConfigPartnerData;
    event: EventModel;
    hasUnansweredActivePrediction: boolean;
    hasActivePredictions: boolean;
    lastBatchReleased: boolean;
    hasDismissedSmsModule: boolean;
}): InformationModuleDetails => {
    const {
        contactInfo,
        partner,
        event,
        hasUnansweredActivePrediction,
        hasActivePredictions,
        lastBatchReleased,
        hasDismissedSmsModule,
    } = params;

    const SMS_OPT_IN_INFO_MODULE_DETAILS: InformationModuleDetails = {
        type: 'smsOptIn',
        body: (partner.properties.infoModuleStayTunedOptInBody && (
            <TallyMarkdown
                source={partner.properties.infoModuleStayTunedOptInBody}
            />
        )) || (
            <FormattedMessage id="predictPage.smsOptInInfoModuleDetails.body" />
        ),
        preTitle: partner.properties.infoModuleStayTunedOptInPreTitle || (
            <FormattedMessage id="predictPage.smsOptInInfoModuleDetails.preTitle" />
        ),
        showSeesaw: false,
        title: partner.properties.infoModuleStayTunedOptInTitle || (
            <FormattedMessage id="predictPage.smsOptInInfoModuleDetails.title" />
        ),
    };

    const DEAD_STATE_AND_ALL_ANSWERED_INFO_MODULE_DETAILS: InformationModuleDetails =
        {
            type: 'displayOnly',
            body: (partner.properties.infoModuleStayTunedBody && (
                <TallyMarkdown
                    source={partner.properties.infoModuleStayTunedBody}
                />
            )) || (
                <FormattedMessage id="predictPage.deadStateAndAllAnsweredInfoModuleDetails.body" />
            ),
            preTitle: partner.properties.infoModuleStayTunedPretitle || '',
            showSeesaw: true,
            title: partner.properties.infoModuleStayTunedTitle || (
                <FormattedMessage id="predictPage.deadStateAndAllAnsweredInfoModuleDetails.title" />
            ),
        };

    const DEAD_STATE_AND_ALL_RELEASED_INFO_MODULE_DETAILS: InformationModuleDetails =
        {
            type: 'displayOnly',
            body: (partner.properties.infoModuleStayTunedPendingBody && (
                <TallyMarkdown
                    source={partner.properties.infoModuleStayTunedPendingBody}
                />
            )) || (
                <FormattedMessage id="predictPage.deadStateAndAllReleasedInfoModuleDetails.body" />
            ),
            preTitle: partner.properties.infoModuleStayTunedPendingPretitle || (
                <FormattedMessage id="predictPage.deadStateAndAllReleasedInfoModuleDetails.preTitle" />
            ),
            showSeesaw: true,
            title: partner.properties.infoModuleStayTunedPendingTitle || (
                <FormattedMessage id="predictPage.deadStateAndAllReleasedInfoModuleDetails.title" />
            ),
        };

    const BASIC_GAME_OVER_INFO_MODULE_DETAILS: InformationModuleDetails = {
        type: 'displayOnly',
        body:
            event.prizes && event.prizes.length > 0 ? (
                <FormattedMessage id="predictPage.basicGameOverInfoModuleDetails.bodyWithPrizes" />
            ) : (
                <FormattedMessage id="predictPage.basicGameOverInfoModuleDetails.bodyWithoutPrizes" />
            ),
        preTitle: (
            <FormattedMessage id="predictPage.basicGameOverInfoModuleDetails.preTitle" />
        ),
        showSeesaw: true,
        title: (
            <FormattedMessage id="predictPage.basicGameOverInfoModuleDetails.title" />
        ),
    };

    const ACTIVE_PREDICTIONS_INFO_MODULE_DETAILS_CONF: InformationModuleDetails =
        {
            type: 'displayOnly',
            body: partner.properties.infoModuleInGameMoreQuestionsBody || (
                <FormattedMessage id="predictPage.activePredictionsInfoModuleDetails.body" />
            ),
            preTitle: partner.properties
                .infoModuleInGameMoreQuestionsPreTitle || (
                <FormattedMessage id="predictPage.activePredictionsInfoModuleDetails.preTitle" />
            ),
            showSeesaw: true,
            title: partner.properties.infoModuleInGameMoreQuestionsTitle || (
                <FormattedMessage id="predictPage.activePredictionsInfoModuleDetails.title" />
            ),
        };

    const eventIsFinalized = event.state === EventState.final;
    const isEmbedded = browserMode === BrowserMode.embedded;
    const { smsAlertsEnabled } = partner;

    if (!contactInfo) {
        return eventIsFinalized
            ? BASIC_GAME_OVER_INFO_MODULE_DETAILS
            : hasActivePredictions
            ? ACTIVE_PREDICTIONS_INFO_MODULE_DETAILS_CONF
            : lastBatchReleased
            ? DEAD_STATE_AND_ALL_RELEASED_INFO_MODULE_DETAILS
            : DEAD_STATE_AND_ALL_ANSWERED_INFO_MODULE_DETAILS;
    }

    const optedIntoSmsAlerts =
        contactInfo.smsAlertsState === SMSAlertsState.OPTED_IN;

    if (eventIsFinalized) {
        if (
            optedIntoSmsAlerts ||
            hasDismissedSmsModule ||
            isEmbedded ||
            !smsAlertsEnabled
        ) {
            return BASIC_GAME_OVER_INFO_MODULE_DETAILS;
        } else {
            return SMS_OPT_IN_INFO_MODULE_DETAILS;
        }
    }

    if (hasActivePredictions) {
        if (
            optedIntoSmsAlerts ||
            hasDismissedSmsModule ||
            isEmbedded ||
            !smsAlertsEnabled
        ) {
            return hasUnansweredActivePrediction
                ? ACTIVE_PREDICTIONS_INFO_MODULE_DETAILS_CONF
                : DEAD_STATE_AND_ALL_ANSWERED_INFO_MODULE_DETAILS;
        } else {
            return SMS_OPT_IN_INFO_MODULE_DETAILS;
        }
    } else {
        // at this point we should not have any more questions to show -
        // so we will not pretend there is more to come
        // return DEAD_STATE_AND_ALL_ANSWERED_INFO_MODULE_DETAILS; -- this says there is more
        return DEAD_STATE_AND_ALL_RELEASED_INFO_MODULE_DETAILS;
    }
};

const ACTIVE_PREDICTIONS_INFO_MODULE_DETAILS: InformationModuleDetails = {
    type: 'displayOnly',
    body: (
        <FormattedMessage id="predictPage.activePredictionsInfoModuleDetails.body" />
    ),
    preTitle: (
        <FormattedMessage id="predictPage.activePredictionsInfoModuleDetails.preTitle" />
    ),
    showSeesaw: true,
    title: (
        <FormattedMessage id="predictPage.activePredictionsInfoModuleDetails.title" />
    ),
};

export default useInformationModuleDetails;
