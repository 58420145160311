import React, { createContext, useContext, useEffect, useState } from 'react';
import { webStorageKeys } from 'src/constants';
import useStorageState from '../useStorageState';

export type UserStorageConsent = {
    analytics: boolean;
};

export type UserStorageConsentContextType = {
    consent: UserStorageConsent | undefined;
    setConsent: (a: UserStorageConsent) => void;
};

export const UserStorageConsentContext =
    createContext<UserStorageConsentContextType>({
        consent: undefined,
        setConsent: () => {},
    });

type ConsentModalContextType = {
    isOpen: boolean;
    setIsOpen: (a: boolean) => void;
};

const ConsentModalContext = createContext<ConsentModalContextType>({
    isOpen: false,
    setIsOpen: () => {},
});

const isUserStorageConsent = (v: unknown): v is UserStorageConsent =>
    typeof v === 'object' &&
    v !== null &&
    'analytics' in v &&
    typeof v.analytics === 'boolean';

export const UserStorageConsentProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [consent, setConsent] = useStorageState(
        localStorage,
        webStorageKeys.storageConsent,
        isUserStorageConsent,
        undefined,
    );
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        // we need to open a modal once, if a user haven't consented
        if (consent === undefined) {
            setIsOpen(true);
        }
    }, [setIsOpen, consent]);

    return (
        <UserStorageConsentContext.Provider value={{ consent, setConsent }}>
            <ConsentModalContext.Provider value={{ isOpen, setIsOpen }}>
                {children}
            </ConsentModalContext.Provider>
        </UserStorageConsentContext.Provider>
    );
};

export const useUserStorageConsent = () => {
    return useContext(UserStorageConsentContext);
};

export const useConsentModal = () => {
    return useContext(ConsentModalContext);
};
