import { css } from 'emotion';
import React, { Component, ContextType, ReactNode } from 'react';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';

import checkmark from 'src/images/icons/Picks-Correct-M@3x.svg';
import wrongmark from 'src/images/icons/Picks-Wrong-M@3x.svg';

import { LocaleContext } from 'src/hooks/useLocale';
import { OptionModel, PredictionType, OddsType } from 'src/util/TallyFirestore';
import { MultipleChoicyPredictionType } from 'src/util/PredictionHelpers';
import SubmitStatusIndicator, {
    IndicatorState,
} from 'src/components/shared/animations/SubmitStatusIndicator';
import { IntlPointsText } from 'src/components/shared/ui/IntlPointsText';
import { Color, TextStyles, TextStyleTypes } from 'src/styles/Constants';
import Theme from 'src/util/Theme';
import { MultipleChoiceOddsType } from 'src/util/PredictionHelpers';
import {
    getOptionContainerStyle,
    getOptionOutcome,
    OptionOutcome,
    styles,
} from './util';
import { getOddsWin } from 'src/util/bettingOdds';

interface Props {
    onPress?: (option: OptionModel) => Promise<boolean>;
    option: OptionModel;
    predictionType: MultipleChoicyPredictionType;
    predictionPointValue: number;
    predictionResolved: boolean;
    predictionLocked: boolean;
    selectedOptionId?: string;
    totalCount: number;
    // optional values for odds
    oddsType?: MultipleChoiceOddsType;
    oddsTheOdds?: number;
    oddClaim?: number;
    allOrNothing?: boolean;
    hideOptionMetrics?: boolean;
}

interface State {
    loadingState: IndicatorState;
}

const OPTION_PROGRESS_CONTAINER_STYLES = {
    correct_and_user_correct: {
        borderBottomWidth: 0,
        borderColor: Color.PREDICTION_GREEN,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderStyle: 'solid',
        borderTopWidth: 1,
    },
    default: {
        borderBottomWidth: 0,
        borderColor: Color.T28_FOG,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderStyle: 'solid',
        borderTopWidth: 1,
    },
    incorrect: {
        borderBottomWidth: 0,
        borderColor: Color.P4_CHILI_PEPPER,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderStyle: 'solid',
        borderTopWidth: 1,
    },
    selected: {
        borderBottomWidth: 0,
        borderColor: Theme.predictionAnsweredIconBackgroundColor,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderStyle: 'solid',
        borderTopWidth: 1,
    },
};

const OptionProgressForegroundColors = {
    correct: Color.PREDICTION_GREEN,
    default: Color.PREDICTION_GREY_A3,
    incorrect: Color.P4_CHILI_PEPPER,
    selected: Theme.predictionAnsweredIconBackgroundColor,
};
export default class Option extends Component<Props, State> {
    public static contextType = LocaleContext;
    public context!: ContextType<typeof LocaleContext>;
    constructor(props: Props) {
        super(props);
        this.state = {
            loadingState: IndicatorState.HIDDEN,
        };
    }

    private onPress = async (e: React.MouseEvent) => {
        const { onPress, option } = this.props;
        e.stopPropagation(); // prevent triggering of other onclicks. (expanding collapsing)
        if (onPress) {
            this.setState({ loadingState: IndicatorState.LOADING });
            const success = await onPress(option);
            this.setState({
                loadingState: success
                    ? IndicatorState.CONFIRMATION
                    : IndicatorState.HIDDEN,
            });
        }
    };

    private getSelectedCount = () => {
        const { option, predictionResolved, selectedOptionId } = this.props;
        const { id, selectedCount = 0 } = option;

        // If selectedOptionId is present, then a user just selected an option
        // that might not be reflected in the option selectedCounts from Firestore.
        // Add one in, *unless* the prediction is resolved.
        // When the prediction is resolved, the option contains the correct count.
        // Don't recalculate on the client.
        return !predictionResolved && selectedOptionId === id
            ? selectedCount + 1
            : selectedCount;
    };

    private getPointValueForOption(
        optionSelectedPercent: number,
        optionPointValue: number | undefined,
        predictionPointValue: number,
        resolved: boolean,
        allOrNothing?: boolean,
    ) {
        // When the prediction is resolved, the option contains the correct point value.
        // Don't recalculate on the client.
        if (resolved && optionPointValue !== undefined) {
            return optionPointValue;
        }

        if (allOrNothing) {
            return predictionPointValue;
        }

        if (optionSelectedPercent >= 1) {
            return predictionPointValue;
        }

        return Math.round((1 - optionSelectedPercent) * predictionPointValue);
    }

    // Returns styling for the bottom portion of the option container (progress bar).
    private static getOptionProgressContainerStyle = (
        optionOutcome: OptionOutcome,
        selected: boolean,
    ) => {
        if (optionOutcome === OptionOutcome.CORRECT && selected) {
            return OPTION_PROGRESS_CONTAINER_STYLES.correct_and_user_correct;
        }
        if (optionOutcome === OptionOutcome.INCORRECT && selected) {
            return OPTION_PROGRESS_CONTAINER_STYLES.incorrect;
        }
        if (optionOutcome === OptionOutcome.UNRESOLVED && selected) {
            return OPTION_PROGRESS_CONTAINER_STYLES.selected;
        }

        // Otherwise return a dimmed container.
        return OPTION_PROGRESS_CONTAINER_STYLES.default;
    };

    // Returns the color of the progress bar.
    private static getOptionProgressForegroundColor = (
        optionOutcome: OptionOutcome,
        selected: boolean,
    ) => {
        if (optionOutcome === OptionOutcome.CORRECT && selected) {
            return OptionProgressForegroundColors.correct;
        }
        if (optionOutcome === OptionOutcome.INCORRECT && selected) {
            return OptionProgressForegroundColors.incorrect;
        }
        if (optionOutcome === OptionOutcome.UNRESOLVED && selected) {
            return OptionProgressForegroundColors.selected;
        }

        return OptionProgressForegroundColors.default;
    };

    public render(): ReactNode {
        const {
            option,
            predictionLocked,
            predictionPointValue,
            predictionResolved,
            predictionType,
            selectedOptionId,
            totalCount,
            oddClaim,
            oddsTheOdds: odds,
            oddsType,
            allOrNothing,
            hideOptionMetrics,
        } = this.props;

        const { loadingState } = this.state;
        const { localeId } = this.context;
        const { pointValue: optionPointValue, text } = option;

        const isPoll = predictionType === 'POLL';
        const isUnlockedPoll = isPoll && !predictionLocked;
        const showStats = !isUnlockedPoll;
        const showProgress = !isUnlockedPoll && !hideOptionMetrics && !isPoll;
        const showPercentText = !isPoll && !hideOptionMetrics;
        const showPointsText =
            !isUnlockedPoll &&
            !isPoll && // i.e. hide the points for locked polls (since it's already shown in the prediction container).
            (!hideOptionMetrics || (hideOptionMetrics && allOrNothing)); //hide if event is configured to hideOptionMetrics, but not when allOrNothing is on
        const showShear = showPercentText && showPointsText;

        const optionOutcome = getOptionOutcome(option);
        const selected = selectedOptionId === option.id;
        const selectedCount = option.selectedCount || 0;

        const selectedPercent = Math.min(
            totalCount ? selectedCount / totalCount : 0,
            1,
        );
        const pointValue = this.getPointValueForOption(
            selectedPercent,
            optionPointValue,
            predictionPointValue,
            predictionResolved,
            allOrNothing,
        );
        const percentText = `${Math.round(selectedPercent * 100)}% ppl`;

        const lineWidth = `${(selectedPercent * 100).toFixed(2)}%`;

        const unlocked = !predictionResolved && !predictionLocked;
        const submitting = loadingState === IndicatorState.LOADING;
        const clickable = !submitting && unlocked;

        // odds related stuff
        const showOdds = predictionType === 'BET_SIM';
        const oddsBet = predictionPointValue;
        const oddsWin = getOddsWin(predictionPointValue, odds);

        const containerStyle = getOptionContainerStyle(optionOutcome, selected);

        const progressContainerStyle = Option.getOptionProgressContainerStyle(
            optionOutcome,
            selected,
        );
        const optionProgressForegroundColor =
            Option.getOptionProgressForegroundColor(optionOutcome, selected);

        let outcomeIcon;
        if (optionOutcome === OptionOutcome.CORRECT) {
            outcomeIcon = checkmark;
        } else if (
            optionOutcome === OptionOutcome.INCORRECT &&
            selectedOptionId === option.id
        ) {
            outcomeIcon = wrongmark;
        }

        const dimText = predictionLocked && !selectedOptionId;

        const getOddOptionTextId = (oddsType: OddsType) => {
            switch (oddsType) {
                case OddsType.overUnder:
                    return 'prediction.option.odds.oddsType.OVER_UNDER';
                case OddsType.moneyLine:
                default:
                    return 'prediction.option.odds.oddsType.MONEY_LINE';
            }
        };

        return (
            <div
                className={css([
                    styles.container,
                    clickable ? styles.activeContainer : undefined,
                    containerStyle,
                    {
                        color: dimText
                            ? Color.PREDICTION_GREY_A3
                            : Color.T31_BLACK,
                        cursor: clickable ? 'pointer' : 'auto',
                    },
                ])}
                onClick={clickable ? this.onPress : undefined}
            >
                <ContentContainer isPoll={isPoll}>
                    <TextContainer>
                        <OptionText isPoll={isPoll}>{text}</OptionText>
                        {showStats &&
                            (!showOdds ? (
                                <StatsText center={isPoll}>
                                    {showPercentText && percentText}
                                    {showShear && <Shear>▰</Shear>}
                                    {showPointsText && (
                                        <IntlPointsText points={pointValue} />
                                    )}
                                </StatsText>
                            ) : (
                                <StatsText center={false}></StatsText>
                            ))}
                        {showStats && showOdds && (
                            <>
                                <StatsText center={isPoll}>
                                    {oddsType && (
                                        <>
                                            {(!predictionLocked ||
                                                !oddClaim) && (
                                                <>
                                                    <FormattedMessage
                                                        id={getOddOptionTextId(
                                                            oddsType,
                                                        )}
                                                    />{' '}
                                                    (
                                                    {(odds && odds < 0
                                                        ? ''
                                                        : '+') + odds}
                                                    ){' '}
                                                </>
                                            )}

                                            {oddClaim && (
                                                <>
                                                    <FormattedMessage
                                                        id={
                                                            'prediction.option.odds.oddClaim'
                                                        }
                                                    />{' '}
                                                    (
                                                    {(oddClaim < 0 ? '' : '+') +
                                                        oddClaim}
                                                    )
                                                </>
                                            )}
                                        </>
                                    )}
                                    <Shear>▰</Shear>

                                    {optionOutcome === OptionOutcome.CORRECT ? (
                                        <FormattedMessage
                                            id={
                                                'prediction.option.odds.betWonShort'
                                            }
                                            values={{
                                                win:
                                                    oddsBet +
                                                    getOddsWin(
                                                        predictionPointValue,
                                                        oddClaim,
                                                    ).toLocaleString(localeId),
                                                bet: oddsBet.toLocaleString(
                                                    localeId,
                                                ),
                                            }}
                                        />
                                    ) : (
                                        <FormattedMessage
                                            id={
                                                'prediction.option.odds.betToWinShort'
                                            }
                                            values={{
                                                win: (
                                                    oddsBet + oddsWin
                                                ).toLocaleString(localeId),
                                                bet: oddsBet.toLocaleString(
                                                    localeId,
                                                ),
                                            }}
                                        />
                                    )}
                                </StatsText>
                            </>
                        )}
                    </TextContainer>
                    {!isPoll && outcomeIcon && (
                        <ResolvedIcon src={outcomeIcon} />
                    )}
                </ContentContainer>
                {showProgress && (
                    <div
                        className={css([
                            styles.progress,
                            progressContainerStyle,
                        ])}
                    >
                        <ProgressForeground
                            width={lineWidth}
                            backgroundColor={optionProgressForegroundColor}
                        />
                    </div>
                )}
                <SubmitStatusIndicator state={loadingState} />
            </div>
        );
    }
}

const Shear = styled.div`
    margin: 0 6px;
`;

const ProgressForeground = styled.div<{
    backgroundColor: string;
    width: string;
}>`
    width: ${(props) => props.width};
    background-color: ${(props) => props.backgroundColor};
    background-size: cover;
    height: 100%;
    vertical-align: bottom;
    transition: width 0.5s;
    transition-timing-function: ease;
`;

const ContentContainer = styled.div<{ isPoll: boolean }>`
    align-items: center;
    display: flex;
    min-height: ${(props) => (props.isPoll ? '26px' : 'initial')};
    padding: ${(props) => (props.isPoll ? '10px' : '8px 8px 4px 8px')};
    text-align: ${(props) => (props.isPoll ? 'center' : 'initial')};
`;

const TextContainer = styled.div`
    flex: 1;
`;

const ResolvedIcon = styled.img`
    height: 18px;
    width: 18px;
`;

const OptionText = styled.div<{ isPoll: boolean }>`
    ${TextStyles[TextStyleTypes.P4]}
    text-transform: ${(props) => (props.isPoll ? 'uppercase' : 'none')};
`;

const StatsText = styled.div<{ center: boolean }>`
    justify-content: ${(props) => (props.center ? 'center' : 'none')};
    display: flex;
    flex-direction: row;
    font-size: 12px;
    font-style: italic;
    line-height: 18px;
    align-items: center;
`;
