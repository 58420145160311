import React from 'react';
import styled from '@emotion/styled';

import { TextStyleTypes, TextStyles, Color } from 'src/styles/Constants';
import TallyMarkdown from 'src/components/shared/ui/TallyMarkdown';

function Section(props: { title: string; markdown: string }) {
    const { title, markdown } = props;
    return (
        <>
            <SectionHeaderText>{title}</SectionHeaderText>
            <SectionBodyText>
                <TallyMarkdown source={markdown} />
            </SectionBodyText>
        </>
    );
}

const SectionHeaderText = styled.h2`
    ${TextStyles[TextStyleTypes.B1]};
    border-bottom: 1px solid ${Color.G7_FOG};
    padding-bottom: 12px;
`;

const SectionBodyText = styled.div`
    ${TextStyles[TextStyleTypes.P1]};
    margin-top: 6px;
    margin-bottom: 30px;
`;

export default Section;
