import React, { useRef } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { InitialEventData, isSingleEventData } from 'src/util/Config';
import SingleEvent from './SingleEvent';
import MultiEvent from './MultiEvent';
import routeUrls, { getEventSlugSearchParam } from 'src/routes';
import { usePartner } from 'src/hooks/usePartner';
import useLiveEntityLeaderboards from '../hooks/useLiveEntityLeaderboards';
import {
    translateFirestoreEvent,
    translateFirestoreIteration,
} from 'src/util/i18n';
import { EventModel } from 'src/util/TallyFirestore';
import { useLocale } from 'src/hooks/useLocale';
import { IterationProvider } from 'src/hooks/useIteration';
import { useAnalytics } from 'src/contexts/AnalyticsContext';
import BottomNav from './BottomNav';
import ModalHoster, {
    ModalType,
} from 'src/components/shared/modals/ModalHoster';
import useLiveSponsors from './SingleEvent/hooks/useLiveSponsors';
import { ModalHosterContext } from 'src/contexts/ModalHosterContext';
import { getGameWideSponsor } from 'src/util/sponsor';
import { WithNotNullProperty } from 'src/utilityTypes';
import { ShowEventPrizeDetailsContext } from 'src/contexts/ShowEventPrizeDetailsContext';
import SponsorsContext from 'src/contexts/SponsorsContext';
import { Container } from './components';

type Props = {
    initialEventData: InitialEventData;
};

const Iteration = ({ initialEventData }: Props) => {
    const { iteration, events } = initialEventData;
    const modalHosterRef = useRef<null | ModalHoster>(null);
    const { localeId } = useLocale();
    const location = useLocation();
    const analytics = useAnalytics();
    const partner = usePartner();
    const sponsors = useLiveSponsors(partner.partnerId);

    const translatedIteration =
        translateFirestoreIteration(localeId)(iteration);
    const translatedEvents = events.map(translateFirestoreEvent(localeId));

    const selectedEventSlug = getEventSlugSearchParam(location.search);

    const iterationLeaderboards = useLiveEntityLeaderboards(
        translatedIteration.tournamentEnabled
            ? translatedIteration.path
            : undefined,
        translatedIteration,
    );

    const getEventComponent = () => {
        if (isSingleEventData(initialEventData)) {
            return (
                <SingleEvent
                    iteration={translatedIteration}
                    iterationLeaderboards={iterationLeaderboards}
                    event={initialEventData.event}
                    events={translatedEvents}
                />
            );
        }

        if (!selectedEventSlug) {
            return (
                <MultiEvent
                    iteration={translatedIteration}
                    events={translatedEvents}
                    iterationLeaderboards={iterationLeaderboards}
                />
            );
        }

        const event = initialEventData.events.get(selectedEventSlug);
        if (!event) {
            return <Redirect to={routeUrls.play} />;
        }
        return (
            <SingleEvent
                iteration={translatedIteration}
                iterationLeaderboards={iterationLeaderboards}
                event={event}
                events={translatedEvents}
            />
        );
    };

    const modalHoster = modalHosterRef.current || undefined;
    const showNav =
        !partner.properties.appEmbedded &&
        (!partner.properties.hideLeaderboards ||
            !partner.properties.hideAccountPage);

    const showPrizeDetails = modalHoster
        ? (event: WithNotNullProperty<EventModel, 'prizeDetailsMarkdown'>) =>
              modalHoster.showModal({
                  event,
                  gameWideSponsor: getGameWideSponsor(event, sponsors),
                  modalType: ModalType.PRIZES,
              })
        : undefined;

    return (
        <SponsorsContext.Provider value={sponsors}>
            <IterationProvider value={translatedIteration}>
                <ModalHosterContext.Provider value={modalHoster}>
                    <ShowEventPrizeDetailsContext.Provider
                        value={showPrizeDetails}
                    >
                        <Container appEmbedded={partner.properties.appEmbedded}>
                            {getEventComponent()}
                            {showNav && <BottomNav />}
                        </Container>
                    </ShowEventPrizeDetailsContext.Provider>
                    <ModalHoster ref={modalHosterRef} analytics={analytics} />
                </ModalHosterContext.Provider>
            </IterationProvider>
        </SponsorsContext.Provider>
    );
};

export default Iteration;
