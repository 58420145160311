import React from 'react';
import styled from '@emotion/styled';
import TextInput from 'src/components/shared/controls/fields/TextInput';
import DateInput from 'src/components/shared/controls/fields/DateInput';
import SelectInput from 'src/components/shared/controls/fields/SelectInput';
import { Color, TextStyles, TextStyleTypes } from 'src/styles/Constants';
import TallyMarkdown from 'src/components/shared/ui/TallyMarkdown';
import {
    TranslatedDataCollection,
    AdditionalDataFieldsTypeEnum,
    AdditionalDataCollectionField,
} from 'src/util/ConfigTypes';
import { TileData } from './DisplayNameTile';
import ServerError from 'src/services/ServerError';
import { ValidationError } from 'src/util/validation';
import { BackgroundPanel } from 'src/components/shared/ui/BackgroundPanel';

type Props = {
    dataCollection: TranslatedDataCollection;
    onContactSupportClick: () => void;
    setData: (value: React.SetStateAction<TileData>) => void;
    errors?: { [key: string]: ServerError | Error };
    onError?: (error: ValidationError) => void;
    onClearError?: (field: string) => void;
    screenHasBg?: boolean | false;
};

export default ({
    dataCollection,
    onContactSupportClick,
    setData,
    errors,
    onError,
    onClearError,
    screenHasBg,
}: Props) => {
    const fields = dataCollection.fields.map(
        (field: AdditionalDataCollectionField<string>, i) => {
            const error = errors && errors[field.id];
            const baseProps = {
                field: field.id,
                error,
                onError,
                onClearError,
                onChange: (value: string) =>
                    setData((previous: TileData) => ({
                        ...previous,
                        [field.id]: value,
                    })),
                label: field.label,
                onContactSupportClick,
                key: field.id,
                validationErrorText: field.validationErrorText,
            };

            switch (field.type) {
                case AdditionalDataFieldsTypeEnum.DATE:
                    return (
                        <BackgroundPanel
                            screenHasBg={screenHasBg}
                            btmConnect={true}
                            topConnect={true}
                        >
                            <FieldContainer>
                                <DateInput
                                    {...baseProps}
                                    onClear={() =>
                                        setData((previous: TileData) => ({
                                            ...previous,
                                            [field.id]: undefined,
                                        }))
                                    }
                                    validation={field.dateValidation}
                                />
                            </FieldContainer>
                        </BackgroundPanel>
                    );
                case AdditionalDataFieldsTypeEnum.SELECT:
                    return (
                        <BackgroundPanel
                            screenHasBg={screenHasBg}
                            btmConnect={true}
                            topConnect={true}
                        >
                            <FieldContainer>
                                <SelectInput
                                    {...baseProps}
                                    defaultValue={field.defaultValue}
                                    selectOptions={field.options}
                                    validation={
                                        field.validationRegexp
                                            ? new RegExp(field.validationRegexp)
                                            : undefined
                                    }
                                />
                            </FieldContainer>
                        </BackgroundPanel>
                    );
                case AdditionalDataFieldsTypeEnum.TEXT:
                default:
                    return (
                        <BackgroundPanel
                            screenHasBg={screenHasBg}
                            btmConnect={true}
                            topConnect={true}
                        >
                            <FieldContainer>
                                <TextInput
                                    {...baseProps}
                                    onClear={() =>
                                        setData((previous: TileData) => ({
                                            ...previous,
                                            [field.id]: undefined,
                                        }))
                                    }
                                    validation={
                                        field.validationRegexp
                                            ? new RegExp(field.validationRegexp)
                                            : undefined
                                    }
                                />
                            </FieldContainer>
                        </BackgroundPanel>
                    );
            }
        },
    );
    return (
        <>
            <BackgroundPanel screenHasBg={screenHasBg} btmConnect={true}>
                <TileTitle color={Color.T31_BLACK}>
                    {dataCollection.headline}
                </TileTitle>
                {dataCollection.description && (
                    <DescriptionText>
                        <TallyMarkdown source={dataCollection.description} />
                    </DescriptionText>
                )}
            </BackgroundPanel>
            {fields}
        </>
    );
};

const TileTitle = styled.div`
    ${TextStyles[TextStyleTypes.H3]}
    margin: 20px 0 15px 0;
    border-bottom: 2px solid ${Color.G8_CONCRETE};
    text-align: left;
    width: 100%;
`;

const DescriptionText = styled.div`
    margin: 15px 0 0 0;
    ${TextStyles[TextStyleTypes.P1]}
    text-align: left;
    width: 100%;
`;

const FieldContainer = styled.div`
    margin: 15px 0 15px 0;
    width: 100%;
`;
