import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Color } from 'src/styles/Constants';
import checkMark from 'src/images/icons/Picks-Correct-S@3x.svg';
import TallyMarkdown from 'src/components/shared/ui/TallyMarkdown';

interface Props {
    field: string;
    onChange?: (value: any) => void;
    label: string;
    value?: boolean;
}

export default ({ value, onChange, label }: Props): any => {
    const [pick, setPick] = useState<boolean>(Boolean(value));

    const change = () => {
        setPick(!pick);
        onChange && onChange(!pick);
    };

    return (
        <CheckboxRow onClick={change}>
            <Checkbox type="checkbox" isChecked={pick} />
            <TextContainer>
                <CheckboxMessage>
                    <TallyMarkdown source={label} paragraphPaddingBottom="0" />
                </CheckboxMessage>
            </TextContainer>
        </CheckboxRow>
    );
};

const CheckboxRow = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 10px 8px;
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const CheckboxMessage = styled.div`
    text-align: left;
    font-size: 10px;
    padding-left: 7px;
`;

const Checkbox = styled.input<{ isChecked: boolean }>`
    appearance: none;
    align-self: flex-start;
    background-image: url(${(props) =>
        props.isChecked ? checkMark : undefined});
    background-repeat: no-repeat;
    background-position: center;
    min-width: 20px;
    min-height: 20px;
    border: 1px solid ${Color.T34_SQUIRTLE};
    transition: all 0.1s;
`;
