import React, { useState, ChangeEvent, useEffect } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import styled from '@emotion/styled';
import Select from '@material-ui/core/Select';
import { keyframes } from 'emotion';
import { Color } from 'src/styles/Constants';
import { SelectOption } from 'src/util/ConfigTypes';
import { regexpValidation } from 'src/util/validation';
import InputLabel from '../../ui/InputLabel';
import HelpLinks from '../../ui/HelpLinks';
import { ValidationError } from 'src/util/validation';

interface Props {
    field: string;
    error?: Error;
    onChange?: (value: any) => void;
    label?: string;
    defaultValue?: string;
    selectOptions: Array<SelectOption<string>>;
    onContactSupportClick: () => void;
    validation?: RegExp;
    validationErrorText?: string;
    onError?: (error: ValidationError) => void;
    onClearError?: (field: string) => void; // clear upper accumulator for errors by its field identification
}

export default ({
    field,
    onChange,
    error,
    label,
    defaultValue,
    onContactSupportClick,
    selectOptions,
    validation,
    validationErrorText,
    onError,
    onClearError,
}: Props): any => {
    const [pick, setPick] = useState<string>('');

    useEffect(() => {
        if (defaultValue) {
            change(defaultValue);
        }
    }, []);

    const backgroundColor = Color.P1_WHITE;

    let borderColor = error ? Color.S13_MAROON : Color.T34_SQUIRTLE;

    const validate = (value: string | undefined): void => {
        if (regexpValidation({ value, validationRegexp: validation })) {
            onClearError && onClearError(field);
        } else {
            const error = new ValidationError({
                message: validationErrorText || 'Invalid input',
                field: field,
            });
            onError && onError(error);
        }
    };

    const change = (value: string) => {
        setPick(value);
        validate(value);
        onChange && onChange(value);
    };

    return (
        <>
            <InputLabel label={label} error={error} />
            {error && (
                <HelpLinks onContactSupportClick={onContactSupportClick} />
            )}
            <InputContainer
                backgroundColor={backgroundColor}
                borderColor={borderColor}
                showError={!!error}
            >
                <StyledSelect
                    value={pick}
                    onChange={(event: ChangeEvent<any>) =>
                        change(event.target.value)
                    }
                >
                    {selectOptions.map((option: SelectOption<string>) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </StyledSelect>
            </InputContainer>
        </>
    );
};

const errorBounce = keyframes`
    from, 80%, to {
        transform: translate3d(0,0,0);
    }

    20% {
        transform: translate3d(-7px, 0, 0);
    }

    40% {
        transform: translate3d(7px, 0, 0);
    }

    60% {
        transform: translate3d(-3px,0,0);
    }

    70% {
        transform: translate3d(3px,0,0);
    }
`;

const InputContainer = styled.div<{
    backgroundColor: Color;
    borderColor: Color;
    showError: boolean;
}>`
    margin: 10px 0 0 0;
    font-family: Graphik Web;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.backgroundColor};
    width: 100%;
    border: 1px solid ${(props) => props.borderColor};
    animation: ${(props) =>
        props.showError ? errorBounce : undefined} 0.3s ease;
    transition 0.3s all;
`;

const StyledSelect = styled(Select)`
    display: flex;
    flex: 1;
    color: ${(props) => props.color};
    background-color: transparent;
    font-size: 14px;
    border: 0px;
    height: 45px;
    outline: none;
    padding: 0 3px 0 15px;
    text-align: left;
`;
