import React from 'react';
import styled from '@emotion/styled';

import { useStatus } from './hooks';
import { TextStyles, TextStyleTypes } from 'src/styles/Constants';
import { IsoDateString } from 'src/util/SponsorshipTypes';

const Status = ({
    pending,
    expirationDate,
}: {
    pending?: boolean;
    expirationDate: IsoDateString | Date;
}) => {
    const { color, iconSrc, statusText } = useStatus({
        pending,
        expirationDate,
    });

    return (
        <StatusContainer>
            {iconSrc && <StatusIcon src={iconSrc} />}
            <StatusText color={color}>{statusText}</StatusText>
        </StatusContainer>
    );
};

const STATUS_ICON_NEGATIVE_MARGIN = '-4px';

const StatusContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const StatusIcon = styled.img`
    margin-left: ${STATUS_ICON_NEGATIVE_MARGIN};
`;

const StatusText = styled.p<{ color: string }>`
    color: ${(props) => props.color};
    ${TextStyles[TextStyleTypes.S3]};
`;

export default Status;
