import React from 'react';

import { TransitionGroup } from 'react-transition-group';
import classnames from 'classnames';

type Props = {
    children: React.ReactNode;
    className?: string;
};

const List = ({ children, className }: Props) => {
    return (
        <TransitionGroup
            component="div"
            className={classnames('transition-group-list', className)}
            appear
        >
            {children}
        </TransitionGroup>
    );
};

export default List;
