import React from 'react';
import { FormattedMessage } from 'react-intl';

export enum TimeUntilLockMode {
    short = 'short',
    medium = 'medium',
    long = 'long',
}

import {
    getDateString,
    getShortWeekday,
    getTimeString,
    getLongWeekday,
    getDaysBetween,
    MILLIS_IN_HOUR,
    getCountdownString,
    DEFAULT_HIGHLIGHT_HOURS,
} from 'src/util/Dates';

type LockTextProps = {
    mode: TimeUntilLockMode;
    countdownDate: Date;
    countdownSeconds: number;
    highlightHours?: number;
};

const LockText = ({
    countdownDate,
    countdownSeconds,
    highlightHours,
    mode,
}: LockTextProps) => {
    const now = new Date();

    const daysUntilStart = getDaysBetween(now, countdownDate);
    const timeString = getTimeString(countdownDate);

    if (daysUntilStart === 1) {
        if (mode === TimeUntilLockMode.short) {
            return (
                <FormattedMessage id="timeUntilLock.timeUntilLockMode.short" />
            );
        }
        if (mode === TimeUntilLockMode.medium) {
            return (
                <FormattedMessage
                    id="timeUntilLock.timeUntilLockMode.medium"
                    values={{ timeString }}
                />
            );
        }
        return (
            <FormattedMessage
                id="timeUntilLock.timeUntilLockMode.fallback"
                values={{ timeString }}
            />
        );
    }
    if (daysUntilStart === 0) {
        const hoursUntilStart =
            (countdownDate.getTime() - now.getTime()) / MILLIS_IN_HOUR;

        if (hoursUntilStart < (highlightHours || DEFAULT_HIGHLIGHT_HOURS)) {
            return <>{getCountdownString(countdownSeconds)}</>;
        }
        return <>{timeString}</>;
    }

    if (daysUntilStart <= 6) {
        if (mode === TimeUntilLockMode.short) {
            return (
                <FormattedMessage
                    id="timeUntilLock.dateType.daysUntil"
                    values={{
                        daysUntilStart,
                    }}
                />
            );
        }
        if (mode === TimeUntilLockMode.medium) {
            return (
                <>
                    <FormattedMessage id={getShortWeekday(countdownDate)} />{' '}
                    {timeString}
                </>
            );
        }
        return (
            <>
                <FormattedMessage id={getLongWeekday(countdownDate)} />
                {timeString}
            </>
        );
    }

    return <>{getDateString(countdownDate)}</>;
};

export default LockText;
