import styled from '@emotion/styled';
import React, { Component, ContextType } from 'react';
import { Color, StyleDefaults } from 'src/styles/Constants';
import {
    FeedLeaderboardEntry,
    isNormalEntry,
    SpecialFeedLeaderboardEntry,
} from 'src/services/ServerApi';
import { LocaleContext } from 'src/hooks/useLocale';

interface Props {
    playerCount: number;
    validatedQuestionCount: number;
    entry: FeedLeaderboardEntry | SpecialFeedLeaderboardEntry;
}

const MIN_ANSWERED_FOR_PERCENT = 5;
const DASH = '—';
const TIED_COLOR = '#5D5D5D';
const MARGIN_RIGHT = '18px';
const MAX_TIED_PLAYERS = 3;
const NORMAL_PADDING = '3px 0';

const defaultStyle = {
    align: 'left',
    color: Color.T31_BLACK,
    fontWeight: 'inherit',
    marginRight: MARGIN_RIGHT,
    padding: NORMAL_PADDING,
};

const normalRankStyle = {
    ...defaultStyle,
    fontWeight: 'bold',
    width: '70px',
};

const specialRankStyle = {
    ...defaultStyle,
    color: Color.P6_ELECTRIC_BLUE,
    fontWeight: 'bold',
    width: '70px',
};

const specialNameStyle = {
    ...defaultStyle,
    flexVal: 2,
    fontWeight: 'bold',
};

const normalNameStyle = {
    ...defaultStyle,
    flexVal: 2,
    width: '240px',
};

const tiedNameStyle = {
    ...defaultStyle,
    color: TIED_COLOR,
    flexVal: 2,
    fontWeight: 'bold',
};

const pointsStyle = {
    ...defaultStyle,
    flexVal: 1,
    width: '140px',
};

const numberCorrectStyle = {
    ...defaultStyle,
    align: 'center',
    width: '70px',
};

const percentRankStyle = {
    ...defaultStyle,
    align: 'center',
    marginRight: '0px',
    width: '105px',
};

export default class BroadcastLeaderboardRow extends Component<Props> {
    public static contextType = LocaleContext;
    public context!: ContextType<typeof LocaleContext>;

    private getPercentRank = (rank: number): string => {
        const { playerCount, validatedQuestionCount } = this.props;
        if (validatedQuestionCount >= MIN_ANSWERED_FOR_PERCENT) {
            const percentile = ((rank / playerCount) * 100).toFixed(1);
            return `${percentile}%`;
        } else {
            return DASH;
        }
    };

    // Renders special users from query params.
    private renderSpecialRow = (entry: SpecialFeedLeaderboardEntry) => {
        const { localeId } = this.context;
        const { correctAnswers, displayName, points, rank } = entry;
        const percentString = this.getPercentRank(rank);

        return (
            <StatsRow key={displayName}>
                <StatValue
                    style={specialRankStyle}
                    text={rank.toLocaleString(localeId)}
                />
                <StatValue style={specialNameStyle} text={displayName} />
                <StatValue
                    style={pointsStyle}
                    text={points.toLocaleString(localeId)}
                />
                <StatValue
                    style={numberCorrectStyle}
                    text={correctAnswers.toLocaleString(localeId)}
                />
                <StatValue style={percentRankStyle} text={percentString} />
            </StatsRow>
        );
    };

    // Renders normal, not tied, user
    private renderNormalRow = (entry: FeedLeaderboardEntry) => {
        const { localeId } = this.context;
        const { correctAnswers, players, points, rank } = entry;
        const percentString = this.getPercentRank(rank);
        const playerName = players[0];
        const hideTopBorder = rank === 1;

        return (
            <StatsRow key={playerName}>
                <StatValue
                    hideBorder={hideTopBorder}
                    style={normalRankStyle}
                    text={rank.toLocaleString(localeId)}
                />
                <StatValue
                    hideBorder={hideTopBorder}
                    style={normalNameStyle}
                    text={playerName}
                />
                <StatValue
                    hideBorder={hideTopBorder}
                    style={pointsStyle}
                    text={points.toLocaleString(localeId)}
                />
                <StatValue
                    hideBorder={hideTopBorder}
                    style={numberCorrectStyle}
                    text={correctAnswers.toLocaleString(localeId)}
                />
                <StatValue
                    hideBorder={hideTopBorder}
                    style={percentRankStyle}
                    text={percentString}
                />
            </StatsRow>
        );
    };

    // Renders row that has all of the stats except instead of player name says X Players tied
    private renderTiedRow = (entry: FeedLeaderboardEntry) => {
        const { localeId } = this.context;
        const { correctAnswers, players, points, rank } = entry;
        const percentString = this.getPercentRank(rank);
        const hideTopBorder = rank === 1;
        // Use player.length instead of playerCount because playerCount does not map to the length of the players array.
        const playersTiedText = `${players.length} Players Tied`;

        return (
            <StatsRow key={rank}>
                <StatValue
                    hideBorder={hideTopBorder}
                    style={normalRankStyle}
                    text={rank.toLocaleString(localeId)}
                />
                <StatValue
                    hideBorder={hideTopBorder}
                    style={tiedNameStyle}
                    text={playersTiedText}
                />
                <StatValue
                    hideBorder={hideTopBorder}
                    style={pointsStyle}
                    text={points.toLocaleString(localeId)}
                />
                <StatValue
                    hideBorder={hideTopBorder}
                    style={numberCorrectStyle}
                    text={correctAnswers.toLocaleString(localeId)}
                />
                <StatValue
                    hideBorder={hideTopBorder}
                    style={percentRankStyle}
                    text={percentString}
                />
            </StatsRow>
        );
    };

    // Renders 3 tied players' name
    private renderNameOnlyRow = (entry: FeedLeaderboardEntry) => {
        const { players } = entry;
        const playersTouse = players.slice(0, MAX_TIED_PLAYERS);
        if (players.length > MAX_TIED_PLAYERS) {
            playersTouse.push('...');
        }

        return playersTouse.map((player, index) => (
            <StatsRow key={player}>
                <StatValue
                    hideBorder={true}
                    style={normalRankStyle}
                    text={''}
                />
                <StatValue
                    hideBorder={true}
                    style={normalNameStyle}
                    text={player}
                />
                <StatValue hideBorder={true} style={pointsStyle} text={''} />
                <StatValue
                    hideBorder={true}
                    style={numberCorrectStyle}
                    text={''}
                />
                <StatValue
                    hideBorder={true}
                    style={percentRankStyle}
                    text={''}
                />
            </StatsRow>
        ));
    };

    public render() {
        const { entry } = this.props;

        if (isNormalEntry(entry)) {
            const numberPlayers = entry.playerCount;
            return (
                <>
                    {numberPlayers > 1 && this.renderTiedRow(entry)}
                    {numberPlayers > 1 && this.renderNameOnlyRow(entry)}
                    {numberPlayers === 1 && this.renderNormalRow(entry)}
                </>
            );
        } else {
            return this.renderSpecialRow(entry);
        }
    }
}

const StatsRow = styled.div`
    display: flex;
    justify-content: space-between;
    text-align: left;
    width: 100%;
`;

const StatValue = (props: {
    text: string;
    style: {
        align: string;
        color: string;
        fontWeight: string;
        marginRight: string;
        padding: string;
        flexVal?: number;
        width?: string;
    };
    hideBorder?: boolean;
}) => (
    <StatRowContainer
        align={props.style.align}
        marginRight={props.style.marginRight}
        flexVal={props.style.flexVal}
        width={props.style.width}
    >
        {!props.hideBorder && <DataBorder />}

        <UserStat
            color={props.style.color}
            fontWeight={props.style.fontWeight}
            padding={props.style.padding}
        >
            {props.text}
        </UserStat>
    </StatRowContainer>
);

const StatRowContainer = styled.div<{
    align?: string;
    marginRight?: string;
    flexVal?: number;
    width?: string;
}>`
    display: flex;
    flex-direction: column;
    min-width: ${(props) => props.width};
    flex: ${(props) => props.flexVal};
    margin-right: ${(props) => props.marginRight};
    text-align: ${(props) => props.align};
`;

const UserStat = styled.p<{
    padding: string;
    color?: string;
    fontWeight?: string;
}>`
    color: ${(props) => (props.color ? props.color : '#000000')};
    font-family: ${StyleDefaults.FONT_FAMILY};
    font-size: 30px;
    font-weight: ${(props) => props.fontWeight};
    letter-spacing: -0.5px;
    padding: ${(props) => props.padding};
`;

const DataBorder = styled.div`
    height: 1px;
    background-color: #9b9b9b;
    margin: 12px 0px;
`;
