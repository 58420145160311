import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import SeesawAnimation from 'src/components/shared/animations/SeesawAnimation';
import TouchButton from 'src/components/shared/controls/TouchButton';
import { Color } from 'src/styles/Constants';

interface Props {
    showPrizeDetails?: () => void;
}

export default class LeaderboardDeadstate extends Component<Props> {
    public render() {
        const { showPrizeDetails } = this.props;
        return (
            <WaitingContainer>
                <SubHead>
                    <FormattedMessage id="leaderboardDeadstate.waitingSubHead" />
                </SubHead>

                <SeesawAnimation />
                <WaitingLabel>
                    <FormattedMessage id="leaderboardDeadstate.waitingLabel" />
                </WaitingLabel>
                {showPrizeDetails && (
                    <TouchButton
                        backgroundColor={Color.TRANSPARENT}
                        textColor={Color.P2_DARK_OLIVE}
                        displayText={
                            <FormattedMessage id="leaderboardDeadstate.buttonText" />
                        }
                        onClick={showPrizeDetails}
                        stretchWidth={true}
                        underline={true}
                    />
                )}
            </WaitingContainer>
        );
    }
}

const WaitingContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
    align-items: center;
`;

const SubHead = styled.div`
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: Graphik Web;
    color: ${Color.T30_CHARCOAL};
`;

const WaitingLabel = styled.div`
    margin 10px 0;
    color: ${Color.T30_CHARCOAL};
    font-family: Graphik Web;
    font-size: 12px;
    font-style: italic;
    line-height: 18px;
    text-align: center;
`;
