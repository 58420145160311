import React, { Component, ReactNode } from 'react';
import styled from '@emotion/styled';

import LoadingCheckmark from './LoadingCheckmark';

export enum IndicatorState {
    HIDDEN = 'HIDDEN',
    LOADING = 'LOADING',
    CONFIRMATION = 'CONFIRMATION',
}

interface Props {
    // This determines what state the indicator should be in.
    // Note that when the state is set to IndicatorState.CONFIRMATION, after a short period of time
    // this component will hide itself. So callers don't need to worry about hiding
    // the checkmark and themselves.
    state: IndicatorState;
}

interface State {
    confirmationAnimationComplete: boolean;
}

export default class SubmitStatusIndicator extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            confirmationAnimationComplete: false,
        };
    }

    public componentDidUpdate(prevProps: Props) {
        // We need to reset confirmationAnimationComplete if we are potentially going to do the
        // confirmation animation again.
        if (
            prevProps.state === IndicatorState.CONFIRMATION &&
            this.props.state !== IndicatorState.CONFIRMATION
        ) {
            this.setState({
                confirmationAnimationComplete: false,
            });
        }
    }

    private onCheckmarkAnimationComplete = () => {
        // Confirmation animation is done. Hide this component.
        this.setState({ confirmationAnimationComplete: true });
    };

    public render(): ReactNode | null {
        const { state } = this.props;
        const { confirmationAnimationComplete } = this.state;

        // This component should be completely hidden if the state is 'hidden',
        // or if we are in the confirmation state and the confirmation animation has completed.
        if (
            state === IndicatorState.HIDDEN ||
            (state === IndicatorState.CONFIRMATION &&
                confirmationAnimationComplete)
        ) {
            return null;
        }

        return (
            <LoadingContainer>
                <LoadingCheckmarkContainer>
                    <LoadingCheckmark
                        onCheckmarkAnimationComplete={
                            this.onCheckmarkAnimationComplete
                        }
                        showCheck={state === IndicatorState.CONFIRMATION}
                        sizePixels={18}
                    />
                </LoadingCheckmarkContainer>
            </LoadingContainer>
        );
    }
}

const LoadingContainer = styled.div`
    display: flex;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
`;

const LoadingCheckmarkContainer = styled.div`
    margin: auto 10px auto auto;
`;
