import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import { SponsorModel } from 'src/util/TallyFirestore';
import { Color, TextStyles, TextStyleTypes } from 'src/styles/Constants';

interface Props {
    bonusSponsors?: SponsorModel[];
}

const BonusSponsors = ({ bonusSponsors }: Props) =>
    bonusSponsors && bonusSponsors.length > 0 ? (
        <Container>
            <ThankYouLabel>
                <FormattedMessage id="bonusSponsors.thankYouLabel" />
            </ThankYouLabel>
            <SponsorContainer>
                {bonusSponsors.map(({ id, logoUrl }) => (
                    <BonusSponsor key={id} src={logoUrl} />
                ))}
            </SponsorContainer>
        </Container>
    ) : null;

export default BonusSponsors;

const BonusSponsor = styled.img`
    height: 30px;
    padding: 6px;
`;

const SponsorContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 10px 0;
`;

const ThankYouLabel = styled.div`
    text-transform: uppercase;
    margin: 0 6px 0 0;
    ${TextStyles[TextStyleTypes.S3]};
    color: ${Color.G1_BLACK};
`;
