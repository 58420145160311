import styled from '@emotion/styled';
import { css } from 'emotion';
import React from 'react';

import checkmark from 'src/images/icons/Picks-Correct-M@3x.svg';
import wrongmark from 'src/images/icons/Picks-Wrong-M@3x.svg';

import {
    ANSWER_CORRECTNESS_COLORS,
    Color,
    TextStyles,
    TextStyleTypes,
} from 'src/styles/Constants';

const MIN_OPTION_SIZE = 100 as const;
const MAX_OPTION_SIZE = 140 as const;

export const styles = {
    activeContainer: {
        '&:active': {
            opacity: 0.4,
        },
    },
    container: {
        position: 'relative' as const,
        transition: 'border-color 0.3s',
        width: 'calc(50% - 20px)',
        minWidth: `${MIN_OPTION_SIZE}px`,
        maxWidth: `${MAX_OPTION_SIZE}px`,
        borderWidth: 5,
        borderRadius: 2,
        borderStyle: 'solid',
    },
};

function getOptionBorderColor(props: Props): string {
    if (props.type === 'answered') {
        if (props.correct) {
            return ANSWER_CORRECTNESS_COLORS.correct;
        }

        if (props.picked) {
            return ANSWER_CORRECTNESS_COLORS.incorrect;
        }
    }

    if (props.type === 'answerLoading' && props.picked) {
        return 'initial';
    }

    return 'transparent';
}

type BasicProps = {
    imageUrl: string;
    text: string | null;
};

type AnswerableProps = BasicProps & {
    type: 'answerable';
    onSelect: () => void;
};

type AnswerLoadingProps = BasicProps & {
    type: 'answerLoading';
    picked: boolean;
};

type Answered = {
    type: 'answered';
    picked: boolean;
    correct: boolean;
};

type AnsweredProps = BasicProps & Answered;

type Props = AnswerableProps | AnswerLoadingProps | AnsweredProps;

const ImageOption = (props: Props) => {
    const clickable = props.type === 'answerable';
    return (
        <div
            className={css([
                styles.container,
                clickable && styles.activeContainer,
                props.text && { padding: 5 },
                {
                    cursor: clickable ? 'pointer' : 'auto',
                },
                {
                    borderColor: getOptionBorderColor(props),
                },
            ])}
            {...{
                onClick:
                    props.type === 'answerable' ? props.onSelect : undefined,
            }}
        >
            <OptionImage
                src={props.imageUrl}
                height={MIN_OPTION_SIZE}
                width={MIN_OPTION_SIZE}
                alt={props.text || undefined}
            />
            {props.text && <OptionText>{props.text}</OptionText>}
            <IndicatorContainer>
                {props.type === 'answered' && props.picked && (
                    <ResolvedIcon src={props.correct ? checkmark : wrongmark} />
                )}
            </IndicatorContainer>
        </div>
    );
};

export default ImageOption;

const IndicatorContainer = styled.div`
    position: absolute;
    top: 7px;
    right: 7px;
`;

const ResolvedIcon = styled.img`
    height: 25px;
    width: 25px;
`;

const OptionText = styled.div`
    ${TextStyles[TextStyleTypes.P4]}
`;

const OptionImage = styled.img`
    width: 100%;
    height: auto;
`;
