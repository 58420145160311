import styled from '@emotion/styled';
import { keyframes } from 'emotion';
import React, { Component, createRef } from 'react';
import { Color } from 'src/styles/Constants';

const DEFAULT_ANIMATION_LENGTH = 0.8;

interface Props {
    animationLength?: number;
    onAnimationEnd?: () => void;
    color?: string;
}

interface State {
    animationIsRunning: boolean;
    cancelAnimation: boolean;
}

export default class SquareLoading extends Component<Props, State> {
    private startingSquare = createRef<HTMLDivElement>();
    private endingSquare = createRef<HTMLDivElement>();

    constructor(props: any) {
        super(props);

        this.state = {
            animationIsRunning: true,
            cancelAnimation: false,
        };
    }

    public componentDidMount = () => {
        if (this.startingSquare.current) {
            this.startingSquare.current.addEventListener(
                'animationiteration',
                this.onNextIteration,
            );
        }
    };

    public cancelAnimation = () => {
        this.setState({
            cancelAnimation: true,
        });
    };

    private onAnimationCancelled = () => {
        const { onAnimationEnd } = this.props;

        if (onAnimationEnd) {
            onAnimationEnd();
        }
    };

    public onNextIteration = () => {
        const { cancelAnimation } = this.state;
        if (cancelAnimation) {
            this.setState({
                animationIsRunning: false,
                cancelAnimation: false,
            });

            this.onAnimationCancelled();
        }
    };

    public render = () => {
        const { animationLength, color } = this.props;
        const { animationIsRunning } = this.state;

        let length = animationLength
            ? animationLength
            : DEFAULT_ANIMATION_LENGTH;
        if (!animationIsRunning) {
            // if animation is canceling then set to 0;
            length = 0;
        }
        const delay = length / 8.0;

        return (
            <Container>
                <Row>
                    <Square
                        color={color}
                        ref={this.startingSquare}
                        animationLength={length}
                    />
                    <Square
                        color={color}
                        animationLength={length}
                        animationDelay={delay}
                    />
                </Row>
                <Row>
                    <Square
                        color={color}
                        ref={this.endingSquare}
                        animationLength={length}
                        animationDelay={delay * 3}
                    />
                    <Square
                        color={color}
                        animationLength={length}
                        animationDelay={delay * 2}
                    />
                </Row>
            </Container>
        );
    };
}

const transform = keyframes`
    0%, 75%, 100% {
        transform-origin: center center;
        transform: scale(0.0)
    }
    25%, 50% {
        transform-origin: center center;
        transform: scale(1.0)
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 20px;
    height: 20px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
`;

const Square = styled.div<{
    animationLength: number;
    animationDelay?: number;
    color?: string;
}>`
    display: flex;
    flex: 1;
    margin: 1px;
    transform: scale(0, 0);
    background-color: ${(props) =>
        props.color ? props.color : Color.T30_CHARCOAL};
    animation: ${transform} ${(props) => props.animationLength}s ease infinite;
    animation-delay: ${(props) => props.animationDelay}s;
`;
