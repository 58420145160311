import styled from '@emotion/styled';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { HeaderText, WidthRestrictor } from './shared';

const SeeYouNextTime = () => {
    return (
        <WidthRestrictor>
            <HeaderText>
                <FormattedMessage id="waitingRoom.gameOver" />
            </HeaderText>
            {/* TODO: come up with the general approach (TP-460) */}
            <Description>See you next time!</Description>
        </WidthRestrictor>
    );
};

export default SeeYouNextTime;

const Description = styled.div`
    align-items: center;
    font-size: 20px;
    justify-content: center;
    text-align: center;
    margin-top: 14px;
`;
