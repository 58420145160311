export enum Color {
    // Primary
    P1_WHITE = '#ffffff',
    P2_DARK_OLIVE = '#001804',
    P3_SEAFOAM = '#65ffce',
    P4_CHILI_PEPPER = '#ff3524',
    P5_MIDNIGHT = '#000e3f',
    P6_ELECTRIC_BLUE = '#1818ff',

    // Secondary
    S7_BLUSH = '#ffc5f3',
    S8_BUBBLEGUM = '#ff2fd3',
    S9_FUCHSIA = '#b3008d',
    S10_PEACHY = '#ffb5b5',
    S11_CORAL = '#ff5d45',
    S12_VARSITY_RED = '#aa0d00',
    S13_MAROON = '#680a0a',
    S14_MINT = '#acffe5',
    S15_DEEP_TEAL = '#58ddb4',
    S16_FOREST = '#00422d',
    S17_SKY_BLUE = '#82f2ff',
    S18_BRIGHT_CYAN = '#19cfe4',
    S19_TURQUOISE = '#008998',
    S20_DEEP_SEA = '#00343a',
    S21_MISTY_BLUE = '#d4f8ff',
    S22_ROYAL_BLUE = '#000089',

    // Tertiary
    T23_CANARY = '#ffe657',
    T24_LIGHT_TEAL = '#7bffce',
    T25_BRIGHT_TEAL = '#2af0b2',
    T26_MARBLE = '#f6f6f6',
    T27_CONCRETE = '#efefef',
    T28_FOG = '#e5e5e5',
    T29_STONE = '#b1b1b1',
    T30_CHARCOAL = '#6e6e6e',
    T31_BLACK = '#000000',
    T32_VIKING_ACCESSIBLE = '#01baa2',
    T33_BACHELOR_PINK = '#ee19c0',
    T34_SQUIRTLE = '#72e7ff',
    T35_ALMOST_BLACK = '#1f1f1f',
    T36_SQUIRTLE_ACCESSIBLE = '#41DFFF',

    G1_BLACK = '#000000',
    G2_CARBON_FIBER = '#191919',
    G3_ASPHALT = '#292828',
    G4_CHARCOAL = '#6e6e6e',
    G5_GRAPHITE = '#999999',
    G6_STONE = '#b1b1b1',
    G7_FOG = '#e5e5e5',
    G8_CONCRETE = '#efefef',
    G9_MARBLE = '#f6f6f6',
    G10_WHITISH = '#fafafa',
    G11_WHITE = '#ffffff',
    NAVY_BLUE = '#0B2340',

    PREDICTION_BLUE = '#1818ff',
    PREDICTION_GREY_93 = '#939393',
    PREDICTION_GREY_A3 = '#a3a3a3',
    PREDICTION_GREEN = '#00e4c7',
    PREDICTION_GREEN_DARK = '#01BAA2',

    TRANSPARENT = 'transparent',
    TRANSLUCENT = 'translucent',
}

export enum TallyUrls {
    WEBSITE = 'https://playtally.com',
    HELP = 'https://www.playtally.com/help/main',
    TWITTER = 'https://twitter.com/playtallyapp',
    FACEBOOK = 'https://www.facebook.com/playtallyapp',
    INSTAGRAM = 'https://www.instagram.com/playtallyapp',
}

export enum StyleDefaults {
    // Standard distance between the edge of the screen to the outermost container/content.
    OUTER_HORIZONTAL_PADDING = '20px',
    OUTER_VERTICAL_PADDING = '20px',

    MODAL_BODY_PADDING = '0 20px 20px 20px',

    // Standard distance between the edge of a container to its content.
    INNER_HORIZONTAL_PADDING = '10px',
    INNER_VERTICAL_PADDING = '10px',

    FONT_FAMILY = "'Graphik Web', 'Arial'",

    MAX_ELEMENT_WIDTH = 'min( 500px, 95%)',
    ELEMENT_WIDTH = '92%',

    PAGE_PADDING = '40px 0 80px 0',
    PAGE_PADDING_TOP = '40px',
    PAGE_PADDING_BOTTOM = '80px',
}

// Generic enum for components that want to implement a dark / light mode;
export enum ColorMode {
    LIGHT = 'LIGHT',
    DARK = 'DARK',
}

export enum Orientation {
    VERTICAL = 'column',
    HORIZONTAL = 'row',
}

export enum Alignment {
    LEFT = 'flex-start',
    RIGHT = 'flex-end',
    CENTER = 'center',
}

export const Height = {
    TITLEBAR: 60,
    COUNTDOWN_HEADER: 30,
};

// To help keep track of our z-indexing.
export const PistachioZIndex = {
    // This should be super high. This shows up on top of all modals.
    NEW_PREDICTION_NOTICE: 100,

    // Modals are next highest.
    MODAL_HOSTER_MODAL: 80, //37
    MODAL_HOSTER_FULL_SCREEN: 75, //36

    // Modal screens show up on top of the predict page, but must have
    // a z-index lower than modals so that modals can show up on top of modal screens.
    MODAL_SCREEN: 70, //35

    // Must be less than modals and modal screens, but higher than the predict page
    // so that the sticky header shows on top of the predict page.
    STICKY_HEADER: 50,
    STICKY_BOTTOM_NAV: 51,
    PREDICTION_ANIMATION_BODY: 20,
    PREDICTION_ANIMATION_BACKGROUND: 19,

    TITLEBAR_EDGE_ELEMENTS: 1,
    WAITING_ROOM_STICKY_HEADER: 1,

    LEADERBOARD_SWITCH_LINK: 1,

    INFORMATION_MODULE: 1,

    BACKGROUND_DIV: -1,
};

// consult for visuals
// https://traceme.invisionapp.com/d/main/#/console/18099204/375576003/preview;
export enum TextStyleTypes {
    H1 = 'H1',
    H2 = 'H2',
    H3 = 'H3',
    S1 = 'S1',
    S2 = 'S2',
    S3 = 'S3',
    S4 = 'S4',
    P1 = 'P1',
    P2 = 'P2',
    P3 = 'P3',
    P4 = 'P4',
    C1 = 'C1',
    C2 = 'C2',
    C3 = 'C3',
    B1 = 'B1',
    B2 = 'B2',
}

type TextStyles = { [index: string]: string };
export const TextStyles: TextStyles = {
    [TextStyleTypes.H1]: `
        font-size: 60px;
        letter-spacing: -1.03px;
        line-height: 42px;
    `,
    [TextStyleTypes.H2]: `
        font-size: 35px;
        letter-spacing: -0.6px;
        line-height: 37px;
    `,
    [TextStyleTypes.H3]: `
        font-size: 24px;
        font-weight: 300;
        letter-spacing: -0.13px;
        line-height: 32px;
    `,
    [TextStyleTypes.S1]: `
        font-size: 24px;
        font-weight: 700;
        letter-spacing: -0.31px;
        line-height: 28px;
    `,
    [TextStyleTypes.S2]: `
        font-size: 19px;
        font-weight: 300;
        letter-spacing: -0.31px;
        line-height: 22px;
    `,
    [TextStyleTypes.S3]: `
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 22px;
        text-transform: uppercase;
    `,
    [TextStyleTypes.S4]: `
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 1px;
        line-height: 18px;
        text-transform: uppercase;
    `,
    [TextStyleTypes.P1]: `
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 18px;
    `,
    // Same as P1. Design has it color dependent so i kept it the same here.
    [TextStyleTypes.P2]: `
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 18px;
    `,
    [TextStyleTypes.P3]: `
        font-size: 15px;
        font-weight: 500;
        letter-spacing: -0.08px;
        line-height: 21px;
    `,
    [TextStyleTypes.P4]: `
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.5px;
        line-height: 18px;
    `,
    [TextStyleTypes.C1]: `
        font-size: 12px;
        letter-spacing: 0.2px;
        line-height: 16px;
    `,
    [TextStyleTypes.C2]: `
        font-size: 12px;
        font-style: italic;
        letter-spacing: 0.2px;
        line-height: 16px;
    `,
    [TextStyleTypes.C3]: `
        font-size: 11px;
        line-height: 16px;
    `,
    [TextStyleTypes.B1]: `
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 1.4px;
        line-height: 18px;
        text-transform: uppercase;
    `,
    [TextStyleTypes.B2]: `
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 18px;
        text-transform: uppercase;
    `,
};

export const ANSWER_CORRECTNESS_COLORS = {
    correct: Color.PREDICTION_GREEN,
    incorrect: Color.P4_CHILI_PEPPER,
} as const;

export const LOCK_TIME_TOKEN = '{LOCK_TIME}';
export const ANSWER_MILESTONE_TOKEN = '{ANSWER_MILESTONE}';

export const TOOLTIP_VISIBLE_DURATION = 2000;

export const TRANSITION_ANIMATION_TIME = 300;

export const BOTTOM_NAV_HEIGHT = 56;
