import { createContext, useContext } from 'react';
import { TranslatedIteration } from 'src/util/i18n';

const IterationContext = createContext<TranslatedIteration>({} as any);
export const IterationProvider = IterationContext.Provider;
export const IterationConsumer = IterationContext.Consumer;

const useIteration = (): TranslatedIteration => {
    const iteration = useContext(IterationContext);

    return iteration;
};

export default useIteration;
