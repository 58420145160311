import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import config from 'src/util/Config';

import ServerApi from 'src/services/ServerApi';
import useTriviaStateReducer, {
    TriviaGameState,
} from './useTriviaStateReducer';
import { NetworkError } from 'src/util/errors/NetworkError';
import { TriviaGameProperties } from 'src/util/TallyFirestore';
import { useLocale } from 'src/hooks/useLocale';

const { partnerId } = config.partnerData;

const useTriviaGame = (
    eventId: string,
    trivia: TriviaGameProperties,
): {
    state: TriviaGameState;
    start: () => void;
    next: () => void;
    answer: ({
        questionId,
        optionId,
    }: {
        questionId: string;
        optionId: string | null;
    }) => void;
} => {
    const intl = useIntl();
    const { localeId: languageId } = useLocale();

    const languageIdRef = useRef(languageId);
    languageIdRef.current = languageId;

    const [state, dispatch] = useTriviaStateReducer();

    useEffect(() => {
        if (state.id === 'initialLoading') {
            ServerApi.getTriviaState({
                partnerId,
                eventId,
                languageId: languageIdRef.current,
            })
                .then((response) => {
                    dispatch({
                        type: 'initialLoadingSuccess',
                        data: response,
                    });
                })
                .catch((error) => {
                    let errorMessage: string;
                    if (error instanceof NetworkError) {
                        errorMessage = intl.formatMessage({
                            id: 'error.failedToFetch',
                        });
                    } else {
                        errorMessage = error.message;
                    }
                    dispatch({
                        type: 'initialLoadingFailure',
                        data: { errorMessage },
                    });
                });
        } else if (state.id === 'nextLoading') {
            ServerApi.loadNextTriviaQuestion({
                partnerId,
                eventId,
                languageId: languageIdRef.current,
            })
                .then((response) => {
                    dispatch({
                        type: 'nextLoadingSuccess',
                        data: { current: response },
                    });
                })
                .catch((error) => {
                    let errorMessage: string;
                    if (error instanceof NetworkError) {
                        errorMessage = intl.formatMessage({
                            id: 'error.failedToFetch',
                        });
                    } else {
                        errorMessage = error.message;
                    }

                    dispatch({
                        type: 'nextLoadingFailure',
                        data: {
                            errorMessage,
                        },
                    });
                });
        } else if (state.id === 'assignLoading') {
            ServerApi.assignEventTriviaQuestions({
                partnerId,
                eventId,
                languageId: languageIdRef.current,
            })
                .then((response) => {
                    const { current, numberOfQuestions } = response;
                    if (current) {
                        dispatch({
                            type: 'assignLoadingSuccess',
                            data: { current, numberOfQuestions },
                        });
                    } else {
                        dispatch({
                            type: 'assignLoadingFailure',
                            data: {
                                errorMessage:
                                    'There are no trivia questions for this event.',
                            },
                        });
                    }
                })
                .catch((error) => {
                    let errorMessage: string;
                    if (error instanceof NetworkError) {
                        errorMessage = intl.formatMessage({
                            id: 'error.failedToFetch',
                        });
                    } else {
                        errorMessage = error.message;
                    }

                    dispatch({
                        type: 'assignLoadingFailure',
                        data: {
                            errorMessage,
                        },
                    });
                });
        } else if (state.id === 'answerLoading') {
            ServerApi.answerTriviaQuestion({
                partnerId,
                eventId,
                questionId: state.current.id,
                selectedOptionId: state.selectedOptionId,
            })
                .then((data) => {
                    dispatch({
                        type: 'answerLoadingSuccess',
                        data,
                    });
                })
                .catch((error) => {
                    let errorMessage: string;
                    if (error instanceof NetworkError) {
                        errorMessage = intl.formatMessage({
                            id: 'error.failedToFetch',
                        });
                    } else {
                        errorMessage = error.message;
                    }
                    dispatch({
                        type: 'answerLoadingFailure',
                        data: { errorMessage },
                    });
                });
        }
    }, [state.id, dispatch]);

    return {
        state,
        start: () => {
            dispatch({
                type: 'assignLoadingRequest',
            });
        },
        answer: ({ optionId }: { optionId: string | null }) => {
            dispatch({
                type: 'answerLoadingRequest',
                data: { selectedOptionId: optionId },
            });
        },
        next: () => {
            if (state.id === 'answerSuccess') {
                const triviaAd =
                    trivia.ads &&
                    trivia.ads[state.past.length - 1] &&
                    trivia.ads[state.past.length - 1][languageId];
                if (triviaAd) {
                    dispatch({
                        type: 'showAd',
                        data: { ad: triviaAd },
                    });
                } else {
                    if (state.past.length === trivia.numberOfQuestions) {
                        dispatch({
                            type: 'gameOver',
                        });
                    } else {
                        dispatch({
                            type: 'nextLoadingRequest',
                        });
                    }
                }
            } else if (state.id === 'postAnswerAd' || state.id === 'past') {
                if (state.past.length === trivia.numberOfQuestions) {
                    dispatch({
                        type: 'gameOver',
                    });
                } else {
                    dispatch({
                        type: 'nextLoadingRequest',
                    });
                }
            }
        },
    };
};

export default useTriviaGame;
