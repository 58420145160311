import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { Color, StyleDefaults } from 'src/styles/Constants';
import css from '@emotion/css';
import TouchButton from 'src/components/shared/controls/TouchButton';
import { ModalType } from './ModalHoster';
import howToPlayBackground from 'src/images/backgrounds/HowToPlayAccent.svg';
import Theme from 'src/util/Theme';
import TallyMarkdown from 'src/components/shared/ui/TallyMarkdown';
import { CloseButtonColor } from 'src/components/shared/controls/CloseButton';
import { TranslatedIteration } from 'src/util/i18n';
import { EventModel } from 'src/util/TallyFirestore';

interface Props {
    requestClose: () => void;
    bodyText?: string;
    headerText?: string;
}

export interface HowToPlayModalDetails {
    modalType: ModalType.HOW_TO_PLAY;
    iteration?: TranslatedIteration;
    event?: EventModel;
}

export const HowToPlayModalPresentationDetails = {
    backgroundColor: Theme.modalBackgroundColor,
    backgroundImageUrl: howToPlayBackground,
    backgroundPosition: 'bottom',
    closeIconColor: CloseButtonColor.GRAY,
};

const HowToPlayModal = ({ bodyText, headerText, requestClose }: Props) => {
    const intl = useIntl();

    return (
        <Container>
            <TagLineText>
                <FormattedMessage id="howToPlayModal.tag" />
            </TagLineText>
            <HeaderText>
                {headerText || <FormattedMessage id="howToPlayModal.header" />}
            </HeaderText>
            <BodyText>
                <TallyMarkdown
                    source={
                        bodyText ||
                        intl.formatMessage({ id: 'howToPlayModal.defaultBody' })
                    }
                />
            </BodyText>
            <CloseButtonContainer>
                <TouchButton
                    backgroundColor={Color.P1_WHITE}
                    textColor={Color.T31_BLACK}
                    displayText={
                        <FormattedMessage id="howToPlayModal.button" />
                    }
                    onClick={requestClose}
                    stretchWidth={true}
                />
            </CloseButtonContainer>
        </Container>
    );
};

export default HowToPlayModal;

const Container = styled.div`
    color: ${Color.P1_WHITE};
    display: flex;
    text-align: left;
    flex-direction: column;
    padding: ${StyleDefaults.MODAL_BODY_PADDING};
`;

const TagLineText = styled.div`
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 18px;
    text-transform: upperCase;
`;

const HeaderText = styled.div`
    font-size: 35px;
    letter-spacing: -0.6px;
    line-height: 37px;
    font-weight: 900;
    margin: 0 0 20px 0;
`;

const BodyTextStyling = css`
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 18px;
`;

const BodyText = styled.div`
    ${BodyTextStyling}
    margin: 10px 0;
`;

const CloseButtonContainer = styled.div`
    width: 100%;
    margin: 40px 0 20px 0;
`;
