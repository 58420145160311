import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { UserOffer } from 'src/util/TallyFirestore';
import { IsoDateString } from 'src/util/SponsorshipTypes';
import OfferDetails from './OfferDetails';
import OfferList, { OfferWithContext } from './OfferList';
import { SectionHeaderText } from '../shared';
import { useUserState } from 'src/contexts/UserStateContext';

interface Props {
    pendingOffers?: Array<UserOffer<IsoDateString>>;
}

type Content = { id: 'main' } | { id: 'offer'; userOffer: UserOffer };

const getUserOffers = (
    pendingOffers: Array<UserOffer<IsoDateString>> | undefined,
    confirmedOffers: UserOffer[] | undefined,
) => {
    const allOffers: OfferWithContext[] = [];
    if (pendingOffers) {
        pendingOffers
            .filter(
                (pendingOffer) =>
                    // Filter out pending offers that are already confirmed.
                    !confirmedOffers ||
                    !confirmedOffers.find(
                        (confirmedOffer) =>
                            confirmedOffer.id === pendingOffer.id,
                    ),
            )
            .forEach((offer) => {
                allOffers.push({
                    pending: true,
                    userOffer: offer,
                });
            });
    }
    if (confirmedOffers) {
        confirmedOffers.forEach((userOffer) => {
            allOffers.push({
                pending: false,
                userOffer,
            });
        });
    }

    return allOffers;
};

const Offers = ({ pendingOffers }: Props) => {
    const userData = useUserState();
    const [content, setContent] = useState<Content>({ id: 'main' });
    const confirmedOffers =
        userData.type === 'initialized' ? userData.offers : undefined;
    const allOffers = getUserOffers(pendingOffers, confirmedOffers);

    const onOfferClick = (userOffer: UserOffer) => {
        setContent({
            id: 'offer',
            userOffer,
        });
    };

    if (allOffers.length === 0) {
        return null;
    }

    let offerContent: React.ReactNode;
    if (content.id === 'offer') {
        offerContent = (
            <OfferDetails
                onClose={() => setContent({ id: 'main' })}
                userOffer={content.userOffer}
            />
        );
    } else {
        offerContent = (
            <OfferList offers={allOffers} onOfferClick={onOfferClick} />
        );
    }

    return (
        <>
            <SectionHeaderText marginTop="30px">
                <FormattedMessage id="account.rewards.header" />
            </SectionHeaderText>
            {offerContent}
        </>
    );
};

export default Offers;
