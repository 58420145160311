import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from '@emotion/styled';

import * as FirestoreDao from 'src/util/FirestoreDao';
import TouchButton from 'src/components/shared/controls/TouchButton';
import SquareLoading from 'src/components/shared/animations/SquareLoading';
import TextInput from 'src/components/shared/controls/fields/TextInput';
import { Color, TextStyles, TextStyleTypes } from 'src/styles/Constants';
import TallyMarkdown from 'src/components/shared/ui/TallyMarkdown';
import HelpLinks from 'src/components/shared/ui/HelpLinks';
import InputLabel from 'src/components/shared/ui/InputLabel';
import DataCollectionFields from './DataCollectionFields';
import ServerError from 'src/services/ServerError';
import {
    DISPLAY_NAME_REGEX,
    DISPLAY_NAME_MIN_LENGTH,
    DISPLAY_NAME_MAX_LENGTH,
} from 'src/util/validation';
import { ValidationError } from 'src/util/validation';
import { usePartner } from 'src/hooks/usePartner';
import { TranslatedIteration } from 'src/util/i18n';
import ServerApi from 'src/services/ServerApi';
import { useLocale } from 'src/hooks/useLocale';
import FirebaseAuth from 'src/services/FirebaseAuth';
import { useAnalytics } from 'src/contexts/AnalyticsContext';
import { AppArea, EventName } from 'src/util/AnalyticsConstants';
import { useInitializeUser } from 'src/contexts/UserStateContext';
import { NetworkError } from 'src/util/errors/NetworkError';
import Logger from 'src/util/Logger';
import { BackgroundPanel } from 'src/components/shared/ui/BackgroundPanel';

export type TileData = {
    displayName: string;
    [key: string]: string | undefined;
};

type Props = {
    iteration: TranslatedIteration;
    onContactSupportClick: () => void;
    screenHasBg?: boolean | false;
    disclaimer?: JSX.Element;
};

const DisplayNameTile = ({
    iteration,
    onContactSupportClick,
    screenHasBg,
    disclaimer,
}: Props): JSX.Element => {
    const intl = useIntl();
    const { localeId } = useLocale();
    const partner = usePartner();
    const initializeUser = useInitializeUser();
    const analytics = useAnalytics();

    const [tileError, setTileError] = useState<Error | undefined>(undefined);
    const [fieldErrors, setFieldErrors] = useState<{
        [key: string]: Error;
    }>({});

    const { dataCollection } = partner.properties;
    const [tileData, setTileData] = useState<TileData>({
        displayName: '',
    });
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    useEffect(() => {
        if (
            isSubmitting &&
            ((Boolean(fieldErrors) &&
                Boolean(Object.getOwnPropertyNames(fieldErrors).length)) ||
                Boolean(tileError))
        ) {
            setIsSubmitting(false);
        }
    }, [tileError, fieldErrors]);

    const onError = (error: ValidationError) => {
        setFieldErrors((previous) => ({
            ...previous,
            [error.field]: error,
        }));
    };

    const onClearError = (field: string, force?: boolean): void => {
        setFieldErrors((previousState) => {
            if (force) {
                return {};
            }
            const newState = { ...previousState };
            delete newState[field];
            return newState;
        });
        setTileError(undefined);
    };

    const shouldShowGeneralTileError = () =>
        tileError &&
        !(
            fieldErrors &&
            (Object.keys(fieldErrors).some(
                (field) =>
                    dataCollection &&
                    dataCollection.fields.some((dc) => dc.id === field),
            ) ||
                fieldErrors.displayName)
        );

    const tileTitle = intl.formatMessage({
        id: 'onboarding.requestDisplayName.title',
    });

    const fieldTitle = intl.formatMessage({
        id: 'onboarding.requestDisplayName.field.title',
    });

    const fieldDescription = intl.formatMessage({
        id: 'onboarding.requestDisplayName.description',
    });

    const onSubmit = async () => {
        setIsSubmitting(true);
        try {
            const iterationId = iteration.id;

            const user = FirebaseAuth.getUser();

            let phoneNumber = undefined;
            if (user && user.phoneNumber) {
                phoneNumber = user.phoneNumber;
            }

            const { displayName, ...other } = tileData;

            await ServerApi.initUser({
                preferredLanguage: localeId,
                displayName,
                phoneNumber,
                additionalDataCollection: JSON.stringify(other),
                lastIterationOptIn: iterationId,
            });

            analytics.logEvent({
                eventName: EventName.signUpDisplayNameComplete,
                eventCategory: AppArea.signup,
            });
            const uid = FirebaseAuth.getUser()?.uid;
            if (!uid) {
                throw new Error(
                    'User should have been already authenticated in here...',
                );
            }
            const userDetails = await FirestoreDao.getUser(uid);
            if (!userDetails) {
                throw new Error(`Couldn't found user. uid = ${uid}`);
            }

            initializeUser(uid, { details: userDetails });
        } catch (error) {
            if (error instanceof NetworkError) {
                setTileError(
                    new Error(
                        intl.formatMessage({ id: 'error.failedToFetch' }),
                    ),
                );
                return;
            }
            if (!(error instanceof ServerError)) {
                Logger.error('Error submitting displayname', error);
                return;
            }

            if (error.type === 'inappropriate') {
                setFieldErrors({
                    ...fieldErrors,
                    displayName: new Error(
                        intl.formatMessage({
                            id: 'error.offensiveLanguage',
                        }),
                    ),
                });
                return;
            }
            if (error.type === 'displayNameIsTaken') {
                setFieldErrors({
                    ...fieldErrors,
                    displayName: new Error(
                        intl.formatMessage({
                            id: 'error.displayName.isTaken',
                        }),
                    ),
                });
                return;
            }
            // ATODO: will it ever be executed?
            if (error.field) {
                setFieldErrors({ ...fieldErrors, [error.field]: error });
                setTileError(
                    new Error(intl.formatMessage({ id: 'error.generic' })),
                );
                return;
            }
            setTileError(error as Error);
            return;
        }
    };

    const disableSubmit =
        Boolean(Object.getOwnPropertyNames(fieldErrors).length) ||
        !Boolean(tileData.displayName) ||
        Boolean(tileError) ||
        !(dataCollection
            ? dataCollection.fields.every((field) => tileData[field.id])
            : true);

    return (
        <>
            <BackgroundPanel screenHasBg={screenHasBg} topConnect={true}>
                <TileTitle color={Color.T31_BLACK}>{tileTitle}</TileTitle>
                {fieldDescription && (
                    <DescriptionText>
                        <TallyMarkdown source={fieldDescription} />
                    </DescriptionText>
                )}
                <TextInput
                    field="displayName"
                    disabled={false}
                    onChange={(value: string) =>
                        setTileData((previous: TileData) => ({
                            ...previous,
                            displayName: value,
                        }))
                    }
                    onClear={() =>
                        setTileData((previous: TileData) => ({
                            ...previous,
                            displayName: '',
                        }))
                    }
                    error={fieldErrors && fieldErrors.displayName}
                    label={fieldTitle}
                    onContactSupportClick={onContactSupportClick}
                    validation={DISPLAY_NAME_REGEX}
                    validationErrorText={intl.formatMessage(
                        { id: 'displayName.error' },
                        {
                            min: DISPLAY_NAME_MIN_LENGTH,
                            max: DISPLAY_NAME_MAX_LENGTH,
                        },
                    )}
                    onError={onError}
                    onClearError={onClearError}
                    validateOnBlurOnly={false}
                />
            </BackgroundPanel>
            {dataCollection && (
                <DataCollectionFields
                    dataCollection={dataCollection}
                    onContactSupportClick={onContactSupportClick}
                    setData={setTileData}
                    errors={fieldErrors}
                    onError={onError}
                    onClearError={onClearError}
                    screenHasBg={screenHasBg}
                />
            )}
            {shouldShowGeneralTileError() && (
                <BackgroundPanel screenHasBg={screenHasBg}>
                    <TileErrorContainer>
                        <InputLabel label={''} error={tileError} />
                        <HelpLinks
                            onContactSupportClick={onContactSupportClick}
                        />
                    </TileErrorContainer>
                </BackgroundPanel>
            )}
            <BackgroundPanel screenHasBg={screenHasBg} topConnect={true}>
                {disclaimer}
            </BackgroundPanel>
            <ButtonContainer>
                <TouchButton
                    disabled={disableSubmit}
                    displayText={
                        <FormattedMessage id="infoCollectionTitle.touchButtonLabel" />
                    }
                    backgroundColor={Color.T31_BLACK}
                    textColor={Color.P1_WHITE}
                    onClick={onSubmit}
                />
            </ButtonContainer>
            <LoadingContainer>
                {isSubmitting && (
                    <SquareLoading color={Color.P6_ELECTRIC_BLUE} />
                )}
            </LoadingContainer>
        </>
    );
};

const TileTitle = styled.div`
    ${TextStyles[TextStyleTypes.H3]}
    border-bottom: 2px solid ${Color.G8_CONCRETE};
    text-align: left;
    width: 100%;
`;

const ButtonContainer = styled.div`
    padding: 25px 0 15px 0;
`;
const TileErrorContainer = styled.div`
    padding: 15px;
`;

const DescriptionText = styled.div`
    margin: 15px 0 30px 0;
    ${TextStyles[TextStyleTypes.P1]}
    text-align: left;
    width: 100%;
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 20px;
    margin: 20px 0;
`;

export default DisplayNameTile;
