import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';

import { AdSizingPolicy, Ad } from 'src/util/TallyFirestore';
import TextButton from '../../controls/TextButton';
import { useAnalytics } from 'src/contexts/AnalyticsContext';
import { AppArea, EventName } from 'src/util/AnalyticsConstants';
import { getIframeAspectRatio, AspectRatio } from 'src/util/Iframe';
import { useIntl } from 'react-intl';
import AdIframe from '../../ads/AdIframe';
import {
    AdHeadline as AdHeadlineBase,
    AspectRatioContainer,
    Disclaimer as DisclaimerBase,
    GradientOverlay,
} from '../../ads/shared';

type Props = {
    eventAd: Ad;
    eventId: string;
};

const EventIframeAd = ({ eventAd, eventId }: Props) => {
    const intl = useIntl();
    const analytics = useAnalytics();

    const [showReadMoreButton, setShowReadMoreButton] = useState<boolean>(true);

    const headline: string = eventAd.headline || '';
    const disclaimer: string = eventAd.disclaimer || '';
    const sizingPolicy = eventAd.iframeHtml
        ? eventAd.sizingPolicy
        : AdSizingPolicy.preserveAspectRatio;

    const ref = useRef<HTMLDivElement>(null);

    const onIframeLoad = () => {
        analytics.logEvent({
            eventName: EventName.iframeSeen,
            params: {
                event: eventId,
                ad_headline: headline,
            },
            eventCategory: AppArea.game,
        });
    };

    if (sizingPolicy === AdSizingPolicy.preserveAspectRatio) {
        const aspectRatio = getIframeAspectRatio(eventAd.iframeHtml);

        return (
            <EventAdContainerWrapper centerItems={true}>
                <EventAdContainer ref={ref} aspectRatio={aspectRatio}>
                    <AdHeadline>{headline}</AdHeadline>
                    <AspectRatioContainer aspectRatio={aspectRatio}>
                        <AdIframe
                            iframeHtml={eventAd.iframeHtml}
                            onLoad={onIframeLoad}
                        />
                    </AspectRatioContainer>
                    <Disclaimer>{disclaimer}</Disclaimer>
                </EventAdContainer>
            </EventAdContainerWrapper>
        );
    }
    return (
        <EventAdContainerWrapper>
            <AdHeadline>{headline}</AdHeadline>
            <div style={{ position: 'relative' }}>
                <div ref={ref} style={{ width: '100%' }}>
                    <AdIframe
                        iframeHtml={eventAd.iframeHtml!}
                        onLoad={onIframeLoad}
                    />
                </div>
                {sizingPolicy === AdSizingPolicy.readMore &&
                    showReadMoreButton && <GradientOverlay />}
            </div>
            {sizingPolicy === AdSizingPolicy.readMore && showReadMoreButton && (
                <TextButton
                    text={intl.formatMessage({
                        id: 'predictPage.readMoreButton.text',
                    })}
                    onClick={async () => {
                        analytics.logEvent({
                            eventName: EventName.contentReadMore,
                            params: { event: eventId },
                            eventCategory: AppArea.game,
                        });
                        setShowReadMoreButton(false);
                    }}
                />
            )}
            <Disclaimer>{disclaimer}</Disclaimer>
        </EventAdContainerWrapper>
    );
};

const AdHeadline = styled(AdHeadlineBase)`
    margin: 0 20px 0 20px;
`;

const Disclaimer = styled(DisclaimerBase)`
    margin: 0 20px 0 20px;
`;

const EventAdContainerWrapper = styled.div<{ centerItems?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: ${(props) => (props.centerItems ? 'center' : 'stretch ')};
`;

const EventAdContainer = styled.div<{
    aspectRatio: AspectRatio | null;
}>`
    width: 100%;
    max-width: ${(props) =>
        props.aspectRatio ? `${props.aspectRatio.width}px` : ''};
`;

export default EventIframeAd;
