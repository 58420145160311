import React from 'react';
import { Color } from 'src/styles/Constants';

const SvgPowerup = (props) => (
    <svg width="1em" height="1em" viewBox="0 0 18 18" {...props}>
        <path
            d="M2.854 13H1v-.372l3.442-3.384c.496-.488.87-.93 1.123-1.327.252-.398.378-.789.378-1.173 0-.368-.11-.66-.328-.879-.218-.218-.525-.327-.92-.327-.437 0-.78.129-1.028.385s-.41.671-.486 1.244H1.252c.05-1.017.385-1.8 1.003-2.346C2.873 4.274 3.715 4 4.783 4c.68 0 1.267.113 1.759.34.492.226.866.542 1.122.948.257.406.385.874.385 1.404 0 .479-.114.934-.34 1.366-.228.431-.56.87-.997 1.314L2.854 13zm2.66-1.474h2.623V13H3.913l1.601-1.474zm3.227-7.308h2.143l1.98 2.91 1.728-2.91h2.055L14.1 8.398 17 13h-2.144L12.65 9.692v.013L8.74 4.218zm3.505 6.18L10.708 13H8.55l2.61-4.154 1.085 1.551z"
            fill={props.color ? props.color : Color.P1_WHITE}
            fillRule="nonzero"
        />
    </svg>
);

export default SvgPowerup;
