declare global {
    interface Window {
        fbq: facebook.Pixel.Event;
    }
}

class ScriptInjector {
    public injectScript = async (
        id: string,
        source?: string,
        content?: string,
    ) => {
        return new Promise<void>((resolve, reject) => {
            // if this script element already exists, do not load
            if (document.getElementById(id)) {
                return;
            }

            // create a new script element with the passed in id;
            const js = document.createElement('script') as HTMLScriptElement;
            js.id = id;

            if (content) {
                js.textContent = content;
            }

            // setup listener for when script is done loading
            js.addEventListener('load', () => {
                resolve();
            });
            js.addEventListener('error', (error) => {
                reject(error);
            });
            // attach source to element. (i think this also attempts to load)
            if (source) {
                js.src = source;
            }

            // insert script element BEFORE the first script
            // this guarantees injected script runs before bundle.js
            const fjs = document.getElementsByTagName('script')[0];
            if (fjs.parentNode) {
                fjs.parentNode.insertBefore(js, fjs);
            }
        });
    };
}

export default new ScriptInjector();
